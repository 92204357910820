export const locale = {
  lang: 'en',
  data: {
      MAILFILTER: {
        FILTER_EMAILS: 'Mails filtern',
        TO: 'Empfänger/CC',
        FROM: 'Absender'
      },
  },
};
