export const locale = {
    lang: 'de',
    data: {
        DETAIL_NOTIFICATION: {
            NOTIFICATIONS: {
                SAVING: 'Benachrichtigung wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Benachrichtigung erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
            },
            COLUMNS: {
                TITLE: 'Titel',
                SUBJECT: 'Betreff',
                TEXT: 'Benachrichtigungstext',
                INTERVALL: 'Intervall',
                INTERVALLDESCRIPTION: '(Addition in Tagen zum Datumsfeld)',
                LIST: 'Liste',
                DATEFIELD: 'Datumsfeld',
                RECEIVERFIELDS: 'Empfängerfelder',
                FIELDSDESCRIPTION:
                    '(Muss in der Standardansicht vorhanden sein)',
            },
            SAVE: 'Speichern',
            NEW: 'Neue Benachrichtigung',
            EDIT: 'Benachrichtigung bearbeiten',
        },
    },
};
