import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1069Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );

            const promises = [
                // Add Field Notizen to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Notizen',
                    FieldType.MultilineText
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
