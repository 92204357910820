import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1139Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const assignmentBudgets =
                this._sharePointService.web.lists.getByTitle(
                    'Aufträge-Budgets'
                );
            await this._updateService.addField(
                assignmentBudgets,
                'Nachtrag',
                FieldType.MultilineText
            );
            await this._updateService.addField(
                assignmentBudgets,
                'Datum',
                FieldType.Date
            );
            await this._updateService.addField(
                assignmentBudgets,
                'Notiz',
                FieldType.MultilineText
            );
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
