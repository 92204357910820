export const locale = {
    lang: 'de',
    data: {
        ADDFOLDERDIALOG: {
            ADDFOLDER: 'Ordner hinzufügen',
            OK: 'OK',
            CANCEL: 'Abbrechen',
            NAME: 'Name',
            MESSAGE: 'Bitte geben Sie den Namen des neuen Ordners ein:',
        },
    },
};
