export const locale = {
    lang: 'en',
    data: {
        ASSIGN_EMAIL: {
            ADDATTACHMENT: 'Add attachment',
            NOTIFICATIONS: {
                LOADING: 'Loading mails...',
                SAVING: 'Assigning mails...',
                WAIT: 'Please wait.',
                SUCCESS: 'Mails assigned successfully',
                ERROR: 'Error while assigning: ',
            },
            COLUMNS: {
                TITLE: 'Title',
                SUBJECT: 'Subject',
                TEXT: 'Message',
                TO: 'To',
                FROM: 'From',
                ATTACHMENTS: 'Attachments',
                SENTDATETIME: 'Sent on',
                ALREADYCREATED: 'Already read'
            },
            IMPORT_MAILS_DIALOG: {
                OK: 'OK',
                DUPLICATE: 'Duplikat',
                UNEXPECTED: 'Unerwarteter Fehler',
                IMPORTED: 'Fertig',
            },
            SAVE: 'Assign E-Mails',
            FOLDER: 'Select Folder',
            ASSIGN: 'Assign E-Mails',
            NEW: 'Assign E-Mails',
            PATH: 'current path',
            DIRUP: 'dir up',
            REFRESH: 'refresh',
        },
    },
};
