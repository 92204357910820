export const locale = {
    lang: 'en',
    data: {
        DOCUMENTASSIGNMENT: {
            PROJECTS: 'Projects',
            BASE: 'Basedata',
            DOCS: 'Documents',
            TASKDOCS: 'Taskdocuments',
            INVOLVED: 'Involved',
            TASKS: 'Tasks',
            KANBAN: 'Kanban',
            NOTES: 'Notes',
            NEW: 'Upload documents',
            NEWFOLDER: 'New folder',
            PROJECTNUMBER: 'Projectnumber',
            JUMP: 'To SharePoint Folder',
            JUMPTASK: 'To SharePoint Folder',
            FILESERVER: 'Fileserver',
            CONNECT: 'Connect to Fileserver',
            MOVE: 'Move element',
            COLUMNS: {
                TITLE: 'Title',
                FILESIZE: 'Filesize',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
                TASK: 'Workpackage/Task',
            },
            TABS: {
                PROJECT: 'Projectdocuments',
                TASKS: 'Taskdocuments',
                ORDER: 'Orderdocuments',
                REQUEST: 'Requestdocuments',
            },
            NOTIFICATIONS: {
                UPLOAD: 'Uploading document...',
                ADDINGFOLDER: 'Adding folder...',
                SUCCESS: 'File uploaded successfully.',
                SUCCESSFOLDER: 'Folder added successfully.',
                ERROR: 'Error while uploading: ',
                UPLOADMULTI: 'Uploading documents...',
                SUCCESSMULTI: 'Files uploaded successfully.',
                ERRORMULTI: 'Fehler beim hochladen: ',
                WAIT: 'Please wait.',
                MISSINGPATH: 'No path to the file server stored',
                CONNECTING: 'Establishing connection',
                MISSINGSTART: 'No start date entered',
                SUCCESS_DELETE: 'File/Folder was removed....',
            },
        },
    },
};
