import { RequestService } from '../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1125Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const requestList: IList =
                this._sharePointService.web.lists.getByTitle('Anträge');

            // Add Field Abgesagt to List Anträge
            await this._updateService.addField(
                requestList,
                'Abgesagt',
                FieldType.Boolean
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
