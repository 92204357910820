export const locale = {
    lang: 'de',
    data: {
        DETAIL_REQUEST: {
            REQUESTS: 'Anfragen',
            BASE: 'Stammdaten',
            NEW: 'Neue Anfrage',
            FORM: {
                CREATED1: 'Erstellt von ',
                CREATED2: ' am ',
                CREATED3: ' um ',
                DATE: 'Anfragedatum',
                DESCRIPTION: 'Projektbezeichnung',
                CLIENT: 'Ansprechpartner',
                CONTACT: 'Kontakte',
                TAXER: 'Projektsteuerer',
                TAXERDESCRIPTION: 'nur zur Rechnungsprüfung',
                COSTCENTRE: 'Kostenstelle',
                TEAM: 'Team',
                AREA: 'Fachbereich',
                LOCATION: 'Niederlassung',
                ACCREDITED: 'Akkreditiert',
                MANAGER: 'Projektleiter',
                CLERK: 'Sachbearbeiter',
                SUM: 'Gesamtsumme',
                INEURO: '(in Euro)',
                ADDITIONAL: 'Davon Nebenkosten',
                INPERCENT: '(in %)',
                EXTERNAL: 'Davon Fremdleistung',
                COPY: 'Kopie Angebot/Anfrage an Sekretariat',
                CONFIRMATION: 'Anfragebestätigung senden',
                FOLDER: 'Ordner anlegen',
                XMAS: 'Weihnachtskarte',
                HANDINGOVER: 'Übergabedatum',
                SAVE: 'Speichern',
                ACCEPT: 'Genehmigen',
                CANCELED: 'Abgesagt',
                DELETE: 'Löschen',
                CONVERT: 'In Auftrag konvertieren',
                POSITION: {
                    HEADER: 'Position',
                    TITLE: 'Titel',
                    WORK: 'Leistung',
                    NETTO: 'Netto Betrag [€]',
                },
            },
            NOTIFICATIONS: {
                SAVING: 'Anfrage wird gespeichert...',
                DELETING: 'Anfrage wird gelöscht...',
                CANCELING: 'Anfrage wird abgesagt...',
                CREATINGPROJECT: 'Projekt wird erstellt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Anfrage erfolgreich gespeichert.',
                SUCCESSDELETE: 'Anfrage erfolgreich gelöscht.',
                SUCCESSCANCELED: 'Anfrage erfolgreich agesagt.',
                SUCCESSPROJECT: 'Projekt erfolgreich erstellt.',
                ERROR: 'Fehler beim speichern: ',
                CONVERT: {
                    SAVING: 'Anfrage wird in einen Auftrag konvertiert...',
                    WAIT: 'Bitte warten.',
                    SUCCESS:
                        'Anfrage wurde erfolgreich in einen Auftrag konvertiert.',
                    ERROR: 'Fehler beim konvertieren: ',
                },
            },
        },
    },
};
