<div *ngIf="this.ready" class="widget-group" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

  <!-- Chart -->
  <fuse-widget *ngFor="let configuration of _configuration" [@animate]="{value:'*',params:{y:'100%'}}" class="widget"
    fxLayout="column" fxFlex="100" fxFlex.gt-sm="50">

    <!-- Front -->
    <div class="fuse-widget-front">
      <div class="h3 px-16 py-24">
        {{configuration.Title}}
      </div>

      <mat-divider></mat-divider>

      <div class="h-400">
        <ngx-charts-pie-chart *fuseIfOnDom [scheme]="configuration.Scheme" [results]="configuration.Chart"
          [legend]="configuration.Legend" [explodeSlices]="configuration.ExplodeSlices" [labels]="configuration.Labels"
          [doughnut]="configuration.Doughnut" [gradient]="configuration.Gradient"
          (select)="configuration.onSelect($event)">
        </ngx-charts-pie-chart>
      </div>

    </div>
    <!-- / Front -->

  </fuse-widget>
  <!-- / Chart -->

</div>
