import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1063Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            let companyList: IList;
            let contactList: IList;

            const promises = [
                // Add List Firmen
                await this._updateService.addList('Firmen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Firmen
                await new Promise<void>((resolve) => {
                    companyList = this._sharePointService.web.lists.getByTitle(
                        'Firmen'
                    );
                    resolve();
                }),
                // Add List Kontakte
                await this._updateService.addList('Kontakte', '', 105, {
                    EnableVersioning: true,
                }),
                // Get List Kontakte
                await new Promise<void>((resolve) => {
                    contactList = this._sharePointService.web.lists.getByTitle(
                        'Kontakte'
                    );
                    resolve();
                }),
                // Add Field Kunde to List Kontakte
                await this._updateService.addLookupField(
                    contactList,
                    companyList,
                    'Kunde'
                ),
                // Add Field Kunde to List Angebote
                await this._updateService.addLookupField(
                    projectList,
                    companyList,
                    'Kunde'
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
