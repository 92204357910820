export const locale = {
  lang: "de",
  data: {
    EVALUATION: "Auswertung",
    CHART: {
      TARGETVALUE: "Sollwert",
      ACTUALVALUE: "Istwert",
    },
    FILTER: {
      YEAR: "Jahr",
      FROM_YEAR: "von (Jahr)",
      UNTIL_YEAR: "bis (Jahr)",
      LOCATION: "Standort",
      TEAM: "Team",
      MONTH: {
        FROM: "von (Monat)",
        UNTIL: "bis (Monat)",
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      ERRORMESSAGE: {
        YEARS:
          'Der Wert für "von (Jahr)" darf nicht größer als der Wert für "bis (Jahr)" sein!',
        FOURDIGITS:
          "Die Jahreswerte müssen jeweils im vierstelligen Bereich liegen!",
        NOTVALID: "Der angegebene Zeitraum ist ungültig!",
      },
    },
  },
};
