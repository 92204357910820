export const locale = {
    lang: 'en',
    data: {
        DETAIL_TASK: {
            TITLE: 'TASKS',
            SEARCH_LABEL: 'Search for a task',
            ALL: 'All tasks',
            MY: 'My tasks',
            CRITICAL: 'Critical tasks',
            OVERDUEDATE: 'Overdue tasks',
            FILTER_EMAILS: 'Mails filtern',
            DIALOG: {
                PERMISSION: 'Do you realy want to delete the selected item?',
                SUCCESS: 'The item has been deleted',
                ERROR: 'An error occured during the deleting process!',
            },
            COLUMNS: {
                TITLE: 'Title',
                DUEDATE: 'Duedate',
                STATUS: 'Status',
                TASK: 'Task',
                ASSIGNED: 'Assigned to',
                PROJECT: 'Project',
                DESCRIPTION: 'Description',
                KIND: 'Taskkind',
                WORKPACKAGE: 'Workpackage',
                START: 'Start',
                PRIORITY: 'Priority',
                HIGH: '(1) High',
                NORMAL: '(2) Normal',
                LOW: '(3) Low',
                NOTSTARTED: 'Not started',
                INPROCESSING: 'In processing',
                COMPLETED: 'Completed',
                RESET: 'Reset',
                WAITING: 'Waiting',
                TYPE: 'Tasktype',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                PROCESSSTEP: 'Processstep',
                ANSPRECHPARTNER: 'Ansprechpartner',
                CONTACT: "Kontakt"
            },
            TABS: {
                BASE: 'Basedata',
                TASKS: 'Tasks',
                DOCS: 'Documents',
                TIMES: 'Times',
                EMAILS: {
                    TITLE: 'E-Mails',
                    IN: 'E-Mails in',
                    OUT: 'E-Mails out',
                    SEND: 'New E-Mail',
                    FILTER_EMAILS: 'Mails filtern',
                    RE: 'Antworten',
                    REALL: 'Alle antworten',
                    FORWARD: 'Forward',
                    INDEX: 'index E-Mails',
                    ASSIGN: 'E-Mails einlesen',
                },
            },
            NEW: 'Neue Aufgabe',
            EDIT: 'Aufgabe bearbeiten',
            SAVE: 'Save',
            TASKSETS: 'TASKSETS',
            GIVEUSERPERMISSIONTOPROJECT:
                'The selected user does not have permissions to the current project. Should this edit permissions be given?',
            NOTIFICATIONS: {
                SAVINGITEM: 'Saving task/workpackage...',
                SAVING: 'Saving permissions...',
                WAIT: 'Please wait.',
                SUCCESS: 'Permissions set successfully',
                SUCCESSITEM: 'Task/workpackage saved successfully',
                ERROR: 'Error while setting permissions: ',
                DUEDATEERROR:
                    'The due date cannot exceed the end date of the project.',
                DUEDATEERRORTASK:
                    'The due date cannot exceed the end date of the workpackage.',
                STARTDATEERROR:
                    'The start date cannot be earlier than the project start date.',
                STARTDATEERRORTASK:
                    'The start date must not be before the start date of the work package.',
                STARTDUEDATEERROR: 'The start date cannot exceed the due date.',
                DUEDATEHINT: 'Not later than: ',
                STARTDATEHINT: 'Not earlier than: ',
                SUCCESS_DELETE: 'File/Folder was removed.',
            },
            DOCS: {
                TITLE: 'Taskdocuments',
                NEW: 'Upload document',
                MOVE: 'Move element',
                NEWFOLDER: 'New folder',
                CREATE_DOCUMENT: 'Create from template',
                COLUMNS: {
                    TITLE: 'Title',
                    FILESIZE: 'Filesize',
                    AUTHOR: 'Author',
                    MODIFIED: 'Modified',
                },
            },
        },
    },
};
