import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1010Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const taskList = this.sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );

            const promises = [
                // Add Field Kennzeichen to List Auftragsaufgaben
                await this._updateService.runFunctionIfFieldNotExists(
                    taskList,
                    'Kennzeichen',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // taskList.fields
                            //     .addChoice('Kennzeichen', [
                            //         'Kritisch',
                            //         'Unkritisch',
                            //     ])
                            //     .then(
                            //         () => {
                            //             resolve();
                            //         },
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
