<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "DETAIL_GROUP.EDIT" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "DETAIL_GROUP.NEW" | translate }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

    <mat-tab class="tab" label='{{ "DETAIL_GROUP.TABS.BASE" | translate }}'>

      <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
        [renderOptions]="formio_renderOptions"
        (change)="onChange($event)">
      </formio>

    </mat-tab>

    <mat-tab class="tab" label='{{ "DETAIL_GROUP.TABS.USER" | translate }}' *ngIf="this.id">

      <div *ngIf="this.addHint" class='hint'>{{ this.addHint }}</div>
      <div *ngIf="this.viewMembersHint" class='hint'>{{ this.viewMembersHint }}</div>

      <app-table-user #taskTable [groupId]="this.groupItem.Id">
      </app-table-user>

    </mat-tab>

  </mat-tab-group>

</div>
