import { TaskService } from 'src/app/main/services/task.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations, FuseTranslationLoaderService, } from 'sp-office365-layout';
import { ColumnTyp, FormatTypes, SharePointService, TableColumn, TableConfiguration, } from 'sp-office365-framework';
import { ActivatedRoute } from '@angular/router';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { getCustomerTitle } from '../../shared/shared.util';

@Component({
    selector: 'app-tasks-assignment',
    templateUrl: './tasks-assignment.component.html',
    styleUrls: ['./tasks-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TasksAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;
    public formioConfiguration;

    public tableConfig: TableConfiguration;
    public subTableConfig: TableConfiguration;
    public columns: TableColumn[];
    public subTableColumns: TableColumn[];

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _taskService: TaskService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('TASKS');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this.sharePointService
                    .getItemById({
                        listTitle: 'Aufträge',
                        id: this.id,
                        select: '*, Kunde/Id, Kunde/Title, Kunde/Title2, Kunde/Title3',
                        expand: 'Kunde'
                    })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        this.initGrid();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    get camlQueryWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryMyWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryCriticalWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryTasks() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        return (
            '<Where><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            '</Where><OrderBy><FieldRef Name="Modified" Ascending="FALSE" /></OrderBy>'
        );
    }

    get camlQueryMyTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let currentUserExpression = CamlBuilder.Expression()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            currentUserExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    get camlQueryCriticalTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let markExpression = CamlBuilder.Expression()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            markExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    initGrid() {
        if (!this.sharePointItem) {
            return;
        }

        this.subTableColumns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TASK'),
                hidden: false,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Status',
                title: this._translateService.instant('COLUMNS.STATUS'),
                hidden: false,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.subTableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.subTableColumns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: false,
            folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('BUTTONS.NEWTASK'),
                    command: 'AddTask',
                    color: 'accent',
                }
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryTasks,
            parentItemLookupName: 'Arbeitspaket',
        };

        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: false,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Status',
                title: this._translateService.instant('COLUMNS.STATUS'),
                hidden: false,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.columns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: false,
            folderName: this.id + '',
            useMaterialDesign: true,
            customButtons: [{ name: 'Link', icon: 'link' }],
            toolbar: [
                {
                    title: this._translateService.instant('BUTTONS.NEW'),
                    command: 'Add',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant('BUTTONS.IMPORTWORKPACKAGESET'),
                    command: 'ImportWorkPackageSet',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant('BUTTONS.IMPORTTASK'),
                    command: 'ImportTask',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant('BUTTONS.MY'),
                    command: 'FilterMyWorkPackages', //CommandType.changeQuery,
                    query: this.camlQueryMyWorkPackages,
                    color: 'basic',
                },
                {
                    title: this._translateService.instant('BUTTONS.CRITICAL'),
                    command: 'FilterCriticalWorkPackages', //CommandType.changeQuery,
                    query: this.camlQueryCriticalWorkPackages,
                    color: 'basic',
                },
                {
                    title: this._translateService.instant(
                        'BUTTONS.CLEARFILTER'
                    ),
                    command: 'ClearFilter',
                    query: this.camlQueryWorkPackages,
                    color: 'basic',
                },
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryWorkPackages,
            afterDeleteItem: (item) => {
                this._taskService.afterDeleteItem(item);
            },
            configurationSubtable: this.subTableConfig,
        };
    }
}
