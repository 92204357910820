import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import * as CamlBuilder from 'camljs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { Bill } from '../shared/models';
import { SharePointService } from 'sp-office365-framework';
import { ApplicationService } from './application.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class BillingAttachmentsService {
    public pdfAttachmentFiles$ = new BehaviorSubject([]);
    public uploadStatus$ = new BehaviorSubject<{ done: number; failed: number; total: number }>({
        total: 0,
        failed: 0,
        done: 0
    });
    public validBillNumbers: Record<string, boolean> = {};
    public billsWithAttachments: Record<string, boolean> = {};
    private queue$ = new Subject();
    private destroy$ = new Subject();

    constructor(
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService
    ) {
    }

    public startQueue(): void {
        this.queue$
            .pipe(
                takeUntil(this.destroy$),
                concatMap((data: any) =>
                    this.uploadAttachment(data.bill, data.attachmentName, data.attachment)
                )
            )
            .subscribe();
    }

    public stopQueue(): void {
        this.destroy$.next(undefined);
    }

    public uploadBillAttachments(attachments: FileList): Observable<any> {
        const pdfAttachmentFiles = [];
        this.uploadStatus$.next({ done: 0, failed: 0, total: attachments.length });
        this.validBillNumbers = {};
        this.billsWithAttachments = {};

        for (let i = 0; i < attachments.length; i++) {
            const billNumber = this.parsePDFBillAttachmentName(attachments[i].name);
            pdfAttachmentFiles.push({
                billNumber,
                attachment: attachments[i],
                status: !billNumber ? 'Falsche Rechnungsnummer' : '',
                error: !billNumber
            });

            if (!billNumber) {
                this.changeStatus(0, 1);
            }
        }

        this.pdfAttachmentFiles$.next(pdfAttachmentFiles);

        const billNumbers = pdfAttachmentFiles
            .map(pdfAttachmentFile => pdfAttachmentFile.billNumber)
            .filter(billNumber => billNumber);

        return from(
            this._sharepointService.getListItems({
                title: 'Rechnungen',
                isDocumentLibrary: false,
                recursiveAll: true,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('Rechnungsnummer')
                    .In(billNumbers.length ? billNumbers : [-1])
                    .ToString()
            })
        ).pipe(
            tap((bills: Bill[]) => {
                bills.forEach(bill => {
                    this.validBillNumbers[bill.Rechnungsnummer] = true;
                    this.billsWithAttachments[bill.Rechnungsnummer] = bill.Attachments;
                    const attachments = pdfAttachmentFiles.filter(pdfAttachment => pdfAttachment.billNumber === bill.Rechnungsnummer);
                    attachments.forEach(attachment => {
                        attachment.billId = bill.Id;

                        if (!this.billsWithAttachments[attachment.billNumber]) {
                            this.queue$.next({
                                bill: attachment,
                                attachmentName: attachment.attachment.name,
                                attachment: attachment.attachment.slice()
                            });
                            this.billsWithAttachments[attachment.billNumber] = true;
                        } else {
                            attachment.status = 'Duplizierter Anhang';
                            attachment.error = true;
                            this.changeStatus(0, 1);
                        }
                    });
                });

                pdfAttachmentFiles.forEach(pdfAttachment => {
                    if (!this.validBillNumbers[pdfAttachment.billNumber] && pdfAttachment.billNumber) {
                        pdfAttachment.status = 'Falsche Rechnungsnummer';
                        pdfAttachment.error = true;
                        this.changeStatus(0, 1);
                    }
                });
            })
        );
    }

    private uploadAttachment(bill, attachmentName: string, attachment: Blob): Observable<any> {
        return from(
            this._sharepointService.addItemAttachment({
                listTitle: 'Rechnungen',
                itemId: bill.billId,
                attachmentMetadata: {
                    filename: attachmentName,
                    content: attachment
                }
            })
        ).pipe(
            map(() => {
                this.changeStatus(1, 0);
                bill.status = 'Finished';
                return ({ bill, attachmentName, attachment });
            }),
            catchError((error) => {
                let errorMessage = 'Unerwarteter Fehler';

                if (error.message.includes('2130575257')) {
                    errorMessage = 'Duplizierter Anhang';
                }

                bill.status = errorMessage;
                bill.error = true;
                this.changeStatus(0, 1);
                return of({ bill, attachmentName, attachment });
            })
        );
    }

    private changeStatus(done: number, failed: number): void {
        const currentStatus = this.uploadStatus$.getValue();
        currentStatus.done = currentStatus.done + done;
        currentStatus.failed = currentStatus.failed + failed;
        this.uploadStatus$.next({ ...currentStatus });
    }

    private parsePDFBillAttachmentName(attachmentName: string): string | null {
        const regex = /_([^_].*?)_/gm;
        const match = regex.exec(attachmentName);

        if (!match) {
            return null;
        }

        return match[1];
    }
}
