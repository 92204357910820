export const locale = {
    lang: 'en',
    data: {
        TIMELINE: {
            PROJECTNUMBER: 'Projectnumber',
            TITLE: 'Scheduler',
            START: 'Start',
            END: 'End',
            RESIZED: 'Workpackage resized',
            MOVED: 'Workpackage moved',
            THISMONTH: 'This month',
            THISYEAR: 'This year',
            NEXTYEAR: '2 years',
            THREEYEARS: '3 years',
            FOURYEARS: '4 years',
            FIVEYEARS: '5 years',
            CURRENT: 'Current',
            HEADER: 'Timeline',
            PROJECTS: 'Projects',
            TIMELINE: 'Timeline',
        },
    },
};
