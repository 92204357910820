<h1 matDialogTitle>{{'UPLOAD_PDF_BILLS_DIALOG.TITLE' | translate}}</h1>
<div *ngIf="billingAttachmentsService.uploadStatus$ | async as uploadStatus" mat-dialog-content
     class="mat-dialog-body">
    <div class="toolbar">
        <div class="loading">Finished {{uploadStatus.done + uploadStatus.failed}} of {{uploadStatus.total}} bills</div>
        <div class="stats">
            <div class="success">Success: {{uploadStatus.done}}</div>
            <div class="error">Error: {{uploadStatus.failed}}</div>
        </div>
    </div>
    <app-sharepoint-table #table
                          *ngIf="tableConfig"
                          class="pdf-bills-table"
                          [configuration]="tableConfig"
                          (onTooolbarButtonClick)="toolbarButtonHandler($event)"
    >
        <ng-template customGroupHeader for="billNumber" let-group="group">
            <div *ngIf="group.value">{{group.value}}</div>
            <div *ngIf="!group.value">Empty Bill</div>
        </ng-template>

        <ng-template customCell for="status" let-dataItem="dataItem">
            <div class="status-column">
                <ng-container *ngIf="(dataItem.status || !dataItem.billNumber);else loading">
                    <span [class.bill-error]="dataItem.error" [class.bill-success]="!dataItem.error">
                      {{ dataItem.status | translate }}
                    </span>
                </ng-container>
                <ng-template #loading>
                    <mat-spinner diameter="20"></mat-spinner>
                </ng-template>
            </div>
        </ng-template>
    </app-sharepoint-table>
</div>
<div mat-dialog-actions class="mat-dialog-actions mt-8">
    <button mat-raised-button matDialogClose color="accent">
        {{ "UPLOAD_PDF_BILLS_DIALOG.OK" | translate }}
    </button>
</div>
