export const locale = {
  lang: "en",
  data: {
    TITLE: "Newware - Dashboard",
    ACTIVEPROJECTS: "Active projects",
    CRITICALPROJECTS: "Critical projects",
    UNCRITICALPROJECTS: "Uncritical projects",
    ACTIVETASKS: "Active tasks",
    OVERDUETASKS: "Overdue tasks",
    NOTOVERDUETASKS: "Not overdue tasks",
    PROJECTS: "Projects",
    TASKS: "Tasks",
    ACTIVE: "Active",
    CRITICAL: "Critical",
    UNCRITICAL: "Uncritical",
    OVERDUE: "Overdue",
    NOTOVERDUE: "Not overdue",
  },
};
