import { NgModule } from '@angular/core';
import { FilterTimeComponent } from './filter-time.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    declarations: [FilterTimeComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
    ],
    exports: [FilterTimeComponent],
})
export class FilterTimeModule {}
