export const locale = {
    lang: 'en',
    data: {
        ADMIN: 'Administration',
        UPDATE: 'Update',
        AVAILABLE: 'An update is available',
        RUN: 'Run one update',
        RUNALL: 'Run all updates',
        INSTALL: 'Install App',
        NOADMIN: 'You are no sitecollectionadministrator.',
        UPTODATE1: 'The app is up to date.',
        UPTODATE2: 'No update available.',
        CURRENT: 'Current version:',
        NOCURRENT: 'Current version: Not installed',
        LATEST: 'Latest version:',
        RUNNING: 'Running update.',
        DURATION: 'This process can take several minutes.',
        CONSOLE: 'Watch console for details.',
        ERROR: 'Error',
        UPDATESUCCESS: 'Update successful!',
    },
};
