import { ApplicationService } from 'src/app/main/services/application.service';
import { IList } from '@pnp/sp/presets/all';
import * as CamlBuilder from 'camljs';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1071Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            const taskList = this._sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );
            const propertyList = this._sharePointService.web.lists.getByTitle(
                'Properties'
            );
            let timeList: IList;

            const promises = [
                // Add List Zeiten
                await this._updateService.addList('Zeiten', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Zeiten
                await new Promise<void>((resolve) => {
                    timeList = this._sharePointService.web.lists.getByTitle(
                        'Zeiten'
                    );
                    resolve();
                }),
                // Add Field Projekt to List Zeiten
                await this._updateService.addLookupField(
                    timeList,
                    projectList,
                    'Projekt'
                ),
                // Add Field Aufgabe to List Zeiten
                await this._updateService.addLookupField(
                    timeList,
                    taskList,
                    'Aufgabe'
                ),
                // Add Field Minuten to List Zeiten
                await this._updateService.addField(
                    timeList,
                    'Minuten',
                    FieldType.Number
                ),
                // Add Field Datum to List Zeiten
                await this._updateService.addField(
                    timeList,
                    'Datum',
                    FieldType.Date
                ),
                // Add Item to List Properties
                await propertyList.items.add({
                    Title: 'Zeiterfassung',
                    Value: 'Ja',
                }),
                // Add folders for existing projects in List Zeiten
                // Set permissions to new folders in List Zeiten
                await this._applicationService.addFoldersForExistingProjects(
                    'Zeiten',
                    false
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
