import { NgModule } from '@angular/core';
import { ListviewRequestsComponent } from './listview-requests.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

const routes = [
    {
        path: 'requests',
        component: ListviewRequestsComponent,
    },
];

@NgModule({
    declarations: [ListviewRequestsComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
    ],
    exports: [ListviewRequestsComponent],
})
export class ListviewRequestsModule {}
