import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { HighLight, HighLightIconType } from '../models/highlight.model';

import * as CamlBuilder from 'camljs';
import { SharePointService } from 'sp-office365-framework';

import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { HighlightComponent } from '../highlight/highlight.component';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
    @ViewChild('highlightComponent', { static: true })
    highlightComponent: HighlightComponent;
    @ViewChild('chartComponent', { static: true })
    chartComponent: ChartComponent;

    quote: string;
    isLoading: boolean;
    currentUser: any;

    public highLights: HighLight[];
    public charts: any[];

    get uncriticalAssignmentsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Kennzeichen')
            .EqualTo('Unkritisch')
            .And()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag angelegt')
            .Or()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag in Arbeit')
            .ToString();
    }

    get criticalAssignmentCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .And()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag angelegt')
            .Or()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag in Arbeit')
            .ToString();
    }

    get notOverDueTasksCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .GreaterThan(new Date())
            .ToString();
    }

    get overDueTasksCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .ToString();
    }

    constructor(
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initDashBoard();
    }

    ngOnInit() {
        this.currentUser = this.sharePointService.currentUser;
        this.isLoading = true;
    }

    onTabChange(event) {
        //
    }

    initDashBoard() {
        this.highLights = [
            {
                Title: this._translateService.instant('UNCRITICALPROJECTS'),
                Count: 0,
                Icon: 'folder_open',
                Iconcolor: HighLightIconType.Success,
                Query: {
                    ListTitle: 'Aufträge',
                    CamlQuery: this.uncriticalAssignmentsCamlQuery,
                    isDocumentLibrary: false,
                },
                Warning: false,
                Advanced: false,
            },
            {
                Title: this._translateService.instant('CRITICALPROJECTS'),
                Count: 0,
                Icon: 'pan_tool',
                Iconcolor: HighLightIconType.Danger,
                Query: {
                    ListTitle: 'Aufträge',
                    CamlQuery: this.criticalAssignmentCamlQuery,
                    isDocumentLibrary: false,
                },
                Warning: true,
                Advanced: false,
            },
            {
                Title: this._translateService.instant('NOTOVERDUETASKS'),
                Count: 0,
                Icon: 'assignment_turned_in',
                Iconcolor: HighLightIconType.Success,
                Query: {
                    ListTitle: 'Auftragsaufgaben',
                    CamlQuery: this.notOverDueTasksCamlQuery,
                    isDocumentLibrary: false,
                    RecursiveAll: true,
                },
                Warning: false,
                Advanced: false,
            },
            {
                Title: this._translateService.instant('OVERDUETASKS'),
                Count: 0,
                Icon: 'pan_tool',
                Iconcolor: HighLightIconType.Danger,
                Query: {
                    ListTitle: 'Auftragsaufgaben',
                    CamlQuery: this.overDueTasksCamlQuery,
                    isDocumentLibrary: false,
                    RecursiveAll: true,
                },
                Warning: true,
                Advanced: false,
            },
        ];

        this.charts = [
            {
                Title: this._translateService.instant('PROJECTS'),
                Chart: [
                    {
                        name: this._translateService.instant('UNCRITICAL'),
                        value: 0,
                        Query: {
                            ListTitle: 'Aufträge',
                            CamlQuery: this.uncriticalAssignmentsCamlQuery,
                            isDocumentLibrary: false,
                        },
                    },
                    {
                        name: this._translateService.instant('CRITICAL'),
                        value: 0,
                        Query: {
                            ListTitle: 'Aufträge',
                            CamlQuery: this.criticalAssignmentCamlQuery,
                            isDocumentLibrary: false,
                        },
                    },
                ],
                Legend: false,
                ExplodeSlices: false,
                Labels: true,
                Doughnut: false,
                Gradient: false,
                Scheme: {
                    domain: ['#03a9f4', '#f44336'],
                },
                onSelect: (ev) => {
                    console.log(ev);
                },
            },
            {
                Title: this._translateService.instant('TASKS'),
                Chart: [
                    {
                        name: this._translateService.instant('NOTOVERDUE'),
                        value: 0,
                        Query: {
                            ListTitle: 'Auftragsaufgaben',
                            CamlQuery: this.notOverDueTasksCamlQuery,
                            isDocumentLibrary: false,
                            RecursiveAll: true,
                        },
                    },
                    {
                        name: this._translateService.instant('OVERDUE'),
                        value: 0,
                        Query: {
                            ListTitle: 'Auftragsaufgaben',
                            CamlQuery: this.overDueTasksCamlQuery,
                            isDocumentLibrary: false,
                            RecursiveAll: true,
                        },
                    },
                ],
                Legend: false,
                ExplodeSlices: false,
                Labels: true,
                Doughnut: false,
                Gradient: false,
                Scheme: {
                    domain: ['#03a9f4', '#f44336'],
                },
                onSelect: (ev) => {
                    console.log(ev);
                },
            },
        ];
    }
}
