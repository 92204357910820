<div class="page-layout carded left-sidebar tabbed inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar
        class="sidebar"
        name="carded-left-sidebar-tabbed-2"
        position="left"
        lockedOpen="gt-md"
    >
        <!-- SIDEBAR HEADER -->
        <div class="header p-6 accent">
            <h2 class="m-0"></h2>
        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div
            class="content"
            fusePerfectScrollbar
            [@animate]="{ value: '*', params: { y: '50px' } }"
        >
            <div class="nav material2">
                <div class="nav-subheader"></div>

                <div
                    *ngFor="
                        let item of this._requestService.sideBarConfigRequest
                    "
                    class="nav-item"
                >
                    <a
                        class="nav-link"
                        matRipple
                        [routerLink]="item.Link"
                        [routerLinkActive]="['active', 'accent']"
                        queryParamsHandling="merge"
                        (click)="onNavChange()"
                    >
                        <mat-icon class="nav-link-icon">{{
                            item.Icon
                        }}</mat-icon>
                        <span>{{ item.Text }}</span>
                    </a>
                </div>
            </div>
        </div>
        <!-- / SIDEBAR CONTENT -->
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT HEADER -->
        <div
            class="header accent p-6"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="h2 mt-4">
                    <mat-icon
                        class="logo-icon mr-4"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    >
                        call_missed
                    </mat-icon>
                    <span
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        {{ this.itemTitle }}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="id">
                    <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
                    <span class="secondary-text">{{
                        'DETAIL_REQUEST.REQUESTS' | translate
                    }}</span>
                    <mat-icon class="secondary-text s-16"
                        >chevron_right</mat-icon
                    >
                    <span class="secondary-text">{{
                        'DETAIL_REQUEST.BASE' | translate
                    }}</span>
                </div>

                <div
                    *ngIf="this.author && this.createDate"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    class="creation-info"
                >
                    <span class="secondary-text"
                        >{{ this.author }}{{ this.createDate }}</span
                    >
                </div>
            </div>

            <!-- SEARCH -->
            <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

            <div class="search-wrapper mt-4 mt-sm-0">

              <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>search</mat-icon>
                <input [formControl]="searchInput" placeholder="Search for a contact">
              </div>

            </div>

          </div> -->
            <!-- / SEARCH -->
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <!-- CONTENT -->
            <div class="content card-content">
                <formio
                    #formioComponent
                    class="formio-material"
                    [renderOptions]="formio_renderOptions"
                    [refresh]="refreshForm"
                    (ready)="formioReady($event)"
                    [service]="this"
                    [form]="formioConfiguration"
                    (change)="onChange($event)"
                >
                </formio>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
