import { NgModule } from '@angular/core';
import { ChartComponent } from './chart.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseWidgetModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

const routes = [
    {
        path: 'chart',
        component: ChartComponent,
    },
];

@NgModule({
    declarations: [ChartComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        FuseWidgetModule,
        MatDividerModule,
        NgxChartsModule,
    ],
    exports: [ChartComponent],
})
export class ChartModule {}
