import { SnackbarService } from './../../services/snackbar.service';
import { LoadingService } from './../../services/loading.service';
import { NewTimeComponent } from './../../time/new-time/new-time.component';
import { TimeService } from './../../services/time.service';
import {
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ColumnTyp,
    SharePointService,
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { Timelevel } from '../../models/timelevel.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../administration/configuration/confirm-dialog/confirm-dialog.component';
import CamlBuilder from 'camljs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { DateUtil } from 'src/app/main/shared/utils';
import { HolidaysService } from 'src/app/main/services/holidays.service';
import { TeamsService } from 'src/app/main/services/teams.service';
import { toFixed } from 'src/app/main/shared/shared.util';
import { ASSIGNMENT_TIME_MONTH_FILTER } from '../constants/assignment-time.constant';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-times-assignment',
    templateUrl: './times-assignment.component.html',
    styleUrls: ['./times-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TimesAssignmentComponent implements OnInit, OnDestroy {

    public formio_renderOptions = {
        // language: 'de'
    }

    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;
    public isManager = false;
    public timeConfig: TableConfiguration;
    public editTask;
    public weekends: boolean[] = [];
    private deleteMode: boolean;

    @ViewChild('timeGrid', { static: false })
    timeGrid: SharePointTableComponent;

    @ViewChild('newTimeComponent', { static: false })
    newTimeComponent: NewTimeComponent;


    @ViewChild('monthGrid', { static: false })
    public monthGrid: SharePointTableComponent;
    public monthAllData = [];
    public monthConfig: TableConfiguration;
    public formioFilterConfiguration;
    public filterChangeEmitter = new EventEmitter<{
        year?: string;
        month?: number;
        employee?: string;
    }>();
    private employeeHours: Record<string, number> = {};
    private totalHours = {};
    private locationPerHoliday: Record<number, any> = {};
    private employeePerLocation: Record<number, number> = {};
    private destroy$ = new Subject();

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        public timeService: TimeService,
        public dialog: MatDialog,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _holidaysService: HolidaysService,
        private _teamsService: TeamsService,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('TIMEASSIGNMENT.TIMES');

        // Reset timeservice
        this.timeService.reset();
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.timeService.reset();
        this.timeService.timeAdded.unsubscribe();
    }

    async ngOnInit() {
        this.route.params.subscribe(async (params) => {
            this.timeService.reinit
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.timeService.restore());

            this.timeService.getUserDailyHours().subscribe(hours => this.employeeHours = hours);
            this.id = +params.id;
            this.timeService.setMaximumUserHoursPerDayLoading(true);
            await this._holidaysService.loadHolidays();
            this.timeService.calculateUserDailyHoursLimit();

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id) {
                const assignment = await this._sharepointService.getItemById({ listTitle: 'Aufträge', id: this.id });

                this.sharePointItem = assignment;
                this.itemTitle = this.sharePointItem.Title;

                if (this.sharePointItem.Projektnummer) {
                    this.itemTitle =
                        this.sharePointItem.Title +
                        ' (' +
                        this._translateService.instant(
                            'TIMEASSIGNMENT.PROJECTNUMBER'
                        ) +
                        ': ' +
                        this.sharePointItem.Projektnummer +
                        ')';
                }

                // Check if currentUser is Manager
                this.isManager = this.sharePointItem.AuftragsleiterId == this._sharepointService.currentUser.Id ||
                    this._sharepointService.currentUser.IsBusinessOwner ||
                    this._currentUserService.isTeamProjectLeiter()

                // Set timelevel
                this.timeService.level = Timelevel.Project;

                // Set projectID
                this.timeService.projectId = this.sharePointItem.Id;

                // Init timetable
                this.timeService.initTimeTable(
                    this.isManager,
                    this.id + ''
                );

                this.timeService.tableConfig.customButtons = [
                    {
                        name: 'Löschen',
                        icon: 'delete',
                    },
                ];
                this.timeService.projectNumber =
                    await this.timeService.getProjectNumber(this.id);
                this.timeConfig = {...this.timeService.tableConfig};
                this.timeConfig.recursiveAll = false;

            } else {
                this.isManager = this._sharepointService.currentUser.IsBusinessOwner || this._currentUserService.isTeamProjectLeiter();
            }

            await this.setupGridTab();
        });
    }

    private async setupGridTab() {
        this.monthAllData = [];
        this.totalHours = {}

        // get time data
        const timeItems = await this._sharepointService.getListItems({
            title: 'Zeiten',
            isDocumentLibrary: false,
            camlQuery: this.isManager ? this.timeService.camlQueryAll : this.timeService.camlQueryCurrentUser,
            folderName: this._applicationService.currentProjectId + '',
        });

        // process total work time by employee per year and month and day
        for (const [index, timeItem] of timeItems.entries()) {
            const dataIndex = this.monthAllData.findIndex(x =>
                x.employeeId === timeItem.AuthorId &&
                x.year === new Date(timeItem.Datum).getFullYear() &&
                x.month === new Date(timeItem.Datum).getMonth()
            );
            const hours = timeItem.Minuten / 60;
            if (dataIndex != -1) {
                const dayKey = 'day' + new Date(timeItem.Datum).getDate();
                const dayHours = this.monthAllData[dataIndex][dayKey];
                if (dayHours){
                    this.monthAllData[dataIndex][dayKey] = Number(dayHours) + hours;
                } else {
                    this.monthAllData[dataIndex][dayKey] = hours;
                }
            } else {
                const entry = {
                    'employeeId': timeItem.AuthorId,
                    'employeeTitle': timeItem.FieldValuesAsText.Author,
                    'year': new Date(timeItem.Datum).getFullYear(),
                    'month': new Date(timeItem.Datum).getMonth(),
                };
                const dayKey = 'day' + new Date(timeItem.Datum).getDate();
                entry[dayKey] = hours;
                this.monthAllData.push(entry);
            }

            const key = `${timeItem.AuthorId}_${new Date(timeItem.Datum).getMonth()}_${new Date(timeItem.Datum).getFullYear()}`;
            if(!this.totalHours[key]) {
                this.totalHours[key] = 0;
            }
            this.totalHours[key] = Number(this.totalHours[key]) + hours;
        }

        await this.initHolidaysPerUser();
        this.initUserTotalHours();

        // create all columns for the current month
        var today = new Date();
        var daysInCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const monthColumns = [];
        for (let i = 1; i <= daysInCurrentMonth; i++) {
            monthColumns.push({
                internalName: 'day' + i.toString(),
                title: i.toString(),
                hidden: false,
                type: ColumnTyp.Text,
                width: '45px',
            });
        }

        // get entries of today
        const processedTimeDataB = this.monthAllData.filter(entry => {
            Object.keys(entry).forEach(key => {
                if (key.startsWith('day')) {
                    if (!entry.total) {
                        entry.total = 0;
                    }

                    entry.total = toFixed(entry.total + Number(entry[key]), 2);
                }
            });
            return entry.year === today.getFullYear() && entry.month === today.getMonth()
        });

        // add employee column
        monthColumns.push({
            internalName: 'employeeTitle',
            title: "Mitarbeiter",
            hidden: true,
            type: ColumnTyp.Text,
        });

        // add employee column with hours
        monthColumns.push({
            internalName: 'employeeTitleWithHours',
            title: "Mitarbeiter",
            hidden: true,
            type: ColumnTyp.Text,
        });

        monthColumns.unshift({
            internalName: 'total',
            title: "Σ",
            hidden: false,
            type: ColumnTyp.Text,
            width: '80px',
        });

        const tableAggregates = Array(31).fill(1).map((value, index) => ({ field: `day${index + 1}`, aggregate: 'sum' }));
        // setup config
        this.monthConfig = {
            list: '-',
            groups: [{ field: 'employeeTitleWithHours', aggregates: tableAggregates }],
            columns: monthColumns,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: true,
            data: processedTimeDataB,
            useMaterialDesign: true,
        };

        // setup filter config
        this.formioFilterConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'TIMEASSIGNMENT.FILTER.YEAR'
                                    ),
                                    type: 'textfield',
                                    key: 'year',
                                    hidden: false,
                                    defaultValue: new Date().getFullYear()
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'TIMEASSIGNMENT.FILTER.MONTH'
                                    ),
                                    type: 'select',
                                    key: 'months',
                                    defaultValue: new Date().getMonth() + 1,
                                    data: {
                                        values: ASSIGNMENT_TIME_MONTH_FILTER.map(item => ({
                                            ...item,
                                            label: this._translateService.instant(item.label)
                                        })),
                                    }
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'TIMEASSIGNMENT.FILTER.EMPLOYEE'
                                    ),
                                    type: 'textfield',
                                    key: 'employee',
                                    hidden: !this.isManager,
                                },
                            ],
                            type: 'column',
                            key: 'column3',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
            ],
        };

        this.subscribeFilterChange();
    }

    onNavChange() {}

    onTooolbarButtonClick(e) {
        this.deleteMode = true;
        this.openDialog(e.dataItem);
    }

    openDialog(item, rowClick?: boolean): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            id: item.Id,
            item: item,
            editMode: true,
            disableProject: true,
        };

        if (!this.deleteMode && rowClick) {
            this.timeService.preserve();
            const dialogRef = this.dialog.open(NewTimeComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(async (result) => {
                this.timeService.restore();
                this.newTimeComponent.setValues();
                if (!result && !this.timeService.timeUpdated) {
                    return;
                }
                // Refresh table
                this.timeGrid.refresh();
                this.timeService.timeUpdated = false;

                await this.setupGridTab();
            });
        } else if (this.deleteMode && !rowClick) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    confirmMessage: this._translateService.instant(
                        'TIMEASSIGNMENT.NOTIFICATIONS.PERMISSION'
                    ),
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.deleteMode = false;
                if (result) {
                    this.timeService
                        .deleteTimeItem(item)
                        .then(async () => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'TIMEASSIGNMENT.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                            this.timeGrid.refresh();

                            await this.setupGridTab();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            });
        }
    }

    public onFormioFilterChange(ev) {
        console.warn('onFormioChange', ev);

        if (!ev.data)
            return;

        this.filterChangeEmitter.emit({
            year: ev.data.year,
            month: ev.data.months,
            employee: ev.data.employee,
        });
    }

    private subscribeFilterChange() {
        this.filterChangeEmitter
            .asObservable()
            .pipe(
                debounceTime(0),
                switchMap((value) => {
                    // if (value.length < 3) {
                    //   return of();
                    // }

                    return of(value);
                })
            )
            .subscribe(
                data => {

                    const processedTimeData = this.monthAllData.filter(mad =>
                        mad.year === Number.parseInt(data.year) &&
                        mad.month === data.month-1 &&
                        (data.employee ? mad.employeeTitle.toLowerCase().includes(data.employee.toLowerCase()) : true)
                    );

                    const monthColumns = [];
                    const daysInCurrentMonth = new Date(Number.parseInt(data.year), data.month, 0).getDate();
                    for (let i = 0; i < daysInCurrentMonth; i++) {
                        const date = new Date(Number.parseInt(data.year), data.month - 1, i + 1);
                        this.weekends[i] = date.getDay() === 6 || date.getDay() === 0;
                        monthColumns.push({
                            internalName: `day${i + 1}`,
                            title: `${i + 1}`,
                            hidden: false,
                            type: ColumnTyp.Text,
                            width: '45px',
                        });
                    }

                    const activeColumns =  this.monthGrid.activeColumns.filter(column => !column.internalName.startsWith('day'));
                    activeColumns.splice(1, 0, ...monthColumns);
                    this.monthGrid.updateActiveColumns(activeColumns);
                    this.monthConfig.data = processedTimeData;
                    this.monthGrid.refresh();
                },
                error => {
                    console.error(error);
                }
            );
    }

    private initUserTotalHours(): void {
        this.monthAllData.forEach(item => {
            const holidays = this._holidaysService.holidaysPerLocation[this.employeePerLocation[item.employeeId]];
            const hours = DateUtil.getBusinessHoursWithHolidays(
                Number.parseInt(item.year, 10),
                item.month,
                this.employeeHours[item.employeeId] ?? 8,
                holidays
            );

            item.holidays = [];
            holidays?.forEach(holiday => {
                if (holiday.Date.getFullYear() === item.year && holiday.Date.getMonth() === item.month) {
                    item.holidays[holiday.Date.getDate() - 1] = 1;
                }
            });

            const actualTime = toFixed(this.totalHours[`${item.employeeId}_${item.month}_${item.year}`], 2).toLocaleString();
            const targetTime = toFixed(hours, 2).toLocaleString()
            item.employeeTitleWithHours = `${item.employeeTitle} ${actualTime} / ${targetTime}`;
        });
    }

    private async initHolidaysPerUser(): Promise<void> {
        const userIds = [...new Set(this.monthAllData.map(item => item.employeeId))];
        const usersGroups = userIds.map(userId => this._sharepointService.web.siteUsers.getById(userId).groups())
        const userGroups = await Promise.all(usersGroups);
        const teams = await this._teamsService.loadAllTeams();
        userGroups.forEach((_userGroups, index) => {
            const groupIds: number[] = _userGroups.map(group => group.Id);
            const team = teams.find(team => groupIds.includes(team.GruppeId) && team.StandortId && team.AvailableForCalculation);
            this.employeePerLocation[userIds[index]] = team?.StandortId;
        });
    }
}


