export const locale = {
    lang: 'de',
    data: {
        DOCUMENTASSIGNMENT: {
            PROJECTS: 'Projekte',
            BASE: 'Stammdaten',
            DOCS: 'Dokumente',
            TASKDOCS: 'Aufgabendokumente',
            INVOLVED: 'Beteiligte',
            TASKS: 'Arbeitspakete',
            KANBAN: 'Kanban',
            NOTES: 'Notizen',
            NEW: 'Dokumente hochladen',
            NEWFOLDER: 'Neuer Ordner',
            PROJECTNUMBER: 'Projektnummer',
            JUMP: 'Zum SharePoint Ordner',
            JUMPTASK: 'Zum SharePoint Ordner',
            FILESERVER: 'Zum Netzlaufwerk',
            CONNECT: 'Netzlaufwerk verbinden',
            MOVE: 'Element verschieben',
            COLUMNS: {
                TITLE: 'Titel',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
                TASK: 'Arbeitspaket/Aufgabe',
            },
            TABS: {
                PROJECT: 'Projektdokumente',
                TASKS: 'Aufgabendokumente',
                ORDER: 'Auftragsdokumente',
                REQUEST: 'Anfragendokumente',
            },
            NOTIFICATIONS: {
                UPLOAD: 'Dokument wird hochgeladen...',
                ADDINGFOLDER: 'Ordner wird erstellt...',
                SUCCESS: 'Datei erfolgreich hochgeladen.',
                SUCCESSFOLDER: 'Ordner erfolgreich erstellt.',
                ERROR: 'Fehler beim hochladen: ',
                UPLOADMULTI: 'Dokumente werden hochgeladen...',
                SUCCESSMULTI: 'Dokumente erfolgreich hochgeladen.',
                ERRORMULTI: 'Fehler beim hochladen: ',
                WAIT: 'Bitte warten.',
                MISSINGPATH: 'Kein Pfad zum Fileserver hinterlegt',
                CONNECTING: 'Verbindung wird hergestellt',
                MISSINGSTART: 'Kein Startdatum eingetragen',
                SUCCESS_DELETE: 'Datei/Ordner erfolgreich gelöscht.',
            },
        },
    },
};
