import { DetailControllingComponent } from './../detail-controlling/detail-controlling.component';
import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { ColumnTyp } from 'sp-office365-framework';
import {
    ListViewConfig,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';

@Component({
    selector: 'app-listview-controlling',
    templateUrl: './listview-controlling.component.html',
    styleUrls: ['./listview-controlling.component.scss'],
})
export class ListviewControllingComponent {
    public config: ListViewConfig;
    @ViewChild('listView', { static: false }) listView: ListViewComponent;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.initConfig();
    }

    get allEntrysCamlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Title')
            .ToString();
    }

    get allEntrysSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    openDialog(itemId?): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.5 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            id: itemId,
        };

        const dialogRef = this.dialog.open(
            DetailControllingComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            //Refresh table
            this.listView._listViewService.onListViewDataChanged.next(
                this.listView._listViewService.listView
            );
        });
    }

    initConfig() {
        this.config = {
            title: this._translateService.instant('TARGETFIGURES'),
            searchLabel: this._translateService.instant('SEARCH'),
            icon: 'assessment',
            add_icon: 'add',
            onAddClick: () => {
                this.openDialog();
            },
            openEditInDialog: false,
            onRowClick: (item) => {
                this.openDialog(item.Id);
            },
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_ASSIGNMENT.ALL'),
                    config: {
                        list: 'SollZahlen',
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Team',
                                title: this._translateService.instant('Team'),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Monat',
                                title: this._translateService.instant('MONTH_'),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Jahr',
                                title: this._translateService.instant(
                                    'FORM.YEAR'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Soll_Wert',
                                title: this._translateService.instant(
                                    'TABS.NUMBERS'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: true,
                        camlQuery: this.allEntrysCamlQuery,
                    },
                    camlQueryForSearch: this.allEntrysSearchCamlQuery,
                },
            ],
        };
    }
}
