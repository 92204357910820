import { MatToolbarModule } from '@angular/material/toolbar';
import { AddUserComponent } from './add-user.component';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [AddUserComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        MatToolbarModule,
        FormioModule,
        DragDropModule,
    ],
    exports: [AddUserComponent],
})
export class AddUserModule {}
