import { FrameworkService } from 'sp-office365-layout';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1147Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {
    }

    async run() {
        try {
            await this._updateService.addList('ArbeitspaketesetsTemplate', '', 100, {
                EnableVersioning: true,
            });
            const workPackageSetList = this._sharePointService.web.lists.getByTitle('ArbeitspaketesetsTemplate');
            const arbeitspaketesetsList = this._sharePointService.web.lists.getByTitle('Arbeitspaketesets');
            await this._updateService.addLookupField(
                workPackageSetList,
                arbeitspaketesetsList,
                'Arbeitspaketesets',
                true
            );
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
