import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableTasktemplateComponent } from './table-tasktemplate.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { DetailTasktemplateComponent } from '../detail-tasktemplate/detail-tasktemplate.component';
import { DetailTasktemplateModule } from '../detail-tasktemplate/detail-tasktemplate.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    declarations: [TableTasktemplateComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        TableTaskModule,
        DetailTasktemplateModule,
    ],
    exports: [TableTasktemplateComponent]
})
export class TableTasktemplateModule {}
