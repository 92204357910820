import { SharepointEntrysService } from './../../services/sharepoint-entrys.service';

import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ColumnTyp, FilterTypes, FormatTypes, SharePointService } from 'sp-office365-framework';
import { fuseAnimations, FuseTranslationLoaderService, ListViewConfig, SnackbarService } from 'sp-office365-layout';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';

import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { BillingImportService } from 'src/app/main/services/billing-import.service';
import { ImportBillsDialogComponent } from 'src/app/main/bills/import-bills-dialog/import-bills-dialog.component';
import { BillingAttachmentsService } from '../../services/billing-attachments.service';
import { UploadBillsPdfAttachmentsDialogComponent } from '../upload-bills-pdf-attachments-dialog/upload-bills-pdf-attachments-dialog.component';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
  selector: 'app-listview-bills',
  templateUrl: './listview-bills.component.html',
  styleUrls: ['./listview-bills.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ListviewBillsComponent implements AfterViewInit, OnDestroy {
  public config: ListViewConfig;
  public isManager = false;
  public subscription: Subscription;

  @ViewChild('listView', { static: false }) listView: ListViewComponent;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('pdfInput', { static: false }) pdfInput: ElementRef;

  constructor(
      public dialog: MatDialog,
      public _sharepointEntrysService: SharepointEntrysService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private _translateService: TranslateService,
      private _sharepointService: SharePointService,
      private _billingImportService: BillingImportService,
      private _billingAttachmentsService: BillingAttachmentsService,
      private _snackBarService: SnackbarService,
      private _currentUserService: CurrentUserService
  ) {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
      if (this._sharepointService.currentUser.IsBusinessOwner || this._currentUserService.isTeamProjectLeiter()) {
          this.isManager = true;
      }

      //Necessary to show the right entrys when the Component loads for the first time
      this._sharepointEntrysService.monthTable = this._sharepointEntrysService.getCurrentMonth();
      this._sharepointEntrysService.yearTable = this._sharepointEntrysService.getCurrentYear();
      this.initConfig();
  }

  ngAfterViewInit(): void {
      this._billingAttachmentsService.startQueue();
      this._sharepointEntrysService.currentListView = this.listView;
      this._sharepointEntrysService.currentListView.changeQuery(
          this._sharepointEntrysService.filteredEntrysForTable
      );
  }

  initConfig() {
      this.config = {
          title: this._translateService.instant('LV_BILLS.BILLS'),
          searchLabel: this._translateService.instant('LV_BILLS.SEARCH_BILLS'),
          icon: 'monetization_on',
          add_icon: 'add',
          openEditInDialog: false,
          tableConfigs: [
              {
                  title: this._translateService.instant('LV_BILLS.ALLTIMES'),
                  config: {
                      list: 'Rechnungen',
                      columns: [
                          {
                              internalName: 'Projekt_x003a_Title',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.PROJECTTITLE'),
                              hidden: false,
                              type: ColumnTyp.Text
                          },
                          {
                              internalName: 'Projekt_x003a_Auftragstitel',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.PROJECTTITLE'),
                              hidden: true,
                              type: ColumnTyp.Text
                          },
                          {
                              internalName: 'Projekt_x003a_Projektnummer',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.PROJECTNUMBER'),
                              hidden: false,
                              type: ColumnTyp.Text
                          },
                          {
                              internalName: 'Title',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.TITLE'),
                              hidden: false,
                              type: ColumnTyp.Text
                          },
                          {
                              internalName: 'Rechnungsdatum',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.DATE'),
                              hidden: false,
                              type: ColumnTyp.Date,
                              filter: FilterTypes.date,
                              format: FormatTypes.yyyyMMdd
                          },
                          {
                            internalName: 'Jahr',
                            title: this._translateService.instant('LV_BILLS.COLUMNS.YEAR'),
                            hidden: true,
                            type: ColumnTyp.Text
                          },
                          {
                            internalName: 'Monat',
                            title: this._translateService.instant('LV_BILLS.COLUMNS.MONTH'),
                            hidden: true,
                            type: ColumnTyp.Text
                          },
                          {
                            internalName: 'Team',
                            title: this._translateService.instant('LV_BILLS.COLUMNS.TEAM'),
                            hidden: false,
                            type: ColumnTyp.Lookup
                          },
                          {
                              internalName: 'Forderung',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.NETTO'),
                              hidden: false,
                              type: ColumnTyp.Text
                          },
                          {
                              internalName: 'Rechnungsnummer',
                              title: this._translateService.instant('LV_BILLS.COLUMNS.BILL_NUMBER'),
                              hidden: false,
                          },
                          { internalName: 'ID', title: 'ID', hidden: true },
                      ],
                      showEditButton: false,
                      showDeleteButton: true,
                      addCheckboxColumn: false,
                      recursiveAll: true,
                      useMaterialDesign: true,
                      toolbar: [],
                      isDocumentLibrary: false,
                      camlQuery: this._sharepointEntrysService.filteredEntrysForTable
                  },
                  camlQueryForSearch: this._sharepointEntrysService.filteredEntrysForTable,
              },
          ],
      };
  }

  public selectBillsFileHandler(event: Event): void {
      const file = (event.target as HTMLInputElement).files[0];
      this._billingImportService.importBillings(file)
          .subscribe(excelBills => {
              if (!excelBills.length) {
                  this.fileInput.nativeElement.value = '';
                  this._snackBarService.add(
                      this._translateService.instant('LV_BILLS.NOTIFICATIONS.IMPORT_NO_BILLS'),
                      '',
                      {
                          duration: 4000,
                          panelClass: 'error-dialog',
                      }
                  );
                  return;
              }

              this.dialog.open(ImportBillsDialogComponent, { data: excelBills, disableClose: true, maxHeight: '80vh' })
                  .afterClosed()
                  .subscribe(() => {
                      this.fileInput.nativeElement.value = '';
                      this.listView.refresh();
                  });
          });
  }

  public selectPDFBillAttachmentHandler(event: Event): void {
      const files = (event.target as HTMLInputElement).files;

      if (!files.length) {
          return;
      }

      this.dialog.open(UploadBillsPdfAttachmentsDialogComponent);
      this._billingAttachmentsService.uploadBillAttachments(files)
          .subscribe(() => this.pdfInput.nativeElement.value = null);
  }

  ngOnDestroy(): void {
      this._billingAttachmentsService.stopQueue();
  }
}
