import { IList } from '@pnp/sp/presets/all';
import { SharePointService } from 'sp-office365-framework';

export class UpdateToVersion1108Component {
  constructor(
    private _sharePointService: SharePointService,
  ) {}

  run() {
    const promise = new Promise<void>(async (resolve, reject) => {
      const propertyList: IList =
        this._sharePointService.web.lists.getByTitle('Properties');

      const promises = [
        // Add Item to List Properties
        await propertyList.items.add({
          Title: 'Usergruppen',
          Value: '',
        }),
      ];

      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
    return promise;
  }
}
