import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormioComponent } from '@formio/angular';
import { SharepointEntrysService } from 'src/app/main/services/sharepoint-entrys.service';
import { ApplicationService } from 'src/app/main/services/application.service';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from 'src/app/main/services/lookup.service';
import { FrameworkService } from 'sp-office365-layout';
import CamlBuilder from 'camljs';
import { SharePointService } from 'sp-office365-framework';
import { ProjectEvaluationService } from 'src/app/main/project-evaluation/project-evaluation.service';
import {
    PROJECT_EVALUATION_FILTER_MONTHS,
    PROJECT_EVALUATION_FILTER_QUARTERS
} from 'src/app/main/project-evaluation/project-evaluation.constant';

@Component({
    selector: 'app-project-evaluation-filter',
    templateUrl: './project-evaluation-filter.component.html',
    styleUrls: ['./project-evaluation-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectEvaluationFilterComponent implements OnInit {
    public formio_renderOptions = { 
        // language: 'de' 
    };
    public refreshForm: EventEmitter<any> = new EventEmitter();
    public formioConfiguration;
    public isManager = false;

    @ViewChild('formioComponent', { static: false }) formioComponent: FormioComponent;

    constructor(
        private _sharepointEntrysService: SharepointEntrysService,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService,
        private _lookupService: LookupService,
        private _frameworkService: FrameworkService,
        private _sharePointService: SharePointService,
        private _projectEvaluationService: ProjectEvaluationService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    public formioReady(): void {
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(this.formioConfiguration.components, emptyObj);
        const currentDate = new Date();
        let dataForm = emptyObj;
        dataForm.Hotkeys = 'Monat';
        dataForm.month = -1;
        dataForm.quarter = -1;
        dataForm.year = currentDate.getFullYear();
        dataForm.closedProjectsHidden = this._projectEvaluationService.closedProjectsHidden;
        dataForm.internalProjectsHidden = this._projectEvaluationService.internalProjectsHidden;

        this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm)
            .then(() => this.refreshForm.emit({ submission: { data: dataForm } }))
            .catch((error) => {
                throw error;
            });
    }

    public onChange(event): void {
        if (event.type === 'change') {
            return;
        }

        const promises = [];
        if (event.add) {
            if (event['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = event.Id;
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getListItems({
                                title: 'Teams',
                                isDocumentLibrary: false,
                                camlQuery: new CamlBuilder()
                                    .Where()
                                    .LookupField('Standort')
                                    .Id()
                                    .EqualTo(event.Id)
                                    .ToString(),
                            })
                            .then((teams) => {
                                const teamId: number[] = [];
                                teams.forEach((team) => teamId.push(team.Id));
                                this._lookupService.teamsFromLocation = [...teamId];
                                resolve();
                            })
                            .catch((err) => {
                                console.log(err);
                                reject();
                            });
                    })
                );
            } else {
                this._lookupService.teams.push(event.Id);
                this._lookupService.locationLookUpId = event.StandortId;
            }
        } else if (event.remove) {
            if (event['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
            } else {
                this._lookupService.teams = this._lookupService.teams
                    .filter((value) => value != event.Id);
                this._lookupService.locationLookUpId = null;
            }
        }

        if (event.changed) {
            switch (event.changed.component.key) {
                case 'month':
                    this._projectEvaluationService.currentMonth = event.changed.value;

                    if (this._projectEvaluationService.currentMonth > -1) {
                        this._projectEvaluationService.currentQuarter = -1;
                        this._frameworkService
                            .getFormioComponentByKey(this.formioComponent.formio.components, 'quarter').setValue(-1);
                    }

                    break;
                case 'year':
                    this._projectEvaluationService.currentYear = event.changed.value || 0;
                    break;
                case 'quarter':
                    this._projectEvaluationService.currentQuarter = event.changed.value || 0;

                    if (this._projectEvaluationService.currentQuarter > -1) {
                        this._projectEvaluationService.currentMonth = -1;
                        this._frameworkService
                            .getFormioComponentByKey(this.formioComponent.formio.components, 'month').setValue(-1);
                    }

                    break;

                case 'closedProjectsHidden':
                    this._projectEvaluationService.closedProjectsHidden = event.changed.value;
                    break;
                case 'internalProjectsHidden':
                    this._projectEvaluationService.internalProjectsHidden = event.changed.value;
                    break;
            }
        }

        Promise.all(promises)
            .then(() => {
                this._projectEvaluationService.filterProjects();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private initForm(): void {
        this.formioConfiguration = {
            components: [
                //Reihe Monat bis und Jahr bis
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.MONTH.UNTIL'
                                    ),
                                    type: 'select',
                                    key: 'month',
                                    data: {
                                        values: PROJECT_EVALUATION_FILTER_MONTHS
                                            .map(item => ({
                                                ...item,
                                                label: item.label ? this._translateService.instant(item.label) : ''
                                            })),
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.QUARTER.TITLE'
                                    ),
                                    type: 'select',
                                    key: 'quarter',
                                    data: {
                                        values: PROJECT_EVALUATION_FILTER_QUARTERS
                                            .map(item => ({
                                                ...item,
                                                label: item.label ? this._translateService.instant(item.label) : ''
                                            })),
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.UNTIL_YEAR'
                                    ),
                                    type: 'number',
                                    key: 'year',
                                    hidden: false,
                                    customClass: 'year-filter'
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                //ANCHOR 3.Zeile Standort und Teams
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                    this._lookupService.locationCAMLQuery,
                                    searchQuery:
                                    this._lookupService
                                        .locationSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);
                                        this._projectEvaluationService.currentLocation = data;

                                        this.updateCamlQuery('TeamId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);
                                        this._projectEvaluationService.currentLocation = null;
                                        this.updateCamlQuery('TeamId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.TEAM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'TeamId',
                                    list: 'Teams',
                                    searchQueryIfIsEmpty:
                                    this._lookupService.teamCAMLQuery,
                                    searchQuery:
                                    this._lookupService.teamSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    multi: true,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);
                                        this._projectEvaluationService.currentTeam = data;
                                        this.updateCamlQuery('StandortId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);
                                        this._projectEvaluationService.currentTeam = null;
                                        this.updateCamlQuery('StandortId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns2',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.HIDECLOSEDPORJECTS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'checkbox',
                                    input: true,
                                    key: 'closedProjectsHidden',
                                    defaultValue: false
                                },
                            ],
                            width: 2,
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'PROJECTEVALUATION.FILTER.HIDE999PROJECTS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'checkbox',
                                    input: true,
                                    key: 'internalProjectsHidden',
                                    defaultValue: false
                                },
                            ],
                            width: 1,
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns7',
                },
            ],
        };
    }

    updateCamlQuery(key: 'TeamId' | 'StandortId'): void {
        let camlQuery;
        let searchCaml;

        camlQuery =
            key == 'StandortId'
                ? this._lookupService.locationCAMLQuery
                : this._lookupService.teamCAMLQuery;
        searchCaml =
            key == 'StandortId'
                ? this._lookupService.locationSearchCAMLQuery
                : this._lookupService.teamSearchCAMLQuery;

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQueryIfIsEmpty',
            camlQuery
        );

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQuery',
            searchCaml
        );
    }
}
