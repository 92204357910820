export const locale = {
    lang: 'en',
    data: {
        CONFIRM_DIALOG: {
            TITLE: 'Wählen Sie einen Standort',
            OK: 'OK',
            CANCEL: 'Cancel',
        },
    },
};
