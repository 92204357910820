import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    ColumnTyp,
    FormatTypes,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { DocTemplatesService } from '../services/doc-templates.service';
import { DocumentsAssignmentService } from '../assignment/documents-assignment/documents-assignment.service';
import { LoadingService } from '../services/loading.service';

@Component({
    selector: 'app-documenttemplate',
    templateUrl: './documenttemplate.component.html',
    styleUrls: ['./documenttemplate.component.scss'],
})
export class DocumenttemplateComponent implements OnInit {
    public tableConfig: TableConfiguration;
    public columns: TableColumn[];

    private itemOptions: {
        itemId: number;
        listName: string;
        lookupFieldName?: string;
    }[];
    private docOptions: { docListName: string; docLFolderName: string };
    private fieldValues: any;
    private category: string;

    @ViewChild('templateGrid', { static: false })
    templateGrid: SharePointTableComponent;

    get camlQueryAll() {
        if (this.category) {
            return new CamlBuilder()
                .Where()
                .ChoiceField('Kategorie')
                .EqualTo(this.category)
                .Or()
                .ChoiceField('Kategorie')
                .EqualTo('Alle')
                .OrderByDesc('Modified')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .ChoiceField('Kategorie')
                .EqualTo('Alle')
                .OrderByDesc('Modified')
                .ToString();
        }
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<DocumenttemplateComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _docTemplateService: DocTemplatesService,
        private _documentService: DocumentsAssignmentService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        if (this.data.itemOptions) {
            this.itemOptions = this.data.itemOptions;
        }

        if (this.data.docOptions) {
            this.docOptions = this.data.docOptions;
        }

        if (this.data.fieldValues) {
            this.fieldValues = this.data.fieldValues;
        }

        if (this.data.category) {
            this.category = this.data.category;
        }

        this.initGrid();
    }

    initGrid() {
        this.columns = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DOCUMENTTEMPLATE.COLUMNS.TITLE'
                ),
                hidden: true,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'Name',
                title: this._translateService.instant(
                    'DOCUMENTTEMPLATE.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DOCUMENTTEMPLATE.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DOCUMENTTEMPLATE.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        let toolbar = [];

        this.tableConfig = {
            list: 'Dokumentenvorlagen',
            pageSize: 20,
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: false,
            camlQuery: this.camlQueryAll,
            folderName: '',
            useMaterialDesign: true,
            addCheckboxColumn: true,
            disableCellClick: true,
            afterDeleteItem: () => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESS_DELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
            },
            toolbar,
            afterRefreshTable: () => {},
        };
    }

    async createDocuments() {
        try {
            for (const element of this.templateGrid.itemSelected) {
                if (element.dataItem.FileRef) {
                    this._loadingService.show(
                        this._translateService.instant(
                            'DOCUMENTTEMPLATE.NOTIFICATIONS.CREATE'
                        ),
                        this._translateService.instant(
                            'DOCUMENTTEMPLATE.NOTIFICATIONS.WAIT'
                        )
                    );

                    let elementIds = '';
                    this.itemOptions.forEach((element) => {
                        if (element.lookupFieldName) {
                            elementIds +=
                                element.listName +
                                '#' +
                                element.itemId +
                                '#' +
                                element.lookupFieldName +
                                ';';
                        } else {
                            elementIds +=
                                element.listName + '#' + element.itemId + ';';
                        }
                    });

                    let result = await this._docTemplateService.createDocument({
                        templateUrl: element.dataItem.FileRef,
                        elementIds,
                    });

                    if (!result) {
                        return;
                    }

                    const blob = new Blob([result], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });

                    // blob to file
                    const file = new File(
                        [blob],
                        this._docTemplateService.getFileNameFromUrl(
                            element.dataItem.FileRef
                        )
                    );

                    await this._documentService.uploadDocumentsAndSetValues(
                        file,
                        this.docOptions.docListName,
                        this.docOptions.docLFolderName,
                        this.fieldValues
                    );
                }
            }

            this._loadingService.hide();

            this.close();

            return;
        } catch (error) {
            this._loadingService.hide();
            throw error;
        }
    }

    close() {
        this.matDialogRef.close();
    }
}
