import { RequestService } from '../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1128Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const mailboxList: IList =
                this._sharePointService.web.lists.getByTitle('Mailbox');
            const mailboxApplicationList: IList =
                this._sharePointService.web.lists.getByTitle('Mailbox-Anträge');

            // Add Field SentDateTime to List Mailbox
            await this._updateService.addField(
                mailboxList,
                'SentDateTime',
                FieldType.Date
            );

            // Add Field SentDateTime to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxApplicationList,
                'SentDateTime',
                FieldType.Date
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
