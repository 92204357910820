<div class="page-layout carded left-sidebar tabbed inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT HEADER -->
        <div
            class="header accent p-6"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="h2 mt-4">
                    <mat-icon
                        class="logo-icon mr-4"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    >assignment
                    </mat-icon>
                    <span
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        {{ 'LV_TIME.TITLE' | translate }}
                    </span>
                </div>
            </div>
        </div>
<!--        <button (click)="generateHours()">generate hours</button>-->
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <!-- CONTENT -->
            <div class="content card-content">
                <app-new-time #newTimeComponent></app-new-time>

                <mat-tab-group>
                    <mat-tab label="Liste">
                        <ng-template matTabContent>

                            <app-sharepoint-table
                              #timeGrid
                              *ngIf="timeConfig"
                              class="times-table"
                              [configuration]="timeConfig"
                              (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
                              (cellClick)="openDialog($event, true)">
                                <ng-template
                                    customCell
                                    for="Minuten"
                                    let-dataItem="dataItem">
                                    {{ (dataItem.Minuten / 60) | number : '1.0-2' }}
                                </ng-template>
                                <ng-template customCell for="Aufgabe" let-dataItem="dataItem">
                                    {{dataItem.Aufgabe}}
                                </ng-template>
                            </app-sharepoint-table>

                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Tabelle">
                        <ng-template matTabContent>
                            <formio [service]="this"
                                    class="formio-material"
                                    [form]="formioFilterConfiguration"
                                    [renderOptions]="formio_renderOptions"
                                    (change)="onFormioFilterChange($event)">
                            </formio>

                            <div class="hideColumnMenu">
                                <app-sharepoint-table #monthGrid
                                                      *ngIf="monthConfig"
                                                      class="month-table"
                                                      [configuration]="monthConfig">
                                    <ng-template customCell [for]="'total'" let-dataItem="dataItem">
                                        {{dataItem.total | number : '1.0-2'}}
                                    </ng-template>
                                    <ng-template customCell [for]="'projectTitle'" let-dataItem="dataItem">
                                        {{dataItem.projectTitle}}
                                    </ng-template>
                                    <ng-container *ngFor="let column of monthGrid.activeColumns;let index = index;">
                                        <ng-container *ngIf="!column.hidden">
                                            <ng-template customCell [for]="column.internalName" let-dataItem="dataItem">
                                                <div [class.day-off]="dataItem.holidays[index - 1]" [class.weekend]="weekends[index - 2]">
                                                    {{dataItem[column.internalName] | number : '1.0-2'}}
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </app-sharepoint-table>
                            </div>

                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
