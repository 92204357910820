import { NgModule } from '@angular/core';
import { MailsAssignmentComponent } from './mails-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    FuseSharedModule,
    FuseSidebarModule,
    LayoutModule,
} from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { DetailMailModule } from '../../mail/detail-mail/detail-mail.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { ResizableBoxModule } from '../../shared/components/resizable-box/resizable-box.module';
import { FilterMailModule } from '../../mail/filter-mail/filter-mail.module';

const routes = [
    {
        path: 'projects/detail/mails',
        component: MailsAssignmentComponent,
    },
    {
        path: 'projects/detail/mails/:id',
        component: MailsAssignmentComponent,
    },
];

@NgModule({
    declarations: [MailsAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatSnackBarModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        DetailMailModule,
        ResizableBoxModule,
        FilterMailModule
    ],
    exports: [MailsAssignmentComponent],
})
export class MailsAssignmentModule {}
