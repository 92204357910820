import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnTyp, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import { TranslateService } from '@ngx-translate/core';
import { BillingAttachmentsService } from '../../services/billing-attachments.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-upload-bills-pdf-attachments-dialog',
    templateUrl: './upload-bills-pdf-attachments-dialog.component.html',
    styleUrls: ['./upload-bills-pdf-attachments-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadBillsPdfAttachmentsDialogComponent implements OnInit {
    public tableConfig: TableConfiguration;
    private destroy$ = new Subject();
    @ViewChild('table', { static: false }) table: SharePointTableComponent;

    constructor(
        private _translateService: TranslateService,
        public billingAttachmentsService: BillingAttachmentsService
    ) {
    }

    ngOnInit(): void {
        this.initTableConfig();
        this.billingAttachmentsService.pdfAttachmentFiles$
            .pipe(
                takeUntil(this.destroy$),
                filter(data => !!data?.length)
            )
            .subscribe(data => {
                this.tableConfig.data = data;
                this.table?.refresh();
            });
    }

    private initTableConfig(): void {
        this.tableConfig = {
            list: '',
            columns: [
                {
                    internalName: 'attachment.name',
                    title: this._translateService.instant('UPLOAD_PDF_BILLS_DIALOG.FILENAME'),
                    hidden: false
                },
                {
                    internalName: 'billNumber',
                    title: this._translateService.instant('UPLOAD_PDF_BILLS_DIALOG.BILL_NUMBER'),
                    hidden: false
                },
                {
                    internalName: 'status',
                    title: this._translateService.instant('UPLOAD_PDF_BILLS_DIALOG.STATUS'),
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '270px'
                }
            ],
            showEditButton: false,
            showDeleteButton: false,
            useMaterialDesign: true,
            toolbar: [
                {
                    title: 'Group',
                    command: 'group',
                },
                {
                    title: 'Ungroup',
                    command: 'Ungroup',
                }
            ],

            isDocumentLibrary: false,
            data: []
        };
    }

    public toolbarButtonHandler(event): void {
        if (event.command === 'group') {
            this.tableConfig.groups = [{ field: 'billNumber' }, { field: 'status' }];
        } else {
            this.tableConfig.groups = [];
        }

        this.table.refresh();
    }
}
