export const locale = {
    lang: 'de',
    data: {
        DOC_TEMPLATES: {
            TITLE: 'Dokumentenvorlagen',
            NEW: 'Vorlage hochladen',
            NOTIFICATIONS: {
                SAVING: 'Vorlage wird erstellt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Vorlage erfolgreich gespeichert.',
                SUCCESS_DOC: 'Dokument erfolgreich erstellt.',
                ERROR: 'Fehler beim speichern: ',
                NO_TEMPLATE_SELECTED: 'Keine Vorlage ausgewählt.',
                NO_ELEMENT_ID: 'Keine Element-ID angegeben.',
                COPY_SUCCESS: 'Inhalt wurde in die Zwischenablage kopiert.',
                SUCCESS_DELETE: 'Vorlage erfolgreich gelöscht.',
                CHANGE_CATEGORY: 'Kategorie wird geändert.',
                SUCCESS_CHANGE_CATEGORY: 'Kategorie erfolgreich geändert.',
            },
            COLUMNS: {
                TITLE: 'Name',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
                CATEGORY: 'Kategorie',
            },
        },
    },
};
