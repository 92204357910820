export const locale = {
    lang: 'de',
    data: {
        NEW_TIME: {
            UPDATE: 'Zeiteintrag bearbeiten',
            COLUMNS: {
                PROJECT: 'Projekt',
                TASK: 'Aufgabe/Arbeitspaket',
                DATE: 'Datum',
                DESCRIPTION: 'Beschreibung',
                COSTSPERHOUR: 'Stundensatz in €',
            },
            BOOKEDTIMESFORSELECTEDDATE1:
                'Für das gewählte Datum haben Sie bereits ',
            BOOKEDTIMESFORSELECTEDDATE2: ' Stunde(n) gebucht.',
            BOOKEDTIMESFORSELECTEDDATE3: ' von ',
            PROJECTSTATE:
                'Neue Zeiten können nur auf nicht abgeschlossene/gestoppte Projekte gebucht werden.',
        },
    },
};
