import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    adminNavigation,
    adminNavigationWithTimes,
    adminUpdateNavigation,
    adminUpdateNavigationWithTimes,
    controllingNavigationChild,
    navigation,
    navigationWithTimes,
    newProjectChild,
} from './navigation/navigation';
import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationGerman } from './navigation/i18n/de';
import {
    FrameworkService,
    FuseConfigService,
    FuseFooterService,
    FuseSidebarService,
    FuseSplashScreenService,
    FuseTranslationLoaderService,
    MailService,
    NavigationService,
} from 'sp-office365-layout';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { footer } from './footer/footer';
import { UpdateManagerService } from './main/administration/update/updateManager.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { CustomMessageService } from './main/shared/message.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { LookupService } from 'src/app/main/services/lookup.service';
import { CurrentUserService } from 'src/app/main/services/current-user.service';
import { ApplicationService } from './main/services/application.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    currentVersion: any;
    allowToCreateProject: boolean = false;
    isManager: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param {DateAdapter} _dateAdapter
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseFooterService: FuseFooterService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _dateAdapter: DateAdapter<any>,
        private _router: Router,
        private _sharePointService: SharePointService,
        private _updateManagerService: UpdateManagerService,
        private _updateService: UpdateService,
        private _kendoMessageService: MessageService,
        private _frameworkService: FrameworkService,
        private lookupService: LookupService,
        private currentUserService: CurrentUserService,
        private _mailService: MailService,
        private _applicationService: ApplicationService,
        private _navigationService: NavigationService
    ) {
        this.initTranslation();
        this._navigationService.set([]);

        // Register formio custom components
        this._frameworkService.registerFormioCustomComponents();
        this._mailService.parseEmailOnClick = true;

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        const promises = [];

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                try {
                    this.allowToCreateProject =
                        await this._applicationService.isUserInRole(
                            'Projektanleger'
                        );

                    resolve();
                } catch (error) {
                    reject(error);
                }
            })
        );

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                try {
                    this.isManager =
                        await this._applicationService.isUserInRole(
                            'Controlling'
                        );

                    resolve();
                } catch (error) {
                    reject(error);
                }
            })
        );

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                try {
                    this.currentVersion =
                        +(await this._updateService.getCurrentVersion());

                    resolve();
                } catch (error) {
                    reject(error);
                }
            })
        );

        Promise.all(promises)
            .then(() => {
                this.initNavigation().subscribe();
                this.initFooter();

                // Install App if Version <= 1000
                if (this.currentVersion <= 1000) {
                    this._router.navigate(['administration/update'], {
                        queryParamsHandling: 'merge',
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);

                // set custom logo and favicon
                if (config.layout.navbar.customLogo) {
                    this.initCustomLogo();
                }
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    initNavigation(): Observable<any> {
        return new Observable<void>((observer) => {
            this.regNavigation();

            // Set Navigation
            forkJoin([
                this._updateService.getPropertyValue('Zeiterfassung'),
                this.currentUserService.loadCurrentUserTeams(),
            ]).subscribe(
                ([value]) => {
                    if (value == 'Ja') {
                        // Show Times
                        if (
                            this._sharePointService.currentUser.IsBusinessOwner
                        ) {
                            // Show Admin
                            if (
                                this.currentVersion <
                                this._updateManagerService.latestVersion
                            ) {
                                // Show Update
                                // this._fuseNavigationService.setCurrentNavigation(
                                //     'adminUpdateTimes'
                                // );
                                this._navigationService.set(adminUpdateNavigationWithTimes);
                            } else {
                                // Hide Update
                                // this._fuseNavigationService.setCurrentNavigation(
                                //     'adminTimes'
                                // );
                                this._navigationService.set(adminNavigationWithTimes);
                            }
                        } else {
                            // Hide Admin
                            // this._fuseNavigationService.setCurrentNavigation(
                            //     'mainTimes'
                            // );
                            this._navigationService.set(navigationWithTimes);
                        }
                    } else {
                        // Hide Times
                        if (
                            this._sharePointService.currentUser.IsBusinessOwner
                        ) {
                            // Show Admin
                            if (
                                this.currentVersion <
                                this._updateManagerService.latestVersion
                            ) {
                                // Show Update
                                // this._fuseNavigationService.setCurrentNavigation(
                                //     'adminUpdate'
                                // );
                                this._navigationService.set(
                                    adminUpdateNavigation
                                );
                            } else {
                                // Hide Update
                                // this._fuseNavigationService.setCurrentNavigation(
                                //     'admin'
                                // );
                                this._navigationService.set(
                                    adminNavigation
                                );
                            }
                        } else {
                            // Hide Admin
                            // this._fuseNavigationService.setCurrentNavigation(
                            //     'main'
                            // );
                            this._navigationService.set(navigation);
                        }
                    }

                    // Check Role Projektanleger
                    if (this.allowToCreateProject) {
                        this._navigationService
                            .getCurrentNavigation()[0]
                            .children.splice(2, 0, newProjectChild);
                    }

                    // Check Role Controlling
                    if (this.isManager) {
                        const currentNavigation = this._navigationService.getCurrentNavigation()[0];

                        const insertIndex =
                        currentNavigation.children.findIndex(
                                (menu) => menu.id === 'customers'
                            );

                        if (insertIndex !== -1) {
                            currentNavigation.children.splice(
                                insertIndex,
                                0,
                                controllingNavigationChild
                            );
                        } else {
                            currentNavigation.children.push(
                                controllingNavigationChild
                            );
                        }
                    } else {
                        // Check if is Teamleiter
                        // this.addControllingMenuForTeamManager();
                    }

                    observer.next(undefined);
                    observer.complete();
                },
                () => {
                    // Set the navigation as our current navigation
                    if (this._sharePointService.currentUser.IsBusinessOwner || this.currentUserService.isTeamProjectLeiter()) {
                        // this._fuseNavigationService.setCurrentNavigation(
                        //     'admin'
                        // );
                        this._navigationService.set(adminNavigation);
                    } else {
                        // this._fuseNavigationService.setCurrentNavigation(
                        //     'main'
                        // );
                        this._navigationService.set(navigation);
                    }
                    observer.next(undefined);
                    observer.complete();
                }
            );
        });
    }

    regNavigation() {
        // Update Version in adminNavigation
        adminNavigation[0].children.find((x) => x.id == 'admin').badge.title =
            'V ' + this.currentVersion.toString().split('').join('.');
        adminNavigation[0].children.find(
            (x) => x.id == 'admin'
        ).badge.translate =
            'V ' + this.currentVersion.toString().split('').join('.');

        // Register the default navigation to the service
        // this._fuseNavigationService.register('admin', adminNavigation);
        // this._fuseNavigationService.register(
        //     'adminUpdate',
        //     adminUpdateNavigation
        // );
        // this._fuseNavigationService.register('main', navigation);

        // Update Version in adminNavigationWithTimes
        adminNavigationWithTimes[0].children.find(
            (x) => x.id == 'admin'
        ).badge.title =
            'V ' + this.currentVersion.toString().split('').join('.');
        adminNavigationWithTimes[0].children.find(
            (x) => x.id == 'admin'
        ).badge.translate =
            'V ' + this.currentVersion.toString().split('').join('.');

        // Register the navigation with times to the service
        // this._fuseNavigationService.register(
        //     'adminTimes',
        //     adminNavigationWithTimes
        // );
        // this._fuseNavigationService.register(
        //     'adminUpdateTimes',
        //     adminUpdateNavigationWithTimes
        // );
        // this._fuseNavigationService.register('mainTimes', navigationWithTimes);
    }

    initTranslation() {
        // Add languages
        this._translateService.addLangs(['en', 'de']);

        // ngxTranslate Fix
        setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('de');
        });

        // Load the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationEnglish,
            navigationGerman
        );

        // Get locale params
        const url = window.location.href;
        const params =
            url.indexOf('?') !== -1
                ? url
                      .slice(url.indexOf('?') + 1)
                      .split('&')
                      .reduce((params: any, param) => {
                          const [key, value] = param.split('=');
                          params[key] = value
                              ? decodeURIComponent(value.replace(/\+/g, ' '))
                              : '';
                          return params;
                      }, {})
                : {};

        if (params.SPLanguage) {
            // Use a language
            this._translateService.use(params.SPLanguage.split('-')[0]);

            // Set angular material date control language
            this._dateAdapter.setLocale(params.SPLanguage.split('-')[0]);

            // Set kendo translation
            const svc = this._kendoMessageService as CustomMessageService;
            svc.language = params.SPLanguage.split('-')[0];
        } else {
            // Use a language
            this._translateService.use('de');

            // Set angular material date control language
            this._dateAdapter.setLocale('de');

            // Set kendo translation
            const svc = this._kendoMessageService as CustomMessageService;
            svc.language = 'de';
        }
    }

    initFooter() {
        // Register the footer to the service
        if (!this.currentVersion) {
            this.currentVersion = 1000;
        }

        footer.push({
            Text: 'Version: ' + this.currentVersion,
            ShowDot: false,
        });

        // this._fuseFooterService.register('main', footer);

        // Set the main footer as our current footer
        this._fuseFooterService.setCurrentFooter('main');
    }

    initCustomLogo() {
        // Get Custom Logo
        this._updateService
            .getPropertyValue('LogoUrl')
            .then((logoUrl: string) => {
                if (logoUrl && logoUrl != '') {
                    const logo: any = document.querySelector('#customLogo');
                    logo.setAttribute('src', logoUrl);
                } else {
                    console.log('LogoUrl misisng');
                }
            })
            .catch((error) => {
                console.error(error);
            });

        // Get Custom Favicon
        this._updateService
            .getPropertyValue('FaviconUrl')
            .then((faviconUrl: string) => {
                if (faviconUrl && faviconUrl != '') {
                    const favicon: any = document.querySelector('#favicon');
                    favicon.setAttribute('href', faviconUrl);
                } else {
                    console.log('FaviconUrl misisng');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    public addControllingMenuForTeamManager(): void {
        const currentNavigation = this._navigationService.getCurrentNavigation()[0];
        const hasControllingMenu = currentNavigation?.children.some(
            (menu) => menu.id === 'controlling'
        );

        if (hasControllingMenu || !currentNavigation?.children.length) {
            return;
        }

        if (!this.currentUserService.isTeamLeiter()) {
            return;
        }

        const insertIndex = currentNavigation.children.findIndex(
            (menu) => menu.id === 'customers'
        );

        if (insertIndex !== -1) {
            currentNavigation.children.splice(
                insertIndex,
                0,
                controllingNavigationChild
            );
        } else {
            currentNavigation.children.push(controllingNavigationChild);
        }

        // this._fuseNavigationService.updateNavigationItem('applications', {});
    }

    public removeAllTimeMenu(): void {
        const currentNavigation =
            this._navigationService.getCurrentNavigation()[0];

        if (
            !this._sharePointService.currentUser.IsBusinessOwner &&
            !this.currentUserService.isTeamProjectLeiter()
        ) {
            const timesMenu = currentNavigation?.children.find(
                (menu) => menu.id === 'times'
            );
            timesMenu.children = timesMenu?.children.filter(
                (menu) => menu.id !== 'alltimes'
            );
        }
    }
}
