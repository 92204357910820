export const locale = {
    lang: 'en',
    data: {
        POPUPHEADER: 'Tasks in workpackage',
        ASSIGNED: 'Assigned to',
        DUEDATE: 'Duedate',
        TASKSWITHOUTWORKPACKAGE: 'Tasks without workpackage',
        TASKMOVED: 'Task moved!',
        ERRORONMOVINGTASK: 'Error on moving task!',
        ERRORONMOVINGTASKBETWEENSWIMLANE:
            'Moving tasks between swimlanes is disabled!',
    },
};
