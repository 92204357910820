export const locale = {
    lang: 'de',
    data: {
        DETAIL_ORDER: {
            ORDERS: 'Aufträge',
            BASE: 'Stammdaten',
            NEW: 'Neuer Auftrag',
            FORM: {
                CREATED1: 'Erstellt von ',
                CREATED2: ' am ',
                CREATED3: ' um ',
                DATE: 'Auftragsdatum',
                DESCRIPTION: 'Projektbezeichnung',
                CLIENT: 'Auftraggeber / Ansprechpartner',
                TAXER: 'Projektsteuerer',
                TAXERDESCRIPTION: 'nur zur Rechnungsprüfung',
                COSTCENTRE: 'Kostenstelle',
                TEAM: 'Team',
                AREA: 'Fachbereich',
                LOCATION: 'Niederlassung',
                ACCREDITED: 'Akkreditiert',
                MANAGER: 'Projektleiter',
                CLERK: 'Sachbearbeiter',
                SUM: 'Gesamtsumme',
                INEURO: '(in Euro)',
                ADDITIONAL: 'Davon Nebenkosten',
                INPERCENT: '(in %)',
                EXTERNAL: 'Davon Fremdleistung',
                COPY: 'Kopie Angebot/Auftrag an Sekretariat',
                CONFIRMATION: 'Auftragsbestätigung senden',
                FOLDER: 'Ordner anlegen',
                XMAS: 'Weihnachtskarte',
                HANDINGOVER: 'Übergabedatum',
                SAVE: 'Speichern',
                ACCEPT: 'Genehmigen',
                DELETE: 'Löschen',
                POSITION:{
                  HEADER:"Position",
                  TITLE: "Titel",
                  WORK:"Leistung",
                  NETTO:"Netto Betrag [€]"
              }
            },
            NOTIFICATIONS: {
                SAVING: 'Auftrag wird gespeichert...',
                DELETING: 'Auftrag wird gelöscht...',
                CREATINGPROJECT: 'Projekt wird erstellt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Auftrag erfolgreich gespeichert.',
                SUCCESSDELETE: 'Auftrag erfolgreich gelöscht.',
                SUCCESSPROJECT: 'Projekt erfolgreich erstellt.',
                ERROR: 'Fehler beim speichern: ',
            },
        },
    },
};
