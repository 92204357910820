import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MailService } from '../../services/mail.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-filter-mail',
    templateUrl: './filter-mail.component.html',
    styleUrls: ['./filter-mail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FilterMailComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    private triggerFilter$ = new Subject<string>();
    private destroy$ = new Subject();
    @Output() filter = new EventEmitter();

    constructor(
      private _mailService: MailService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.initForm();
    }

    ngOnInit(): void {
        // this.form.get('commonQuery')
        //     .valueChanges
        //     .pipe(
        //         takeUntil(this.destroy$),
        //         debounceTime(500)
        //     )
        //     .subscribe(value => this._mailService.filterEmails$.next(value));
        // this.form.get('fromUser')
        //     .valueChanges
        //     .pipe(
        //         takeUntil(this.destroy$),
        //         debounceTime(500)
        //     )
        //     .subscribe(value => this._mailService.filterFromEmails$.next(value));
        // this.form.get('toUser')
        //     .valueChanges
        //     .pipe(
        //         takeUntil(this.destroy$),
        //         debounceTime(500)
        //     )
        //     .subscribe(value => this._mailService.filterToEmails$.next(value));

        this.listenField('commonQuery', this._mailService.filterEmails$);
        this.listenField('fromUser', this._mailService.filterFromEmails$);
        this.listenField('toUser', this._mailService.filterToEmails$);
    }

    public filterEmail(value: string): void {
        this.triggerFilter$.next(value);
    }

    public cleanUpField(controlName: string): void {
        this.form.get(controlName).setValue('');
    }

    private initForm(): void {
        this.form = new UntypedFormGroup({
            commonQuery: new UntypedFormControl(),
            fromUser: new UntypedFormControl(),
            toUser: new UntypedFormControl()
        });
    }

    private listenField(field: string, trigger$: Subject<string>): void {
        this.form.get(field)
            .valueChanges
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(500)
            )
            .subscribe(() => {
              this.filter.emit([
                this.form.value.commonQuery,
                this.form.value.fromUser,
                this.form.value.toUser
              ]);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }

}
