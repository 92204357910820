<!-- Header -->
<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "DETAIL_CUSTOMER.EDIT" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "DETAIL_CUSTOMER.NEW" | translate }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<!-- End Header -->

<!-- Content -->
<div id='content'>

  <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

    <mat-tab class="tab" label='{{ "DETAIL_CUSTOMER.TABS.BASE" | translate }}'>

      <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
        [renderOptions]="formio_renderOptions">
      </formio>

    </mat-tab>

    <mat-tab *ngIf="this.id" class="tab" label='{{ "DETAIL_CUSTOMER.TABS.CONTACTS" | translate }}'>

      <app-sharepoint-table #contactGrid *ngIf="this.tableConfigContacts" [configuration]="this.tableConfigContacts"
        (onTooolbarButtonClick)="onTooolbarButtonClick($event)">
      </app-sharepoint-table>

    </mat-tab>

  </mat-tab-group>

</div>
<!-- End Content -->
