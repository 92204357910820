import { FieldType, SharePointService, UpdateService, } from 'sp-office365-framework';

export class UpdateToVersion1141Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {
    }

    async run() {
        try {
            const mailboxList = this._sharePointService.web.lists.getByTitle('Mailbox');
            const mailboxAntrageList = this._sharePointService.web.lists.getByTitle('Mailbox-Anträge');
            this._updateService.addField(mailboxList, 'MessageId', FieldType.Text);
            this._updateService.addField(mailboxAntrageList, 'MessageId', FieldType.Text);
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
