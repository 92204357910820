import { IList } from '@pnp/sp/lists';
import {
        UpdateService,
        SharePointService,
        FieldType }
from 'sp-office365-framework';

export class UpdateToVersion1105Component {
    constructor(
        private _updateService: UpdateService,
        private _sharePointService: SharePointService
    ) {}

    run() {


      const promise = new Promise<void>(async (resolve, reject) => {

        const applicationList: IList = this._sharePointService.web.lists.getByTitle('Anträge');
        let positionsList: IList

            const promises = [

                await this._updateService.addList(
                    'Positionen',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),

                await new Promise<void>((resolve) => {
                  positionsList = this._sharePointService.web.lists.getByTitle(
                      'Positionen'
                  );
                  resolve();
                }),

                await this._updateService.addField(
                    positionsList,
                    'Leistung',
                    FieldType.Text
                ),

                await this._updateService.addField(
                    positionsList,
                    'Netto_Betrag',
                    FieldType.Number
                ),

                await this._updateService.addLookupField(
                    positionsList,
                    applicationList,
                    'Antrag',
                    false,
                    "Title"
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
