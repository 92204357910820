import { ConfirmdatedialogComponent } from './confirmdatedialog/confirmdatedialog.component';
import { FremdkostenComponent } from './../../fremdkosten/fremdkosten.component';
import { LookupService } from './../../services/lookup.service';
import { BudgetService } from './../../services/budget.service';
import { TaskService } from 'src/app/main/services/task.service';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    FrameworkService,
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ColumnTyp,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { from, interval, Subject } from 'rxjs';
import * as numeral from 'numeral';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { OverviewBudgetComponent } from '../../budget/overview-budget/overview-budget.component';
import { FormioComponent } from '@formio/angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssignmentBudgetService } from 'src/app/main/services/assignment-budget.service';
import { getCustomerTitle } from 'src/app/main/shared/shared.util';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { PRINT_ASSIGNMENTS_BUDGET_COLUMNS } from './edit-assignment.constant';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PROJECT_EVALUATION_HEADER_FORMAT } from '../../project-evaluation/project-evaluation.constant';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-edit-assignment',
    templateUrl: './edit-assignment.component.html',
    styleUrls: ['./edit-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class EditAssignmentComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de',
    };

    public refreshForm: EventEmitter<any> = new EventEmitter();

    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;
    @ViewChild('excelTable') excelTable: ExcelExportComponent;

    public id: number;
    public itemTitle = '';
    public sharePointItem;
    public formioConfiguration;
    public triggerChange = false;
    public firstTimeLoad = true;
    public author;
    public createDate;
    public disableMark = false;
    public isCreateMode = true;
    public PRINT_ASSIGNMENTS_BUDGET_COLUMNS = PRINT_ASSIGNMENTS_BUDGET_COLUMNS;
    public confirmDialogRef: MatDialogRef<ConfirmdatedialogComponent>;
    public budgetTableToPrint: any[] = [];
    public printFileName: string;
    public PROJECT_EVALUATION_HEADER_FORMAT = PROJECT_EVALUATION_HEADER_FORMAT;
    private destroy$ = new Subject();
    private searchGroups: string;

    constructor(
        public dialog: MatDialog,
        public _applicationService: ApplicationService,
        private _frameworkService: FrameworkService,
        private route: ActivatedRoute,
        private router: Router,
        private _sharepointService: SharePointService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _budgetService: BudgetService,
        private _taskService: TaskService,
        private renderer: Renderer2,
        private _updateService: UpdateService,
        private _lookupService: LookupService,
        private _assignmentBudgetService: AssignmentBudgetService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnDestroy() {
        this._assignmentBudgetService.reset();
        this._lookupService.reset();
        this.destroy$.next(undefined);
    }

    ngOnInit() {
        this.route.params.subscribe(async (params) => {
            this._assignmentBudgetService.reset();
            this.id = +params.id;
            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            const promises = [];

            if (this.id) {
                await this._assignmentBudgetService.loadAssignmentBudgets(
                    this.id
                );
                // Edit mode
                this.isCreateMode = false;

                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getItemById({ listTitle: 'Aufträge', id: this.id })
                            .then((assignment) => {
                                this.sharePointItem = assignment;
                                this.itemTitle = this.sharePointItem.Title;

                                if (this.sharePointItem.Projektnummer) {
                                    this.itemTitle =
                                        this.sharePointItem.Title +
                                        ' (' +
                                        this._translateService.instant(
                                            'EDIT_ASSIGNMENT.FORM.PROJECTNUMBER'
                                        ) +
                                        ': ' +
                                        this.sharePointItem.Projektnummer +
                                        ')';
                                }

                                this.printFileName =
                                    `BudgetTable_${this.itemTitle}`.replace(
                                        /\s/g,
                                        '_'
                                    );
                                // Get author async
                                this._frameworkService
                                    .getUserOrGroupById(
                                        this.sharePointItem.AuthorId
                                    )
                                    .then((user: any) => {
                                        if (user.Title) {
                                            this.author =
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.CREATED1'
                                                ) + user.Title;
                                            this.createDate =
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.CREATED2'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleDateString() +
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.CREATED3'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleTimeString();
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });

                                // success
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
                promises.push(
                    // Kennzeichen im Projekt disabled wenn mind. 1 Arbeitspaket im Projekt
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getListItems({
                                title: 'Auftragsaufgaben',
                                isDocumentLibrary: false,
                                folderName: this.id + '',
                                camlQuery: new CamlBuilder()
                                    .View()
                                    .RowLimit(1)
                                    .Query()
                                    .Where()
                                    .ChoiceField('Aufgabenart')
                                    .EqualTo('Arbeitspaket')
                                    .ToString(),
                            })
                            .then((result) => {
                                if (result.length > 0) {
                                    this.disableMark = true;
                                }

                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
            } else {
                // Create mode
                this.itemTitle = this._translateService.instant(
                    'EDIT_ASSIGNMENT.NEW'
                );

                // Get default permissions
                promises.push(
                    this._applicationService.getDefaultPermissionsForProject()
                );
            }

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._updateService
                        .getPropertyValue('Usergruppen')
                        .then((value) => {
                            this.searchGroups = value;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            Promise.all(promises)
                .then(() => {
                    if (this.id) {
                        this.getLookUpInFirstLoad(this.sharePointItem)
                            .then(() => {
                                this.initGrid();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        this.initGrid();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    getLookUpInFirstLoad({ StandortId, TeamId }) {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (TeamId) {
                    this._lookupService.teams = [this.sharePointItem.TeamId];
                    const teamItem = await this._sharepointService.getListItems(
                        {
                            title: 'Teams',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .EqualTo(TeamId)
                                .ToString(),
                        }
                    );
                    this._lookupService.locationLookUpId =
                        teamItem[0]['StandortId'];
                }

                if (StandortId) {
                    this._lookupService.location = StandortId;
                    const teams = await this._sharepointService.getListItems({
                        title: 'Teams',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .LookupField('Standort')
                            .Id()
                            .EqualTo(StandortId)
                            .ToString(),
                    });

                    let teamId: number[] = [];
                    for (let team of teams) {
                        teamId.push(team.Id);
                        if (!TeamId) {
                            this._lookupService.teamsFromLocation = [...teamId];
                        } else {
                            this._lookupService.teamsFirstTimeLoad = [
                                ...teamId,
                            ];
                        }
                    }
                }
                resolve();
            } catch (err) {
                console.error(err);
                reject();
            }
        });
    }

    onNavChange() {}

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'EDIT_ASSIGNMENT.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('EDIT_ASSIGNMENT.NOTIFICATIONS.WAIT')
        );

        const budgetTable = e.data.BudgetTable;
        // Remove not existing columns
        delete e.data.submit;
        delete e.data.NebenkostenEuro;
        delete e.data.overviewBudget;
        delete e.data.externalCost;
        delete e.data.BudgetTable;
        delete e.data.exportButton;

        // Check why they are in e.data
        delete e.data.TB_Antragssumme;
        delete e.data.TB_Fremdleistung;
        delete e.data.TB_Nebenkosten;
        delete e.data.TB_NebenkostenEuro;
        delete e.data.TB_Nachtrag;
        delete e.data.TB_Datum;
        delete e.data.TB_Notiz;
        delete e.data.TB_Skonto;
        delete e.data.id;
        delete e.data.SkontoTotal;
        // Check why they are in e.data

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            // Edit Mode
            return from(
                this._sharepointService
                    .updateItem({
                        id: this.sharePointItem.Id,
                        listTitle: 'Aufträge',
                        newFiledValues: e.data,
                    })
                    .then(async () => {
                        await this._assignmentBudgetService.deleteAssignmentBudgets(
                            budgetTable
                        );
                        await this._assignmentBudgetService.saveAssignmentBudget(
                            this.id,
                            budgetTable
                        );

                        this._sharepointService
                            .getItemById({
                                listTitle: 'Aufträge',
                                id: this.id,
                            })
                            .then(async (assignment) => {
                                //Proof if Standort or Team and it is necessary if there are bills for this project
                                if (
                                    this.sharePointItem.StandortId !=
                                        e.data.StandortId ||
                                    e.data.TeamId != this.sharePointItem.TeamId
                                ) {
                                    this.sharePointItem = assignment;
                                    await this._sharepointService
                                        .getListItems({
                                            title: 'Rechnungen',
                                            isDocumentLibrary: false,
                                            recursiveAll: true,
                                            camlQuery: new CamlBuilder()
                                                .Where()
                                                .LookupField('Projekt')
                                                .Id()
                                                .EqualTo(this.id)
                                                .ToString(),
                                        })
                                        .then((results) => {
                                            let bills = results;
                                            bills.forEach(async (bill) => {
                                                //Change Standort or Team if the value in bill is not the same as the changed values in project
                                                if (
                                                    bill.StandortId !=
                                                        e.data.StandortId ||
                                                    e.data.TeamId != bill.TeamId
                                                ) {
                                                    bill.StandortId =
                                                        e.data.StandortId;
                                                    bill.TeamId = e.data.TeamId;
                                                    await this._sharepointService
                                                        .updateItem({
                                                            id: bill.ID,
                                                            listTitle:
                                                                'Rechnungen',
                                                            newFiledValues: {
                                                                StandortId: bill.StandortId,
                                                                TeamId: bill.TeamId
                                                            },
                                                        })
                                                        .then(() => {})
                                                        .catch((err) => {
                                                            console.log(err);
                                                        });
                                                }
                                            });
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                }

                                const promises = [];

                                // Check if duration changed
                                promises.push(
                                    this._taskService.syncDurationOfChildren(
                                        this.sharePointItem,
                                        assignment,
                                        false
                                    )
                                );

                                this.sharePointItem = assignment;

                                //Set Permissions
                                promises.push(
                                    this._applicationService.updateAssignemntPermissions(
                                        this.sharePointItem
                                    )
                                );

                                Promise.all(promises)
                                    .then(() => {
                                        const dataForm =
                                            this.formioComponent.formio._data;
                                        this.refreshForm.emit({
                                            submission: {
                                                data: {
                                                    ...dataForm,
                                                    BudgetTable: [
                                                        ...this
                                                            ._assignmentBudgetService
                                                            .assignmentInitialBudgets,
                                                    ].filter((item) => item),
                                                },
                                            },
                                        });
                                        this._loadingService.hide();
                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'EDIT_ASSIGNMENT.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Create Mode
            return from(
                this._applicationService
                    .createNewProject(e.data, true, budgetTable)
                    .then(async (item: any) => {
                        // Success
                        this.sharePointItem = item;

                        await this._sharepointService
                            .getListItems({
                                title: 'Kostenrollen',
                                isDocumentLibrary: false,
                                recursiveAll: true,
                                camlQuery: new CamlBuilder()
                                    .Where()
                                    .CounterField('ID')
                                    .NotEqualTo(0)
                                    .ToString(),
                            })
                            .then((results) => {
                                results.forEach(async (costRole) => {
                                    //Projekt_Kostenrollen

                                    costRole.ProjektId = this.sharePointItem.Id;
                                    costRole.KostenrolleId = costRole.Id;

                                    await this._sharepointService
                                        .addItem({
                                            listTitle: 'Projekt_Kostenrollen',
                                            data: costRole,
                                            folder: '',
                                        })
                                        .then(() => {})
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                });

                                this._loadingService.hide();

                                this.router.navigate(
                                    ['projects/detail/basedata/' + item.Id],
                                    {
                                        queryParamsHandling: 'merge',
                                    }
                                );

                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'EDIT_ASSIGNMENT.NOTIFICATIONS.SUCCESS'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    public formLoad(): void {
        interval(1000)
            .pipe(
                takeUntil(this.destroy$),
                filter(
                    () =>
                        !!document.querySelector(
                            'tfoot td'
                        )
                ),
                take(1)
            )
            .subscribe(() => {
                // const parentContainer = document.querySelector('tfoot td');
                // const excelExportButton = document.querySelector('.exportBudgetTableButton');
                // this.renderer.insertBefore(
                //     parentContainer,
                //     excelExportButton,
                //     parentContainer.firstChild
                // );
                // this.renderer.insertBefore(
                //     excelExportButton,
                //     document.querySelector('.formio-button-add-row'),
                //     excelExportButton.firstChild
                // );
            });
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
            dataForm.Auftragsstart = new Date();
            dataForm.Auftragsstatus = 'Auftrag angelegt';
            dataForm.Kennzeichen = 'Unkritisch';
            dataForm.Gewinnmarge = 0;
        }

        // Set not existing columns
        let sum = dataForm.Antragssumme ? dataForm.Antragssumme : 0;
        let percent = dataForm.Nebenkosten ? dataForm.Nebenkosten : 0;
        dataForm.NebenkostenEuro =
            this._assignmentBudgetService.assignmentInitialNebenkostenEuro;
        dataForm.SkontoTotal =
            this._assignmentBudgetService.assignmentInitialSkontoTotal;

        if (this._assignmentBudgetService.assignmentInitialBudgets?.length) {
            dataForm.BudgetTable = [
                ...this._assignmentBudgetService.assignmentInitialBudgets,
            ].filter((item) => item);
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() => {
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                });

                // Add change listener for formiotabs
                document.querySelectorAll('div[role="tab"]').forEach((tab) => {
                    this.renderer.listen(tab, 'click', (evt) => {
                        //
                    });
                });
            })
            .catch((error) => {
                throw error;
            });
    }

    confirmationPopUp(question): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const confirmMessage = question;
            this.confirmDialogRef = this.dialog.open(
                ConfirmdatedialogComponent,
                {
                    data: {
                        confirmMessage,
                    },
                }
            );

            this.confirmDialogRef.afterClosed().subscribe((result) => {
                resolve(result || false);
            });
        });
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }
            // Get formvalues
            const data = JSON.parse(JSON.stringify(ev.data));
            const componentKey = ev.changed.component.key;

            if (
                componentKey.startsWith('TB_') &&
                componentKey !== 'TB_Nachtrag' &&
                componentKey !== 'TB_Datum' &&
                componentKey !== 'TB_Notiz'
            ) {
                this.updateBudgetRow(data);
                return;
            }

            if (ev.changed.component.key === 'BudgetTable') {
                this.updateBudgetRow(data);
                return;
            }

            if (
                ev.changed.component.key ===
                    'Budget_x0020__x0028_in_x0020_Tag' ||
                ev.changed.component.key === 'Budget_x0020__x0028_in_x0020_Stu'
            ) {
                if (
                    ev.changed.component.key ===
                        'Budget_x0020__x0028_in_x0020_Tag' &&
                    !this.triggerChange
                ) {
                    this.triggerChange = !this.triggerChange;
                    const tage = numeral(
                        ev.data.Budget_x0020__x0028_in_x0020_Tag.toString().replace(
                            ',',
                            '.'
                        )
                    ).value();
                    data.Budget_x0020__x0028_in_x0020_Stu = tage
                        ? Math.round(tage * 8 * 100) / 100
                        : 0;
                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                } else if (
                    ev.changed.component.key ===
                        'Budget_x0020__x0028_in_x0020_Stu' &&
                    !this.triggerChange
                ) {
                    this.triggerChange = !this.triggerChange;
                    const stunden = numeral(
                        ev.data.Budget_x0020__x0028_in_x0020_Stu.toString().replace(
                            ',',
                            '.'
                        )
                    ).value();
                    data.Budget_x0020__x0028_in_x0020_Tag = stunden
                        ? Math.round((stunden / 8) * 100) / 100
                        : 0;
                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                } else if (this.triggerChange) {
                    this.triggerChange = !this.triggerChange;
                }
            } else if (ev.changed.component.key === 'Antragssumme') {
                // this.updateBudgets(
                //     data,
                //     ev.changed.value,
                //     data.Nebenkosten ? data.Nebenkosten : 0,
                //     data.Fremdleistung ? data.Fremdleistung : 0
                // );
            } else if (ev.changed.component.key === 'Nebenkosten') {
                // this.updateBudgets(
                //     data,
                //     data.Antragssumme ? data.Antragssumme : 0,
                //     ev.changed.value,
                //     data.Fremdleistung ? data.Fremdleistung : 0
                // );
            } else if (ev.changed.component.key === 'Fremdleistung') {
                // this.updateBudgets(
                //     data,
                //     data.Antragssumme ? data.Antragssumme : 0,
                //     data.Nebenkosten ? data.Nebenkosten : 0,
                //     ev.changed.value
                // );
            } else if (
                componentKey === 'Auftragsbudget' ||
                componentKey === 'Gewinnmarge'
            ) {
                // Update chart
                this._budgetService.updateBudget(data);
            } else if (ev.changed.component.key === 'Title') {
                // Update title
                if (this.sharePointItem && this.sharePointItem.Projektnummer) {
                    this.itemTitle =
                        ev.changed.value +
                        ' (' +
                        this._translateService.instant(
                            'EDIT_ASSIGNMENT.FORM.PROJECTNUMBER'
                        ) +
                        ': ' +
                        this.sharePointItem.Projektnummer +
                        ')';
                } else {
                    this.itemTitle = ev.changed.value;
                }
            } else if (ev.changed.component.key === 'Auftragsende') {
                // Check Auftragsende
                const dueDate = this._frameworkService.dateOnly(
                    ev.data.Auftragsende
                );
                let startDate;
                if (ev.data.Auftragsstart) {
                    startDate = this._frameworkService.dateOnly(
                        ev.data.Auftragsstart
                    );
                }

                let minDueDate;
                let notification;
                // Check duedate is greater than startdate
                if (dueDate && startDate && dueDate < startDate) {
                    minDueDate = startDate;
                    notification = this._translateService.instant(
                        'EDIT_ASSIGNMENT.NOTIFICATIONS.STARTDUEDATEERROR'
                    );
                }

                if (minDueDate && minDueDate > dueDate) {
                    // Set Auftragsende Min
                    data.Auftragsende = minDueDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'Auftragsstart') {
                // Check Auftragsstart
                const startDate = this._frameworkService.dateOnly(
                    ev.data.Auftragsstart
                );
                let dueDate;
                if (ev.data.Auftragsende) {
                    dueDate = this._frameworkService.dateOnly(
                        ev.data.Auftragsende
                    );
                }

                let maxStartDate;
                let notification;
                // Check duedate is greater than startdate
                if (dueDate && startDate && dueDate < startDate) {
                    maxStartDate = dueDate;
                    notification = this._translateService.instant(
                        'EDIT_ASSIGNMENT.NOTIFICATIONS.STARTDUEDATEERROR'
                    );
                }

                if (maxStartDate && maxStartDate < startDate) {
                    // Set Auftragsstart Max
                    data.Auftragsstart = maxStartDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }

                if (!data.Auftragsende) {
                    const { date, month, year } = data.Auftragsstart;
                    data.Auftragsende = new Date(year + 1, month, date);

                    this.refreshForm.emit({
                        submission: {
                            data: data,
                        },
                    });
                } else {
                    const currentDate = new Date(data.Auftragsstart);
                    const year = currentDate.getFullYear();
                    const month = currentDate.getMonth();
                    const day = currentDate.getDate();
                    const newDate = new Date(year + 1, month, day);
                    const newDateString = newDate.toLocaleDateString();
                    const confirmMessage =
                        this._translateService.instant(
                            'EDIT_ASSIGNMENT.POPUP.QUESTION_1'
                        ) +
                        newDateString +
                        this._translateService.instant(
                            'EDIT_ASSIGNMENT.POPUP.QUESTION_2'
                        );

                    this.confirmationPopUp(confirmMessage)
                        .then((confirmation) => {
                            if (confirmation) {
                                data.Auftragsende = newDate;
                            }
                            this.refreshForm.emit({
                                submission: {
                                    data: data,
                                },
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            }
        }

        if (ev.add) {
            if (this._lookupService.teamsFirstTimeLoad.length) {
                this._lookupService.teams = [
                    ...this._lookupService.teamsFirstTimeLoad,
                ];
                //Has to be set to [] after first changen
                this._lookupService.teamsFirstTimeLoad = [];
            }
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = ev.Id;
                this._sharepointService
                    .getListItems({
                        title: 'Teams',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .LookupField('Standort')
                            .Id()
                            .EqualTo(ev.Id)
                            .ToString(),
                    })
                    .then((results) => {
                        const teams = results;
                        let teamId: number[] = [];
                        teams.forEach((team) => {
                            teamId.push(team.Id);
                        });
                        this._lookupService.teamsFromLocation = [...teamId];
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this._lookupService.teams = [ev.Id];
                this._lookupService.locationLookUpId = ev.StandortId;
            }
        } else if (ev.remove) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
                if (this._lookupService.teamsFirstTimeLoad.length) {
                    this._lookupService.teams = [
                        ...this._lookupService.teamsFirstTimeLoad,
                    ];
                    //Has to be set to [] after first change
                    this._lookupService.teamsFirstTimeLoad = [];
                }
            } else {
                this._lookupService.teamsFirstTimeLoad = [];
                this._lookupService.teams = [];
                this._lookupService.locationLookUpId = null;
            }
        }
    }

    updateBudgets(data: any, sum: number, percent: number, external: number) {
        // Calculate in Euro
        data.NebenkostenEuro = (sum / 100) * percent;

        // Calculate budget
        data.Auftragsbudget = sum + data.NebenkostenEuro - external;

        this.refreshForm.emit({
            submission: {
                data,
            },
        });

        // Update chart
        this._budgetService.updateBudget(data);
    }

    public updateBudgetRow(data: any): void {
        let auftragsbudgetTotal = 0;
        let totalSumValue = 0;
        let totalNebenkosten = 0;
        let totalNebenkostenEuro = 0;
        let totalSkonto = 0;
        let externalValue = 0;

        data.BudgetTable.forEach((budgetRow) => {
            // Calculate in Euro
            budgetRow.TB_NebenkostenEuro =
                ((budgetRow.TB_Antragssumme ?? 0) / 100) *
                (budgetRow.TB_Nebenkosten ?? 0);
            const skonto =
                budgetRow.TB_Skonto && budgetRow.TB_Antragssumme
                    ? (budgetRow.TB_Antragssumme +
                          budgetRow.TB_NebenkostenEuro) *
                      0.03
                    : 0;
            // Calculate budget
            auftragsbudgetTotal =
                auftragsbudgetTotal +
                ((budgetRow.TB_Antragssumme ?? 0) +
                    (budgetRow.TB_NebenkostenEuro ?? 0) -
                    (budgetRow.TB_Fremdleistung ?? 0)) -
                skonto;
            totalSumValue = totalSumValue + (budgetRow.TB_Antragssumme ?? 0);
            totalNebenkosten =
                totalNebenkosten + (budgetRow.TB_Nebenkosten ?? 0);
            totalNebenkostenEuro =
                totalNebenkostenEuro + (budgetRow.TB_NebenkostenEuro ?? 0);
            totalSkonto = totalSkonto + skonto;
            externalValue = externalValue + (budgetRow.TB_Fremdleistung ?? 0);
        });

        data.Auftragsbudget = auftragsbudgetTotal;
        data.Antragssumme = totalSumValue;
        data.Nebenkosten = Number(
            ((totalNebenkostenEuro * 100) / data.Antragssumme).toFixed(2)
        );
        data.NebenkostenEuro = totalNebenkostenEuro;
        data.SkontoTotal = totalSkonto;
        data.Fremdleistung = externalValue;

        this.refreshForm.emit({
            submission: {
                data,
            },
        });
        this._budgetService.updateBudget(data);
    }

    initGrid() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Tabs',
                    components: [
                        {
                            label: this._translateService.instant(
                                'EDIT_ASSIGNMENT.BASE'
                            ),
                            key: 'tab1',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.TITLE'
                                    ),
                                    type: 'textfield',
                                    key: 'Title',
                                    inputFormat: 'plain',
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.DESCRIPTION'
                                    ),
                                    isUploadEnabled: false,
                                    showWordCount: false,
                                    showCharCount: false,
                                    type: 'textarea',
                                    inputFormat: 'html',
                                    key: 'Bezeichnung',
                                    rows: 8,
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.PROJECTMAIL'
                                    ),
                                    type: 'textfield',
                                    key: 'ProjectMail',
                                    inputFormat: 'plain',
                                    validateOn: 'blur',
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.OWNUMBER'
                                    ),
                                    type: 'textfield',
                                    key: 'OW_Nummer',
                                    inputFormat: 'plain',
                                    validateOn: 'blur',
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.CATEGORY'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AuftragskategorieId',
                                    selectThreshold: 0.3,
                                    list: 'Auftragskategorien',
                                    searchQuery: new CamlBuilder()
                                        .Where()
                                        .TextField('Title')
                                        .Contains('<%searchText%>')
                                        .OrderBy('Title')
                                        .ToString(),
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.CUSTOMER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'KundeId',
                                    selectThreshold: 0.3,
                                    list: 'Firmen',
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    formatTitle: getCustomerTitle,
                                    addComponent: DetailCustomerComponent,
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.TAXER'
                                    ),
                                    description: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.TAXERDESCRIPTION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektsteuererId',
                                    list: 'Firmen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    formatTitle: getCustomerTitle,
                                    addComponent: DetailCustomerComponent,
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.COSTCENTRE'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'KostenstelleId',
                                    list: 'Kostenstellen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    isCreateMode: this.isCreateMode,
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.locationCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.ACCREDITED'
                                    ),
                                    type: 'checkbox',
                                    key: 'Akkreditiert',
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.UEBERGABE'
                                    ),
                                    type: 'datetime',
                                    key: 'Uebergabe',
                                    disabled: true,
                                    format: 'dd.MM.yyyy',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone:
                                            'viewer',
                                        submissionTimezone:
                                            'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings:
                                            false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    customOptions: {
                                        locale: German
                                    },
                                },
                                {
                                    label: 'Columns',
                                    columns: [
                                        {
                                            components: [
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.TEAM'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    input: true,
                                                    key: 'TeamId',
                                                    selectThreshold: 0.3,
                                                    list: 'Teams',
                                                    //searchQuery: this._lookupService.teamCAMLQuery,
                                                    searchQueryIfIsEmpty:
                                                        this._lookupService
                                                            .teamCAMLQuery,
                                                    isPeoplePicker: false,
                                                    removable: true,
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                    onSelected: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.add = true;
                                                        data.remove = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );

                                                        // Add Teamgtoup to permissions
                                                        this._sharepointService
                                                            .getItemById({
                                                                listTitle:
                                                                    'Teams',
                                                                id: data.Id,
                                                            })
                                                            .then((team) => {
                                                                // Get formvalues
                                                                const data =
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            this
                                                                                .formioComponent
                                                                                .formio
                                                                                ._data
                                                                        )
                                                                    );

                                                                // Add Teamgtoup to permissions
                                                                if (
                                                                    team.GruppeId &&
                                                                    data.BearbeitungsberechtigungId.results.indexOf(
                                                                        team.GruppeId ===
                                                                            -1
                                                                    )
                                                                ) {
                                                                    data.BearbeitungsberechtigungId.results.push(
                                                                        team.GruppeId
                                                                    );

                                                                    this.refreshForm.emit(
                                                                        {
                                                                            submission:
                                                                                {
                                                                                    data,
                                                                                },
                                                                        }
                                                                    );
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                this._loadingService.hide();
                                                                console.error(
                                                                    error
                                                                );
                                                            });
                                                    },
                                                    onRemoved: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.remove = true;
                                                        data.add = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );
                                                    },
                                                },
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.START'
                                                    ),
                                                    hideLabel: false,
                                                    format: 'dd.MM.yyyy',
                                                    placeholder: '',
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'datetime',
                                                    input: true,
                                                    key: 'Auftragsstart',
                                                    suffix: true,
                                                    // defaultValue: new Date(),
                                                    widget: {
                                                        type: 'calendar',
                                                        displayInTimezone:
                                                            'viewer',
                                                        submissionTimezone:
                                                            'Europe/Berlin',
                                                        language: 'en',
                                                        useLocaleSettings:
                                                            false,
                                                        allowInput: true,
                                                        mode: 'single',
                                                        enableTime: true,
                                                        noCalendar: false,
                                                        format: 'dd.MM.yyyy',
                                                        defaultDate: '',
                                                        hourIncrement: 1,
                                                        minuteIncrement: 1,
                                                        time_24hr: true,
                                                        minDate: '',
                                                        maxDate: '',
                                                        icons: 'fontawesome',
                                                    },
                                                    enableTime: false,
                                                    datePicker: {
                                                        minDate: '',
                                                        maxDate: '',
                                                    },
                                                    timePicker: {
                                                        showMeridian: false,
                                                    },
                                                    validateOn: 'blur',
                                                    customOptions: {
                                                        locale: German
                                                    },
                                                },
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.STATUS'
                                                    ),
                                                    hideLabel: false,
                                                    placeholder: '',
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'select',
                                                    input: true,
                                                    key: 'Auftragsstatus',
                                                    data: {
                                                        values: [
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.CREATED'
                                                                ),
                                                                value: 'Auftrag angelegt',
                                                            },
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.PROGRESS'
                                                                ),
                                                                value: 'Auftrag in Arbeit',
                                                            },
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.STOPPED'
                                                                ),
                                                                value: 'Auftrag gestoppt',
                                                            },
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.COMPLETED'
                                                                ),
                                                                value: 'Auftrag abgeschlossen',
                                                            },
                                                        ],
                                                    },
                                                    valueProperty: 'value',
                                                    selectThreshold: 0.3,
                                                    lazyLoad: false,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                },
                                            ],
                                            width: 6,
                                            offset: 0,
                                            push: 0,
                                            pull: 0,
                                            type: 'column',
                                            input: false,
                                            hideOnChildrenHidden: false,
                                            key: 'column',
                                            tableView: true,
                                            label: 'Column',
                                        },
                                        {
                                            components: [
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.FACHBEREICH'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    input: true,
                                                    key: 'FachbereichId',
                                                    selectThreshold: 0.3,
                                                    list: 'Fachbereiche',
                                                    searchQuery:
                                                        new CamlBuilder()
                                                            .Where()
                                                            .TextField('Title')
                                                            .Contains(
                                                                '[SearchText]'
                                                            )
                                                            .OrderBy('Title')
                                                            .ToString(),
                                                    searchQueryIfIsEmpty:
                                                        new CamlBuilder()
                                                            .Where()
                                                            .NumberField('ID')
                                                            .NotEqualTo(-1)
                                                            .OrderBy('Title')
                                                            .ToString(),
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                    isPeoplePicker: false,
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                    removable:
                                                        this.isCreateMode,
                                                    disabled:
                                                        !this.isCreateMode,
                                                },
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.END'
                                                    ),
                                                    hideLabel: false,
                                                    format: 'dd.MM.yyyy',
                                                    placeholder: '',
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'datetime',
                                                    input: true,
                                                    key: 'Auftragsende',
                                                    suffix: true,
                                                    defaultValue: '',
                                                    validate: {
                                                        required: true,
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                    },
                                                    widget: {
                                                        type: 'calendar',
                                                        displayInTimezone:
                                                            'viewer',
                                                        submissionTimezone:
                                                            'Europe/Berlin',
                                                        language: 'en',
                                                        useLocaleSettings:
                                                            false,
                                                        allowInput: true,
                                                        mode: 'single',
                                                        enableTime: true,
                                                        noCalendar: false,
                                                        format: 'dd.MM.yyyy HH:mm',
                                                        defaultDate: '',
                                                        hourIncrement: 1,
                                                        minuteIncrement: 1,
                                                        time_24hr: true,
                                                        minDate: '',
                                                        maxDate: '',
                                                        icons: 'fontawesome',
                                                    },
                                                    enableTime: false,
                                                    datePicker: {
                                                        minDate: '',
                                                        maxDate: '',
                                                    },
                                                    timePicker: {
                                                        showMeridian: false,
                                                    },
                                                    validateOn: 'blur',
                                                    customOptions: {
                                                        locale: German
                                                    },
                                                },
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.MARK'
                                                    ),
                                                    hideLabel: false,
                                                    type: 'select',
                                                    input: true,
                                                    key: 'Kennzeichen',
                                                    disabled: this.disableMark,
                                                    data: {
                                                        values: [
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.CRITICAL'
                                                                ),
                                                                value: 'Kritisch',
                                                            },
                                                            {
                                                                label: this._translateService.instant(
                                                                    'EDIT_ASSIGNMENT.FORM.UNCRITICALLY'
                                                                ),
                                                                value: 'Unkritisch',
                                                            },
                                                        ],
                                                    },
                                                    valueProperty: 'value',
                                                    selectThreshold: 0.3,
                                                    lazyLoad: true,
                                                },
                                            ],
                                            width: 6,
                                            offset: 0,
                                            push: 0,
                                            pull: 0,
                                            type: 'column',
                                            input: false,
                                            hideOnChildrenHidden: false,
                                            key: 'column',
                                            tableView: true,
                                            label: 'Column',
                                        },
                                    ],
                                    mask: false,
                                    tableView: false,
                                    alwaysEnabled: false,
                                    type: 'columns',
                                    input: false,
                                    key: 'columns2',
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.ORDER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'AntragId',
                                    list: 'Anträge',
                                    isPeoplePicker: false,
                                    isCreateMode: this.isCreateMode,
                                    removable: this.isCreateMode,
                                    disabled: !this.isCreateMode,
                                    showAsTable: true,
                                    columns: [
                                        {
                                            internalName: 'Title',
                                            title: this._translateService.instant(
                                                'EDIT_ASSIGNMENT.FORM.TITLE'
                                            ),
                                            hidden: false,
                                            type: ColumnTyp.Text,
                                        },
                                        {
                                            internalName: 'ID',
                                            title: 'ID',
                                            hidden: true,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: this._translateService.instant(
                                'EDIT_ASSIGNMENT.FORM.USER'
                            ),
                            key: 'tab2',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.MANAGEMENT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AuftragsleiterId',
                                    selectThreshold: 0.3,
                                    isPeoplePicker: true,
                                    // useGraph: !window['useApp'],
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.CLERK'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'SachbearbeiterId',
                                    multi: true,
                                    isPeoplePicker: true,
                                    // useGraph: !window['useApp'],
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                        },
                        {
                            label: this._translateService.instant(
                                'EDIT_ASSIGNMENT.BUDGETS'
                            ),
                            key: 'tab3',
                            components: [
                                {
                                    label: '',
                                    key: 'BudgetTable',
                                    type: 'datagrid',
                                    customClass: 'budget-table',
                                    input: true,
                                    validate: {
                                        minLength: 1,
                                        maxLength: 100,
                                    },
                                    components: [
                                        {
                                            label: '',
                                            type: 'number',
                                            key: 'id',
                                            customClass: 'tb-id'
                                        },
                                        {
                                            label: 'Auftrag/Nachtrag',
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INTEXT'
                                                ),
                                            type: 'textfield',
                                            key: 'TB_Nachtrag',
                                            customClass: 'tb-auftrag',
                                        },
                                        {
                                            label: 'Datum',
                                            type: 'datetime',
                                            key: 'TB_Datum',
                                            customDefaultValue: `if(!row.Id) {
                                                value = new Date();
                                                row.TB_Datum = new Date();
                                                return new Date();
                                            }`,
                                            format: 'dd.MM.yyyy',
                                            widget: {
                                                type: 'calendar',
                                                displayInTimezone:
                                                    'viewer',
                                                submissionTimezone:
                                                    'Europe/Berlin',
                                                language: 'en',
                                                useLocaleSettings:
                                                    false,
                                                allowInput: true,
                                                mode: 'single',
                                                enableTime: true,
                                                noCalendar: false,
                                                format: 'dd.MM.yyyy',
                                                defaultDate: '',
                                                hourIncrement: 1,
                                                minuteIncrement: 1,
                                                time_24hr: true,
                                                minDate: '',
                                                maxDate: '',
                                                icons: 'fontawesome',
                                            },
                                            enableTime: false,
                                            customOptions: {
                                                locale: German
                                            },
                                        },
                                        {
                                            label: this._translateService.instant(
                                                'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                            ),
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INPERCENT'
                                                ),
                                            type: 'number',
                                            key: 'TB_Nebenkosten',
                                        },
                                        {
                                            label: this._translateService.instant(
                                                'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                            ),
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INEURO'
                                                ),
                                            type: 'number',
                                            key: 'TB_NebenkostenEuro',
                                            disabled: true,
                                        },
                                        {
                                            label: this._translateService.instant(
                                                'EDIT_ASSIGNMENT.FORM.EXTERNAL'
                                            ),
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INEURO'
                                                ),
                                            type: 'number',
                                            key: 'TB_Fremdleistung',
                                        },
                                        {
                                            label: this._translateService.instant(
                                                'EDIT_ASSIGNMENT.FORM.SUM'
                                            ),
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INEURO'
                                                ),
                                            type: 'number',
                                            key: 'TB_Antragssumme',
                                            validate: {
                                                customMessage:
                                                    '{{field}} ist ein Pflichtfeld',
                                                required: true,
                                            },
                                        },
                                        {
                                            label: 'Skonto',
                                            type: 'checkbox',
                                            key: 'TB_Skonto',
                                            customClass: 'skonto-checkbox'
                                        },
                                        {
                                            label: 'Notiz',
                                            description:
                                                this._translateService.instant(
                                                    'EDIT_ASSIGNMENT.FORM.INTEXT'
                                                ),
                                            type: 'textarea',
                                            key: 'TB_Notiz',
                                        },
                                    ],
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.EXPORT'
                                    ),
                                    size: 'small',
                                    type: 'buttonCustom',
                                    leftIcon: 'get_app',
                                    customClass: 'exportBudgetTableButton',
                                    hidden: !this.id,
                                    key: 'exportButton',
                                    onClick: () => this.printBudgetTable(),
                                },
                                {
                                    // Columns 2
                                    label: 'Columns',
                                    type: 'columns',
                                    key: 'columns2',
                                    columns: [
                                        {
                                            // Column 1
                                            type: 'column',
                                            key: 'column1',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.SUM'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'Antragssumme',
                                                    disabled: true,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            // Column 2
                                            type: 'column',
                                            key: 'column2',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INPERCENT'
                                                        ),
                                                    type: 'number',
                                                    disabled: true,
                                                    key: 'Nebenkosten',
                                                },
                                            ],
                                        },
                                        {
                                            // Column 3
                                            type: 'column',
                                            key: 'column3',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'NebenkostenEuro',
                                                    disabled: true,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 4
                                            type: 'column',
                                            key: 'column4',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.SKONTO'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'SkontoTotal',
                                                    disabled: true,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 5
                                            type: 'column',
                                            key: 'column5',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.EXTERNAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    disabled: true,
                                                    key: 'Fremdleistung',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                // {
                                //     // Columns 2
                                //     label: 'Columns',
                                //     type: 'columns',
                                //     key: 'columns2',
                                //     columns: [
                                //         {
                                //             // Column 1
                                //             type: 'column',
                                //             key: 'column1',
                                //             components: [
                                //                 // Components
                                //                 {
                                //                     label: this._translateService.instant(
                                //                         'EDIT_ASSIGNMENT.FORM.SUM'
                                //                     ),
                                //                     description:
                                //                         this._translateService.instant(
                                //                             'EDIT_ASSIGNMENT.FORM.INEURO'
                                //                         ),
                                //                     type: 'number',
                                //                     key: 'Antragssumme',
                                //                 },
                                //             ],
                                //         },
                                //         {
                                //             // Column 2
                                //             type: 'column',
                                //             key: 'column2',
                                //             components: [
                                //                 // Components
                                //                 {
                                //                     label: this._translateService.instant(
                                //                         'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                //                     ),
                                //                     description:
                                //                         this._translateService.instant(
                                //                             'EDIT_ASSIGNMENT.FORM.INPERCENT'
                                //                         ),
                                //                     type: 'number',
                                //                     key: 'Nebenkosten',
                                //                 },
                                //             ],
                                //         },
                                //         {
                                //             // Column 3
                                //             type: 'column',
                                //             key: 'column3',
                                //             components: [
                                //                 // Components
                                //                 {
                                //                     label: this._translateService.instant(
                                //                         'EDIT_ASSIGNMENT.FORM.ADDITIONAL'
                                //                     ),
                                //                     description:
                                //                         this._translateService.instant(
                                //                             'EDIT_ASSIGNMENT.FORM.INEURO'
                                //                         ),
                                //                     type: 'number',
                                //                     key: 'NebenkostenEuro',
                                //                     disabled: true,
                                //                 },
                                //             ],
                                //         },
                                //         {
                                //             // Column 4
                                //             type: 'column',
                                //             key: 'column4',
                                //             components: [
                                //                 // Components
                                //                 {
                                //                     label: this._translateService.instant(
                                //                         'EDIT_ASSIGNMENT.FORM.EXTERNAL'
                                //                     ),
                                //                     description:
                                //                         this._translateService.instant(
                                //                             'EDIT_ASSIGNMENT.FORM.INEURO'
                                //                         ),
                                //                     type: 'number',
                                //                     key: 'Fremdleistung',
                                //                 },
                                //             ],
                                //         },
                                //     ],
                                // },
                                {
                                    label: 'Columns',
                                    columns: [
                                        {
                                            components: [
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.BUDGET'
                                                    ),
                                                    hideLabel: false,
                                                    placeholder: '',
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INEURO'
                                                        ),
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'number',
                                                    input: true,
                                                    key: 'Auftragsbudget',
                                                    validate: {
                                                        customMessage: '',
                                                        json: '',
                                                    },
                                                    delimiter: false,
                                                    requireDecimal: false,
                                                    reorder: false,
                                                },
                                            ],
                                            width: 4,
                                            offset: 0,
                                            push: 0,
                                            pull: 0,
                                            type: 'column',
                                            input: false,
                                            hideOnChildrenHidden: false,
                                            key: 'column',
                                            tableView: true,
                                            label: 'Column',
                                        },
                                        {
                                            components: [
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.BUDGET'
                                                    ),
                                                    hideLabel: false,
                                                    placeholder: '',
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INHOURS'
                                                        ),
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'number',
                                                    input: true,
                                                    key: 'Budget_x0020__x0028_in_x0020_Stu',
                                                    validate: {
                                                        customMessage: '',
                                                        json: '',
                                                    },
                                                    delimiter: false,
                                                    requireDecimal: false,
                                                },
                                            ],
                                            width: 4,
                                            offset: 0,
                                            push: 0,
                                            pull: 0,
                                            type: 'column',
                                            input: false,
                                            hideOnChildrenHidden: false,
                                            key: 'column',
                                            tableView: true,
                                            label: 'Column',
                                        },
                                        {
                                            components: [
                                                {
                                                    label: this._translateService.instant(
                                                        'EDIT_ASSIGNMENT.FORM.BUDGET'
                                                    ),
                                                    hideLabel: false,
                                                    placeholder: '',
                                                    description:
                                                        this._translateService.instant(
                                                            'EDIT_ASSIGNMENT.FORM.INDAYS'
                                                        ),
                                                    mask: false,
                                                    tableView: true,
                                                    alwaysEnabled: false,
                                                    type: 'number',
                                                    input: true,
                                                    key: 'Budget_x0020__x0028_in_x0020_Tag',
                                                    delimiter: false,
                                                    requireDecimal: false,
                                                },
                                            ],
                                            width: 4,
                                            offset: 0,
                                            push: 0,
                                            pull: 0,
                                            type: 'column',
                                            input: false,
                                            hideOnChildrenHidden: false,
                                            key: 'column',
                                            tableView: true,
                                            label: 'Column',
                                        },
                                    ],
                                    mask: false,
                                    tableView: false,
                                    alwaysEnabled: false,
                                    type: 'columns',
                                    input: false,
                                    key: 'columns3',
                                },
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.EXPECTEDPROFITMARGIN'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    description: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.INPERCENT'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'number',
                                    input: true,
                                    key: 'Gewinnmarge',
                                    delimiter: false,
                                    requireDecimal: false,
                                    customClass: 'gewinnmarge-field',
                                },
                                {
                                    label: '',
                                    type: 'contentCustom',
                                    component: OverviewBudgetComponent,
                                    key: 'overviewBudget',
                                    hidden: !this._applicationService
                                        .timesEnabled,
                                },
                            ],
                        },
                        {
                            label: this._translateService.instant(
                                'EDIT_ASSIGNMENT.FORM.PERMISSIONS'
                            ),
                            key: 'tab4',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EDIT_ASSIGNMENT.FORM.EDIT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    multi: true,
                                    key: 'BearbeitungsberechtigungId',
                                    selectThreshold: 0.3,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld!',
                                        required: true,
                                    },
                                    isPeoplePicker: true,
                                    // useGraph: !window['useApp'],
                                    removable: true,
                                    setValue:
                                        this._applicationService
                                            .editorPermissions,
                                    isCreateMode: this.isCreateMode,
                                },
                            ],
                        },
                    ],
                    key: 'tabs1',
                    type: 'tabs',
                },
                {
                    label: this._translateService.instant(
                        'EDIT_ASSIGNMENT.FORM.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    input: true,
                    defaultValue: false,
                    showValidations: false,
                    action: 'submit',
                },
            ],
        };
        if (this.id) {
            const externalCost = {
                label: this._translateService.instant(
                    'EDIT_ASSIGNMENT.FORM.EXTERNALCOSTS'
                ),
                key: 'tab5',
                components: [
                    {
                        label: '',
                        type: 'contentCustom',
                        component: FremdkostenComponent,
                        key: 'externalCost',
                    },
                ],
            };

            this.formioConfiguration.components[0].components.push(
                externalCost
            );
        }
    }

    public printBudgetTable(): void {
        this.budgetTableToPrint =
            this.formioComponent.formio._data.BudgetTable.map((item) => ({
                ...item,
                Date: item.TB_Datum ? new Date(item.TB_Datum) : null,
                TB_Skonto: item.Skonto
                    ? this._translateService.instant('EDIT_ASSIGNMENT.FORM.YES')
                    : this._translateService.instant('EDIT_ASSIGNMENT.FORM.NO'),
            }));
        setTimeout(() => this.excelTable.save(), 1000);
    }
}
