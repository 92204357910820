import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { DetailOrderComponent } from './detail-order.component';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';

const routes = [
    {
        path: 'new-order',
        component: DetailOrderComponent,
    },
    {
        path: 'orders/detail/basedata/:id',
        component: DetailOrderComponent,
    },
];

@NgModule({
    declarations: [DetailOrderComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
        MatIconModule,
        MatTabsModule,
    ],
    exports: [DetailOrderComponent],
})
export class DetailOrderModule {}
