export const locale = {
    lang: 'en',
    data: {
        INDEX_EMAIL: {
            ADDATTACHMENT: 'Add attachment',
            NOTIFICATIONS: {
                LOADING: 'Loading mails...',
                SAVING: 'Assigning mails...',
                WAIT: 'Please wait.',
                SUCCESS: 'Mails assigned successfully',
                ERROR: 'Error while assigning: ',
            },
            COLUMNS: {
                TITLE: 'Title',
                SUBJECT: 'Subject',
                TEXT: 'Message',
                TO: 'To',
                FROM: 'From',
                ATTACHMENTS: 'Attachments',
            },
            SAVE: 'Assign E-Mails',
            FOLDER: 'Select Folder',
            ASSIGN: 'Assign E-Mails',
            NEW: 'Assign E-Mails',
        },
    },
};
