export const locale = {
    lang: 'de',
    data: {
        LV_TASK: {
            TITLE: 'Aufgaben',
            SEARCH_LABEL: 'Nach einer Aufgabe suchen',
            ALL: 'Alle Aufgaben',
            MY: 'Meine Aufgaben',
            OVERDUEDATE: 'Überfällige Aufgaben',
            COLUMNS: {
                TITLE: 'Aufgabe',
                DUEDATE: 'Fälligkeitsdatum',
                STATUS: 'Status',
                TASK: 'Aufgabe',
                ASSIGNED: 'Zugewiesen an',
                PROJECT: 'Projekt',
                DESCRIPTION: 'Beschreibung',
                KIND: 'Aufgabenart',
                WORKPACKAGE: 'Arbeitspaket',
                START: 'Start',
                PRIORITY: 'Priorität',
                HIGH: '(1) Hoch',
                NORMAL: '(2) Normal',
                LOW: '(3) Niedrig',
                NOTSTARTED: 'Nicht begonnen',
                INPROCESSING: 'In Bearbeitung',
                COMPLETED: 'Abgeschlossen',
                RESET: 'Zurückgestellt',
                WAITING: 'Wartet auf jemand anderen',
                TYPE: 'Aufgabentyp',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                PROCESSSTEP: 'Prozessschritt',
            },
            NEW: 'Neue Aufgabe',
            EDIT: 'Aufgabe bearbeiten',
            SAVE: 'Speichern',
        },
    },
};
