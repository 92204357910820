export const locale = {
    lang: 'de',
    data: {
        PROJECTS: 'Projekte',
        BASE: 'Stammdaten',
        DOCS: 'Dokumente',
        INVOLVED: 'Beteiligte',
        TASKS: 'Arbeitspakete',
        KANBAN: 'Kanban',
        NOTES: 'Notizen',
        DESCRIPTION:
            'Zum Bearbeiten des Notizenes klicken Sie auf "Bearbeiten"',
        EDIT: 'Bearbeiten',
        PROJECTNUMBER: 'Projektnummer',
        SAVE: 'Speichern',
        LASTCHANGE: 'Letzte Änderung: ',
        NOTIFICATIONS: {
            NOCHANGES: 'Es wurden keine Änderungen vorgenommen',
            SAVING: 'Notizen werden gespeichert...',
            WAIT: 'Bitte warten.',
            SUCCESS: 'Notizen erfolgreich gespeichert.',
            ERROR: 'Fehler beim speichern: ',
            ERRORBYTES:
                'Fehler beim Speichern: Ihr Datensatz ist zu groß. Bitte komprimieren Sie Videos oder Bilder und versuchen Sie es erneut!',
        },
        PLACEHOLDER: 'Notizen hier eingeben ...',
    },
};
