export const locale = {
    lang: 'de',
    data: {
        PROCESSSTEPS: {
            SEARCH_LABEL: 'Nach einem Prozessschritt suchen',
            TITLE: 'Prozessschritte',
            NEW: 'Neuer Prozessschritt',
            SAVE: 'Speichern',
            EDIT: 'Prozessschritt bearbeiten',
            NOTIFICATIONS: {
                SAVING: 'Eintrag speichern',
                WAIT: 'Bitte warten Sie...',
                ERROR: 'Ein Fehler ist aufgetreten!',
                SUCCESS: {
                    EDIT: 'Prozessschritt wurde erfolgreich bearbeitet',
                    NEW: 'Neuer Prozessschritt wurde erstellt',
                },
            },
            COLUMNS: {
                TITLE: 'Name',
                STEP: 'Schritt',
                STATUS: 'Wechseln in Aufgabenstatus',
                NOTSTARTED: 'Nicht begonnen',
                INPROCESSING: 'In Bearbeitung',
                DEFAULTVALUE: {
                    TITLE: 'Standardwert',
                    YES: 'Ja',
                    NO: 'Nein',
                },
                COMPLETED: 'Abgeschlossen',
                RESET: 'Zurückgestellt',
                WAITING: 'Wartet auf jemand anderen',
            },
        },
    },
};
