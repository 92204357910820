export const locale = {
    lang: 'de',
    data: {
        LV_REPORT: {
            TITLE: 'Reports',
            SEARCH_LABEL: 'Nach einer Zeit suchen',
            ALLTIMES: 'Alle',
            COLUMNS: {
                DATE: 'Datum',
                PROJECTNUMBER: 'Projektnummer',
                MINUTES: 'Dauer (in Stunden)',
                MINUTESEXCEL: 'Dauer (in Minuten)',
                HOURS: 'Dauer (in Stunden)',
                COSTS: 'Kosten (in €)',
                PROJECT: 'Projekt',
                TASK: 'Aufgabe',
                AUTHOR: 'Mitarbeiter',
                DESCRIPTION: 'Beschreibung',
                SUM: 'Summe: ',
            },
        },
    },
};
