export const locale = {
  lang: "en",
  data: {
    LV_BILLS: {
      TITLE: "Reports",
      SEARCH_BILLS: "Search for a bill",
      ALLTIMES: "All",
      LOCATION: "Location",
      TEAM: "Team",
      BILLS: "Bills",
      COLUMNS: {
        PROJECTNUMBER: "Projektnummer",
        PROJECTTITLE: "Projekt",
        TITLE: "Rechnungstitel",
        DATE: "Datum",
        YEAR: "Jahr",
        NETTO: "Nettobetrag",
        MONTH: "Monat",
        TEAM: "Team",
        BILL_NUMBER: 'Rechnungsnummer'
      },
      NOTIFICATIONS: {
        IMPORT_NO_BILLS: 'No bills to import'
      }
    },
    TABLE: {
      SUM: "Sum",
      NAME: "Teamname",
      AMOUNT: "Target amount",
      DIFFERENCE: "Difference",
      IMPORT_BUTTON: "Rechnungsimport",
      IMPORT_PDF_BUTTON: "Rechnungsdokumente-import"
    },
    FILTER: {
      YEAR: "Year",
      MONTH: {
        ALL: " ",
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      MONTH_: "Month",
      MONTHS: "Months",
      TIME: "Period",
      QUARTER_: "Quarter",
      QUARTER: {
        1: "1.Quarter",
        2: "2.Quarter",
        3: "3.Quarter",
        4: "4.Quarter",
      },
    },
    YEAR: "Year",
    LOCATION: "Location",
    TEAM: "Team",
  },
  IMPORT_BILLS_DIALOG: {
    OK: 'OK',
    LOADING: 'Loading',
    DUPLICATE: 'Duplikat',
    NO_PROJECT: 'Falsche Projektnummer',
    UNEXPECTED: 'Unerwarteter Fehler',
    IMPORTED: 'Fertig',
    STATUS: 'Status',
    BILL_NUMBER: 'Belegnr.',
    BILL_TITLE: 'Rechnungstitel',
    DATE: 'Rechnungsdatum',
    PROJECT_NUMBER: 'Projektnummer',
    MATCHCODE: 'Matchcode'
  },
  UPLOAD_PDF_BILLS_DIALOG: {
    TITLE: 'Upload PDF Bills',
    FILENAME: 'Title',
    OK: 'OK',
    LOADING: 'Loading',
    DUPLICATE: 'Duplikat',
    NO_PROJECT: 'Falsche Projektnummer',
    UNEXPECTED: 'Unerwarteter Fehler',
    IMPORTED: 'Fertig',
    STATUS: 'Status',
    BILL_NUMBER: 'Belegnr.',
    BILL_TITLE: 'Rechnungstitel',
    DATE: 'Datum',
    PROJECT_NUMBER: 'Projektnummer',
    MATCHCODE: 'Matchcode'
  }
};
