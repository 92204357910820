import { OrderService } from './../../services/order.service';
import { RequestService } from './../../services/request.service';
import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormioComponent } from '@formio/angular';
import { TranslateService } from '@ngx-translate/core';
import * as CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FrameworkService,
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';

import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { TablePositionComponent } from '../../position/table-position/table-position.component';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { LookupService } from '../../services/lookup.service';
import { getCustomerTitle } from 'src/app/main/shared/shared.util';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-detail-request',
    templateUrl: './detail-request.component.html',
    styleUrls: ['./detail-request.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailRequestComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public id: number;
    public itemTitle = '';
    public sharePointItem;
    public formioConfiguration;
    public triggerChange = false;
    public firstTimeLoad = true;
    public author;
    public createDate;

    public disableMark = false;
    public isCreateMode = true;
    public isAcceptor = false;
    public header;

    @ViewChild('formioComponent', { static: true })
    formioComponent: FormioComponent;

    constructor(
        public _applicationService: ApplicationService,
        public _updateService: UpdateService,
        public _requestService: RequestService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private _sharepointService: SharePointService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _frameworkService: FrameworkService,
        private _lookupService: LookupService,
        private _orderService: OrderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnDestroy() {
        this._lookupService.teams = [];
        this._lookupService.teamsFromLocation = [];
        this._lookupService.teamsFirstTimeLoad = [];
        this._lookupService.location = null;
        this._lookupService.locationLookUpId = null;
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            // if (this.id != this._requestService.currentRequestId) {
            this._requestService.getSideBarConfig(this.id);
            // }

            const promises = [];

            if (this.id) {
                // Edit mode
                this.isCreateMode = false;

                promises.push(
                    new Promise<void>(async (resolve) => {
                        // Get name of Genehmiger Group
                        this._updateService
                            .getPropertyValue(
                                'Standard Gruppen für Genehmigungen'
                            )
                            .then((value) => {
                                // Check if User is in Genehmiger Group
                                this._applicationService
                                    .isCurrentUserInGroup(value)
                                    .then((inGroup) => {
                                        if (inGroup) {
                                            this.isAcceptor = true;
                                        }
                                        resolve();
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        resolve();
                                    });
                            })
                            .catch((error) => {
                                console.error(error);
                                resolve();
                            });
                    })
                );

                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getItemById({ listTitle: 'Anträge', id: this.id })
                            .then((assignment) => {
                                this.sharePointItem = assignment;
                                this.itemTitle = this.sharePointItem.Title;
                                this._requestService.currentRequestId =
                                    this.sharePointItem.Id;
                                this._orderService.currentOrderId =
                                    this.sharePointItem.Id;

                                // Get author async
                                this._frameworkService
                                    .getUserOrGroupById(
                                        this.sharePointItem.AuthorId
                                    )
                                    .then((user: any) => {
                                        if (user.Title) {
                                            this.author =
                                                this._translateService.instant(
                                                    'DETAIL_REQUEST.FORM.CREATED1'
                                                ) + user.Title;
                                            this.createDate =
                                                this._translateService.instant(
                                                    'DETAIL_REQUEST.FORM.CREATED2'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleDateString() +
                                                this._translateService.instant(
                                                    'DETAIL_REQUEST.FORM.CREATED3'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleTimeString();
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                                // success
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
            } else {
                // Create mode
                this.itemTitle =
                    this._translateService.instant('DETAIL_REQUEST.NEW');
            }

            Promise.all(promises)
                .then(() => {
                    if (this.id) {
                        this.getLookUpInFirstLoad(this.sharePointItem)
                            .then(() => {
                                this.initGrid();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        this.initGrid();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    getLookUpInFirstLoad({ StandortId, TeamId }) {
        return new Promise<void>(async (resolve, reject) => {
            if (TeamId) {
                this._lookupService.teams = [this.sharePointItem.TeamId];
                const teamItem = await this._sharepointService.getListItems({
                    title: 'Teams',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .EqualTo(TeamId)
                        .ToString(),
                });

                this._lookupService.locationLookUpId =
                    teamItem[0]['StandortId'];
            }
            if (StandortId) {
                this._lookupService.location = StandortId;
                const teams = await this._sharepointService.getListItems({
                    title: 'Teams',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Standort')
                        .Id()
                        .EqualTo(StandortId)
                        .ToString(),
                });

                let teamId: number[] = [];
                teams.forEach((team) => {
                    teamId.push(team.Id);
                });
                if (!TeamId) {
                    this._lookupService.teamsFromLocation = [...teamId];
                } else {
                    this._lookupService.teamsFirstTimeLoad = [...teamId];
                }
            }
            resolve();
        });
    }

    onNavChange() {}

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_REQUEST.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('DETAIL_REQUEST.NOTIFICATIONS.WAIT')
        );
        // Remove not existing fields
        delete e.data.submit;
        delete e.data.canceledButton;
        delete e.data.NebenkostenEuro;
        delete e.data.Accept;
        delete e.data.Delete;

        if (!e.data.Ist_Auftrag) {
            e.data.Ist_Auftrag = false;
        }

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            // Edit mode
            return from(
                this._requestService
                    .updateOrder(e.data, this.sharePointItem.Id)
                    .then((request) => {
                        // Success
                        this._loadingService.hide();

                        this.sharePointItem = request;

                        this._snackBarService.add(
                            this._translateService.instant(
                                'DETAIL_REQUEST.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Create mode
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Anträge',
                        data: e.data,
                        folder: '',
                    })
                    .then((newItemId) => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Anträge',
                                id: +newItemId,
                            })
                            .then((item) => {
                                this.sharePointItem = item;

                                const promises = [
                                    this._requestService.createFoldersForRequests(
                                        this.sharePointItem
                                    ),
                                ];

                                Promise.all(promises)
                                    .then(() => {
                                        // Success
                                        this._loadingService.hide();

                                        this.router.navigate(
                                            [
                                                'requests/detail/basedata/' +
                                                    newItemId,
                                            ],
                                            {
                                                queryParamsHandling: 'merge',
                                            }
                                        );

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_REQUEST.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        // Set not existing columns
        let sum = dataForm.Antragssumme ? dataForm.Antragssumme : 0;
        let percent = dataForm.Nebenkosten ? dataForm.Nebenkosten : 0;
        dataForm.NebenkostenEuro = (sum / 100) * percent;

        // Add customEvent listeners
        this.formioComponent.customEvent.subscribe((event) => {
            this.handleFormioCustomEvents(event);
        });

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    handleFormioCustomEvents(event: any) {
        switch (event.type) {
            case 'delete':
                this.deleteRequest();
                break;
            case 'convert':
                this.convertToOrder(event);
                break;
            case 'canceled':
                this.cancelRequest();
                break;
        }
    }

    convertToOrder(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_REQUEST.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant(
                'DETAIL_REQUEST.NOTIFICATIONS.CONVERT.WAIT'
            )
        );

        // Set Ist_Auftrag to true
        e.data.Ist_Auftrag = true;

        // Remove not existing fields
        delete e.data.submit;
        delete e.data.canceledButton;
        delete e.data.NebenkostenEuro;
        delete e.data.Accept;
        delete e.data.Delete;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        return from(
            this._requestService
                .updateOrder(e.data, this.sharePointItem.Id)
                .then((order) => {
                    // Success
                    this._loadingService.hide();

                    this.sharePointItem = order;

                    this.router.navigate(
                        ['orders/detail/basedata/' + this.id],
                        {
                            queryParamsHandling: 'merge',
                        }
                    );

                    this._snackBarService.add(
                        this._translateService.instant(
                            'DETAIL_REQUEST.NOTIFICATIONS.CONVERT.SUCCESS'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );
                })
                .catch((error) => {
                    this._loadingService.hide();
                    console.error(error);
                })
        );
    }

    deleteRequest() {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_REQUEST.NOTIFICATIONS.DELETING'
            ),
            this._translateService.instant('DETAIL_REQUEST.NOTIFICATIONS.WAIT')
        );

        this._sharepointService
            .recycleItem({
                listTitle: 'Anträge',
                id: this.id,
            })
            .then(() => {
                this._loadingService.hide();

                // Success
                this._snackBarService.add(
                    this._translateService.instant(
                        'DETAIL_REQUEST.NOTIFICATIONS.SUCCESSDELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                // Navigate to listview-order
                this.router.navigate(['requests'], {
                    queryParamsHandling: 'merge',
                });
            })
            .catch((error) => {
                this._loadingService.hide();
                console.error(error);
            });
    }

    cancelRequest() {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_REQUEST.NOTIFICATIONS.CANCELING'
            ),
            this._translateService.instant('DETAIL_REQUEST.NOTIFICATIONS.WAIT')
        );

        this._sharepointService
            .updateItem({
                listTitle: 'Anträge',
                id: this.id,
                newFiledValues: {
                    Abgesagt: true,
                },
            })
            .then(() => {
                this._loadingService.hide();
                this.sharePointItem.Abgesagt = true;

                // Hide Button
                this._frameworkService.updateFormioComponentProperty(
                    this.formioComponent,
                    'canceledButton',
                    'hidden',
                    true
                );

                // Success
                this._snackBarService.add(
                    this._translateService.instant(
                        'DETAIL_REQUEST.NOTIFICATIONS.SUCCESSCANCELED'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
            })
            .catch((error) => {
                this._loadingService.hide();
                console.error(error);
            });
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }
            if (ev.changed.component.key === 'Nebenkosten') {
                // Get form values
                const data = JSON.parse(JSON.stringify(ev.data));

                let sum = data.Antragssumme ? data.Antragssumme : 0;
                let percent = ev.changed.value;

                data.NebenkostenEuro = (sum / 100) * percent;

                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            } else if (ev.changed.component.key === 'Antragssumme') {
                // Get form values
                const data = JSON.parse(JSON.stringify(ev.data));

                let sum = ev.changed.value;
                let percent = data.Nebenkosten ? data.Nebenkosten : 0;

                data.NebenkostenEuro = (sum / 100) * percent;

                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            }
        }
        if (ev.add) {
            if (this._lookupService.teamsFirstTimeLoad.length) {
                this._lookupService.teams = [
                    ...this._lookupService.teamsFirstTimeLoad,
                ];
                //Has to be set to [] after first changen
                this._lookupService.teamsFirstTimeLoad = [];
            }
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = ev.Id;
                this._sharepointService
                    .getListItems({
                        title: 'Teams',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .LookupField('Standort')
                            .Id()
                            .EqualTo(ev.Id)
                            .ToString(),
                    })
                    .then((results) => {
                        const teams = results;
                        let teamId: number[] = [];
                        teams.forEach((team) => {
                            teamId.push(team.Id);
                        });
                        this._lookupService.teamsFromLocation = [...teamId];
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this._lookupService.teams = [ev.Id];
                this._lookupService.locationLookUpId = ev.StandortId;
            }
        } else if (ev.remove) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
                if (this._lookupService.teamsFirstTimeLoad.length) {
                    this._lookupService.teams = [
                        ...this._lookupService.teamsFirstTimeLoad,
                    ];
                    //Has to be set to [] after first change
                    this._lookupService.teamsFirstTimeLoad = [];
                }
            } else {
                this._lookupService.teamsFirstTimeLoad = [];
                this._lookupService.teams = [];
                this._lookupService.locationLookUpId = null;
            }
        }
    }

    initGrid() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Tabs',
                    key: 'tabs1',
                    type: 'tabs',
                    onTabChanged: (control, ev) => {
                        //
                    },
                    components: [
                        {
                            // Tab 1
                            label: this._translateService.instant(
                                'DETAIL_REQUEST.BASE'
                            ),
                            key: 'tab1',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.DATE'
                                    ),
                                    type: 'datetime',
                                    key: 'Antragsdatum',
                                    enableTime: false,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    format: 'dd.MM.yyyy',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone:
                                            'viewer',
                                        submissionTimezone:
                                            'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings:
                                            false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    customOptions: {
                                        locale: German
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.DESCRIPTION'
                                    ),
                                    type: 'textfield',
                                    key: 'Title',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.CLIENT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'AuftraggeberId',
                                    list: 'Firmen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    formatTitle: getCustomerTitle,
                                    isCreateMode: this.isCreateMode,
                                    addComponent: DetailCustomerComponent
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.TAXER'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.TAXERDESCRIPTION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektsteuererId',
                                    list: 'Firmen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    formatTitle: getCustomerTitle,
                                    addComponent: DetailCustomerComponent,
                                },
                                {
                                    // Columns 1
                                    label: 'Columns',
                                    type: 'columns',
                                    key: 'columns1',
                                    columns: [
                                        {
                                            // Column 1
                                            type: 'column',
                                            key: 'column1',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.COSTCENTRE'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    key: 'KostenstelleId',
                                                    list: 'Kostenstellen',
                                                    searchQueryIfIsEmpty:
                                                        new CamlBuilder()
                                                            .Where()
                                                            .NumberField('ID')
                                                            .NotEqualTo(-1)
                                                            .OrderBy('Title')
                                                            .ToString(),
                                                    isPeoplePicker: false,
                                                    removable: true,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 2
                                            type: 'column',
                                            key: 'column2',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.TEAM'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    key: 'TeamId',
                                                    list: 'Teams',
                                                    searchQueryIfIsEmpty:
                                                        this._lookupService
                                                            .teamCAMLQuery,
                                                    isPeoplePicker: false,
                                                    removable: true,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                    onSelected: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.add = true;
                                                        data.remove = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );
                                                    },
                                                    onRemoved: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.remove = true;
                                                        data.add = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.AREA'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'FachbereichId',
                                    list: 'Fachbereiche',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.locationCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.ACCREDITED'
                                    ),
                                    type: 'checkbox',
                                    key: 'Akkreditiert',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.MANAGER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektleiterId',
                                    isPeoplePicker: true,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.CLERK'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'SachbearbeiterId',
                                    multi: true,
                                    isPeoplePicker: true,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },

                                {
                                    // Columns 2
                                    label: 'Columns',
                                    type: 'columns',
                                    key: 'columns2',
                                    columns: [
                                        {
                                            // Column 1
                                            type: 'column',
                                            key: 'column1',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.SUM'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_REQUEST.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'Antragssumme',
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            // Column 2
                                            type: 'column',
                                            key: 'column2',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_REQUEST.FORM.INPERCENT'
                                                        ),
                                                    type: 'number',
                                                    key: 'Nebenkosten',
                                                },
                                            ],
                                        },
                                        {
                                            // Column 3
                                            type: 'column',
                                            key: 'column3',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_REQUEST.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'NebenkostenEuro',
                                                    disabled: true,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 4
                                            type: 'column',
                                            key: 'column4',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_REQUEST.FORM.EXTERNAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_REQUEST.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'Fremdleistung',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: '',
                                    type: 'contentCustom',
                                    component: TablePositionComponent,
                                    key: '',
                                    hidden: !this.id ? true : false,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.COPY'
                                    ),
                                    type: 'checkbox',
                                    key: 'Kopie',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.CONFIRMATION'
                                    ),
                                    type: 'checkbox',
                                    key: 'Auftragsbestaetigung',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.FOLDER'
                                    ),
                                    type: 'checkbox',
                                    key: 'Ordner',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.XMAS'
                                    ),
                                    type: 'checkbox',
                                    key: 'Weihnachtskarte',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.HANDINGOVER'
                                    ),
                                    type: 'datetime',
                                    key: 'Uebergabe',
                                    disabled: true,
                                    enableTime: false,
                                    hidden:
                                        !this.sharePointItem ||
                                        !this.sharePointItem.Genehmigt,
                                    format: 'dd.MM.yyyy',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone:
                                            'viewer',
                                        submissionTimezone:
                                            'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings:
                                            false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    customOptions: {
                                        locale: German
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    // Columns 3
                    label: 'Columns',
                    type: 'columns',
                    key: 'columns3',
                    customClass: 'button-row',
                    columns: [
                        {
                            // Column 1
                            type: 'column',
                            key: 'column1',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.SAVE'
                                    ),
                                    size: 'small',
                                    type: 'button',
                                    action: 'submit',
                                },
                            ],
                        },
                        {
                            // Column 2
                            type: 'column',
                            key: 'column2',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.CANCELED'
                                    ),
                                    size: 'small',
                                    type: 'buttonCustom',
                                    key: 'canceledButton',
                                    hidden:
                                        !this.id ||
                                        this.sharePointItem.Abgesagt,
                                    onClick: (e, data) => {
                                        this.handleFormioCustomEvents({
                                            data,
                                            type: 'canceled',
                                        });
                                    },
                                },
                            ],
                        },
                        {
                            // Column 3
                            type: 'column',
                            key: 'column3',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.CONVERT'
                                    ),
                                    size: 'small',
                                    type: 'button',
                                    action: 'event',
                                    event: 'convert',
                                    hidden: !this.id || false,
                                },
                            ],
                        },
                        {
                            // Column 4
                            type: 'column',
                            key: 'column4',
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_REQUEST.FORM.DELETE'
                                    ),
                                    customClass: 'delete',
                                    size: 'small',
                                    type: 'button',
                                    action: 'event',
                                    event: 'delete',
                                    key: 'Delete',
                                    hidden:
                                        !this.isAcceptor ||
                                        this.isCreateMode ||
                                        (this.sharePointItem &&
                                            this.sharePointItem.Genehmigt),
                                },
                            ],
                        },
                    ],
                },
                // Columns 3
            ],
        };
    }
}
