<h1 matDialogTitle>{{ "ADDFOLDERDIALOG.ADDFOLDER" | translate }}</h1>
<div mat-dialog-content>
  {{this.confirmMessage}}
</div>
<div mat-dialog-actions class="pt-24">

  <mat-form-field>
    <input matInput type="text" [(ngModel)]="value" placeholder="{{placeHolder}}" autocomplete="off">
    <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <br>
  <button mat-raised-button class="mat-accent mr-4" (click)="save()" cdkFocusInitial color="accent">{{
    "ADDFOLDERDIALOG.OK" | translate }}</button>
  <button mat-button (click)="onNoClick()">{{ "ADDFOLDERDIALOG.CANCEL" | translate }}</button>
</div>
