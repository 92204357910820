export const locale = {
    lang: 'en',
    data: {
        MOVE_ELEMENT: {
            MOVE: 'Move Document',
            WORKPACKAGE: {
                FROM: 'from (Workpackage/Task): ',
                TO: 'to (Workpackage/Task):',
            },
            NOTIFICATION: {
                NOCHANGE_ERROR:
                    'You have to change the project and/or the workpackage/task!',
                WAIT: 'Please wait...',
                MOVE: 'Moving Document.',
                SUCCESS: 'Successfully moved Document.',
            },
            PROJECT: {
                FROM: 'from (Project): ',
                TO: 'to (Project): ',
            },
            SAVE: 'Move Element',
        },
    },
};
