import { IList } from '@pnp/sp/lists';
import CamlBuilder from 'camljs';
import { FrameworkService } from 'sp-office365-layout';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1143Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {}

    async run() {
        try {
            const roleList: IList =
                await this._sharePointService.web.lists.getByTitle(
                    'Berechtigungsgruppen'
                );

            // Update Berechtigungsgruppe to multi
            await this._updateService.updateFieldProperty(
                roleList,
                'Berechtigungsgruppe',
                { AllowMultipleValues: true },
                'SP.FieldUser'
            );

            // Delete Entries in Properties
            let items = await this._sharePointService.getListItems({
                title: 'Properties',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('Title')
                    .EqualTo('Standard Gruppen für Genehmigungen')
                    .ToString(),
            });

            items.forEach((item) => {
                this._sharePointService.recycleItem({
                    listTitle: 'Properties',
                    id: item.Id,
                });
            });

            // Delete Entries in Berechtigungsgruppen
            items = await this._sharePointService.getListItems({
                title: 'Berechtigungsgruppen',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('Title')
                    .EqualTo('Standard Gruppe für Genehmigungen')
                    .ToString(),
            });

            items.forEach((item) => {
                this._sharePointService.recycleItem({
                    listTitle: 'Berechtigungsgruppen',
                    id: item.Id,
                });
            });

            items = await this._sharePointService.getListItems({
                title: 'Berechtigungsgruppen',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('Title')
                    .EqualTo('Standard Gruppe für Bearbeitungsberechtigungen')
                    .ToString(),
            });

            items.forEach((item) => {
                this._sharePointService.recycleItem({
                    listTitle: 'Berechtigungsgruppen',
                    id: item.Id,
                });
            });

            // Get Gruppe Genehemigungsgruppe
            let approverGroup: any;
            try {
                approverGroup = await this._frameworkService.getSiteGroupByName(
                    'Genehemigungsgruppe'
                );
            } catch (error) {
                console.error(error);
            }

            // Get Gruppe Verwaltung
            let managerGroup: any;
            try {
                managerGroup = await this._frameworkService.getSiteGroupByName(
                    'Verwaltung'
                );
            } catch (error) {
                console.error(error);
            }

            // Add Entries in Berechtigungsgruppen: Genehmiger, Controlling
            let dataApprover: any = { Title: 'Genehmiger' };
            if (approverGroup) {
                dataApprover.BerechtigungsgruppeId.results = [approverGroup.Id];
            }

            let dataManager: any = { Title: 'Controlling' };
            if (managerGroup) {
                dataManager.BerechtigungsgruppeId.results = [managerGroup.Id];
            }

            this._sharePointService.addItem({
                listTitle: 'Berechtigungsgruppen',
                folder: '',
                data: dataApprover,
            });

            this._sharePointService.addItem({
                listTitle: 'Berechtigungsgruppen',
                folder: '',
                data: dataManager,
            });

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
