export const locale = {
  lang: "de",
  data: {
    LV_BILLS: {
      TITLE: "Reports",
      ALLTIMES: "Alle",
      BILLS: "Rechnungen",
      LOCATION: "Standort",
      TEAM: "Team",
      SEARCH_BILLS: "Nach Rechnungen suchen",
      COLUMNS: {
        PROJECTNUMBER: "Projektnummer",
        PROJECTTITLE: "Projekt",
        TITLE: "Rechnungstitel",
        DATE: "Datum",
        YEAR: "Jahr",
        NETTO: "Nettobetrag",
        MONTH: "Monat",
        TEAM: "Team",
        BILL_NUMBER: 'Rechnungsnummer'
      },
      NOTIFICATIONS: {
        IMPORT_NO_BILLS: 'Es gibt keine Rechnungen'
      }
    },
    TABLE: {
      SUM: "Rechnungssumme",
      NAME: "Teamname",
      AMOUNT: "Sollbetrag",
      DIFFERENCE: "Differenz",
      IMPORT_BUTTON: "Rechnungsimport",
      IMPORT_PDF_BUTTON: "Rechnungsdokumente-import"
    },
    FILTER: {
      YEAR: "Jahr",
      MONTH: {
        ALL: " ",
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      MONTH_: "Monat",
      MONTHS: "Monate",
      TIME: "Zeitraum",
      QUARTER_: "Quartal",
      QUARTER: {
        1: "1.Quartal",
        2: "2.Quartal",
        3: "3.Quartal",
        4: "4.Quartal",
      },
      YEAR_FROM: "Jahr (von)",
      YEAR_UNTIL: "Jahr (bis)",
      LOCATION: "Standort",
      TEAM: "Team",
    },
    IMPORT_BILLS_DIALOG: {
      OK: 'OK',
      LOADING: 'Loading',
      DUPLICATE: 'Duplikat',
      NO_PROJECT: 'Falsche Projektnummer',
      UNEXPECTED: 'Unerwarteter Fehler',
      IMPORTED: 'Fertig',
      STATUS: 'Status',
      BILL_NUMBER: 'Belegnr.',
      BILL_TITLE: 'Rechnungstitel',
      DATE: 'Rechnungsdatum',
      PROJECT_NUMBER: 'Projektnummer',
      MATCHCODE: 'Matchcode'
    },
    UPLOAD_PDF_BILLS_DIALOG: {
      TITLE: 'Upload PDF Bills',
      FILENAME: 'Title',
      OK: 'OK',
      LOADING: 'Loading',
      DUPLICATE: 'Duplikat',
      NO_PROJECT: 'Falsche Projektnummer',
      UNEXPECTED: 'Unerwarteter Fehler',
      IMPORTED: 'Fertig',
      STATUS: 'Status',
      BILL_NUMBER: 'Belegnr.',
      BILL_TITLE: 'Rechnungstitel',
      DATE: 'Datum',
      PROJECT_NUMBER: 'Projektnummer',
      MATCHCODE: 'Matchcode'
    }
  },
};
