import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NewTenantMigrationService } from '../new-tenant-migration.service';
import { SharePointService } from 'sp-office365-framework';

@Component({
  selector: 'app-new-tenant-migration',
  templateUrl: './new-tenant-migration.component.html',
  styleUrls: ['./new-tenant-migration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NewTenantMigrationComponent implements OnInit {


  constructor(public newTenantMigrationService: NewTenantMigrationService, private sharePointService: SharePointService) {
  }

  ngOnInit(): void {
  }

  public addProjectDocumentsFolderPrefix(): void {
    this.newTenantMigrationService.addDocumentLibraryFolderPrefix('Auftragsdokumente');
  }

  public removeProjectDocumentsFolderPrefix(): void {
    this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Auftragsdokumente');
  }

  public addTaskDocumentsFolderPrefix(): void {
    this.newTenantMigrationService.addDocumentLibraryFolderPrefix('Aufgabendokumente');
  }

  public removeTaskDocumentsFolderPrefix(): void {
    this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Aufgabendokumente');
  }

  public fixProjectDocumentName(): void {
    this.newTenantMigrationService.fixDocumentLibraryFolderName('Auftragsdokumente');
  }

}
