<h1 matDialogTitle>{{ "CONFIRM_DIALOG.TITLE" | translate }}</h1>
<div mat-dialog-content>
  <formio class="pb-0 formio-material"
              [refresh]="refreshForm"
              [service]="this"
              [form]="formioConfiguration"
              [renderOptions]="formio_renderOptions"
              (ready)="formioReady()"
  >
  </formio>
</div>
<div mat-dialog-actions class="pt-24">
  <button mat-raised-button class="mat-accent mr-4" (click)="selectLocationHandler()" color="accent">
    {{ "CONFIRM_DIALOG.OK" | translate }}
  </button>
  <button mat-button mat-dialog-close>{{ "CONFIRM_DIALOG.CANCEL" | translate }}</button>
</div>
