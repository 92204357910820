import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterMailComponent } from './filter-mail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [FilterMailComponent],
    exports: [FilterMailComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class FilterMailModule {
}
