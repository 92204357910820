import { SharePointService } from 'sp-office365-framework';

export class UpdateToVersion1027Component {
    constructor(private sharePointService: SharePointService) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const propertyList = this.sharePointService.web.lists.getByTitle(
                'Properties'
            );

            const promises = [
                // Add Items to List Properties
                await propertyList.items.add({
                    Title: 'LogoUrl',
                }),
                await propertyList.items.add({
                    Title: 'FaviconUrl',
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
