export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Tasks',
        KANBAN: 'Kanban',
        NOTES: 'Notes',
        HEADER: 'Kanban',
        PROJECTNUMBER: 'Projectnumber',
    },
};
