export const locale = {
    lang: 'en',
    data: {
        LV_CUSTOMER: {
            TITLE: 'Customers',
            SEARCH_LABEL: 'Search customer',
            ALLCUSTOMERS: 'All',
            COLUMNS: {
                TITLE1: 'Customers 1',
                TITLE2: 'Customers 2',
                TITLE3: 'Customers 3',
                LOCATIONS: 'Location',
                STREET: 'Straße/Hausnummer',
                ZIP: 'PLZ',
                CITY: 'Ort',
                SAVE: 'Speichern',
                EMAIL: 'E-Mail',
                LAND: "Land"
            },
            NOTIFICATIONS: {
                LOADING: 'Loading data...',
                WAIT: 'Please wait.',
            },
            SAVE: 'Save',
        },
    },
};
