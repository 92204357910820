<div class="simple left-sidebar inner-sidebar inner-scroll partially-finished-services">
  <!-- HEADER -->
  <div
    class="header accent p-16 p-sm-24"
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center"
  >
    <!-- APP TITLE -->

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon
          class="logo-icon mr-4"
          [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
        >my_library_books
        </mat-icon>
        <span
          class="logo-text h1"
          [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
        >
          {{ "PARTIALLY_FINISHED_SERVICES.TITLE" | translate }}
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->
  </div>
  <!-- / HEADER -->

  <div id="content">
    <div class="content">
      <div class="p-6">
        <div class="slider">
          <span class="pr-4">{{'PARTIALLY_FINISHED_SERVICES.SLIDER_TITLE' | translate}}:</span>
          <mat-slider [min]="0.5"
                      [max]="1"
                      [step]="0.01"
                      showTickMarks
                      discrete
                      [(ngModel)]="sliderValue"
          >
            <input matSliderThumb />
          </mat-slider>
        </div>

        <div>
          <button mat-raised-button color="accent" (click)="exportPartiallyFinishedServices()">
            {{ "PARTIALLY_FINISHED_SERVICES.DOWNLOAD_PARTIAL_SERVICES_BUTTON" | translate }}
          </button>
          <button class="ml-2" mat-raised-button color="accent" (click)="exportFeeNotChargedProjects()">
            {{ "PARTIALLY_FINISHED_SERVICES.DOWNLOAD_FEE_NOT_CHARGED_PROJECTS_BUTTON" | translate }}
          </button>

          <kendo-excelexport #export1
                             [data]="data"
                             fileName="Teilfertigeleistungen.xlsx"
          >
            <ng-container *ngFor="let column of columns1">
              <kendo-excelexport-column
                  [field]="column.internalName"
                  [title]="column.title"
                  [width]="column.width || '150px'"
                  [cellOptions]="column.currency ? {format: currencyFormat} : null"
                  [headerCellOptions]="PROJECT_EVALUATION_HEADER_FORMAT"
              >
              </kendo-excelexport-column>
            </ng-container>
          </kendo-excelexport>

          <kendo-excelexport #export2
                             [data]="data"
                             fileName="Teilfertigeleistungen (Nicht berechnetes Honorar).xlsx"

          >
            <kendo-excelexport-column
              field="title"
              title="Projekt"
              [width]="200"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="budget"
              title="Gesamtbudget"
              [width]="200"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="timeCosts"
              title="Erbrachte Leistungen"
              [width]="200"
            >
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="calculated"
              title="Nicht berechnetes Honorar"
              [width]="200"
            >
            </kendo-excelexport-column>
          </kendo-excelexport>
        </div>

      </div>
    </div>
  </div>
</div>
