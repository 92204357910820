import { MatIconModule } from '@angular/material/icon';
import { EvaluationChartModule } from './../evaluation-chart/evaluation-chart.module';
import { FilterEvaluationComponent } from './../filter-evaluation/filter-evaluation.component';
import { NgModule } from '@angular/core';
import { ListviewEvaluationComponent } from './listview-evaluation.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { FilterEvaluationModule } from '../filter-evaluation/filter-evaluation.module';
import { EvaluationChartComponent } from '../evaluation-chart/evaluation-chart.component';

const routes = [
  {
      path: 'evaluation',
      component: ListviewEvaluationComponent,
  },
];

@NgModule({
    declarations: [ListviewEvaluationComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        FilterEvaluationModule,
        EvaluationChartModule,
        MatIconModule
    ],
    exports: [ListviewEvaluationComponent]
})
export class ListviewEvaluationModule { }
