import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
    SimpleSnackBar,
} from '@angular/material/snack-bar';

export class SnackBarMessage {
    message: string;
    action: string = null;
    config: MatSnackBarConfig = null;
}

@Injectable({
    providedIn: 'root',
})
export class SnackbarService implements OnDestroy {
    private messageQueue = [];
    private subscription: Subscription;
    private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
    private isInstanceVisible = false;

    constructor(public snackBar: MatSnackBar) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Add a message
     * @param message The message to show in the snackbar.
     * @param action The label for the snackbar action.
     * @param config Additional configuration options for the snackbar.
     */
    add(message: string, action?: string, config?: MatSnackBarConfig): void {
        if (!config) {
            config = new MatSnackBarConfig();
            config.duration = 10000;
        }

        if (!config.horizontalPosition) {
            config.horizontalPosition = 'end';
        }

        if (!config.verticalPosition) {
            config.verticalPosition = 'top';
        }

        const sbMessage = new SnackBarMessage();
        sbMessage.message = message;
        sbMessage.action = action;
        sbMessage.config = config;

        this.messageQueue.push(sbMessage);

        if (!this.isInstanceVisible) {
            this.showNext();
        }
    }

    showNext() {
        if (this.messageQueue.length == 0) {
            return;
        }

        this.isInstanceVisible = true;

        this.snackBarRef = this.snackBar.open(
            this.messageQueue[0].message,
            this.messageQueue[0].action,
            this.messageQueue[0].config
        );

        this.messageQueue.shift();

        this.snackBarRef.afterDismissed().subscribe(() => {
            this.isInstanceVisible = false;
            this.showNext();
        });
    }
}
