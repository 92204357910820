import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationChartComponent } from './evaluation-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';



@NgModule({
  declarations: [EvaluationChartComponent],
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  exports: [EvaluationChartComponent],
})
export class EvaluationChartModule { }
