import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';
import { ApplicationService } from 'src/app/main/services/application.service';
import { delay } from 'rxjs/operators';
import * as CamlBuilder from 'camljs';
import * as XLSX from 'xlsx';
import { BillsImportsStatus } from 'src/app/main/bills/enum/bills-imports-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { excelDateToJSDate } from '../shared/shared.util';
import { ExcelBillItem } from '../bills/models';
import { Bill } from '../shared/models';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class BillingImportService {
    constructor(
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService
    ) {
    }

    public importBillings(file: File): Observable<ExcelBillItem[]> {
        return new Observable(observer => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = event.target.result;
                const readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                dataParse.splice(0, 1);

                const billsData: ExcelBillItem[] = [];
                const {
                    projectNumberColumnPosition,
                    priceColumnPosition,
                    billTitleColumnPosition,
                    dateColumnPosition,
                    billNumberColumnPosition
                } = this.parseExcelColumnsPosition(dataParse[0] as string[]);

                dataParse
                    .filter((row: string | number[]) => row.length)
                    .forEach((row, index) => {
                        if (!index || !row[projectNumberColumnPosition] || !row[billTitleColumnPosition] || !row[dateColumnPosition] || !moment(row[dateColumnPosition]).isValid()) {
                            return;
                        }

                        billsData.push({
                            date: excelDateToJSDate(row[dateColumnPosition]),
                            docNumber: row[billNumberColumnPosition],
                            projectNumber: row[projectNumberColumnPosition],
                            billTitle: row[billTitleColumnPosition],
                            price: row[priceColumnPosition],
                            status: this._translateService.instant('IMPORT_BILLS_DIALOG.LOADING')
                        });
                    });
                observer.next(billsData);
                observer.complete();
            };
            reader.readAsBinaryString(file);
        });
    }

    private parseExcelColumnsPosition(columns: string[]): {
        dateColumnPosition: number;
        billNumberColumnPosition: number;
        billTitleColumnPosition: number;
        projectNumberColumnPosition: number;
        priceColumnPosition: number
    } {
        if (!columns || !columns.length) {
            return {
                billNumberColumnPosition: null,
                dateColumnPosition: null,
                billTitleColumnPosition: null,
                priceColumnPosition: null,
                projectNumberColumnPosition: null
            }
        }

        let dateColumnPosition = null;
        let billNumberColumnPosition = null;
        let projectNumberColumnPosition = null;
        let billTitleColumnPosition = null;
        let priceColumnPosition = null;

        columns.forEach((column, index) => {
            if (column === 'Datum') {
                dateColumnPosition = index;
            }

            if (column === 'Belegnr.') {
                billNumberColumnPosition = index;
            }

            if (column === 'Projektnummer') {
                projectNumberColumnPosition = index;
            }

            if (column === 'RG Art intern') {
                billTitleColumnPosition = index;
            }

            if (column === 'Netto H.') {
                priceColumnPosition = index;
            }
        });

        return {
            dateColumnPosition,
            billNumberColumnPosition,
            projectNumberColumnPosition,
            billTitleColumnPosition,
            priceColumnPosition
        }
    }

    public createNewBill(item: ExcelBillItem): Promise<any> {
        const fristDate = new Date(item.date);
        fristDate.setMonth(fristDate.getMonth() + 1);

        const payload: Partial<Bill> = {
            Title: item.billTitle,
            Von: new Date(item.date.getFullYear(), item.date.getMonth(), 1),
            Bis: new Date(item.date.getFullYear(), item.date.getMonth() + 1, 0),
            Frist: fristDate,
            Jahr: item.date.getFullYear(),
            Monat: item.date.getMonth() + 1,
            Rechnungsdatum: item.date,
            Abrechnungstyp: 'Pauschal',
            Forderung: item.price,
            Gezahlt: null,
            Offen: null,
            Rechnungsnummer: item.docNumber,
            StandortId: null,
            TeamId: null,
            ProjektId: null,
            AR_Number: null
        };

        return from(
            this._sharepointService
                .getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .Any([
                            CamlBuilder.Expression().TextField('Projektnummer').EqualTo(item.projectNumber),
                            CamlBuilder.Expression().TextField('OW_Nummer').EqualTo(item.projectNumber),
                        ])
                        .ToString(),
                })
                .then(data => {
                    if (data.length) {
                        const project = data[0];
                        payload.ProjektId = project.Id;
                        payload.StandortId = project.StandortId;
                        payload.TeamId = project.TeamId;
                        return this._applicationService.getARNumber(project.Id, null)
                            .then(async (ARNumber) => {
                                payload.AR_Number = ARNumber;

                                if (ARNumber !== 0) {
                                    payload.Title = payload.Title.replace('{R_NR}', payload.Rechnungsnummer);
                                }

                                const possibleDuplicateBills = await this._sharepointService.getListItems({
                                    title: 'Rechnungen',
                                    isDocumentLibrary: false,
                                    folderName: project.Id.toString(),
                                    camlQuery: new CamlBuilder()
                                        .Where()
                                        .TextField('Rechnungsnummer')
                                        .EqualTo(payload.Rechnungsnummer)
                                        .ToString(),
                                });

                                if (possibleDuplicateBills.length) {
                                    return Promise.resolve({
                                        ...payload,
                                        error: BillsImportsStatus.DUPLICATE,
                                        id: item.docNumber
                                    });
                                }

                                return this._sharepointService
                                    .addItem({
                                        listTitle: 'Rechnungen',
                                        data: payload,
                                        folder: project.Id.toString(),
                                    })
                                    .then(() => ({ ...payload, id: item.docNumber }));
                            })
                            .catch(() => {
                                return { ...payload, error: BillsImportsStatus.UNEXPECTED, id: item.docNumber };
                            });
                    } else {
                        return Promise.resolve({
                            ...payload,
                            error: BillsImportsStatus.NO_PROJECT,
                            id: item.docNumber
                        });
                    }
                }).catch((error) => console.error(error))
        ).pipe(delay(1500)).toPromise();
    }
}

