export const locale = {
    lang: 'en',
    data: {
        CONFIGURATION: {
            ADMIN: 'Administration',
            TITLE: 'COonfiguration',
            CATEGORIES: {
                SEARCH_LABEL: 'Search for a category',
                TITLE: 'Categories',
                NEW: 'New category',
                EDIT: 'Edit category',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            SIGNATURES: {
                SEARCH_LABEL: 'Search for a signature',
                TITLE: 'Signatures',
                NEW: 'New signature',
                EDIT: 'Edit signature',
                COLUMNS: {
                    TITLE: 'Name',
                    TEXT: 'Signature',
                },
            },
            SALUTATIONS: {
                SEARCH_LABEL: 'Search for a salutation',
                TITLE: 'Salutations',
                NEW: 'New salutation',
                EDIT: 'Edit salutation',
                COLUMNS: {
                    TITLE: 'Salutation',
                },
            },
            COSTROLES: {
                SEARCH_LABEL: 'Search for a costrole',
                TITLE: 'Costroles',
                NEW: 'New costrole',
                EDIT: 'Edit costrole',
                COLUMNS: {
                    TITLE: 'Costrole',
                    COSTSPERHOUR: 'Costs per hour',
                    INEURO: '(in Euro)',
                    ABBREVIATION: 'Abbreviation',
                },
            },
            EMPLOYEES: {
                SEARCH_LABEL: 'Search for an employee',
                TITLE: 'Employees',
                NEW: 'New employee',
                EDIT: 'Edit employee',
                COLUMNS: {
                    USER: 'User',
                    HOURSPERDAY: 'Hours per day',
                    INHOURS: '(in hours)',
                    COSTROLE: 'Costrole',
                },
            },
            PERMISSIONS: {
                TITLE: 'Permission groups',
                COLUMNS: {
                    TITLE: 'Role',
                    VALUE: 'Permission group',
                },
            },
            PROCESSSTEPS: {
                SEARCH_LABEL: 'Search for a processstep',
                TITLE: 'Processsteps',
                NEW: 'New processstep',
                EDIT: 'Edit processstep',
                COLUMNS: {
                    TITLE: 'Name',
                    STEP: 'Step',
                    STATUS: 'Change to taskstatus',
                    NOTSTARTED: 'Not started',
                    INPROCESSING: 'In processing',
                    COMPLETED: 'Completed',
                    DEFAULTVALUE: {
                        TITLE: 'Default value',
                        YES: 'Yes',
                        NO: 'No',
                    },
                    RESET: 'Reset',
                    WAITING: 'Waiting',
                },
            },
            TASKTYPES: {
                SEARCH_LABEL: 'Search for a tasktype',
                TITLE: 'Tasktypes',
                NEW: 'New tasktype',
                EDIT: 'Edit tasktype',
                COLUMNS: {
                    TITLE: 'Name',
                    COLOR: 'Color',
                },
            },
            TASKTEMPLATES: {
                SEARCH_LABEL: 'Search for a tasktemplate',
                TITLE: 'Tasktemplate',
                NEW: 'New tasktemplate',
                EDIT: 'Edit tasktemplate',
                COLUMNS: {
                    TITLE: 'Name',
                    DESCRIPTION: 'Description',
                    OFFSETSTART: 'Addition to the startdate',
                    INDAYS: '(in days)',
                    OFFSETEND: 'Addition to the duedate',
                    PRIORITY: 'Priority',
                    HIGH: '(1) High',
                    NORMAL: '(2) Normal',
                    LOW: '(3) Low',
                    PROCESSSTEP: 'Processstep',
                    TYPE: 'Tasktype',
                    SCHEDULEDTIME: 'Scheduled time',
                    INHOURS: '(in hours)',
                    OFFSETSTARTTYPE: 'Type of addition to the startdate',
                    OFFSETENDTYPE: 'Type of addition to the duedate',
                    TODAY: 'Since today',
                    START: 'Since start of workpackage',
                    END: 'From end of workpackage',
                    STARTASSIGNMENT: 'Since start of project',
                    ENDASSIGNMENT: 'From end of project',
                },
            },
            NOTIFICATIONS: {
                SEARCH_LABEL: 'Search for a notification',
                TITLE: 'Notificationa',
                NEW: 'New notification',
                EDIT: 'Edit notification',
                COLUMNS: {
                    TITLE: 'Title',
                    LIST: 'List',
                    INTERVALL: 'Intervall',
                },
                WAIT: 'Bitte warten',
                SUCCESS: 'Das Ereignis wurde erfolgreich gespeichert',
                ERROR: 'Importfehler',
            },
            TEAMS: {
                SEARCH_LABEL: 'Search for a team',
                TITLE: 'Teams',
                NEW: 'New team',
                EDIT: 'Edit team',
                COLUMNS: {
                    TITLE: 'Name',
                    GROUP: 'Group',
                    LOCATION: 'Location',
                    TEAMMANAGER: 'Teammanager',
                    AVAILABLEFORCALCULATION: 'Relevant für Auswertung'
                },
            },
            FACHBEREICHE: {
                SEARCH_LABEL: 'Search for a department',
                TITLE: 'Departments',
                NEW: 'New department',
                EDIT: 'Edit department',
                COLUMNS: {
                    TITLE: 'Name',
                    NUMBER: 'Number',
                },
            },
            LOCATIONS: {
                SEARCH_LABEL: 'Search for a location',
                TITLE: 'Locations',
                NEW: 'New location',
                EDIT: 'Edit location',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            LANDS: {
                SEARCH_LABEL: 'Nach einem Land suchen',
                TITLE: 'Länder',
                NEW: 'Neuer Land',
                EDIT: 'Land bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            COSTCENTRES: {
                SEARCH_LABEL: 'Search for a cost centre',
                TITLE: 'Cost centres',
                NEW: 'New cost centre',
                EDIT: 'Edit cost centre',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            NETWORKDRIVES: {
                SEARCH_LABEL: 'Search for a networkdrive',
                TITLE: 'Networkdrive',
                NEW: 'New networkdrive',
                EDIT: 'Edit networkdrive',
                COLUMNS: {
                    TITLE: 'Foldername',
                },
            },
            PROPERTIES: {
                SEARCH_LABEL: 'Search for a property',
                TITLE: 'Properties',
                NEW: 'New property',
                EDIT: 'Edit property',
                COLUMNS: {
                    TITLE: 'Title',
                    VALUE: 'Value',
                },
            },
            TASKSETS: {
                SEARCH_LABEL: 'Search for a taskset',
                TITLE: 'Tasksets',
                NEW: 'New taskset',
                EDIT: 'Edit taskset',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            HOLIDAYS: {
                TITLE: 'Feiertage',
                IMPORT_ICS: 'Import ICS File',
                COLUMNS: {
                    TITLE: 'Name',
                    DATE: 'Datum',
                    LOCATION: 'Standorte',
                    BUNDESLAND: 'Bundesland',
                    FAKTOR: 'Faktor',
                },
                SUCCESS: 'Der Feiertag wurde erfolgreich gestaltet',
            },
            SAVE: 'Save',
        },
    },
};
