export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Tasks',
        KANBAN: 'Kanban',
        NOTES: 'Notes',
        DESCRIPTION: 'To edit the notebook click "Edit"',
        EDIT: 'Edit',
        PROJECTNUMBER: 'Projectnumber',
        SAVE: 'Save',
        LASTCHANGE: 'Last change: ',
        NOTIFICATIONS: {
            NOCHANGES: 'No Changes were made',
            SAVING: 'Saving notes...',
            WAIT: 'Please wait.',
            SUCCESS: 'Notes saved successfully',
            ERROR: 'Error while saving: ',
            ERRORBYTES:
                'Error while saving: your data set is too big. Please compress videos or pictures and try again!',
        },
        PLACEHOLDER: 'Type notes here ...',
    },
};
