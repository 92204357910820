import { Injectable } from '@angular/core';
import { SPDocument } from 'src/app/main/shared/models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ChangeDocumentNameDialogComponent } from '../document/change-document-name/change-document-name-dialog/change-document-name-dialog.component';
import { ChangeDocumentStatus } from '../document/change-document-name/enum';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private _dialog: MatDialog) {}

    public openRenameDocumentDialog(
        projectId: number,
        document: SPDocument,
        mode: ChangeDocumentStatus
    ): Observable<void> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            projectId,
            mode,
            document: { ...document },
        };

        return this._dialog
            .open(ChangeDocumentNameDialogComponent, dialogConfig)
            .afterClosed();
    }

    public static initFilePathData(document: SPDocument): {
        fileExtension: string;
        oldFileName: string;
        filePath: string;
    } {
        const pathData = document.FileRef.split('/');
        const fileName = pathData.pop().split('.');

        return {
            fileExtension: fileName.pop(),
            oldFileName: fileName.join('.'),
            filePath: pathData.join('/') + '/',
        };
    }
}
