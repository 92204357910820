import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { FrameworkService, fuseAnimations, FuseTranslationLoaderService, } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent, FormioForm } from '@formio/angular';
import { FormioRefreshValue } from '@formio/angular/formio.common';
import * as CamlBuilder from 'camljs';

import { LookupService } from './../../services/lookup.service';
import { ReportService } from './../../services/report.service';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { CurrentUserService } from 'src/app/main/services/current-user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-filter-time',
    templateUrl: './filter-time.component.html',
    styleUrls: ['./filter-time.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FilterTimeComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de'
    };
    public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();
    public formioConfiguration: FormioForm;
    private firstTimeLoad = true;
    private sharePointItem;
    private isManager = false;
    private searchGroups: string[] = [];
    private destroy$ = new Subject();
    @ViewChild('formioComponent', { static: false }) formioComponent: FormioComponent;

    constructor(
        private _applicationService: ApplicationService,
        private _reportService: ReportService,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _updateService: UpdateService,
        private _lookupService: LookupService,
        private _frameworkService: FrameworkService,
        private currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnDestroy(): void {
        this._lookupService.reset();
    }

    ngOnInit(): void {
        this.listenTeamLeiter();
        const promises: Promise<void>[] = [];

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Usergruppen')
                    .then((value) => {
                        this.searchGroups = value;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        // Check if currentUser is Manager
        if (this._sharePointService.currentUser.IsBusinessOwner || this.currentUserService.isTeamProjectLeiter()) {
            this.isManager = true;
        }

        // Set default values
        this._reportService.startDate = this._reportService.startOfWeek(new Date());
        this._reportService.endDate = this._reportService.endOfWeek(new Date());
        this._reportService.userIds.push(this._sharePointService.currentUser.Id);

        // if (this._reportService.currentListView) {
        //     this._reportService.currentListView.changeQuery(
        //         this._reportService.getFilteredTimesCamlQuery()
        //     );
        // }

        Promise.all(promises)
            .then(() => this.initForm())
            .catch((error) => console.error(error));
    }

    public formioReady(): void {
        let dataForm;
        const emptyObj = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
            dataForm.Hotkeys = 'Diese Woche';
            dataForm.Start = this._reportService.startDate;
            dataForm.End = this._reportService.endDate;
            dataForm.UserId = this._reportService.userIds;
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm)
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    public onChange(event): void {
        if (event.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
            } else if (event.changed.component.key == 'Hotkeys') {
                const dataForm = this.formioComponent.formio._data;

                switch (event.changed.value) {
                    case 'Dieses Jahr':
                        this._reportService.startDate =
                            this._reportService.startOfYear(new Date());
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.endOfYear(new Date())
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Dieses Quartal':
                        this._reportService.startDate =
                            this._reportService.startOfQuarter(new Date());
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.endOfQuarter(new Date())
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Letzte 30 Tage':
                        this._reportService.startDate =
                            this._reportService.addDaysToDate(new Date(), -30);
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                new Date(new Date().toDateString())
                            ); // Today withou hours

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Diesen Monat':
                        this._reportService.startDate =
                            this._reportService.startOfMonth(new Date());
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.endOfMonth(new Date())
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Letzter Monat':
                        this._reportService.startDate =
                            this._reportService.startOfMonth(
                                new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() - 1
                                    )
                                )
                            );
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.endOfMonth(
                                    new Date(
                                        new Date().setMonth(
                                            new Date().getMonth() - 1
                                        )
                                    )
                                )
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Diese Woche':
                        this._reportService.startDate =
                            this._reportService.startOfWeek(new Date());
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.endOfWeek(new Date())
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                    case 'Heute':
                        this._reportService.startDate = new Date(
                            new Date().toDateString()
                        ); // Today without hours
                        this._reportService.endDate =
                            this._reportService.getEndOfTheDay(
                                this._reportService.startDate
                            );

                        dataForm.Start = this._reportService.startDate;
                        dataForm.End = this._reportService.endDate;

                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                }

                this._reportService.triggerFilterData();
            } else if (event.changed.component.key == 'Start') {
                if (event.changed.value) {
                    this._reportService.startDate = event.changed.value;

                    this._reportService.triggerFilterData();
                }
            } else if (event.changed.component.key == 'End') {
                if (event.changed.value) {
                    this._reportService.endDate = event.changed.value;

                    this._reportService.triggerFilterData();
                }
            }
        }

        const promises = [];
        if (event.add) {
            if (event['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = event.Id;
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getListItems({
                                title: 'Teams',
                                isDocumentLibrary: false,
                                camlQuery: new CamlBuilder()
                                    .Where()
                                    .LookupField('Standort')
                                    .Id()
                                    .EqualTo(event.Id)
                                    .ToString(),
                            })
                            .then((results) => {
                                let teamId: number[] = [];
                                results.forEach((team) => teamId.push(team.Id));
                                this._lookupService.teamsFromLocation = [...teamId];
                                resolve();
                            })
                            .catch((err) => {
                                console.log(err);
                                reject();
                            });
                    })
                );
            } else {
                this._lookupService.teams.push(event.Id);
                this._lookupService.locationLookUpId = event.StandortId;
            }
        } else if (event.remove) {
            if (event['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
            } else {
                this._lookupService.teams = this._lookupService.teams.filter((value) => value != event.Id);
                this._lookupService.locationLookUpId = null;
            }
        }
    }

    private updateCamlQuery(key: 'ProjektId' | 'TeamId' | 'StandortId'): void {
        let camlQuery;
        let searchCaml;

        if (key == 'ProjektId') {
            camlQuery = this._lookupService.projectCamlQuery;
            searchCaml = this._lookupService.projectSearchCamlQuery;
        } else {
            camlQuery =
                key == 'StandortId'
                    ? this._lookupService.locationCAMLQuery
                    : this._lookupService.teamCAMLQuery;
            searchCaml =
                key == 'StandortId'
                    ? this._lookupService.locationSearchCAMLQuery
                    : this._lookupService.teamSearchCAMLQuery;
        }
        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQueryIfIsEmpty',
            camlQuery
        );

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQuery',
            searchCaml
        );
    }

    private setDisabling(key: 'StandortId' | 'TeamId', value: boolean): void {
        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'disabled',
            value
        );
    }

    private initForm(): void {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.HOTBUTTONS'
                                    ),
                                    type: 'select',
                                    key: 'Hotkeys',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.THISYEAR'
                                                ),
                                                value: 'Dieses Jahr',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.THISQAURTAL'
                                                ),
                                                value: 'Dieses Quartal',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.LAST30'
                                                ),
                                                value: 'Letzte 30 Tage',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.THISMONTH'
                                                ),
                                                value: 'Diesen Monat',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.LASTMONTH'
                                                ),
                                                value: 'Letzter Monat',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.THISWEEK'
                                                ),
                                                value: 'Diese Woche',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER_TIME.COLUMNS.TODAY'
                                                ),
                                                value: 'Heute',
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.START'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    type: 'datetime',
                                    key: 'Start',
                                    suffix: true,
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy HH:mm',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    customOptions: {
                                        locale: German
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.END'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    type: 'datetime',
                                    key: 'End',
                                    suffix: true,
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy HH:mm',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    customOptions: {
                                        locale: German
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column3',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    isPeoplePicker: false,
                                    multi: false,
                                    removable: true,
                                    disabled: false,
                                    searchQueryIfIsEmpty: this._lookupService.locationCAMLQuery,
                                    searchQuery: this._lookupService.locationSearchCAMLQuery,
                                    onSelected: async (control, data) => {
                                        data.add = true;
                                        data.remove = false;
                                        this.onChange(data);
                                        this.updateCamlQuery('TeamId');
                                        this.updateCamlQuery('ProjektId');
                                        this._reportService.projectIds =
                                            await this._reportService.getProjectIdsFromLookUp(data.Id, 'Standort');
                                        this._reportService.triggerFilterData();
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;
                                        this.onChange(data);
                                        this.updateCamlQuery('TeamId');
                                        this.updateCamlQuery('ProjektId');
                                        this._reportService.projectIds = [];
                                        this._reportService.triggerFilterData();
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.TEAM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'TeamId',
                                    list: 'Teams',
                                    isPeoplePicker: false,
                                    multi: true,
                                    removable: true,
                                    disabled: false,
                                    searchQueryIfIsEmpty:
                                    this._lookupService.teamCAMLQuery,
                                    searchQuery:
                                    this._lookupService.teamSearchCAMLQuery,
                                    onSelected: async (control, data) => {
                                        data.add = true;
                                        data.remove = false;
                                        this.onChange(data);
                                        this.setDisabling('StandortId', true);
                                        this.updateCamlQuery('StandortId');
                                        this.updateCamlQuery('ProjektId');

                                        const projectIds =
                                            await this._reportService.getProjectIdsFromLookUp(
                                                data.Id,
                                                'Team'
                                            );

                                        if (this._lookupService.teams.length > 1) {
                                            this._reportService.projectIds.push(...projectIds);
                                        } else {
                                            this._reportService.projectIds = projectIds;
                                        }

                                        this._reportService.triggerFilterData();
                                    },
                                    onRemoved: async (control, data) => {
                                        data.remove = true;
                                        data.add = false;
                                        this.onChange(data);

                                        if (!this._lookupService.teams.length) {
                                            this.setDisabling(
                                                'StandortId',
                                                false
                                            );
                                        }

                                        this.updateCamlQuery('StandortId');
                                        this.updateCamlQuery('ProjektId');

                                        const projectIds =
                                            await this._reportService.getProjectIdsFromLookUp(
                                                data.Id,
                                                'Team'
                                            );
                                        if (this._lookupService.teams.length) {
                                            this._reportService.projectIds =
                                                this._reportService.projectIds.filter(
                                                    (id) =>
                                                        !projectIds.includes(id)
                                                );
                                        } else {
                                            if (this._lookupService.location) {
                                                this._reportService.projectIds =
                                                    await this._reportService.getProjectIdsFromLookUp(
                                                        this._lookupService
                                                            .location,
                                                        'Standort'
                                                    );
                                            } else {
                                                this._reportService.projectIds = [];
                                            }
                                        }

                                        this._reportService.triggerFilterData();
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.PROJECT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektId',
                                    list: 'Aufträge',
                                    isPeoplePicker: false,
                                    multi: true,
                                    removable: true,
                                    disabled: false,
                                    searchQueryIfIsEmpty:
                                        this._lookupService.projectCamlQuery,
                                    searchQuery:
                                        this._lookupService.projectSearchCamlQuery,
                                    formatTitle: (item) => `${item.Title} (${item.Projektnummer})`,
                                    onSelected: (control, data) => {
                                        this.setDisabling('StandortId', true);
                                        this.setDisabling('TeamId', true);

                                        if (this.formioComponent.formio._data.ProjektId.results.length > 1) {
                                            // Add projectId
                                            this._reportService.projectIds.push(data.Id);
                                        } else {
                                            this._reportService.projectIds = [data.Id];
                                        }

                                        this._reportService.triggerFilterData();
                                    },
                                    onRemoved: async (control, item) => {
                                        if (!this.formioComponent.formio._data.ProjektId.results.length) {
                                            this.setDisabling('TeamId', false);

                                            if (!this._lookupService.teams.length) {
                                                this.setDisabling('StandortId', false);
                                            }
                                        }
                                        if (this._reportService.projectIds.length > 1) {
                                            const removeIndex =
                                                this._reportService.projectIds
                                                    .map((element) => element)
                                                    .indexOf(item.Id);

                                            this._reportService.projectIds.splice(removeIndex, 1);
                                        } else if (this._lookupService.teams.length) {
                                            this._reportService.projectIds = [];
                                            const teamIds = this._lookupService.teams;
                                            let projectIds: number[] = [];
                                            for (let id of teamIds) {
                                                projectIds =
                                                    await this._reportService.getProjectIdsFromLookUp(
                                                        id,
                                                        'Team'
                                                    );
                                                this._reportService.projectIds.push(
                                                    ...projectIds.filter(
                                                        () =>
                                                            !this._reportService.projectIds.includes(id)
                                                    )
                                                );
                                            }
                                        } else if (!this._lookupService.location) {
                                            this._reportService.projectIds = [];
                                        } else if (this._lookupService.location) {
                                            this._reportService.projectIds =
                                                await this._reportService.getProjectIdsFromLookUp(
                                                    this._lookupService.location,
                                                    'Standort'
                                                );
                                        }

                                        this._reportService.triggerFilterData();
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'FILTER_TIME.COLUMNS.USER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'UserId',
                                    isPeoplePicker: true,
                                    multi: true,
                                    removable: this.isManager || this.currentUserService.isTeamLeiter(),
                                    searchGroups: this.getSearchGroups(),
                                    disabled: !this.isManager && !this.currentUserService.isTeamLeiter(),
                                    filteredOptionsFunction: async (value) => {
                                        return this._applicationService.searchUsers({
                                            value: value,
                                            searchGroups: this.getSearchGroups(),
                                        }).then(users => {
                                            if (this.currentUserService.isTeamLeiter() && !this.isManager) {
                                                users.push({
                                                    Id: this._sharePointService.currentUser.Id,
                                                    Title: this._sharePointService.currentUser.FullName,
                                                    LoginName: this._sharePointService.currentUser.AccountName
                                                });
                                            }

                                            return users;
                                        });
                                    },
                                    onSelected: (control, data) => {
                                        if (this._reportService.searchByAllTeamLeiterUsers) {
                                            this._reportService.searchByAllTeamLeiterUsers = false;
                                            this._reportService.userIds = [];
                                        }

                                        if (this._reportService.isDuplicateUser(data.Id)) {
                                            return;
                                        }

                                        // Add userId
                                        this._reportService.addUser(data.Id);

                                        if (this._reportService.currentListView) {
                                            // Check grouping for User
                                            if (this._reportService.userIds.length == 2) {
                                                this._reportService.addTableGroup({ field: 'Author', dir: 'desc' });
                                            } else if (this._reportService.userIds.length == 1) {
                                                this._reportService.removeTableGroup();
                                            }
                                            this._reportService.changeQuery();
                                        }
                                    },
                                    onRemoved: (control, item) => {
                                        this._reportService.removeUser(item.Id);

                                        if (this._reportService.currentListView) {
                                            if (this.currentUserService.isTeamLeiter() && !this.isManager && !this._reportService.userIds.length) {
                                                this._reportService.userIds = this.currentUserService.getTeamleiterUsersIds();
                                                this._reportService.searchByAllTeamLeiterUsers = true;
                                            }

                                            // Check grouping for User
                                            if (this._reportService.userIds.length == 1) {
                                                this._reportService.removeTableGroup();
                                            } else if (this._reportService.userIds.length == 0 || this._reportService.searchByAllTeamLeiterUsers) {
                                                this._reportService.addTableGroup({ field: 'Author', dir: 'desc' });
                                            }
                                            this._reportService.changeQuery();
                                        }
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
            ],
        };
    }

    private listenTeamLeiter(): void {
        this.currentUserService.isTeamLeiter$
            .pipe(takeUntil(this.destroy$))
            .subscribe(isTeamLeiter => {
                if (this.formioComponent?.formio?.components) {
                    this._frameworkService.updateFormioComponentProperty(
                        this.formioComponent,
                        'UserId',
                        'disabled',
                        !this.isManager && !isTeamLeiter
                    );
                }

                if (this.currentUserService.isTeamLeiter()) {
                    this.currentUserService.loadTeamleiterGroups().subscribe();
                }
            });
    }

    private getSearchGroups(): string[] {
        if (!this.isManager && this.currentUserService.isTeamLeiter()) {
            return this.currentUserService.teamLeiterGroups$.getValue();
        }

        return this.searchGroups;
    }
}
