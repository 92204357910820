import { FrameworkService } from 'sp-office365-layout';
import { FieldType, SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1148Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {
    }

    async run() {
        try {
            const assignmentBudgetsList = this._sharePointService.web.lists.getByTitle('Aufträge-Budgets');
            await this._updateService.runFunctionIfFieldNotExists(
                assignmentBudgetsList,
                'Skonto',
                async () => await this._updateService.addField(assignmentBudgetsList, 'Skonto', FieldType.Boolean)
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}
