import { RequestService } from './../../services/request.service';
import { Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { MailListComponent } from 'sp-office365-layout/lib/src/app/layout/components/mail/mail-list/mail-list.component';
import { AssignMailComponent } from '../../mail/assign-mail/assign-mail.component';
import { DetailMailComponent } from '../../mail/detail-mail/detail-mail.component';
import { IndexMailComponent } from '../../mail/index-mail/index-mail.component';
import { ReAssignMailComponent } from '../../mail/reassign-mail/reassign-mail.component';
import { MailService } from '../../services/mail.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-mails-request',
    templateUrl: './mails-request.component.html',
    styleUrls: ['./mails-request.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MailsRequestComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public mailConfigIn = { mails: [] };
    public mailConfigOut = { mails: [] };
    public mailConfigRequest = { mails: [] };
    private requestItem;
    public hide = false;
    public isAssignedMail = false;
    private destroy$ = new Subject();

    @ViewChild('mailOut', { static: false }) mailOut: MailListComponent;
    @ViewChild('mailIn', { static: false }) mailIn: MailListComponent;
    @ViewChild('mailRequest', { static: false }) mailRequest: MailListComponent;
    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

    constructor(
        public _requestService: RequestService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private _sharePointService: SharePointService,
        private _mailService: MailService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private zone: NgZone
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            // if (this.id != this._requestService.currentOrderId) {
            this._requestService.getSideBarConfig(this.id);
            // }

            if (this.id != null) {
                this._sharePointService
                    .getItemById({ listTitle: 'Anträge', id: this.id })
                    .then((request) => {
                        this.requestItem = request;
                        this.itemTitle = this.requestItem.Title;

                        this.loadMails();
                        this.mailRequest.setAsCurrentList();
                        this.isAssignedMail = true;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    onTabChange(ev) {
        //this.mailIn.resetDetails();
        this.mailOut.resetDetails();
        this.mailRequest.resetDetails();

        // Set current
        if (
            ev.tab.textLabel == this._translateService.instant('MAILREQUEST.IN')
        ) {
            this.mailIn.setAsCurrentList();
            this.isAssignedMail = false;
        } else if (
            ev.tab.textLabel ==
            this._translateService.instant('MAILREQUEST.OUT')
        ) {
            this.mailOut.setAsCurrentList();
            this.isAssignedMail = false;
        } else {
            this.mailRequest.setAsCurrentList();
            this.isAssignedMail = true;
        }
    }

    loadMails(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._sharePointService
                .getListItems({
                    title: 'Mailbox-Anträge',
                    isDocumentLibrary: false,
                    recursiveAll: false,
                    folderName: this.id + '',
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .OrderByDesc('Modified')
                        .ToString(),
                })
                .then((mails) => {
                    mails.forEach((element) => {
                        if (element.Title == 'out') {
                            this.pushMailItem(element, this.mailConfigOut);
                        } else if (element.Title == 'in') {
                            this.pushMailItem(element, this.mailConfigIn);
                        } else {
                            this.pushMailItem(element, this.mailConfigRequest);
                        }
                    });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    pushMailItem(item, config) {
        if (!item.From) {
            item.From = 'Unbekannt';
        }

        const promises = [];
        const attachments = [];
        if (item.Attachment_Urls) {
            let urls = item.Attachment_Urls.split(';');

            urls.forEach((element) => {
                if (element != '') {
                    let tmp = element.split('/');
                    let filename = tmp[tmp.length - 1];

                    attachments.push({
                        // type: file.odata.type,
                        fileName: filename,
                        // preview:
                        //     this._sharePointService.sphostUrl +
                        //     file.ServerRelativePath.DecodedUrl,
                        url:
                            this._sharePointService.sphostUrl.split(
                                '/sites/'
                            )[0] + element,
                        // size: '1.1Mb',
                    });
                }
            });
        } else if (item.Attachments) {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharePointService.web.lists
                        .getByTitle('Mailbox-Anträge')
                        .items.getById(item.Id)
                        .attachmentFiles()
                        .then((files) => {
                            files.forEach((file) => {
                                attachments.push({
                                    // type: file.odata.type,
                                    fileName: file.FileName,
                                    // preview:
                                    //     this._sharePointService.sphostUrl +
                                    //     file.ServerRelativePath.DecodedUrl,
                                    url:
                                        this._sharePointService.sphostUrl +
                                        '/Lists/' +
                                        file.ServerRelativePath.DecodedUrl.split(
                                            'Lists/'
                                        )[1],
                                    // size: '1.1Mb',
                                });
                            });

                            resolve();
                        })
                        .catch((error) => reject(error));
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                const mailSendTime = item.SentDateTime ? new Date(item.SentDateTime) : new Date(item.Created);
                config.mails.push({
                    id: item.Id,
                    from: {
                        name: item.From,
                        avatar: '',
                        email: item.From,
                    },
                    to: [
                        {
                            name: item.To,
                            email: item.To,
                        },
                    ],
                    cc: [
                        {
                            name: item.CC,
                            email: item.CC,
                        },
                    ],
                    subject: item.Subject,
                    message: item.Body,
                    time: mailSendTime.toLocaleString(),
                    timeAsDate: mailSendTime,
                    read: false,
                    starred: false,
                    important: false,
                    hasAttachments: item.Attachments,
                    attachments,
                    labels: [],
                    folder: 0,
                });
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this._mailService.sortMailsByDateDESC(config);
            });
    }

    send() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            requestId: this.requestItem.Id,
            project: this.requestItem
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    assign() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            requestId: this.requestItem.Id,
            isInComingEmail: this.isAssignedMail
        };

        this.zone.runOutsideAngular(() => {
            const dialogRef = this.dialog.open(AssignMailComponent, dialogConfig);

            dialogRef.afterClosed().subscribe((result) => {
                this._mailService.attachmentFiles = [];

                if (result && result.elements) {
                    result.elements.forEach((element) => {
                        if (this.isAssignedMail) {
                            this.pushMailItem(element, this.mailConfigRequest);
                        } else {
                            this.pushMailItem(element, this.mailConfigOut);
                        }
                    });
                }
            });
        });
    }

    index() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.requestItem.Id,
        };

        const dialogRef = this.dialog.open(IndexMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.elements) {
                //result.elements.forEach(element => {
                //    this.pushMailItem(element, this.mailConfigProject);
                //});
            }
        });
    }

    mailRE(replyAll?: boolean) {
        const currentMail = this.mailRequest.currentMail;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            requestId: this.requestItem.Id,
            isReply: true,
            replySubject: 'AW: ' + this.mailRequest.currentMail.subject,
            replyTo: this.mailRequest.currentMail.from.email, //{ results: [this.mailProject.currentMail.from.email], } ,
            project: this.requestItem,
            mailContent: currentMail.message,
            email: this.mailRequest.currentMail,
            replyAll
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    forwardTo(): void {
        const currentMail = this.mailRequest.currentMail;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            requestId: this.requestItem.Id,
            replySubject: 'FW: ' + this.mailRequest.currentMail.subject,
            mailContent: currentMail.message,
            project: this.requestItem,
            email: this.mailRequest.currentMail,
            forwardTo: true
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    reassign() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.requestItem.Id,
            antragsId: this.requestItem.AntragId,
        };

        const dialogRef = this.dialog.open(ReAssignMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.elements) {
                result.elements.forEach((element) => {
                    this.mailConfigRequest.mails =
                        this.mailConfigRequest.mails.filter(
                            (x) => x.id != element
                        );
                    this.mailRequest.mails = this.mailRequest.mails.filter(
                        (x) => x.id != element
                    );
                    this.mailConfigOut.mails = this.mailConfigOut.mails.filter(
                        (x) => x.id != element
                    );
                    this.mailOut.mails = this.mailOut.mails.filter(
                        (x) => x.id != element
                    );
                });
            }
            if (result && result.deletedItems) {
                result.deletedItems.forEach((element) => {
                    this.mailConfigRequest.mails =
                        this.mailConfigRequest.mails.filter(
                            (x) => x.id != element.Id
                        );
                    this.mailRequest.mails = this.mailRequest.mails.filter(
                        (x) => x.id != element.Id
                    );
                    this.mailConfigOut.mails = this.mailConfigOut.mails.filter(
                        (x) => x.id != element.Id
                    );
                    this.mailOut.mails = this.mailOut.mails.filter(
                        (x) => x.id != element.Id
                    );
                });
            }
        });
    }

    public getMailListBasedOnTab() {
        return this.matTabGroup.selectedIndex === 0 ? this.mailRequest : this.mailOut;
    }

    public filterEmail(filterFields: string[]): void {
      this.getMailListBasedOnTab().filterEmails(filterFields[0], filterFields[1], filterFields[2]);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }
}
