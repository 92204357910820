import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { NgModule } from '@angular/core';
import { ListviewWorkpackageComponent } from './listview-workpackage.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
    {
        path: 'workpackages',
        component: ListviewWorkpackageComponent,
    },
];

@NgModule({
    declarations: [ListviewWorkpackageComponent, DeleteConfirmationComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        MatButtonModule,
        MatDialogModule,
        LayoutModule,
        MatIconModule,
    ],
    exports: [ListviewWorkpackageComponent, DeleteConfirmationComponent]
})
export class ListviewWorkpackageModule {}
