<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "EDITPERMISSIONS" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "NEWPERMISSIONS" | translate }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <mat-slide-toggle id="defaultChange" (change)="defaultChange($event);" [checked]="this.hasDefaultPermission">
    {{ "DEFAULTPERMISSIONS" | translate }}
  </mat-slide-toggle>
  <br>

  <button *ngIf="this.hasDefaultPermission" mat-raised-button color="accent" (click)="saveSubmission($event)">
    {{ "SAVE" | translate }}
  </button>

  <div *ngIf="!this.hasDefaultPermission">

    <mat-label>{{ "NOTIFICATIONS.PERMISSIONS" | translate }}</mat-label>
    <mat-label id="defaultUserInfo" *ngFor="let user of this.defaultPermissionUser">{{ user + ', ' }}</mat-label>

    <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
      [renderOptions]="formio_renderOptions"
      (change)="onChange($event)">
    </formio>

  </div>

</div>
