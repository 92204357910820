import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1068Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const mailboxList = this._sharePointService.web.lists.getByTitle(
                'Mailbox'
            );

            const promises = [
                // Add Field ReadyToSend to List Mailbox
                await this._updateService.addField(
                    mailboxList,
                    'ReadyToSend',
                    FieldType.Boolean
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
