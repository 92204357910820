export const locale = {
    lang: 'en',
    data: {
        DETAIL_CATEGORY: {
            TITLE: 'Categories',
            NEW: 'New category',
            NEWDOCUMENT: 'New document',
            EDIT: 'Edit category',
            BASE: 'Basedata',
            SEARCH_LABEL: 'Search for a category',
            DOCUMENTS: 'Documents',
            COLUMNS: {
                TITLE: 'Title',
                FILESIZE: 'Filesize',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
            },
            NOTIFICATIONS: {
                SAVING: 'Saving category...',
                WAIT: 'Please wait.',
                SUCCESS: 'Category saved successfully',
                ERROR: 'Error while saving: ',
            },
            SAVE: 'Save',
        },
    },
};
