import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import { IList } from '@pnp/sp/lists';

export class UpdateToVersion1150Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Get list Dokumentenvorlagen
            const docTemplateList: IList =
                await this._sharePointService.web.lists.getByTitle(
                    'Dokumentenvorlagen'
                );

            // Add field Kategorie
            await this._updateService.addField(
                docTemplateList,
                'Kategorie',
                FieldType.Choice,
                null,
                ['Alle', 'Anfrage', 'Projekt']
            );

            return;
        } catch (error) {
            throw new Error(error);
        }
    }
}
