export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Invoices',
        INVOICES: 'Invoices',
        KANBAN: 'Kanban',
        PROJECTNUMBER: 'Projectnumber',
        NOTES: 'Notes',
        TABS: {
            WORKPACKAGES: 'Workpackages',
            TASKS: 'Tasks',
        },
        BUTTONS: {
            NEW: 'Add new invoice',
            NEWTASK: 'Add new task',
            MY: 'My workpackages',
            MYTASK: 'My tasks',
            CRITICAL: 'Critical workpackages',
            CRITICALTASK: 'Critical tasks',
        },
        COLUMNS: {
            TITLE: 'Costrole',
            DUEDATE: 'Duedate',
            STATUS: 'Status',
            TASK: 'Task',
            ASSIGNED: 'Assigned to',
        },
    },
};
