<div class="duration">
  <div class="mat-field-top-label-wrapper">
    <mat-label>{{ this.hoursLabel }}</mat-label>
    <mat-form-field floatLabel="always" class="example-form-field">
      
      <!-- <input
              matInput
              type="number"
              min="0"
              max="23"
              pattern="^$|^([0-9]|[0-1][0-9]|[2-2][0-3])?"
              [(ngModel)]="this.hours"
              autocomplete="off"
              required
              #hoursInput="ngModel"
          /> -->

      <input matInput type="number" min="0" [(ngModel)]="this.hours" autocomplete="off" required #hoursInput="ngModel" />

      <!-- <button mat-button *ngIf="this.hours" matSuffix mat-icon-button aria-label="Clear" (click)="this.hours=null">
        <mat-icon>close</mat-icon>
      </button> -->

      <mat-error *ngIf="
                  hoursInput.errors &&
                  hoursInput.errors.pattern &&
                  (hoursInput.dirty || hoursInput.touched)
              ">
        {{ this.hoursErrorMessage }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="mat-field-top-label-wrapper">
    <mat-label>{{ this.minutesLabel }}</mat-label>
    <mat-form-field class="example-form-field">    
      <input matInput type="number" min="0" max="55" step="5" pattern="^$|^([0-0]|[5-5]|[0-5]([0-0]|[5-5]))?"
        [(ngModel)]="this.minutes" autocomplete="off" required #minutesInput="ngModel" />
      <!-- <button mat-button *ngIf="this.minutes" matSuffix mat-icon-button aria-label="Clear" (click)="this.minutes=null">
        <mat-icon>close</mat-icon>
      </button> -->

      <mat-error *ngIf="
                  minutesInput.errors &&
                  minutesInput.errors.pattern &&
                  (minutesInput.dirty || minutesInput.touched)
              ">
        {{ this.minutesErrorMessage }}
      </mat-error>
    </mat-form-field>
  </div>
</div>

<div class="submition">
  <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" (click)="this.saveTime()">
    <mat-icon>check</mat-icon>
  </button>
</div>