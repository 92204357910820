export const locale = {
    lang: 'de',
    data: {
        MAILFILTER: {
          FILTER_EMAILS: 'Mails filtern',
          TO: 'Empfänger/CC',
          FROM: 'Absender'
        },
    },
};
