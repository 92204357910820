import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';
import * as CamlBuilder from 'camljs';
import { ApplicationService } from 'src/app/main/services/application.service';

export class UpdateToVersion1056Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const propertyList = this.sharePointService.web.lists.getByTitle(
                'Properties'
            );
            const projectList = this.sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            let mailboxList: IList;

            const promises = [
                // Add List Mailbox
                await this._updateService.addList('Mailbox', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Mailbox
                await new Promise<void>((resolve) => {
                    mailboxList = this.sharePointService.web.lists.getByTitle(
                        'Mailbox'
                    );
                    resolve();
                }),
                // Add Field Subject to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'Subject',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('Subject').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Body to List Mailbox
                await this._updateService.addField(
                    mailboxList,
                    'Body',
                    FieldType.MultilineText
                ),
                // Add Field From to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'From',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('From').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field To to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'To',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('To').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field CC to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'CC',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('CC').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field BCC to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'BCC',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('BCC').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Headers to List Mailbox
                await this._updateService.runFunctionIfFieldNotExists(
                    mailboxList,
                    'Headers',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            mailboxList.fields.addText('Headers').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Projekt to List Mailbox
                await new Promise<void>((resolve, reject) => {
                    projectList
                        .select('Id')()
                        .then(
                            (projectListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        mailboxList,
                                        'Projekt',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // mailboxList.fields
                                                    //     .addLookup(
                                                    //         'Projekt',
                                                    //         projectListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add folders for existing projects in List Mailbox
                await new Promise<void>((resolve) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Aufträge',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                        })
                        .then(
                            (items) => {
                                const promises2 = [];

                                items.forEach((item) => {
                                    // Add folders
                                    promises2.push(
                                        this.sharePointService.addFolder({
                                            listTitle: 'Mailbox',
                                            folderName: item.Id + '',
                                            isDocumentLibrary: false,
                                        })
                                    );
                                });

                                Promise.all(promises2).then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Set permissions to new folders in List Mailbox
                await new Promise<void>((resolve) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Aufträge',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                        })
                        .then(
                            (items) => {
                                const promises3 = [];

                                items.forEach((item) => {
                                    // Update Permision of folders
                                    promises3.push(
                                        this._applicationService.updateFolderPermissions(
                                            item,
                                            {
                                                listTitle: 'Mailbox',
                                                isDocumentLibrary: false,
                                            }
                                        )
                                    );
                                });

                                Promise.all(promises3).then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Item to List Properties
                await propertyList.items.add({
                    Title: 'E-Mail Kommunikation',
                    Value: 'Nein',
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
