<div class="page-layout carded left-sidebar tabbed inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar" name="carded-left-sidebar-tabbed-2" position="left" lockedOpen="gt-md">

    <!-- SIDEBAR HEADER -->
    <div class="header p-6 accent">
      <h2 class="m-0"></h2>
    </div>
    <!-- / SIDEBAR HEADER -->

    <!-- SIDEBAR CONTENT -->
    <div class="content" fusePerfectScrollbar [@animate]="{ value: '*', params: { y: '50px' } }">
      <div class="nav material2">

        <div class="nav-subheader">

        </div>

        <div *ngFor="let item of this._applicationService.sideBarConfig" class="nav-item">
          <a class="nav-link" matRipple [routerLink]="item.Link" [routerLinkActive]="['active', 'accent']"
            queryParamsHandling="merge" (click)="onNavChange()">
            <mat-icon class="nav-link-icon">{{ item.Icon }}</mat-icon>
            <span>{{ item.Text }}</span>
          </a>
        </div>

      </div>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header accent p-6" fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="h2 mt-4">
          <mat-icon class="logo-icon mr-4" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">assignment
          </mat-icon>
          <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
            {{ this.itemTitle }}
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
          <span class="secondary-text">{{ "MAILASSIGNMENT.PROJECTS" | translate }}</span>
          <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
          <span class="secondary-text">{{ "MAILASSIGNMENT.EMAILS" | translate }}</span>
        </div>

      </div>

      <!-- SEARCH -->
      <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

              <div class="search-wrapper mt-4 mt-sm-0">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <input [formControl]="searchInput" placeholder="Search for a contact">
                </div>

              </div>

            </div> -->
      <!-- / SEARCH -->

    </div>
    <!-- / CONTENT HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">

      <!-- CONTENT -->
      <div class="content card-content mailbox-wrapper">

        <mat-tab-group #matTabGroup dynamicHeight="true" class="mails-tab" (selectedTabChange)="onTabChange($event)">

          <mat-tab label='{{ "MAILASSIGNMENT.ASSIGNED_EMAILS" | translate }}'>
            <app-filter-mail (filter)="filterEmail($event)"></app-filter-mail>
            <mat-card appearance="outlined" class="mat-card">
              <app-resizable-box>
                <mail-list #mailProject *ngIf="this.mailConfigProject" [keyBoardNavigation]="true" [config]="this.mailConfigProject" leftSide>
                </mail-list>
                <mail-details *ngIf="this.mailConfigProject" [showDetails]="true" [trustEmail]="true" rightSide></mail-details>
              </app-resizable-box>
            </mat-card>

          </mat-tab>

          <mat-tab label='{{ "MAILASSIGNMENT.OUT" | translate }}' >
              <app-filter-mail (filter)="filterEmail($event)"></app-filter-mail>
            <mat-card appearance="outlined" class="mat-card">
              <app-resizable-box>
                <mail-list #mailOut *ngIf="this.mailConfigOut" [keyBoardNavigation]="true" [config]="this.mailConfigOut" leftSide>
                </mail-list>
                <mail-details  *ngIf="this.mailConfigOut" [showDetails]="true" [trustEmail]="true" rightSide></mail-details>
              </app-resizable-box>
            </mat-card>

          </mat-tab>

          <mat-tab *ngIf="this.hide" label='{{ "MAILASSIGNMENT.IN" | translate }}'>
            <mat-card appearance="outlined" class="mat-card">
              <app-resizable-box>
                <mail-list #mailIn *ngIf="this.hide" [config]="this.mailConfigIn" leftSide>
                </mail-list>
                <mail-details *ngIf="this.hide" [showDetails]="true" [trustEmail]="true" rightSide></mail-details>
              </app-resizable-box>
            </mat-card>

          </mat-tab>

        </mat-tab-group>

        <button mat-raised-button class="newButton ml-12" color="accent" (click)="send()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.SEND" | translate }}
        </button>

        <button mat-raised-button class="newButton" color="accent" (click)="assign()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.ASSIGN" | translate }}
        </button>

        <button mat-raised-button *ngIf="this.mailProject?.currentMail || mailOut?.currentMail" class="newButton" color="accent" (click)="mailRE(true)">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.REALL" | translate }}
        </button>

        <button mat-raised-button *ngIf="this.mailProject?.currentMail || mailOut?.currentMail" class="newButton" color="accent" (click)="mailRE()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.RE" | translate }}
        </button>

        <button mat-raised-button *ngIf="this.mailProject?.currentMail || mailOut?.currentMail" class="newButton" color="accent" (click)="forwardTo()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.FORWARD" | translate }}
        </button>

        <button mat-raised-button class="newButton" color="accent" (click)="index()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.INDEX" | translate }}
        </button>

        <button mat-raised-button *ngIf="this.isAssignedMail" class="newButton" color="accent" (click)="reassign()">
          <mat-icon>mail</mat-icon>
          {{ "MAILASSIGNMENT.REASSIGN" | translate }}
        </button>

      </div>
      <!-- / CONTENT -->

    </div>
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>
