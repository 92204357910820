export const locale = {
    lang: 'en',
    data: {
        NEW_TIME: {
            UPDATE: 'Update time',
            COLUMNS: {
                PROJECT: 'Project',
                TASK: 'Task/Workpackage',
                DATE: 'Date',
                DESCRIPTION: 'Description',
                COSTSPERHOUR: '€/h',
            },
            BOOKEDTIMESFORSELECTEDDATE1: 'You booked already ',
            BOOKEDTIMESFORSELECTEDDATE2: ' hour(s) for the selected date.',
            BOOKEDTIMESFORSELECTEDDATE3: ' of',
            PROJECTSTATE:
                'New times can only be booked on not completed/stopped projects.',
        },
    },
};
