import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfigurationService } from '../configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-selected-bar-custom',
    templateUrl: './selected-bar-custom.component.html',
    styleUrls: ['./selected-bar-custom.component.scss'],
})
export class SelectedBarCustomComponent implements OnInit {
    confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
    hasSelectedItems: boolean;
    isIndeterminate: boolean;
    selectedItems: string[];

    @Input()
    table: any;

    @Input()
    listName: string;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ContactsService} _contactsService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _configurationService: ConfigurationService,
        public _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this._configurationService.onSelectedItemsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((selectedItems) => {
                this.selectedItems = selectedItems;
                setTimeout(() => {
                    this.hasSelectedItems = selectedItems.length > 0;
                    // this.isIndeterminate =
                    //   selectedItems.length !==
                    //     this._configurationService.items.length &&
                    //   selectedItems.length > 0;
                }, 0);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    /**
     * Select all
     */
    selectAll(): void {
        this._configurationService.selectItems();
    }

    /**
     * Deselect all
     */
    deselectAll(): void {
        this._configurationService.deselectItems();
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void {
        this.confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            data: { confirmMessage: this._translateService.instant('DELETE') },
        });

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._configurationService.deleteSelectedItems(
                    this.table,
                    this.listName
                );
            }
            this.confirmDialogRef = null;
        });
    }
}
