<h1 matDialogTitle>{{ 'DELETEDIALOG.CONFIRM' | translate }}</h1>
<div mat-dialog-content>
    {{ this.confirmMessage }}
</div>
<div mat-dialog-actions class="pt-24">
    <button
        mat-raised-button
        class="mat-accent mr-4"
        (click)="save()"
        cdkFocusInitial
        color="accent"
    >
        {{ 'DELETEDIALOG.OK' | translate }}
    </button>
    <button mat-button (click)="onNoClick()">
        {{ 'DELETEDIALOG.CANCEL' | translate }}
    </button>
</div>
