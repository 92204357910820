import { DetailCategoryModule } from './../../category/detail-category/detail-category.module';
import { DetailNotificationModule } from './../../notification/detail-notification/detail-notification.module';
import { DetailTasksetModule } from './../../taskset/detail-taskset/detail-taskset.module';
import { NgModule } from '@angular/core';
import { ConfigurationComponent } from './configuration.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { CommonModule } from '@angular/common';
import { SelectedBarCustomComponent } from './selected-bar-custom/selected-bar-custom.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DetailTasksetComponent } from '../../taskset/detail-taskset/detail-taskset.component';
import { DetailNotificationComponent } from '../../notification/detail-notification/detail-notification.component';
import { DetailCategoryComponent } from '../../category/detail-category/detail-category.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { HolidayDetailsModule } from 'src/app/main/holidays/holiday-details/holiday-details.module';
import { SelectLocationDialogComponent } from './select-location-dialog/select-location-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DetailsWorkpackageSetModule } from '../../work-package-template/components/details-workpackage-template/details-workpackage-set.module';
import { DetailsWorkpackageSetTemplateModule } from '../../work-package-template/components/details-workpackage-set-template/details-workpackage-set-template.module';

const routes = [
    {
        path: 'administration/configuration',
        component: ConfigurationComponent,
    },
];

@NgModule({
    declarations: [
        ConfigurationComponent,
        SelectedBarCustomComponent,
        ConfirmDialogComponent,
        SelectLocationDialogComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatTabsModule,
        MatIconModule,
        AngularFrameworkModule,
        MatButtonModule,
        CommonModule,
        MatMenuModule,
        MatCheckboxModule,
        MatCardModule,
        MatButtonModule,
        DetailTasksetModule,
        DetailNotificationModule,
        DetailCategoryModule,
        HolidayDetailsModule,
        MatDialogModule,
        DetailsWorkpackageSetModule,
        DetailsWorkpackageSetTemplateModule
    ],
    exports: [ConfigurationComponent]
})
export class ConfigurationModule {}
