import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { ListviewEmployeeanalysisComponent } from './listview-employeeanalysis.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {
  DropDownListModule,
  MultiSelectModule,
  ComboBoxModule,
  DropDownsModule
} from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';

const routes = [
  {
      path: 'employeeanalysis',
      component: ListviewEmployeeanalysisComponent,
  },
];

@NgModule({
  declarations: [ListviewEmployeeanalysisComponent],
  imports: [
      RouterModule.forChild(routes),
      TranslateModule,
      FuseSharedModule,
      LayoutModule,
      AngularFrameworkModule,
      GridModule,
      DateInputsModule,
      MatIconModule,
      DropDownListModule,
      MultiSelectModule,
      ComboBoxModule,
      DropDownsModule,
      InputsModule,
      ExcelModule
  ],
  exports: [ListviewEmployeeanalysisComponent],
})
export class ListviewEmployeeanalysisModule { }
