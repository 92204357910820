export const locale = {
    lang: 'de',
    data: {
        DETAIL_TASKSET: {
            BASE: 'Stammdaten',
            TASKSET: 'Aufgabenvorlagen',
            TASKTEMPLATES: 'Aufgabenvorlagen',
            SEARCH_LABEL: 'Nach einem Aufgabenset suchen',
            TITLE: 'Aufgabensets',
            NEW: 'Neues Aufgabenvorlagen',
            EDIT: 'Aufgabenvorlagen bearbeiten',
            NEWTASKTEMPLATE: 'Neue Aufgabenvorlage',
            COLUMNS: {
                TITLE: 'Name',
            },
            COLUMNSTASKTEMPLATE: {
                TITLE: 'Name',
                DESCRIPTION: 'Beschreibung',
                OFFSETSTART: 'Addition zum Anfangsdatum',
                INDAYS: '(in Tagen)',
                OFFSETEND: 'Addition zum Fälligkeitsdatum',
                PRIORITY: 'Priorität',
                HIGH: '(1) Hoch',
                NORMAL: '(2) Normal',
                LOW: '(3) Niedrig',
                PROCESSSTEP: 'Prozessschritt',
                TYPE: 'Aufgabentyp',
                SCHEDULEDTIME: 'Geschätzte Arbeitszeit',
                INHOURS: '(in Tagen)',
                OFFSETSTARTTYPE: 'Typ der Addition zum Anfangsdatum',
                OFFSETENDTYPE: 'Typ der Addition zum Fälligkeitsdatum',
                TODAY: 'Ab Heute',
                START: 'Ab Beginn Arbeitspaket',
                END: 'Ab Ende Arbeitspaket',
                STARTASSIGNMENT: 'Ab Beginn Projekt',
                ENDASSIGNMENT: 'Ab Ende Projekt',
            },
            NOTIFICATIONS: {
                SAVING: 'Aufgabenset wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Aufgabenset erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
            },
            SAVE: 'Speichern',
        },
    },
};
