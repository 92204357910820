import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1011Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const tasktemplateList = this.sharePointService.web.lists.getByTitle(
                'Aufgabenvorlagen'
            );
            const tasksetList = this.sharePointService.web.lists.getByTitle(
                'Aufgabensets'
            );

            const promises = [
                // Add Field Aufgabenset to List Aufgabenvorlagen
                await new Promise<void>((resolve, reject) => {
                    tasksetList
                        .select('Id')()
                        .then(
                            (tasksetListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        tasktemplateList,
                                        'Aufgabenset',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // tasktemplateList.fields
                                                    //     .addLookup(
                                                    //         'Aufgabenset',
                                                    //         tasksetListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
