import { DetailDocumentModule } from './../../document/detail-document/detail-document.module';
import { NgModule } from '@angular/core';
import { DocumentsAssignmentComponent } from './documents-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { AddFolderDialogComponent } from './add-folder-dialog/add-folder-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { ChangeDocumentNameModule } from '../../document/change-document-name/change-document-name.module';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
    {
        path: 'projects/detail/documents',
        component: DocumentsAssignmentComponent,
    },
    {
        path: 'projects/detail/documents/:id',
        component: DocumentsAssignmentComponent,
    },
];

@NgModule({
    declarations: [DocumentsAssignmentComponent, AddFolderDialogComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatIconModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        DetailDocumentModule,
        ChangeDocumentNameModule
    ],
    exports: [DocumentsAssignmentComponent]
})
export class DocumentsAssignmentModule {}
