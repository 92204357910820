import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1064Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const categoryList = this._sharePointService.web.lists.getByTitle(
                'Auftragskategorien'
            );
            const contactList = this._sharePointService.web.lists.getByTitle(
                'Kontakte'
            );
            const companyList = this._sharePointService.web.lists.getByTitle(
                'Firmen'
            );

            let categroyDocList: IList;
            let salutationList: IList;

            const promises = [
                // Add List Kategoriedokumente
                await this._updateService.addList(
                    'Kategoriedokumente',
                    '',
                    101,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Kategoriedokumente
                await new Promise<void>((resolve) => {
                    categroyDocList = this._sharePointService.web.lists.getByTitle(
                        'Kategoriedokumente'
                    );
                    resolve();
                }),
                // Add List Anreden
                await this._updateService.addList('Anreden', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Anreden
                await new Promise<void>((resolve) => {
                    salutationList = this._sharePointService.web.lists.getByTitle(
                        'Anreden'
                    );
                    resolve();
                }),
                // Add Field Kategorie to List Kategoriedokumente
                await this._updateService.addLookupField(
                    categroyDocList,
                    categoryList,
                    'Kategorie'
                ),
                // Add Field Salutation to List Kontakte
                await this._updateService.addLookupField(
                    contactList,
                    salutationList,
                    'Salutation'
                ),
                // Add Field Strasse to List Kontakte
                await this._updateService.addField(
                    contactList,
                    'Strasse',
                    FieldType.Text
                ),
                // Add Field WorkZip to List Firmen
                await this._updateService.addField(
                    companyList,
                    'WorkZip',
                    FieldType.Text
                ),
                // Add Field WorkCity to List Firmen
                await this._updateService.addField(
                    companyList,
                    'WorkCity',
                    FieldType.Text
                ),
                // Add Field Strasse to List Firmen
                await this._updateService.addField(
                    companyList,
                    'Strasse',
                    FieldType.Text
                ),
                // Add Items to List Anreden
                await salutationList.items.add({
                    Title: 'Frau',
                }),
                await salutationList.items.add({
                    Title: 'Herr',
                }),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
