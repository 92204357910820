import { IList } from '@pnp/sp/lists';
import { FrameworkService } from 'sp-office365-layout';
import { FieldType, SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1144Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {
    }

    async run() {
        try {
            const billsList: IList = await this._sharePointService.web.lists.getByTitle('Rechnungen');
            await this._updateService.addField(billsList, 'Notizen', FieldType.MultilineText);
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
