export const locale = {
    lang: 'en',
    data: {
        OV_BUDGET: {
            BOOKEDCURRENCY: 'Booked Budget',
            BOOKEDHOURS: 'Booked Budget',
            BOOKEDDAYS: 'Booked Budget',
            INEURO: '(in Euro)',
            INHOURS: '(in Stunden)',
            INDAYS: '(in Tagen)',
            DIFFCURRENCY: 'Difference',
            DIFFHOURS: 'Difference',
            DIFFDAYS: 'Difference',
            BOOKEDBUDGETINPERCENT: 'Budget (in Euro)',
            BOOKED: 'Booked',
            OPEN: 'Available',
        },
    },
};
