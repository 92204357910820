export const locale = {
    lang: 'en',
    data: {
        LV_CURRENTUSER: {
            TITLE: 'My views',
            SEARCH_LABEL: 'Search',
            MYPROJECTS: 'My projects',
            MYTASKS: 'My tasks',
            MYWPS: 'Workpackages',
            COLUMNS_PROJECT: {
                NAME: 'Project',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                STATUS: 'Status',
                CREATED: 'Created',
                PROGRESS: 'In progress',
                STOPPED: 'Stopped',
                COMPLETED: 'Completed',
                START: 'Start',
                END: 'End',
                BASE: 'Basedata',
                DESCRIPTION: 'Beschreibung',
                CATEGORY: 'Category',
                BUDGET: 'Budget',
                INEURO: '(in euro)',
                INHOURS: '(in hours)',
                INDAYS: '(in days)',
                USER: 'Roles',
                MANAGEMENT: 'Management',
                PPL: 'PPL',
                CONTACTPERSON: 'Contact person',
                APPLICANT: 'Applicant',
                PROJECTNUMBER: 'Projektnummer',
                OW_NUMBER: 'OW-Nummer',
            },
            COLUMNS_TASK: {
                TITLE: 'Task',
                DUEDATE: 'Duedate',
                STATUS: 'Status',
                TASK: 'Task',
                ASSIGNED: 'Assigned to',
                PROJECT: 'Project',
                DESCRIPTION: 'Description',
                KIND: 'Taskkind',
                WORKPACKAGE: 'Workpackage',
                START: 'Start',
                PRIORITY: 'Priority',
                HIGH: '(1) High',
                NORMAL: '(2) Normal',
                LOW: '(3) Low',
                NOTSTARTED: 'Not started',
                INPROCESSING: 'In processing',
                COMPLETED: 'Completed',
                RESET: 'Reset',
                WAITING: 'Waiting',
                TYPE: 'Tasktype',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                PROCESSSTEP: 'Processstep',
                TEAM: 'Team',
                ANSPRECHPARTNER: 'Ansprechpartner'
            },
            NOTIFICATIONS: {
                LOADING: 'Loading data...',
                WAIT: 'Please wait.',
            },
            SAVE: 'Save',
        },
    },
};
