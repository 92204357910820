import { Component, EventEmitter, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnTyp, SharePointService, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService } from '../../../services/application.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../services/loading.service';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { FormioComponent } from '@formio/angular';
import { from, Observable } from 'rxjs';

@Component({
    selector: 'app-details-workpackage-set-template',
    templateUrl: './details-workpackage-set-template.component.html',
    styleUrls: ['./details-workpackage-set-template.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DetailsWorkpackageSetTemplateComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    };

    public refreshForm: EventEmitter<any> = new EventEmitter();
    public tableAufgabensetsConfig: TableConfiguration;
    public formioConfiguration;
    public sharePointItem;
    public aufgabensets: any[];
    public id: number;
    @ViewChild('aufgabensetsGrid', { static: false }) aufgabensetsGrid: SharePointTableComponent;
    @ViewChild('firstTabFormio', { static: false }) firstTabFormio: FormioComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<DetailsWorkpackageSetTemplateComponent>,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.id = this.data.id;

        if (this.id) {
            this._sharepointService
                .getItemById({ id: this.id, listTitle: 'ArbeitspaketesetsTemplate' })
                .then((processstep) => {
                    this.sharePointItem = processstep;
                    this.sharePointItem.AufgabensetsId = this.sharePointItem.ArbeitspaketesetsId ?? [];
                    console.warn(this.sharePointItem);
                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    public async formioReady() {
        let dataForm;
        const emptyObj = {};
        this._applicationService.getEmptyObject(this.formioConfiguration.components, emptyObj);

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(emptyObj, this.sharePointItem || {});
        } else {
            dataForm = emptyObj;
        }

        await this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm);

        this.refreshForm.emit({
            submission: {
                data: dataForm,
            },
        });
    }

    public initForm(): void {
        if (this.id) {
            this.initAufgabensetsTableConfig();
        }

        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant('WORKPACKAGESETS.FORM.TITLE'),
                    hideLabel: false,
                    disabled: this.data.readOnly,
                    placeholder: '',
                    allowMultipleMasks: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    defaultValue: '',
                    validate: {
                        required: true,
                        customMessage: '',
                        json: '',
                    },
                    inputFormat: 'plain',
                }
            ]
        };
    }

    private initAufgabensetsTableConfig(): void {
        this.tableAufgabensetsConfig = {
            list: 'Arbeitspaketesets',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('WORKPACKAGESETS.FORM.COLUMNS.TITLE'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Aufgabensets',
                    title: this._translateService.instant('WORKPACKAGESETS.FORM.COLUMNS.TASKS'),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: true,
            checkboxColumnShowSelectAll: false,
            recursiveAll: true,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString(),
            useMaterialDesign: true
        };
    }

    public save(): void {
        this.firstTabFormio.formio.emit('submitButton');
    }

    public saveSubmission(event): Observable<void> {
        const { data } = event;
        this._loadingService.show(
            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.SAVING'),
            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.WAIT')
        );

        delete data.submit;
        delete data.isTask;

        this._applicationService.cleanDataForSaving(data, this.formioConfiguration.components);
        this._applicationService.callBeforeSaveFunctions(this.formioConfiguration, data);

        if (this.sharePointItem) {
            return from(
                this._sharepointService
                    .updateItem({
                        listTitle: 'ArbeitspaketesetsTemplate',
                        id: this.id,
                        newFiledValues: {
                            ...data,
                            ArbeitspaketesetsId: { results: this.aufgabensetsGrid.allSelectedItemsIDs }
                        },
                    })
                    .then(() => {
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            ) as Observable<void>;
        } else {
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'ArbeitspaketesetsTemplate',
                        data: data,
                        folder: '',
                    })
                    .then(() => {
                        this.matDialogRef.close();
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            ) as Observable<void>;
        }
    }
}
