export const locale = {
    lang: 'en',
    data: {
        EDIT_ASSIGNMENT: {
            PROJECTS: 'Projects',
            BASE: 'Basedata',
            BUDGETS: 'Budgets',
            DOCS: 'Documents',
            INVOLVED: 'Involved',
            TASKS: 'Tasks',
            KANBAN: 'Kanban',
            NOTES: 'Notes',
            NEW: 'New project',
            POPUP: {
                QUESTION_1:
                    'Do you want to set the value for the end of the project to ',
                QUESTION_2: ' ?',
            },
            FORM: {
                CREATED1: 'Created by ',
                CREATED2: ' on ',
                CREATED3: ' at ',
                USER: 'Roles',
                SAVE: 'Save',
                TITLE: 'Title',
                DESCRIPTION: 'Description',
                START: 'Start',
                END: 'End',
                STATUS: 'Status',
                CREATED: 'Created',
                PROGRESS: 'In progress',
                STOPPED: 'Stopped',
                COMPLETED: 'Completed',
                CATEGORY: 'Category',
                CUSTOMER: 'Customer',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                BUDGET: 'Budget',
                INEURO: '(in euro)',
                INHOURS: '(in hours)',
                INDAYS: '(in days)',
                SUM: 'Sum',
                ADDITIONAL: 'Additional costs',
                INPERCENT: '(in %)',
                EXTERNAL: 'External service',
                MANAGEMENT: 'Management',
                CLERK: 'Clerk',
                PPL: 'PPL',
                OWNUMBER: 'OW-Number',
                CONTACTPERSON: 'Contact person',
                APPLICANT: 'Applicant',
                PERMISSIONS: 'Permissions',
                READ: 'Read',
                EDIT: 'Edit',
                TEAM: 'Team',
                FACHBEREICH: 'Department',
                PROJECTNUMBER: 'Projectnumber',
                ORDER: 'Order',
                UEBERGABE: 'Handover date',
                TAXER: 'Taxer',
                TAXERDESCRIPTION: 'only for auditing',
                COSTCENTRE: 'Cost centre',
                LOCATION: 'Location',
                ACCREDITED: 'Accredited',
                EXTERNALCOSTS: 'External Costs',
                COSTS: 'Costs',
                BILLDATE: 'Bill Date',
                PROJECTMAIL: 'Project E-Mail',
                EXPORT: 'Excel-Export',
                EXPECTEDPROFITMARGIN: 'Erwartete Gewinnmarge'
            },
            NOTIFICATIONS: {
                SAVING: 'Saving project...',
                WAIT: 'Please wait.',
                SUCCESS: 'Project saved successfully',
                ERROR: 'Error while saving: ',
                STARTDUEDATEERROR: 'The start date cannot exceed the due date.',
            },
        },
    },
};
