export const locale = {
    lang: 'de',
    data: {
        MOVE_ELEMENT: {
            MOVE: 'Dokument/Ordner verschieben',
            WORKPACKAGE: {
                FROM: 'von (Arbeitspaket/Aufgabe): ',
                TO: 'nach (Arbeitspaket/Aufgabe): ',
            },
            NOTIFICATION: {
                NOCHANGE_ERROR:
                    'Bitte wählen Sie für das Feld "nach (Projekt)" und/oder "nach (Arbeitspaket)" einen anderen Wert!',
                WAIT: 'Bitte warten...',
                MOVE: 'Verschiebe Dokument.',
                SUCCESS: 'Dokument wurde erfolgreich verschoben',
            },
            PROJECT: {
                FROM: 'von (Projekt): ',
                TO: 'nach (Projekt): ',
            },
            SAVE: 'Ordner/Dokument verschieben',
        },
    },
};
