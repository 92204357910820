export const locale = {
    lang: 'en',
    data: {
        DETAIL_NOTIFICATION: {
            NOTIFICATIONS: {
                SAVING: 'Saving notification...',
                WAIT: 'Please wait.',
                SUCCESS: 'Notification saved successfully',
                ERROR: 'Error while saving: ',
            },
            COLUMNS: {
                TITLE: 'Title',
                SUBJECT: 'Subject',
                TEXT: 'Notificationtext',
                INTERVALL: 'Intervall',
                INTERVALLDESCRIPTION: '(Addition in days to datefield)',
                LIST: 'List',
                DATEFIELD: 'Datefield',
                RECEIVERFIELDS: 'Receiverfields',
                FIELDSDESCRIPTION: '(Has to be part of default view)',
            },
            SAVE: 'Save',
            NEW: 'New notification',
            EDIT: 'Edit notification',
        },
    },
};
