import { Injectable } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import { LookupService } from 'src/app/main/services/lookup.service';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Team } from 'src/app/main/shared/models';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    public isTeamLeiter$ = new BehaviorSubject(false);
    public userTeams$ = new BehaviorSubject<Team[]>(null);
    public teamLeiterGroups$ = new BehaviorSubject<string[]>(null);
    public isInTeamLeiterGroup = false;
    public isInProjectLeiteGroup = false;
    private teamLeiterGroupUsers$ = new BehaviorSubject<any[]>([])

    constructor(
        private _sharepointService: SharePointService,
        private _lookupService: LookupService
    ) {
    }

    public load(): void {
        this.loadCurrentUserTeams().subscribe();
    }

    public loadCurrentUserTeams(): Observable<Team[]> {
        return forkJoin([
            this._sharepointService.getListItems({
                title: 'Teams',
                isDocumentLibrary: false,
                camlQuery: this._lookupService.getTeamleiterById(this._sharepointService.currentUser.Id)
            })
        ]).pipe(
            map(([teams]) => {
                if (teams.length) {
                    const groups = teams.map(team => team.FieldValuesAsText.Gruppe);
                    this.userTeams$.next(teams);
                    this.teamLeiterGroups$.next(groups);
                    this.isTeamLeiter$.next(true);
                }

                return teams;
            })
        );
    }

    public isTeamLeiter(): boolean {
        return this.isTeamLeiter$.getValue();
    }

    public loadTeamleiterGroups() {
        if(!this.isTeamLeiter()) {
            return of([]);
        }

        if (this.teamLeiterGroupUsers$.getValue().length) {
            return this.teamLeiterGroupUsers$.asObservable();
        }

        return forkJoin(
            this.userTeams$.getValue().map(team => this._sharepointService.web.siteGroups.getById(team.GruppeId).users())
        ).pipe(
            map(data => {
                const flatData = data.flat();
                this.teamLeiterGroupUsers$.next(flatData);
                return flatData;
            })
        )
    }

    public getTeamleiterUsersIds(): number[] {
        return [...new Set([this._sharepointService.currentUser.Id, ...this.teamLeiterGroupUsers$.getValue().map(user => user.Id)])];
    }

    public isTeamProjectLeiter(): boolean {
        return this.isInTeamLeiterGroup || this.isInProjectLeiteGroup;
    }

    public initCurrentUserRoles(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._sharepointService.web.currentUser.groups()
                .then(groups => {
                    groups.forEach(group => {
                        if (group.Title === 'Teamleiter') {
                            this.isInTeamLeiterGroup = true;
                        }

                        if (group.Title === 'Projektleiter') {
                            this.isInProjectLeiteGroup = true;
                        }
                    });
                    resolve(true)
                }, () => resolve(true));
        })
    }
}
