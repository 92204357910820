import {
    Component,
    OnInit,
    ViewChildren,
    ViewChild,
    Inject,
} from '@angular/core';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { TableConfiguration, ColumnTyp } from 'sp-office365-framework';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { TaskService } from '../../services/task.service';
import { DetailTasksetComponent } from '../detail-taskset/detail-taskset.component';
import { LoadingService } from '../../services/loading.service';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogConfig,
} from '@angular/material/dialog';

@Component({
    selector: 'app-table-taskset',
    templateUrl: './table-taskset.component.html',
    styleUrls: ['./table-taskset.component.scss'],
})
export class TableTasksetComponent implements OnInit {
    public tableConfig: TableConfiguration;

    private auftragsId: number;
    private arbeitspaketId: number;

    @ViewChildren('checkBoxes') checkBoxes;
    @ViewChild('table', { static: false }) table;

    constructor(
        public matDialogRef: MatDialogRef<TableTasksetComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _taskService: TaskService,
        private loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    get camlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Modified')
            .ToString();
    }

    ngOnInit() {
        this.auftragsId = this.data.auftragsId;
        this.arbeitspaketId = this.data.arbeitsPaketId;

        this.initGrid();
    }

    editItem(e) {
        this.openDialog(e.Id);
    }

    onClose() {
        this.table.refresh();
    }

    checkBoxChange(e, item) {
        this._taskService.toggleSelectedItem(item.ID);
    }

    openDialog(tasksetId): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            tasksetId,
            readOnly: true,
        };

        const dialogRef = this.dialog.open(
            DetailTasksetComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            this._taskService.selectedItems = [];
        });
    }

    createTaskSet() {
        this.loadingService.show(
            this._translateService.instant(
                'TABLE_TASKSET.NOTIFICATIONS.CREATE'
            ),
            this._translateService.instant('TABLE_TASKSET.NOTIFICATIONS.WAIT')
        );

        const promises = [];

        this._taskService.selectedItems.forEach((tasksetId) => {
            promises.push(
                this._taskService.createTaskSet(
                    tasksetId,
                    this.auftragsId,
                    this.arbeitspaketId
                )
            );
        });

        Promise.all(promises).then(
            () => {
                this._taskService.selectedItems = [];
                this.loadingService.hide();
                this.matDialogRef.close();
            },
            (error) => {
                this._taskService.selectedItems = [];
                this.loadingService.hide();
                console.error(error);
                this.matDialogRef.close();
            }
        );
    }

    initGrid() {
        this.tableConfig = {
            list: 'Aufgabensets',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'TABLE_TASKSET.COLUMNS.TITLE'
                    ),
                    hidden: false,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: 'TITLE',
                        hideLabel: false,
                        placeholder: '',
                        allowMultipleMasks: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        alwaysEnabled: false,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        defaultValue: '',
                        validate: {
                            customMessage: '',
                            json: '',
                        },
                        widget: {
                            type: '',
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'TABLE_TASKSET.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };
    }
}
