export const locale = {
    lang: 'de',
    data: {
        MAILREQUEST: {
            PROJECTS: 'Projekte',
            REQUESTS: 'Anfragen',
            EMAILS: 'E-Mails',
            SEND: 'Neue E-Mail',
            IN: 'Empfangen alt',
            OUT: 'Gesendet',
            ASSIGN: 'E-Mails einlesen',
            ASSIGNED_EMAILS: 'Empfangen',
            REASSIGN: 'E-Mails löschen/verschieben',
            RE: 'Antworten',
            REALL: 'Alle antworten',
            FORWARD: 'Weiterleiten',
            INDEX: 'E-Mails zuordnen',
            FILTER_EMAILS: 'Mails filtern',
            COLUMNS: {
                TITLE: 'Titel',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
            },
            NOTIFICATIONS: {
                UPLOAD: 'Dokument wird hochgeladen...',
                ADDINGFOLDER: 'Ordner wird erstellt...',
                SUCCESS: 'Datei erfolgreich hochgeladen.',
                SUCCESSFOLDER: 'Ordner erfolgreich erstellt.',
                ERROR: 'Fehler beim hochladen: ',
                UPLOADMULTI: 'Dokumente werden hochgeladen...',
                SUCCESSMULTI: 'Dokumente erfolgreich hochgeladen.',
                ERRORMULTI: 'Fehler beim hochladen: ',
                WAIT: 'Bitte warten.',
            },
        },
    },
};
