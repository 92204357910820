import { IList } from '@pnp/sp/lists';
import { FrameworkService } from 'sp-office365-layout';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1146Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {
    }

    async run() {
        try {
            const tasksList: IList = await this._sharePointService.web.lists.getByTitle('Auftragsaufgaben');
            const firmenList: IList = await this._sharePointService.web.lists.getByTitle('Firmen');
            await this._updateService.runFunctionIfFieldNotExists(
                tasksList,
                'Auftraggeber',
                async () => await this._updateService.addLookupField(tasksList, firmenList, 'Auftraggeber')
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
