import { NgModule } from '@angular/core';
import { OverviewBudgetComponent } from './overview-budget.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [OverviewBudgetComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
        MatProgressBarModule,
        NgxChartsModule
    ],
    exports: [OverviewBudgetComponent],
})
export class OverviewBudgetModule {}
