import { Injectable, OnDestroy } from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { TaskSetModel } from '../models/taskset.model';
import * as CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DocumentsAssignmentService } from '../assignment/documents-assignment/documents-assignment.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogTasksComponent } from '../task/detail-task/confirm-dialog-tasks/confirm-dialog-tasks.component';
import { LoadingService } from './loading.service';
import { DeleteConfirmationComponent } from '../task/listview-workpackage/delete-confirmation/delete-confirmation.component';
import { ICamlQuery } from '@pnp/sp/lists';
import { InjectHeaders } from '@pnp/queryable';
import '@pnp/sp/items/get-all';

@Injectable({
    providedIn: 'root',
})
export class TaskService implements OnDestroy {
    public selectedItems: number[] = [];
    public onSelectedItemsChanged: BehaviorSubject<any>;
    public onStateRefreshed: BehaviorSubject<any>;
    public confirmDeleteDialogRef: MatDialogRef<DeleteConfirmationComponent>;
    private packageId: number;
    private tasksImportRunning: boolean;
    private subscription: Subscription;

    private dependantFolderLists = [
        { listTitle: 'Aufgabendokumente', isDocumentLibrary: true },
    ];
    public processSteps = [];
    public confirmDialogRef: MatDialogRef<ConfirmDialogTasksComponent>;

    get camlQueryProcessSteps() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Schritt')
            .ToString();
    }

    constructor(
        public dialog: MatDialog,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _snackBarService: SnackbarService,
        private _documentService: DocumentsAssignmentService,
        private _updateService: UpdateService,
        private _loadingService: LoadingService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.onSelectedItemsChanged = new BehaviorSubject([]);
        this.onStateRefreshed = new BehaviorSubject([]);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    get allTasks() {
        return new CamlBuilder()
            .Where()
            .LookupField('Arbeitspaket')
            .Id()
            .EqualTo(this.packageId)
            .ToString();
    }
    /**
     * Toggle selected item by id
     *
     * @param id
     */
    toggleSelectedItem(id): void {
        // First, check if we already have that item as selected...
        if (this.selectedItems.length > 0) {
            const index = this.selectedItems.indexOf(id);

            if (index !== -1) {
                this.selectedItems.splice(index, 1);

                // Trigger the next event
                this.onSelectedItemsChanged.next(this.selectedItems);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedItems.push(id);

        // Trigger the next event
        this.onSelectedItemsChanged.next(this.selectedItems);
    }

    toggleStateRefreshed(updatedItem): void {
        // Trigger the next event
        this.onStateRefreshed.next(updatedItem);
    }

    createTaskSet(tasksetId: number, auftragId: number, arbeitspaketId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];
            let apStartDate;
            let apDueDate;
            let projectStartDate;
            let projectDueDate;

            if (tasksetId && auftragId && arbeitspaketId) {
                // Get processSteps
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getListItems({
                                title: 'Prozessschritte',
                                camlQuery: this.camlQueryProcessSteps,
                                isDocumentLibrary: false,
                            })
                            .then((processSteps) => {
                                this.processSteps = processSteps;

                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );

                // Get date values from project and workpackage
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getItemById({
                                id: arbeitspaketId,
                                listTitle: 'Auftragsaufgaben',
                            })
                            .then((paket) => {
                                if (paket.StartDate) {
                                    apStartDate = new Date(paket.StartDate);
                                }
                                if (paket.DueDate) {
                                    apDueDate = new Date(paket.DueDate);
                                }
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getItemById({
                                id: auftragId,
                                listTitle: 'Aufträge',
                            })
                            .then((auftrag) => {
                                if (auftrag.Auftragsstart) {
                                    projectStartDate = new Date(
                                        auftrag.Auftragsstart
                                    );
                                }
                                if (auftrag.Auftragsende) {
                                    projectDueDate = new Date(
                                        auftrag.Auftragsende
                                    );
                                }

                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );

                Promise.all(promises)
                    .then(() => {
                        const options: TaskSetModel = {
                            id: tasksetId,
                            auftragId,
                            arbeitspaketId,
                            projectStartDate,
                            projectDueDate,
                            apStartDate,
                            apDueDate,
                        };

                        this.generateTaskSet(options)
                            .then(() => {
                                resolve();
                            })
                            .catch((error) => {
                                console.error(error);
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            } else {
                if (!this.tasksImportRunning) {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'TASK_SERVICE.NOTASKSETSELECTED'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );
                }

                resolve();
            }
        });
    }

    generateTaskSet(options: TaskSetModel): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._sharePointService
                .getListItems({
                    title: 'Aufgabenvorlagen',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Aufgabenset')
                        .Id()
                        .EqualTo(options.id)
                        .ToString(),
                })
                .then((results) => {
                    if (results.length > 0) {
                        const promises = [];

                        results.forEach((item) => {
                            let offsetStartType: string;
                            let offsetEndType: string;
                            let start: Date;
                            let due: Date;

                            offsetStartType = item.OffsetStartType;
                            offsetEndType = item.OffsetEndeType;

                            switch (offsetStartType) {
                                case 'Ab Heute':
                                    start = new Date();
                                    break;
                                case 'Ab Beginn Arbeitspaket':
                                    start = options.apStartDate;
                                    break;
                                case 'Ab Beginn Auftrag':
                                    start = options.projectStartDate;
                                    break;
                                case 'Ab Ende Arbeitspaket':
                                    start = options.apDueDate;
                                    break;
                                case 'Ab Ende Auftrag':
                                    start = options.projectDueDate;
                                    break;
                            }
                            switch (offsetEndType) {
                                case 'Ab Heute':
                                    due = new Date();
                                    break;
                                case 'Ab Beginn Arbeitspaket':
                                    due = options.apStartDate;
                                    break;
                                case 'Ab Beginn Auftrag':
                                    due = options.projectStartDate;
                                    break;
                                case 'Ab Ende Arbeitspaket':
                                    due = options.apDueDate;
                                    break;
                                case 'Ab Ende Auftrag':
                                    due = options.projectDueDate;
                                    break;
                            }

                            promises.push(
                                this.addTask(
                                    item,
                                    options.arbeitspaketId,
                                    options.auftragId,
                                    start,
                                    due
                                )
                            );
                        });

                        Promise.all(promises)
                            .then(() => {
                                resolve();
                            })
                            .catch((error) => {
                                console.error(error);
                                reject(error);
                            });
                    } else {
                        if (!this.tasksImportRunning) {
                            this._snackBarService.add(
                                this._translateService.instant(
                                    'TASK_SERVICE.NOTASKS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                        }

                        resolve();
                    }
                })
                .catch((error) => {
                    this._snackBarService.add(
                        this._translateService.instant('TASK_SERVICE.ERROR'),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );

                    console.error(error);
                    reject(error);
                });
        });
    }

    addTask(
        taskTemplate,
        arbeitspaketId,
        auftragId,
        startDate,
        dueDate
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (startDate) {
                startDate.setDate(
                    startDate.getDate() + taskTemplate.OffsetAnfangsdatum
                );
            }

            if (dueDate) {
                dueDate.setDate(
                    dueDate.getDate() +
                        taskTemplate.OffsetF_x00e4_lligkeitsdatum
                );
            }

            // Set Satus
            let status = 'Nicht begonnen';

            let processStepItem = this.processSteps.find(
                (x) => x.Id == taskTemplate.ProzessschrittId
            );

            if (processStepItem && processStepItem.Aufgabenstatus) {
                status = processStepItem.Aufgabenstatus;
            }

            this._sharePointService
                .addItem({
                    listTitle: 'Auftragsaufgaben',
                    folder: auftragId + '',
                    data: {
                        Title: taskTemplate.Title,
                        Body: taskTemplate.Beschreibung,
                        Priority: taskTemplate.Priorit_x00e4_t,
                        ArbeitspaketId: arbeitspaketId,
                        AuftragId: auftragId,
                        Aufgabenart: 'Aufgabe',
                        StartDate: startDate,
                        DueDate: dueDate,
                        ProzessschrittId: taskTemplate.ProzessschrittId,
                        Status: status,
                        AufgabentypId: taskTemplate.AufgabentypId,
                        Kennzeichen: 'Unkritisch',
                    },
                })
                .then((taskId) => {
                    // Add folders for task
                    this.addFoldersForTask(taskId, auftragId)
                        .then(() => {
                            // Get taskdocuments
                            this._sharePointService
                                .getListItems({
                                    title: 'Aufgabenvorlagendokumente',
                                    isDocumentLibrary: true,
                                    camlQuery: new CamlBuilder()
                                        .Where()
                                        .LookupField('Aufgabenvorlage')
                                        .Id()
                                        .EqualTo(taskTemplate.Id)
                                        .ToString(),
                                })
                                .then((docs) => {
                                    const promises = [];

                                    docs.forEach((element) => {
                                        promises.push(
                                            new Promise<void>(
                                                (resolve, reject) => {
                                                    const targetPath =
                                                        'Aufgabendokumente/' +
                                                        auftragId +
                                                        '/' +
                                                        taskId +
                                                        '/' +
                                                        element
                                                            .FieldValuesAsText
                                                            .FileLeafRef;

                                                    this._sharePointService.web
                                                        .using(
                                                            InjectHeaders({
                                                                Accept: 'application/json;odata=fullmetadata',
                                                                'Content-Type':
                                                                    'application/json;charset=utf-8',
                                                            })
                                                        )
                                                        .getFileByServerRelativePath(
                                                            element.FileRef
                                                        )
                                                        .copyTo(
                                                            targetPath,
                                                            true
                                                        )
                                                        .then(() => {
                                                            this._documentService
                                                                .loadItemFromFile(
                                                                    this._sharePointService.computeServerRelativeUrl(
                                                                        this
                                                                            ._sharePointService
                                                                            .sphostUrl
                                                                    ) +
                                                                        '/' +
                                                                        targetPath
                                                                )
                                                                .then(
                                                                    (data) => {
                                                                        // Update Item
                                                                        this._sharePointService
                                                                            .updateItem(
                                                                                {
                                                                                    listTitle:
                                                                                        'Aufgabendokumente',
                                                                                    id: data
                                                                                        .item
                                                                                        .Id,
                                                                                    newFiledValues:
                                                                                        {
                                                                                            AuftragId:
                                                                                                auftragId,
                                                                                            AufgabeId:
                                                                                                taskId,
                                                                                        },
                                                                                }
                                                                            )
                                                                            .then(
                                                                                () => {
                                                                                    // Success
                                                                                    resolve();
                                                                                }
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    error
                                                                                ) => {
                                                                                    reject(
                                                                                        error
                                                                                    );
                                                                                }
                                                                            );
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) => {
                                                                        reject(
                                                                            error
                                                                        );
                                                                    }
                                                                );
                                                        })
                                                        .catch((error) => {
                                                            reject(error);
                                                        });
                                                }
                                            )
                                        );
                                    });

                                    Promise.all(promises)
                                        .then(() => {
                                            if (!this.tasksImportRunning) {
                                                this._snackBarService.add(
                                                    this._translateService.instant(
                                                        'TASK_SERVICE.SUCCESS'
                                                    ),
                                                    '',
                                                    {
                                                        duration: 4000,
                                                        panelClass:
                                                            'success-dialog',
                                                    }
                                                );
                                            }

                                            resolve();
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    confirmationPopUp(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const confirmMessage = this._translateService.instant(
                'TASK_SERVICE.DELETETASK.MESSAGE'
            );

            this.confirmDeleteDialogRef = this.dialog.open(
                DeleteConfirmationComponent,
                {
                    data: {
                        confirmMessage,
                    },
                }
            );
            this.subscription = this.confirmDeleteDialogRef
                .afterClosed()
                .subscribe((result) => {
                    resolve(result || false);
                });
        });
    }

    afterDeleteItem(item) {
        if (item) {
            if (item.dataItem) {
                item = item.dataItem;
            }

            // Fake SPItem for methode
            const currentItem = {
                Id: item.Id,
                Kennzeichen: 'Unkritisch',
            };

            let parentItemID = item.AuftragId;

            if (item.ArbeitspaketId) {
                parentItemID = item.ArbeitspaketId;
            }

            //If a Workpackage is choosen it will be asked if the tasks of this package should be deleted additionally.
            //If the Item is a task it then item.ArbeitspaketId has a value!
            if (!item.ArbeitspaketId) {
                this.confirmationPopUp()
                    .then(async (result) => {
                        if (result) {
                            this.packageId = item.Id;
                            const allTasks =
                                await this._sharePointService.getListItems({
                                    title: 'Auftragsaufgaben',
                                    isDocumentLibrary: false,
                                    camlQuery: this.allTasks,
                                    recursiveAll: true,
                                });

                            for (let task of allTasks) {
                                await this._sharePointService.recycleItem({
                                    listTitle: 'Auftragsaufgaben',
                                    id: task.Id,
                                });
                            }

                            if (allTasks.length) {
                                this._loadingService.hide();
                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'TASK_SERVICE.DELETETASK.SUCCESS'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );
                            } else {
                                this._loadingService.hide();
                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'TASK_SERVICE.DELETETASK.NOTASKS'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'error-dialog',
                                    }
                                );
                            }
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            this.updateStateOfParentItem(true, parentItemID, currentItem)
                .then(() => {
                    // success
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    updateStateOfParentItem(
        isTask: boolean,
        parentItemId: number,
        currentItem: any
    ): Promise<any> {
        let parentListTitle = 'Aufträge';
        let text = this._translateService.instant('TASK_SERVICE.PROJECTSTATUS');

        if (isTask) {
            parentListTitle = 'Auftragsaufgaben';
            text = this._translateService.instant(
                'TASK_SERVICE.WORKPACKAGESTATUS'
            );
        }

        return new Promise<void>((resolve, reject) => {
            this._sharePointService
                .getItemById({
                    listTitle: parentListTitle,
                    id: parentItemId,
                })
                .then((parentItem) => {
                    if (!parentItem.Kennzeichen) {
                        this.changeStateOfParentTo(
                            parentItem,
                            currentItem,
                            isTask
                        )
                            .then((newState) => {
                                this._sharePointService
                                    .updateItem({
                                        id: parentItem.Id,
                                        listTitle: parentListTitle,
                                        newFiledValues: {
                                            Kennzeichen: newState,
                                        },
                                    })
                                    .then(() => {
                                        parentItem.Kennzeichen = newState;

                                        this.toggleStateRefreshed(parentItem);

                                        this._snackBarService.add(
                                            text + newState,
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        if (isTask) {
                                            this.updateStateOfParentItem(
                                                false,
                                                parentItem.AuftragId,
                                                parentItem
                                            )
                                                .then(() => {
                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        } else {
                                            resolve();
                                        }
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        if (parentItem.Kennzeichen == currentItem.Kennzeichen) {
                            resolve();
                        } else {
                            switch (parentItem.Kennzeichen) {
                                case 'Unkritisch':
                                    this._sharePointService
                                        .updateItem({
                                            id: parentItem.Id,
                                            listTitle: parentListTitle,
                                            newFiledValues: {
                                                Kennzeichen:
                                                    currentItem.Kennzeichen,
                                            },
                                        })
                                        .then(() => {
                                            parentItem.Kennzeichen =
                                                currentItem.Kennzeichen;

                                            this.toggleStateRefreshed(
                                                parentItem
                                            );

                                            this._snackBarService.add(
                                                text + currentItem.Kennzeichen,
                                                '',
                                                {
                                                    duration: 4000,
                                                    panelClass:
                                                        'success-dialog',
                                                }
                                            );

                                            if (isTask) {
                                                this.updateStateOfParentItem(
                                                    false,
                                                    parentItem.AuftragId,
                                                    parentItem
                                                )
                                                    .then(() => {
                                                        resolve();
                                                    })
                                                    .catch((error) => {
                                                        reject(error);
                                                    });
                                            } else {
                                                resolve();
                                            }
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                    break;
                                case 'Kritisch':
                                    this.changeStateOfParentTo(
                                        parentItem,
                                        currentItem,
                                        isTask
                                    )
                                        .then((newState) => {
                                            if (
                                                newState ==
                                                parentItem.Kennzeichen
                                            ) {
                                                resolve();
                                            }

                                            this._sharePointService
                                                .updateItem({
                                                    id: parentItem.Id,
                                                    listTitle: parentListTitle,
                                                    newFiledValues: {
                                                        Kennzeichen: newState,
                                                    },
                                                })
                                                .then(() => {
                                                    parentItem.Kennzeichen =
                                                        newState;

                                                    this.toggleStateRefreshed(
                                                        parentItem
                                                    );

                                                    this._snackBarService.add(
                                                        text + newState,
                                                        '',
                                                        {
                                                            duration: 4000,
                                                            panelClass:
                                                                'success-dialog',
                                                        }
                                                    );

                                                    if (isTask) {
                                                        this.updateStateOfParentItem(
                                                            false,
                                                            parentItem.AuftragId,
                                                            parentItem
                                                        )
                                                            .then(() => {
                                                                resolve();
                                                            })
                                                            .catch((error) => {
                                                                reject(error);
                                                            });
                                                    } else {
                                                        resolve();
                                                    }
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                    break;
                            }
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    changeStateOfParentTo(
        parentItem: any,
        currentItem: any,
        isTask: boolean
    ): Promise<any> {
        let internalName = 'Auftrag';

        if (isTask) {
            internalName = 'Arbeitspaket';
        }

        return new Promise((resolve, reject) => {
            this._sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    isDocumentLibrary: false,
                    recursiveAll: true,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(currentItem.Id)
                        .And()
                        .LookupField(internalName)
                        .Id()
                        .EqualTo(parentItem.Id)
                        .ToString(),
                })
                .then((otherItemsWithSameParent) => {
                    let newState = currentItem.Kennzeichen;

                    otherItemsWithSameParent.forEach((item) => {
                        if (item.Kennzeichen == 'Kritisch') {
                            newState = item.Kennzeichen;
                        }
                    });

                    resolve(newState);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addFoldersForTask(taskId, auftragsId): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            for (const dependantList of this.dependantFolderLists) {
                this._updateService
                    .listExists(dependantList.listTitle)
                    .then((exists) => {
                        if (exists) {
                            promises.push(
                                this._sharePointService.addFolderByPath({
                                    listTitle: dependantList.listTitle,
                                    rootFolder: auftragsId,
                                    folderName: taskId + '',
                                    isDocumentLibrary:
                                        dependantList.isDocumentLibrary,
                                })
                            );
                        }
                    });
            }

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    syncDurationOfChildren(
        oldItem: any,
        newItem: any,
        isWorkpackage: boolean
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let startfield = 'Auftragsstart';
            let endField = 'Auftragsende';

            let confirmMessage = this._translateService.instant(
                'TASK_SERVICE.SYNCTOWP'
            );
            let loadingDialog = this._translateService.instant(
                'TASK_SERVICE.NOTIFICATIONS.SAVINGPROJECT'
            );

            if (isWorkpackage) {
                startfield = 'StartDate';
                endField = 'DueDate';
                confirmMessage = this._translateService.instant(
                    'TASK_SERVICE.SYNCTOTASK'
                );
                loadingDialog = this._translateService.instant(
                    'TASK_SERVICE.NOTIFICATIONS.SAVINGWP'
                );
            }

            if (
                oldItem[startfield] != newItem[startfield] ||
                oldItem[endField] != newItem[endField]
            ) {
                // Get diff between Dates
                let diffSart = Math.floor(
                    (new Date(newItem[startfield]).getTime() -
                        new Date(oldItem[startfield]).getTime()) /
                        1000 /
                        60 /
                        60 /
                        24
                );
                let diffEnd = Math.floor(
                    (new Date(newItem[endField]).getTime() -
                        new Date(oldItem[endField]).getTime()) /
                        1000 /
                        60 /
                        60 /
                        24
                );

                confirmMessage +=
                    this._translateService.instant('TASK_SERVICE.START') +
                    diffSart +
                    ' ' +
                    this._translateService.instant('TASK_SERVICE.DAYS') +
                    ' ' +
                    this._translateService.instant('TASK_SERVICE.END') +
                    diffEnd +
                    ' ' +
                    this._translateService.instant('TASK_SERVICE.DAYS');

                this._loadingService.hide();

                // Abfrage an User: Children verschieben ja/nein
                this.confirmDialogRef = this.dialog.open(
                    ConfirmDialogTasksComponent,
                    {
                        data: {
                            confirmMessage,
                        },
                    }
                );

                this.confirmDialogRef.afterClosed().subscribe((result) => {
                    this._loadingService.show(
                        loadingDialog,
                        this._translateService.instant(
                            'TASK_SERVICE.NOTIFICATIONS.WAIT'
                        )
                    );

                    if (result) {
                        let diffStart = Math.ceil(
                            (new Date(newItem[startfield]).valueOf() -
                                new Date(oldItem[startfield]).valueOf()) /
                                (1000 * 60 * 60 * 24)
                        );
                        let diffEnd = Math.ceil(
                            (new Date(newItem[endField]).valueOf() -
                                new Date(oldItem[endField]).valueOf()) /
                                (1000 * 60 * 60 * 24)
                        );

                        this.updateAllChildren(
                            newItem,
                            isWorkpackage,
                            diffStart,
                            diffEnd
                        )
                            .then(() => {
                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'TASK_SERVICE.SUCCESSSYNC'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );

                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }

    updateAllChildren(
        parent: any,
        isWorkpackage: boolean,
        diffStart: number,
        diffEnd: number
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            let startfield = 'Auftragsstart';
            let endField = 'Auftragsende';
            let parentLookupField = 'Auftrag';
            let type = 'Arbeitspaket';

            if (isWorkpackage) {
                startfield = 'StartDate';
                endField = 'DueDate';
                parentLookupField = 'Arbeitspaket';
                type = 'Aufgabe';
            }

            // Get all wps/tasks
            this._sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    isDocumentLibrary: false,
                    recursiveAll: true,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField(parentLookupField)
                        .Id()
                        .EqualTo(parent.Id)
                        .And()
                        .ChoiceField('Aufgabenart')
                        .EqualTo(type)
                        .ToString(),
                })
                .then((items) => {
                    const promises = [];

                    // Update all wps/tasks
                    items.forEach((item) => {
                        let newFiledValues: any = {};

                        if (diffStart != 0) {
                            if (item.StartDate) {
                                newFiledValues.StartDate = this.addDays(
                                    new Date(item.StartDate),
                                    diffStart
                                );
                            } else {
                                newFiledValues.StartDate = parent[startfield];
                            }
                        }

                        if (diffEnd != 0) {
                            if (item.DueDate) {
                                newFiledValues.DueDate = this.addDays(
                                    new Date(item.DueDate),
                                    diffEnd
                                );
                            } else {
                                newFiledValues.DueDate = parent[endField];
                            }
                        }

                        promises.push(
                            this._sharePointService.updateItem({
                                listTitle: 'Auftragsaufgaben',
                                id: item.Id,
                                newFiledValues,
                            })
                        );

                        if (!isWorkpackage) {
                            // Update tasks from wp
                            promises.push(
                                this.updateAllChildren(
                                    item,
                                    true,
                                    diffStart,
                                    diffEnd
                                )
                            );
                        }
                    });

                    Promise.all(promises)
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    public async importWorkpackages(workpackageIds: number[], projectId: number): Promise<void> {
        const payload = {
            ArbeitspaketId: null,
            AssignedToId: this._sharePointService.currentUser.Id,
            Aufgabenart: 'Arbeitspaket',
            AufgabentypId: null,
            AuftragId: projectId,
            Body: null,
            DueDate: new Date(),
            Kennzeichen: 'Unkritisch',
            Priority: null,
            ProzessschrittId: 3,
            StartDate: new Date(),
            Status: 'Abgeschlossen',
            Title: null
        };

        const project = await this._sharePointService
            .getItemById({ listTitle: 'Aufträge', id: projectId });
        const arbeitspaketesets = await this._sharePointService.getListItems({
            title: 'Arbeitspaketesets',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .In(workpackageIds)
                .ToString()
        });

        for (let i = 0; i < arbeitspaketesets.length; i++) {
            let offsetStartType: string;
            let offsetEndType: string;
            let startDate: Date;
            let dueDate: Date;

            offsetStartType = arbeitspaketesets[i].OffsetStartType;
            offsetEndType = arbeitspaketesets[i].OffsetEndeType;

            switch (offsetStartType) {
                case 'Ab Heute':
                    startDate = new Date();
                    break;
                case 'Ab Beginn Auftrag':
                    startDate = new Date(project.Auftragsstart);
                    break;
                case 'Ab Ende Auftrag':
                    startDate = new Date(project.Auftragsende);
                    break;
            }
            switch (offsetEndType) {
                case 'Ab Heute':
                    dueDate = new Date();
                    break;
                case 'Ab Beginn Auftrag':
                    dueDate = new Date(project.Auftragsstart);
                    break;
                case 'Ab Ende Auftrag':
                    dueDate = new Date(project.Auftragsende);
                    break;
            }

            if (startDate) {
                startDate.setDate(
                    startDate.getDate() + arbeitspaketesets[i].OffsetAnfangsdatum
                );
            }

            if (dueDate) {
                dueDate.setDate(
                    dueDate.getDate() +
                    arbeitspaketesets[i].OffsetF_x00e4_lligkeitsdatum
                );
            }

            payload.StartDate = startDate;
            payload.DueDate = dueDate;
            payload.Kennzeichen = arbeitspaketesets[i].Kennzeichen;
            payload.Title = arbeitspaketesets[i].Title;
            payload.Body = arbeitspaketesets[i].Description;
            payload.AufgabentypId = arbeitspaketesets[i].AufgabentypId;
            payload.ProzessschrittId = arbeitspaketesets[i].ProzessschrittId;
            payload.Priority = arbeitspaketesets[i].Priority;
            const stateChanged = 'Unkritisch' != payload.Kennzeichen;
            const newItemId = await this._sharePointService
                .addItem({
                    listTitle: 'Auftragsaufgaben',
                    data: payload,
                    folder: payload.AuftragId.toString(),
                });
            const sharePointItem = await this._sharePointService
                .getItemById({
                    listTitle: 'Auftragsaufgaben',
                    id: newItemId,
                });

            const promises = [];

            // Add folders
            promises.push(
                this.addFoldersForTask(
                    sharePointItem.Id,
                    payload.AuftragId
                )
            );

            if (stateChanged) {
                const isTask = false;
                let parentItemID = null;

                if (!isTask) {
                    parentItemID = payload.AuftragId;
                }

                // Update state of parent item
                promises.push(
                    this.updateStateOfParentItem(
                        isTask,
                        parentItemID,
                        sharePointItem
                    )
                );
            }

            if (promises) {
                await Promise.all(promises);
            }

            for (let aufgabensetIndex = 0; aufgabensetIndex < arbeitspaketesets[i].AufgabensetsId.length; aufgabensetIndex++) {
                await this.createTaskSet(
                    arbeitspaketesets[i].AufgabensetsId[aufgabensetIndex],
                    payload.AuftragId,
                    sharePointItem.Id
                )
            }
        }
    }

    public toggleTasksImportRunning(): void {
        this.tasksImportRunning = !this.tasksImportRunning;
    }

    public async loadTasksByQuery(query: string): Promise<any[]> {
        const tasks = [];
        const pageLimit = 5000;
        let hasNext = false;
        let listItemCollectionPosition = null;
        do {
            const caml: ICamlQuery = {
                ViewXml:
                    '<View Scope="RecursiveAll">' +
                    '<RowLimit>' + pageLimit + '</RowLimit>' +
                    '<ViewFields>' +
                    '<FieldRef Name="ID"></FieldRef>' +
                    '<FieldRef Name="Title"></FieldRef>' +
                    '<FieldRef Name="DueDate"></FieldRef>' +
                    '<FieldRef Name="AssignedTo"></FieldRef>' +
                    '<FieldRef Name="Prozessschritt"></FieldRef>' +
                    '<FieldRef Name="Auftrag"></FieldRef>' +
                    '<FieldRef Name="Arbeitspaket"></FieldRef>' +
                    '<FieldRef Name="Auftraggeber"></FieldRef>' +
                    '<FieldRef Name="Auftraggeber_x003a_Title2"></FieldRef>' +
                    '<FieldRef Name="Auftraggeber_x003a_Title3"></FieldRef>' +
                    '</ViewFields>' +
                    '<Query>' + query + '</Query>' +
                    '</View>',
                ListItemCollectionPosition: listItemCollectionPosition
            };
            const tasks_items_chunk = await this._sharePointService.web.lists.getByTitle('Auftragsaufgaben').getItemsByCAMLQuery(caml, 'FieldValuesAsText');

            if (tasks_items_chunk.length) {
                tasks.push(...tasks_items_chunk);

                const last = tasks_items_chunk[tasks_items_chunk.length - 1];
                listItemCollectionPosition = { PagingInfo: `Paged=TRUE&p_ID=${last.ID}&p_Title=${last.Title}` };

                hasNext = true;
            } else {
                hasNext = false;
            }
        } while (hasNext);

        tasks.forEach(item => {
            if(item.DueDate) {
                item.DueDate = new Date(item.DueDate);
            }
            item.AssignedTo = item.FieldValuesAsText.AssignedTo;
            item.Prozessschritt = item.FieldValuesAsText.Prozessschritt;
            item.Auftrag = item.FieldValuesAsText.Auftrag;
            item.Arbeitspaket = item.FieldValuesAsText.Arbeitspaket;
            item.Auftraggeber = item.FieldValuesAsText.Auftraggeber;
        })

        return tasks;
    }

    public async loadAllTasks(filter: (item) => boolean): Promise<any[]> {
        let tasks = await this._sharePointService.web.lists.getByTitle('Auftragsaufgaben')
            .items
            .select('*, AssignedTo/Id, AssignedTo/Title, Prozessschritt/Id, Prozessschritt/Title, Auftrag/Id, Auftrag/Title, Arbeitspaket/Id, Arbeitspaket/Title, Auftraggeber/Id, Auftraggeber/Title, Auftraggeber/Title2, Auftraggeber/Title3')
            .expand('AssignedTo, Prozessschritt, Auftrag, Arbeitspaket, Auftraggeber')
            .top(5000)
            .getAll();

        tasks.forEach(item => {
            if (item.FileSystemObjectType === 1) {
                return;
            }

            if (item.DueDate) {
                item.DueDate = new Date(item.DueDate);
            }

            item.AssignedTo = item.AssignedTo?.Title ?? '';
            item.Prozessschritt = item.Prozessschritt?.Title ?? '';
            item.Auftrag = item.Auftrag?.Title ?? '';
            item.FieldValuesAsText = {
                Auftraggeber: item.Auftraggeber?.Title,
                Auftraggeber_x005f_x003a_x005f_Title2: item.Auftraggeber?.Title2,
                Auftraggeber_x005f_x003a_x005f_Title3: item.Auftraggeber?.Title3,
            };
            item.Arbeitspaket = item.Arbeitspaket?.Title ?? '';
            item.Auftraggeber = item.Auftraggeber?.Title ?? '';
        });

        return tasks.filter(filter);
    }
}
