export const locale = {
    lang: 'en',
    data: {
        COMMENTS_ASSIGNMENT: {
            ADDCOMMENT: 'New comment',
            PROJECTS: 'Projects',
            COMMENTS: 'Comments',
            EDIT: 'Edit',
            SAVE: 'Save',
            DELETE: 'Delete',
            NOTIFICATIONS: {
                SUCCESSDELETE: 'Comment deleted succesful!',
                ERRORDELETE: 'Error on deleting comment!',
                SUCCESSUPDATE: 'Comment updated succesful!',
                ERRORUPDATE: 'Error on updating comment!',
                SUCCESSADD: 'Comment added succesful!',
                ERRORADD: 'Error on adding comment!',
            },
        },
    },
};
