export const locale = {
    lang: 'de',
    data: {
        REASSIGN_EMAIL: {
            FORM: {
                PROJECT: 'Projekt',
            },
            NOTIFICATIONS: {
                LOADING: 'E-Mails werden geladen...',
                SAVING: 'E-Mails werden verschoben...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'E-Mails erfolgreich verschoben.',
                ERROR: 'Fehler beim verschieben: ',
            },
            COLUMNS: {
                TITLE: 'Titel',
                SUBJECT: 'Betreff',
                TEXT: 'Nachricht',
                TO: 'An',
                FROM: 'Von',
                ATTACHMENTS: 'Anhänge',
            },
            SAVE: 'E-Mails verschieben',
            FOLDER: 'Ordner auswählen',
            TITLE: 'E-Mails löschen/verschieben',
        },
    },
};
