import { NgModule } from '@angular/core';
import { ListviewTaskComponent } from './listview-task.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

const routes = [
    {
        path: 'tasks',
        component: ListviewTaskComponent,
    },
];

@NgModule({
    declarations: [ListviewTaskComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
    ],
    exports: [ListviewTaskComponent],
})
export class ListviewTaskModule {}
