export const locale = {
  lang: "en",
  data: {
    NEW: "New Entry",
    CONTROLLING: "Controlling",
    TARGETFIGURES: "Target Figures",
    EDIT: "Edit entry",
    SUCCESS: "Saved successfully",
    YEAR: "Year",
    SEARCH: "Search entry",
    MONTH_: "Month",
    TABS: {
      NUMBERS: "Target Figures",
    },
    FORM: {
      TEAM: "Team",
      MONTH: "Month",
      YEAR: "Year",
      VALUE: "setpoint",
    },
    TABLE: {
      AMOUNT: "Target Amount",
      TEAM: "Team",
    },
    MONTH: {
      JANUARY: "January",
      FEBRUARY: "February",
      MARCH: "March",
      APRIL: "April",
      MAY: "May",
      JUNE: "June",
      JULY: "July",
      AUGUST: "August",
      SEPTEMBER: "September",
      OCTOBER: "October",
      NOVEMBER: "November",
      DECEMBER: "December",
    },
    SAVE: "Save",
  },
};
