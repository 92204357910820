<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "ASSIGN_EMAIL.EDIT" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "ASSIGN_EMAIL.NEW" | translate }}</span>

    <!-- Close Diaolog -->
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>

  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <formio class="formio-material" #formioComponent [refresh]="refreshForm" (ready)="formioReady($event)" (change)="onChange($event)"
    [renderOptions]="formio_renderOptions"
    [service]="this" [form]="formioConfiguration">
  </formio>

  <button mat-raised-button class="newButton" color="accent" (click)="dirup()">
    <mat-icon>arrow_upward</mat-icon>
    <!-- {{ "ASSIGN_EMAIL.DIRUP" | translate }} -->
  </button>

  <button mat-raised-button class="newButton" color="accent" (click)="refreshMails()">
    <mat-icon>refresh</mat-icon>
    <!-- {{ "ASSIGN_EMAIL.REFRESH" | translate }} -->
  </button>

  <button mat-raised-button class="newButton" color="accent" (click)="assignMails()">
    <!-- <mat-icon>refresh</mat-icon> -->
    {{ "ASSIGN_EMAIL.ASSIGN" | translate }}
  </button>

  <app-sharepoint-table *ngIf="tableConfig"
                        #documentGrid
                        [configuration]="tableConfig"
                        (onTooolbarButtonClick)="onTooolbarButtonClick($event)">
    <ng-template customCell for="ALREADYCREATED" let-dataItem="dataItem">
      <span class="duplicate-status-column"
            [class.non-duplicate]="!duplicateEmails[dataItem.ID]"
            [class.duplicate]="duplicateEmails[dataItem.ID]"
      >
        {{duplicateEmails[dataItem.ID] ? 'Ja' : 'Nein'}}
      </span>
    </ng-template>
  </app-sharepoint-table>

</div>
