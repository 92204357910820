export const locale = {
    lang: 'de',
    data: {
        PROJECTS: 'Projekte',
        BASE: 'Stammdaten',
        DOCS: 'Dokumente',
        INVOLVED: 'Beteiligte',
        TASKS: 'Arbeitspakete',
        KANBAN: 'Kanban',
        NOTES: 'Notizen',
        HEADER: 'Kanban',
        PROJECTNUMBER: 'Projektnummer',
    },
};
