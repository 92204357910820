export interface HighLight {
  Title: string;
  Count: number;
  Icon: string;
  Iconcolor: HighLightIconType;
  Query: HighLightQuery;
  Warning: boolean;
  Advanced: boolean;
}
export enum HighLightIconType {
  Success = 'highlight-icon-success',
  Message = 'highlight-icon-message',
  Info = 'highlight-icon-info',
  Danger = 'highlight-icon-danger'
}
export interface HighLightQuery {
  ListTitle: string;
  CamlQuery: string;
  isDocumentLibrary: boolean;
  RecursiveAll?: boolean;
  FolderName?: string;
}
