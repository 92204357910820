import { TimeService } from './../../services/time.service';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Inject,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
} from '@angular/core';
import { GlobalTask } from '../../models/globaltask.model';
import * as CamlBuilder from 'camljs';
import {
    TableConfiguration,
    SharePointService,
    ColumnTyp,
    FormatTypes,
    TableColumn,
    SharePointTableComponent,
    UpdateService,
} from 'sp-office365-framework';
import { from } from 'rxjs';
import { TaskService } from '../../services/task.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
    FrameworkService,
} from 'sp-office365-layout';
import { ConfirmDialogInvoicesComponent } from './confirm-dialog-invoices/confirm-dialog-invoices.component';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { PermissionKind } from '@pnp/sp/presets/all';
import { Timelevel } from '../../models/timelevel.model';
import {
    MatDialogRef,
    MatDialog,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { FormioComponent } from '@formio/angular';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-detail-invoice',
    templateUrl: './detail-invoice.component.html',
    styleUrls: ['./detail-invoice.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailInvoiceComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    };

    @Input() filter: any;
    @Input() auftragsId: number;
    @Input() isTask: boolean;
    @Input() id: number;
    @Input() initData: GlobalTask;

    @ViewChild('taskTable', { static: false }) taskTable;
    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('formio', { static: false }) formio: FormioComponent;

    confirmDialogRef: MatDialogRef<ConfirmDialogInvoicesComponent>;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public sharePointItem;
    public assignmentItem;
    public customerItem;
    public auftragItem;
    public formioConfiguration;
    public tableConfig: TableConfiguration;

    public selectedUser;
    public isCreateMode = true;
    public isManager = false;

    public uploadMultipleDocuments = true;
    public documentTabHeader;

    public arbeitsPaketId;
    public defaultvalueWorkPackes;
    public taskSetListItems = [];
    public taskSetListItemGetter = this._sharepointService
        .getListItems({
            title: 'Aufgabensets',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .TextField('Title')
                .NotEqualTo('')
                .ToString(),
        })
        .then((result) => {
            result.forEach((element) => {
                this.taskSetListItems.push(element.Title);
            });
        });

    public tableConfigTasks: TableConfiguration;

    public timesEnabled = false;

    public isNetto = true;

    private docTemplateId = 0;
    private worddata;

    private firstTimeLoad = true;

    get camlQueryTasks() {
        return new CamlBuilder()
            .Where()
            .LookupField('Arbeitspaket')
            .Id()
            .EqualTo(this.id)
            .OrderBy('Modified')
            .ToString();
    }

    get camlQueryProcessSteps() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Schritt')
            .ToString();
    }

    get camlQueryDocs() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public _documentService: DocumentsAssignmentService,
        public _tasksService: TaskService,
        public _timeService: TimeService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailInvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _applicationService: ApplicationService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _updateService: UpdateService,
        private http: HttpClient,
        private _frameworkservice: FrameworkService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.documentTabHeader = this._translateService.instant(
            'DETAIL_INVOICE.TABS.DOCS'
        );

        // reset timeservice
        this._timeService.reset();
    }

    ngOnInit() {
        this.auftragsId = this.data.auftragsId;
        this.isTask = this.data.isTask;
        this.id = this.data.taskId;
        this.initData = this.data._taskInitData;
        this.filter = this.data._filter;

        if (this.data._paketId) {
            this.arbeitsPaketId = this.data._paketId;
        }

        const promises = [];

        //this.getCurrentUserTest();

        //this._frameworkservice.getUserOrGroupById(this._sharepointService.currentUser.Id).then((user) => { debugger;});

        //var x = this._sharepointService.currentUser;

        //var x = this._sharepointService.sphostUrl;

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Zeiterfassung')
                    .then((value) => {
                        if (value == 'Ja') {
                            this.timesEnabled = true;
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        if (this.auftragsId) {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            listTitle: 'Aufträge',
                            id: this.auftragsId,
                        })
                        .then((assignementItem) => {
                            this.assignmentItem = assignementItem;
                            if (this.assignmentItem.KundeId) {
                                this._sharepointService
                                    .getItemById({
                                        listTitle: 'Firmen',
                                        id: this.assignmentItem.KundeId,
                                    })
                                    .then((customerItem) => {
                                        this.customerItem = customerItem; //customerItem

                                        if (this.assignmentItem.AntragId) {
                                            this._sharepointService
                                                .getItemById({
                                                    listTitle: 'Anträge',
                                                    id: this.assignmentItem
                                                        .AntragId,
                                                })
                                                .then((antragItem) => {
                                                    this.auftragItem =
                                                        antragItem; //auftragItem

                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        } else {
                                            resolve();
                                        }
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            } else {
                                resolve();
                            }
                            //resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                if (this.id != null) {
                    this.isCreateMode = false;

                    this._sharepointService
                        .getItemById({
                            id: this.id,
                            listTitle: 'Rechnungen',
                        })
                        .then((invoice) => {
                            this.sharePointItem = invoice;

                            this._sharepointService
                                .getItemAttachments({
                                    listTitle: 'Rechnungen',
                                    itemId: this.id,
                                })
                                .then((tmp) => {
                                    this.initForm();
                                });

                            /*
                            // Check if currentUser is Manager
                            if (
                                this.assignmentItem.AuftragsleiterId ==
                                this._sharepointService.currentUser.Id
                            ) {
                                this.isManager = true;
                            }

                            // Set timelevel
                            this._timeService.level = Timelevel.Task;

                            // Set projectID
                            this._timeService.projectId = this.assignmentItem.Id;

                            // Set taskID
                            this._timeService.taskId = this.sharePointItem.Id;

                            // Init timetable
                            this._timeService.initTimeTable(
                                this.isManager,
                                this.auftragsId + ''
                            );
                            */
                        });
                } else {
                    this.initForm();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onTabChange(e) {
        //
    }

    formioReady(ev?) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );

            const gezahlt = dataForm.Gezahlt;
            const forderung = dataForm.Forderung;
            dataForm.ForderungBrutto = Math.round(forderung * 119) / 100;
            dataForm.OffenBrutto = Math.round(dataForm.Offen * 119) / 100;
            dataForm.GezahltBrutto = Math.round(gezahlt * 119) / 100;
        } else {
            dataForm = emptyObj;

            // Set default values

            //dataForm.isTask = this.isTask;
            //dataForm.Aufgabenart = 'Arbeitspaket';

            var date = new Date();

            dataForm.Jahr = date.getFullYear();

            var month = date.getMonth() + 1;
            dataForm.Monat = month.toString();

            date.setDate(date.getDate() + 30);

            dataForm.Frist = date;

            dataForm.ProjektId = this.assignmentItem.Id;

            dataForm.Abrechnungstyp = 'Pauschal';
            dataForm.AR_Number = 0;

            if (this.auftragItem && this.auftragItem.Antragsdatum) {
                dataForm.Auftragsdatum = formatDate(
                    this.auftragItem.Antragsdatum,
                    'dd.MM.yyyy',
                    'de-DE'
                );
            }

            //dataForm.Status = 'Nicht begonnen';
            //dataForm.Kennzeichen = 'Unkritisch';

            /*if (this.isTask) {
                //dataForm.Aufgabenart = 'Aufgabe';

                if (this.arbeitsPaketId) {
                    //dataForm.ArbeitspaketId = this.arbeitsPaketId;
                }
            }*/
        }

        if (dataForm.Monat) {
            dataForm.Monat = dataForm.Monat.toString();
        }

        if (this.customerItem) {
            dataForm.Address =
                this.customerItem.Title +
                '\n' +
                this.customerItem.Strasse +
                '\n' +
                this.customerItem.WorkZip +
                ' ' +
                this.customerItem.WorkCity;
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    fillWordData(data) {
        this.docTemplateId = data.DocTemplateId;

        //this.getCurrentUserTest().then((contactStr) => {

        this.worddata = {};
        this.worddata.address = data.Address;
        //this.worddata.contact = contactStr + " - " + new Date();//.format("dd.MM.yyyy");
        this.worddata.von = formatDate(data.Von, 'dd.MM.yyyy', 'de-DE');
        this.worddata.bis = formatDate(data.Bis, 'dd.MM.yyyy', 'de-DE');
        //this.worddata.invoicenumber = "invnumber todo";
        this.worddata.projectnumber = this.assignmentItem.Projektnummer;
        this.worddata.Frist = formatDate(data.Frist, 'dd.MM.yyyy', 'de-DE');

        this.worddata.isAR = data.isAbschlagsrechnung;

        if (this.auftragItem) {
            if (this.auftragItem.Antragsdatum) {
                this.worddata.Auftragsdatum = formatDate(
                    this.auftragItem.Antragsdatum,
                    'dd.MM.yyyy',
                    'de-DE'
                );
            }
            if (this.auftragItem.Antragssumme) {
                this.worddata.Gesamtsumme_Auftrag =
                    this.auftragItem.Antragssumme;
            }
            if (this.auftragItem.Nebenkosten) {
                this.worddata.Nebenkosten_Prozent =
                    this.auftragItem.Nebenkosten;
            }
        }

        //});
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_INVOICE.NOTIFICATIONS.SAVINGITEM'
            ),
            this._translateService.instant('DETAIL_INVOICE.NOTIFICATIONS.WAIT')
        );

        this.fillWordData(e.data);

        delete e.data.submit;
        //delete e.data.isTask;
        delete e.data.DocTemplateId;
        delete e.data.Address;
        delete e.data.ForderungBrutto;
        delete e.data.GezahltBrutto;
        delete e.data.OffenBrutto;
        delete e.data.Auftragsdatum;

        const rechnungsdatum = new Date(e.data.Rechnungsdatum);
        e.data.Jahr = rechnungsdatum.getFullYear();
        e.data.Monat = rechnungsdatum.getMonth() + 1;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        // Set Team and Standort
        e.data.StandortId = this.assignmentItem.StandortId;
        e.data.TeamId = this.assignmentItem.TeamId;

        // Get ARNumber
        if (this.sharePointItem) {
            delete e.data.isAbschlagsrechnung;

            const data = { ...e.data };
            delete data.Rechnungsnummer;

            return from(
                this._sharepointService
                    .updateItem({
                        id: this.id,
                        listTitle: 'Rechnungen',
                        newFiledValues: data,
                    })
                    .then(() => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Rechnungen',
                                id: this.sharePointItem.Id,
                            })
                            .then((item) => {
                                this.sharePointItem = item;

                                const promises = [];

                                /*if (this.stateChanged) {
                                    let parentItemID = this.arbeitsPaketId;

                                    if (!this.isTask) {
                                        parentItemID = this.auftragsId;
                                    }

                                    // Update state of parent item
                                    promises.push(
                                        this._tasksService.updateStateOfParentItem(
                                            this.isTask,
                                            parentItemID,
                                            this.sharePointItem
                                        )
                                    );
                                }*/

                                Promise.all(promises)
                                    .then(() => {
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_INVOICE.NOTIFICATIONS.SUCCESSITEM'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close('success');
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            return from(
                this._applicationService
                    .getInvoiceNumber()
                    .then((invoiceNumber) => {
                        invoiceNumber = `m-${invoiceNumber}`;
                        e.data.Rechnungsnummer = invoiceNumber;
                        this.worddata.invoicenumber = invoiceNumber;

                        this._applicationService
                            .getARNumber(
                                this.assignmentItem.Id,
                                e.data.isAbschlagsrechnung
                            )
                            .then((ARNumber) => {
                                e.data.AR_Number = ARNumber;
                                this.worddata.ARNumber = ARNumber;
                                if (ARNumber !== 0) {
                                    e.data.Title = e.data.Title.replace(
                                        '{R_NR}',
                                        e.data.Rechnungsnummer
                                    );
                                }
                                delete e.data.isAbschlagsrechnung;

                                this._sharepointService
                                    .addItem({
                                        listTitle: 'Rechnungen',
                                        data: e.data,
                                        folder: this.auftragsId.toString(),
                                    })
                                    .then((newItemId) => {
                                        this._sharepointService
                                            .getItemById({
                                                listTitle: 'Rechnungen',
                                                id: newItemId,
                                            })
                                            .then((item) => {
                                                this.sharePointItem = item;

                                                const promises = [];

                                                if (this.docTemplateId) {
                                                    promises.push(
                                                        this.generateWordDoc(
                                                            this.docTemplateId,
                                                            'Rechnungen',
                                                            this.sharePointItem
                                                                .Id,
                                                            'Rechnung_' +
                                                                this
                                                                    .sharePointItem
                                                                    .Id +
                                                                '_test.docx',
                                                            this.worddata
                                                        )
                                                    );
                                                }

                                                Promise.all(promises)
                                                    .then(() => {
                                                        this._loadingService.hide();

                                                        this._snackBarService.add(
                                                            this._translateService.instant(
                                                                'DETAIL_INVOICE.NOTIFICATIONS.SUCCESSITEM'
                                                            ),
                                                            '',
                                                            {
                                                                duration: 4000,
                                                                panelClass:
                                                                    'success-dialog',
                                                            }
                                                        );

                                                        this.close('success');
                                                    })
                                                    .catch((error) => {
                                                        this._loadingService.hide();
                                                        console.error(error);
                                                    });
                                            })
                                            .catch((error) => {
                                                this._loadingService.hide();
                                                console.error(error);
                                            });
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    checkUserPermissionInProject() {
        this._sharepointService
            .getItemById({ listTitle: 'Aufträge', id: this.auftragsId })
            .then((project) => {
                this._sharepointService.web.lists
                    .getByTitle('Aufträge')
                    .items.getById(project.ID)
                    .userHasPermissions(
                        this.selectedUser.LoginName,
                        PermissionKind.EditListItems
                    )
                    .then((result) => {
                        if (!result) {
                            this.confirmDialogRef = this.dialog.open(
                                ConfirmDialogInvoicesComponent,
                                {
                                    data: {
                                        confirmMessage:
                                            this._translateService.instant(
                                                'DETAIL_INVOICE.GIVEUSERPERMISSIONTOPROJECT'
                                            ),
                                    },
                                }
                            );

                            this.confirmDialogRef
                                .afterClosed()
                                .subscribe((result) => {
                                    if (result) {
                                        this._loadingService.show(
                                            this._translateService.instant(
                                                'DETAIL_INVOICE.NOTIFICATIONS.SAVING'
                                            ),
                                            this._translateService.instant(
                                                'DETAIL_INVOICE.NOTIFICATIONS.WAIT'
                                            )
                                        );

                                        // Set Permissions
                                        let newValues = [];

                                        if (
                                            project.BearbeitungsberechtigungId
                                        ) {
                                            if (
                                                project
                                                    .BearbeitungsberechtigungId
                                                    .results
                                            ) {
                                                newValues =
                                                    project
                                                        .BearbeitungsberechtigungId
                                                        .results;
                                            } else {
                                                newValues =
                                                    project.BearbeitungsberechtigungId;
                                            }
                                        }

                                        newValues.push(this.selectedUser.Id);

                                        this._sharepointService
                                            .updateItem({
                                                id: project.Id,
                                                listTitle: 'Aufträge',
                                                newFiledValues: {
                                                    BearbeitungsberechtigungId:
                                                        {
                                                            results: newValues,
                                                        },
                                                },
                                            })
                                            .then(() => {
                                                this._sharepointService
                                                    .getItemById({
                                                        listTitle: 'Aufträge',
                                                        id: this.auftragsId,
                                                    })
                                                    .then((assignment) => {
                                                        this._applicationService
                                                            .updateAssignemntPermissions(
                                                                assignment
                                                            )
                                                            .then(() => {
                                                                // Success
                                                                this._loadingService.hide();

                                                                this._snackBarService.add(
                                                                    this._translateService.instant(
                                                                        'DETAIL_INVOICE.NOTIFICATIONS.SUCCESS'
                                                                    ),
                                                                    '',
                                                                    {
                                                                        duration: 4000,
                                                                        panelClass:
                                                                            'success-dialog',
                                                                    }
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                this._loadingService.hide();
                                                                console.error(
                                                                    error
                                                                );
                                                            });
                                                    })
                                                    .catch((error) => {
                                                        this._loadingService.hide();
                                                        console.error(error);
                                                    });
                                            })
                                            .catch((error) => {
                                                this._loadingService.hide();
                                                console.error(error);
                                            });
                                    }

                                    this.confirmDialogRef = null;
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    isNettoChange(event) {
        this.isNetto = !event.checked;

        this._frameworkservice.updateFormioComponentProperty(
            this.formio,
            'Forderung',
            'disabled',
            !this.isNetto
        );
        this._frameworkservice.updateFormioComponentProperty(
            this.formio,
            'Gezahlt',
            'disabled',
            !this.isNetto
        );

        this._frameworkservice.updateFormioComponentProperty(
            this.formio,
            'ForderungBrutto',
            'disabled',
            this.isNetto
        );
        this._frameworkservice.updateFormioComponentProperty(
            this.formio,
            'GezahltBrutto',
            'disabled',
            this.isNetto
        );

        //this.formioConfiguration.components[0].disabled = this.isNetto;
        //this.formioConfiguration.components[5].disabled = this.isNetto;

        /*this.formioConfiguration.components[3].columns.forEach(column => {
            column.components.forEach(component => {
                if ( component.key == "Forderung" || component.key == "Gezahlt" || component.key == "Offen" ) {
                    component.disabled = !this.isNetto;

                }
                if ( component.key == "ForderungBrutto" || component.key == "GezahltBrutto" || component.key == "OffenBrutto" ) {
                    component.disabled = this.isNetto;
                }
            });
        });*/

        //this.formioReady();
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }
            if (ev.changed.component.key === 'Gezahlt') {
                if (this.isNetto) {
                    // Get form values
                    const data = JSON.parse(JSON.stringify(ev.data));

                    let claim = data.Forderung ? data.Forderung : 0;
                    let paid = ev.changed.value;

                    data.Offen = claim - paid;
                    data.OffenBrutto = Math.round(data.Offen * 119) / 100;
                    data.GezahltBrutto = Math.round(paid * 119) / 100;

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'Forderung') {
                if (this.isNetto) {
                    // Get form values
                    const data = JSON.parse(JSON.stringify(ev.data));

                    let paid = data.Gezahlt ? data.Gezahlt : 0;
                    let claim = ev.changed.value;

                    data.Offen = claim - paid;
                    data.OffenBrutto = Math.round(data.Offen * 119) / 100;
                    data.ForderungBrutto = Math.round(claim * 119) / 100;

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'ForderungBrutto') {
                if (!this.isNetto) {
                    // Get form values
                    const data = JSON.parse(JSON.stringify(ev.data));

                    //let paid = data.Gezahlt ? data.Gezahlt : 0;
                    let claim = ev.changed.value;

                    data.Forderung = Math.round((claim / 1.19) * 100) / 100;

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'GezahltBrutto') {
                if (!this.isNetto) {
                    // Get form values
                    const data = JSON.parse(JSON.stringify(ev.data));

                    //let paid = data.Gezahlt ? data.Gezahlt : 0;
                    let paid = ev.changed.value;

                    data.Gezahlt = Math.round((paid / 1.19) * 100) / 100;

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'isAbschlagsrechnung') {
                // Get form values
                const data = JSON.parse(JSON.stringify(ev.data));

                let isAR = ev.changed.value;

                this._frameworkservice.updateFormioComponentProperty(
                    this.formio,
                    'Title',
                    'disabled',
                    isAR
                );

                data.Title =
                    'AR {R_NR} vom ' +
                    formatDate(new Date(), 'dd.MM.yyyy', 'de-DE');
                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            }
            /*
            if (ev.changed.component.key === 'DueDate') {
                // Check DueDate
                const data = JSON.parse(JSON.stringify(ev.data));

                const assignementDate = new Date(
                    this.assignmentItem.Auftragsende
                );
                const taskDate = new Date(data.DueDate);

                if (assignementDate < taskDate) {
                    // Set DueDate Max
                    data.DueDate = this.assignmentItem.Auftragsende;

                    this._snackBarService.add(
                        this._translateService.instant(
                            'DETAIL_INVOICE.NOTIFICATIONS.DUEDATEERROR'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            }
            */
        }
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
            case 'AddFolder':
                this._documentService.addNewFolder(
                    this.documentGrid,
                    this.tableConfig.list
                );
                break;
        }
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.TITLE'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    allowMultipleMasks: false,
                                    showWordCount: false,
                                    showCharCount: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'Title',
                                    defaultValue: '',
                                    validate: {
                                        required: true,
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                    },
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.BILL_NUMBER'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    allowMultipleMasks: false,
                                    showWordCount: false,
                                    showCharCount: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    disabled: true,
                                    hidden: !this.data.taskId,
                                    key: 'Rechnungsnummer',
                                    defaultValue: '',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: !this.data.taskId ? 0 : 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: true,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: 'Abschlagsrechnung',
                                    hidden: false,
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'checkbox',
                                    input: true,
                                    key: 'isAbschlagsrechnung',
                                    defaultValue: false,
                                    tab: 0,
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns3',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_INVOICE.COLUMNS.PROJECTID'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'ProjektId',
                    list: 'Aufträge',
                    // valueProperty: 'value',
                    // selectThreshold: 0.3,
                    isPeoplePicker: false,
                    removable: true,
                    disabled: true,
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.ORDERDATE'
                                    ),
                                    autoExpand: false,
                                    tableView: false,
                                    key: 'Auftragsdatum',
                                    type: 'textfield',
                                    input: true,
                                    disabled: true,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.RECHNUNGSDATUM'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Rechnungsdatum',
                                    suffix: true,
                                    // defaultValue: new Date(),
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.VON'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Von',
                                    suffix: true,
                                    // defaultValue: new Date(),
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.BIS'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Bis',
                                    suffix: true,
                                    customClass: 'bis-field',
                                    // defaultValue: new Date(),
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.YEAR'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'Jahr',
                                    hidden: true,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.MONTH'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'Monat',
                                    hidden: true,
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.JAN'
                                                ),
                                                value: '1',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.FEB'
                                                ),
                                                value: '2',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.MAR'
                                                ),
                                                value: '3',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.APR'
                                                ),
                                                value: '4',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.MAY'
                                                ),
                                                value: '5',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.JUN'
                                                ),
                                                value: '6',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.JUL'
                                                ),
                                                value: '7',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.AUG'
                                                ),
                                                value: '8',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.SEP'
                                                ),
                                                value: '9',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.OCT'
                                                ),
                                                value: '10',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.NOV'
                                                ),
                                                value: '11',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.DEC'
                                                ),
                                                value: '12',
                                            },
                                        ],
                                    },
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    lazyLoad: false,
                                },
                            ],
                            width: 0,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns1',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.INVOICETYPE'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'Abrechnungstyp',
                                    data: {
                                        values: [
                                            {
                                                label: 'Pauschal',
                                                value: 'Pauschal',
                                            },
                                            {
                                                label: 'Stundenhonorar',
                                                value: 'Stundenhonorar',
                                            },
                                            /*
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_INVOICE.FORM.MONTH.FEB'
                                                ),
                                                value: '2',
                                            },
                                            */
                                        ],
                                    },
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    lazyLoad: false,
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.CLAIM'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'Forderung',
                                    disabled: !this.isNetto,
                                    decimalLimit: 2,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.CLAIMGROSS'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'ForderungBrutto',
                                    disabled: this.isNetto,
                                    decimalLimit: 2,
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.PAID'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'Gezahlt',
                                    disabled: !this.isNetto,
                                    decimalLimit: 2,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.PAIDGROSS'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'GezahltBrutto',
                                    disabled: this.isNetto,
                                    decimalLimit: 2,
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.OUTSTANDING'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'Offen',
                                    disabled: true,
                                    decimalLimit: 2,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.OUTSTANDINGGROSS'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_INVOICE.FORM.INEURO'
                                    ),
                                    type: 'number',
                                    input: true,
                                    key: 'OffenBrutto',
                                    disabled: true,
                                    decimalLimit: 2,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_INVOICE.COLUMNS.FRIST'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'datetime',
                                    input: true,
                                    key: 'Frist',
                                    suffix: true,
                                    // defaultValue: new Date(),
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    enableTime: false,
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                    /*validateOn: 'blur',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },*/
                                },
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },

                {
                    label: this._translateService.instant(
                        'DETAIL_INVOICE.COLUMNS.NOTIZEN'
                    ),
                    type: 'textarea',
                    key: 'Notizen',
                    inputFormat: 'plain',
                },
                // TODO: Uncomment when needed. Rechnungsvorlage and Anschrift fields are hidden in terms of 28798
                // {
                //     label: this._translateService.instant(
                //         'DETAIL_INVOICE.COLUMNS.TEMPLATE'
                //     ),
                //     type: 'sharePointAutocomplete',
                //     input: true,
                //     key: 'DocTemplateId',
                //     selectThreshold: 0.3,
                //     list: 'Dokumentenvorlagen',
                //     //titleField: 'Name',
                //     isDocumentLibrary: true,
                //     searchQuery: new CamlBuilder()
                //         .Where()
                //         .TextField('FileLeafRef')
                //         .Contains('<%searchText%>')
                //         .OrderBy('FileLeafRef')
                //         .ToString(),
                //     searchQueryIfIsEmpty: new CamlBuilder()
                //         .Where()
                //         .NumberField('ID')
                //         .NotEqualTo(-1)
                //         .OrderBy('FileLeafRef')
                //         .ToString(),
                //     isPeoplePicker: false,
                //     removable: true,
                //     isCreateMode: this.isCreateMode,
                // },
                // {
                //     label: this._translateService.instant(
                //         'DETAIL_INVOICE.COLUMNS.ADDRESS'
                //     ),
                //     autoExpand: false,
                //     tableView: false,
                //     key: 'Address',
                //     type: 'textarea',
                //     input: true,
                //     disabled: true,
                // },
                /*{
                    label: 'isTask',
                    hidden: true,
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'checkbox',
                    input: true,
                    key: 'isTask',
                    defaultValue: false,
                    tab: 0,
                },*/
                {
                    label: this._translateService.instant(
                        'DETAIL_INVOICE.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };

        if (!this.id) {
            return;
        }
    }

    public getCurrentUserTest() {
        return new Promise((resolve, reject) => {
            this._sharepointService.web
                .currentUser()
                .then((user) => {
                    //debugger;
                    resolve(
                        user.Title + ' - ' + 'TelefonTmp' + ' - ' + user.Email
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private generateWordDoc(
        templateID,
        listName,
        invoiceID,
        fileName,
        worddata
    ) {
        //var docfile = docx;
        //var name = "Test.docx";//docfile.name;
        //var x = this.getDocxFields();
        //debugger;

        /*var requestObj = {
            webUrl: "https://sharepointtemplate.sharepoint.com/sites/DevMarco/bvb_test",
            templateListName: "Vorlagen",
            templateID: 4,
            listName: "Anerkennungsverfahrendokumente",
            fileName: "TestDocGen.docx",
            folder: "115",
            Fieldvalues: {
                "Name" : "TestName123",
                "cb_ingenieur" : "true"
            }
        };*/

        var artestdata = {
            AR_Titel_1: 'AR 2021-211 vom 11.1.2021',
            AR_Titel_2: 'AR 2021-212 vom 12.2.2021',
            AR_Titel_3: 'AR 2021-213 vom 13.3.2021',
            AR_Titel_4: 'AR 2021-214 vom 14.4.2021',
            AR_Netto_1: 1050,
            AR_Netto_2: 2100,
            AR_Netto_3: 3150,
            AR_Netto_4: 4200,
        };

        var testFieldValues = {
            Anschrift: worddata.address,
            Kontakt: worddata.contact,
            Rechnungsnummer: worddata.invoicenumber,
            Projektnummer: worddata.projectnumber,
            von: worddata.von,
            bis: worddata.bis,
            Auftragsdatum: worddata.Auftragsdatum,
            Gesamtsumme_Auftrag: worddata.Gesamtsumme_Auftrag,
            Leistungen: worddata.Gesamtsumme_Auftrag,
            Frist: worddata.Frist,
            Nebenkosten_Prozent: worddata.Nebenkosten_Prozent,
        };

        //TODO vllt number values getrennt speichern von strings (für berechnung und anzeige mit 2 nachkomma) ?

        var AR_Netto_Sum = 0;
        for (let i = 1; i < 5; i++) {
            testFieldValues['AR_Titel_' + i] =
                'abzgl. ' + artestdata['AR_Titel_' + i]; //AR_Titel_1
            testFieldValues['AR_Netto_' + i] = artestdata['AR_Netto_' + i];
            testFieldValues['AR_Mwst_' + i] = (
                testFieldValues['AR_Netto_' + i] * 0.19
            ).toFixed(2); //Math.round(data.Offen * 119) / 100;
            testFieldValues['AR_Brutto_' + i] = (
                testFieldValues['AR_Netto_' + i] * 1.19
            ).toFixed(2); //+ testFieldValues["AR_Mwst_"+i];
            AR_Netto_Sum = AR_Netto_Sum + testFieldValues['AR_Netto_' + i];
        }

        testFieldValues['Nebenkosten_Prozent_Netto'] =
            testFieldValues['Leistungen'] *
            (testFieldValues['Nebenkosten_Prozent'] / 100);
        testFieldValues['Summe_Nebenkosten_Netto'] =
            testFieldValues['Leistungen'] +
            testFieldValues['Nebenkosten_Prozent_Netto'];

        if (worddata.isAR) {
            testFieldValues['Summe_Leistungen_Netto'] = AR_Netto_Sum;
        } else {
            testFieldValues['Summe_Leistungen_Netto'] =
                testFieldValues['Summe_Nebenkosten_Netto'];
        }

        testFieldValues['Summe_Leistungen_Mwst'] =
            testFieldValues['Summe_Leistungen_Netto'] * 0.19;
        testFieldValues['Summe_Leistungen_Brutto'] =
            testFieldValues['Summe_Leistungen_Netto'] +
            testFieldValues['Summe_Leistungen_Mwst'];

        if (worddata.isAR) {
            testFieldValues['AR_Netto_Sum'] =
                testFieldValues['Summe_Leistungen_Netto'] - AR_Netto_Sum;
        } else {
            testFieldValues['AR_Netto_Sum'] =
                testFieldValues['Summe_Nebenkosten_Netto'];
        }

        testFieldValues['AR_Mwst_Sum'] = testFieldValues['AR_Netto_Sum'] * 0.19;
        testFieldValues['AR_Brutto_Sum'] =
            testFieldValues['AR_Netto_Sum'] + testFieldValues['AR_Mwst_Sum'];
        testFieldValues['Zahlbetrag'] = testFieldValues['AR_Brutto_Sum'];

        var requestObj = {
            webUrl: this._sharepointService.sphostUrl,
            templateListName: 'Dokumentenvorlagen',
            templateID: templateID,
            listName: listName,
            itemID: invoiceID,
            fileName: fileName,
            folder: '',
            //Fieldvalues: worddata
            Fieldvalues: testFieldValues,
            /*
            Fieldvalues: {
                "Anschrift" : worddata.address,
                "Kontakt" : worddata.contact,
                "Rechnungsnummer": worddata.invoicenumber,
                "Projektnummer": worddata.projectnumber,
                "von": worddata.von,
                "bis": worddata.bis,
                "Auftragsdatum": worddata.Auftragsdatum,
                "Gesamtsumme_Auftrag": worddata.Gesamtsumme_Auftrag,
                "Leistungen": worddata.Gesamtsumme_Auftrag,
                /*
                "Nebenkosten_Prozent": "nbn%",
                "Summe_Nebenkosten_Netto": "nbn netto",
                "Summe_Nebenkosten_Mwst": "nbn mwst",
                "Summe_Nebenkosten_Brutto": "nbn brutto",

                "AR_Titel1": "AR 2021-213 vom 12.3.2021",
                "AR1_Netto": "100",
                "AR1_Mwst": "19",
                "AR1_Brutto": "119",
                "AR_Titel2": "AR 2021-214 vom 13.4.2021",
                "AR2_Netto": "200",
                "AR2_Mwst": "38",
                "AR2_Brutto": "238",
                "AR_Titel3": "AR 2021-215 vom 14.5.2021",
                "AR3_Netto": "300",
                "AR3_Mwst": "57",
                "AR3_Brutto": "357",
                "AR_Titel4": "AR 2021-216 vom 15.6.2021",
                "AR4_Netto": "400",
                "AR4_Mwst": "76",
                "AR4_Brutto": "476",
                "Summe_AR_Netto": "sum ar netto",
                "Summe_AR_Mwst": "sum ar mwst",
                "Summe_AR_Brutto": "sum ar brutto",
                "Zahlbetrag": "zahlbetr",
                "Frist": "02.02.1971",
                */
            //}
        };

        //const body = JSON.stringify(requestObj);
        //debugger;
        //this._applicationService.callApiWithAuth("http://localhost:59950/api/Document/GenerateWordDoc", [], requestObj);
        //this._applicationService.callApiWithAuth("http://localhost:62849/api/GenWord", [], requestObj);

        //this._applicationService.callApiWithAuth("https://mopa-server.azurewebsites.net/api/Document", [], body);

        return this._applicationService.callApiWithAuth(
            'https://mopa-server.azurewebsites.net/api/Document/GenerateWordDoc',
            [],
            requestObj
        );

        //
        //const formData = new FormData();

        //formData.append(name, docfile);
        /*
        return new Promise<void>((resolve, reject) => {

            let headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');
            //debugger;
            this.http.post("https://mopa-server.azurewebsites.net/api/Document", body, { headers: headers }).toPromise().then(
                (res: string) => { // Success
                    debugger;
                    //this.docxFieldsResponse = res;
                    //this.docxSignatureResponse  = JSON.parse(res);
                    //resolve(JSON.parse(res));
                    //resolve(folderServerRelativeUrl + "/" + fileName);
                    resolve();
                },
                (error) => {
                    reject(error);
                    debugger;
                    console.error('Error in generateWord function');
                }
            );
        });*/
    }
}
