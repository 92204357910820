import { FilterTimeComponent } from './../../time/filter-time/filter-time.component';
import { FilterTimeModule } from './../../time/filter-time/filter-time.module';
import { NgModule } from '@angular/core';
import { ListviewReportComponent } from './listview-report.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

const routes = [
    {
        path: 'reports',
        component: ListviewReportComponent,
    },
];

@NgModule({
    declarations: [ListviewReportComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        FilterTimeModule,
        ExcelExportModule
    ],
    exports: [ListviewReportComponent]
})
export class ListviewReportModule {}
