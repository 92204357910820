import {
    Component,
    OnInit,
    ViewChild,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import { fuseAnimations } from 'sp-office365-layout';
import { DetailInvolvedComponent } from '../detail-involved/detail-involved.component';

@Component({
    selector: 'app-table-involved',
    templateUrl: './table-involved.component.html',
    styleUrls: ['./table-involved.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TableInvolvedComponent implements OnInit {
    @ViewChild('involvedGrid', { static: false })
    involvedGrid: SharePointTableComponent;

    _auftragsId: number;
    @Input() set auftragsId(aId: number) {
        this._auftragsId = aId;
    }

    _configuration: TableConfiguration;
    @Input() set configuration(config: TableConfiguration) {
        this._configuration = config;
    }

    constructor(public dialog: MatDialog) {}

    ngOnInit() {}

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.openDialog();
                break;
            case 'Edit':
                this.openDialog(e.dataItem.Id);
                break;
        }
    }

    openDialog(involvedId?): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            involvedId,
        };

        const dialogRef = this.dialog.open(
            DetailInvolvedComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.involvedGrid.refresh();
            }
            this.involvedGrid.refresh();
        });
    }

    refresh() {
        this.involvedGrid.refresh();
    }
}
