import CamlBuilder from 'camljs';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1117Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const ordersList: IList =
                await this._sharePointService.web.lists.getByTitle('Anträge');

            const promises = [
                this._updateService.addField(
                    ordersList,
                    'Ist_Auftrag',
                    FieldType.Boolean
                ),
            ];

            Promise.all(promises)
                .then(async () => {
                    try {
                        const allEntrys =
                            await this._sharePointService.getListItems({
                                title: 'Anträge',
                                isDocumentLibrary: false,
                                camlQuery: new CamlBuilder()
                                    .Where()
                                    .NumberField('ID')
                                    .NotEqualTo(-1)
                                    .ToString(),
                            });

                        for (let entry of allEntrys) {
                            await this._sharePointService.updateItem({
                                listTitle: 'Anträge',
                                id: entry.Id,
                                newFiledValues: {
                                    Ist_Auftrag: true,
                                },
                            });
                        }
                        resolve();
                    } catch (err) {
                        console.error(err);
                        reject(err);
                    }
                })
                .catch((error) => console.error(error));
        });
        return promise;
    }
}
