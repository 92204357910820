import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import * as CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/main/services/application.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-select-location-dialog',
    templateUrl: './select-location-dialog.component.html',
    styleUrls: ['./select-location-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLocationDialogComponent implements OnInit {
    public formioConfiguration: any;
    public formio_renderOptions = {
        // language: 'de' 
    };
    public refreshForm: EventEmitter<any> = new EventEmitter();
    public locationId: number;

    constructor(
        private _translateService: TranslateService,
        private _applicationService: ApplicationService,
        private matDialogRef: MatDialogRef<SelectLocationDialogComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant('FORM.LOCATION'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'StandortId',
                    list: 'Standorte',
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    onSelected: (control, data) => {
                        this.locationId = data.Id;
                    },
                    removable: true,
                }
            ],
        };
    }

    public formioReady(): void {
        let dataForm;
        const emptyObj: any = { Date: new Date() };

        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises).then(
            () =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                }),
            (error) => {
                console.error(error);
            }
        );
    }

    public selectLocationHandler(): void {
        if (this.locationId) {
            this.matDialogRef.close(this.locationId);
        }
    }

}
