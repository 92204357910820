import { MatToolbarModule } from '@angular/material/toolbar';
import { DurationTimeModule } from './../duration-time/duration-time.module';
import { NgModule } from '@angular/core';
import { NewTimeComponent } from './new-time.component';
import { FormioModule } from '@formio/angular';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { DurationTimeComponent } from '../duration-time/duration-time.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [NewTimeComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        FuseSidebarModule,
        FormioModule,
        DurationTimeModule,
        MatToolbarModule,
        DragDropModule
    ],
    exports: [NewTimeComponent]
})
export class NewTimeModule {}
