export const locale = {
    lang: 'de',
    data: {
        DETAIL_CUSTOMER: {
            EDIT: 'Kunden bearbeiten',
            NEW: 'Neuer Kunde',
            NOTIFICATIONS: {
                SAVING: 'Kunde wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Kunde erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
            },
            FORM: {
                TITLE1: 'Firmenname 1',
                TITLE2: 'Firmenname 2',
                TITLE3: 'Firmenname 3',
                STREET: 'Straße/Hausnummer',
                ZIP: 'PLZ',
                CITY: 'Ort',
                SAVE: 'Speichern',
                LOCATION: 'Niederlassung',
                EMAIL: 'E-Mail',
                LAND: "Land"
            },
            TABS: {
                BASE: 'Stammdaten',
                CONTACTS: 'Kontakte',
            },
            CONTACTS: {
                NEW: 'Neuer Kontakt',
                COLUMNS: {
                    TITLE: 'Nachname',
                    FIRSTNAME: 'Vorname',
                    EMAIL: 'E-Mail',
                    JOBTITLE: 'Position',
                    WORKPHONE: 'Telefon (Geschäftlich)',
                    HOMEPHONE: 'Telefon (Privat)',
                    CELLPHONE: 'Mobil',
                    WORKFAX: 'Fax',
                    WORKADRESS: 'Adresse',
                    WEBPAGE: 'Homepage',
                    CUSTOMER: 'Firma',
                    SAVE: 'Speichern',
                },
            },
        },
    },
};
