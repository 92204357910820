import { IList, FieldUserSelectionMode } from '@pnp/sp/presets/all';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1057Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const propertyList = this.sharePointService.web.lists.getByTitle(
                'Properties'
            );
            const projectList = this.sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            let fachbereicheList: IList;
            let teamList: IList;

            const promises = [
                // Add List Fachbereiche
                await this._updateService.addList('Fachbereiche'),
                // Get List Fachbereiche
                await new Promise<void>((resolve) => {
                    fachbereicheList = this.sharePointService.web.lists.getByTitle(
                        'Fachbereiche'
                    );
                    resolve();
                }),
                // Add List Teams
                await this._updateService.addList('Teams'),
                // Get List Teams
                await new Promise<void>((resolve) => {
                    teamList = this.sharePointService.web.lists.getByTitle(
                        'Teams'
                    );
                    resolve();
                }),
                // Add Field Bereichsnummer to List Fachbereiche
                await this._updateService.runFunctionIfFieldNotExists(
                    fachbereicheList,
                    'Bereichsnummer',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            fachbereicheList.fields
                                .addText('Bereichsnummer')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Projektnummer to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    projectList,
                    'Projektnummer',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            projectList.fields.addText('Projektnummer').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Gruppe to List Teams
                await this._updateService.runFunctionIfFieldNotExists(
                    teamList,
                    'Gruppe',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // teamList.fields
                            //     .addUser(
                            //         'Gruppe',
                            //         FieldUserSelectionMode.PeopleAndGroups
                            //     )
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Team to List Aufträge
                await new Promise<void>((resolve, reject) => {
                    teamList
                        .select('Id')()
                        .then(
                            (teamListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        projectList,
                                        'Team',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // projectList.fields
                                                    //     .addLookup(
                                                    //         'Team',
                                                    //         teamListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Fachbereich to List Aufträge
                await new Promise<void>((resolve, reject) => {
                    fachbereicheList
                        .select('Id')()
                        .then(
                            (fachbereicheListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        projectList,
                                        'Fachbereich',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // projectList.fields
                                                    //     .addLookup(
                                                    //         'Fachbereich',
                                                    //         fachbereicheListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Item to List Properties
                await propertyList.items.add({
                    Title: 'Aktuelle Projektnummer',
                    Value: '00001',
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
