<div fxFlex fxLayout="row" fxLayoutAlign="start center" class="p-6">

  <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.gt-sm="space-between center">

    <div>
      <span class="selected-contacts-count">
        <span class="mr-4">{{selectedItems.length}}</span>
        <span>{{ "SELECTED" | translate}}</span>
      </span>

    </div>

    <div class="multi-select-actions">
      <button mat-icon-button (click)="deleteSelectedContacts()" aria-label="delete selected">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

  </div>

</div>
