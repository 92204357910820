import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { TableColumn, TableConfiguration, SharePointService } from 'sp-office365-framework';
import { fuseAnimations, FuseTranslationLoaderService } from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-detail-position',
  templateUrl: './detail-position.component.html',
  styleUrls: ['./detail-position.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class DetailPositionComponent  implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
  public id;
  public item;
  public assessmenItem
  public sharePointItem;
  public formioConfiguration;
  public isCreateMode = true;
  public attachementColumns : TableColumn[]

  public tableConfigApplicants: TableConfiguration;
  public tableConfigApplications: TableConfiguration
  public tableConfigAttachements: TableConfiguration
  public applicationColumns: TableColumn[];


  public refreshForm: EventEmitter<any> = new EventEmitter();

    get camlQueryAllEntrys()  {
      return new CamlBuilder()
          .Where()
          .NumberField("ID")
          .NotEqualTo(-1)
          .OrderBy("Title")
          .ToString();
    }

    get camlQueryAttachements()  {
      return new CamlBuilder()
          .Where()
          .ChoiceField("Aktuelle_Bewerbung")
          .NotEqualTo("Ja")
          .OrderBy("Title")
          .ToString();
    }


  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DetailPositionComponent>,
      public dialog: MatDialog,
      private _sharepointService: SharePointService,
      private _applicationService: ApplicationService,
      private _orderService: OrderService,
      private _loadingService: LoadingService,
      private _snackbarService: SnackbarService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private _translateService: TranslateService
  ) {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit() {


    if (this.data) {
        this.id = this.data.Id
        this.isCreateMode = false;

        this._sharepointService
            .getItemById({
                id: this.data.Id,
                listTitle: 'Positionen',
            })
            .then((position) => {
                this.sharePointItem = position;
                this.initForm();
            })
            .catch((error) => console.error(error));
    } else {
        this.initForm();
    }
}

formioReady(ev) {
    let dataForm;
    const emptyObj: any = {};
    this._applicationService.getEmptyObject(
        this.formioConfiguration.components,
        emptyObj
    );
    if (this.sharePointItem) {
        dataForm = this._applicationService.leftMerge(
            emptyObj,
            this.sharePointItem || {}
          );
      }else {
        dataForm = emptyObj;
    }

    const promises = [];

    promises.push(
        this._applicationService.callBeforeLoadFunctions(
            this.formioConfiguration,
            dataForm
        )
    );




    Promise.all(promises)
        .then(() =>
            this.refreshForm.emit({
                submission: {
                    data: dataForm,
                },
            })
        )
        .catch((error) => {
            console.log(error);
        });
}

saveSubmission(e) {
    this._loadingService.show(
        this._translateService.instant('POSITION.SAVE'),
        this._translateService.instant('WAIT')
    );

    delete e.data.submit;
    //Has to be declared because the field "Antrag" is not in the form
    e.data.AntragId = this._orderService.currentOrderId

    this._applicationService.cleanDataForSaving(
        e.data,
        this.formioConfiguration.components
    );

    this._applicationService.callBeforeSaveFunctions(
        this.formioConfiguration,
        e.data
    );

    return from(
        new Promise<void>((resolve, reject) => {
            const promises = [];

          if(this.data){
            promises.push(
                this._sharepointService.updateItem({
                    id: this.id,
                    listTitle: 'Positionen',
                    newFiledValues: e.data,
                })
            );
          }else {
              promises.push(
                  this._sharepointService.addItem({
                      listTitle:"Positionen",
                      folder: '',
                      data: e.data,
                  })
              )
          }

          Promise.all(promises)
              .then((result) => {
                  this._loadingService.hide();

                  this._snackbarService.add(
                      this._translateService.instant('SUCCESS'),
                      '',
                      {
                          duration: 4000,
                          panelClass: 'success-dialog',
                      }
                  );

                  this.close({ Success: true, ItemId: this.id });
                  resolve();
              })
              .catch((error) => {
                  reject(error);
              });
        })
    );
}

close(message?): void {
    this.dialogRef.close(message);
}


initForm() {
    this.formioConfiguration = {
        useMaterialDesign: true,
        components: [
              {
                label: this._translateService.instant('FORM.TITLE'),
                type: 'textfield',
                input: true,
                key: "Title",
                selectThreshold: 0.3,
                isPeoplePicker: false,
                removable: true,
                multi: false,
                isCreateMode: this.isCreateMode,
                validate: {
                    customMessage: '{{field}} ist ein Pflichtfeld',
                    required: true,
                },
              },
              {
                  label: this._translateService.instant('FORM.SERVICE'),
                  type: 'textfield',
                  input: true,
                  key: "Leistung",
                  selectThreshold: 0.3,
                  isPeoplePicker: false,
                  removable: true,
                  multi: false,
                  isCreateMode: this.isCreateMode,
              },
              {
                label: this._translateService.instant('FORM.PAYMENT'),
                type: 'number',
                input: true,
                key: "Netto_Betrag",
                selectThreshold: 0.3,
                isPeoplePicker: false,
                removable: true,
                multi: false,
                isCreateMode: this.isCreateMode,
              },
              {
                label:this._translateService.instant('SAVE'),
                size: 'small',
                type: 'button',
                key: 'submit',
                action: 'submit',
              },
        ],
    };
  }
}


