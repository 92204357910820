import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogConfig,
    MatDialog,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { from } from 'rxjs';
import {
    SharePointTableComponent,
    TableConfiguration,
    SharePointService,
} from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { DetailTasksetComponent } from '../../taskset/detail-taskset/detail-taskset.component';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-detail-processsteps',
    templateUrl: './detail-processsteps.component.html',
    styleUrls: ['./detail-processsteps.component.scss'],
})
export class DetailProcessstepsComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public refreshForm: EventEmitter<any> = new EventEmitter();

    @Input() id: number;

    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    public tableConfigDocs: TableConfiguration;
    public formioConfiguration;
    public sharePointItem;

    get camlQueryTasksTemplates() {
        return new CamlBuilder()
            .Where()
            .LookupField('Kategorie')
            .Id()
            .EqualTo(this.id)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public matDialogRef: MatDialogRef<DetailTasksetComponent>,
        public _documentService: DocumentsAssignmentService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _applicationService: ApplicationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data) {
            this.id = this.data.Id;
        }

        if (this.id) {
            this._sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Prozessschritte',
                })
                .then((processstep) => {
                    this.sharePointItem = processstep;
                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    defaultValueToNo(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._sharepointService
                .getListItems({
                    title: 'Prozessschritte',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .ChoiceField('Standardwert')
                        .EqualTo('Ja')
                        .ToString(),
                })
                .then((result) => {
                    if (result.length) {
                        //Max. one other elements attribute "Standardwert" can be "Ja"
                        this._sharepointService
                            .updateItem({
                                listTitle: 'Prozessschritte',
                                id: result[0].Id,
                                newFiledValues: {
                                    Standardwert: 'Nein',
                                },
                            })
                            .then(() => {
                                resolve();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        resolve();
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    showErrorMessage() {
        this._snackBarService.add(
            this._translateService.instant('PROCESSSTEPS.NOTIFICATIONS.ERROR'),
            '',
            {
                duration: 4000,
                panelClass: 'error-dialog',
            }
        );
    }

    showSuccessMessage(action) {
        this._snackBarService.add(
            this._translateService.instant(
                'PROCESSSTEPS.NOTIFICATIONS.SUCCESS.' + action
            ),
            '',
            {
                duration: 4000,
                panelClass: 'success-dialog',
            }
        );
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('PROCESSSTEPS.NOTIFICATIONS.SAVING'),
            this._translateService.instant('PROCESSSTEPS.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;
        const promise = [];
        if (e.data.Standardwert == 'Ja') {
            promise.push(this.defaultValueToNo());
        }

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            return from(
                Promise.all(promise)
                    .then(() => {
                        this._sharepointService
                            .updateItem({
                                listTitle: 'Prozessschritte',
                                id: this.id,
                                newFiledValues: e.data,
                            })
                            .then(() => {
                                this._loadingService.hide();
                                this.showSuccessMessage('EDIT');
                                this.close('success');
                            })
                            .catch((err) => {
                                this.showErrorMessage();
                                console.error(err);
                            });
                    })
                    .catch((err) => {
                        this.showErrorMessage();
                        console.error(err);
                    })
            );
        } else {
            return from(
                Promise.all(promise)
                    .then(() => {
                        this._sharepointService
                            .addItem({
                                listTitle: 'Prozessschritte',
                                data: e.data,
                                folder: '',
                            })
                            .then(() => {
                                this._loadingService.hide();
                                this.showSuccessMessage('NEW');
                                this.close('success');
                            })
                            .catch((err) => {
                                console.error(err);
                                this.showErrorMessage();
                            });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.showErrorMessage();
                    })
            );
        }
    }

    close(message?): void {
        this.matDialogRef.close(message);
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises).then(
            () =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                }),
            (error) => {
                console.error(error);
            }
        );
    }

    onChange(ev) {}

    onTabChange(ec) {}

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant(
                        'PROCESSSTEPS.COLUMNS.TITLE'
                    ),
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'PROCESSSTEPS.COLUMNS.STEP'
                    ),
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Schritt',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    delimiter: false,
                    requireDecimal: false,
                },
                {
                    label: this._translateService.instant(
                        'PROCESSSTEPS.COLUMNS.DEFAULTVALUE.TITLE'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Standardwert',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.DEFAULTVALUE.YES'
                                ),
                                value: 'Ja',
                            },
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.DEFAULTVALUE.NO'
                                ),
                                value: 'Nein',
                            },
                        ],
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                },
                {
                    label: this._translateService.instant(
                        'PROCESSSTEPS.COLUMNS.STATUS'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Aufgabenstatus',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.NOTSTARTED'
                                ),
                                value: 'Nicht begonnen',
                            },
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.INPROCESSING'
                                ),
                                value: 'In Bearbeitung',
                            },
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.COMPLETED'
                                ),
                                value: 'Abgeschlossen',
                            },
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.RESET'
                                ),
                                value: 'Zurückgestellt',
                            },
                            {
                                label: this._translateService.instant(
                                    'PROCESSSTEPS.COLUMNS.WAITING'
                                ),
                                value: 'Wartet auf jemand anderen',
                            },
                        ],
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                },
                {
                    label: this._translateService.instant('PROCESSSTEPS.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
