import { OrderService } from './../../services/order.service';
import { DetailPositionComponent } from './../detail-position/detail-position.component';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnTyp, SharePointTableComponent, TableColumn, TableConfiguration, } from 'sp-office365-framework';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import CamlBuilder from 'camljs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, } from '@angular/material/dialog';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-table-position',
    templateUrl: './table-position.component.html',
    styleUrls: ['./table-position.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TablePositionComponent implements OnInit {
    public tableConfig: TableConfiguration;
    public columns: TableColumn[];
    public buttons = [];
    public position;

    @ViewChild('tableGrid', { static: true })
    tableGrid: SharePointTableComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailPositionComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _orderService: OrderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        this.initGrid();
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.openDialog();
                break;

            case 'Edit':
                this.openDialog(e.dataItem);
                break;
        }
    }

    openDialog(position?): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        if (position) {
            dialogConfig.data = {
                Title: position.Title,
                Netto_Betrag: position.Netto_Betrag,
                Id: position.ID,
            };
        }

        const dialogRef = this.dialog.open(
            DetailPositionComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            this.tableGrid.refresh();
        });
    }

    initGrid() {
        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('TABLE.TITLE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Leistung',
                title: this._translateService.instant('TABLE.SERVICE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Netto_Betrag',
                title: this._translateService.instant('TABLE.PAYMENT'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Positionen',
            columns: this.columns,
            isDocumentLibrary: false,
            showEditButton: true,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: new CamlBuilder()
                .Where()
                .LookupField('Antrag')
                .Id()
                .EqualTo(this._orderService.currentOrderId)
                .ToString(),
            folderName: '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('NEW'),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            afterRefreshTable: () => {
                //
            },
        };
    }
}
