<listview #listView *ngIf="config" [config]="config" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <ng-template customCell for="Monat" let-dataItem="dataItem">

        <div *ngIf="dataItem.Monat == 1">{{ "MONTH.JANUARY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 2">{{ "MONTH.FEBRUARY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 3">{{ "MONTH.MARCH" | translate }}</div>
        <div *ngIf="dataItem.Monat == 4">{{ "MONTH.APRIL" | translate }}</div>
        <div *ngIf="dataItem.Monat == 5">{{ "MONTH.MAY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 6">{{ "MONTH.JUNE" | translate }}</div>
        <div *ngIf="dataItem.Monat == 7">{{ "MONTH.JULY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 8">{{ "MONTH.AUGUST" | translate }}</div>
        <div *ngIf="dataItem.Monat == 9">{{ "MONTH.SEPTEMBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 10">{{ "MONTH.OCTOBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 11">{{ "MONTH.NOVEMBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 12">{{ "MONTH.DECEMBER" | translate }}</div>

    </ng-template>

    <ng-template
        customCell
        for="Soll_Wert"
        let-dataItem="dataItem">
        {{ dataItem.Soll_Wert.toString().replace(".",',') }}
    </ng-template>

</listview>
