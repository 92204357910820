export const locale = {
    lang: 'de',
    data: {
        DOCUMENTTEMPLATE: {
            TITLE: 'Dokumentenvorlagen',
            CREATE: 'Dokumente erstellen',
            NOTIFICATIONS: {
                CREATE: 'Dokumente werden erstellt...',
                WAIT: 'Bitte warten.',
            },
            COLUMNS: {
                TITLE: 'Name',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
            },
        },
    },
};
