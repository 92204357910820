import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1067Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const contactList: IList = this._sharePointService.web.lists.getByTitle(
                'Kontakte'
            );

            const involvedList: IList = this._sharePointService.web.lists.getByTitle(
                'Auftragsbeteiligte'
            );

            const mailboxList = this._sharePointService.web.lists.getByTitle(
                'Mailbox'
            );

            const promises = [
                // Add Field Kontakt to List Auftragsbeteiligte
                await this._updateService.addLookupField(
                    involvedList,
                    contactList,
                    'Kontakt'
                ),
                // Add Field Send to List Mailbox
                await this._updateService.addField(
                    mailboxList,
                    'Send',
                    FieldType.Boolean
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
