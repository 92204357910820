<div *ngIf="this.ready" class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

  <!-- WIDGET -->
  <fuse-widget *ngFor="let widget of this._configuration; let i = index" [@animate]="{value:'*',params:{y:'100%'}}"
    class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

    <!-- Front -->
    <div class="fuse-widget-front">
      <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="h3">{{ widget.Label }}</div>

        <button *ngIf="widget.Advanced" mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
          aria-label="more">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">

        <div *ngIf="!widget.Warning" class="light-blue-fg font-size-72 line-height-72">
          {{ widget.Count }}
        </div>

        <div *ngIf="widget.Warning" class="red-fg font-size-72 line-height-72">
          {{ widget.Count }}
        </div>

        <div class="h3 secondary-text font-weight-500">{{ widget.Title }}
        </div>
      </div>

      <!-- <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center">
        <span class="h4 secondary-text text-truncate">
          {{ widget.Subtitle }}
        </span>
        <span class="h4 ml-8"> {{ widget.Subcount }}</span>
      </div> -->

    </div>
    <!-- / Front -->

    <!-- Back -->
    <div class="fuse-widget-back p-16 pt-32">
      <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button" aria-label="Flip widget">
        <mat-icon class="s-16">close</mat-icon>
      </button>

      <div>
        {{ widget.Description }}
      </div>
    </div>
    <!-- / Back -->

  </fuse-widget>
  <!-- / WIDGET -->

</div>
<!-- / WIDGET GROUP -->
