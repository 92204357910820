import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HolidayDetailsComponent } from 'src/app/main/holidays/holiday-details/holiday-details.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormioModule } from '@formio/angular';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [HolidayDetailsComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        MatSnackBarModule,
        MatToolbarModule,
        FormioModule,
        FormioModule,
        MatIconModule
    ],
    exports: [HolidayDetailsComponent]
})
export class HolidayDetailsModule {
}
