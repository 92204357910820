export const locale = {
    lang: 'de',
    data: {
        INDEX_EMAIL: {
            FORM: {
                WORKPACKAGE: 'Arbeitspaket',
            },
            ADDATTACHMENT: 'Datei anhängen',
            NOTIFICATIONS: {
                LOADING: 'E-Mails werden geladen...',
                SAVING: 'E-Mails werden zugeordnet...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'E-Mails erfolgreich zugeordnet.',
                ERROR: 'Fehler beim zuordnen: ',
            },
            COLUMNS: {
                TITLE: 'Titel',
                SUBJECT: 'Betreff',
                TEXT: 'Nachricht',
                TO: 'An',
                FROM: 'Von',
                ATTACHMENTS: 'Anhänge',
            },
            SAVE: 'E-Mails zuordnen',
            FOLDER: 'Ordner auswählen',
            TITLE: 'E-Mails zuordnen',
        },
    },
};
