export const locale = {
    lang: 'en',
    data: {
        DURATION_TIME: {
            HOURS: 'Stunden',
            MINUTES: 'Minuten',
            HOURSERROR: 'Only numbers between 0 and 23',
            MINUTESERROR: 'Only numbers of 5-series and lower 60',
        },
    },
};
