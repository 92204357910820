import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1130Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            await this._updateService.addList('Feiertage', '', 100, {
                EnableVersioning: true,
            });
            const list: IList =
                this._sharePointService.web.lists.getByTitle('Feiertage');
            const standorteList: IList =
                this._sharePointService.web.lists.getByTitle('Standorte');
            await this._updateService.addField(list, 'Title', FieldType.Text);
            await this._updateService.addField(list, 'Date', FieldType.Date);
            await this._updateService.addLookupField(
                list,
                standorteList,
                'Standort',
                false
            );
            await this._updateService.addField(
                list,
                'Bundesland',
                FieldType.Text
            );
            await this._updateService.addField(list, 'Year', FieldType.Number);
            await this._updateService.addField(list, 'Month', FieldType.Number);
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
