export const locale = {
    lang: 'de',
    data: {
        CONFIGURATION: {
            ADMIN: 'Administration',
            TITLE: 'Konfiguration',
            CATEGORIES: {
                SEARCH_LABEL: 'Nach einer Kategorie suchen',
                TITLE: 'Kategorien',
                NEW: 'Neue Kategorie',
                EDIT: 'Kategorie bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            SIGNATURES: {
                SEARCH_LABEL: 'Nach einer Signatur suchen',
                TITLE: 'Signaturen',
                NEW: 'Neue Signatur',
                EDIT: 'Signatur bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    TEXT: 'Signatur',
                },
            },
            SALUTATIONS: {
                SEARCH_LABEL: 'Nach einer Anrede suchen',
                TITLE: 'Anreden',
                NEW: 'Neue Anrede',
                EDIT: 'Anrede bearbeiten',
                COLUMNS: {
                    TITLE: 'Anrede',
                },
            },
            COSTROLES: {
                SEARCH_LABEL: 'Nach einer Kostenrolle suchen',
                TITLE: 'Kostenrollen',
                NEW: 'Neue Kostenrolle',
                EDIT: 'Kostenrolle bearbeiten',
                COLUMNS: {
                    TITLE: 'Kostenrolle',
                    COSTSPERHOUR: 'Stundensatz',
                    INEURO: '(in Euro)',
                    ABBREVIATION: 'Abkürzung',
                },
            },
            EMPLOYEES: {
                SEARCH_LABEL: 'Nach einem Mitarbeiter suchen',
                TITLE: 'Mitarbeiter',
                NEW: 'Neuer Mitarbeiter',
                EDIT: 'Mitarbeiter bearbeiten',
                COLUMNS: {
                    USER: 'User',
                    HOURSPERDAY: 'Tagessoll',
                    INHOURS: '(in Stunden)',
                    COSTROLE: 'Kostenrolle',
                },
            },
            PERMISSIONS: {
                TITLE: 'Berechtigungsgruppen',
                COLUMNS: {
                    TITLE: 'Rolle',
                    VALUE: 'Berechtigungsgruppe',
                },
            },
            PROCESSSTEPS: {
                SEARCH_LABEL: 'Nach einem Prozessschritt suchen',
                TITLE: 'Prozessschritte',
                NEW: 'Neuer Prozessschritt',
                EDIT: 'Prozessschritt bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    STEP: 'Schritt',
                    STATUS: 'Wechseln in Aufgabenstatus',
                    NOTSTARTED: 'Nicht begonnen',
                    INPROCESSING: 'In Bearbeitung',
                    DEFAULTVALUE: {
                        TITLE: 'Standardwert',
                        YES: 'Ja',
                        NO: 'Nein',
                    },
                    COMPLETED: 'Abgeschlossen',
                    RESET: 'Zurückgestellt',
                    WAITING: 'Wartet auf jemand anderen',
                },
            },
            TASKTYPES: {
                SEARCH_LABEL: 'Nach einem Aufgabentypen suchen',
                TITLE: 'Aufgabentypen',
                NEW: 'Neuer Aufgabentyp',
                EDIT: 'Aufgabentyp bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    COLOR: 'Farbe',
                },
            },
            TASKTEMPLATES: {
                SEARCH_LABEL: 'Nach einer Aufgabenvorlage suchen',
                TITLE: 'Aufgabenvorlagen',
                NEW: 'Neue Aufgabenvorlage',
                EDIT: 'Aufgabenvorlage bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    DESCRIPTION: 'Beschreibung',
                    OFFSETSTART: 'Addition zum Anfangsdatum',
                    INDAYS: '(in Tagen)',
                    OFFSETEND: 'Addition zum Fälligkeitsdatum',
                    PRIORITY: 'Priorität',
                    HIGH: '(1) Hoch',
                    NORMAL: '(2) Normal',
                    LOW: '(3) Niedrig',
                    PROCESSSTEP: 'Prozessschritt',
                    TYPE: 'Aufgabentyp',
                    SCHEDULEDTIME: 'Geschätzte Arbeitszeit',
                    INHOURS: '(in Tagen)',
                    OFFSETSTARTTYPE: 'Typ der Addition zum Anfangsdatum',
                    OFFSETENDTYPE: 'Typ der Addition zum Fälligkeitsdatum',
                    TODAY: 'Ab Heute',
                    START: 'Ab Beginn Arbeitspaket',
                    END: 'Ab Ende Arbeitspaket',
                    STARTASSIGNMENT: 'Ab Beginn Projekt',
                    ENDASSIGNMENT: 'Ab Ende Projekt',
                },
            },
            NOTIFICATIONS: {
                SEARCH_LABEL: 'Nach einer Benachrichtigung suchen',
                TITLE: 'Benachrichtigungen',
                NEW: 'Neue Benachrichtigung',
                EDIT: 'Benachrichtigung bearbeiten',
                COLUMNS: {
                    TITLE: 'Titel',
                    LIST: 'Liste',
                    INTERVALL: 'Intervall',
                },
                WAIT: 'Bitte warten',
                SUCCESS: 'Das Ereignis wurde erfolgreich gespeichert',
                ERROR: 'Importfehler',
            },
            TEAMS: {
                SEARCH_LABEL: 'Nach einem Team suchen',
                TITLE: 'Teams',
                NEW: 'Neues Team',
                EDIT: 'Team bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    GROUP: 'Gruppe',
                    LOCATION: 'Standort',
                    TEAMMANAGER: 'Teamleiter',
                    AVAILABLEFORCALCULATION: 'Relevant für Auswertung'
                },
            },
            FACHBEREICHE: {
                SEARCH_LABEL: 'Nach einem Fachbereich suchen',
                TITLE: 'Fachbereiche',
                NEW: 'Neuer Fachbereiche',
                EDIT: 'Fachbereich bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    NUMBER: 'Bereichsnummer',
                },
            },
            LOCATIONS: {
                SEARCH_LABEL: 'Nach einem Standort suchen',
                TITLE: 'Standorte',
                NEW: 'Neuer Standort',
                EDIT: 'Standort bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                    FILESERVER: 'Fileserver',
                },
            },
            LANDS: {
                SEARCH_LABEL: 'Nach einem Land suchen',
                TITLE: 'Länder',
                NEW: 'Neuer Land',
                EDIT: 'Land bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            COSTCENTRES: {
                SEARCH_LABEL: 'Nach einer Kostenstelle suchen',
                TITLE: 'Kostenstellen',
                NEW: 'Neue Kostenstelle',
                EDIT: 'Kostenstelle bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            NETWORKDRIVES: {
                SEARCH_LABEL: 'Nach einem Netzlaufwerkunterordner suchen',
                TITLE: 'Netzlaufwerkunterordner',
                NEW: 'Neuer Netzlaufwerkunterordner',
                EDIT: 'Netzlaufwerkunterordner bearbeiten',
                COLUMNS: {
                    TITLE: 'Ordnername',
                },
            },
            PROPERTIES: {
                SEARCH_LABEL: 'Nach einer Eigenschaft suchen',
                TITLE: 'Eigenschaften',
                NEW: 'Neue Eigenschaft',
                EDIT: 'Eigenschaft bearbeiten',
                COLUMNS: {
                    TITLE: 'Bezeichnung',
                    VALUE: 'Wert',
                },
            },
            TASKSET: {
                SEARCH_LABEL: 'Nach einem Aufgabenvorlagen suchen',
                TITLE: 'Aufgabenvorlagen',
                NEW: 'Neues Aufgabenvorlagen',
                EDIT: 'Aufgabenvorlagen bearbeiten',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            HOLIDAYS: {
                TITLE: 'Feiertage',
                IMPORT_ICS: 'Import ICS File',
                COLUMNS: {
                    TITLE: 'Name',
                    DATE: 'Datum',
                    LOCATION: 'Standorte',
                    BUNDESLAND: 'Bundesland',
                    FAKTOR: 'Faktor',
                },
                SUCCESS: 'Der Feiertag wurde erfolgreich gestaltet',
            },
            WORK_PACKAGE_TEMPLATES: {
                TITLE: 'Arbeitspaketeset',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            WORK_PACKAGES: {
                TITLE: 'Aufgabensets',
                COLUMNS: {
                    TITLE: 'Name',
                },
            },
            SAVE: 'Speichern',
        },
    },
};
