import { ApplicationService } from 'src/app/main/services/application.service';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InjectHeaders } from '@pnp/queryable';
import * as CamlBuilder from 'camljs';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    public currentOrderId = -1;
    public sideBarConfigOrder = [];
    private dependantOrderLists = [
        { listTitle: 'Mailbox-Anträge', isDocumentLibrary: false },
        { listTitle: 'Antragsdokumente', isDocumentLibrary: true },
    ];

    constructor(
        private _translateService: TranslateService,
        private _sharePointService: SharePointService,
        private _applicationService: ApplicationService,
        private _updateService: UpdateService
    ) {}

    getSideBarConfig(id: number): Promise<any> {
        // Reset if id of project changed
        if (this.currentOrderId != id) {
            this.sideBarConfigOrder = [];
            this.currentOrderId = id;
        }

        return new Promise(async (resolve) => {
            const promises = [];
            const config = [];

            if (!id) {
                config.push({
                    Text: this._translateService.instant('ORDER_SERVICE.BASE'),
                    Link: '/new-order',
                    Icon: 'star',
                });
            } else {
                config.push(
                    {
                        Text: this._translateService.instant(
                            'ORDER_SERVICE.BASE'
                        ),
                        Link: '/orders/detail/basedata/' + id,
                        Icon: 'star',
                    },
                    {
                        Text: this._translateService.instant(
                            'ORDER_SERVICE.DOCS'
                        ),
                        Link: '/orders/detail/documents/' + id,
                        Icon: 'folder',
                    },
                    {
                        Text: this._translateService.instant(
                            'ORDER_SERVICE.MAILS'
                        ),
                        Link: '/orders/detail/mails/' + id,
                        Icon: 'mailbox',
                    },
                    {
                        Text: this._translateService.instant(
                            'ORDER_SERVICE.COSTROLES'
                        ),
                        Link: '/orders/detail/costRoles/' + id,
                        Icon: 'attach_money',
                    }
                );

                // Get number of documents
                this.getNumberOfItemsWithoutFolders('Antragsdokumente', id)
                    .then((length) => {
                        const index = config.findIndex(
                            (item) =>
                                item.Text ==
                                this._translateService.instant(
                                    'ORDER_SERVICE.DOCS'
                                )
                        );
                        config[index].Text =
                            this._translateService.instant(
                                'ORDER_SERVICE.DOCS'
                            ) +
                            ' (' +
                            length +
                            ')';
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            Promise.all(promises)
                .then(() => {
                    this.sideBarConfigOrder = config;
                    resolve(config);
                })
                .catch((error) => {
                    this.sideBarConfigOrder = config;
                    console.error(error);
                    resolve(config);
                });
        });
    }

    getNumberOfItemsWithoutFolders(
        listName: string,
        orderId: number,
        camlQuery?: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!camlQuery) {
                camlQuery = new CamlBuilder()
                    .Where()
                    .NumberField('AntragId')
                    .EqualTo(orderId)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderBy('Modified')
                    .ToString();
            }

            // Get number of items
            this._sharePointService
                .getListItems({
                    title: listName,
                    isDocumentLibrary: true,
                    recursiveAll: true,
                    folderName: orderId + '',
                    camlQuery,
                })
                .then((items) => {
                    resolve(items.length);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    createDependantFoldersForOrder(orderItemId: number) {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            for (const dependantList of this.dependantOrderLists) {
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._updateService
                            .listExists(dependantList.listTitle)
                            .then((exists) => {
                                if (exists) {
                                    this._sharePointService
                                        .addFolder({
                                            listTitle: dependantList.listTitle,
                                            folderName: orderItemId + '',
                                            isDocumentLibrary:
                                                dependantList.isDocumentLibrary,
                                        })
                                        .then(() => resolve())
                                        .catch((error) => {
                                            reject(error);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                    })
                );
            }

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateOrder(data: any, itemId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Remove not existing fields
            delete data.submit;
            delete data.NebenkostenEuro;
            delete data.Accept;
            delete data.Delete;

            this._sharePointService
                .updateItem({
                    id: itemId,
                    listTitle: 'Anträge',
                    newFiledValues: data,
                })
                .then(() => {
                    this._sharePointService
                        .getItemById({
                            listTitle: 'Anträge',
                            id: itemId,
                        })
                        .then((order) => {
                            const promises = [];

                            Promise.all(promises)
                                .then(() => {
                                    // Success
                                    resolve(order);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    copyDocTemplatesInProject(projectId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const query = new CamlBuilder()
                .Where()
                .TextField('FileLeafRef')
                .EqualTo('Auftragsbestätigung.docx')
                .ToString();
            const listTitle = 'Dokumentenvorlagen';
            const folderName = '';

            // Get DocTemplate
            this._sharePointService
                .getListItems({
                    title: listTitle,
                    isDocumentLibrary: true,
                    camlQuery: query,
                    folderName,
                })
                .then((templates) => {
                    if (templates.length > 0) {
                        templates.forEach((element) => {
                            // Copy DocTemplate
                            const targetPath =
                                'Auftragsdokumente/' +
                                projectId +
                                '/' +
                                element.FieldValuesAsText.FileLeafRef;

                            this._sharePointService.web
                                .using(
                                    InjectHeaders({
                                        Accept: 'application/json;odata=fullmetadata',
                                        'Content-Type':
                                            'application/json;charset=utf-8',
                                    })
                                )
                                .getFileByServerRelativePath(element.FileRef)
                                .copyTo(targetPath, true)
                                .then(() => {
                                    this._applicationService
                                        .loadItemFromFolder(
                                            this._sharePointService.computeServerRelativeUrl(
                                                this._sharePointService
                                                    .sphostUrl
                                            ) +
                                                '/' +
                                                targetPath
                                        )
                                        .then((data) => {
                                            // Set ProjectId
                                            this._sharePointService
                                                .updateItem({
                                                    listTitle:
                                                        'Auftragsdokumente',
                                                    id: data.Id,
                                                    newFiledValues: {
                                                        AuftragId: projectId,
                                                    },
                                                })
                                                .then(() => {
                                                    // Success
                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                    } else {
                        // Success without DocTemplate
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async copyEMailsInProject(
        projectId: number,
        antragId: number
    ): Promise<any> {
        try {
            this._sharePointService
                .getListItems({
                    title: 'Mailbox-Anträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Antrag')
                        .Id()
                        .EqualTo(antragId)
                        .ToString(),
                    folderName: antragId + '',
                })
                .then((mails) => {
                    if (mails.length > 0) {
                        mails.forEach((mail) => {
                            // Copy mail
                            const data: any = {
                                Title: mail.Title,
                                Subject: mail.Subject,
                                Body: mail.Body,
                                From: mail.From,
                                To: mail.To,
                                CC: mail.CC,
                                BCC: mail.BCC,
                                Headers: mail.Headers,
                                ProjektId: projectId,
                                Send: mail.Send,
                                ReadyToSend: mail.ReadyToSend,
                                SentDateTime: mail.SentDateTime || mail.Created,
                                Attachment_Urls: mail.Attachment_Urls,
                            };

                            this._sharePointService
                                .addItem({
                                    listTitle: 'Mailbox',
                                    folder: projectId + '',
                                    data,
                                })
                                .then(() => {
                                    return;
                                })
                                .catch((error) => {
                                    throw new Error(error);
                                });
                        });
                    }

                    return;
                })
                .catch((error) => {
                    throw new Error(error);
                });

            return;
        } catch (error) {
            throw new Error(error);
        }
    }

    async copyDocumentsInProject(
        projectId: number,
        orderId: number
    ): Promise<any> {
        try {
            // get all neccessary documents from 'Antragsdokumente'
            const orderDocsForCopy = await this._sharePointService.getListItems(
                {
                    title: 'Antragsdokumente',
                    isDocumentLibrary: true,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('AntragId')
                        .EqualTo(orderId)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .ToString(),
                    folderName: orderId.toString(),
                    recursiveAll: true,
                }
            );

            // copy each document and update the properties
            for (const doc of orderDocsForCopy) {
                const srcFolderServerRelativeURL = `${this._sharePointService.computeServerRelativeUrl(
                    this._sharePointService.sphostUrl
                )}/Antragsdokumente/${orderId}`;
                const subFolders: string[] =
                    doc.FieldValuesAsText.FileDirRef.replaceAll(
                        srcFolderServerRelativeURL,
                        ''
                    ).split('/');

                const [rootFolder, ...remainingSubFolders] = [...subFolders];
                if (remainingSubFolders?.length) {
                    let checkFolderPath = `${this._sharePointService.computeServerRelativeUrl(
                        this._sharePointService.sphostUrl
                    )}/Auftragsdokumente/${projectId}`;
                    for (const subFolder of remainingSubFolders) {
                        checkFolderPath = `${checkFolderPath}/${subFolder}`;
                        await this.ensureFolder(checkFolderPath);
                    }
                }

                const targetPath = `Auftragsdokumente/${projectId}${subFolders.join(
                    '/'
                )}/${doc.FieldValuesAsText.FileLeafRef}`;

                // #1 copy
                await this._sharePointService.web
                    .using(
                        InjectHeaders({
                            Accept: 'application/json;odata=fullmetadata',
                            'Content-Type': 'application/json;charset=utf-8',
                        })
                    )
                    .getFileByServerRelativePath(doc.FileRef)
                    .copyTo(targetPath, true);

                // #2 load
                const data = await this.loadItemFromFile(
                    this.computeServerRelativeUrl(
                        this._sharePointService.sphostUrl
                    ) +
                        '/' +
                        targetPath
                );

                // #3 update
                await this._sharePointService.updateItem({
                    listTitle: 'Auftragsdokumente',
                    id: data.item.Id,
                    newFiledValues: {
                        AuftragId: projectId,
                    },
                });
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    private loadItemFromFile(fileServerRelativeUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const file =
                this._sharePointService.web.getFileByServerRelativePath(
                    fileServerRelativeUrl
                );

            file.listItemAllFields()
                .then((item) => {
                    resolve({ item });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private computeServerRelativeUrl(spHostUrl?: string): string {
        spHostUrl = spHostUrl
            ? spHostUrl
            : decodeURIComponent(this._sharePointService.sphostUrl);
        const arr: string[] = spHostUrl.split('/');
        const tmp: string = arr[0] + '//' + arr[2];

        const result: string = spHostUrl.replace(tmp, '');

        return result;
    }

    /**
     * Important: We must only ensure and create folders in the correct sequential order!!!
     * @param serverRelativeUrl e.g. '/sites/MyTeamSite/MySubSite/MyDocumentLibrary/myFolderA/myFolderB'
     */
    private async ensureFolder(serverRelativeUrl: string): Promise<any> {
        const folder = await this._sharePointService.web
            .getFolderByServerRelativePath(serverRelativeUrl)
            .select('Exists')();
        if (!folder.Exists) {
            await this._sharePointService.web.folders.addUsingPath(
                serverRelativeUrl
            );
        }
    }
}
