import { Subject } from 'rxjs';
import { ReportService } from './../../services/report.service';
import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnTyp, SharePointService, TableConfiguration, FormatTypes, TableColumn } from 'sp-office365-framework';
import { fuseAnimations, FuseTranslationLoaderService, ListViewConfig, } from 'sp-office365-layout';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialog } from '@angular/material/dialog';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import moment from 'moment';
import { CurrentUserService } from 'src/app/main/services/current-user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-listview-report',
    templateUrl: './listview-report.component.html',
    styleUrls: ['./listview-report.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ListviewReportComponent implements AfterViewInit, OnDestroy {
    public config: ListViewConfig;
    public tableConfig: TableConfiguration;
    public isManager = false;
    public tableColumns: TableColumn[] = [];
    private project: { Id: number; Projektnummer: string }[] = [];
    private destroy$ = new Subject();

    @ViewChild('listView', { static: false }) listView: ListViewComponent;
    @ViewChild('kendoExcelExport', { static: false }) kendoExcelExport: ExcelExportComponent;

    constructor(
        public dialog: MatDialog,
        public _reportService: ReportService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        // reset reportService
        this._reportService.reset();

        // Check if currentUser is Manager
        if (this._sharepointService.currentUser.IsBusinessOwner || this.currentUserService.isTeamProjectLeiter()) {
            this.isManager = true;
        }

        this.initConfig();
    }

    ngOnInit(): void {
        this.currentUserService.loadCurrentUserTeams().subscribe();
        this.currentUserService.isTeamLeiter$
            .pipe(takeUntil(this.destroy$))
            .subscribe(isTeamLeiter => {
                const column = this.tableColumns.find(column => column.internalName === 'Kosten');
                column.hidden = !this.isManager && !isTeamLeiter;
            });
    }

    ngAfterViewInit(): void {
        // Set listview
        this._reportService.currentListView = this.listView;
        this._reportService.changeQuery();
        /*
        this._reportService.currentListView.changeQuery(
            this._reportService.filteredTimesCamlQuery
        );*/
    }

    initConfig() {
        this.initTableColumn();

        this.tableConfig = {
            list: 'Zeiten',
            groups: [
                {
                    field: 'OriginalDate',
                    dir: 'desc',
                    aggregates: [
                        {
                            field: 'Minuten',
                            aggregate: 'sum',
                            preText: this._translateService.instant(
                                'LV_REPORT.COLUMNS.SUM'
                            ),
                            //multiplikator: 1 / 60,
                        },
                    ],
                },
            ],
            columns: this.tableColumns,
            showEditButton: false,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            useMaterialDesign: true,
            pageSize: 20,
            toolbar: [
                { title: 'Excel-Export', command: 'exportExcel' }
            ],
            isDocumentLibrary: false,
            data: [],
        };

        this.config = {
            title: this._translateService.instant('LV_REPORT.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_REPORT.SEARCH_LABEL'
            ),
            icon: 'description',
            add_icon: 'add',
            openEditInDialog: false,
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_REPORT.ALLTIMES'),
                    config: this.tableConfig,
                    camlQueryForSearch:
                    this._reportService.allCustomersSearchCamlQuery,
                },
            ],
        };
    }

    public toolbarButtonClick(event: { e: any; command: string; selectedItems: any[]; dataItem: any; latestParentItem?: any; }): void {
        if (event.command === 'exportExcel') {
            const dataToExport = [...this._reportService.currentListView.listViewConfig.tableConfigs[0].config.data];
            dataToExport.sort((item1, item2) => item2.OriginalDate?.getTime() - item1.OriginalDate?.getTime());
            this.kendoExcelExport.save(dataToExport);
        }
    }

    private initTableColumn(): void {
        this.tableColumns = [
            {
                internalName: 'Projekt',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.PROJECT'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Datum',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.DATE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
              internalName: 'OriginalDate',
              title: this._translateService.instant(
                  'LV_REPORT.COLUMNS.DATE'
              ),
              hidden: true,
              type: ColumnTyp.Date,
              format: FormatTypes.yyyyMMdd
          },
            {
                internalName: 'Projektnummer',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.PROJECTNUMBER'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Aufgabe',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.TASK'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Minuten',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.HOURS'
                ),
                hidden: false,
            },
            {
                internalName: 'Kosten',
                title: this._translateService.instant(
                    'TIME_SERVICE.COLUMNS.COSTS'
                ),
                hidden: !this.isManager,
            },
            {
                internalName: 'Kostenrolle',
                title: 'Kostenrolle',
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Beschreibung',
                title: this._translateService.instant(
                    'LV_REPORT.COLUMNS.DESCRIPTION'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }
}
