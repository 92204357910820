import { SharepointEntrysService } from './../../services/sharepoint-entrys.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    TableConfiguration,
    ColumnTyp,
    SharePointTableComponent,
    TableColumn,
    SharePointService,
} from 'sp-office365-framework';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import CamlBuilder from 'camljs';
import { ApplicationService } from '../../services/application.service';

@Component({
    selector: 'app-table-controlling',
    templateUrl: './table-controlling.component.html',
    styleUrls: ['./table-controlling.component.scss'],
})
export class TableControllingComponent implements OnInit, AfterViewInit {
    public tableConfig: TableConfiguration;
    public columns: TableColumn[];
    public buttons = [];
    public columnValues = new Map();

    @ViewChild('tableGrid', { static: true })
    documentGrid: SharePointTableComponent;

    private folderName = '';

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _sharepointEntrysService: SharepointEntrysService,
        private _applicationService: ApplicationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    getBillsSumFromMap(targetAmountId): number {
        if (this.columnValues.get(targetAmountId)) {
            return this.columnValues.get(targetAmountId)['Rechnungssumme'];
        } else {
            return 0;
        }
    }

    getBillsDifferenceFromMap(targetAmountId): number {
        if (this.columnValues.get(targetAmountId)) {
            return this.columnValues.get(targetAmountId)['Differenz'];
        } else {
            return 0;
        }
    }

    ngOnInit() {
        let allBills;
        let allTargetAmounts;
        let promises = [];

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._sharepointService
                    .getListItems({
                        title: 'SollZahlen',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .NumberField('ID')
                            .NotEqualTo(-1)
                            .OrderByDesc('Modified')
                            .ToString(),
                    })
                    .then((result) => {
                        allTargetAmounts = result;
                        resolve(allTargetAmounts);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        );

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._applicationService
                    .getAllItems('Rechnungen')
                    .then((result) => {
                        allBills = result;
                        resolve(allBills);
                    })
                    .catch((err) => {
                        reject(new Error(err));
                    });
            })
        );

        Promise.all(promises)
            .then((results) => {
                allTargetAmounts.forEach((targetAmount) => {
                    let bills = allBills.filter(({ Monat, Jahr, TeamId }) => {
                        return (
                            Monat == targetAmount['Monat'] &&
                            Jahr == targetAmount['Jahr'] &&
                            TeamId == targetAmount['TeamId']
                        );
                    });
                    if (bills.length > 0) {
                        let result = 0;
                        bills.forEach((bill) => {
                            result += bill['Forderung'];
                        });
                        let differenz = result - targetAmount['Soll_Wert'];
                        this.columnValues.set(targetAmount['ID'], {
                            Rechnungssumme: result,
                            Differenz: differenz,
                        });
                    } else {
                        let differenz = 0 - targetAmount['Soll_Wert'];
                        this.columnValues.set(targetAmount.ID, {
                            Rechnungssumme: 0,
                            Differenz: differenz,
                        });
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });

        this.initGrid();
    }

    ngAfterViewInit(): void {
        //Set listview
        this._sharepointEntrysService.currentTable = this.documentGrid;
        this._sharepointEntrysService.currentTable.changeQuery(
            this._sharepointEntrysService.filteredEntrysForTable
        );
    }

    initGrid() {
        this.columns = [
            {
                internalName: 'Soll_Wert',
                title: this._translateService.instant('TABLE.AMOUNT'),
                hidden: false,
                type: ColumnTyp.Text,
                width: '200',
            },

            {
                internalName: 'Jahr',
                title: this._translateService.instant('YEAR'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Monat',
                title: this._translateService.instant('MONTH_'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Team',
                title: this._translateService.instant('TABLE.TEAM'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            //This Column DOES NOT exists in SP/Backend
            {
                internalName: 'Rechnungssumme',
                title: this._translateService.instant('TABLE.SUM'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            //This Column DOES NOT exists in SP/Backend
            {
                internalName: 'Differenz',
                title: this._translateService.instant('TABLE.DIFFERENCE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Jahr',
                title: '',
                hidden: true,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'SollZahlen',
            columns: this.columns,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this._sharepointEntrysService.filteredEntrysForTable,
            folderName: '',
            useMaterialDesign: true,
            toolbar: this.buttons,
            afterRefreshTable: () => {
                //
            },
        };
    }
}
