import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AttachmentFile } from '../../../shared/models';

@Component({
    selector: 'app-bills-attachments-popup',
    templateUrl: './bills-attachments-popup.component.html',
    styleUrls: ['./bills-attachments-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BillsAttachmentsPopupComponent {
    @Input() attachments: AttachmentFile[];
}
