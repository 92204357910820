import { FilterBillsComponent } from './../filter-bills/filter-bills.component';
import { FilterBillsModule } from './../filter-bills/filter-bills.module';
import { NgModule } from '@angular/core';
import { ListviewBillsComponent } from './listview-bills.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { ImportBillsDialogModule } from '../import-bills-dialog/import-bills-dialog.module';
import { UploadBillsPdfAttachmentsDialogModule } from '../upload-bills-pdf-attachments-dialog/upload-bills-pdf-attachments-dialog.module';


const routes = [
    {
        path: 'bills',
        component: ListviewBillsComponent,
    },
];

@NgModule({
    declarations: [ListviewBillsComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        FilterBillsModule,
        ImportBillsDialogModule,
        UploadBillsPdfAttachmentsDialogModule
    ],
    exports: [ListviewBillsComponent]
})
export class ListviewBillsModule { }
