<h1 matDialogTitle>Import Bills</h1>
<div *ngIf="uploadStatus$ | async as uploadStatus" mat-dialog-content class="mat-dialog-body">
  <div class="toolbar">
    <div class="loading">Finished {{uploadStatus.done + uploadStatus.failed}} of {{uploadStatus.total}} bills</div>
    <div class="stats">
      <div class="success">Success: {{uploadStatus.done}}</div>
      <div class="error">Error: {{uploadStatus.failed}}</div>
    </div>
  </div>
  <app-sharepoint-table #importTable *ngIf="tableConfig" [configuration]="tableConfig" (onTooolbarButtonClick)="toolbarButtonHandler($event)">
    <ng-template customCell for="status" let-dataItem="dataItem">
      <div class="status-column">
        <ng-container *ngIf="finishedProjects[dataItem.docNumber];else loading">
          <span [class.bill-error]="finishedProjects[dataItem.docNumber].error"
                [class.bill-success]="!finishedProjects[dataItem.docNumber].error"
          >
            {{ finishedProjects[dataItem.docNumber].status | translate }}
          </span>
        </ng-container>
        <ng-template #loading>
          <mat-spinner diameter="20"></mat-spinner>
        </ng-template>
      </div>
    </ng-template>
  </app-sharepoint-table>
</div>
<div mat-dialog-actions class="mat-dialog-actions mt-8">
  <button mat-raised-button matDialogClose color="accent" [disabled]="!isFinished">
    {{ "IMPORT_BILLS_DIALOG.OK" | translate }}
  </button>
</div>
