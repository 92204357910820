import { NgModule } from '@angular/core';
import { HighlightComponent } from './highlight.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseWidgetModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

const routes = [
    {
        path: 'highlight',
        component: HighlightComponent,
    },
];

@NgModule({
    declarations: [HighlightComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        FuseWidgetModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [HighlightComponent],
})
export class HighlightModule {}
