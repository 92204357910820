<app-sharepoint-table #taskGrid
                      *ngIf="_configuration"
                      class="bills-table"
                      [configuration]="_configuration"
                      (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
>
    <ng-template customCell for="Forderung" let-dataItem="dataItem">
        <ng-container *ngIf="dataItem.Forderung">
            {{dataItem.Forderung | currency}}
        </ng-container>
    </ng-template>
    <ng-template customCell for="ForderungBrutto" let-dataItem="dataItem">
        <ng-container *ngIf="dataItem.Forderung">
            {{ ((dataItem.Forderung * 119) / 100) | currency}}
        </ng-container>
    </ng-template>
    <ng-template customCell for="PDFAttachments" let-dataItem="dataItem">
        <div class="attachment-wrapper" (click)="downloadAttachments(dataItem.Id, anchor)">
            <i class="material-icons pdf-attachment-icon"
               #anchor
               [class.has-attachments]="billingsService.attachments[dataItem.Id]?.length"
            >
                picture_as_pdf
            </i>
            <span *ngIf="billingsService.attachments[dataItem.Id]?.length" class="attachment-badge">
                {{billingsService.attachments[dataItem.Id]?.length}}
            </span>
        </div>
    </ng-template>
</app-sharepoint-table>
