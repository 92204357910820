export const locale = {
    lang: 'en',
    data: {
        FILTER_TIME: {
            COLUMNS: {
                LOCATION: 'Location',
                TEAM: 'Team',
                HOTBUTTONS: 'Select duration',
                THISYEAR: 'This year',
                THISQAURTAL: 'This quartal',
                LAST30: 'Last 30 days',
                THISMONTH: 'This month',
                LASTMONTH: 'Last month',
                THISWEEK: 'This week',
                TODAY: 'Today',
                START: 'Start',
                END: 'End',
                PROJECT: 'Project',
                USER: 'Person',
            },
        },
    },
};
