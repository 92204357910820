import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/main/administration/configuration/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog-invoices',
    templateUrl: './confirm-dialog-invoices.component.html',
    styleUrls: ['./confirm-dialog-invoices.component.scss'],
})
export class ConfirmDialogInvoicesComponent {
    public confirmMessage: any;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        if (data.confirmMessage) {
            this.confirmMessage = data.confirmMessage;
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    close(): void {
        this.dialogRef.close(false);
    }

    save(): void {
        this.dialogRef.close(true);
    }
}
