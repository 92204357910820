export const locale = {
    lang: 'de',
    data: {
        WORKPACKAGE: {
            TITLE: 'Aufgabensets',
            FORM: {
                TITLE: 'Titel',
                SEARCH_LABEL: 'Nach einer Aufgabe suchen',
                ALL: 'Alle Aufgaben',
                MY: 'Meine Aufgaben',
                CRITICAL: 'Kritische Aufgaben',
                OVERDUEDATE: 'Überfällige Aufgaben',
                OFFSETSTART: 'Addition to the startdate',
                INDAYS: '(in days)',
                OFFSETEND: 'Addition to the duedate',
                TODAY: 'Ab Heute',
                START: 'Ab Beginn Arbeitspaket',
                END: 'Ab Ende Arbeitspaket',
                STARTASSIGNMENT: 'Ab Beginn Projekt',
                ENDASSIGNMENT: 'Ab Ende Projekt',
                OFFSETSTARTTYPE: 'Typ der Addition zum Anfangsdatum',
                OFFSETENDTYPE: 'Typ der Addition zum Fälligkeitsdatum',
                IMPORT_WORKPACKAGE: 'Aufgabensets erstellen',
                DIALOG: {
                    PERMISSION: 'Wollen Sie dieses Element wirklich löschen?',
                    SUCCESS:
                        'Der ausgewählte Zeiteintrag wurde erfolgreich gelöscht',
                    ERROR: 'Beim Löschen ist ein Fehler aufgtetreten!',
                },
                COLUMNS: {
                    TITLE: 'Titel',
                    DUEDATE: 'Fälligkeitsdatum',
                    STATUS: 'Status',
                    TASK: 'Aufgabe',
                    ASSIGNED: 'Zugewiesen an',
                    PROJECT: 'Projekt',
                    DESCRIPTION: 'Beschreibung',
                    KIND: 'Aufgabenart',
                    WORKPACKAGE: 'Arbeitspaket',
                    START: 'Start',
                    PRIORITY: 'Priorität',
                    HIGH: '(1) Hoch',
                    NORMAL: '(2) Normal',
                    LOW: '(3) Niedrig',
                    NOTSTARTED: 'Nicht begonnen',
                    INPROCESSING: 'In Bearbeitung',
                    COMPLETED: 'Abgeschlossen',
                    RESET: 'Zurückgestellt',
                    WAITING: 'Wartet auf jemand anderen',
                    TYPE: 'Aufgabentyp',
                    MARK: 'Kennzeichen',
                    CRITICAL: 'Kritisch',
                    UNCRITICALLY: 'Unkritisch',
                    PROCESSSTEP: 'Prozessschritt',
                    AUFGABENSETS: 'Aufgabenvorlagen'
                },
                TABS: {
                    BASE: 'Stammdaten',
                    TASKSETS: 'Aufgabenvorlagen',
                    WORKPACKAGESETS: 'Arbeitspakete',
                    TASKS: 'Aufgaben',
                    DOCS: 'Dokumente',
                    TIMES: 'Zeiten',
                    EMAILS: {
                        TITLE: 'E-Mails',
                        IN: 'Empfangen',
                        OUT: 'Gesendet',
                        SEND: 'Neue E-Mail',
                        RE: 'Antworten',
                        INDEX: 'E-Mails zuordnen',
                    },
                },
                NEWTASK: 'Neue Aufgabe',
                EDITTASK: 'Aufgabe bearbeiten',
                NEW: 'Neues Arbeitspaket',
                EDIT: 'Arbeitspaket bearbeiten',
                SAVE: 'Speichern',
                NOTIFICATIONS: {
                    SAVINGITEM: 'Aufgabe/Arbeitspaket wird gespeichert...',
                    SAVING: 'Berechtigungen werden gesetzt...',
                    WAIT: 'Bitte warten.',
                    SUCCESS: 'Berechtigungen erfolgreich gespeichert.',
                    SUCCESSITEM: 'Aufgabe/Arbeitspaket erfolgreich gespeichert.',
                    ERROR: 'Fehler beim setzen der Berechtigungen: ',
                    DUEDATEERROR:
                        'Das Fälligkeitsdatum darf das Enddatum des Projekts nicht überschreiten.',
                    DUEDATEERRORTASK:
                        'Das Fälligkeitsdatum darf das Enddatum des Arbeitspakets nicht überschreiten.',
                    STARTDATEERROR:
                        'Das Startdatum darf nicht vor dem Startdatum des Projekts liegen.',
                    STARTDATEERRORTASK:
                        'Das Startdatum darf nicht vor dem Startdatum des Arbeitspakets liegen.',
                    STARTDUEDATEERROR:
                        'Das Startdatum darf das Fälligkeitsdatum nicht überschreiten.',
                    DUEDATEHINT: 'Nicht später als: ',
                    STARTDATEHINT: 'Nicht früher als: ',
                    SUCCESS_DELETE: 'Datei/Ordner wurde erfolgreich gelöscht.',
                },
            },
        },
        WORKPACKAGESETS: {
            TITLE: 'Arbeitspaketeset',
            FORM: {
                TITLE: 'Titel',
                IMPORT_WORKPACKAGE_SET: 'Arbeitspaketeset erstellen',
                SAVE: 'Speichern',
                COLUMNS: {
                    TITLE: 'Titel',
                    WORKPACKAGE: 'Aufgabensets',
                    TASKS: 'Aufgabenvorlagen'
                },
                TABS: {
                    BASE: 'Stammdaten',
                    TASKSETS: 'Aufgabensets',
                    WORKPACKAGESETS: 'Aufgabensets',
                },
                NOTIFICATIONS: {
                    SAVINGITEM: 'Aufgabe/Arbeitspaket wird gespeichert...',
                    SAVING: 'Berechtigungen werden gesetzt...',
                    WAIT: 'Bitte warten.',
                    SUCCESS: 'Berechtigungen erfolgreich gespeichert.',
                    SUCCESSITEM: 'Aufgabe/Arbeitspaket erfolgreich gespeichert.',
                    ERROR: 'Fehler beim setzen der Berechtigungen: ',
                    DUEDATEERROR:
                        'Das Fälligkeitsdatum darf das Enddatum des Projekts nicht überschreiten.',
                    DUEDATEERRORTASK:
                        'Das Fälligkeitsdatum darf das Enddatum des Arbeitspakets nicht überschreiten.',
                    STARTDATEERROR:
                        'Das Startdatum darf nicht vor dem Startdatum des Projekts liegen.',
                    STARTDATEERRORTASK:
                        'Das Startdatum darf nicht vor dem Startdatum des Arbeitspakets liegen.',
                    STARTDUEDATEERROR:
                        'Das Startdatum darf das Fälligkeitsdatum nicht überschreiten.',
                    DUEDATEHINT: 'Nicht später als: ',
                    STARTDATEHINT: 'Nicht früher als: ',
                    SUCCESS_DELETE: 'Datei/Ordner wurde erfolgreich gelöscht.',
                },
            },
        }
    },
};
