<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id && this.isTask" class="title dialog-title">{{ "DETAIL_INVOICE.EDITTASK" | translate }}</span>
    <span *ngIf="!this.id && this.isTask" class="title dialog-title">{{ "DETAIL_INVOICE.NEWTASK" | translate }}</span>
    <span *ngIf="this.id && !this.isTask" class="title dialog-title">{{ "DETAIL_INVOICE.EDIT" | translate }}</span>
    <span *ngIf="!this.id && !this.isTask" class="title dialog-title">{{ "DETAIL_INVOICE.NEW" | translate }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

    <mat-tab class="tab" label='{{ "DETAIL_INVOICE.TABS.BASE" | translate }}'>

      <mat-slide-toggle id="isNettoChange" (change)="isNettoChange($event);" [checked]="!this.isNetto">
        {{ "DETAIL_INVOICE.ISNETTO" | translate }}
      </mat-slide-toggle>

      <br />

      <formio class="formio-material" #formio [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this"
        [renderOptions]="formio_renderOptions"
        [form]="formioConfiguration" (change)="onChange($event)">
      </formio>

    </mat-tab>

  </mat-tab-group>

</div>
