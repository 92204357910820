export const locale = {
    lang: 'en',
    data: {
        DETAIL_INVOICE: {
            TITLE: 'Invoices',
            SEARCH_LABEL: 'Search for a Invoice',
            ALL: 'All Invoices',
            //MY: 'My tasks',
            //CRITICAL: 'Critical tasks',
            //OVERDUEDATE: 'Overdue tasks',
            ISNETTO: 'Netto/Brutto Eingabe',
            COLUMNS: {
                TITLE: 'Title',
                YEAR: 'Year',
                MONTH: 'Month',
                CLAIM: 'Claim',
                CLAIMGROSS: 'Gross Claim',
                PAID: 'Paid',
                PAIDGROSS: 'Gross Paid',
                OUTSTANDING: 'Outstanding',
                OUTSTANDINGGROSS: 'Gross Outstanding',
                ADDRESS: 'ADDRESS',
                TEMPLATE: 'Invoice template',
                PROJECTID: 'Project',
                VON: 'From',
                BIS: 'Until',
                FRIST: 'Frist',
                BILL_NUMBER: 'Invoice number',
                NOTIZEN: 'Notizen',
                RECHNUNGSDATUM: 'Rechnungsdatum'
                /*
                DUEDATE: 'Duedate',
                STATUS: 'Status',
                TASK: 'Task',
                ASSIGNED: 'Assigned to',
                PROJECT: 'Project',
                DESCRIPTION: 'Description',
                KIND: 'Taskkind',
                WORKPACKAGE: 'Workpackage',
                START: 'Start',
                PRIORITY: 'Priority',
                HIGH: '(1) High',
                NORMAL: '(2) Normal',
                LOW: '(3) Low',
                NOTSTARTED: 'Not started',
                INPROCESSING: 'In processing',
                COMPLETED: 'Completed',
                RESET: 'Reset',
                WAITING: 'Waiting',
                TYPE: 'Tasktype',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                PROCESSSTEP: 'Processstep',
                */
            },
            FORM: {
                MONTH: {
                    JAN: 'Jan',
                    FEB: 'Feb',
                    MAR: 'Mar',
                    APR: 'Apr',
                    MAY: 'May',
                    JUN: 'Jun',
                    JUL: 'Jul',
                    AUG: 'Aug',
                    SEP: 'Sep',
                    OCT: 'Oct',
                    NOV: 'Nov',
                    DEC: 'Dec',
                }
            },
            TABS: {
                BASE: 'Basedata',
                TASKS: 'Tasks',
                DOCS: 'Documents',
                TIMES: 'Times',
            },
            NEW: 'New invoice',
            EDIT: 'Edit invoice',
            SAVE: 'Save',
            //TASKSETS: 'TASKSETS',
            GIVEUSERPERMISSIONTOPROJECT:
                'The selected user does not have permissions to the current project. Should this edit permissions be given?',
            NOTIFICATIONS: {
                SAVINGITEM: 'Saving invoice...',
                SAVING: 'Saving permissions...',
                WAIT: 'Please wait.',
                SUCCESS: 'Permissions set successfully',
                SUCCESSITEM: 'Invoice saved successfully',
                ERROR: 'Error while setting permissions: ',
                DUEDATEERROR:
                    'The due date cannot exceed the end date of the project.',
            },
            DOCS: {
                TITLE: 'Taskdocuments',
                NEW: 'Upload document',
                NEWFOLDER: 'New folder',
                COLUMNS: {
                    TITLE: 'Title',
                    FILESIZE: 'Filesize',
                    AUTHOR: 'Author',
                    MODIFIED: 'Modified',
                },
            },
        },
    },
};
