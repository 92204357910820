export const locale = {
    lang: 'en',
    data: {
        LV_GROUP: {
            TITLE: 'Groups',
            SEARCH_LABEL: 'Search for a group',
            ALL: 'All',
            COLUMNS: {
                TITLE: 'Group',
                DESCRIPTION: 'Description',
            },
            NEW: 'New group',
            EDIT: 'Edit group',
            SAVE: 'Save',
            NOTIFICATIONS: {
                DELETED: 'Group deleted!',
                HINT: 'Note: Only owners of a group can delete it.',
            },
        },
    },
};
