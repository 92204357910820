export const locale = {
    lang: 'de',
    data: {
        TITLE: 'Dokumente',
        SEARCH_LABEL: 'Nach einem Dokument suchen',
        COLUMNS: {
            EDITPERMISSIONS: 'Bearbeitungsberechtigungen',
            PROJECT: 'Projekt',
        },
        NEW: 'Neues Dokument',
        EDIT: 'Dokument bearbeiten',
        EDITPERMISSIONS: 'Berechtigungen setzen',
        SAVE: 'Speichern',
        CURRENTUSER: '(Angemeldeter Nutzer)',
        MANAGER: '(Projektleiter)',
        DEFAULTPERMISSIONS: 'Standardberechtigungen',
        NOTIFICATIONS: {
            SAVINGITEM: 'Dokument wird gespeichert...',
            SAVING: 'Berechtigungen werden gesetzt...',
            WAIT: 'Bitte warten.',
            SUCCESS: 'Berechtigungen erfolgreich gespeichert.',
            SUCCESSITEM: 'Dokument erfolgreich gespeichert.',
            ERROR: 'Fehler beim setzen der Berechtigungen: ',
            PERMISSIONS:
                'Hinweis! Folgende User werden automatisch berechtigt: ',
        },
    },
};
