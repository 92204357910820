import CamlBuilder from 'camljs';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1121Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const processstepsList: IList =
                await this._sharePointService.web.lists.getByTitle(
                    'Prozessschritte'
                );

            const promises = [
                this._updateService.addField(
                    processstepsList,
                    'Standardwert',
                    FieldType.Choice,
                    null,
                    ['Ja', 'Nein']
                ),
            ];

            Promise.all(promises)
                .then(() =>
                    this._sharePointService
                        .getListItems({
                            title: 'Prozessschritte',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                        })
                        .then(async (results) => {
                            try {
                                for (let result of results) {
                                    await this._sharePointService.updateItem({
                                        listTitle: 'Prozessschritte',
                                        id: result.Id,
                                        newFiledValues: {
                                            Standardwert: 'Nein',
                                        },
                                    });
                                }
                                resolve();
                            } catch (err) {
                                reject(err);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                )
                .catch((error) => reject(error));
        });
        return promise;
    }
}
