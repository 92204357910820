import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    SharePointService,
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import { GlobalTask } from '../../models/globaltask.model';
import { DetailTaskComponent } from '../detail-task/detail-task.component';
import { TableTasksetComponent } from '../../taskset/table-taskset/table-taskset.component';
import { TaskService } from '../../services/task.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImportWorkpackageComponent } from 'src/app/main/work-package-template/components/import-workpackage/import-workpackage.component';
import {
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { LoadingService } from '../../services/loading.service';
import CamlBuilder from 'camljs';
import { ImportWorkpackageSetComponent } from '../../work-package-template/components/import-workpackage-set/import-workpackage-set.component';

@Component({
    selector: 'app-table-task',
    templateUrl: './table-task.component.html',
    styleUrls: ['./table-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableTaskComponent implements OnInit {
    @ViewChild('taskGrid', { static: false })
    taskGrid: SharePointTableComponent;

    _auftragsId: number;
    @Input() set auftragsId(aId: number) {
        this._auftragsId = aId;
    }

    _auftrag: any;
    @Input() set auftrag(auftrag: any) {
        this._auftrag = auftrag;
    }

    _isTask: boolean;
    @Input() set isTask(isTask: boolean) {
        this._isTask = isTask;
    }

    _configuration: TableConfiguration;
    _configurationJSONCopy: string;
    @Input() set configuration(config: TableConfiguration) {
        this._configuration = config;
        this._configurationJSONCopy = JSON.stringify(config);
    }

    _taskInitData: GlobalTask;
    @Input() set taskInitData(initData: GlobalTask) {
        this._taskInitData = initData;
    }

    _filter: string;
    @Input() set filter(filter: string) {
        this._filter = filter;
    }

    _paketId: number;
    @Input() set paketId(pId: number) {
        this._paketId = pId;
    }

    public id: number;

    get camlQueryAll() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public dialog: MatDialog,
        public tasksService: TaskService,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private _sharePointService: SharePointService,
        private _snackBarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {}

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this._isTask = false;
                this.openDialog();
                break;
            case 'AddTask':
                this._isTask = true;
                if (e.latestParentItem) {
                    this._paketId = e.latestParentItem.Id;
                }
                this.openDialog();
                break;
            case 'Edit':
                this.id = e.dataItem ? e.dataItem.Id : null;
                this._paketId = e.dataItem.ArbeitspaketId;

                if (this._paketId) {
                    this._isTask = true;
                } else {
                    this._isTask = false;
                }

                this.openDialog();
                break;
            case 'TaskSets':
                this.openTaskSetDialog();
                break;
            case 'Recycle':
                this._paketId = e.dataItem.ArbeitspaketId;

                if (this._paketId) {
                    this._isTask = true;
                } else {
                    this._isTask = false;
                }

                // Fake SPItem for methode
                const currentItem = {
                    Id: e.dataItem.Id,
                    Kennzeichen: 'Unkritisch',
                };

                // Parentitem
                let parentItemID = this._auftragsId;

                if (this._isTask) {
                    parentItemID = this._paketId;
                }

                this.tasksService
                    .updateStateOfParentItem(
                        this._isTask,
                        parentItemID,
                        currentItem
                    )
                    .then(() => {
                        // success
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                break;
            case 'FilterMyWorkPackages':
                {
                    const tmpConfiguration = JSON.parse(
                        this._configurationJSONCopy
                    );
                    this._configuration = null;
                    this.cdRef.detectChanges();
                    tmpConfiguration.camlQuery = tmpConfiguration.toolbar.find(
                        (x) => x.command === 'FilterMyWorkPackages'
                    ).query;
                    this._configuration = tmpConfiguration;
                    this.cdRef.detectChanges();
                }
                break;
            case 'FilterCriticalWorkPackages':
                {
                    const tmpConfiguration = JSON.parse(
                        this._configurationJSONCopy
                    );
                    this._configuration = null;
                    this.cdRef.detectChanges();
                    tmpConfiguration.camlQuery = tmpConfiguration.toolbar.find(
                        (x) => x.command === 'FilterCriticalWorkPackages'
                    ).query;
                    this._configuration = tmpConfiguration;
                    this.cdRef.detectChanges();
                }
                break;
            case 'ClearFilter':
                {
                    const tmpConfiguration = JSON.parse(
                        this._configurationJSONCopy
                    );
                    this._configuration = null;
                    this.cdRef.detectChanges();
                    tmpConfiguration.camlQuery = tmpConfiguration.toolbar.find(
                        (x) => x.command === 'ClearFilter'
                    ).query;
                    this._configuration = tmpConfiguration;
                    this.cdRef.detectChanges();
                }
                break;
            case 'ImportWorkPackageSet':
                this.openImportWorkPackageSetDialog();
                break;
            case 'ImportTask':
                this.openImportWorkPackageDialog();
                break;
            case 'Link':
                let projectfolderName;

                if (
                    this._auftrag &&
                    this._auftrag.Auftragsstart &&
                    this._auftrag.Projektnummer
                ) {
                    projectfolderName =
                        new Date(this._auftrag.Auftragsstart).getFullYear() +
                        '\\' +
                        this._auftrag.Projektnummer;
                }

                if (!projectfolderName) {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'TABLE_TASK.NOTIFICATIONS.MISSINGSTART'
                        ),
                        '',
                        {
                            duration: 10000,
                            panelClass: 'error-dialog',
                        }
                    );
                } else {
                    if (this._auftrag && this._auftrag.StandortId) {
                        this._sharePointService
                            .getItemById({
                                listTitle: 'Standorte',
                                id: this._auftrag.StandortId,
                            })
                            .then((location) => {
                                if (location && location.Fileserver) {
                                    let locationFileserver =
                                        location.Fileserver;

                                    if (!locationFileserver) {
                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'TABLE_TASK.NOTIFICATIONS.MISSINGPATH'
                                            ),
                                            '',
                                            {
                                                duration: 10000,
                                                panelClass: 'error-dialog',
                                            }
                                        );
                                    } else {
                                        this._loadingService.show(
                                            this._translateService.instant(
                                                'TABLE_TASK.NOTIFICATIONS.CONNECTING'
                                            ),
                                            this._translateService.instant(
                                                'TABLE_TASK.NOTIFICATIONS.WAIT'
                                            )
                                        );

                                        if (
                                            locationFileserver.substr(
                                                locationFileserver.length - 1
                                            ) != '/' &&
                                            locationFileserver.substr(
                                                locationFileserver.length - 1
                                            ) != '\\'
                                        ) {
                                            locationFileserver += '\\';
                                        }

                                        // this._sharePointService
                                        //     .getListItems({
                                        //         title: 'Netzlaufwerkunterordner',
                                        //         isDocumentLibrary: false,
                                        //         camlQuery: this.camlQueryAll,
                                        //     })
                                        //     .then((x: any[]) => {
                                        //         let subfolders = '';
                                        //         x.forEach((element, index) => {
                                        //             subfolders +=
                                        //                 '&' + element.Title;
                                        //         });

                                        //         this._loadingService.hide();

                                        //         window.open(
                                        //             'mopa:' +
                                        //                 locationFileserver +
                                        //                 projectfolderName +
                                        //                 '?open' +
                                        //                 subfolders,
                                        //             '_blank'
                                        //         );
                                        //     })
                                        //     .catch((e) => {
                                        //         console.error(e);
                                        //         this._loadingService.hide();
                                        //     });

                                        this._loadingService.hide();

                                        window.open(
                                            'mopa:' +
                                                locationFileserver +
                                                projectfolderName +
                                                '?open' +
                                                '&' +
                                                e.dataItem.Title,
                                            '_blank'
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                }
                break;
        }
    }

    openDialog(): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            isTask: this._isTask,
            taskId: this.id,
            _taskInitData: this._taskInitData,
            _filter: this._filter,
            arbeitsPaketId: this._paketId,
        };

        if (!this._isTask && !this.id) {
            // Collapse subtables on adding new wp
            this.taskGrid.collapseAll();
        }

        const dialogRef = this.dialog.open(DetailTaskComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this.id = null;

            // Refresh table
            this.taskGrid.refresh();
            // Refresh subtables
            this.taskGrid.refreshSubtables();
        });
    }

    openTaskSetDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            arbeitsPaketId: this._paketId,
        };

        const dialogRef = this.dialog.open(TableTasksetComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this.id = null;

            this.taskGrid.refresh();

            if (this._isTask) {
                this.taskGrid.refreshSubtables();
            }
        });
    }

    private openImportWorkPackageDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = 'dialog-without-padding';

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            arbeitsPaketId: this._paketId,
        };

        this.ngZone.runOutsideAngular(() => {
            const dialogRef = this.dialog.open(
                ImportWorkpackageComponent,
                dialogConfig
            );

            dialogRef.afterClosed().subscribe(() => {
                this.taskGrid.refresh();

                if (this._isTask) {
                    this.taskGrid.refreshSubtables();
                }
            });
        });
    }

    private openImportWorkPackageSetDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = 'dialog-without-padding';

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            arbeitsPaketId: this._paketId,
        };

        this.ngZone.runOutsideAngular(() => {
            const dialogRef = this.dialog.open(
                ImportWorkpackageSetComponent,
                dialogConfig
            );

            dialogRef.afterClosed().subscribe(() => {
                this.taskGrid.refresh();

                if (this._isTask) {
                    this.taskGrid.refreshSubtables();
                }
            });
        });
    }
}
