export const locale = {
    lang: 'de',
    data: {
        EDIT_ASSIGNMENT: {
            PROJECTS: 'Projekte',
            BASE: 'Stammdaten',
            BUDGETS: 'Budgets',
            DOCS: 'Dokumente',
            INVOLVED: 'Beteiligte',
            TASKS: 'Arbeitspakete',
            KANBAN: 'Kanban',
            NOTES: 'Notizen',
            NEW: 'Neues Projekt',
            POPUP: {
                QUESTION_1: 'Soll das Datum für das Auftragsende auf den ',
                QUESTION_2: ' gesetzt werden?',
            },
            FORM: {
                CREATED1: 'Erstellt von ',
                CREATED2: ' am ',
                CREATED3: ' um ',
                USER: 'Rollen',
                SAVE: 'Speichern',
                TITLE: 'Projektbezeichnung',
                DESCRIPTION: 'Beschreibung',
                START: 'Start',
                END: 'Ende',
                STATUS: 'Status',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                CATEGORY: 'Kategorie',
                CUSTOMER: 'Auftraggeber / Ansprechpartner',
                MARK: 'Kennzeichnung',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                BUDGET: 'Interne Auftragssumme',
                INEURO: '(in Euro)',
                INHOURS: '(in Stunden)',
                INDAYS: '(in Tagen)',
                INTEXT: '(Text)',
                SUM: 'Auftragssumme  ',
                ADDITIONAL: 'zzgl. Nebenkosten  ',
                INPERCENT: '(in %)',
                EXTERNAL: 'Davon Fremdleistung',
                MANAGEMENT: 'Projektleiter',
                CLERK: 'Sachbearbeiter',
                PPL: 'PPL',
                CONTACTPERSON: 'Ansprechpartner/Fachbereich',
                APPLICANT: 'Antragssteller',
                PERMISSIONS: 'Berechtigungen',
                READ: 'Lesen',
                EDIT: 'Bearbeiten',
                TEAM: 'Team',
                FACHBEREICH: 'Fachbereich',
                PROJECTNUMBER: 'Projektnummer',
                ORDER: 'Auftrag',
                OWNUMBER: 'OW-Nummer',
                UEBERGABE: 'Übergabedatum',
                TAXER: 'Projektsteuerer',
                TAXERDESCRIPTION: 'nur zur Rechnungsprüfung',
                COSTCENTRE: 'Kostenstelle',
                LOCATION: 'Niederlassung',
                ACCREDITED: 'Akkreditiert',
                EXTERNALCOSTS: 'Fremdkosten',
                COSTS: 'Kosten',
                BILLDATE: 'Rechnungsdatum',
                PROJECTMAIL: 'Projekt E-Mail',
                EXPORT: 'Excel-Export',
                SKONTO: 'Skonto',
                YES: 'Ja',
                NO: 'Nein',
                EXPECTEDPROFITMARGIN: 'Erwartete Gewinnmarge'
            },
            NOTIFICATIONS: {
                SAVING: 'Projekt wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Projekt erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
                STARTDUEDATEERROR:
                    'Das Startdatum darf das Enddatum nicht überschreiten.',
            },
        },
    },
};
