import {
    Component,
    OnInit,
    EventEmitter,
    Inject,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {
    SharePointService,
    ColumnTyp,
    TableColumn,
    TableConfiguration,
    FormatTypes,
    SharePointTableComponent,
} from 'sp-office365-framework';
import { ApplicationService } from '../../services/application.service';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { DetailNotificationComponent } from '../../notification/detail-notification/detail-notification.component';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { WindowRef } from '../../shared/WindowRef';
import { from } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { IAttachmentFileInfo } from '@pnp/sp/presets/all';
import { MailService } from '../../services/mail.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import '@pnp/graph/users';
import { Mail } from '../../models/mail.model';
import CamlBuilder from 'camljs';

@Component({
    selector: 'app-reassign-mail',
    templateUrl: './reassign-mail.component.html',
    styleUrls: ['./reassign-mail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ReAssignMailComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    //public id;
    public sharePointItems = [];
    public formioConfiguration;
    public isCreateMode = true;
    public auftragsId;
    public addresses = [];
    public mailFolders = [];
    public mailFoldersSelect = [];
    public antragsId;
    public deletedItems = [];

    public tableConfig: TableConfiguration;
    private columns: TableColumn[];

    get camlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Projekt')
            .Id()
            .EqualTo(this.auftragsId)
            .And()
            .LookupField('Arbeitspaket')
            .Id()
            .IsNull()
            .OrderByDesc('Modified')
            .ToString();
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();

    //@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailNotificationComponent>,
        public _documentService: DocumentsAssignmentService,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _mailService: MailService,
        private winRef: WindowRef
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.winRef.nativeWindow.dateFields = [];
        this.winRef.nativeWindow.userFields = [];
    }

    ngOnInit() {
        const promises = [];

        this.auftragsId = this.data.auftragsId;
        this.antragsId = this.data.antragsId;

        // Load O365 adresses in background
        this._sharepointService.graph.me.people
            .top(1000)
            .select('scoredEmailAddresses')()
            .then((people) => {
                this.addresses = people
                    .map((x) => x.scoredEmailAddresses)
                    .map((y) => y[0].address);
            })
            .catch((error) => {
                console.error(error);
            });

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                this._mailService.getMailfoldersFromUser().then(
                    (folders) => {
                        this.mailFolders = folders;

                        this.mailFolders.forEach((element) => {
                            this.mailFoldersSelect.push({
                                label: element.displayName,
                                value: element.displayName,
                            });
                        });

                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
            })
        );

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'REASSIGN_EMAIL.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('REASSIGN_EMAIL.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        return from(
            this._applicationService
                .callBeforeSaveFunctions(this.formioConfiguration, e.data)
                .then(() => {
                    const promises = [];

                    this.documentGrid.itemSelected.forEach((element) => {
                        let newData = element.dataItem;
                        newData.ProjektId = e.data.ProjektId;

                        promises.push(
                            this._sharepointService
                                .addItem({
                                    listTitle: 'Mailbox',
                                    folder: e.data.ProjektId + '',
                                    data: newData,
                                })
                                .then((elementId) => {
                                    this.sharePointItems.push(
                                        element.dataItem.Id
                                    );
                                    this._sharepointService
                                        .recycleItem({
                                            listTitle: 'Mailbox',
                                            id: element.dataItem.Id,
                                        })
                                        .then((emailItem) => {
                                            //debugger;
                                            //this.sharePointItems.push(emailItem);
                                        });
                                })
                        );
                    });

                    Promise.all(promises)
                        .then(() => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'REASSIGN_EMAIL.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close({
                                state: 'success',
                                elements: this.sharePointItems,
                            });
                        })
                        .catch((error) => {
                            this._loadingService.hide();
                            console.error(error);
                        });
                })
                .catch((error) => {
                    this._loadingService.hide();
                    console.error(error);
                })
        );
    }

    onChange(ev) {}

    close(message?): void {
        if (message) {
            message.deletedItems = this.deletedItems;
        } else {
            message = {
                //state: 'success',
                deletedItems: this.deletedItems,
            };
        }

        this.dialogRef.close(message);
    }

    //addAttachment() {
    //    this.fileInput.nativeElement.click();
    //}

    initForm() {
        this.columns = [
            {
                internalName: 'Subject',
                title: this._translateService.instant(
                    'REASSIGN_EMAIL.COLUMNS.SUBJECT'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'From',
                title: this._translateService.instant(
                    'REASSIGN_EMAIL.COLUMNS.FROM'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'To',
                title: this._translateService.instant(
                    'REASSIGN_EMAIL.COLUMNS.TO'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Body',
                title: this._translateService.instant(
                    'REASSIGN_EMAIL.COLUMNS.TEXT'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Title',
                title: 'Title',
                hidden: true,
            },
            {
                internalName: 'CC',
                title: 'CC',
                hidden: true,
            },
            {
                internalName: 'Attachment_Urls',
                title: 'Attachment_Urls',
                hidden: true,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Mailbox',
            columns: this.columns,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: true,
            addCheckboxColumn: true,
            useMaterialDesign: true,
            camlQuery: this.camlQuery,
            folderName: this.auftragsId + '',
            afterRefreshTable: () => {
                // Update sidebar
                this._applicationService.getSideBarConfig(this.auftragsId);
            },
            afterDeleteItem: (item) => {
                this.deletedItems.push(item);
            },
        };

        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'REASSIGN_EMAIL.FORM.PROJECT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektId',
                                    list: 'Aufträge',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .LookupField('Antrag')
                                        .Id()
                                        .NotEqualTo(0)
                                        //.EqualTo(this.antragsId)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    //isCreateMode: this.isCreateMode,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                        {
                            components: [],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns1',
                },
                {
                    label: this._translateService.instant(
                        'REASSIGN_EMAIL.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }

    onTooolbarButtonClick(e) {
        //switch (e.command) {
        //    case 'Add':
        //        this.fileInput.nativeElement.click();
        //        break;
        //}
    }
}
