export const locale = {
    lang: 'en',
    data: {
        LV_TIME: {
            TITLE: 'Meine Zeiten',
            PROJECTS: 'Projects',
            TIMES: 'Times',
            NEW: 'Add new time',
            COLUMNS: {
                DATE: 'Date',
                MINUTES: 'Duration (in minutes)',
                TASK: 'Task',
                AUTHOR: 'Employee',
            },
        },
        FILTER: {
            YEAR: "Jahr",
            MONTH: "Monat",
            MONTHS: {
                ALL: " ",
                JANUARY: "Januar",
                FEBRUARY: "Februar",
                MARCH: "März",
                APRIL: "April",
                MAY: "Mai",
                JUNE: "Juni",
                JULY: "Juli",
                AUGUST: "August",
                SEPTEMBER: "September",
                OCTOBER: "Oktober",
                NOVEMBER: "November",
                DECEMBER: "Dezember",
            },
            EMPLOYEE: "Mitarbeiter",
            TEAMMANAGER: "Teamleiter",
        },
    },
};
