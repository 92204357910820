<listview #listView *ngIf="this.config" [config]="this.config"
          class="page-layout bills-table simple left-sidebar inner-sidebar inner-scroll">

    <ng-template customHeader>
        <div class="listview-header">
            <app-filter-bills></app-filter-bills>
        </div>
        <button class="mb-8 ml-24" mat-raised-button color="accent" (click)="fileInput.click()">
            {{ "TABLE.IMPORT_BUTTON" | translate }}
        </button>
        <button class="mb-8 ml-24" mat-raised-button color="accent" (click)="pdfInput.click()">
            {{ "TABLE.IMPORT_PDF_BUTTON" | translate }}
        </button>
        <input #fileInput
               type="file"
               style="display: none"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
               (change)="selectBillsFileHandler($event)"
        />
        <input #pdfInput
               type="file"
               style="display: none"
               accept="application/pdf"
               multiple
               (change)="selectPDFBillAttachmentHandler($event)"
        />
    </ng-template>

    <ng-template customCell for="Projekt_x003a_Title" let-dataItem="dataItem">
        {{dataItem.FieldValuesAsText.Projekt_x005f_x003a_x005f_Title || dataItem.FieldValuesAsText.Projekt_x005f_x003a_x005f_Auftragstitel}}
    </ng-template>

    <ng-template customCell for="Projekt_x003a_Projektnummer" let-dataItem="dataItem">
        {{dataItem.FieldValuesAsText.Projekt_x005f_x003a_x005f_Projektnummer}}
    </ng-template>

    <ng-template customCell for="Monat" let-dataItem="dataItem">
        <div *ngIf="dataItem.Monat == 1">{{ "MONTH.JANUARY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 2">{{ "MONTH.FEBRUARY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 3">{{ "MONTH.MARCH" | translate }}</div>
        <div *ngIf="dataItem.Monat == 4">{{ "MONTH.APRIL" | translate }}</div>
        <div *ngIf="dataItem.Monat == 5">{{ "MONTH.MAY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 6">{{ "MONTH.JUNE" | translate }}</div>
        <div *ngIf="dataItem.Monat == 7">{{ "MONTH.JULY" | translate }}</div>
        <div *ngIf="dataItem.Monat == 8">{{ "MONTH.AUGUST" | translate }}</div>
        <div *ngIf="dataItem.Monat == 9">{{ "MONTH.SEPTEMBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 10">{{ "MONTH.OCTOBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 11">{{ "MONTH.NOVEMBER" | translate }}</div>
        <div *ngIf="dataItem.Monat == 12">{{ "MONTH.DECEMBER" | translate }}</div>
    </ng-template>

    <ng-template customCell for="Forderung" let-dataItem="dataItem">
        {{dataItem.Forderung | currency}}
    </ng-template>

</listview>
