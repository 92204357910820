import { DetailPositionComponent } from './detail-position.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { AngularFrameworkModule } from 'sp-office365-framework';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [DetailPositionComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
        AngularFrameworkModule,
        MatButtonModule,
        DragDropModule,
    ],
    exports: [DetailPositionComponent],
})
export class DetailPositionModule {}
