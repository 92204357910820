import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1041Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const documentList = this.sharePointService.web.lists.getByTitle(
                'Auftragsdokumente'
            );

            const promises = [
                // Add Field Standardberechtigung to List Auftragsdokumente
                await this._updateService.runFunctionIfFieldNotExists(
                    documentList,
                    'Standardberechtigung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            documentList.fields
                                .addBoolean('Standardberechtigung')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
