import { NgModule } from '@angular/core';
import { ListviewInvoiceComponent } from './listview-invoice.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

const routes = [
    {
        path: 'invoices',
        component: ListviewInvoiceComponent,
    },
];

@NgModule({
    declarations: [ListviewInvoiceComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
    ],
    exports: [ListviewInvoiceComponent],
})
export class ListviewInvoiceModule {}
