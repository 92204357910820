import { Component, ViewChild } from '@angular/core';
import {
    FuseTranslationLoaderService,
    ListViewConfig,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { ColumnTyp, FormatTypes } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailInvoiceComponent } from '../detail-invoice/detail-invoice.component';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { TaskService } from '../../services/task.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-listview-invoice',
    templateUrl: './listview-invoice.component.html',
    styleUrls: ['./listview-invoice.component.scss'],
})
export class ListviewInvoiceComponent {
    @ViewChild('taskGrid', { static: false }) taskGrid: ListViewComponent;

    public config: ListViewConfig;

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _taskService: TaskService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initConfig();
    }

    get activeTasksCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .ToString();
    }

    get activeTasksSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .ToString();
    }

    get activeOverDueTasksCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .ToString();
    }

    get activeOverDueTasksSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .ToString();
    }

    openDialog(item): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: item.AuftragId,
            isTask: true,
            taskId: item.Id,
            _paketId: item.ArbeitspaketId,
        };

        const dialogRef = this.dialog.open(
            DetailInvoiceComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            // Refresh table
            self.taskGrid._listViewService.onListViewDataChanged.next(
                self.taskGrid._listViewService.listView
            );
        });
    }

    initConfig() {
        this.config = {
            title: this._translateService.instant('LV_TASK.TITLE'),

            searchLabel: this._translateService.instant('LV_TASK.SEARCH_LABEL'),
            icon: 'assignment_turned_in',
            add_icon: 'add',
            openEditInDialog: true,
            onRowClick: (item) => {
                this.openDialog(item);
            },
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_TASK.ALL'),
                    config: {
                        list: 'Auftragsaufgaben',
                        groups: [{ field: 'Auftrag' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.TITLE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'DueDate',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.DUEDATE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'AssignedTo',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.ASSIGNED'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftrag',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.PROJECT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Status',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Arbeitspaket',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.WORKPACKAGE'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: true,
                        recursiveAll: true,
                        camlQuery: this.activeTasksCamlQuery,
                    },
                    camlQueryForSearch: this.activeTasksSearchCamlQuery,
                    afterDeleteItem: (item) => {
                        this._taskService.afterDeleteItem(item);
                    },
                },
                {
                    title: this._translateService.instant(
                        'LV_TASK.OVERDUEDATE'
                    ),
                    config: {
                        list: 'Auftragsaufgaben',
                        groups: [{ field: 'Auftrag' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.TITLE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'DueDate',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.DUEDATE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'AssignedTo',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.ASSIGNED'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftrag',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.PROJECT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Status',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Arbeitspaket',
                                title: this._translateService.instant(
                                    'LV_TASK.COLUMNS.WORKPACKAGE'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: true,
                        recursiveAll: true,
                        camlQuery: this.activeOverDueTasksCamlQuery,
                    },
                    camlQueryForSearch: this.activeOverDueTasksSearchCamlQuery,
                    afterDeleteItem: (item) => {
                        this._taskService.afterDeleteItem(item);
                    },
                },
            ],
        };
    }
}
