import { NewTimeModule } from './../new-time/new-time.module';
import { NgModule } from '@angular/core';
import { ListviewTimeComponent } from './listview-time.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { NewTimeComponent } from '../../time/new-time/new-time.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
    {
        path: 'times',
        component: ListviewTimeComponent,
        data: {
            adminHours: true
        }
    }
];

@NgModule({
    declarations: [ListviewTimeComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatCardModule,
        NewTimeModule,
    ],
    exports: [ListviewTimeComponent]
})
export class ListviewTimeModule {}
