import { RequestService } from './../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1124Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _requestService: RequestService
    ) {}

    async run() {
        try {
            const requestList: IList =
                this._sharePointService.web.lists.getByTitle('Anträge');
            let mailboxRequestList: IList;

            // Add List Mailbox-Anträge
            await this._updateService.addList('Mailbox-Anträge', '', 100, {
                EnableVersioning: true,
            });

            mailboxRequestList =
                this._sharePointService.web.lists.getByTitle('Mailbox-Anträge');

            // Add Field Subject to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'Subject',
                FieldType.Text
            );

            // Add Field Body to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'Body',
                FieldType.MultilineText
            );

            // Add Field From to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'From',
                FieldType.Text
            );

            // Add Field To to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'To',
                FieldType.Text
            );

            // Add Field CC to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'CC',
                FieldType.Text
            );

            // Add Field BCC to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'BCC',
                FieldType.Text
            );

            // Add Field Headers to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'Headers',
                FieldType.Text
            );

            // Add Field ReadyToSend to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'ReadyToSend',
                FieldType.Boolean
            );

            // Add Field Send to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'Send',
                FieldType.Boolean
            );

            // Add Field Attachment_Urls to List Mailbox-Anträge
            await this._updateService.addField(
                mailboxRequestList,
                'Attachment_Urls',
                FieldType.MultilineText
            );

            // Add Field Antrag to List Mailbox-Anträge
            await this._updateService.addLookupField(
                mailboxRequestList,
                requestList,
                'Antrag'
            );

            // Add folders for existing orders in List Mailbox-Anträge
            await this._requestService.addFoldersForExistingOrders(
                'Mailbox-Anträge',
                false
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
