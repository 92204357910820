export const PRINT_ASSIGNMENTS_BUDGET_COLUMNS = [
    {
        title: 'Auftrag/Nachtrag',
        field: 'TB_Nachtrag'
    },
    {
        title: 'Datum',
        field: 'Date',
        format: 'dd.MM.yyyy',
        width: 100
    },
    {
        title: 'EDIT_ASSIGNMENT.FORM.ADDITIONAL',
        field: 'TB_Nebenkosten',
        format: '0\\%;[Red](0\\%)'
    },
    {
        title: 'EDIT_ASSIGNMENT.FORM.ADDITIONAL',
        field: 'TB_NebenkostenEuro',
        format: '_(€ #,##0_);_(€ (#,##0);_(@_)'
    },
    {
        title: 'EDIT_ASSIGNMENT.FORM.EXTERNAL',
        field: 'TB_Fremdleistung',
        format: '_(€ #,##0_);_(€ (#,##0);_(@_)'
    },
    {
        title: 'EDIT_ASSIGNMENT.FORM.SUM',
        field: 'TB_Antragssumme',
        format: '_(€ #,##0_);_(€ (#,##0);_(@_)'
    },
    {
        title: 'EDIT_ASSIGNMENT.FORM.SKONTO',
        field: 'TB_Skonto',
        width: 100
    },
    {
        title: 'Notiz',
        field: 'TB_Notiz'
    }
];
