export const locale = {
  lang: "en",
  data: {
    EVALUATION: "Evaluation",
    CHART: {
      TARGETVALUE: "Target Value",
      ACTUALVALUE: "Actual Value",
    },
    FILTER: {
      LOCATION: "Location",
      FROM_YEAR: "from (Year)",
      UNTIL_YEAR: "until (Year)",
      YEAR: "Year",
      TEAM: "Team",
      MONTH: {
        FROM: "from (Month)",
        UNTIL: "until (Month)",
        ALL: " ",
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      ERRORMESSAGE: {
        YEARS:
          'The value for "from (Year)" has to be less than or equal to the value of "until (Year)"!',
        FOURDIGITS: "The values for the years must have 4 digits!",
        NOTVALID: "The specified period is not valid!",
      },
    },
  },
};
