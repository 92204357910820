export const locale = {
    lang: 'de',
    data: {
        TIMELINE: {
            PROJECTNUMBER: 'Projektnummer',
            TITLE: 'Zeitplan',
            START: 'Start',
            END: 'Ende',
            RESIZED: 'Zeit geändert',
            MOVED: 'Arbeitspaket verschoben',
            THISMONTH: 'Diesen Monat',
            THISYEAR: 'Dieses Jahr',
            NEXTYEAR: '2 Jahre',
            THREEYEARS: '3 Jahre',
            FOURYEARS: '4 Jahre',
            FIVEYEARS: '5 Jahre',
            CURRENT: 'Aktuell',
            HEADER: 'Zeitplan',
            PROJECTS: 'Projekte',
            TIMELINE: 'Zeitplan',
        },
    },
};
