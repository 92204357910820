import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharePointService } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
    FrameworkService,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ApplicationService } from '../../services/application.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';

import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module';
import { formatDate } from '@angular/common';

const Quill: any = QuillNamespace;
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-notes-assignment',
    templateUrl: './notes-assignment.component.html',
    styleUrls: ['./notes-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class NotesAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public placeHolder = '';
    private userName;
    public lastEntry: string;

    public editorConfig = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ align: [] }],

            ['clean'], // remove formatting button

            ['link', 'image', 'video'], // link and image, video
        ],
        imageResize: true,
    };

    public data = '';

    private sharePointItem;

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _frameworkService: FrameworkService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('NOTES');
        this.placeHolder = this._translateService.instant('PLACEHOLDER');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this._sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then(async (assignment) => {
                        this.userName = await this._sharePointService
                            .currentUser.FullName;
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        const { Notiz_geaendert_am, Notiz_geaendert_von } =
                            this.sharePointItem;

                        if (!this.sharePointItem.Notiz_geaendert_von) {
                            this.lastEntry = '';
                        } else {
                            this.lastEntry = this.getLastEntryFromBackend(
                                Notiz_geaendert_am,
                                Notiz_geaendert_von
                            );
                        }

                        if (this.sharePointItem.Notizen) {
                            this.data = this._frameworkService
                                .replaceAllSpecialChars(
                                    this.sharePointItem.Notizen
                                )
                                .split('#imgph#')
                                .join('<img src="data:image/');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    onSelectionChanged(event) {
        if (event.oldRange == null) {
            this.onFocus();
        }
        if (event.range == null) {
            this.onBlur();
        }
    }

    onContentChanged(event) {
        // console.log(event.html);
    }

    onFocus() {
        // console.log('On Focus');
    }

    onBlur() {
        // console.log('Blurred');
    }

    getLastEntryFromBackend(lastDate: string, lastUser: string) {
        return (
            this._translateService.instant('LASTCHANGE') +
            lastDate +
            ' (' +
            lastUser +
            ')'
        );
    }

    getCurrentDateTime() {
        const currentDate = new Date();
        let currentMinutes: number | string = currentDate.getMinutes();
        if (currentDate.getMinutes() - 10 < 0) {
            currentMinutes = '0' + currentDate.getMinutes();
        }
        const daytime = currentDate.getHours() + ':' + currentMinutes;
        const formatedDate = formatDate(currentDate, 'dd.MM.yyyy', 'de-DE');
        return formatedDate + ' ' + daytime;
    }

    getLastEntryText(userName) {
        return (
            this._translateService.instant('LASTCHANGE') +
            this.getCurrentDateTime() +
            ' (' +
            userName +
            ')'
        );
    }

    edit() {
        this._loadingService.show(
            this._translateService.instant('NOTIFICATIONS.SAVING'),
            this._translateService.instant('NOTIFICATIONS.WAIT')
        );

        let newFiledValues = {
            Notizen: null,
            Notiz_geaendert_am: null,
            Notiz_geaendert_von: null,
        };

        if (this.sharePointItem.Notizen != this.data) {
            this.lastEntry = this.getLastEntryText(
                this._sharePointService.currentUser.FullName
            );

            newFiledValues = {
                Notizen: null,
                Notiz_geaendert_am: this.getCurrentDateTime(),
                Notiz_geaendert_von:
                    this._sharePointService.currentUser.FullName,
            };

            if (this.data) {
                newFiledValues.Notizen = this.data
                    .split('<img src="data:image/')
                    .join('#imgph#');
            }

            this._sharePointService
                .updateItem({
                    id: this.sharePointItem.Id,
                    listTitle: 'Aufträge',
                    newFiledValues,
                })
                .then(() => {
                    this._sharePointService
                        .getItemById({
                            listTitle: 'Aufträge',
                            id: this.id,
                        })
                        .then((assignment) => {
                            this.sharePointItem = assignment;

                            // Success
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                        })
                        .catch((error) => {
                            this._loadingService.hide();
                            console.error(error);
                        });
                })
                .catch((error) => {
                    this._loadingService.hide();
                    console.error(error);

                    if (error.message.indexOf('Bytes') > -1) {
                        this._snackBarService.add(
                            this._translateService.instant(
                                'NOTIFICATIONS.ERRORBYTES'
                            ),
                            '',
                            {
                                duration: 10000,
                                panelClass: 'error-dialog',
                            }
                        );
                    }
                });
        } else {
            this._loadingService.hide();
            this._snackBarService.add(
                this._translateService.instant('NOTIFICATIONS.NOCHANGES'),
                '',
                {
                    duration: 4000,
                    panelClass: 'error-dialog',
                }
            );
        }
    }
}
