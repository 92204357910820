import { FremdkostenComponent } from './fremdkosten.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AngularFrameworkModule } from "sp-office365-framework";
import { FuseSharedModule, LayoutModule } from "sp-office365-layout";

@NgModule({
  declarations: [FremdkostenComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    LayoutModule,
    AngularFrameworkModule,
  ],
  exports: [FremdkostenComponent],
})
export class FremdkostenModule { }
