import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsWorkpackageSetTemplateComponent } from './details-workpackage-set-template.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TableTaskModule } from '../../../task/table-task/table-task.module';
import { FormioModule } from '@formio/angular';
import { TableTasktemplateModule } from '../../../tasktemplate/table-tasktemplate/table-tasktemplate.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DetailsWorkpackageSetTemplateComponent],
  imports: [
    CommonModule,
    AngularFrameworkModule,
    TranslateModule,
    MatTabsModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    TableTaskModule,
    FormioModule,
    TableTasktemplateModule,
    DragDropModule,
  ]
})
export class DetailsWorkpackageSetTemplateModule { }
