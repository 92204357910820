import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { ColumnTyp, SharePointService, ToolBarControlType } from 'sp-office365-framework';
import { fuseAnimations, FuseTranslationLoaderService, ListViewConfig, } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { TeamsService } from '../../services/teams.service';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-listview-requests',
    templateUrl: './listview-requests.component.html',
    styleUrls: ['./listview-requests.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ListviewRequestsComponent implements OnInit {
    public config: ListViewConfig;
    @ViewChild('listView', { static: true }) listViewComponent: ListViewComponent;

    public getOpenRequestsCamlQuery(teamId?: number) {
        if (teamId) {
            return new CamlBuilder()
                .Where()
                .BooleanField('Ist_Auftrag')
                .IsFalse()
                .And()
                .LookupField('Team')
                .Id()
                .EqualTo(teamId)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().BooleanField('Abgesagt').IsFalse(),
                        CamlBuilder.Expression().BooleanField('Abgesagt').IsNull()
                    ]
                )
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .And()
            .Any(
                [
                    CamlBuilder.Expression().BooleanField('Abgesagt').IsFalse(),
                    CamlBuilder.Expression().BooleanField('Abgesagt').IsNull()
                ]
            )
            .OrderBy('Title')
            .ToString();
    }

    public getOpenRequestsSearchCamlQuery(teamId?: number) {
        if (teamId) {
            return new CamlBuilder()
                .Where()
                .BooleanField('Ist_Auftrag')
                .IsFalse()
                .And()
                .LookupField('Team')
                .Id()
                .EqualTo(teamId)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().BooleanField('Abgesagt').IsFalse(),
                        CamlBuilder.Expression().BooleanField('Abgesagt').IsNull()
                    ]
                )
                .And()
                .TextField('Title')
                .Contains('<%searchText%>')
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .And()
            .Any(
                [
                    CamlBuilder.Expression().BooleanField('Abgesagt').IsFalse(),
                    CamlBuilder.Expression().BooleanField('Abgesagt').IsNull()
                ]
            )
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    get canceledRequestsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .And()
            .BooleanField('Abgesagt')
            .IsTrue()
            .OrderBy('Title')
            .ToString();
    }

    get canceledRequestsSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .And()
            .BooleanField('Abgesagt')
            .IsTrue()
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    get allRequestsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .OrderBy('Title')
            .ToString();
    }

    get allRequestsSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsFalse()
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _currentUserService: CurrentUserService,
        private router: Router,
        private teamsService: TeamsService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initConfig();
    }

    ngOnInit(): void {
        this.loadTeams();
    }

    initConfig() {
        const hasRootPermissions = this._sharepointService.currentUser.IsBusinessOwner ||
            this._sharepointService.currentUser.IsAdministrator ||
            this._currentUserService.isTeamProjectLeiter();
        this.config = {
            title: this._translateService.instant('LV_REQUEST.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_REQUEST.SEARCH_LABEL'
            ),
            icon: 'call_missed',
            add_icon: 'add',
            searchDebounceTime: 1000,
            openEditInDialog: false,
            onRowClick: (item) => {
                this.router.navigate(['requests/detail/basedata/' + item.ID], {
                    queryParamsHandling: 'merge',
                });
            },
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_REQUEST.OPEN'),
                    config: {
                        list: 'Anträge',
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        data: [],
                        toolbar: [{
                            title: '',
                            command: 'filterTeams',
                            type: ToolBarControlType.Dropdown,
                            placeholder: 'Filter by Team',
                            dropDownItems: []
                        }],
                        useMaterialDesign: true
                    },
                    camlQueryForSearch: this.getOpenRequestsSearchCamlQuery(),
                },
                {
                    title: this._translateService.instant(
                        'LV_REQUEST.CANCELED'
                    ),
                    config: {
                        list: 'Anträge',
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.canceledRequestsCamlQuery,
                    },
                    camlQueryForSearch: this.canceledRequestsSearchCamlQuery,
                },
                {
                    title: this._translateService.instant('LV_REQUEST.ALL'),
                    config: {
                        list: 'Anträge',
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.allRequestsCamlQuery,
                    },
                    camlQueryForSearch: this.allRequestsSearchCamlQuery,
                },
                {
                    title: this._translateService.instant('LV_REQUEST.TEAMS'),
                    config: {
                        list: 'Anträge',
                        groups: [{ field: 'Team' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Team',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.TEAM'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_REQUEST.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getOpenRequestsCamlQuery(),
                    },
                    camlQueryForSearch: this.getOpenRequestsSearchCamlQuery(),
                },
            ],
        };
    }

    public toolbarButtonHandler(event: {
        e: any;
        command: string;
        selectedItems: any[];
        dataItem: any;
        latestParentItem?: any;
    }): void {
        if (event.command === 'filterTeams') {
            const teamId = event.e.value;

            if (this.listViewComponent.listComponent.currentTableConfig.title === this.config.tableConfigs[0].title) {
                (this.config.tableConfigs[0].config.toolbar[0] as any).value = teamId;
                this.config.tableConfigs[0].config.camlQuery = this.getOpenRequestsCamlQuery(teamId);
                this.config.tableConfigs[0].camlQueryForSearch = this.getOpenRequestsSearchCamlQuery(teamId);
            }
            this.listViewComponent.listComponent.sharePointTableComponent.updateCamlQuery();
            this.listViewComponent.listComponent.sharePointTableComponent.refresh();
        }
    }

    public async loadTeams(): Promise<void> {
        const teams = await this.teamsService.loadAllTeams();
        let filterById: number;

        if (teams.length) {
            const _teams = teams.map(team => ({ label: team.Title, value: team.Id }));

            this.config.tableConfigs[0].config.toolbar = [{
                title: '',
                command: 'filterTeams',
                type: ToolBarControlType.Dropdown,
                placeholder: 'Filter by Team',
                value: teams[0].Id,
                dropDownItems: [{ label: 'All', value: null }, ..._teams]
            }];

            const teamManagerTeam = teams.find(team => team.isTeamManager);

            if (teamManagerTeam) {
                filterById = teamManagerTeam.Id;
            }
        }

        this.toolbarButtonHandler({
            command: 'filterTeams',
            e: { value: filterById },
            dataItem: null,
            selectedItems: []
        });
    }
}
