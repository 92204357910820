import { NgModule } from '@angular/core';
import { UpdateComponent } from './update.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NewTenantMigrationComponent } from './new-tenant-migration/new-tenant-migration.component';

const routes = [
    {
        path: 'administration/update',
        component: UpdateComponent,
    },
    {
        path: 'administration/new-tenant-update',
        component: NewTenantMigrationComponent,
    },
];

@NgModule({
    declarations: [UpdateComponent, NewTenantMigrationComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
    ],
    exports: [UpdateComponent],
})
export class UpdateModule {}
