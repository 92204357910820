import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    onItemsChanged: BehaviorSubject<any>;
    onItemsDeleted: BehaviorSubject<any>;
    onSelectedItemsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    items: any[];
    user: any;
    selectedItems: number[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private sharePointService: SharePointService) {
        // Set the defaults
        this.onItemsChanged = new BehaviorSubject([]);
        this.onItemsDeleted = new BehaviorSubject([]);
        this.onSelectedItemsChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    /**
     * Toggle selected item by id
     *
     * @param id
     */
    toggleSelectedItem(id): void {
        // First, check if we already have that item as selected...
        if (this.selectedItems.length > 0) {
            const index = this.selectedItems.indexOf(id);

            if (index !== -1) {
                this.selectedItems.splice(index, 1);

                // Trigger the next event
                this.onSelectedItemsChanged.next(this.selectedItems);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedItems.push(id);

        // Trigger the next event
        this.onSelectedItemsChanged.next(this.selectedItems);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedItems.length > 0) {
            this.deselectItems();
        } else {
            this.selectItems();
        }
    }

    /**
     * Select items
     *
     * @param filterParameter
     * @param filterValue
     */
    selectItems(filterParameter?, filterValue?): void {
        this.selectedItems = [];

        // If there is no filter, select all items
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedItems = [];
            this.items.map((contact) => {
                this.selectedItems.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedItemsChanged.next(this.selectedItems);
    }

    /**
     * Deselect items
     */
    deselectItems(): void {
        this.selectedItems = [];

        // Trigger the next event
        this.onSelectedItemsChanged.next(this.selectedItems);
    }

    /**
     * Delete selected items
     */
    deleteSelectedItems(table, listName): void {
        for (const itemId of this.selectedItems) {
            this.sharePointService
                .recycleItem({ listTitle: listName, id: itemId })
                .then(() => {
                    // this.loading = false;
                    table.refresh();
                });
        }
        this.onItemsChanged.next(this.items);
        this.onItemsDeleted.next(this.items);
        this.deselectItems();
    }
}
