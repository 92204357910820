import { TableTasksetModule } from './../../taskset/table-taskset/table-taskset.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableInvoiceComponent } from './table-invoice.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TableTasksetComponent } from '../../taskset/table-taskset/table-taskset.component';
import { BillsAttachmentsPopupComponent } from './bills-attachments-popup/bills-attachments-popup.component';

@NgModule({
    declarations: [TableInvoiceComponent, BillsAttachmentsPopupComponent],
    imports: [CommonModule, AngularFrameworkModule, TableTasksetModule],
    exports: [TableInvoiceComponent]
})
export class TableInvoiceModule {}
