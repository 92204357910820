import {
    Component,
    OnInit,
    EventEmitter,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';

import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { ApplicationService } from '../../services/application.service';
import * as CamlBuilder from 'camljs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-detail-contact',
    templateUrl: './detail-contact.component.html',
    styleUrls: ['./detail-contact.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailContactComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public id;
    public customerItem;
    public sharePointItem;
    public formioConfiguration;
    public isCreateMode = true;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailCustomerComponent>,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _loadingService: LoadingService,
        private _snackbarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data.contactId) {
            this.id = this.data.contactId;
        }

        if (this.data.customer) {
            this.customerItem = this.data.customer;
        }

        if (this.id) {
            this.isCreateMode = false;

            this._sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Kontakte',
                })
                .then((contact) => {
                    this.sharePointItem = contact;

                    this.initForm();
                })
                .catch((error) => console.error(error));
        } else {
            this.initForm();
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
            if (this.customerItem && this.customerItem.Strasse) {
                dataForm.Strasse = this.customerItem.Strasse;
            }
            if (this.customerItem && this.customerItem.WorkZip) {
                dataForm.WorkZip = this.customerItem.WorkZip;
            }
            if (this.customerItem && this.customerItem.WorkCity) {
                dataForm.WorkCity = this.customerItem.WorkCity;
            }
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_CONTACT.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('DETAIL_CONTACT.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        // Build WorkAddress
        if (e.data.Strasse || e.data.WorkZip || e.data.WorkCity) {
            let street = '';
            let zip = '';
            let city = '';

            if (e.data.Strasse) {
                street = e.data.Strasse;
            }
            if (e.data.WorkZip) {
                zip = e.data.WorkZip;
            }
            if (e.data.WorkCity) {
                city = e.data.WorkCity;
            }

            e.data.WorkAddress = street + ', ' + zip + '-' + city;
        }

        return from(
            new Promise<void>((resolve, reject) => {
                const promises = [];

                if (this.id) {
                    promises.push(
                        this._sharepointService.updateItem({
                            id: this.id,
                            listTitle: 'Kontakte',
                            newFiledValues: e.data,
                        })
                    );
                } else {
                    promises.push(
                        this._sharepointService.addItem({
                            listTitle: 'Kontakte',
                            data: e.data,
                            folder: '',
                        })
                    );
                }

                Promise.all(promises)
                    .then((result) => {
                        this._loadingService.hide();

                        if (!this.id && result) {
                            this.id = +result;
                        }

                        this._snackbarService.add(
                            this._translateService.instant(
                                'DETAIL_CONTACT.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close({ Success: true, ItemId: this.id });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CONTACT.FORM.SALUTATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'SalutationId',
                                    selectThreshold: 0.3,
                                    list: 'Anreden',
                                    isPeoplePicker: false,
                                    removable: true,
                                    // validate: {
                                    //     customMessage:
                                    //         '{{field}} ist ein Pflichtfeld',
                                    //     required: true,
                                    // },
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isCreateMode: this.isCreateMode,
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column2',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CONTACT.FORM.FIRSTNAME'
                                    ),
                                    type: 'textfield',
                                    key: 'FirstName',
                                    inputFormat: 'plain',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CONTACT.FORM.TITLE'
                                    ),
                                    type: 'textfield',
                                    key: 'Title',
                                    inputFormat: 'plain',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column2',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.EMAIL'
                    ),
                    type: 'textfield',
                    key: 'Email',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.JOBTITLE'
                    ),
                    type: 'textfield',
                    key: 'JobTitle',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.WORKPHONE'
                    ),
                    type: 'textfield',
                    key: 'WorkPhone',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.HOMEPHONE'
                    ),
                    type: 'textfield',
                    key: 'HomePhone',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.CELLPHONE'
                    ),
                    type: 'textfield',
                    key: 'CellPhone',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.WORKFAX'
                    ),
                    type: 'textfield',
                    key: 'WorkFax',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.STREET'
                    ),
                    type: 'textfield',
                    key: 'Strasse',
                    inputFormat: 'plain',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CONTACT.FORM.ZIP'
                                    ),
                                    type: 'textfield',
                                    key: 'WorkZip',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CONTACT.FORM.CITY'
                                    ),
                                    type: 'textfield',
                                    key: 'WorkCity',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column2',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.WEBPAGE'
                    ),
                    type: 'textfield',
                    key: 'WebPage',
                    inputFormat: 'plain'
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.CUSTOMER'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'KundeId',
                    selectThreshold: 0.3,
                    list: 'Firmen',
                    isPeoplePicker: false,
                    removable: true,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    isCreateMode: this.isCreateMode,
                    setValue: this.customerItem.Id,
                    disabled: true,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CONTACT.FORM.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
