import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectEvaluationTableComponent } from './project-evaluation-table/project-evaluation-table.component';
import { ProjectEvaluationFilterComponent } from './project-evaluation-filter/project-evaluation-filter.component';
import { Route, RouterModule } from '@angular/router';
import { ProjectEvaluationComponent } from './project-evaluation/project-evaluation.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { GridModule } from '@progress/kendo-angular-grid';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

const routes: Route[] = [
    {
        path: 'project-evaluation',
        component: ProjectEvaluationComponent,
    },
];

@NgModule({
    declarations: [ProjectEvaluationTableComponent, ProjectEvaluationFilterComponent, ProjectEvaluationComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        AngularFrameworkModule,
        TranslateModule,
        ExcelExportModule,
        GridModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class ProjectEvaluationModule {
}
