import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations, FuseTranslationLoaderService } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ColumnTyp, FormatTypes, SharePointService } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { forkJoin, from, Observable } from 'rxjs';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { delay, map, switchMap } from 'rxjs/operators';
import { LoadingService } from 'src/app/main/services/loading.service';
import { SnackbarService } from 'src/app/main/services/snackbar.service';
import { Bill } from '../../shared/models';
import { TimeService } from '../../services/time.service';
import { PartiallyFinishedServicesService } from './partially-finished-services.service';
import { PROJECT_EVALUATION_HEADER_FORMAT } from '../../project-evaluation/project-evaluation.constant';

@Component({
  selector: 'app-partially-finished-services',
  templateUrl: './partially-finished-services.component.html',
  styleUrls: ['./partially-finished-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PartiallyFinishedServicesComponent implements OnInit {
  public sliderValue = 0.8;
  public data = [];
  public columns1 = [];
  public currencyFormat = '_(€ #,##0.00_);_(€ -#,##0.00;_(@_)';
  public PROJECT_EVALUATION_HEADER_FORMAT = PROJECT_EVALUATION_HEADER_FORMAT;
  @ViewChild('export1', { static: false }) export1: ExcelExportComponent;
  @ViewChild('export2', { static: false }) export2: ExcelExportComponent;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translationService: TranslateService,
    private sharePointService: SharePointService,
    private _loadingService: LoadingService,
    private _snackBarService: SnackbarService,
    private _timeService: TimeService,
    private _partiallyFinishedServicesService: PartiallyFinishedServicesService,
    private cdr: ChangeDetectorRef
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit(): void {
    this.initColumns1();
  }

  public exportPartiallyFinishedServices(): void {
    this._loadingService.show(
      this._translationService.instant('DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'),
      ''
    );
    this.data = [];
    this._partiallyFinishedServicesService.exportPartiallyFinishedServices(this.sliderValue)
      .subscribe(data => {
        this.data = data;
        this.cdr.markForCheck();
        this._loadingService.hide();

        if (!this.data.length) {
          this._snackBarService.add(
            this._translationService.instant('NO_PROJECTS'),
            null,
            { duration: 5000, verticalPosition: 'bottom', horizontalPosition: 'center' }
          );
          return;
        }

        setTimeout(() => {
          const options = this.export1.workbookOptions();
          const rows = options.sheets[0].rows;
          rows.forEach((row) => {
            if (row.type === 'header') {
              row.height = 70;
            }

            if (row.type === 'data' && (row.cells[12]?.value > row.cells[6]?.value)) {
              row.cells[12].background = '#ff8787';
              row.cells[13].background = '#ff8787';
            }
          });
          this.export1.save(options);
        }, 1000);
      });
  }

  public exportFeeNotChargedProjects(): void {
    this._loadingService.show(
      this._translationService.instant('DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'),
      ''
    );
    this.data = [];

    this._partiallyFinishedServicesService.exportFeeNotChargedProjects().subscribe((data) => {
      this.data = data;
      this.cdr.markForCheck();
      this._loadingService.hide();

      if (!this.data.length) {
        this._snackBarService.add(
          this._translationService.instant('NO_PROJECTS'),
          null,
          { duration: 5000, verticalPosition: 'bottom', horizontalPosition: 'center' }
        );
        return;
      }

      setTimeout(() => this.export2.save(), 1000);
    });
  }

  private initColumns1(): void {
    this.columns1 = [
      {
        internalName: 'Projektnummer',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.PROJECTNUMBER'),
        hidden: false,
        type: ColumnTyp.Text,
      },
      {
        internalName: 'Title',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.TITLE'),
        hidden: false,
        type: ColumnTyp.Text,
      },
      {
        internalName: 'OW_Nummer',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.OWNUMBER'),
        hidden: false,
        type: ColumnTyp.Text
      },
      {
        internalName: 'Auftragsstart',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.START'),
        hidden: false,
        type: ColumnTyp.Date,
        format: FormatTypes.yyyyMMdd,
        width: '100px'
      },
      {
        internalName: 'Auftragsende',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.END'),
        hidden: false,
        type: ColumnTyp.Date,
        format: FormatTypes.yyyyMMdd,
        width: '100px'
      },
      {
        internalName: 'StartEndDateDifference',
        title: 'Laufzeit [Monate]',
        hidden: false,
        type: ColumnTyp.Date,
        format: FormatTypes.yyyyMMdd,
        width: '100px'
      },
      {
        internalName: 'Antragssumme',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.TOTLALSUM'),
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: 'Gewinnmarge',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.EXPECTEDPROFITMARGIN'),
        hidden: false,
        type: ColumnTyp.Text
      },
      {
        internalName: 'NebenkostenEuro',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.ADDITIONAL_EURO'),
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: 'Auftragsbudget',
        title: 'interne Auftragsumme',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: 'Fremdleistung',
        title: 'Gesamte Fremdleistungen',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_Bills.Internal',
        title: 'Rechnung intern',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_Bills.External',
        title: 'Rechnung extern',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_Bills.Total',
        title: 'Rechnungen  (intern/extern)',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_RemainingFee',
        title: 'Resthonorar (intern/extern)',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_TotalHours',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.TOTAL_HOURS'),
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_WorkInProgressCost',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.WORK_IN_PROGRESS_COST'),
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_InvoiceWithFactor',
        title: 'erbrachte Leistung > Rechnungen korrigiert mit Faktor (default 0,8)',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_ServicesTotalPrice',
        title: 'teilfertige Leistungen',
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: '_WIP',
        title: this._translationService.instant('PARTIALLY_FINISHED_SERVICES.COLUMNS.WIP'),
        hidden: false,
        type: ColumnTyp.Text,
        currency: true
      },
      {
        internalName: 'FieldValuesAsText.Team',
        title: 'Team   ',
        hidden: false,
        type: ColumnTyp.Text,
        width: '200px'
      },
      {
        internalName: 'FieldValuesAsText.Auftragsleiter',
        title: 'PL',
        hidden: false,
        type: ColumnTyp.Text,
      }
    ];
  }
}
