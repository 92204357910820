import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnTyp, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from 'src/app/main/services/task.service';
import { LoadingService } from 'src/app/main/services/loading.service';
import { FuseTranslationLoaderService, SnackbarService } from 'sp-office365-layout';
import { locale as english } from 'src/app/main/work-package-template/components/i18n/en';
import { locale as german } from 'src/app/main/work-package-template/components/i18n/de';

@Component({
    selector: 'app-import-workpackage',
    templateUrl: './import-workpackage.component.html',
    styleUrls: ['./import-workpackage.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImportWorkpackageComponent implements OnInit {
    public tableWorkpackageConfig: TableConfiguration;
    @ViewChild('table', { static: false }) table: SharePointTableComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { auftragsId: number },
        public matDialogRef: MatDialogRef<ImportWorkpackageComponent>,
        private _translateService: TranslateService,
        private _taskService: TaskService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.initTableConfig();
    }

    public importWorkpackage(): void {
        this._loadingService.show(
            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.SAVING'),
            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.WAIT')
        );
        this._taskService.importWorkpackages(this.table.allSelectedItemsIDs, this.data.auftragsId)
            .then(() => {
                this._loadingService.hide();
                this._snackBarService.add(
                    this._translateService.instant(
                        'WORKPACKAGE.FORM.NOTIFICATIONS.SUCCESSITEM'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
                this.matDialogRef.close();
            });
    }

    private initTableConfig(): void {
        this.tableWorkpackageConfig = {
            list: 'Arbeitspaketesets',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('WORKPACKAGE.FORM.COLUMNS.TITLE'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Aufgabensets',
                    title: this._translateService.instant('WORKPACKAGE.FORM.COLUMNS.AUFGABENSETS'),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: true,
            checkboxColumnShowSelectAll: true,
            recursiveAll: true,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString(),
            useMaterialDesign: true,
        };
    }

}
