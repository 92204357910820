export const locale = {
    lang: 'de',
    data: {
        DETAIL_CATEGORY: {
            TITLE: 'Kategorien',
            NEW: 'Neue Kategorie',
            NEWDOCUMENT: 'Neues Dokument',
            EDIT: 'Kategorie bearbeiten',
            BASE: 'Stammdaten',
            SEARCH_LABEL: 'Nach einer Kategorie suchen',
            DOCUMENTS: 'Dokumente',
            COLUMNS: {
                TITLE: 'Name',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
            },
            NOTIFICATIONS: {
                SAVING: 'Kategorie wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Kategorie erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
            },
            SAVE: 'Speichern',
        },
    },
};
