import { Injectable } from '@angular/core';
import CamlBuilder from 'camljs';
import { SharePointService } from 'sp-office365-framework';
import { Team } from 'src/app/main/shared/models';

@Injectable({
    providedIn: 'root'
})
export class TeamsService {
    public allTeams: Team[] = [];

    constructor(
        private _sharepointService: SharePointService
    ) {
    }

    public async loadAllTeams(): Promise<Team[]> {
        if (this.allTeams.length) {
            return this.allTeams;
        }

        this.allTeams = await this._sharepointService.getListItems({
            folderName: '',
            title: 'Teams',
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString(),
            isDocumentLibrary: false
        });

        this.allTeams.forEach(team => team.isTeamManager = team.TeamleiterId === this._sharepointService.currentUser.Id);
        return this.allTeams;
    }
}
