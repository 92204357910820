import { IList } from "@pnp/sp/presets/all";
import {
  FieldType,
  SharePointService,
  UpdateService,
} from "sp-office365-framework";

export class UpdateToVersion1110Component {
  constructor(
    private _sharePointService: SharePointService,
    private _updateService: UpdateService
  ) {}

  run() {
    const promise = new Promise<void>(async (resolve, reject) => {
      let externalCosts: IList;

      const promises = [
        await this._updateService.addList("Fremdkosten", "", 100, {
          EnableVersioning: true,
        }),

        await new Promise<void>((resolve) => {
          externalCosts =
            this._sharePointService.web.lists.getByTitle("Fremdkosten");
          resolve();
        }),

        await this._updateService.addField(
          externalCosts,
          "Kosten",
          FieldType.Number
        ),

        await this._updateService.addField(
          externalCosts,
          "Rechnungsdatum",
          FieldType.Date
        ),
      ];

      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
    return promise;
  }
}
