export const locale = {
    lang: 'de',
    data: {
        POPUPHEADER: 'Aufgaben im Arbeitspaket',
        ASSIGNED: 'Zugewiesen an',
        DUEDATE: 'Fälligkeitsdatum',
        TASKSWITHOUTWORKPACKAGE: 'Aufgaben ohne Arbeitspaket',
        TASKMOVED: 'Aufgabe verschoben!',
        ERRORONMOVINGTASK: 'Fehler beim Verschieben der Aufgabe!',
        ERRORONMOVINGTASKBETWEENSWIMLANE:
            ' Das Verschieben von Aufgaben zwischen Swimlanes ist deaktiviert!',
    },
};
