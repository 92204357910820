import { MessageService as KendoMessageService } from '@progress/kendo-angular-l10n';

const data = {
    de: {
        rtl: false,
        messages: {
            'kendo.grid.groupPanelEmpty':
                'Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren',
            'kendo.grid.noRecords': 'Keine Datensätze verfügbar.',
            'kendo.grid.pagerFirstPage': 'Zur ersten Seite',
            'kendo.grid.pagerPreviousPage': 'Zur vorherigen Seite',
            'kendo.grid.pagerNextPage': 'Zur nächsten Seite',
            'kendo.grid.pagerLastPage': 'Zur letzten Seite',
            'kendo.grid.pagerPage': 'Seite',
            'kendo.grid.pagerOf': 'von',
            'kendo.grid.pagerItems': 'Elementen',
            'kendo.grid.pagerItemsPerPage': 'Elemente pro Seite',
            'kendo.grid.filter': 'Filter',
            'kendo.grid.filterEqOperator': 'ist gleich',
            'kendo.grid.filterNotEqOperator': 'ist nicht gleich',
            'kendo.grid.filterIsNullOperator': 'ist Null',
            'kendo.grid.filterIsNotNullOperator': 'ist nicht Null',
            'kendo.grid.filterIsEmptyOperator': 'ist leer',
            'kendo.grid.filterIsNotEmptyOperator': 'ist nicht leer',
            'kendo.grid.filterStartsWithOperator': 'beginnt mit',
            'kendo.grid.filterContainsOperator': 'beinhaltet',
            'kendo.grid.filterNotContainsOperator': 'beinhaltet nicht',
            'kendo.grid.filterEndsWithOperator': 'endet mit',
            'kendo.grid.filterGteOperator': 'ist größer als oder gleich',
            'kendo.grid.filterGtOperator': 'ist größer als',
            'kendo.grid.filterLteOperator': 'ist kleiner oder gleich als',
            'kendo.grid.filterLtOperator': 'ist kleiner als',
            'kendo.grid.filterIsTrue': 'ist richtig',
            'kendo.grid.filterIsFalse': 'ist falsch',
            'kendo.grid.filterBooleanAll': '(Alle)',
            'kendo.grid.filterAfterOrEqualOperator':
                'ist gleich oder später als',
            'kendo.grid.filterAfterOperator': 'ist später als',
            'kendo.grid.filterBeforeOperator': 'ist früher als',
            'kendo.grid.filterBeforeOrEqualOperator':
                'ist gleich oder früher als',
            'kendo.grid.filterFilterButton': 'Filtern',
            'kendo.grid.filterClearButton': 'Löschen',
            'kendo.grid.filterAndLogic': 'und',
            'kendo.grid.filterOrLogic': 'oder',
            'kendo.grid.loading': 'Laden',
            'kendo.grid.sort': 'Sortieren',
            'kendo.grid.columnMenu': 'Spaltenmenü',
            'kendo.grid.columns': 'Spalten',
            'kendo.grid.lock': 'Sperren',
            'kendo.grid.unlock': 'Entsperren',
            'kendo.grid.sortAscending': 'Aufsteigend sortieren',
            'kendo.grid.sortDescending': 'Absteigend sortieren',
            'kendo.grid.columnsApply': 'Übernehmen',
            'kendo.grid.columnsReset': 'Zurücksetzen',
        },
    },
    en: {
        rtl: false,
        messages: {
            'kendo.grid.groupPanelEmpty':
                'Drag a column header and drop it here to group by that column',
            'kendo.grid.noRecords': 'No records available.',
            'kendo.grid.pagerFirstPage': 'Go to the first page',
            'kendo.grid.pagerPreviousPage': 'Go to the previous page',
            'kendo.grid.pagerNextPage': 'Go to the next page',
            'kendo.grid.pagerLastPage': 'Go to the last page',
            'kendo.grid.pagerPage': 'Page',
            'kendo.grid.pagerOf': 'of',
            'kendo.grid.pagerItems': 'items',
            'kendo.grid.pagerItemsPerPage': 'items per page',
            'kendo.grid.filter': 'Filter',
            'kendo.grid.filterEqOperator': 'Is equal to',
            'kendo.grid.filterNotEqOperator': 'Is not equal to',
            'kendo.grid.filterIsNullOperator': 'Is null',
            'kendo.grid.filterIsNotNullOperator': 'Is not null',
            'kendo.grid.filterIsEmptyOperator': 'Is empty',
            'kendo.grid.filterIsNotEmptyOperator': 'Is not empty',
            'kendo.grid.filterStartsWithOperator': 'Starts with',
            'kendo.grid.filterContainsOperator': 'Contains',
            'kendo.grid.filterNotContainsOperator': 'Does not contain',
            'kendo.grid.filterEndsWithOperator': 'Ends with',
            'kendo.grid.filterGteOperator': 'Is greater than or equal to',
            'kendo.grid.filterGtOperator': 'Is greater than',
            'kendo.grid.filterLteOperator': 'Is less than or equal to',
            'kendo.grid.filterLtOperator': 'Is less than',
            'kendo.grid.filterIsTrue': 'is true',
            'kendo.grid.filterIsFalse': 'is false',
            'kendo.grid.filterBooleanAll': '(All)',
            'kendo.grid.filterAfterOrEqualOperator': 'Is after or equal to',
            'kendo.grid.filterAfterOperator': 'Is after',
            'kendo.grid.filterBeforeOperator': 'Is before',
            'kendo.grid.filterBeforeOrEqualOperator': 'Is before or equal to',
            'kendo.grid.filterFilterButton': 'Filter',
            'kendo.grid.filterClearButton': 'Clear',
            'kendo.grid.filterAndLogic': 'And',
            'kendo.grid.filterOrLogic': 'Or',
            'kendo.grid.loading': 'Loading',
            'kendo.grid.sort': 'Sort',
            'kendo.grid.columnMenu': 'Column Menu',
            'kendo.grid.columns': 'Columns',
            'kendo.grid.lock': 'Lock',
            'kendo.grid.unlock': 'Unlock',
            'kendo.grid.sortAscending': 'Sort Ascending',
            'kendo.grid.sortDescending': 'Sort Descending',
            'kendo.grid.columnsApply': 'Apply',
            'kendo.grid.columnsReset': 'Reset',
        },
    },
};

export class CustomMessageService extends KendoMessageService {
    public set language(value: string) {
        const lang = data[value];
        if (lang) {
            this.localeId = value;
            this.notify(lang.rtl);
        }
    }

    public get language(): string {
        return this.localeId;
    }

    private localeId = 'en';
    private get messages(): any {
        const lang = data[this.localeId];

        if (lang) {
            return lang.messages;
        }
    }

    public get(key: string): string {
        return this.messages[key];
    }
}
