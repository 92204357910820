import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { Assignment } from '../shared/models';
import { BillingsService } from './billings.service';
import { OverviewBudgetChartModel } from '../budget/overview-budget/overview-budget-chart.model';
import { OverviewBudgetChartEnum } from '../budget/overview-budget/overview-budget-chart.enum';

@Injectable({
    providedIn: 'root',
})
export class BudgetService {
    public ready = false;
    public currentCosts = 0;
    public currentMinutes = 0;
    public progress = 0;
    public billsSumProgress = 0;
    public sumAfterBills = 0;
    public widgets = [];

    constructor(
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _billingsService: BillingsService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.initChartData();
    }

    updateBudget(assignment: Assignment) {
        this.ready = false;

        if (assignment && assignment.Auftragsbudget) {
            const nebenkosten = assignment.Nebenkosten ?? 0;
            const gewinnmarge = assignment.Gewinnmarge ?? 0;
            const budget =
                assignment.Auftragsbudget -
                (gewinnmarge / 100) *
                    (assignment.Antragssumme +
                        assignment.Antragssumme * (nebenkosten / 100));

            if (budget > 0) {
                this.progress = (this.currentCosts / budget) * 100;
            } else {
                this.progress = 100;
            }

            if (assignment.Antragssumme > 0) {
                this.billsSumProgress =
                    // Percentage
                    (this._billingsService.totalBillsSum /
                        (assignment.Antragssumme + nebenkosten)) *
                    100;
            } else {
                this.billsSumProgress = 100;
            }
        }

        this.progress = isNaN(this.progress) ? 0 : this.progress;
        this.billsSumProgress = isNaN(this.billsSumProgress)
            ? 0
            : this.billsSumProgress;

        // Reste widgets
        this.initChartData();

        this.updateChart(
            this.widgets[0].devices,
            this.widgets[0].scheme.domain,
            this.progress,
            'BUDGET_SERVICE.BOOKED',
            'BUDGET_SERVICE.OPEN'
        );
        this.updateChart(
            this.widgets[1].devices,
            this.widgets[1].scheme.domain,
            this.billsSumProgress,
            'BUDGET_SERVICE.TOTALSUM_BILL',
            'BUDGET_SERVICE.TOTALSUMLEFT_BILL'
        );
        this.ready = true;
    }

    private updateChart(
        devices: OverviewBudgetChartModel[],
        domains: string[],
        progress,
        translationKey1: string,
        translationKey2: string
    ): void {
        devices.push({
            name: this._translateService.instant(translationKey1),
            value: progress,
        });

        if (progress < 100) {
            domains.push('#9adf66'); // green
            domains.push('#c2c5c0');
            domains.push('#89a9f4');

            devices.push({
                name: this._translateService.instant(translationKey2),
                value: 100 - progress,
            });
        } else {
            domains.push('#d61b1b'); // red
            domains.push('#c2c5c0');
            domains.push('#89a9f4');

            devices.push({
                name: this._translateService.instant(translationKey2),
                value: 0,
            });
        }
    }

    private initChartData(): void {
        this.widgets = [
            {
                id: OverviewBudgetChartEnum.BUDGET,
                label: 'OV_BUDGET.BOOKEDBUDGETINPERCENT',
                scheme: {
                    domain: [],
                },
                devices: [],
            },
            {
                id: OverviewBudgetChartEnum.BILL,
                label: 'OV_BUDGET.BUDGETBILLWDIGETTITLE',
                scheme: {
                    domain: [],
                },
                devices: [],
            },
        ];
    }
}
