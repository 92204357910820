import { NgModule } from '@angular/core';
import { TasksAssignmentComponent } from './tasks-assignment.component';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { KanbanModule } from '../../kanban/kanban.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
    {
        path: 'projects/detail/tasks',
        component: TasksAssignmentComponent,
    },
    {
        path: 'projects/detail/tasks/:id',
        component: TasksAssignmentComponent,
    },
];

@NgModule({
    declarations: [TasksAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        MatCardModule,
        AngularFrameworkModule,
        TableTaskModule,
        KanbanModule,
    ],
    exports: [TasksAssignmentComponent],
})
export class TasksAssignmentModule {}
