import { LookupService } from './lookup.service';
import { SharePointService } from 'sp-office365-framework';
import { Injectable } from '@angular/core';
import * as CamlBuilder from 'camljs';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { formatDate } from '@angular/common';
import { TimeService } from 'src/app/main/services/time.service';
import { forkJoin, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    public startDate: Date;
    public endDate: Date;
    public userIds: number[] = [];
    public projectIds: number[] = [];
    public currentListView: ListViewComponent;
    public searchByAllTeamLeiterUsers: boolean;
    public cancelLoadItems$ = new Subject();

    public getFilteredTimesCamlQuery(startDate: Date, endDate: Date) {
        // If a location/team was choosen, and this location/team is set to no project
        if (
            (this._lookUpService.location ||
                this._lookUpService.teams.length) &&
            !this.projectIds.length
        ) {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .ToString();
        }
        if (this.startDate && this.endDate) {
            if (this.userIds.length > 0) {
                if (this.projectIds.length > 0) {
                    // Auf Zeitraum, User und Projekt filtern
                    return new CamlBuilder()
                        .Where()
                        .LookupField('Projekt')
                        .Id()
                        .In(this.projectIds)
                        .And()
                        .DateField('Created')
                        .GreaterThan(startDate)
                        .And()
                        .DateField('Created')
                        .LessThanOrEqualTo(endDate)
                        .And()
                        .DateField('Datum')
                        .GreaterThan(this.startDate)
                        .And()
                        .DateField('Datum')
                        .LessThanOrEqualTo(this.endDate)
                        .And()
                        .UserField('Author')
                        .Id()
                        .In(this.userIds)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .OrderByDesc('Modified')
                        .ToString();
                } else {
                    // Auf Zeitraum und User filtern
                    return new CamlBuilder()
                        .Where()
                        .UserField('Author')
                        .Id()
                        .In(this.userIds)
                        .And()
                        .DateField('Created')
                        .GreaterThan(startDate)
                        .And()
                        .DateField('Created')
                        .LessThanOrEqualTo(endDate)
                        .And()
                        .DateField('Datum')
                        .GreaterThan(this.startDate)
                        .And()
                        .DateField('Datum')
                        .LessThanOrEqualTo(this.endDate)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .OrderByDesc('Modified')
                        .ToString();
                }
            } else if (this.projectIds.length > 0) {
                // Auf Zeitraum und Projekt filtern
                return new CamlBuilder()
                    .Where()
                    .LookupField('Projekt')
                    .Id()
                    .In(this.projectIds)
                    .And()
                    .DateField('Created')
                    .GreaterThan(startDate)
                    .And()
                    .DateField('Created')
                    .LessThanOrEqualTo(endDate)
                    .And()
                    .DateField('Datum')
                    .GreaterThan(this.startDate)
                    .And()
                    .DateField('Datum')
                    .LessThanOrEqualTo(this.endDate)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Modified')
                    .ToString();
            } else {
                // Auf Zeitraum filtern
                return new CamlBuilder()
                    .Where()
                    .DateField('Datum')
                    .GreaterThan(this.startDate)
                    .And()
                    .DateField('Datum')
                    .LessThanOrEqualTo(this.endDate)
                    .And()
                    .DateField('Created')
                    .GreaterThan(startDate)
                    .And()
                    .DateField('Created')
                    .LessThanOrEqualTo(endDate)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Modified')
                    .ToString();
            }
        } else if (this.userIds.length > 0) {
            if (this.projectIds.length > 0) {
                // Auf User und Projekt Filtern
                return new CamlBuilder()
                    .Where()
                    .LookupField('Projekt')
                    .Id()
                    .In(this.projectIds)
                    .And()
                    .DateField('Created')
                    .GreaterThan(startDate)
                    .And()
                    .DateField('Created')
                    .LessThanOrEqualTo(endDate)
                    .And()
                    .UserField('Author')
                    .Id()
                    .In(this.userIds)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Modified')
                    .ToString();
            } else {
                // Auf User filtern
                return new CamlBuilder()
                    .Where()
                    .UserField('Author')
                    .Id()
                    .In(this.userIds)
                    .And()
                    .DateField('Created')
                    .GreaterThan(startDate)
                    .And()
                    .DateField('Created')
                    .LessThanOrEqualTo(endDate)
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Modified')
                    .ToString();
            }
        } else if (this.projectIds.length > 0) {
            // Auf Projekt Filtern
            return new CamlBuilder()
                .Where()
                .LookupField('Projekt')
                .Id()
                .In(this.projectIds)
                .And()
                .DateField('Created')
                .GreaterThan(startDate)
                .And()
                .DateField('Created')
                .LessThanOrEqualTo(endDate)
                .And()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderByDesc('Modified')
                .ToString();
        } else {
            // Keine Ergebnisse
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .And()
                .NumberField('FSObjType')
                .EqualTo(0)
                .OrderByDesc('Modified')
                .ToString();
        }
    }

    get allCustomersSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .TextField('Title')
            .Contains('<%searchText%>')
            .And()
            .NumberField('FSObjType')
            .EqualTo(0)
            .OrderByDesc('Modified')
            .ToString();
    }

    getProjectIdsFromLookUp(
        id: number,
        lookupField: string
    ): Promise<number[]> {
        return new Promise<number[]>((resolve, reject) => {
            this._sharepointService
                .getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField(lookupField)
                        .Id()
                        .EqualTo(id)
                        .ToString(),
                })
                .then((results) => {
                    let projectIds: number[] = [];

                    results.forEach((result) => {
                        projectIds.push(result.Id);
                    });

                    resolve(projectIds);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    constructor(
        private _sharepointService: SharePointService,
        private _lookUpService: LookupService,
        private _timeService: TimeService
    ) {}

    startOfWeek(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        const diff =
            date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    }

    endOfWeek(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(
            this.startOfWeek(date).setDate(this.startOfWeek(date).getDate() + 6)
        );
    }

    startOfMonth(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(date.setDate(1));
    }

    endOfMonth(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    startOfYear(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(new Date(date.setMonth(0)).setDate(1));
    }

    endOfYear(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(new Date(date.setMonth(11)).setDate(31));
    }

    startOfQuarter(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        if (this.getQuarter(date) == 1) {
            return this.startOfMonth(new Date(date.setMonth(0)));
        } else if (this.getQuarter(date) == 2) {
            return this.startOfMonth(new Date(date.setMonth(3)));
        } else if (this.getQuarter(date) == 3) {
            return this.startOfMonth(new Date(date.setMonth(6)));
        } else if (this.getQuarter(date) == 4) {
            return this.startOfMonth(new Date(date.setMonth(9)));
        }
    }

    endOfQuarter(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        if (this.getQuarter(date) == 1) {
            return this.endOfMonth(new Date(date.setMonth(2)));
        } else if (this.getQuarter(date) == 2) {
            return this.endOfMonth(new Date(date.setMonth(5)));
        } else if (this.getQuarter(date) == 3) {
            return this.endOfMonth(new Date(date.setMonth(8)));
        } else if (this.getQuarter(date) == 4) {
            return this.endOfMonth(new Date(date.setMonth(11)));
        }
    }

    addDaysToDate(date: Date, days: number) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(date.setDate(date.getDate() + days));
    }

    getQuarter(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return Math.floor((date.getMonth() + 3) / 3);
    }

    getEndOfTheDay(date: Date) {
        // Remove time
        date = new Date(date.toDateString());

        return new Date(
            new Date(new Date(date.setHours(23)).setMinutes(59)).setSeconds(59)
        );
    }

    reset() {
        this.startDate = null;
        this.endDate = null;
        this.userIds = [];
        this.projectIds = [];
    }

    public changeQuery(): void {
        this.setData();
    }

    public setData(): void {
        this.cancelLoadItems$.next(undefined);
        this.currentListView.listComponent.sharePointTableComponent.loading =
            true;

        forkJoin(
            this._timeService.getDateEqualChunks().map((item) => {
                return this._sharepointService.getListItems({
                    title: 'Zeiten',
                    isDocumentLibrary: false,
                    camlQuery: this.getFilteredTimesCamlQuery(
                        item.startDate,
                        item.endDate
                    ),
                    recursiveAll: true,
                });
            })
        )
            .pipe(
                mergeMap((items) => {
                    let rUserIds = [];
                    items = items.flat();
                    items.forEach((item) => rUserIds.push(item.AuthorId));
                    rUserIds = [...new Set(rUserIds)];
                    console.log(
                        `%cLoaded report elements: ${items.length}`,
                        'color: green;font-weight:bold;font-size:20px;'
                    );
                    return forkJoin([
                        this._sharepointService.getListItems({
                            title: 'Mitarbeiter',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .UserField('User')
                                .Id()
                                .In(rUserIds.length ? rUserIds : [-1])
                                .ToString(),
                            recursiveAll: true,
                        }),
                        this._sharepointService.getListItems({
                            title: 'Kostenrollen',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(0)
                                .ToString(),
                            recursiveAll: true,
                        }),
                    ]).pipe(
                        map(([mitarbeiters, kostenrollens]) => [
                            items,
                            mitarbeiters,
                            kostenrollens,
                        ])
                    );
                }),
                takeUntil(this.cancelLoadItems$)
            )
            .subscribe(([items, mitarbeiters, kostenrollens]) => {
                const tableData = [];
                const rMitarbeiter = [];
                const rKostenrollen = [];
                mitarbeiters.forEach((result) => rMitarbeiter.push(result));
                kostenrollens.forEach((result) => rKostenrollen.push(result));

                items.forEach((item) => {
                    const hours = Math.round((item.Minuten * 100) / 60) / 100;
                    const date = new Date(item.Datum);
                    const data = {
                        ID: item.Id,
                        Projekt: item.FieldValuesAsText.Projekt,
                        Datum: formatDate(date, 'dd.MM.yyyy', 'de-DE'),
                        OriginalDate: date,
                        Projektnummer: item.Projektnummer,
                        Author: item.FieldValuesAsText.Author,
                        Aufgabe: item.FieldValuesAsText.Aufgabe,
                        Minuten: hours,
                        Kosten: item.Kosten,
                        Beschreibung: item.Beschreibung,
                        Kostenrolle: '',
                    };

                    for (let i = 0; i < rMitarbeiter.length; i++) {
                        if (rMitarbeiter[i].UserId == item.AuthorId) {
                            for (let j = 0; j < rKostenrollen.length; j++) {
                                if (
                                    rKostenrollen[j].Id ==
                                    rMitarbeiter[i].KostenrolleId
                                ) {
                                    data.Kostenrolle =
                                        rKostenrollen[j].Abbreviation;
                                    break;
                                }
                            }
                        }
                    }

                    tableData.push(data);
                });

                this.currentListView.listViewConfig.tableConfigs[0].config.data =
                    tableData;
                this.currentListView.refresh();
            });
    }

    public addUser(userId: number): void {
        this.userIds.push(userId);
    }

    public removeUser(userId: number): void {
        this.userIds = this.userIds.filter((_userId) => _userId !== userId);
    }

    public isDuplicateUser(userId: number): boolean {
        return this.userIds.some((_userId) => _userId === userId);
    }

    public addTableGroup(data: any): void {
        this.currentListView.listViewConfig.tableConfigs[0].config.groups.push(
            data
        );
    }

    public removeTableGroup(): void {
        const removeIndex =
            this.currentListView.listViewConfig.tableConfigs[0].config.groups
                .map((element) => element.field)
                .indexOf('Author');

        this.currentListView.listViewConfig.tableConfigs[0].config.groups.splice(
            removeIndex,
            1
        );
    }

    public triggerFilterData(): void {
        if (this.currentListView) {
            this.changeQuery();
        }
    }
}
