import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import {
    TableConfiguration,
    SharePointService,
    ColumnTyp,
    FormatTypes,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { ActivatedRoute } from '@angular/router';

import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-involved-assignment',
    templateUrl: './involved-assignment.component.html',
    styleUrls: ['./involved-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class InvolvedAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public tableConfig: TableConfiguration;
    public header;
    private sharePointItem;

    get camlQuery() {
        if (this.id) {
            // Return all from this project
            return new CamlBuilder()
                .Where()
                .LookupField('Auftrag')
                .Id()
                .EqualTo(this.id)
                .OrderByDesc('Modified')
                .ToString();
        } else {
            // Return no data
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .ToString();
        }
    }

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('INVOLVED');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            this.initTable();

            if (this.id != null) {
                this.sharepointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    initTable() {
        const self = this;
        this.tableConfig = {
            list: 'Auftragsbeteiligte',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('COLUMNS.NAME'),
                    hidden: false,
                },
                {
                    internalName: 'Art',
                    title: this._translateService.instant('COLUMNS.KIND'),
                    hidden: false,
                },
                // { internalName: 'Rolle', title: 'Rolle', hidden: false },
                {
                    internalName: 'Beteiligung',
                    title:
                        this._translateService.instant(
                            'COLUMNS.PARTICIPATION'
                        ) +
                        ' ' +
                        this._translateService.instant('COLUMNS.INPERCENT'),
                    hidden: false,
                },
                {
                    internalName: 'Start',
                    title: this._translateService.instant('COLUMNS.START'),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMdd,
                },
                {
                    internalName: 'Ende',
                    title: this._translateService.instant('COLUMNS.END'),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMdd,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            folderName: self.id + '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('NEW'),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQuery,
        };
    }
}
