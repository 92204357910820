<listview #listView *ngIf="config" [config]="config" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

  <ng-template customCell for="Auftragsstatus" let-dataItem="dataItem">
    <div *ngIf="dataItem.Auftragsstatus == 'Auftrag angelegt'">
      {{ "LV_CURRENTUSER.COLUMNS_PROJECT.CREATED" | translate }}</div>

    <div *ngIf="dataItem.Auftragsstatus == 'Auftrag in Arbeit'">
      {{ "LV_CURRENTUSER.COLUMNS_PROJECT.PROGRESS" | translate }}
    </div>

    <div *ngIf="dataItem.Auftragsstatus == 'Auftrag gestoppt'">
      {{ "LV_CURRENTUSER.COLUMNS_PROJECT.STOPPED" | translate }}</div>

    <div *ngIf="dataItem.Auftragsstatus == 'Auftrag abgeschlossen'">
      {{ "LV_CURRENTUSER.COLUMNS_PROJECT.COMPLETED" | translate }}
    </div>
  </ng-template>

  <ng-template customCell for="Auftraggeber" let-dataItem="dataItem">
    {{ dataItem.FieldValuesAsText.Auftraggeber }} {{ dataItem.FieldValuesAsText.Auftraggeber_x005f_x003a_x005f_Title2 }} {{ dataItem.FieldValuesAsText.Auftraggeber_x005f_x003a_x005f_Title3 }}
  </ng-template>

</listview>
