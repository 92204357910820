import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    FuseTranslationLoaderService,
    SnackbarService,
    fuseAnimations,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { DocumentsAssignmentService } from '../assignment/documents-assignment/documents-assignment.service';
import { DocTemplatesService } from '../services/doc-templates.service';
import { LoadingService } from '../services/loading.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-doc-templates',
    templateUrl: './doc-templates.component.html',
    styleUrls: ['./doc-templates.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DocTemplatesComponent implements OnInit, AfterViewInit {
    public tableConfig: TableConfiguration;
    public columns: TableColumn[];
    public acceptedFileExtensions = '.docx, .doc';
    public createButtonDisbaled = true;
    public copyButtonDisbaled = true;

    public textPH1 = '';
    public arrPHFields1 = [
        // Platzhalter Arbeitspaket
        {
            DisplayName: '',
            List: '',
            Title: '<b>Aktuelles Arbeitspaket</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Titel',
            List: 'Auftragsaufgaben',
            Title: 'Title',
            Type: 'Text',
            LookupField: '',
        },
        // {
        //     DisplayName: '',
        //     List: 'Auftragsaufgaben',
        //     Title: 'PercentComplete',
        //     Type: 'Number',
        //     LookupField: '',
        // },
        {
            DisplayName: 'Start',
            List: 'Auftragsaufgaben',
            Title: 'StartDate',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Aufgabenart',
            List: 'Auftragsaufgaben',
            Title: 'Aufgabenart',
            Type: 'Choice',
            LookupField: '',
        },
        // {
        //     DisplayName: '',
        //     List: 'Auftragsaufgaben',
        //     Title: 'TaskGroup',
        //     Type: 'User',
        //     LookupField: '',
        // },
        // {
        //     DisplayName: '',
        //     List: 'Auftragsaufgaben',
        //     Title: 'Status',
        //     Type: 'Choice',
        //     LookupField: '',
        // },
        {
            DisplayName: 'Beschreibung',
            List: 'Auftragsaufgaben',
            Title: 'Body',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'Fälligkeitsdatum',
            List: 'Auftragsaufgaben',
            Title: 'DueDate',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Kennzeichen',
            List: 'Auftragsaufgaben',
            Title: 'Kennzeichen',
            Type: 'Choice',
            LookupField: '',
        },
        {
            DisplayName: 'Priorität',
            List: 'Auftragsaufgaben',
            Title: 'Priority',
            Type: 'Choice',
            LookupField: '',
        },
        {
            DisplayName: 'Zugewiesen an',
            List: 'Auftragsaufgaben',
            Title: 'AssignedTo',
            Type: 'User',
            LookupField: '',
        },
        // Platzhalter Arbeitspaket Aufgabentyp
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Aufgabentyp im Arbeitspaket</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Aufgabentypen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'AufgabentypId',
        },
        // Platzhalter Arbeitspaket Prozessschritt
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Prozessschritt im Arbeitspaket</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Prozessschritte',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'ProzessschrittId',
        },
        // Platzhalter Arbeitspaket Ansprechpartner
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Ansprechpartner im Arbeitspaket</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Firmenname 1',
            List: 'Firmen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firmenname 2',
            List: 'Firmen',
            Title: 'Title2',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firmenname 3',
            List: 'Firmen',
            Title: 'Title3',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'PLZ',
            List: 'Firmen',
            Title: 'WorkZip',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Ort',
            List: 'Firmen',
            Title: 'WorkCity',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Straße/Hausnummer',
            List: 'Firmen',
            Title: 'Strasse',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        // {
        //     DisplayName: '',
        //     List: 'Firmen',
        //     Title: 'StandortID',
        //     Type: 'Lookup',
        //     LookupField: 'AuftraggeberId',
        // },
        {
            DisplayName: 'Niederlassung',
            List: 'Firmen',
            Title: 'Kunden_Niederlassung',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'E-Mail',
            List: 'Firmen',
            Title: 'Email',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firmenbriefkopf',
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        // Platzhalter Projekt
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Zugehöriges Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Projektbezeichnung',
            List: 'Aufträge',
            Title: 'Title',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'Status',
            List: 'Aufträge',
            Title: 'Auftragsstatus',
            Type: 'Choice',
            LookupField: '',
        },
        {
            DisplayName: 'Start',
            List: 'Aufträge',
            Title: 'Auftragsstart',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Ende',
            List: 'Aufträge',
            Title: 'Auftragsende',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Projektleiter',
            List: 'Aufträge',
            Title: 'Auftragsleiter',
            Type: 'User',
            LookupField: '',
        },
        {
            DisplayName: 'Interne Auftragssumme',
            List: 'Aufträge',
            Title: 'Auftragsbudget',
            Type: 'Currency',
            LookupField: '',
        },
        // { List: 'Aufträge', Title: 'Auftragsart', Type: 'Choice', LookupField: '' },
        {
            DisplayName: 'Kennzeichen',
            List: 'Aufträge',
            Title: 'Kennzeichen',
            Type: 'Choice',
            LookupField: '',
        },
        // { List: 'Aufträge', Title: 'PPL', Type: 'User', LookupField: '' },
        // { List: 'Aufträge', Title: 'Ansprechpartner', Type: 'User', LookupField: '' },
        // { List: 'Aufträge', Title: 'Antragssteller', Type: 'User', LookupField: '' },
        {
            DisplayName: 'Beschreibung',
            List: 'Aufträge',
            Title: 'Bezeichnung',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'Projektnummer',
            List: 'Aufträge',
            Title: 'Projektnummer',
            Type: 'Text',
            LookupField: '',
        },
        // { List: 'Aufträge', Title: 'Notizen', Type: 'Text', LookupField: '' },
        {
            DisplayName: 'Übergabedatum',
            List: 'Aufträge',
            Title: 'Uebergabe',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Akkreditiert',
            List: 'Aufträge',
            Title: 'Akkreditiert',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Sachbearbeiter',
            List: 'Aufträge',
            Title: 'Sachbearbeiter',
            Type: 'User',
            LookupField: '',
        },
        {
            DisplayName: 'Auftragssumme',
            List: 'Aufträge',
            Title: 'Antragssumme',
            Type: 'Currency',
            LookupField: '',
        },
        {
            DisplayName: 'zzgl. Nebenkosten',
            List: 'Aufträge',
            Title: 'Nebenkosten',
            Type: 'Number',
            LookupField: '',
        },
        {
            DisplayName: 'Davon Fremdleistung',
            List: 'Aufträge',
            Title: 'Fremdleistung',
            Type: 'Currency',
            LookupField: '',
        },
        {
            DisplayName: 'AR-Nummer',
            List: 'Aufträge',
            Title: 'currentAR_Number',
            Type: 'Number',
            LookupField: '',
        },
        {
            DisplayName: 'Projekt E-Mail',
            List: 'Aufträge',
            Title: 'ProjectMail',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'OW-Nummer',
            List: 'Aufträge',
            Title: 'OW_Nummer',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'Erwartete Gewinnmarge',
            List: 'Aufträge',
            Title: 'Gewinnmarge',
            Type: 'Number',
            LookupField: '',
        },
        // Platzhalter Projekt Auftraggeber/Ansprechpartner
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Auftraggeber/Ansprechpartner im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Firmenname 1',
            List: 'Firmen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'Firmenname 2',
            List: 'Firmen',
            Title: 'Title2',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'Firmenname 3',
            List: 'Firmen',
            Title: 'Title3',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'PLZ',
            List: 'Firmen',
            Title: 'WorkZip',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'Ort',
            List: 'Firmen',
            Title: 'WorkCity',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'Straße/Hausnummer',
            List: 'Firmen',
            Title: 'Strasse',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        // {
        //     DisplayName: 'Niederlassung',
        //     List: 'Firmen',
        //     Title: 'StandortID',
        //     Type: 'Lookup',
        //     LookupField: 'KundeId',
        // },
        {
            DisplayName: 'Niederlassung',
            List: 'Firmen',
            Title: 'Kunden_Niederlassung',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'E-Mail',
            List: 'Firmen',
            Title: 'Email',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        {
            DisplayName: 'Firmenbriefkopf',
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            Type: 'Lookup',
            LookupField: 'KundeId',
        },
        // Platzhalter Projekt Projektsteuerer
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Projektsteuerer im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Firmenname 1',
            List: 'Firmen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firmenname 2',
            List: 'Firmen',
            Title: 'Title2',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firmenname 3',
            List: 'Firmen',
            Title: 'Title3',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'PLZ',
            List: 'Firmen',
            Title: 'WorkZip',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Ort',
            List: 'Firmen',
            Title: 'WorkCity',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Straße/Hausnummer',
            List: 'Firmen',
            Title: 'Strasse',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        // {
        //     DisplayName: '',
        //     List: 'Firmen',
        //     Title: 'StandortID',
        //     Type: 'Lookup',
        //     LookupField: 'ProjektsteuererId',
        // },
        {
            DisplayName: 'Niederlassung',
            List: 'Firmen',
            Title: 'Kunden_Niederlassung',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'E-Mail',
            List: 'Firmen',
            Title: 'Email',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firmenbriefkopf',
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        // Pltazhalter Projekt Team
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Team im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Teams',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Gruppe',
            List: 'Teams',
            Title: 'Gruppe',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Teamleiter',
            List: 'Teams',
            Title: 'Teamleiter',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Relevant für Auswertung',
            List: 'Teams',
            Title: 'AvailableForCalculation',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        // Platzhalter Projekt Niederlassung
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Niederlassung im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Standorte',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'StandortId',
        },
        // Platzhalter Projekt Fachbereich
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Fachbereich im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Fachbereiche',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'FachbereichId',
        },
        {
            DisplayName: 'Bereichsnummer',
            List: 'Fachbereiche',
            Title: 'Bereichsnummer',
            Type: 'Lookup',
            LookupField: 'FachbereichId',
        },
        // Platzhalter Projekt Kategorie
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Kategorie im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Auftragskategorien',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'AuftragskategorieId',
        },
        // { List: '', Title: '<br><b>Auftragsunterkategorien:</b>', Type: '', LookupField: '' },
        // { List: 'Auftragsunterkategorien', Title: 'Title', Type: 'Lookup', LookupField: 'AuftragsunterkategorieId' },
        // Platzhalter Projekt Kostenstelle
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Kostenstelle im Projekt</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Kostenstellen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'KostenstelleId',
        },
    ];

    public textPH2 = '';
    public arrPHFields2 = [
        {
            DisplayName: '',
            List: '',
            Title: '<b>Aktueller Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Projektbezeichnung',
            List: 'Anträge',
            Title: 'Title',
            Type: 'Text',
            LookupField: '',
        },
        {
            DisplayName: 'Auftragsdatum',
            List: 'Anträge',
            Title: 'Antragsdatum',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Akkreditiert',
            List: 'Anträge',
            Title: 'Akkreditiert',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Projektleiter',
            List: 'Anträge',
            Title: 'Projektleiter',
            Type: 'User',
            LookupField: '',
        },
        {
            DisplayName: 'Sachbearbeiter',
            List: 'Anträge',
            Title: 'Sachbearbeiter',
            Type: 'User',
            LookupField: '',
        },
        {
            DisplayName: 'Gesamtsumme',
            List: 'Anträge',
            Title: 'Antragssumme',
            Type: 'Currency',
            LookupField: '',
        },
        {
            DisplayName: 'Davon Nebenkosten',
            List: 'Anträge',
            Title: 'Nebenkosten',
            Type: 'Number',
            LookupField: '',
        },
        {
            DisplayName: 'Davon Fremdleistung',
            List: 'Anträge',
            Title: 'Fremdleistung',
            Type: 'Currency',
            LookupField: '',
        },
        {
            DisplayName: 'Kopie Angebot/Auftrag an Sekretariat',
            List: 'Anträge',
            Title: 'Kopie',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Auftragsbestätigung senden',
            List: 'Anträge',
            Title: 'Auftragsbestaetigung',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Ordner anlegen',
            List: 'Anträge',
            Title: 'Ordner',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Weihnachtskarte',
            List: 'Anträge',
            Title: 'Weihnachtskarte',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Übergabedatum',
            List: 'Anträge',
            Title: 'Uebergabe',
            Type: 'Date',
            LookupField: '',
        },
        {
            DisplayName: 'Genehmigt',
            List: 'Anträge',
            Title: 'Genehmigt',
            Type: 'Boolean',
            LookupField: '',
        },
        {
            DisplayName: 'Abgesagt',
            List: 'Anträge',
            Title: 'Abgesagt',
            Type: 'Boolean',
            LookupField: '',
        },
        // {
        //     DisplayName: '',
        //     List: 'Anträge',
        //     Title: 'Old_Id',
        //     Type: 'Number',
        //     LookupField: '',
        // },
        // Platzhalter Auftrag/Anfrage Auftraggeber/Ansprechpartner
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Auftraggeber/Ansprechpartner in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Firmenname 1',
            List: 'Firmen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firnenname 2',
            List: 'Firmen',
            Title: 'Title2',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firnenname 3',
            List: 'Firmen',
            Title: 'Title3',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'PLZ',
            List: 'Firmen',
            Title: 'WorkZip',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Ort',
            List: 'Firmen',
            Title: 'WorkCity',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Straße/Hausnummer',
            List: 'Firmen',
            Title: 'Strasse',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        // {
        //     DisplayName: '',
        //     List: 'Firmen',
        //     Title: 'StandortID',
        //     Type: 'Lookup',
        //     LookupField: 'AuftraggeberId',
        // },
        {
            DisplayName: 'Niederlassung',
            List: 'Firmen',
            Title: 'Kunden_Niederlassung',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'E-Mail',
            List: 'Firmen',
            Title: 'Email',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        {
            DisplayName: 'Firmenbriefkopf',
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            Type: 'Lookup',
            LookupField: 'AuftraggeberId',
        },
        // Platzhalter Auftrag/Anfrage Projektsteuerer
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Projektsteuerer in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Firmenname 1',
            List: 'Firmen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firnenname 2',
            List: 'Firmen',
            Title: 'Title2',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firnenname 3',
            List: 'Firmen',
            Title: 'Title3',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'PLZ',
            List: 'Firmen',
            Title: 'WorkZip',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Ort',
            List: 'Firmen',
            Title: 'WorkCity',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Straße/Hausnummer',
            List: 'Firmen',
            Title: 'Strasse',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        // {
        //     DisplayName: '',
        //     List: 'Firmen',
        //     Title: 'StandortID',
        //     Type: 'Lookup',
        //     LookupField: 'ProjektsteuererId',
        // },
        {
            DisplayName: 'Niederlassung',
            List: 'Firmen',
            Title: 'Kunden_Niederlassung',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'E-Mail',
            List: 'Firmen',
            Title: 'Email',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        {
            DisplayName: 'Firmenbriefkopf',
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            Type: 'Lookup',
            LookupField: 'ProjektsteuererId',
        },
        // Platzhalter Auftrag/Anfrage Kostenstelle
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Kostenstelle in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Kostenstellen',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'KostenstelleId',
        },
        // Platzhalter Auftrag/Anfrage Team
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Team in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Teams',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Gruppe',
            List: 'Teams',
            Title: 'Gruppe',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Teamleiter',
            List: 'Teams',
            Title: 'Teamleiter',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        {
            DisplayName: 'Relevant für Auswertung',
            List: 'Teams',
            Title: 'AvailableForCalculation',
            Type: 'Lookup',
            LookupField: 'TeamId',
        },
        // Platzhalter Auftrag/Anfrage Niederlassung
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Niederlassung in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Standorte',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'StandortId',
        },
        // Platzhalter Auftrag/Anfrage Fachbereich
        {
            DisplayName: '',
            List: '',
            Title: '<br><b>Fachbereich in Auftrag/Anfrage</b>',
            Type: '',
            LookupField: '',
        },
        {
            DisplayName: 'Name',
            List: 'Fachbereiche',
            Title: 'Title',
            Type: 'Lookup',
            LookupField: 'FachbereichId',
        },
        {
            DisplayName: 'Bereichsnummer',
            List: 'Fachbereiche',
            Title: 'Bereichsnummer',
            Type: 'Lookup',
            LookupField: 'FachbereichId',
        },
    ];

    public lists = [
        { Title: 'CurrentUser', DisplayName: 'Aktueller User' },
        { Title: 'Aufträge', DisplayName: 'Liste: Projekte' },
        { Title: 'Auftragsaufgaben', DisplayName: 'Liste: Projektaufgaben' },
        { Title: 'Anträge', DisplayName: 'Liste: Anfragen' },
        { Title: 'Firmen', DisplayName: 'Liste: Kunden' },
        { Title: 'Teams', DisplayName: 'Liste: Teams' },
        { Title: 'Fachbereiche', DisplayName: 'Liste: Fachbereiche' },
        { Title: 'Kostenstellen', DisplayName: 'Liste: Kostenstellen' },
        { Title: 'Standorte', DisplayName: 'Liste: Standorte' },
        {
            Title: 'Auftragskategorien',
            DisplayName: 'Liste: Auftragskategorien',
        },
    ];
    public fields = [
        {
            List: 'Aufträge',
            Title: 'Title',
            DisplayName: 'Projektbezeichnung',
            Type: 'Text',
        },
        {
            List: 'Aufträge',
            Title: 'Bezeichnung',
            DisplayName: 'Beschreibung ',
            Type: 'Note',
        },
        {
            List: 'Aufträge',
            Title: 'ProjectMail',
            DisplayName: 'Projekt E-Mail',
            Type: 'Text',
        },
        {
            List: 'Aufträge',
            Title: 'OW_Nummer',
            DisplayName: 'OW-Nummer',
            Type: 'Text',
        },
        {
            List: 'Aufträge',
            Title: 'Auftragsstart',
            DisplayName: 'Start ',
            Type: 'Date',
        },
        {
            List: 'Aufträge',
            Title: 'Auftragsende',
            DisplayName: 'Ende',
            Type: 'Date',
        },
        {
            List: 'Auftragsaufgaben',
            Title: 'Title',
            DisplayName: 'Auftragsname',
            Type: 'Text',
        },
        {
            List: 'Auftragsaufgaben',
            Title: 'Body',
            DisplayName: 'Beschreibung',
            Type: 'Text',
        },
        {
            List: 'Auftragsaufgaben',
            Title: 'StartDate',
            DisplayName: 'Start',
            Type: 'Date',
        },
        {
            List: 'Auftragsaufgaben',
            Title: 'DueDate',
            DisplayName: 'Fälligkeitsdatum',
            Type: 'Date',
        },
        {
            List: 'Anträge',
            Title: 'Title',
            DisplayName: 'Anfragetitel',
            Type: 'Text',
        },
        {
            List: 'Anträge',
            Title: 'Antragsdatum',
            DisplayName: 'Anfragedatum',
            Type: 'Date',
        },
        {
            List: 'Firmen',
            Title: 'Title',
            DisplayName: 'Firmenname 1',
            Type: 'Text',
        },
        {
            List: 'Firmen',
            Title: 'Title2',
            DisplayName: 'Firmenname 2',
            Type: 'Text',
        },
        {
            List: 'Firmen',
            Title: 'Title3',
            DisplayName: 'Firmenname 3',
            Type: 'Text',
        },
        {
            List: 'Firmen',
            Title: 'Email',
            DisplayName: 'E-Mail-Adresse',
            Type: 'Text',
        },
        {
            List: 'Firmen',
            Title: 'Firmenbriefkopf',
            DisplayName: 'Firmenbriefkopf',
            Type: 'Note',
        },
        {
            List: 'Auftragskategorien',
            Title: 'Title',
            DisplayName: 'Name',
            Type: 'Text',
        },
        //   {
        //     List: 'Auftragsunterkategorien',
        //     Title: 'Title',
        //     DisplayName: 'Name',
        //     Type: 'Text',
        // },
        {
            List: 'Teams',
            Title: 'Title',
            DisplayName: 'Name',
            Type: 'Text',
        },
        {
            List: 'Fachbereiche',
            Title: 'Title',
            DisplayName: 'Name',
            Type: 'Text',
        },
        {
            List: 'Fachbereiche',
            Title: 'Bereichsnummer',
            DisplayName: 'Bereichsnummer',
            Type: 'Text',
        },

        {
            List: 'Kostenstellen',
            Title: 'Title',
            DisplayName: 'Name',
            Type: 'Text',
        },

        {
            List: 'Standorte',
            Title: 'Title',
            DisplayName: 'Name',
            Type: 'Text',
        },
        // {
        //     List: 'Standorte',
        //     Title: 'Adresse',
        //     DisplayName: 'Adresse',
        //     Type: 'Note',
        // },
        {
            List: 'CurrentUser',
            Title: 'DisplayName',
            DisplayName: 'Vollständiger Name',
            Type: 'CurrentUser',
        },
        {
            List: 'CurrentUser',
            Title: 'Mail',
            DisplayName: 'E-Mail-Adresse',
            Type: 'CurrentUser',
        },
        {
            List: 'CurrentUser',
            Title: 'BusinessPhone',
            DisplayName: 'Telefonnummer',
            Type: 'CurrentUser',
        },
        {
            List: 'CurrentUser',
            Title: 'MobilePhone',
            DisplayName: 'Mobile Nummer',
            Type: 'CurrentUser',
        },
    ];
    public listFields = [];
    public categories = ['Alle', 'Anfrage', 'Arbeitspaket'];

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('templateGrid', { static: false })
    templateGrid: SharePointTableComponent;
    @ViewChild('elementIdInput', { static: true }) elementIdInput: ElementRef;
    @ViewChild('listSelect', { static: true }) listSelect: ElementRef;
    @ViewChild('fieldSelect', { static: false }) fieldSelect: any;
    @ViewChild('placHolderInput', { static: true }) placHolderInput: ElementRef;

    get camlQueryAll() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _docTemplateService: DocTemplatesService,
        private _translateService: TranslateService,
        private _snackBarService: SnackbarService,
        public _documentService: DocumentsAssignmentService,
        private _loadingService: LoadingService,
        private clipboard: Clipboard,
        private _sharePointService: SharePointService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngAfterViewInit(): void {
        if (this.templateGrid) {
            this.templateGrid.selectionChanged.subscribe((selection) => {
                if (selection && selection.length == 1) {
                    this.createButtonDisbaled = false;
                } else {
                    this.createButtonDisbaled = true;
                }
            });
        }
    }

    ngOnInit(): void {
        this.initGrid();
    }

    async onButtonClick() {
        try {
            // get selected item
            const selectedTemplate = this.templateGrid.itemSelected[0];

            if (!selectedTemplate || !selectedTemplate.dataItem.FileRef) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.NO_TEMPLATE_SELECTED'
                    ),
                    '',
                    {
                        duration: 5000,
                        panelClass: 'error-dialog',
                    }
                );

                return;
            }

            if (!this.elementIdInput.nativeElement.value) {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.NO_ELEMENT_ID'
                    ),
                    '',
                    {
                        duration: 5000,
                        panelClass: 'error-dialog',
                    }
                );

                return;
            }

            this._loadingService.show(
                this._translateService.instant(
                    'DOC_TEMPLATES.NOTIFICATIONS.SAVING'
                ),
                this._translateService.instant(
                    'DOC_TEMPLATES.NOTIFICATIONS.WAIT'
                )
            );

            let result = await this._docTemplateService.createDocument({
                templateUrl: selectedTemplate.dataItem.FileRef,
                elementIds: this.elementIdInput.nativeElement.value,
            });

            if (!result) {
                return;
            }

            // create blob and download
            const blob = new Blob([result], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            this._docTemplateService.downloadBloob(
                blob,
                this._docTemplateService.getFileNameFromUrl(
                    selectedTemplate.dataItem.FileRef
                )
            );

            this._snackBarService.add(
                this._translateService.instant(
                    'DOC_TEMPLATES.NOTIFICATIONS.SUCCESS_DOC'
                ),
                '',
                {
                    duration: 5000,
                    panelClass: 'success-dialog',
                }
            );
            this._loadingService.hide();
        } catch (error) {
            this._loadingService.hide();
            throw error;
        }
    }

    async onCopyButtonClick() {
        try {
            if (this.placHolderInput.nativeElement.value) {
                this.clipboard.copy(this.placHolderInput.nativeElement.value);
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.COPY_SUCCESS'
                    ),
                    '',
                    {
                        duration: 5000,
                        panelClass: 'success-dialog',
                    }
                );
            }
        } catch (error) {
            throw error;
        }
    }

    onListChange(e) {
        if (e.value) {
            this.listFields = this.fields.filter((f) => f.List == e.value);
            if (this.fieldSelect) {
                this.fieldSelect.value = '';
            }
            if (this.placHolderInput) {
                this.placHolderInput.nativeElement.value = '';
            }
        } else {
            this.listFields = [];
        }
    }

    onFieldChange(e) {
        if (e.value) {
            let selectedField = this.listFields.find((f) => f.Title == e.value);

            if (!selectedField) return;

            let placeholder =
                '{{' +
                selectedField.List +
                '.' +
                selectedField.Title +
                '#' +
                selectedField.Type +
                '}}';

            this.placHolderInput.nativeElement.value = placeholder;
            this.copyButtonDisbaled = false;
        } else {
            this.placHolderInput.nativeElement.value = '';
            this.copyButtonDisbaled = true;
        }
    }

    initGrid() {
        this.columns = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DOC_TEMPLATES.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'Kategorie',
                title: this._translateService.instant(
                    'DOC_TEMPLATES.COLUMNS.CATEGORY'
                ),
                hidden: false,
                type: ColumnTyp.Choice,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DOC_TEMPLATES.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DOC_TEMPLATES.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        let toolbar = [
            {
                title: this._translateService.instant('DOC_TEMPLATES.NEW'),
                command: 'Add',
                color: 'accent',
            },
        ];

        this.tableConfig = {
            list: 'Dokumentenvorlagen',
            pageSize: 20,
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this.camlQueryAll,
            folderName: '',
            useMaterialDesign: true,
            addCheckboxColumn: true,
            afterDeleteItem: () => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.SUCCESS_DELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
            },
            toolbar,
            afterRefreshTable: () => {},
        };

        this.arrPHFields1.forEach((element) => {
            if (element.List != '') {
                if (element.Type == 'Lookup' && element.LookupField != '') {
                    this.textPH1 +=
                        element.DisplayName +
                        ': {{' +
                        element.List +
                        '.' +
                        element.Title +
                        '#' +
                        element.Type +
                        '#' +
                        element.LookupField +
                        '}}' +
                        '<br>';
                } else {
                    this.textPH1 +=
                        element.DisplayName +
                        ': {{' +
                        element.List +
                        '.' +
                        element.Title +
                        '#' +
                        element.Type +
                        '}}' +
                        '<br>';
                }
            } else {
                this.textPH1 += element.Title + '<br>';
            }
        });

        this.arrPHFields2.forEach((element) => {
            if (element.List != '') {
                if (element.Type == 'Lookup' && element.LookupField != '') {
                    this.textPH2 +=
                        element.DisplayName +
                        ': {{' +
                        element.List +
                        '.' +
                        element.Title +
                        '#' +
                        element.Type +
                        '#' +
                        element.LookupField +
                        '}}' +
                        '<br>';
                } else {
                    this.textPH2 +=
                        element.DisplayName +
                        ': {{' +
                        element.List +
                        '.' +
                        element.Title +
                        '#' +
                        element.Type +
                        '}}' +
                        '<br>';
                }
            } else {
                this.textPH2 += element.Title + '<br>';
            }
        });

        /*this.arrPHFields3.forEach(element => {
            if (element.List != '') {
                if (element.Type == 'Lookup' && element.LookupField != '') {
                    this.textPH3 += "- {{" + element.List + "." + element.Title + "#" + element.Type + "#" + element.LookupField + "}}" + "<br>";
                } else {
                    this.textPH3 += "- {{" + element.List + "." + element.Title + "#" + element.Type + "}}" + "<br>";
                }
            } else {
                this.textPH3 += element.Title + "<br>";
            }
        });*/
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
        }
    }

    async changeDocumentCategory(e, dataItem) {
        try {
            if (e.value && dataItem) {
                this._loadingService.show(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.CHANGE_CATEGORY'
                    ),
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.WAIT'
                    )
                );

                this._sharePointService.updateItem({
                    listTitle: 'Dokumentenvorlagen',
                    id: dataItem.Id,
                    newFiledValues: { Kategorie: e.value },
                });

                this._snackBarService.add(
                    this._translateService.instant(
                        'DOC_TEMPLATES.NOTIFICATIONS.SUCCESS_CHANGE_CATEGORY'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                this._loadingService.hide();
            }

            return;
        } catch (error) {
            this._loadingService.hide();
            throw error;
        }
    }
}
