import { DetailOrderComponent } from './../../order/detail-order/detail-order.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablePositionComponent } from './table-position.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { AngularFrameworkModule } from 'sp-office365-framework';

@NgModule({
    declarations: [TablePositionComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
    ],
    exports: [TablePositionComponent]
})
export class TablePositionModule {}
