import {
    Component,
    OnInit,
    EventEmitter,
    Inject,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
} from '@angular/core';
import {
    SharePointService,
    SharePointTableComponent,
    TableConfiguration,
    TableColumn,
    ColumnTyp,
    FormatTypes,
} from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import * as CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ApplicationService } from '../../services/application.service';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-detail-tasktemplate',
    templateUrl: './detail-tasktemplate.component.html',
    styleUrls: ['./detail-tasktemplate.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailTasktemplateComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public id;
    public sharePointItem;
    public formioConfiguration;
    public isCreateMode = true;
    public tableConfig: TableConfiguration;
    private columns: TableColumn[];

    public refreshForm: EventEmitter<any> = new EventEmitter();

    private taskSetId;

    uploadMultipleDocuments = true;

    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    get camlQueryDocs() {
        return new CamlBuilder()
            .Where()
            .LookupField('Aufgabenvorlage')
            .Id()
            .EqualTo(this.id)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public dialogRef: MatDialogRef<DetailTasktemplateComponent>,
        private sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService,
        private _applicationService: ApplicationService,
        public documentService: DocumentsAssignmentService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        this.id = this.data.taskTemplateID;
        this.taskSetId = this.data.taskSetId;

        if (this.id != null) {
            this.isCreateMode = false;

            this.sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Aufgabenvorlagen',
                })
                .then((tasktemplate) => {
                    this.sharePointItem = tasktemplate;

                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
        }
    }

    onTabChange(e) {
        //
    }

    saveSubmission(e) {
        this.loadingService.show(
            this._translateService.instant('NOTIFICATIONS.SAVING'),
            this._translateService.instant('NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.id != null) {
            return from(
                this.sharepointService
                    .updateItem({
                        id: this.id,
                        listTitle: 'Aufgabenvorlagen',
                        newFiledValues: e.data,
                    })
                    .then(
                        () => {
                            this.loadingService.hide();

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        } else {
            return from(
                this.sharepointService
                    .addItem({
                        listTitle: 'Aufgabenvorlagen',
                        data: e.data,
                        folder: '',
                    })
                    .then(
                        () => {
                            this.loadingService.hide();

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.TITLE'
                    ),
                    hideLabel: false,
                    allowMultipleMasks: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    inputFormat: 'plain',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.DESCRIPTION'
                    ),
                    isUploadEnabled: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textarea',
                    input: true,
                    key: 'Beschreibung',
                    // editor: 'quill',
                    inputFormat: 'plain',
                    rows: 8,
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.OFFSETSTART'
                                    ),
                                    hideLabel: false,
                                    description: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.INDAYS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'OffsetAnfangsdatum',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    delimiter: false,
                                    requireDecimal: false,
                                    reorder: false,
                                },
                                {
                                    label: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.OFFSETEND'
                                    ),
                                    hideLabel: false,
                                    description: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.INDAYS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'OffsetF_x00e4_lligkeitsdatum',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    delimiter: false,
                                    requireDecimal: false,
                                    reorder: false,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.OFFSETSTARTTYPE'
                                    ),
                                    hideLabel: false,
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'OffsetStartType',
                                    defaultValue: 'Ab Heute',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.TODAY'
                                                ),
                                                value: 'Ab Heute',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.START'
                                                ),
                                                value: 'Ab Beginn Arbeitspaket',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.END'
                                                ),
                                                value: 'Ab Ende Arbeitspaket',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.STARTASSIGNMENT'
                                                ),
                                                value: 'Ab Beginn Auftrag',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.ENDASSIGNMENT'
                                                ),
                                                value: 'Ab Ende Auftrag',
                                            },
                                        ],
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'COLUMNSTASKTEMPLATE.OFFSETENDTYPE'
                                    ),
                                    hideLabel: false,
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'OffsetEndeType',
                                    defaultValue: 'Ab Heute',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.TODAY'
                                                ),
                                                value: 'Ab Heute',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.START'
                                                ),
                                                value: 'Ab Beginn Arbeitspaket',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.END'
                                                ),
                                                value: 'Ab Ende Arbeitspaket',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.STARTASSIGNMENT'
                                                ),
                                                value: 'Ab Beginn Auftrag',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'COLUMNSTASKTEMPLATE.ENDASSIGNMENT'
                                                ),
                                                value: 'Ab Ende Auftrag',
                                            },
                                        ],
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.PRIORITY'
                    ),
                    hideLabel: false,
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'select',
                    input: true,
                    key: 'Priorit_x00e4_t',
                    defaultValue: '(2) Normal',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'COLUMNSTASKTEMPLATE.HIGH'
                                ),
                                value: '(1) Hoch',
                            },
                            {
                                label: this._translateService.instant(
                                    'COLUMNSTASKTEMPLATE.NORMAL'
                                ),
                                value: '(2) Normal',
                            },
                            {
                                label: this._translateService.instant(
                                    'COLUMNSTASKTEMPLATE.LOW'
                                ),
                                value: '(3) Niedrig',
                            },
                        ],
                    },
                },
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.PROCESSSTEP'
                    ),
                    hideLabel: false,
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'ProzessschrittId',
                    list: 'Prozessschritte',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.TYPE'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AufgabentypId',
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    list: 'Aufgabentypen',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.SCHEDULEDTIME'
                    ),
                    hideLabel: false,
                    description: this._translateService.instant(
                        'COLUMNSTASKTEMPLATE.INHOURS'
                    ),
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Geplante_x0020_Arbeitszeit',
                    delimiter: false,
                    requireDecimal: false,
                    reorder: false,
                },
                {
                    label: this._translateService.instant('TASKSET'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AufgabensetId',
                    setValue: this.taskSetId,
                    selectThreshold: 0.3,
                    list: 'Aufgabensets',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    disabled: true,
                    isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant('SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };

        if (!this.id) {
            return;
        }

        this.columns = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant('DOCS.COLUMNS.TITLE'),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant('DOCS.COLUMNS.FILESIZE'),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant('DOCS.COLUMNS.AUTHOR'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant('DOCS.COLUMNS.MODIFIED'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Aufgabenvorlagendokumente',
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this.camlQueryDocs,
            rootFolderName: this._translateService.instant('DOCS.TITLE'),
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('DOCS.NEW'),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            afterRefreshTable: () => {
                //
            },
        };
    }
}
