<div class="listview-employee-analysis simple left-sidebar inner-sidebar inner-scroll">
  <!-- HEADER -->
  <div
    class="header accent p-8 p-sm-8"
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center"
  >
    <!-- APP TITLE -->

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon
          class="logo-icon mr-4"
          [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
          >my_library_books
        </mat-icon>
        <span
          class="logo-text h1"
          [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
        >
          {{ "EMPLOYEEANALYSIS.EMPLOYEEANALYSIS" | translate }}
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->

    <!-- SEARCH -->
    <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

      <div class="search-wrapper mt-4 mt-sm-0">

        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input [formControl]="searchInput" placeholder="Search for a contact">
        </div>

      </div>

    </div> -->
    <!-- / SEARCH -->
  </div>
  <!-- / HEADER -->

  <div id="content">
    <div class="content">
      <div class="p-6">

        <!-- filter -->
        <formio class="formio-material" [refresh]="refreshFormio" (ready)="onFormioReady($event)" [service]="this" [form]="formioConfiguration"
          [renderOptions]="formio_renderOptions"
          (change)="onFormioChange($event)">
        </formio>

        <!-- grid -->
        <kendo-grid
          *ngIf="tableConfig"
          #usersExcelTable
          [data]="gridView"
          [group]="groups"
          [groupable]="true"
          [selectable]="{ enabled: true, checkboxOnly: true }"
          (dataStateChange)="dataStateChange($event)"
          (selectionChange)="selectionChange($event)"
          [columnMenu]="{ filter: false, columnChooser: false }"
          [pageable]="tableConfig.pageSize != null"
          [pageSize]="tableConfig.pageSize"
          [skip]="state.skip"
          (excelExport)="onExcelExport($event)"
        >
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand mat-raised-button>
              <mat-icon>get_app</mat-icon>
              Excel-Export
            </button>
          </ng-template>
          <kendo-grid-checkbox-column
            *ngIf="tableConfig.addCheckboxColumn"
            width="50"
            [showSelectAll]="tableConfig.checkboxColumnShowSelectAll ? true : null"
            [columnMenu]="false"
            class="checkBoxColumn"
          >
          </kendo-grid-checkbox-column>
          <kendo-grid-column
            *ngFor="let column of tableConfig.columns"
            [field]="column.internalName"
            [hidden]="column.hidden"
            [title]="column.title"
          >
            <ng-container *ngIf="column.internalName === 'employeeTitle'">
              <ng-template kendoGridGroupHeaderTemplate let-group="group">
                <div [innerHTML]="group.value"></div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="column.internalName === 'month'">
              <ng-template kendoGridGroupHeaderTemplate let-group="group">
                <div [innerHTML]="group.value"></div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="column.internalName === 'year'">
              <ng-template kendoGridGroupHeaderTemplate let-group="group">
                <div [innerHTML]="group.value"></div>
              </ng-template>
            </ng-container>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Personenauswertung.xlsx" [collapsible]="true"></kendo-grid-excel>
        </kendo-grid>

        <kendo-excelexport [data]="filteredGridData" fileName="Products.xlsx"  #usersFlatExcelTable>
          <kendo-excelexport-column field="employeeTitle" title="Mitarbeiter">
          </kendo-excelexport-column>
          <kendo-excelexport-column width="100" field="month" title="Monat">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="year" title="Jahr">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="teamTitle" title="Team">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="percentTimeNumber" title="%-Aufteilung">
          </kendo-excelexport-column>
          <kendo-excelexport-column width="100" field="totalTimeInHoursNumber" title="gebuchte Stunden je Team">
          </kendo-excelexport-column>
        </kendo-excelexport>
      </div>
    </div>
  </div>
</div>
