import { NgModule } from '@angular/core';
import { DurationTimeComponent } from './duration-time.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [DurationTimeComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
    ],
    exports: [DurationTimeComponent],
})
export class DurationTimeModule {}
