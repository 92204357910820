<div class="page-layout simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <!-- APP TITLE -->


    <div fxLayout="column" fxLayoutAlign="center start">

      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-4" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">apps
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{ "TITLE" | translate }}
        </span>
      </div>

    </div>
    <!-- / APP TITLE -->

    <!-- SEARCH -->
    <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

      <div class="search-wrapper mt-4 mt-sm-0">

        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input [formControl]="searchInput" placeholder="Search for a contact">
        </div>

      </div>

    </div> -->
    <!-- / SEARCH -->

  </div>
  <!-- / HEADER -->

  <div id='content'>

    <div class="content">

      <div class="p-6">

        <!-- <app-highlight #highlightComponent style="width: 100%;" [configuration]="this.highLights">
        </app-highlight>

        <app-chart #chartComponent style="width: 100%;" [configuration]="this.charts"></app-chart> -->

      </div>

    </div>

  </div>

</div>
