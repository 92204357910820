export const locale = {
  lang: "de",
  data: {
    TITLE: "Newware - Dashboard",
    ACTIVEPROJECTS: "Aktive Projekte",
    CRITICALPROJECTS: "Kritische Projekte",
    UNCRITICALPROJECTS: "Unkritische Projekte",
    ACTIVETASKS: "Aktive Aufgaben",
    OVERDUETASKS: "Überfällige Aufgaben",
    NOTOVERDUETASKS: "Nichtüberfällige Aufgaben",
    PROJECTS: "Projekte",
    TASKS: "Aufgaben",
    ACTIVE: "Aktiv",
    CRITICAL: "Kritisch",
    UNCRITICAL: "Unkritisch",
    OVERDUE: "Überfällig",
    NOTOVERDUE: "Nicht überfällig",
  },
};
