import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1008Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const assignmentList = this.sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            const taskList = this.sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );
            let processStepList: IList;

            const promises = [
                // Add List Prozessschritte
                await this._updateService.addList('Prozessschritte', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Prozessschritte
                await new Promise<void>((resolve) => {
                    processStepList = this.sharePointService.web.lists.getByTitle(
                        'Prozessschritte'
                    );
                    resolve();
                }),
                // Add Field Bezeichnung to List Prozessschritte
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Bezeichnung)',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addMultilineText('Bezeichnung')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Schritt to List Prozessschritte
                await this._updateService.runFunctionIfFieldNotExists(
                    processStepList,
                    'Schritt',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            processStepList.fields.addNumber('Schritt').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Items to List Prozessschritte
                await processStepList.items.add({
                    Title: 'Backlog',
                    Schritt: '1',
                }),
                await processStepList.items.add({
                    Title: 'Bearbeitung',
                    Schritt: '2',
                }),
                await processStepList.items.add({
                    Title: 'Abgeschlossen',
                    Schritt: '3',
                }),
                // Add Field Prozessschritt to List Auftragsaufgaben
                await new Promise<void>((resolve, reject) => {
                    processStepList
                        .select('Id')()
                        .then(
                            (processStepListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskList,
                                        'Prozessschritt',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskList.fields
                                                    //     .addLookup(
                                                    //         'Prozessschritt',
                                                    //         processStepListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
