import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1040Component {
    bearbeitungsberechtigungxml =
        '<Field Type="UserMulti" DisplayName="Bearbeitungsberechtigung" List="UserInfo" Description="Erhalten Bearbeitungsberechtigung." ' +
        'Required="FALSE" EnforceUniqueValues="FALSE" ShowField="ImnName" UserSelectionMode="PeopleAndGroups" UserSelectionScope="0" Mult="TRUE" ' +
        'ortable="FALSE" ID="{40633bd9-42ab-4514-a88f-b37e8ee6a776}" SourceID="{94f82869-be4b-4ee9-a742-c39a75ac3e04}" ' +
        'StaticName="Bearbeitungsberechtigung" Name="Bearbeitungsberechtigung" ColName="int5" RowOrdinal="0" />';

    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const documentList = this.sharePointService.web.lists.getByTitle(
                'Auftragsdokumente'
            );

            const promises = [
                // Add Field Bearbeitungsberechtigung to List Auftragsdokumente
                await this._updateService.runFunctionIfFieldNotExists(
                    documentList,
                    'Bearbeitungsberechtigung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            documentList.fields
                                .createFieldAsXml(
                                    this.bearbeitungsberechtigungxml
                                )
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
