export const locale = {
    lang: 'de',
    data: {
        CONFIRM_DIALOG: {
            TITLE: 'Wählen Sie einen Standort',
            OK: 'OK',
            CANCEL: 'Abbrechen',
        },
        FORM: {
            LOCATION: 'Standorte'
        }
    },
};
