export const locale = {
    lang: 'en',
    data: {
        TABLE_TASK: {
            NOTIFICATIONS: {
                WAIT: 'Please wait.',
                MISSINGPATH: 'No path to the file server stored',
                CONNECTING: 'Establishing connection',
                MISSINGSTART: 'No start date entered',
            },
        },
    },
};
