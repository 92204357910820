import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1107Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const mailboxList: IList = this._sharePointService.web.lists.getByTitle(
                'Mailbox'
            );

            const promises = [
                
                // Add Field Attachment_Urls to List Mailbox
                await this._updateService.addField(
                    mailboxList,
                    'Attachment_Urls',
                    FieldType.MultilineText
                ),

            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
