export const locale = {
    lang: 'en',
    data: {
        PROCESSSTEPS: {
            SEARCH_LABEL: 'Search for a processstep',
            TITLE: 'Processsteps',
            NEW: 'New processstep',
            SAVE: 'Speichern',
            EDIT: 'Edit processstep',
            NOTIFICATIONS: {
                SUCCESS: {
                    EDIT: 'Entry successfully edited',
                    NEW: 'Successfully created new element',
                    ERROR: 'An error occured!',
                    SAVING: 'Saving element.',
                    WAIT: 'Please wait...',
                },
            },
            COLUMNS: {
                TITLE: 'Name',
                STEP: 'Step',
                STATUS: 'Change to taskstatus',
                NOTSTARTED: 'Not started',
                INPROCESSING: 'In processing',
                COMPLETED: 'Completed',
                DEFAULTVALUE: {
                    TITLE: 'Default value',
                    YES: 'Yes',
                    NO: 'No',
                },
                RESET: 'Reset',
                WAITING: 'Waiting',
            },
        },
    },
};
