export const locale = {
    lang: 'de',
    data: {
        TITLE: 'Zeitplan',
        START: 'Start',
        END: 'Ende',
        RESIZED: 'Projektzeit geändert',
        MOVED: 'Projekt verschoben',
        THISMONTH: 'Diesen Monat',
        THISYEAR: 'Dieses Jahr',
        NEXTYEAR: '2 Jahre',
        THREEYEARS: '3 Jahre',
        FOURYEARS: '4 Jahre',
        FIVEYEARS: '5 Jahre',
        CURRENT: 'Aktuell',
    },
};
