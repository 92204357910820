import { IList } from '@pnp/sp/presets/all';
import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';

export class UpdateToVersion1113Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            
            let signatureList: IList;

            const promises = [
                // Add List Signaturen
                await this._updateService.addList('Signaturen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Signaturen
                await new Promise<void>((resolve) => {
                    signatureList = this._sharePointService.web.lists.getByTitle(
                        'Signaturen'
                    );
                    resolve();
                }),
                // Add Field Text to List Signaturen
                await this._updateService.addField(
                    signatureList,
                    'Text',
                    FieldType.MultilineText
                ),
            ];


            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
