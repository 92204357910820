<app-sharepoint-table #tableGrid [configuration]="tableConfig">
  <ng-template customCell for="Rechnungssumme" let-dataItem="dataItem">
    {{ getBillsSumFromMap(dataItem.ID) | number: '1.2-2' }}
  </ng-template>

  <ng-template customCell for="Differenz" let-dataItem="dataItem">
    {{ getBillsDifferenceFromMap(dataItem.ID) | number: '1.2-2' }}
  </ng-template>

  <ng-template customCell for="Monat" let-dataItem="dataItem">
    <div *ngIf="dataItem.Monat == 1">{{ "MONTH.JANUARY" | translate }}</div>
    <div *ngIf="dataItem.Monat == 2">{{ "MONTH.FEBRUARY" | translate }}</div>
    <div *ngIf="dataItem.Monat == 3">{{ "MONTH.MARCH" | translate }}</div>
    <div *ngIf="dataItem.Monat == 4">{{ "MONTH.APRIL" | translate }}</div>
    <div *ngIf="dataItem.Monat == 5">{{ "MONTH.MAY" | translate }}</div>
    <div *ngIf="dataItem.Monat == 6">{{ "MONTH.JUNE" | translate }}</div>
    <div *ngIf="dataItem.Monat == 7">{{ "MONTH.JULY" | translate }}</div>
    <div *ngIf="dataItem.Monat == 8">{{ "MONTH.AUGUST" | translate }}</div>
    <div *ngIf="dataItem.Monat == 9">{{ "MONTH.SEPTEMBER" | translate }}</div>
    <div *ngIf="dataItem.Monat == 10">{{ "MONTH.OCTOBER" | translate }}</div>
    <div *ngIf="dataItem.Monat == 11">{{ "MONTH.NOVEMBER" | translate }}</div>
    <div *ngIf="dataItem.Monat == 12">{{ "MONTH.DECEMBER" | translate }}</div>
  </ng-template>
</app-sharepoint-table>
