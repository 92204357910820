export const locale = {
    lang: 'de',
    data: {
        HOLIDAY_DETAILS: {
            NEW: 'Neuer Feiertag',
            EDIT: 'Feiertag bearbeiten',
            NOTIFICATIONS: {
                SAVING: 'Eintrag speichern',
                WAIT: 'Bitte warten',
                SUCCESS: 'Der Feiertag wurde erfolgreich gestaltet',
                ERROR: 'Importfehler',
            },
            FORM: {
                TITLE: 'Name',
                DATE: 'Datum',
                LOCATION: 'Standorte',
                BUNDESLAND: 'Bundesland',
                SAVE: 'Speichern',
                FAKTOR: 'Faktor'
            }
        },
    },
};
