import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1140Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Add List Netzlaufwerkunterordner
            await this._updateService.addList(
                'Netzlaufwerkunterordner',
                '',
                100,
                {
                    EnableVersioning: true,
                }
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
