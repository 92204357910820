export const locale = {
    lang: 'en',
    data: {
        TABLE:{
            TITLE:"Position",
            SERVICE:"Service",
            PAYMENT: "Netto amount [€]",
        },
        NEW:"Create new position",
        SAVE: "Save",
        WAIT:"Please wait...",
        SUCCESS:"Saved successfully",
        POSITION:{
            NEW:"Create new Position",
            CHANGE:"Change Position",
            SAVE:"Change Entry"
        }
    }
};
