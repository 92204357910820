import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1054Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            let notificationList: IList;

            const promises = [
                // Add List Notifications
                await this._updateService.addList('Notifications', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Notifications
                await new Promise<void>((resolve) => {
                    notificationList = this.sharePointService.web.lists.getByTitle(
                        'Notifications'
                    );
                    resolve();
                }),
                // Add Field Text to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'Text',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields
                                .addMultilineText('Text')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Intervall to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'Intervall',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields.addNumber('Intervall').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field List to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'List',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields.addText('List').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Datefield to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'Datefield',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields.addText('Datefield').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Receiverfields Text to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'Receiverfields',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields
                                .addText('Receiverfields')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
