import { ConfirmDialogComponent } from 'src/app/main/administration/configuration/confirm-dialog/confirm-dialog.component';
import { MoveElementComponent } from './../move-element/move-element.component';
import { NgZone, OnDestroy } from '@angular/core';
import { TimeService } from './../../services/time.service';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Inject,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
} from '@angular/core';
import { GlobalTask } from '../../models/globaltask.model';
import * as CamlBuilder from 'camljs';
import {
    TableConfiguration,
    SharePointService,
    ColumnTyp,
    FormatTypes,
    TableColumn,
    SharePointTableComponent,
    UpdateService,
} from 'sp-office365-framework';
import { from, Subject } from 'rxjs';
import { TaskService } from '../../services/task.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
    FrameworkService,
} from 'sp-office365-layout';
import { ConfirmDialogTasksComponent } from './confirm-dialog-tasks/confirm-dialog-tasks.component';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { PermissionKind } from '@pnp/sp/presets/all';
import { Timelevel } from '../../models/timelevel.model';
import {
    MatDialogRef,
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogConfig,
} from '@angular/material/dialog';
import { MailListComponent } from 'sp-office365-layout/lib/src/app/layout/components/mail/mail-list/mail-list.component';
import { DetailMailComponent } from '../../mail/detail-mail/detail-mail.component';
import { MailService } from '../../services/mail.service';
import { IndexMailComponent } from '../../mail/index-mail/index-mail.component';
import { NewTimeComponent } from '../../time/new-time/new-time.component';
import { AssignMailComponent } from 'src/app/main/mail/assign-mail/assign-mail.component';
import { SPDocument } from '../../shared/models';
import { DocumentsService } from '../../services/documents.service';
import { ChangeDocumentStatus } from '../../document/change-document-name/enum';
import { HolidaysService } from '../../services/holidays.service';
import { MatTabGroup } from '@angular/material/tabs';
import { getCustomerTitle } from '../../shared/shared.util';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { DocTemplatesService } from '../../services/doc-templates.service';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-detail-task',
    templateUrl: './detail-task.component.html',
    styleUrls: ['./detail-task.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailTaskComponent implements OnInit, OnDestroy {
    @Input() filter: any;
    @Input() auftragsId: number;
    @Input() isTask: boolean;
    @Input() id: number;
    @Input() initData: GlobalTask;

    public formio_renderOptions = {
        // language: 'de',
    };

    public mailConfigIn = { mails: [] };
    public mailConfigOut = { mails: [] };
    public showReply = false;
    public isAssignedMail = true;
    private defaultValue: number;

    @ViewChild('taskTable', { static: false }) taskTable;
    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('timeGrid', { static: false })
    timeGrid: SharePointTableComponent;
    @ViewChild('mailIn', { static: false }) mailIn: MailListComponent;
    @ViewChild('mailOut', { static: false }) mailOut: MailListComponent;
    @ViewChild('newTimeComponent', { static: false })
    newTimeComponent: NewTimeComponent;
    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

    public confirmDialogRef: MatDialogRef<ConfirmDialogTasksComponent>;
    public confirmDialog: MatDialogRef<ConfirmDialogComponent>;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public sharePointItem;
    public assignmentItem;
    public wpItem;
    public formioConfiguration;
    public tableConfig: TableConfiguration;
    public tableConfigTasks: TableConfiguration;
    public columns: TableColumn[];

    public selectedUser;
    public isCreateMode = true;
    public isManager = false;
    public uploadMultipleDocuments = true;
    public documentTabHeader;
    public arbeitsPaketId;
    public defaultvalueWorkPackes;
    public timesEnabled = false;
    private deleteMode: boolean;

    private disableMark = false;
    private stateChanged = false;
    private firstTimeLoad = true;
    private dueDateHintParent: string;
    private startDateHintParent: string;
    public timeConfig;
    public ChangeDocumentStatus = ChangeDocumentStatus;
    public mailsSectionSelected = false;
    private searchGroups: string;
    private destroy$ = new Subject();

    get camlQueryTasks() {
        return new CamlBuilder()
            .Where()
            .LookupField('Arbeitspaket')
            .Id()
            .EqualTo(this.id)
            .OrderBy('Modified')
            .ToString();
    }

    get camlQueryProcessSteps() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Schritt')
            .ToString();
    }

    get camlQueryDocs() {
        return new CamlBuilder()
            .Where()
            .LookupField('Aufgabe')
            .Id()
            .EqualTo(this.id)
            .Or()
            .NumberField('FSObjType')
            .EqualTo(1)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public _documentService: DocumentsAssignmentService,
        public _taskService: TaskService,
        public _timeService: TimeService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailTaskComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _applicationService: ApplicationService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _updateService: UpdateService,
        private _mailService: MailService,
        private _frameworkService: FrameworkService,
        private _ngZone: NgZone,
        private _documentsService: DocumentsService,
        private _holidaysService: HolidaysService,
        private _docTemplateService: DocTemplatesService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.documentTabHeader = this._translateService.instant(
            'DETAIL_TASK.TABS.DOCS'
        );

        this._timeService.preserve();

        if (data.createMode) {
            this._timeService.editMode = true;
            this.data.auftragsId = this._timeService.projectId;
            this.data;
        }

        // reset timeservice
        this._timeService.reset();
    }

    ngOnDestroy(): void {
        this._timeService.editMode = false;
        this._timeService.reinit.next(undefined);
        this.destroy$.next(undefined);
    }

    ngOnInit() {
        this._timeService.setMaximumUserHoursPerDayLoading(true);
        this.auftragsId = this.data.auftragsId;
        this.isTask = this.data.isTask;
        this.id = this.data.taskId;
        this.initData = this.data._taskInitData;
        this.filter = this.data._filter;

        if (this.data.arbeitsPaketId) {
            this.arbeitsPaketId = this.data.arbeitsPaketId;
        } else if (!this.isTask) {
            this.arbeitsPaketId = this.id;
        }

        const promises = [];

        promises.push(this._holidaysService.loadHolidays());
        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Zeiterfassung')
                    .then((value) => {
                        if (value == 'Ja') {
                            this.timesEnabled = true;
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Usergruppen')
                    .then((value) => {
                        this.searchGroups = value;

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        // Get project
        if (this.auftragsId) {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            listTitle: 'Aufträge',
                            id: this.auftragsId,
                        })
                        .then((assignmentItem) => {
                            this.assignmentItem = assignmentItem;

                            if (!this.assignmentItem.Auftragsende) {
                            }

                            if (!this.isTask && assignmentItem.Auftragsende) {
                                this.dueDateHintParent =
                                    this._translateService.instant(
                                        'DETAIL_TASK.NOTIFICATIONS.DUEDATEHINT'
                                    ) +
                                    new Date(
                                        assignmentItem.Auftragsende
                                    ).toLocaleDateString();
                            }

                            if (!this.isTask && assignmentItem.Auftragsstart) {
                                this.startDateHintParent =
                                    this._translateService.instant(
                                        'DETAIL_TASK.NOTIFICATIONS.STARTDATEHINT'
                                    ) +
                                    new Date(
                                        assignmentItem.Auftragsstart
                                    ).toLocaleDateString();
                            }

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );
        }

        // Get WP
        if (this.arbeitsPaketId) {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            listTitle: 'Auftragsaufgaben',
                            id: this.arbeitsPaketId,
                        })
                        .then((wpItem) => {
                            this.wpItem = wpItem;

                            if (this.isTask && wpItem.DueDate) {
                                this.dueDateHintParent =
                                    this._translateService.instant(
                                        'DETAIL_TASK.NOTIFICATIONS.DUEDATEHINT'
                                    ) +
                                    new Date(
                                        wpItem.DueDate
                                    ).toLocaleDateString();
                            }

                            if (this.isTask && wpItem.StartDate) {
                                this.startDateHintParent =
                                    this._translateService.instant(
                                        'DETAIL_TASK.NOTIFICATIONS.STARTDATEHINT'
                                    ) +
                                    new Date(
                                        wpItem.StartDate
                                    ).toLocaleDateString();
                            }

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );
        }

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._sharepointService
                    .getListItems({
                        title: 'Prozessschritte',
                        camlQuery: this.camlQueryProcessSteps,
                        isDocumentLibrary: false,
                    })
                    .then((processSteps) => {
                        this._taskService.processSteps = processSteps;

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        if (this.id) {
            if (this.auftragsId) {
                // Get number of documents
                this._applicationService
                    .getNumberOfItemsWithoutFolders(
                        'Aufgabendokumente',
                        this.auftragsId + '',
                        new CamlBuilder()
                            .Where()
                            .LookupField('Aufgabe')
                            .Id()
                            .EqualTo(this.id)
                            .And()
                            .NumberField('FSObjType')
                            .EqualTo(0)
                            .OrderBy('Modified')
                            .ToString()
                    )
                    .then((length) => {
                        this.documentTabHeader =
                            this._translateService.instant(
                                'DETAIL_TASK.TABS.DOCS'
                            ) +
                            ' (' +
                            length +
                            ')';
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            if (!this.isTask) {
                // If AP in Edit Mode
                this._taskService.onStateRefreshed.subscribe((value) => {
                    if (this.sharePointItem && value.Kennzeichen) {
                        this.sharePointItem.Kennzeichen = value.Kennzeichen;
                        this.updateStateField();
                    }
                });

                promises.push(
                    // Kennzeichen im Arbeitspaket disabled wenn mind. 1 Aufgabe im Arbeitspaket
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getListItems({
                                title: 'Auftragsaufgaben',
                                isDocumentLibrary: false,
                                folderName: this.auftragsId + '',
                                camlQuery: new CamlBuilder()
                                    .View()
                                    .RowLimit(1)
                                    .Query()
                                    .Where()
                                    .ChoiceField('Aufgabenart')
                                    .EqualTo('Aufgabe')
                                    .And()
                                    .LookupField('Arbeitspaket')
                                    .Id()
                                    .EqualTo(this.id)
                                    .ToString(),
                            })
                            .then((result) => {
                                if (result.length > 0) {
                                    this.disableMark = true;
                                }

                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
            }
        }

        Promise.all(promises)
            .then(() => {
                this._timeService.calculateUserDailyHoursLimit();
                if (this.id != null) {
                    this.isCreateMode = false;

                    this._sharepointService
                        .getItemById({
                            id: this.id,
                            listTitle: 'Auftragsaufgaben',
                        })
                        .then((task) => {
                            this.sharePointItem = task;

                            this.loadMails();
                            this.mailIn.setAsCurrentList();

                            this.initForm();

                            // Check if currentUser is Manager
                            if (
                                this.assignmentItem.AuftragsleiterId ==
                                this._sharepointService.currentUser.Id
                            ) {
                                this.isManager = true;
                            }

                            // Set timelevel
                            this._timeService.level = Timelevel.Task;

                            // Set projectID
                            this._timeService.projectId =
                                this.assignmentItem.Id;

                            // Set taskID
                            this._timeService.taskId = this.sharePointItem.Id;

                            // Init timetable
                            this._timeService.initTimeTable(
                                this.isManager,
                                this.auftragsId + ''
                            );
                            this._timeService.tableConfig.customButtons = [
                                {
                                    name: 'Löschen',
                                    icon: 'delete',
                                },
                            ];
                            this.timeConfig = this._timeService.tableConfig;
                        });

                    this.tableConfigTasks = {
                        list: 'Auftragsaufgaben',
                        columns: [
                            {
                                internalName: 'Title',
                                title: 'Aufgabe',
                                hidden: false,
                            },
                            {
                                internalName: 'DueDate',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.DUEDATE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'AssignedTo',
                                title: 'Zugewiesen an',
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Prozessschritt',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.PROCESSSTEP'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Arbeitspaket',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.WORKPACKAGE'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.ANSPRECHPARTNER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title2',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.ANSPRECHPARTNER'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title3',
                                title: this._translateService.instant(
                                    'DETAIL_TASK.COLUMNS.ANSPRECHPARTNER'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'ID',
                                title: 'ID',
                                hidden: true,
                            },
                        ],
                        showEditButton: true,
                        showDeleteButton: true,
                        addCheckboxColumn: false,
                        recursiveAll: false,
                        folderName: this.auftragsId + '',
                        useMaterialDesign: true,
                        toolbar: [
                            {
                                title: this._translateService.instant(
                                    'DETAIL_TASK.NEWTASK'
                                ),
                                command: 'AddTask',
                                color: 'accent',
                            },
                            {
                                title: this._translateService.instant(
                                    'DETAIL_TASK.TASKSETS'
                                ),
                                command: 'TaskSets',
                                color: 'accent',
                            },
                            {
                                title: this._translateService.instant(
                                    'DETAIL_TASK.CRITICAL'
                                ),
                                command: 'changeQuery',
                                query: new CamlBuilder()
                                    .Where()
                                    .LookupField('Arbeitspaket')
                                    .Id()
                                    .EqualTo(this.arbeitsPaketId)
                                    .And()
                                    .ChoiceField('Kennzeichen')
                                    .EqualTo('Kritisch')
                                    .And()
                                    .ChoiceField('Aufgabenart')
                                    .EqualTo('Aufgabe')
                                    .OrderBy('Title')
                                    .ToString(),
                                color: 'basic',
                            },
                        ],
                        isDocumentLibrary: false,
                        camlQuery: this.camlQueryTasks,
                    };
                } else {
                    this._sharepointService
                        .getListItems({
                            title: 'Prozessschritte',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .ChoiceField('Standardwert')
                                .EqualTo('Ja')
                                .ToString(),
                        })
                        .then((result) => {
                            if (result.length) {
                                this.defaultValue = result[0].Id;
                            }
                            this.initForm();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onTabChange(ev) {
        this.mailIn.resetDetails();
        this.mailOut.resetDetails();
        this.showReply = false;

        // Set current
        if (
            ev.tab.textLabel ==
            this._translateService.instant('DETAIL_TASK.TABS.EMAILS.TITLE')
        ) {
            this.mailsSectionSelected = true;
            this.mailIn.setAsCurrentList();
            this.showReply = true;
        } else {
            this.mailsSectionSelected = false;
        }

        this.initForm();
    }

    onTabChangeMail(ev) {
        this.mailIn.resetDetails();
        this.mailOut.resetDetails();
        if (
            ev.tab.textLabel ==
            this._translateService.instant('DETAIL_TASK.TABS.EMAILS.IN')
        ) {
            this.mailIn.setAsCurrentList();
            this.showReply = true;
            this.isAssignedMail = true;
        } else if (
            ev.tab.textLabel ==
            this._translateService.instant('DETAIL_TASK.TABS.EMAILS.OUT')
        ) {
            this.mailOut.setAsCurrentList();
            this.showReply = false;
            this.isAssignedMail = false;
        }
    }

    loadMails(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._sharepointService
                .getListItems({
                    title: 'Mailbox',
                    isDocumentLibrary: false,
                    recursiveAll: false,
                    folderName: this.auftragsId + '',
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Arbeitspaket')
                        .Id()
                        .EqualTo(this.id)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .OrderByDesc('Modified')
                        .ToString(),
                })
                .then((mails) => {
                    mails.forEach((element) => {
                        if (element.Title == 'out') {
                            this.pushMailItem(element, this.mailConfigOut);
                        } else {
                            this.pushMailItem(element, this.mailConfigIn);
                        }
                    });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    pushMailItem(item, config) {
        if (!item.From) {
            item.From = 'Unbekannt';
        }

        const promises = [];
        const attachments = [];

        if (item.Attachment_Urls) {
            let urls = item.Attachment_Urls.split(';');
            urls.forEach((element) => {
                let tmp = element.split('/');
                let filename = tmp[tmp.length - 1];

                attachments.push({
                    // type: file.odata.type,
                    fileName: filename,
                    // preview:
                    //     this._sharePointService.sphostUrl +
                    //     file.ServerRelativePath.DecodedUrl,
                    url:
                        this._sharepointService.sphostUrl.split('/sites/')[0] +
                        element,
                    // size: '1.1Mb',
                });
            });
        }

        if (item.Attachments) {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService.web.lists
                        .getByTitle('Mailbox')
                        .items.getById(item.Id)
                        .attachmentFiles()
                        .then((files) => {
                            files.forEach((file) => {
                                attachments.push({
                                    // type: file.odata.type,
                                    fileName: file.FileName,
                                    // preview:
                                    //     this._sharePointService.sphostUrl +
                                    //     file.ServerRelativePath.DecodedUrl,
                                    url:
                                        this._sharepointService.sphostUrl +
                                        '/Lists/' +
                                        file.ServerRelativePath.DecodedUrl.split(
                                            'Lists/'
                                        )[1],
                                    // size: '1.1Mb',
                                });
                            });

                            resolve();
                        })
                        .catch((error) => reject(error));
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                const mailSendTime = item.SentDateTime
                    ? new Date(item.SentDateTime)
                    : new Date(item.Created);
                config.mails.push({
                    id: item.Id,
                    from: {
                        name: item.From,
                        avatar: '',
                        email: item.From,
                    },
                    to: [
                        {
                            name: item.To,
                            email: item.To,
                        },
                    ],
                    cc: [
                        {
                            name: item.CC,
                            email: item.CC,
                        },
                    ],
                    subject: item.Subject,
                    message: item.Body,
                    time: mailSendTime.toLocaleString(),
                    timeAsDate: mailSendTime,
                    read: false,
                    starred: false,
                    important: false,
                    hasAttachments: item.Attachments,
                    attachments,
                    labels: [],
                    folder: 0,
                    isTaskMail: !!item.ArbeitspaketId,
                });
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this._mailService.sortMailsByDateDESC(config);
            });
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
            dataForm.isTask = this.isTask;
            dataForm.Aufgabenart = 'Arbeitspaket';
            dataForm.StartDate = new Date();
            dataForm.Kennzeichen = 'Unkritisch';

            if (this.defaultValue) {
                dataForm.ProzessschrittId = this.defaultValue;
            }

            if (this.isTask) {
                // From Workpackage
                if (this.wpItem) {
                    if (this.wpItem.StartDate) {
                        dataForm.StartDate = new Date(this.wpItem.StartDate);
                    }
                    if (
                        this.wpItem.DueDate &&
                        this.wpItem.DueDate >= this.wpItem.StartDate
                    ) {
                        dataForm.DueDate = new Date(this.wpItem.DueDate);
                    }
                    if (this.wpItem.AssignedToId) {
                        dataForm.AssignedToId = this.wpItem.AssignedToId;
                    }
                }
            } else {
                // From Project
                if (this.assignmentItem) {
                    if (this.assignmentItem.Auftragsstart) {
                        dataForm.StartDate = new Date(
                            this.assignmentItem.Auftragsstart
                        );
                    }
                    if (this.assignmentItem.Auftragsende) {
                        dataForm.DueDate = new Date(
                            this.assignmentItem.Auftragsende
                        );
                    }
                    dataForm.AssignedToId =
                        this._sharepointService.currentUser.Id;
                }
            }

            if (this.isTask) {
                dataForm.Aufgabenart = 'Aufgabe';

                if (this.arbeitsPaketId) {
                    dataForm.ArbeitspaketId = this.arbeitsPaketId;
                }
            }
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_TASK.NOTIFICATIONS.SAVINGITEM'
            ),
            this._translateService.instant('DETAIL_TASK.NOTIFICATIONS.WAIT')
        );

        // Remove not existing columns
        delete e.data.submit;
        delete e.data.isTask;
        delete e.data.Prozessschritt;
        delete e.data.Ansprechpartner;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        // Set Satus
        let processStepItem = this._taskService.processSteps.find(
            (x) => x.Id == e.data.ProzessschrittId
        );
        if (processStepItem && processStepItem.Aufgabenstatus) {
            e.data.Status = processStepItem.Aufgabenstatus;
        }

        if (this.sharePointItem) {
            // Set values on edit
            this.stateChanged =
                this.sharePointItem.Kennzeichen != e.data.Kennzeichen;

            return from(
                this._sharepointService
                    .updateItem({
                        id: this.id,
                        listTitle: 'Auftragsaufgaben',
                        newFiledValues: e.data,
                    })
                    .then(() => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Auftragsaufgaben',
                                id: this.sharePointItem.Id,
                            })
                            .then((item) => {
                                const promises = [];

                                if (!this.isTask) {
                                    // Check if duration changed
                                    promises.push(
                                        this._taskService.syncDurationOfChildren(
                                            this.sharePointItem,
                                            item,
                                            true
                                        )
                                    );
                                }

                                this.sharePointItem = item;

                                if (this.stateChanged) {
                                    let parentItemID = this.arbeitsPaketId;

                                    if (!this.isTask) {
                                        parentItemID = this.auftragsId;
                                    }

                                    // Update state of parent item
                                    promises.push(
                                        this._taskService.updateStateOfParentItem(
                                            this.isTask,
                                            parentItemID,
                                            this.sharePointItem
                                        )
                                    );
                                }

                                Promise.all(promises)
                                    .then(() => {
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_TASK.NOTIFICATIONS.SUCCESSITEM'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close('success');
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Set values on create
            if (this.auftragsId) {
                e.data.AuftragId = this.auftragsId;
            }
            this.stateChanged = 'Unkritisch' != e.data.Kennzeichen;

            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Auftragsaufgaben',
                        data: e.data,
                        folder: this.auftragsId.toString(),
                    })
                    .then((newItemId) => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Auftragsaufgaben',
                                id: newItemId,
                            })
                            .then((item) => {
                                this.sharePointItem = item;

                                const promises = [];

                                // Add folders
                                promises.push(
                                    this._taskService.addFoldersForTask(
                                        this.sharePointItem.Id,
                                        this.auftragsId
                                    )
                                );

                                if (this.stateChanged) {
                                    let parentItemID = this.arbeitsPaketId;

                                    if (!this.isTask) {
                                        parentItemID = this.auftragsId;
                                    }

                                    // Update state of parent item
                                    promises.push(
                                        this._taskService.updateStateOfParentItem(
                                            this.isTask,
                                            parentItemID,
                                            this.sharePointItem
                                        )
                                    );
                                }

                                Promise.all(promises)
                                    .then(() => {
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_TASK.NOTIFICATIONS.SUCCESSITEM'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close({
                                            message: 'success',
                                            ItemId: newItemId,
                                        });
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    checkUserPermissionInProject() {
        this._sharepointService
            .getItemById({ listTitle: 'Aufträge', id: this.auftragsId })
            .then((project) => {
                this._sharepointService.web.lists
                    .getByTitle('Aufträge')
                    .items.getById(project.ID)
                    .userHasPermissions(
                        this.selectedUser.LoginName,
                        PermissionKind.EditListItems
                    )
                    .then((result) => {
                        if (!result) {
                            this.confirmDialogRef = this.dialog.open(
                                ConfirmDialogTasksComponent,
                                {
                                    data: {
                                        confirmMessage:
                                            this._translateService.instant(
                                                'DETAIL_TASK.GIVEUSERPERMISSIONTOPROJECT'
                                            ),
                                    },
                                }
                            );

                            this.confirmDialogRef
                                .afterClosed()
                                .subscribe((result) => {
                                    if (result) {
                                        this._loadingService.show(
                                            this._translateService.instant(
                                                'DETAIL_TASK.NOTIFICATIONS.SAVING'
                                            ),
                                            this._translateService.instant(
                                                'DETAIL_TASK.NOTIFICATIONS.WAIT'
                                            )
                                        );

                                        // Set Permissions
                                        let newValues = [];

                                        if (
                                            project.BearbeitungsberechtigungId
                                        ) {
                                            if (
                                                project
                                                    .BearbeitungsberechtigungId
                                                    .results
                                            ) {
                                                newValues =
                                                    project
                                                        .BearbeitungsberechtigungId
                                                        .results;
                                            } else {
                                                newValues =
                                                    project.BearbeitungsberechtigungId;
                                            }
                                        }

                                        newValues.push(this.selectedUser.Id);

                                        this._sharepointService
                                            .updateItem({
                                                id: project.Id,
                                                listTitle: 'Aufträge',
                                                newFiledValues: {
                                                    BearbeitungsberechtigungId:
                                                        {
                                                            results: newValues,
                                                        },
                                                },
                                            })
                                            .then(() => {
                                                this._sharepointService
                                                    .getItemById({
                                                        listTitle: 'Aufträge',
                                                        id: this.auftragsId,
                                                    })
                                                    .then((assignment) => {
                                                        this._applicationService
                                                            .updateAssignemntPermissions(
                                                                assignment
                                                            )
                                                            .then(() => {
                                                                // Success
                                                                this._loadingService.hide();

                                                                this._snackBarService.add(
                                                                    this._translateService.instant(
                                                                        'DETAIL_TASK.NOTIFICATIONS.SUCCESS'
                                                                    ),
                                                                    '',
                                                                    {
                                                                        duration: 4000,
                                                                        panelClass:
                                                                            'success-dialog',
                                                                    }
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                this._loadingService.hide();
                                                                console.error(
                                                                    error
                                                                );
                                                            });
                                                    })
                                                    .catch((error) => {
                                                        this._loadingService.hide();
                                                        console.error(error);
                                                    });
                                            })
                                            .catch((error) => {
                                                this._loadingService.hide();
                                                console.error(error);
                                            });
                                    }

                                    this.confirmDialogRef = null;
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }

            if (ev.changed.component.key === 'DueDate') {
                // Check DueDate
                // Workpackage
                let maxDueDate = new Date(this.assignmentItem.Auftragsende);
                let minDueDate = new Date(this.assignmentItem.Auftragsstart);
                let notification = this._translateService.instant(
                    'DETAIL_TASK.NOTIFICATIONS.DUEDATEERROR'
                );

                // Task
                if (this.isTask && this.wpItem && this.wpItem.DueDate) {
                    this.wpItem;
                    maxDueDate = new Date(this.wpItem.DueDate);
                    minDueDate = new Date(this.wpItem.StartDate);
                    notification = this._translateService.instant(
                        'DETAIL_TASK.NOTIFICATIONS.DUEDATEERRORTASK'
                    );
                }

                const dueDate = this._frameworkService.dateOnly(
                    ev.data.DueDate
                );
                let startDate;
                if (ev.data.StartDate) {
                    startDate = new Date(ev.data.StartDate);
                }

                // Check duedate is greater than startdate
                if (dueDate && startDate && dueDate < startDate) {
                    minDueDate = startDate;
                    notification = this._translateService.instant(
                        'DETAIL_TASK.NOTIFICATIONS.STARTDUEDATEERROR'
                    );
                }

                const data = JSON.parse(JSON.stringify(ev.data));

                if (minDueDate > dueDate) {
                    // Set DueDate Min
                    data.DueDate = minDueDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                } else if (maxDueDate < dueDate) {
                    // Set DueDate Max
                    data.DueDate = maxDueDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            } else if (ev.changed.component.key === 'StartDate') {
                // Check StartDate
                // Workpackage
                let maxStartDate = new Date(this.assignmentItem.Auftragsende);
                let minStartDate = new Date(this.assignmentItem.Auftragsstart);
                let notification = this._translateService.instant(
                    'DETAIL_TASK.NOTIFICATIONS.STARTDATEERROR'
                );

                // Task
                if (this.isTask && this.wpItem && this.wpItem.DueDate) {
                    this.wpItem;
                    maxStartDate = new Date(this.wpItem.DueDate);
                    minStartDate = new Date(this.wpItem.StartDate);
                    notification = this._translateService.instant(
                        'DETAIL_TASK.NOTIFICATIONS.STARTDATEERRORTASK'
                    );
                }

                const startDate = new Date(ev.data.StartDate);
                let dueDate;
                if (ev.data.DueDate) {
                    dueDate = this._frameworkService.dateOnly(ev.data.DueDate);
                }

                // Check duedate is greater than startdate
                if (dueDate && startDate && dueDate < startDate) {
                    maxStartDate = dueDate;
                    notification = this._translateService.instant(
                        'DETAIL_TASK.NOTIFICATIONS.STARTDUEDATEERROR'
                    );
                }

                const data = JSON.parse(JSON.stringify(ev.data));

                if (minStartDate > startDate) {
                    // Set StartDate Min
                    data.StartDate = minStartDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                } else if (maxStartDate < startDate) {
                    // Set StartDate Max
                    data.StartDate = maxStartDate;

                    this._snackBarService.add(notification, '', {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    });

                    this.refreshForm.emit({
                        submission: {
                            data,
                        },
                    });
                }
            }
        }
    }

    updateStateField() {
        this._sharepointService
            .getItemById({
                listTitle: 'Auftragsaufgaben',
                id: this.id,
            })
            .then((sharePointItem) => {
                this.sharePointItem = sharePointItem;

                this._sharepointService
                    .getListItems({
                        title: 'Auftragsaufgaben',
                        isDocumentLibrary: false,
                        folderName: this.auftragsId + '',
                        camlQuery: new CamlBuilder()
                            .View()
                            .RowLimit(1)
                            .Query()
                            .Where()
                            .ChoiceField('Aufgabenart')
                            .EqualTo('Aufgabe')
                            .And()
                            .LookupField('Arbeitspaket')
                            .Id()
                            .EqualTo(this.id)
                            .ToString(),
                    })
                    .then((result) => {
                        if (result.length > 0) {
                            this.disableMark = true;
                            // this.formioConfiguration.components[4].columns[1].components[2].disabled = true;
                        } else {
                            this.disableMark = false;
                            // this.formioConfiguration.components[4].columns[1].components[2].disabled = true;
                        }

                        this.formioReady(null);
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    });
            })
            .catch((error) => {
                this._loadingService.hide();
                console.error(error);
            });
    }

    moveElement(e) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            projectId: this.assignmentItem.Id,
            taskId: this.data.taskId,
            itemId: e.dataItem.Id,
            fileRef: e.dataItem.FieldValuesAsText.FileRef,
            objectType: e.dataItem.FieldValuesAsText.FSObjType,
        };

        const dialogRef = this.dialog.open(MoveElementComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this.documentGrid.refresh();
        });
    }

    openDialog(item, rowClick?: boolean): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            id: item.Id,
            item: item,
            editMode: true,
            disableProjectAndTask: true,
        };

        if (!this.deleteMode && rowClick) {
            const dialogRef = this.dialog.open(NewTimeComponent, dialogConfig);

            dialogRef.afterClosed().subscribe((result) => {
                this.newTimeComponent.setValues();
                if (!result && !this._timeService.timeUpdated) {
                    return;
                }

                // Refresh table
                this.timeGrid.refresh();
                this._timeService.timeUpdated = false;
            });
        } else if (this.deleteMode && !rowClick) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    confirmMessage: this._translateService.instant(
                        'DETAIL_TASK.DIALOG.PERMISSION'
                    ),
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.deleteMode = false;
                if (result) {
                    this._timeService
                        .deleteTimeItem(item)
                        .then(() => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'DETAIL_TASK.DIALOG.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                            this.timeGrid.refresh();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            });
        }
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
            case 'AddFolder':
                this._documentService.addNewFolder(
                    this.documentGrid,
                    this.tableConfig.list
                );
                break;
            case 'CreateDocument':
                let itemOptions: {
                    itemId: number;
                    listName: string;
                    lookupFieldName?: string;
                }[] = [
                    {
                        itemId: +this.assignmentItem.Id,
                        listName: 'Aufträge',
                    },
                    { itemId: this.id, listName: 'Auftragsaufgaben' },
                ];

                if (this.assignmentItem) {
                    let lookUpFieldsAssignment = [
                        {
                            internalName: 'AuftragskategorieId',
                            listName: 'Auftragskategorien',
                        },
                        {
                            internalName: 'AuftragsunterkategorieId',
                            listName: 'Auftragsunterkategorien',
                        },
                        {
                            internalName: 'TeamId',
                            listName: 'Teams',
                        },
                        {
                            internalName: 'FachbereichId',
                            listName: 'Fachbereiche',
                        },
                        {
                            internalName: 'StandortId',
                            listName: 'Standorte',
                        },
                        {
                            internalName: 'KundeId',
                            listName: 'Firmen',
                        },
                        {
                            internalName: 'ProjektsteuererId',
                            listName: 'Firmen',
                        },
                        {
                            internalName: 'AntragId',
                            listName: 'Anträge',
                        },
                        {
                            internalName: 'KostenstelleId',
                            listName: 'Kostenstellen',
                        },
                    ];

                    // LookupItem mitgeben wenn befüllt
                    lookUpFieldsAssignment.forEach((element) => {
                        if (this.assignmentItem[element.internalName]) {
                            itemOptions.push({
                                itemId: this.assignmentItem[
                                    element.internalName
                                ],
                                listName: element.listName,
                                lookupFieldName: element.internalName,
                            });
                        }
                    });
                }

                if (this.sharePointItem) {
                    let lookUpFieldsTask = [
                        {
                            internalName: 'AuftraggeberId',
                            listName: 'Firmen',
                        },
                        {
                            internalName: 'AufgabentypId',
                            listName: 'Aufgabentypen',
                        },
                        {
                            internalName: 'ProzessschrittId',
                            listName: 'Prozessschritte',
                        },
                    ];

                    // LookupItem mitgeben wenn befüllt
                    lookUpFieldsTask.forEach((element) => {
                        if (this.sharePointItem[element.internalName]) {
                            itemOptions.push({
                                itemId: this.sharePointItem[
                                    element.internalName
                                ],
                                listName: element.listName,
                                lookupFieldName: element.internalName,
                            });
                        }
                    });
                }

                this._docTemplateService.openDocTemplateDialog(
                    itemOptions,
                    {
                        docListName: 'Aufgabendokumente',
                        docLFolderName: this.assignmentItem.Id + '/' + this.id,
                    },
                    { AuftragId: this.assignmentItem.Id, AufgabeId: this.id },
                    this.documentGrid,
                    'Arbeitspaket'
                );
                break;
            case 'moveElement':
                this.moveElement(e);
            case 'Löschen':
                this.deleteMode = true;
                this.openDialog(e.dataItem);
                break;
        }
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_TASK.COLUMNS.TITLE'
                    ),
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    validate: {
                        required: true,
                        customMessage: '{{field}} ist ein Pflichtfeld',
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_TASK.COLUMNS.DESCRIPTION'
                    ),
                    type: 'textarea',
                    input: true,
                    key: 'Body',
                    inputFormat: 'plain',
                    rows: 8,
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.ASSIGNED'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AssignedToId',
                                    valueProperty: 'Id',
                                    selectThreshold: 0.3,
                                    showAllOptionsIfEmpty: false,
                                    isPeoplePicker: true,
                                    // useGraph: !window['useApp'],
                                    removable: true,
                                    searchGroups: this.searchGroups,
                                    onSelected: (control, data) => {
                                        if (data.Id) {
                                            this.selectedUser = data;
                                            this.checkUserPermissionInProject();
                                        }
                                    },
                                    isCreateMode: this.isCreateMode,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.PRIORITY'
                                    ),
                                    type: 'select',
                                    input: true,
                                    key: 'Priority',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.HIGH'
                                                ),
                                                value: '(1) Hoch',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.NORMAL'
                                                ),
                                                value: '(2) Normal',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.LOW'
                                                ),
                                                value: '(3) Niedrig',
                                            },
                                        ],
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.START'
                                    ),
                                    format: 'dd.MM.yyyy',
                                    type: 'datetime',
                                    key: 'StartDate',
                                    enableTime: false,
                                    description: this.startDateHintParent,
                                    validate: {
                                        required: true,
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.KIND'
                                    ),
                                    type: 'select',
                                    key: 'Aufgabenart',
                                    disabled: true,
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.WORKPACKAGE'
                                                ),
                                                value: 'Arbeitspaket',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.TASK'
                                                ),
                                                value: 'Aufgabe',
                                            },
                                        ],
                                    },
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    validate: {
                                        required: true,
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.TYPE'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AufgabentypId',
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    list: 'Aufgabentypen',
                                    searchQuery: new CamlBuilder()
                                        .Where()
                                        .TextField('Title')
                                        .Contains('<%searchText%>')
                                        .OrderBy('Title')
                                        .ToString(),
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.PROCESSSTEP'
                                    ),
                                    hideLabel: false,
                                    placeholder: '',
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'ProzessschrittId',
                                    list: 'Prozessschritte',
                                    searchQuery: new CamlBuilder()
                                        .Where()
                                        .TextField('Title')
                                        .Contains('<%searchText%>')
                                        .OrderBy('Title')
                                        .ToString(),
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    isCreateMode: this.isCreateMode,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.DUEDATE'
                                    ),
                                    format: 'dd.MM.yyyy',
                                    type: 'datetime',
                                    key: 'DueDate',
                                    enableTime: false,
                                    description: this.dueDateHintParent,
                                    validate: {
                                        required: true,
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                    },
                                    customOptions: {
                                        locale: German,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_TASK.COLUMNS.MARK'
                                    ),
                                    disabled: this.disableMark,
                                    type: 'select',
                                    input: true,
                                    key: 'Kennzeichen',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.CRITICAL'
                                                ),
                                                value: 'Kritisch',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'DETAIL_TASK.COLUMNS.UNCRITICALLY'
                                                ),
                                                value: 'Unkritisch',
                                            },
                                        ],
                                    },
                                    valueProperty: 'value',
                                    selectThreshold: 0.3,
                                    lazyLoad: false,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_TASK.COLUMNS.WORKPACKAGE'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'ArbeitspaketId',
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    list: 'Auftragsaufgaben',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .ChoiceField('Aufgabenart')
                        .EqualTo('Arbeitspaket')
                        .And()
                        .LookupField('Auftrag')
                        .Id()
                        .EqualTo(this.auftragsId)
                        .And()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .ChoiceField('Aufgabenart')
                        .EqualTo('Arbeitspaket')
                        .And()
                        .LookupField('Auftrag')
                        .Id()
                        .EqualTo(this.auftragsId)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    hidden: !this.isTask,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_TASK.COLUMNS.ANSPRECHPARTNER'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AuftraggeberId',
                    selectThreshold: 0.3,
                    list: 'Firmen',
                    isPeoplePicker: false,
                    removable: true,
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isCreateMode: this.isCreateMode,
                    formatTitle: getCustomerTitle,
                    addComponent: DetailCustomerComponent,
                },
                {
                    label: 'isTask',
                    hidden: true,
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'checkbox',
                    input: true,
                    key: 'isTask',
                    defaultValue: false,
                    tab: 0,
                },
                {
                    label: this._translateService.instant('DETAIL_TASK.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };

        if (!this.id) {
            return;
        }

        this.columns = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DETAIL_TASK.DOCS.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant(
                    'DETAIL_TASK.DOCS.COLUMNS.FILESIZE'
                ),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DETAIL_TASK.DOCS.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DETAIL_TASK.DOCS.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            {
                internalName: 'CopyColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'RenameColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'FileSystemObjectType',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Aufgabendokumente',
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this.camlQueryDocs,
            folderName: this.auftragsId + '/' + this.id,
            rootFolderName: this._translateService.instant(
                'DETAIL_TASK.DOCS.TITLE'
            ),
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant(
                        'DETAIL_TASK.DOCS.NEW'
                    ),
                    command: 'Add',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant(
                        'DETAIL_TASK.DOCS.NEWFOLDER'
                    ),
                    command: 'AddFolder',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant(
                        'DETAIL_TASK.DOCS.CREATE_DOCUMENT'
                    ),
                    command: 'CreateDocument',
                    color: 'accent',
                },
            ],
            afterDeleteItem: () => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DETAIL_TASK.NOTIFICATIONS.SUCCESS_DELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
            },
            afterRefreshTable: () => {
                if (this.id && this.auftragsId) {
                    // Get number of documents
                    this._applicationService
                        .getNumberOfItemsWithoutFolders(
                            'Aufgabendokumente',
                            this.auftragsId + '',
                            new CamlBuilder()
                                .Where()
                                .LookupField('Aufgabe')
                                .Id()
                                .EqualTo(this.id)
                                .And()
                                .NumberField('FSObjType')
                                .EqualTo(0)
                                .OrderBy('Modified')
                                .ToString()
                        )
                        .then((length) => {
                            this.documentTabHeader =
                                this._translateService.instant(
                                    'DETAIL_TASK.TABS.DOCS'
                                ) +
                                ' (' +
                                length +
                                ')';
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
        };
    }

    send() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            auftragsId: this.assignmentItem.Id,
            projectMail: this.assignmentItem.ProjectMail,
            taskId: this.id,
            taskAuftraggeberId: this.sharePointItem.AuftraggeberId,
            project: this.assignmentItem,
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    public assign(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            auftragsId: this.assignmentItem.Id,
            isInComingEmail: this.isAssignedMail,
            taskId: this.id,
        };
        this._ngZone.runOutsideAngular(() => {
            const dialogRef = this.dialog.open(
                AssignMailComponent,
                dialogConfig
            );

            dialogRef.afterClosed().subscribe((result) => {
                this._mailService.attachmentFiles = [];

                if (result && result.elements) {
                    result.elements.forEach((element) => {
                        if (this.isAssignedMail) {
                            this.pushMailItem(element, this.mailConfigIn);
                        } else {
                            this.pushMailItem(element, this.mailConfigOut);
                        }
                        console.error('pushed mailitem', element);
                    });
                }
            });
        });
    }

    index() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.assignmentItem.Id,
            taskId: this.id,
        };

        const dialogRef = this.dialog.open(IndexMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.elements) {
                result.elements.forEach((element) => {
                    this.mailConfigIn.mails = this.mailConfigIn.mails.filter(
                        (x) => x.id != element
                    );
                    this.mailIn.mails = this.mailIn.mails.filter(
                        (x) => x.id != element
                    );
                    this.mailConfigOut.mails = this.mailConfigOut.mails.filter(
                        (x) => x.id != element
                    );
                    this.mailOut.mails = this.mailOut.mails.filter(
                        (x) => x.id != element
                    );
                });
            }
        });
    }

    mailRE(replyAll?: boolean) {
        const currentMail = this.mailIn.currentMail;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            auftragsId: this.assignmentItem.Id,
            isReply: true,
            replySubject: 'AW: ' + this.mailIn.currentMail.subject,
            replyTo: this.mailIn.currentMail.from.email, //{ results: [this.mailProject.currentMail.from.email], } ,
            project: this.assignmentItem,
            email: this.mailIn.currentMail,
            mailContent: currentMail.message,
            replyAll,
            taskId: this.id,
            taskAuftraggeberId: this.sharePointItem.AuftraggeberId,
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    forwardTo(): void {
        const currentMail = this.mailIn.currentMail;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.height = '95vh';

        dialogConfig.data = {
            auftragsId: this.assignmentItem.Id,
            replySubject: 'FW: ' + this.mailIn.currentMail.subject,
            mailContent: currentMail.message,
            project: this.assignmentItem,
            email: this.mailIn.currentMail,
            forwardTo: true,
            taskId: this.id,
            taskAuftraggeberId: this.sharePointItem.AuftraggeberId,
        };

        const dialogRef = this.dialog.open(DetailMailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this._mailService.attachmentFiles = [];

            if (result && result.element) {
                this.pushMailItem(result.element, this.mailConfigOut);
            }
        });
    }

    public getMailListBasedOnTab() {
        return this.matTabGroup.selectedIndex === 0
            ? this.mailIn
            : this.mailOut;
    }

    public filterEmail(filterFields: string[]): void {
        this.getMailListBasedOnTab().filterEmails(
            filterFields[0],
            filterFields[1],
            filterFields[2]
        );
    }

    public openRenameDocumentDialog(
        document: SPDocument,
        mode: ChangeDocumentStatus
    ): void {
        this._documentsService
            .openRenameDocumentDialog(this.id, document, mode)
            .subscribe(() => {
                this.documentGrid.refresh();
            });
    }

    public onSelectNewDocumentFile(event: Event): void {
        if (!this.sharePointItem?.Id || !this.auftragsId) {
            console.error('Failed to upload a file');
            return;
        }

        const targetFolderPath =
            `${this._sharepointService.computeServerRelativeUrl(this._sharepointService.sphostUrl)}/${'Aufgabendokumente'}/${this.auftragsId}/${this.sharePointItem.Id}`;
        from(this._applicationService.ensureFolder(targetFolderPath, 'Aufgabendokumente', String(this.auftragsId), String(this.sharePointItem.Id), true))
            .subscribe(() => {
                this._documentService.fileChangeEvent(
                    event,
                    this.documentGrid,
                    this.tableConfig.list,
                    { AuftragId: this.auftragsId, AufgabeId: this.id }
                );
            });
    }
}
