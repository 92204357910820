import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';
import * as CamlBuilder from 'camljs';
import { ApplicationService } from 'src/app/main/services/application.service';
import { TaskService } from 'src/app/main/services/task.service';

export class UpdateToVersion1060Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService,
        private taskService: TaskService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this.sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            const taskList = this.sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );
            let taskDocsList: IList;

            const promises = [
                // Add List Aufgabendokumente
                await this._updateService.addList(
                    'Aufgabendokumente',
                    '',
                    101,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Aufgabendokumente
                await new Promise<void>((resolve) => {
                    taskDocsList = this.sharePointService.web.lists.getByTitle(
                        'Aufgabendokumente'
                    );
                    resolve();
                }),
                // Add Field Auftrag to List Aufgabendokumente
                await new Promise<void>((resolve, reject) => {
                    projectList
                        .select('Id')()
                        .then(
                            (projectListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskDocsList,
                                        'Auftrag',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskDocsList.fields
                                                    //     .addLookup(
                                                    //         'Auftrag',
                                                    //         projectListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Aufgabe to List Aufgabendokumente
                await new Promise<void>((resolve, reject) => {
                    taskList
                        .select('Id')()
                        .then(
                            (taskListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskDocsList,
                                        'Aufgabe',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskDocsList.fields
                                                    //     .addLookup(
                                                    //         'Aufgabe',
                                                    //         taskListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add folders for existing projects and tasks in List Aufgabendokumente
                await new Promise<void>((resolve, reject) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Aufträge',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                        })
                        .then(
                            (items) => {
                                const promises2 = [];

                                items.forEach((item) => {
                                    promises2.push(
                                        new Promise<void>((resolve, reject) => {
                                            const promises3 = [];

                                            // Add project-folders
                                            promises3.push(
                                                this.sharePointService.addFolder(
                                                    {
                                                        listTitle:
                                                            'Aufgabendokumente',
                                                        folderName:
                                                            item.Id + '',
                                                        isDocumentLibrary: true,
                                                    }
                                                )
                                            );

                                            promises3.push(
                                                new Promise<void>(
                                                    (resolve, reject) => {
                                                        this.sharePointService
                                                            .getListItems({
                                                                title:
                                                                    'Auftragsaufgaben',
                                                                isDocumentLibrary: false,
                                                                recursiveAll: true,
                                                                camlQuery: new CamlBuilder()
                                                                    .Where()
                                                                    .LookupField(
                                                                        'Auftrag'
                                                                    )
                                                                    .Id()
                                                                    .EqualTo(
                                                                        item.Id
                                                                    )
                                                                    .ToString(),
                                                            })
                                                            .then(
                                                                (taskItems) => {
                                                                    const promises4 = [];

                                                                    taskItems.forEach(
                                                                        (
                                                                            taskItem
                                                                        ) => {
                                                                            // Add task-folders
                                                                            promises4.push(
                                                                                this.taskService.addFoldersForTask(
                                                                                    taskItem.Id,
                                                                                    item.Id
                                                                                )
                                                                            );
                                                                        }
                                                                    );

                                                                    Promise.all(
                                                                        promises4
                                                                    ).then(
                                                                        () => {
                                                                            resolve();
                                                                        },
                                                                        (
                                                                            error
                                                                        ) => {
                                                                            reject(
                                                                                error
                                                                            );
                                                                        }
                                                                    );
                                                                },
                                                                (error) => {
                                                                    //
                                                                    reject(
                                                                        error
                                                                    );
                                                                }
                                                            );
                                                    }
                                                )
                                            );

                                            Promise.all(promises3).then(
                                                () => {
                                                    resolve();
                                                },
                                                (error) => {
                                                    reject(error);
                                                }
                                            );
                                        })
                                    );
                                });

                                Promise.all(promises2).then(
                                    () => {
                                        resolve();
                                    },
                                    (error) => {
                                        reject(error);
                                    }
                                );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Set permissions to new folders in List Aufgabendokumente
                await new Promise<void>((resolve) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Aufträge',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                        })
                        .then(
                            (items) => {
                                const promises3 = [];

                                items.forEach((item) => {
                                    // Update Permision of folders
                                    promises3.push(
                                        this._applicationService.updateFolderPermissions(
                                            item,
                                            {
                                                listTitle: 'Aufgabendokumente',
                                                isDocumentLibrary: true,
                                            }
                                        )
                                    );
                                });

                                Promise.all(promises3).then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
