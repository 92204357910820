import { LookupService } from './../../services/lookup.service';
import { TablePositionComponent } from './../../position/table-position/table-position.component';
import { OrderService } from './../../services/order.service';
import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import * as CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import {
    FrameworkService,
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialog } from '@angular/material/dialog';
import { getCustomerTitle } from 'src/app/main/shared/shared.util';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-detail-order',
    templateUrl: './detail-order.component.html',
    styleUrls: ['./detail-order.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailOrderComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de',
    };

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public id: number;
    public itemTitle = '';
    public sharePointItem;
    public formioConfiguration;
    public triggerChange = false;
    public firstTimeLoad = true;
    public author;
    public createDate;

    public disableMark = false;
    public isCreateMode = true;
    public isAcceptor = false;
    public header;

    @ViewChild('formioComponent', { static: true })
    formioComponent: FormioComponent;

    constructor(
        public _applicationService: ApplicationService,
        public _updateService: UpdateService,
        public _orderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private _sharepointService: SharePointService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _frameworkService: FrameworkService,
        private _lookupService: LookupService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnDestroy() {
        this._lookupService.teams = [];
        this._lookupService.teamsFromLocation = [];
        this._lookupService.teamsFirstTimeLoad = [];
        this._lookupService.location = null;
        this._lookupService.locationLookUpId = null;
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if offerId Changed
            if (this.id != this._orderService.currentOrderId) {
                this._orderService.getSideBarConfig(this.id);
            }

            const promises = [];

            if (this.id) {
                // Edit mode
                this.isCreateMode = false;

                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        try {
                            // Check if User is in Genehmiger role
                            this.isAcceptor =
                                await this._applicationService.isUserInRole(
                                    'Genehmiger'
                                );

                            resolve();
                        } catch (error) {
                            console.error(error);
                            reject(error);
                        }
                    })
                );

                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        this._sharepointService
                            .getItemById({ listTitle: 'Anträge', id: this.id })
                            .then((assignment) => {
                                this.sharePointItem = assignment;
                                this.itemTitle = this.sharePointItem.Title;
                                this._orderService.currentOrderId =
                                    this.sharePointItem.Id;
                                // Get author async
                                this._frameworkService
                                    .getUserOrGroupById(
                                        this.sharePointItem.AuthorId
                                    )
                                    .then((user: any) => {
                                        if (user.Title) {
                                            this.author =
                                                this._translateService.instant(
                                                    'DETAIL_ORDER.FORM.CREATED1'
                                                ) + user.Title;
                                            this.createDate =
                                                this._translateService.instant(
                                                    'DETAIL_ORDER.FORM.CREATED2'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleDateString() +
                                                this._translateService.instant(
                                                    'DETAIL_ORDER.FORM.CREATED3'
                                                ) +
                                                new Date(
                                                    this.sharePointItem.Created
                                                ).toLocaleTimeString();
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                                // success
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
            } else {
                // Create mode
                this.itemTitle =
                    this._translateService.instant('DETAIL_ORDER.NEW');
            }

            Promise.all(promises)
                .then(() => {
                    if (this.id) {
                        this.getLookUpInFirstLoad(this.sharePointItem)
                            .then(() => {
                                this.initGrid();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        this.initGrid();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    getLookUpInFirstLoad({ StandortId, TeamId }) {
        return new Promise<void>(async (resolve, reject) => {
            if (TeamId) {
                this._lookupService.teams = [this.sharePointItem.TeamId];
                const teamItem = await this._sharepointService.getListItems({
                    title: 'Teams',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .EqualTo(TeamId)
                        .ToString(),
                });

                this._lookupService.locationLookUpId =
                    teamItem[0]['StandortId'];
            }
            if (StandortId) {
                this._lookupService.location = StandortId;
                const teams = await this._sharepointService.getListItems({
                    title: 'Teams',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .LookupField('Standort')
                        .Id()
                        .EqualTo(StandortId)
                        .ToString(),
                });

                let teamId: number[] = [];
                teams.forEach((team) => {
                    teamId.push(team.Id);
                });
                if (!TeamId) {
                    this._lookupService.teamsFromLocation = [...teamId];
                } else {
                    this._lookupService.teamsFirstTimeLoad = [...teamId];
                }
            }
            resolve();
        });
    }

    onNavChange() {}

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('DETAIL_ORDER.NOTIFICATIONS.SAVING'),
            this._translateService.instant('DETAIL_ORDER.NOTIFICATIONS.WAIT')
        );
        // Remove not existing fields
        delete e.data.submit;
        delete e.data.NebenkostenEuro;
        delete e.data.Accept;
        delete e.data.Delete;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            // Edit mode
            return from(
                this._orderService
                    .updateOrder(e.data, this.sharePointItem.Id)
                    .then((order) => {
                        // Success
                        this._loadingService.hide();

                        this.sharePointItem = order;

                        this._snackBarService.add(
                            this._translateService.instant(
                                'DETAIL_ORDER.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Create mode
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Anträge',
                        data: e.data,
                        folder: '',
                    })
                    .then((newItemId) => {
                        this._sharepointService
                            .getItemById({
                                listTitle: 'Anträge',
                                id: +newItemId,
                            })
                            .then((item) => {
                                this.sharePointItem = item;

                                const promises = [
                                    this._orderService.createDependantFoldersForOrder(
                                        this.sharePointItem.Id
                                    ),
                                ];

                                // Copy Kostenrollen
                                promises.push(
                                    new Promise<void>((resolve, reject) => {
                                        this._sharepointService
                                            .getListItems({
                                                title: 'Kostenrollen',
                                                isDocumentLibrary: false,
                                                camlQuery: new CamlBuilder()
                                                    .Where()
                                                    .CounterField('ID')
                                                    .NotEqualTo(0)
                                                    .ToString(),
                                                recursiveAll: true,
                                            })
                                            .then((results) => {
                                                results.forEach(
                                                    async (costRole) => {
                                                        //Projekt_Kostenrollen

                                                        costRole.AuftragId =
                                                            this.sharePointItem.Id;
                                                        costRole.KostenrolleId =
                                                            costRole.Id;

                                                        await this._sharepointService
                                                            .addItem({
                                                                listTitle:
                                                                    'Projekt_Kostenrollen',
                                                                data: costRole,
                                                                folder: '',
                                                            })
                                                            .then(() => {})
                                                            .catch((error) => {
                                                                reject(error);
                                                            });
                                                    }
                                                );

                                                resolve();
                                            })
                                            .catch((error) => {
                                                reject(error);
                                            });
                                    })
                                );

                                Promise.all(promises)
                                    .then(() => {
                                        // Success
                                        this._loadingService.hide();

                                        this.router.navigate(
                                            [
                                                'orders/detail/basedata/' +
                                                    newItemId,
                                            ],
                                            {
                                                queryParamsHandling: 'merge',
                                            }
                                        );

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_ORDER.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;
            dataForm.Ist_Auftrag = true;
            // Set default values
        }

        // Set not existing columns
        let sum = dataForm.Antragssumme ? dataForm.Antragssumme : 0;
        let percent = dataForm.Nebenkosten ? dataForm.Nebenkosten : 0;
        dataForm.NebenkostenEuro = (sum / 100) * percent;

        // Add customEvent listeners
        this.formioComponent.customEvent.subscribe((event) => {
            this.handleFormioCustomEvents(event);
        });

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    handleFormioCustomEvents(event: any) {
        switch (event.type) {
            case 'accept':
                this._loadingService.show(
                    this._translateService.instant(
                        'DETAIL_ORDER.NOTIFICATIONS.SAVING'
                    ),
                    this._translateService.instant(
                        'DETAIL_ORDER.NOTIFICATIONS.WAIT'
                    )
                );

                // Set Acceptdate and acception
                this.formioComponent.formio._data.Uebergabe = new Date();
                this.formioComponent.formio._data.Genehmigt = true;
                delete this.formioComponent.formio._data[''];

                // Save order
                this._orderService
                    .updateOrder(
                        this.formioComponent.formio._data,
                        this.sharePointItem.Id
                    )
                    .then((order) => {
                        // Success
                        this._loadingService.hide();
                        this.sharePointItem = order;

                        this._snackBarService.add(
                            this._translateService.instant(
                                'DETAIL_ORDER.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        // Create project
                        this.createProjectFromOrder();
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    });

                break;
            case 'delete':
                this.deleteOrder();
                break;
        }
    }

    deleteOrder() {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_ORDER.NOTIFICATIONS.DELETING'
            ),
            this._translateService.instant('DETAIL_ORDER.NOTIFICATIONS.WAIT')
        );

        this._sharepointService
            .recycleItem({
                listTitle: 'Anträge',
                id: this.id,
            })
            .then(() => {
                this._loadingService.hide();

                // Success
                this._snackBarService.add(
                    this._translateService.instant(
                        'DETAIL_ORDER.NOTIFICATIONS.SUCCESSDELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                // Navigate to listview-order
                this.router.navigate(['orders'], {
                    queryParamsHandling: 'merge',
                });
            })
            .catch((error) => {
                this._loadingService.hide();
                console.error(error);
            });
    }

    createProjectFromOrder() {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_ORDER.NOTIFICATIONS.CREATINGPROJECT'
            ),
            this._translateService.instant('DETAIL_ORDER.NOTIFICATIONS.WAIT')
        );

        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);
        // Copy fields
        var data: any = {};
        data.Uebergabe = new Date();
        data.Auftragsstart = this.sharePointItem.Antragsdatum;
        data.Auftragsende = endDate;
        data.Title = this.sharePointItem.Title;
        data.KundeId = this.sharePointItem.AuftraggeberId;
        data.ProjektsteuererId = this.sharePointItem.ProjektsteuererId;
        data.KostenstelleId = this.sharePointItem.KostenstelleId;
        data.TeamId = this.sharePointItem.TeamId;
        data.FachbereichId = this.sharePointItem.FachbereichId;
        data.StandortId = this.sharePointItem.StandortId;
        data.Akkreditiert = this.sharePointItem.Akkreditiert
            ? this.sharePointItem.Akkreditiert
            : false;
        data.AuftragsleiterId = this.sharePointItem.ProjektleiterId;
        data.SachbearbeiterId = {
            results: this.sharePointItem.SachbearbeiterId,
        };
        data.AntragId = this.sharePointItem.Id;
        data.Auftragsstatus = 'Auftrag angelegt';

        // Budgetfelder
        data.Antragssumme = this.sharePointItem.Antragssumme
            ? this.sharePointItem.Antragssumme
            : 0;
        data.Nebenkosten = this.sharePointItem.Nebenkosten
            ? this.sharePointItem.Nebenkosten
            : 0;
        data.Fremdleistung = this.sharePointItem.Fremdleistung
            ? this.sharePointItem.Fremdleistung
            : 0;
        data.Auftragsbudget =
            data.Antragssumme -
            (data.Antragssumme / 100) * data.Nebenkosten -
            data.Fremdleistung;
        data.Gewinnmarge = 0;

        const budgetTable = [
            {
                TB_Antragssumme: data.Antragssumme,
                TB_Nebenkosten: data.Nebenkosten,
                TB_Fremdleistung: data.Fremdleistung,
                TB_Nachtrag: '',
                TB_Datum: new Date(),
                TB_Skonto: false,
                TB_Notiz: '',
            },
        ];

        // Get default permissions for project
        this._applicationService
            .getDefaultPermissionsForProject()
            .then(async () => {
                await this._sharepointService
                    .getItemById({
                        listTitle: 'Teams',
                        id: this.sharePointItem.TeamId,
                    })
                    .then((team) => {
                        // Add Teamgtoup to permissions
                        if (
                            team.GruppeId &&
                            this._applicationService.editorPermissions.indexOf(
                                team.GruppeId === -1
                            )
                        ) {
                            this._applicationService.editorPermissions.push(
                                team.GruppeId
                            );
                        }
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    });

                // Set permissions
                data.BearbeitungsberechtigungId = {
                    results: this._applicationService.editorPermissions,
                };

                const categoryId = await this.getProjectCategory();

                if (categoryId) {
                    data.AuftragskategorieId = categoryId;
                }

                // Create project
                this._applicationService
                    .createNewProject(data, true, budgetTable)
                    .then((item: any) => {
                        const promises = [];

                        // Check if Auftragsbestaetigung
                        if (this.sharePointItem.Auftragsbestaetigung) {
                            // Copy Auftragsbestaetigung
                            promises.push(
                                this._orderService.copyDocTemplatesInProject(
                                    item.Id
                                )
                            );
                        }

                        // Copy documents
                        promises.push(
                            this._orderService.copyDocumentsInProject(
                                item.ID,
                                this.sharePointItem.Id
                            )
                        );

                        // Copy emails
                        promises.push(
                            this._orderService.copyEMailsInProject(
                                item.ID,
                                this.sharePointItem.Id
                            )
                        );

                        // Copy Kostenrollen
                        promises.push(
                            new Promise<void>((resolve, reject) => {
                                this._sharepointService
                                    .getListItems({
                                        title: 'Projekt_Kostenrollen',
                                        isDocumentLibrary: false,
                                        camlQuery: new CamlBuilder()
                                            .Where()
                                            .LookupField('Auftrag')
                                            .Id()
                                            .EqualTo(this.sharePointItem.Id)
                                            .ToString(),
                                        recursiveAll: true,
                                    })
                                    .then((results) => {
                                        results.forEach(async (costRole) => {
                                            //Projekt_Kostenrollen

                                            costRole.ProjektId = item.ID;
                                            //costRole.KostenrolleId = costRole.Id;

                                            await this._sharepointService
                                                .addItem({
                                                    listTitle:
                                                        'Projekt_Kostenrollen',
                                                    data: costRole,
                                                    folder: '',
                                                })
                                                .then(() => {})
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        });

                                        resolve();
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                        );

                        Promise.all(promises)
                            .then(() => {
                                // Success
                                this._loadingService.hide();

                                this.router.navigate(
                                    ['projects/detail/basedata/' + item.Id],
                                    {
                                        queryParamsHandling: 'merge',
                                    }
                                );

                                this._snackBarService.add(
                                    this._translateService.instant(
                                        'DETAIL_ORDER.NOTIFICATIONS.SUCCESSPROJECT'
                                    ),
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'success-dialog',
                                    }
                                );
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    });
            })
            .catch((error) => {
                this._loadingService.hide();
                console.error(error);
            });
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }
            if (ev.changed.component.key === 'Nebenkosten') {
                // Get form values
                const data = JSON.parse(JSON.stringify(ev.data));

                let sum = data.Antragssumme ? data.Antragssumme : 0;
                let percent = ev.changed.value;

                data.NebenkostenEuro = (sum / 100) * percent;

                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            } else if (ev.changed.component.key === 'Antragssumme') {
                // Get form values
                const data = JSON.parse(JSON.stringify(ev.data));

                let sum = ev.changed.value;
                let percent = data.Nebenkosten ? data.Nebenkosten : 0;

                data.NebenkostenEuro = (sum / 100) * percent;

                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            }
        }
        if (ev.add) {
            if (this._lookupService.teamsFirstTimeLoad.length) {
                this._lookupService.teams = [
                    ...this._lookupService.teamsFirstTimeLoad,
                ];
                //Has to be set to [] after first changen
                this._lookupService.teamsFirstTimeLoad = [];
            }
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = ev.Id;
                this._sharepointService
                    .getListItems({
                        title: 'Teams',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .LookupField('Standort')
                            .Id()
                            .EqualTo(ev.Id)
                            .ToString(),
                    })
                    .then((results) => {
                        const teams = results;
                        let teamId: number[] = [];
                        teams.forEach((team) => {
                            teamId.push(team.Id);
                        });
                        this._lookupService.teamsFromLocation = [...teamId];
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this._lookupService.teams = [ev.Id];
                this._lookupService.locationLookUpId = ev.StandortId;
            }
        } else if (ev.remove) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
                if (this._lookupService.teamsFirstTimeLoad.length) {
                    this._lookupService.teams = [
                        ...this._lookupService.teamsFirstTimeLoad,
                    ];
                    //Has to be set to [] after first change
                    this._lookupService.teamsFirstTimeLoad = [];
                }
            } else {
                this._lookupService.teamsFirstTimeLoad = [];
                this._lookupService.teams = [];
                this._lookupService.locationLookUpId = null;
            }
        }
    }

    initGrid() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Tabs',
                    key: 'tabs1',
                    type: 'tabs',
                    onTabChanged: (control, ev) => {
                        //
                    },
                    components: [
                        {
                            // Tab 1
                            label: this._translateService.instant(
                                'DETAIL_ORDER.BASE'
                            ),
                            key: 'tab1',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.DATE'
                                    ),
                                    type: 'datetime',
                                    enableTime: false,
                                    key: 'Antragsdatum',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    format: 'dd.MM.yyyy',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone:
                                            'viewer',
                                        submissionTimezone:
                                            'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings:
                                            false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    customOptions: {
                                        locale: German
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.DESCRIPTION'
                                    ),
                                    type: 'textfield',
                                    key: 'Title',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.CLIENT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'AuftraggeberId',
                                    list: 'Firmen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    isCreateMode: this.isCreateMode,
                                    formatTitle: getCustomerTitle,
                                    addComponent: DetailCustomerComponent,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.TAXER'
                                    ),
                                    description: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.TAXERDESCRIPTION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektsteuererId',
                                    list: 'Firmen',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    formatTitle: getCustomerTitle,
                                    addComponent: DetailCustomerComponent,
                                },
                                {
                                    // Columns 1
                                    label: 'Columns',
                                    type: 'columns',
                                    key: 'columns1',
                                    columns: [
                                        {
                                            // Column 1
                                            type: 'column',
                                            key: 'column1',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.COSTCENTRE'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    key: 'KostenstelleId',
                                                    list: 'Kostenstellen',
                                                    searchQueryIfIsEmpty:
                                                        new CamlBuilder()
                                                            .Where()
                                                            .NumberField('ID')
                                                            .NotEqualTo(-1)
                                                            .OrderBy('Title')
                                                            .ToString(),
                                                    isPeoplePicker: false,
                                                    removable: true,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 2
                                            type: 'column',
                                            key: 'column2',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.TEAM'
                                                    ),
                                                    type: 'sharePointAutocomplete',
                                                    key: 'TeamId',
                                                    list: 'Teams',
                                                    searchQueryIfIsEmpty:
                                                        this._lookupService
                                                            .teamCAMLQuery,
                                                    isPeoplePicker: false,
                                                    removable: true,
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                    isCreateMode:
                                                        this.isCreateMode,
                                                    onSelected: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.add = true;
                                                        data.remove = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );
                                                    },
                                                    onRemoved: (
                                                        control,
                                                        data
                                                    ) => {
                                                        data.remove = true;
                                                        data.add = false;

                                                        this.onChange(data);

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQueryIfIsEmpty',
                                                            this._lookupService
                                                                .locationCAMLQuery
                                                        );

                                                        this._frameworkService.updateFormioComponentProperty(
                                                            this
                                                                .formioComponent,
                                                            'StandortId',
                                                            'searchQuery',
                                                            this._lookupService
                                                                .locationSearchCAMLQuery
                                                        );
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.AREA'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'FachbereichId',
                                    list: 'Fachbereiche',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.locationCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQueryIfIsEmpty',
                                            this._lookupService.teamCAMLQuery
                                        );

                                        this._frameworkService.updateFormioComponentProperty(
                                            this.formioComponent,
                                            'TeamId',
                                            'searchQuery',
                                            this._lookupService
                                                .teamSearchCAMLQuery
                                        );
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.ACCREDITED'
                                    ),
                                    type: 'checkbox',
                                    key: 'Akkreditiert',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.MANAGER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ProjektleiterId',
                                    isPeoplePicker: true,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.CLERK'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'SachbearbeiterId',
                                    multi: true,
                                    isPeoplePicker: true,
                                    removable: true,
                                    isCreateMode: this.isCreateMode,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },

                                {
                                    // Columns 2
                                    label: 'Columns',
                                    type: 'columns',
                                    key: 'columns2',
                                    columns: [
                                        {
                                            // Column 1
                                            type: 'column',
                                            key: 'column1',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.SUM'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_ORDER.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'Antragssumme',
                                                    validate: {
                                                        customMessage:
                                                            '{{field}} ist ein Pflichtfeld',
                                                        required: true,
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            // Column 2
                                            type: 'column',
                                            key: 'column2',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_ORDER.FORM.INPERCENT'
                                                        ),
                                                    type: 'number',
                                                    key: 'Nebenkosten',
                                                },
                                            ],
                                        },
                                        {
                                            // Column 3
                                            type: 'column',
                                            key: 'column3',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.ADDITIONAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_ORDER.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'NebenkostenEuro',
                                                    disabled: true,
                                                },
                                            ],
                                        },
                                        {
                                            // Column 4
                                            type: 'column',
                                            key: 'column4',
                                            components: [
                                                // Components
                                                {
                                                    label: this._translateService.instant(
                                                        'DETAIL_ORDER.FORM.EXTERNAL'
                                                    ),
                                                    description:
                                                        this._translateService.instant(
                                                            'DETAIL_ORDER.FORM.INEURO'
                                                        ),
                                                    type: 'number',
                                                    key: 'Fremdleistung',
                                                },
                                            ],
                                        },
                                    ],
                                },

                                {
                                    label: '',
                                    type: 'contentCustom',
                                    component: TablePositionComponent,
                                    key: '',
                                    hidden: !this.id ? true : false,
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.COPY'
                                    ),
                                    type: 'checkbox',
                                    key: 'Kopie',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.CONFIRMATION'
                                    ),
                                    type: 'checkbox',
                                    key: 'Auftragsbestaetigung',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.FOLDER'
                                    ),
                                    type: 'checkbox',
                                    key: 'Ordner',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.XMAS'
                                    ),
                                    type: 'checkbox',
                                    key: 'Weihnachtskarte',
                                },
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.HANDINGOVER'
                                    ),
                                    type: 'datetime',
                                    key: 'Uebergabe',
                                    disabled: true,
                                    enableTime: false,
                                    hidden:
                                        !this.sharePointItem ||
                                        !this.sharePointItem.Genehmigt,
                                    format: 'dd.MM.yyyy',
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone:
                                            'viewer',
                                        submissionTimezone:
                                            'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings:
                                            false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    customOptions: {
                                        locale: German
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    // Columns 3
                    label: 'Columns',
                    type: 'columns',
                    key: 'columns3',
                    customClass: 'button-row',
                    columns: [
                        {
                            // Column 1
                            type: 'column',
                            key: 'column5',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.SAVE'
                                    ),
                                    size: 'small',
                                    type: 'button',
                                    action: 'submit',
                                },
                            ],
                        },
                        {
                            // Column 2
                            type: 'column',
                            key: 'column6',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.ACCEPT'
                                    ),
                                    size: 'small',
                                    type: 'button',
                                    theme: 'accent',
                                    action: 'event',
                                    event: 'accept',
                                    key: 'Accept',
                                    hidden:
                                        !this.isAcceptor ||
                                        this.isCreateMode ||
                                        this.sharePointItem?.Genehmigt,
                                },
                            ],
                        },
                        {
                            // Column 3
                            type: 'column',
                            key: 'column7',
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_ORDER.FORM.DELETE'
                                    ),
                                    customClass: 'delete',
                                    size: 'small',
                                    type: 'button',
                                    action: 'event',
                                    event: 'delete',
                                    key: 'Delete',
                                    hidden:
                                        !this.isAcceptor ||
                                        this.isCreateMode ||
                                        (this.sharePointItem &&
                                            this.sharePointItem.Genehmigt),
                                },
                            ],
                        },
                    ],
                },
                // Columns 3
            ],
        };
    }

    private async getProjectCategory(): Promise<number> {
        const categories = await this._sharepointService.getListItems({
            title: 'Auftragskategorien',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .TextField('Title')
                .EqualTo('Projekt')
                .ToString()
        });

        if (!categories.length) {
            return null;
        }

        return categories[0].Id;
    }
}
