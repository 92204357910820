export const locale = {
    lang: 'de',
    data: {
        CHANGE_DOCUMENT_DIALOG: {
            TITLE: 'Rename the document',
            FORM: {
                TITLE: 'Filename',
                SAVE: 'Speichern'
            },
            NOTIFICATIONS: {
                SAVINGITEM: 'i18n',
                SAVING: 'CHANGE',
                WAIT: 'Bitte warten.',
                SUCCESS: 'File being successfully renamed',
                DUPLICATE: 'File with such name already exists',
                ERROR: 'Error occurred during renaming of the file',
                INVALID_CHARACTERS: 'Ungültige Sonderzeichen im Dateinamen'
            },
        }
    },
};
