<div class="box-wrapper" [class.touched]="touched">
  <div mwlResizable
       #leftSideContentWrapper
       class="resizable-box"
       [enableGhostResize]="true"
       (resizeEnd)="onResizeEnd($event)"
       (resizeStart)="onResizeStart()"
  >
    <div class="content-wrapper">
      <ng-content select="[leftSide]"></ng-content>
    </div>

    <div class="resize-handle-right"
         mwlResizeHandle
         [resizeEdges]="{ right: true }"
    >
    </div>
  </div>
  <div #rightSideContentWrapper class="email-details-content-wrapper">
    <ng-content select="[rightSide]"></ng-content>
  </div>
</div>
