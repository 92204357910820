import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from 'sp-office365-layout';

@Component({
  selector: 'app-project-evaluation',
  templateUrl: './project-evaluation.component.html',
  styleUrls: ['./project-evaluation.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ProjectEvaluationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
