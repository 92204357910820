export const locale = {
    lang: 'en',
    data: {
        CHANGE_DOCUMENT_DIALOG: {
            TITLE: 'Rename the document',
            NOTIFICATIONS: {
                SAVINGITEM: 'i18n',
                SAVING: 'CHANGE',
                WAIT: 'Bitte warten.',
                SUCCESS: 'i18n',
                SUCCESSITEM: 'i18n',
                ERROR: 'i18n',
                INVALID_CHARACTERS: 'Ungültige Sonderzeichen im Dateinamen'
            },
        }
    },
};
