import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseWidgetModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { ListviewTaskModule } from '../task/listview-task/listview-task.module';
import { HighlightModule } from '../highlight/highlight.module';
import { ChartModule } from '../chart/chart.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
    },
];

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        ListviewTaskModule,
        HighlightModule,
        MatIconModule,
        FuseWidgetModule,
        MatDividerModule,
        ChartModule,
        MatTabsModule,
        SchedulerModule,
    ],
    exports: [DashboardComponent],
})
export class DashboardModule {}
