import { NgModule } from '@angular/core';
import { CostRolesOrderComponent } from './costRoles-order.component';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
//import { TableTaskModule } from '../../task/table-task/table-task.module'; //TODO
import { KanbanModule } from '../../kanban/kanban.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TableInvoiceModule } from '../../invoice/table-invoice/table-invoice.module';
import { MatButtonModule } from '@angular/material/button';

const routes = [
    {
        path: 'orders/detail/costRoles',
        component: CostRolesOrderComponent,
    },
    {
        path: 'orders/detail/costRoles/:id',
        component: CostRolesOrderComponent,
    },
];

@NgModule({
    declarations: [CostRolesOrderComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        FuseSidebarModule,
        MatCardModule,
        AngularFrameworkModule,
        TableInvoiceModule,
        KanbanModule,
    ],
    exports: [CostRolesOrderComponent],
})
export class CostRolesOrderModule {}
