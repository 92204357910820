import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { BillingImportService } from 'src/app/main/services/billing-import.service';
import { ColumnTyp, FormatTypes, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import { TranslateService } from '@ngx-translate/core';
import { ExcelBillItem } from '../models';

@Component({
    selector: 'app-import-bills-dialog',
    templateUrl: './import-bills-dialog.component.html',
    styleUrls: ['./import-bills-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ImportBillsDialogComponent implements OnInit, OnDestroy {
    public uploadStatus$ = new BehaviorSubject<{ done: number, failed: number, total: number }>({
        done: 0,
        failed: 0,
        total: 0
    });
    public isFinished: boolean;
    public currentBill: string;
    public tableConfig: TableConfiguration;
    public currentUploadingBill: ExcelBillItem;
    public finishedProjects: any = {};
    private destroy$ = new Subject();
    @ViewChild('importTable', { static: false }) table: SharePointTableComponent;

    constructor(
        private billingImportService: BillingImportService,
        private dialogRef: MatDialogRef<ImportBillsDialogComponent>,
        private cdr: ChangeDetectorRef,
        private _translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(): void {
        this.initTableConfig();
        const queue$ = new Subject<ExcelBillItem>();
        const totalAmountOfRequests = this.data.length;
        this.uploadStatus$.next({ done: 0, failed: 0, total: totalAmountOfRequests });

        queue$
            .pipe(
                takeUntil(this.destroy$),
                concatMap((excelBillItem: ExcelBillItem) => {
                    this.currentUploadingBill = excelBillItem;
                    this.currentBill = excelBillItem.billTitle;
                    return this.billingImportService.createNewBill(excelBillItem);
                })
            )
            .subscribe(result => {
                const statusTranslationKey = result.error ? `IMPORT_BILLS_DIALOG.${result.error}` : `IMPORT_BILLS_DIALOG.IMPORTED`;
                const bill = this.data.find(_bill => _bill.docNumber === result.id);

                bill.status = this._translateService.instant(statusTranslationKey);
                result.status = bill.status;
                this.finishedProjects[result.id] = result;

                const currentStatus = this.uploadStatus$.getValue();
                let successful = currentStatus.done;
                let failed = currentStatus.failed;
                this.table.refresh();

                if (!result.error) {
                    successful++;

                } else {
                    failed++;
                }

                this.isFinished = (failed + successful) === this.data.length;
                this.uploadStatus$.next({ done: successful, failed, total: this.data.length });
                this.table.data = this.data;
                this.cdr.markForCheck();
            });
        this.data.forEach(item => {
            queue$.next(item);
        });
    }

    private initTableConfig(): void {
        this.tableConfig = {
            list: '',
            columns: [
                {
                    internalName: 'billTitle',
                    title: this._translateService.instant('IMPORT_BILLS_DIALOG.BILL_TITLE'),
                    hidden: false
                },
                {
                    internalName: 'projectNumber',
                    title: this._translateService.instant('IMPORT_BILLS_DIALOG.PROJECT_NUMBER'),
                    hidden: false
                },
                {
                    internalName: 'docNumber',
                    title: this._translateService.instant('IMPORT_BILLS_DIALOG.BILL_NUMBER'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'date',
                    title: this._translateService.instant('IMPORT_BILLS_DIALOG.DATE'),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMdd,
                },
                {
                    internalName: 'status',
                    title: this._translateService.instant('IMPORT_BILLS_DIALOG.STATUS'),
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '270px'
                }
            ],
            showEditButton: false,
            showDeleteButton: false,
            useMaterialDesign: true,
            toolbar: [
                {
                    title: 'Group',
                    command: 'group',
                },
                {
                    title: 'Ungroup',
                    command: 'Ungroup',
                }
            ],

            isDocumentLibrary: false,
            data: this.data
        };
    }

    public toolbarButtonHandler(event): void {
        if (event.command === 'group') {
            this.tableConfig.groups = [{ field: 'status' }];
        } else {
            this.tableConfig.groups = [];
        }

        this.table.refresh();
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }

}
