import { UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1012Component {
    constructor(private _updateService: UpdateService) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const promises = [
                // Add List Projektnotizen
                await this._updateService.addList('Projektnotizen', '', 101, {
                    EnableVersioning: true,
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
