import { TimeService } from './../../services/time.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-duration-time',
    templateUrl: './duration-time.component.html',
    styleUrls: ['./duration-time.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DurationTimeComponent implements OnInit {
    public hours;
    public minutes;
    public hoursLabel: string;
    public minutesLabel: string;
    public hoursErrorMessage: string;
    public minutesErrorMessage: string;

    constructor(
        public timeService: TimeService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.hoursLabel = this._translateService.instant('DURATION_TIME.HOURS');
        this.minutesLabel = this._translateService.instant(
            'DURATION_TIME.MINUTES'
        );
        this.hoursErrorMessage = this._translateService.instant(
            'DURATION_TIME.HOURSERROR'
        );
        this.minutesErrorMessage = this._translateService.instant(
            'DURATION_TIME.MINUTESERROR'
        );
    }

    ngOnInit() {
        this.hours = this.timeService.hours;
        this.minutes = this.timeService.minutes;
    }

    saveTime(): Promise<any> {
        return new Promise<void>((resolve) => {
            this.timeService.hours = this.hours;
            this.timeService.minutes = this.minutes;
            this.timeService.duration = this.hours * 60 + this.minutes;
            this.timeService
                .saveTime()
                .then(() => {
                    this.timeService.timeGrid.refresh();
                    this.timeService.timeAdded.emit();
                    resolve();
                })
                .catch((error) => {
                    resolve();
                });
        });
    }
}
