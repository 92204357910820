<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">{{
      'MOVE_ELEMENT.MOVE' | translate
      }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id="content">
  <formio class="formio-material" #formioComponent [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this"
    [renderOptions]="formio_renderOptions"
    [form]="formioConfiguration">
  </formio>
</div>
