export const locale = {
    lang: 'de',
    data: {
        LV_ORDER: {
            TITLE: 'Aufträge',
            SEARCH_LABEL: 'Nach einem Auftrag suchen',
            ALL: 'Alle Aufträge',
            OPEN: 'Offene Aufträge',
            CLOSED: 'Genehmigte Aufträge',
            COLUMNS: {
                DESCRIPTION: 'Projektbezeichnung',
                CLIENT: 'Auftraggeber / Ansprechpartner',
                HANDINGOVER: 'Übergabedatum',
                TEAM: 'Team',
                LOCATION: 'Niederlassung',
            },
        },
    },
};
