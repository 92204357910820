import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1073Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const timeList = this._sharePointService.web.lists.getByTitle(
                'Zeiten'
            );

            let employeeList: IList;
            let costRoleList: IList;

            const promises = [
                // Add List Mitarbeiter
                await this._updateService.addList('Mitarbeiter', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Mitarbeiter
                await new Promise<void>((resolve) => {
                    employeeList = this._sharePointService.web.lists.getByTitle(
                        'Mitarbeiter'
                    );
                    resolve();
                }),
                // Add List Kostenrollen
                await this._updateService.addList('Kostenrollen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Kostenrollen
                await new Promise<void>((resolve) => {
                    costRoleList = this._sharePointService.web.lists.getByTitle(
                        'Kostenrollen'
                    );
                    resolve();
                }),
                // Add Field User to List Mitarbeiter
                await this._updateService.addUserField(employeeList, 'User'),
                // Add Field Tagessoll to List Mitarbeiter
                await this._updateService.addField(
                    employeeList,
                    'Tagessoll',
                    FieldType.Number
                ),
                // Add Field Kostenrolle to List Mitarbeiter
                await this._updateService.addLookupField(
                    employeeList,
                    costRoleList,
                    'Kostenrolle'
                ),
                // Add Field Stundensatz to List Kostenrollen
                await this._updateService.addField(
                    costRoleList,
                    'Stundensatz',
                    FieldType.Currency
                ),
                // Add Field Kosten to List Zeiten
                await this._updateService.addField(
                    timeList,
                    'Kosten',
                    FieldType.Currency
                ),
                // Add Field Beschreibung to List Zeiten
                await this._updateService.addField(
                    timeList,
                    'Beschreibung',
                    FieldType.Text
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
