import { OrderService } from './order.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { ApplicationService } from './application.service';
import { InjectHeaders } from '@pnp/queryable';

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    public currentRequestId = -1;
    public sideBarConfigRequest = [];
    private dependantRequestLists = [
        { listTitle: 'Mailbox-Anträge', isDocumentLibrary: false },
        { listTitle: 'Antragsdokumente', isDocumentLibrary: true },
    ];

    constructor(
        private _translateService: TranslateService,
        private _sharePointService: SharePointService,
        private _applicationService: ApplicationService,
        private _updateService: UpdateService,
        private _orderService: OrderService
    ) {}

    getSideBarConfig(id: number): Promise<any> {
        // Reset if id of project changed
        if (this.currentRequestId != id) {
            this.sideBarConfigRequest = [];
            this.currentRequestId = id;
        }

        return new Promise(async (resolve) => {
            const promises = [];
            const config = [];

            if (!id) {
                config.push({
                    Text: this._translateService.instant(
                        'REQUESTS_SERVICE.BASE'
                    ),
                    Link: '/new-request',
                    Icon: 'star',
                });
            } else {
                config.push(
                    {
                        Text: this._translateService.instant(
                            'REQUESTS_SERVICE.BASE'
                        ),
                        Link: '/requests/detail/basedata/' + id,
                        Icon: 'star',
                    },
                    {
                        Text: this._translateService.instant(
                            'REQUESTS_SERVICE.DOCS'
                        ),
                        Link: '/requests/detail/documents/' + id,
                        Icon: 'folder',
                    },
                    {
                        Text: this._translateService.instant(
                            'REQUESTS_SERVICE.MAILS'
                        ),
                        Link: '/requests/detail/mails/' + id,
                        Icon: 'mailbox',
                    }
                );

                this._orderService
                    .getNumberOfItemsWithoutFolders('Antragsdokumente', id)
                    .then((length) => {
                        const index = config.findIndex(
                            (item) =>
                                item.Text ==
                                this._translateService.instant(
                                    'REQUESTS_SERVICE.DOCS'
                                )
                        );
                        config[index].Text =
                            this._translateService.instant(
                                'REQUESTS_SERVICE.DOCS'
                            ) +
                            ' (' +
                            length +
                            ')';
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            Promise.all(promises)
                .then(() => {
                    this.sideBarConfigRequest = config;
                    resolve(config);
                })
                .catch((error) => {
                    this.sideBarConfigRequest = config;
                    console.error(error);
                    resolve(config);
                });
        });
    }

    updateOrder(data: any, itemId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // Remove not existing fields
            delete data.submit;
            delete data.NebenkostenEuro;
            delete data.Accept;
            delete data.Delete;

            this._sharePointService
                .updateItem({
                    id: itemId,
                    listTitle: 'Anträge',
                    newFiledValues: data,
                })
                .then(() => {
                    this._sharePointService
                        .getItemById({
                            listTitle: 'Anträge',
                            id: itemId,
                        })
                        .then((order) => {
                            const promises = [];

                            Promise.all(promises)
                                .then(() => {
                                    // Success
                                    resolve(order);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    createFoldersForRequests(requestItem) {
        return new Promise<void>((resolve, reject) => {
            const promises = [];

            for (const dependantList of this.dependantRequestLists) {
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._updateService
                            .listExists(dependantList.listTitle)
                            .then((exists) => {
                                if (exists) {
                                    this._sharePointService
                                        .addFolder({
                                            listTitle: dependantList.listTitle,
                                            folderName: requestItem.Id + '',
                                            isDocumentLibrary:
                                                dependantList.isDocumentLibrary,
                                        })
                                        .then(() => resolve())
                                        .catch((error) => {
                                            reject(error);
                                        });
                                } else {
                                    resolve();
                                }
                            });
                    })
                );
            }

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addFoldersForExistingOrders(
        listTitle: string,
        isDocumentLibrary: boolean
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._sharePointService
                .getListItems({
                    title: 'Anträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .ToString(),
                })
                .then((items) => {
                    const promises = [];

                    items.forEach((item) => {
                        // Add folders
                        promises.push(
                            new Promise<void>((resolve, reject) => {
                                this._sharePointService
                                    .addFolder({
                                        listTitle,
                                        folderName: item.Id + '',
                                        isDocumentLibrary,
                                    })
                                    .then(() => {
                                        resolve();
                                    })
                                    .catch((error) => reject(error));
                            })
                        );
                    });

                    Promise.all(promises)
                        .then(() => resolve())
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    copyDocTemplatesInProject(projectId: number): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let query = new CamlBuilder()
                .Where()
                .TextField('FileLeafRef')
                .EqualTo('Auftragsbestätigung.docx')
                .ToString();
            let listTitle = 'Dokumentenvorlagen';
            let folderName = '';

            // Get DocTemplate
            this._sharePointService
                .getListItems({
                    title: listTitle,
                    isDocumentLibrary: true,
                    camlQuery: query,
                    folderName,
                })
                .then((templates) => {
                    if (templates.length > 0) {
                        templates.forEach((element) => {
                            // Copy DocTemplate
                            const targetPath =
                                'Auftragsdokumente/' +
                                projectId +
                                '/' +
                                element.FieldValuesAsText.FileLeafRef;

                            this._sharePointService.web
                                .using(
                                    InjectHeaders({
                                        Accept: 'application/json;odata=fullmetadata',
                                        'Content-Type':
                                            'application/json;charset=utf-8',
                                    })
                                )
                                .getFileByServerRelativePath(element.FileRef)
                                .copyTo(targetPath, true)
                                .then(() => {
                                    this._applicationService
                                        .loadItemFromFolder(
                                            this._sharePointService.computeServerRelativeUrl(
                                                this._sharePointService
                                                    .sphostUrl
                                            ) +
                                                '/' +
                                                targetPath
                                        )
                                        .then((data) => {
                                            // Set ProjectId
                                            this._sharePointService
                                                .updateItem({
                                                    listTitle:
                                                        'Auftragsdokumente',
                                                    id: data.Id,
                                                    newFiledValues: {
                                                        AuftragId: projectId,
                                                    },
                                                })
                                                .then(() => {
                                                    // Success
                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                    } else {
                        // Success without DocTemplate
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
