export const LISTVIEW_TIME_MONTH_FILTER = [
    {
        label: 'FILTER.MONTHS.ALL',
        value: 0,
    },
    {
        label: 'FILTER.MONTHS.JANUARY',
        value: 1,
    },
    {
        label: 'FILTER.MONTHS.FEBRUARY',
        value: 2,
    },
    {
        label: 'FILTER.MONTHS.MARCH',
        value: 3,
    },
    {
        label: 'FILTER.MONTHS.APRIL',
        value: 4,
    },
    {
        label: 'FILTER.MONTHS.MAY',
        value: 5,
    },
    {
        label: 'FILTER.MONTHS.JUNE',
        value: 6,
    },
    {
        label: 'FILTER.MONTHS.JULY',
        value: 7,
    },
    {
        label: 'FILTER.MONTHS.AUGUST',
        value: 8,
    },
    {
        label: 'FILTER.MONTHS.SEPTEMBER',
        value: 9,
    },
    {
        label: 'FILTER.MONTHS.OCTOBER',
        value: 10,
    },
    {
        label: 'FILTER.MONTHS.NOVEMBER',
        value: 11,
    },
    {
        label: 'FILTER.MONTHS.DECEMBER',
        value: 12,
    },
];
