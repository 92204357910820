export const locale = {
    lang: 'en',
    data: {
        DETAIL_REQUEST: {
            REQUESTS: 'Anfragen',
            BASE: 'Basedata',
            NEW: 'New order',
            FORM: {
                CREATED1: 'Created by ',
                CREATED2: ' on ',
                CREATED3: ' at ',
                DATE: 'Orderdate',
                DESCRIPTION: 'Description',
                CLIENT: 'Client',
                TAXER: 'Taxer',
                TAXERDESCRIPTION: 'only for auditing',
                COSTCENTRE: 'Cost centre',
                TEAM: 'Team',
                AREA: 'Subject area',
                LOCATION: 'Location',
                ACCREDITED: 'Accredited',
                MANAGER: 'Projectmanager',
                CLERK: 'Clerk',
                SUM: 'Sum',
                INEURO: '(in Euro)',
                ADDITIONAL: 'Additional costs',
                INPERCENT: '(in %)',
                EXTERNAL: 'External service',
                COPY: 'Copy order',
                CONFIRMATION: 'Send confirmation',
                FOLDER: 'Add folder',
                XMAS: 'X-Mas card',
                HANDINGOVER: 'Handing over date',
                SAVE: 'Save',
                ACCEPT: 'Accept',
                CANCELED: 'Canceled',
                DELETE: 'Delete',
                CONVERT: 'Convert to order',
                POSITION: {
                    HEADER: 'Position',
                    TITLE: 'Title',
                    WORK: 'Work',
                    NETTO: 'Netto Amount [€]',
                },
            },
            NOTIFICATIONS: {
                SAVING: 'Saving request...',
                DELETING: 'Deleting request...',
                CANCELING: 'Canceling request...',
                CREATINGPROJECT: 'Creating project...',
                WAIT: 'Please wait.',
                SUCCESS: 'Request saved successfully',
                SUCCESSDELETE: 'Request deleted successfully',
                SUCCESSCANCELED: 'Request canceled successfully',
                SUCCESSPROJECT: 'Project created successfully',
                ERROR: 'Error while saving: ',
            },
        },
    },
};
