import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { UploadBillsPdfAttachmentsDialogComponent } from './upload-bills-pdf-attachments-dialog.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [UploadBillsPdfAttachmentsDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule,
        AngularFrameworkModule,
        MatProgressSpinnerModule
    ]
})
export class UploadBillsPdfAttachmentsDialogModule {
}
