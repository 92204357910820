<listview
    #listView
    *ngIf="config"
    [config]="config"
    (onTooolbarButtonClick)="toolbarButtonHandler($event)"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
    <ng-template customCell for="Auftragsstatus" let-dataItem="dataItem">
        <div *ngIf="dataItem.Auftragsstatus == 'Auftrag angelegt'">
            {{ 'LV_ASSIGNMENT.COLUMNS.CREATED' | translate }}
        </div>

        <div *ngIf="dataItem.Auftragsstatus == 'Auftrag in Arbeit'">
            {{ 'LV_ASSIGNMENT.COLUMNS.PROGRESS' | translate }}
        </div>

        <div *ngIf="dataItem.Auftragsstatus == 'Auftrag gestoppt'">
            {{ 'LV_ASSIGNMENT.COLUMNS.STOPPED' | translate }}
        </div>

        <div *ngIf="dataItem.Auftragsstatus == 'Auftrag abgeschlossen'">
            {{ 'LV_ASSIGNMENT.COLUMNS.COMPLETED' | translate }}
        </div>
    </ng-template>
</listview>
