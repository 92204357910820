import { SharepointEntrysService } from './../../services/sharepoint-entrys.service';
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { SharePointService } from "sp-office365-framework";
import {
  ListViewConfig,
  FuseTranslationLoaderService,
  fuseAnimations,
} from "sp-office365-layout";
import { ListViewComponent } from "sp-office365-layout/lib/src/app/layout/listview/listview.component";
import { locale as english } from "../i18n/en";
import { locale as german } from "../i18n/de";
import { CurrentUserService } from '../../services/current-user.service';

@Component({
  selector: "app-listview-evaluation",
  templateUrl: "./listview-evaluation.component.html",
  styleUrls: ["./listview-evaluation.component.scss"],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ListviewEvaluationComponent {
  public config: ListViewConfig;
  public isManager = false;
  public subscription: Subscription;

  @ViewChild("listView", { static: false }) listView: ListViewComponent;

  constructor(
    public dialog: MatDialog,
    public _sharepointEntrysService: SharepointEntrysService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _sharepointService: SharePointService,
    private _currentUserService: CurrentUserService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
    if (this._sharepointService.currentUser.IsBusinessOwner || this._currentUserService.isTeamProjectLeiter()) {
      this.isManager = true;
    }
    this.initConfig();
  }

  initConfig() {
    this.config = {
      title: this._translateService.instant("LV_BILLS.BILLS"),
      searchLabel: this._translateService.instant("LV_BILLS.SEARCH_BILLS"),
      icon: "monetization_on",
      add_icon: "add",
      onAddClick: () => {},
      openEditInDialog: false,
      onRowClick: (item) => {},
      tableConfigs: [
        {
          title: this._translateService.instant("LV_BILLS.ALLTIMES"),
          config: {
            list: "Rechnungen",
            columns: [{ internalName: "ID", title: "ID", hidden: true }],
            showEditButton: false,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: this._sharepointEntrysService.filteredEntrysForChart,
            afterRefreshTable: (data, grid) => {},
          },
          camlQueryForSearch: this._sharepointEntrysService.filteredEntrysForChart,
        },
      ],
    };
  }
}
