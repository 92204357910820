export const locale = {
    lang: 'de',
    data: {
        TABLE:{
            TITLE:"Position",
            SERVICE:"Leistung",
            PAYMENT: "Netto Betrag [€]",
        },
        NEW:"Neue Position erstellen",
        SAVE: "Speichern",
        WAIT:"Bitte haben Sie einen Moment Geduld",
        SUCCESS:"Erfolgreich gespeichert",
        POSITION:{
            NEW:"Neue Position erstellen",
            CHANGE:"Position ändern",
            SAVE:"Speichere Eintrag"
        }
    },
};
