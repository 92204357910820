import { FieldType, SharePointService, UpdateService, } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { ClientUtil } from '../../../shared/utils';

export class UpdateToVersion1152Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {
    }

    async run() {
        try {
            const firmenList =
                await this._sharePointService.web.lists.getByTitle(
                    'Firmen'
                );

            await this._updateService.addField(firmenList, 'Firmenbriefkopf', FieldType.MultilineText);

            const clients = await this._sharePointService.getListItems({
                title: 'Firmen',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString()
            });

            for (let i = 0; i < clients.length; i++) {
                await this._sharePointService.updateItem({
                    id: clients[i].Id,
                    listTitle: 'Firmen',
                    newFiledValues: {
                        Firmenbriefkopf: ClientUtil.getClientSummary(clients[i])
                    }
                });
            }

            return;
        } catch (error) {
            throw new Error(error);
        }
    }
}
