import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnTyp, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from '../../../services/task.service';
import { LoadingService } from '../../../services/loading.service';
import { FuseTranslationLoaderService, SnackbarService } from 'sp-office365-layout';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import CamlBuilder from 'camljs';

@Component({
    selector: 'app-import-workpackage-set',
    templateUrl: './import-workpackage-set.component.html',
    styleUrls: ['./import-workpackage-set.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImportWorkpackageSetComponent implements OnInit {
    public tableWorkpackageSetConfig: TableConfiguration;
    @ViewChild('table', { static: false }) table: SharePointTableComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { auftragsId: number },
        public matDialogRef: MatDialogRef<ImportWorkpackageSetComponent>,
        private _translateService: TranslateService,
        private _taskService: TaskService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.initTableConfig();
    }

    public importWorkpackageSet(): void {
        this._loadingService.show(
            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.SAVING'),
            this._translateService.instant('WORKPACKAGESETS.FORM.NOTIFICATIONS.WAIT')
        );
        let arbeitspaketesetsIds = this.table.data.filter(item => this.table.allSelectedItemsIDs.some(id => id === item.Id)).map(item => item.ArbeitspaketesetsId).flat();
        arbeitspaketesetsIds = [...new Set(arbeitspaketesetsIds)];
        this._taskService.toggleTasksImportRunning();
        this._taskService.importWorkpackages(arbeitspaketesetsIds, this.data.auftragsId)
            .then(() => {
                this._loadingService.hide();
                this._snackBarService.add(
                    this._translateService.instant(
                        'WORKPACKAGESETS.FORM.NOTIFICATIONS.SUCCESSITEM'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
                this.matDialogRef.close();
            })
            .finally(() => this._taskService.toggleTasksImportRunning());
    }

    private initTableConfig(): void {
        this.tableWorkpackageSetConfig = {
            list: 'ArbeitspaketesetsTemplate',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('WORKPACKAGESETS.FORM.COLUMNS.TITLE'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Arbeitspaketesets',
                    title: this._translateService.instant('WORKPACKAGESETS.FORM.COLUMNS.WORKPACKAGE'),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: true,
            checkboxColumnShowSelectAll: true,
            recursiveAll: true,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString(),
            useMaterialDesign: true,
        };
    }

}
