<div id="detailtasktemplate">
  <mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span *ngIf="this.id" class="title dialog-title">{{ "EDIT" | translate }}</span>
      <span *ngIf="!this.id" class="title dialog-title">{{ "NEW" | translate }}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div id='content'>

    <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

      <mat-tab class="tab" label='{{ "TABS.BASE" | translate }}'>

        <formio *ngIf="formioConfiguration" class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
          [renderOptions]="formio_renderOptions">
        </formio>

      </mat-tab>

      <mat-tab class="tab" label='{{ "TABS.DOCS" | translate }}' *ngIf="this.id">

        <input type="file" style="display: none;" [multiple]="uploadMultipleDocuments"
          [accept]="this.documentService.acceptedFileExtensions"
          (change)="this.documentService.fileChangeEvent($event, this.documentGrid, this.tableConfig.list, { AufgabenvorlageId: this.id })"
          #fileInput />

        <app-sharepoint-table #documentGrid
                              *ngIf="tableConfig"
                              [configuration]="tableConfig"
          (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
        >
        </app-sharepoint-table>

      </mat-tab>

    </mat-tab-group>

  </div>

</div>
