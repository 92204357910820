export const locale = {
    lang: 'de',
    data: {
        PROJECTS: 'Projekte',
        BASE: 'Stammdaten',
        DOCS: 'Dokumente',
        INVOLVED: 'Beteiligte',
        TASKS: 'Arbeitspakete',
        KANBAN: 'Kanban',
        PROJECTNUMBER: 'Projektnummer',
        NOTES: 'Notizen',
        TABS: {
            WORKPACKAGES: 'Arbeitspakete',
            TASKS: 'Aufgaben',
        },
        BUTTONS: {
            NEW: 'Neues Arbeitspaket anlegen',
            IMPORTWORKPACKAGESET: 'Arbeitspaketesets',
            IMPORTTASK: 'Aufgabensets',
            NEWTASK: 'Neue Aufgabe anlegen',
            MY: 'Meine Arbeitspakete',
            MYTASK: 'Meine Aufgaben',
            CRITICAL: 'Kritische Arbeitspakete',
            CRITICALTASK: 'Kritische Aufgaben',
            CLEARFILTER: 'Filter löschen',
        },
        COLUMNS: {
            TITLE: 'Arbeitspaket',
            DUEDATE: 'Fälligkeitsdatum',
            STATUS: 'Status',
            TASK: 'Aufgabe',
            ASSIGNED: 'Zugewiesen an',
            ANSPRECHPARTNER: 'Ansprechpartner'
        },
    },
};
