import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import { ApplicationService } from '../../services/application.service';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { DetailNotificationComponent } from '../../notification/detail-notification/detail-notification.component';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { WindowRef } from '../../shared/WindowRef';
import { from, of } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { MailService } from '../../services/mail.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import '@pnp/graph/users';
import { Mail } from '../../models/mail.model';
import CamlBuilder from 'camljs';
import Quill from 'quill';
import { RequestService } from 'src/app/main/services/request.service';
import { OrderService } from 'src/app/main/services/order.service';
import { SPDocument } from '../../shared/models';
import { DocumentsService } from '../../services/documents.service';
import { ChangeDocumentStatus } from '../../document/change-document-name/enum';
import { FormioComponent } from '@formio/angular';

@Component({
    selector: 'app-detail-mail',
    templateUrl: './detail-mail.component.html',
    styleUrls: ['./detail-mail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailMailComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    };

    public id;
    public sharePointItem;
    public formioConfiguration;
    public isCreateMode = true;
    public mailContent;
    public parentId;
    public isReply = false;
    public defaultTo = [];
    public defaultCC = [];
    public replySubject;
    public replyTo;
    public replyToAllEmails: string[];
    public projectFrom;
    public addresses = [];
    public taskId;
    public tableConfig: TableConfiguration;
    public isRequest: boolean;
    public clientContacts = [];
    public ChangeDocumentStatus = ChangeDocumentStatus;

    private columns: TableColumn[];
    private rootFolderName = 'Dokumente';
    private currentUser;
    private mailboxListTitle: string;

    get camlQuery() {
        if (this.isRequest) {
            return new CamlBuilder()
                .Where()
                .NumberField('AntragId')
                .EqualTo(this.parentId)
                .OrderByDesc('Modified')
                .ToString();
        } else {
            return new CamlBuilder()
                .Where()
                .LookupField('Auftrag')
                .Id()
                .EqualTo(this.parentId)
                .OrderByDesc('Modified')
                .ToString();
        }
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('forwardMessage') forwardMessage: ElementRef;
    @ViewChild('replyMessage') replyMessage: ElementRef;
    @ViewChild('formioComponent') formioComponent: FormioComponent;

    private bodyEditor: any;
    private bodyEditorAnswer: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailNotificationComponent>,
        public _documentService: DocumentsAssignmentService,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _mailService: MailService,
        private winRef: WindowRef,
        private _requestService: RequestService,
        private _orderService: OrderService,
        private _documentsService: DocumentsService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.winRef.nativeWindow.dateFields = [];
        this.winRef.nativeWindow.userFields = [];
    }

    async ngOnInit() {
        const promises = [];

        if (this.data.mailContent) {
            //this.mailContent = this.data.mailContent;
        }

        if (this.data.auftragsId) {
            this.parentId = this.data.auftragsId;
            this.mailboxListTitle = 'Mailbox';
            this.isRequest = false;
        } else {
            this.parentId = this.data.requestId;
            this.mailboxListTitle = 'Mailbox-Anträge';
            this.isRequest = true;
        }

        this.isReply = this.data.isReply;
        this.replySubject = this.data.replySubject;
        this.replyTo = this.data.replyTo;
        this.taskId = this.data.taskId;

        if (this.data.projectMail) {
            this.projectFrom = this.data.projectMail;
        }

        if (this.isReply) {
            this.defaultTo.push(this.replyTo);

            if (this.data.replyAll) {
                this.replyToAllEmails = [];
                this.data.email.to.forEach((emailAddressData) => {
                    const emails = emailAddressData.email
                        .split(';')
                        .map((address) => address.trim());
                    this.replyToAllEmails.push(...emails);
                });

                this.defaultTo.push(...this.replyToAllEmails);
                this.defaultTo = [...new Set(this.defaultTo)];

                if (this.data.email?.cc[0]?.email) {
                    const ccEmails = [];
                    this.data.email?.cc.forEach((emailAddressData) => {
                        const emails = emailAddressData.email
                            .split(';')
                            .map((address) => address.trim());
                        ccEmails.push(...emails);
                    });

                    this.defaultCC.push(...ccEmails);
                    this.defaultCC = [...new Set(this.defaultCC)];
                }
            }
        }

        this.currentUser = await this._sharepointService.graph.me.select(
            'displayName, streetAddress, businessPhones, mobilePhone, officeLocation, city, postalCode, mySite, faxNumber'
        )();

        if (this.currentUser.businessPhones.length > 0) {
            this.currentUser.businessPhone = this.currentUser.businessPhones[0];
        }

        // Load O365 adresses in background
        this._sharepointService.graph.me.people
            .top(1000)
            .select('scoredEmailAddresses')()
            .then((people) => {
                this.addresses = people
                    .map((x) => x.scoredEmailAddresses)
                    .map((y) => y[0].address);
            })
            .catch((error) => {
                console.error(error);
            });
        this.loadCustomerContacts();

        if (this.id != null) {
            this.isCreateMode = false;

            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            id: this.id,
                            listTitle: this.mailboxListTitle,
                        })
                        .then(
                            (mail) => {
                                this.sharePointItem = mail;

                                resolve();
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                })
            );
        }

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            if (this.isReply || this.data.forwardTo) {
                dataForm.Subject = this.replySubject;
                //dataForm.To.results.push({
                //    Id: this.replyTo,
                //    Title: this.replyTo,
                //});
                //dataForm.Body = this.mailContent;
            }

            if (this.isReply) {
                dataForm.To = { results: this.defaultTo };
                dataForm.CC = { results: this.defaultCC };
            }
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });

        this.bodyEditor = new Quill('.bodyEditor div', {
            //modules: { toolbar: '#toolbar' },
            theme: 'snow',
        });

        if (this.isReplyForwardMode()) {
            this.bodyEditorAnswer = new Quill('.bodyEditorAnswer div', {
                theme: 'snow',
            });
        }

        if (this.data.forwardTo) {
            this.bodyEditor.clipboard.dangerouslyPasteHTML(
                `${this.forwardMessage.nativeElement.innerHTML}<div>${
                    this.data.mailContent || ''
                }</div>`
            );
        } else if (this.isReply) {
            this.bodyEditor.clipboard.dangerouslyPasteHTML(
                `${this.replyMessage.nativeElement.innerHTML}<div>${
                    this.data.mailContent || ''
                }</div>`
            );
        } else {
            this.bodyEditor.clipboard.dangerouslyPasteHTML(
                this.data.mailContent || ''
            );
        }

        this.bodyEditorAnswer?.focus();
    }

    saveSubmission(e) {
        if (
            !e.data.To?.results?.length &&
            !e.data.ToContacts?.results?.length
        ) {
            return of(false);
        }

        this._loadingService.show(
            this._translateService.instant('DETAIL_EMAIL.NOTIFICATIONS.SAVING'),
            this._translateService.instant('DETAIL_EMAIL.NOTIFICATIONS.WAIT')
        );

        const customersContacts = e.data.ToContacts;
        delete e.data.submit;
        delete e.data.bodyEditor;
        delete e.data.bodyEditorAnswer;
        delete e.data.ToContacts;
        let bodyContent = this.bodyEditor.container.firstChild.innerHTML;

        if (this.isReplyForwardMode()) {
            let signature = '';
            if (
                !this.id &&
                e.data.SignatureText &&
                e.data.SignatureText != ''
            ) {
                e.data.SignatureText = e.data.SignatureText.replace(
                    /\r\n|\r|\n/g,
                    '<br />'
                );
                signature = '\n\n<br>' + e.data.SignatureText;
            }

            bodyContent = `
                ${this.bodyEditorAnswer.container.firstChild.innerHTML}
                <div>${signature}</div>
                <div style="padding-left: 10px;border-left: 5px solid gray;">${bodyContent}</div>
            `;
        }

        e.data.Body = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <body>
                ${bodyContent}
            </body>
            </html>
        `;

        if (this.taskId) {
            e.data.ArbeitspaketId = this.taskId;
        }

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        if (this.id) {
            // Edit mode
            return from(
                this._applicationService
                    .callBeforeSaveFunctions(this.formioConfiguration, e.data)
                    .then(() => {
                        this._sharepointService
                            .updateItem({
                                id: this.id,
                                listTitle: this.mailboxListTitle,
                                newFiledValues: e.data,
                            })
                            .then((elementId) => {
                                // Get Mailitem
                                this._sharepointService
                                    .getItemById({
                                        listTitle: this.mailboxListTitle,
                                        id: elementId,
                                    })
                                    .then((element) => {
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_EMAIL.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close({
                                            state: 'success',
                                            element,
                                        });
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        } else {
            // Create mode
            // Set defaults
            e.data.Title = 'out';

            if (this.parentId) {
                if (!this.isRequest) {
                    e.data.ProjektId = this.parentId;
                } else {
                    e.data.AntragId = this.parentId;
                }
            }

            if (this.fileInput.nativeElement.files.length == 0) {
                e.data.ReadyToSend = true;
            } else {
                e.data.ReadyToSend = false;
            }

            e.data.From = this._sharepointService.currentUser.FullName;

            if (customersContacts.results && customersContacts.results.length) {
                if (!e.data.To.results) {
                    e.data.To.results = [];
                }

                e.data.To.results = [
                    ...e.data.To.results,
                    ...customersContacts.results,
                ];
            }

            // Lookup to text
            if (e.data.To.results && e.data.To.results.length > 0) {
                e.data.To = e.data.To.results.join(';');
            } else if (this.isReply) {
                if (this.data.replyAll) {
                    e.data.To = this.replyToAllEmails.join(';');
                } else {
                    e.data.To = this.replyTo;
                }
            }

            if (!this._mailService.validateEmail(e.data.To)) {
                this._snackBarService.add('Invalid email', '', {
                    duration: 4000,
                    panelClass: 'error-dialog',
                });
                this._loadingService.hide();
                return of(false);
            }

            // Lookup to text
            if (e.data.CC.results && e.data.CC.results.length > 0) {
                e.data.CC = e.data.CC.results.join(';');
            } else {
                e.data.CC = null;
            }

            if (e.data.projectFrom == 'projectFrom' && this.projectFrom) {
                e.data.projectFrom = this.projectFrom;
            } else {
                e.data.projectFrom = null;
            }

            if (
                !this.isReplyForwardMode() &&
                e.data.SignatureText &&
                e.data.SignatureText != ''
            ) {
                e.data.SignatureText = e.data.SignatureText.replace(
                    /\r\n|\r|\n/g,
                    '<br />'
                );
                e.data.Body = e.data.Body + '\n\n<br>' + e.data.SignatureText;
            }

            if (this.data.taskId) {
                e.data.ArbeitspaketId = this.data.taskId;
            }

            return from(
                this._applicationService
                    .callBeforeSaveFunctions(this.formioConfiguration, e.data)
                    .then(() => {
                        // Send mail with graph
                        let mail: Mail = {
                            Subject: e.data.Subject,
                            To: e.data.To.split(';'),
                            CC: e.data.CC ? e.data.CC.split(';') : null,
                            Content: e.data.Body,
                            From: e.data.projectFrom,
                        };
                        let attachment_urls = '';

                        delete e.data.projectFrom;
                        delete e.data.SignatureText;
                        delete e.data.SignatureId;

                        // Add ServerRelativeUrls from selected docs
                        mail.AttachmentServerRelativeUrls = [];

                        if (this.documentGrid) {
                            this.documentGrid.data.forEach((_mail) => {
                                if (
                                    this.documentGrid.allSelectedItemsIDs.includes(
                                        _mail.Id
                                    )
                                ) {
                                    mail.AttachmentServerRelativeUrls.push(
                                        _mail.FileRef
                                    );
                                    if (attachment_urls != '') {
                                        attachment_urls += ';';
                                    }
                                    attachment_urls += _mail.FileRef;
                                }
                            });
                        }

                        e.data.Attachment_Urls = attachment_urls;
                        this._mailService.sendMail(mail);

                        this._sharepointService
                            .addItem({
                                listTitle: this.mailboxListTitle,
                                data: e.data,
                                folder: this.parentId + '',
                            })
                            .then((elementId) => {
                                // Get Mailitem
                                this._sharepointService
                                    .getItemById({
                                        listTitle: this.mailboxListTitle,
                                        id: elementId,
                                    })
                                    .then((emailItem) => {
                                        this.sharePointItem = emailItem;
                                        const promises = [];

                                        Promise.all(promises)
                                            .then(() => {
                                                this._loadingService.hide();

                                                this._snackBarService.add(
                                                    this._translateService.instant(
                                                        'DETAIL_EMAIL.NOTIFICATIONS.SUCCESS'
                                                    ),
                                                    '',
                                                    {
                                                        duration: 4000,
                                                        panelClass:
                                                            'success-dialog',
                                                    }
                                                );

                                                this.close({
                                                    state: 'success',
                                                    element:
                                                        this.sharePointItem,
                                                });
                                            })
                                            .catch((error) => {
                                                this._loadingService.hide();
                                                console.error(error);
                                            });
                                    })
                                    .catch((error) => {
                                        this._loadingService.hide();
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                this._loadingService.hide();
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.error(error);
                    })
            );
        }
    }

    onChange(ev) {
        if (ev.changed) {
            //
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    addAttachment() {
        this.fileInput.nativeElement.click();
    }

    fileChangeEvent(fileInput: any) {
        fileInput.target.files.forEach((file) => {
            if (
                !this._mailService.attachmentFiles.some(
                    (x) => x.name == file.name
                )
            ) {
                this._mailService.attachmentFiles.push(file);
            } else {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DETAIL_EMAIL.NOTIFICATIONS.DOUBLEATTACHMENT' +
                            file.name
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
            }
        });
    }

    initForm() {
        this.columns = [
            {
                internalName: 'Permission',
                title: '',
                hidden: false,
                type: ColumnTyp.Text,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.FILESIZE'
                ),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            {
                internalName: 'Standardberechtigung',
                title: '',
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'CopyColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'RenameColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.initDocumentTableConfig();

        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.FROM'
                    ),
                    hideLabel: false,
                    placeholder: '',
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'select',
                    input: true,
                    key: 'projectFrom',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'DETAIL_EMAIL.FORM.CURRENTUSER'
                                ),
                                value: 'currentUser',
                            },
                            {
                                label: this._translateService.instant(
                                    'DETAIL_EMAIL.FORM.PROJECTFROM'
                                ),
                                value: 'projectFrom',
                            },
                        ],
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    lazyLoad: false,
                    hidden: !this.projectFrom,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.SUBJECT'
                    ),
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    type: 'textfield',
                    input: true,
                    key: 'Subject',
                    inputFormat: 'plain',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.TO'
                    ),
                    type: 'sharePointAutocomplete',
                    multi: true,
                    allowAddOnEnter: true,
                    filteredOptionsFunction: (value) => {
                        return new Promise<any>((resolve) => {
                            const filteredOptions = [];

                            if (value != null && value != '') {
                                this.addresses
                                    .filter(
                                        (x) =>
                                            x
                                                .toLocaleLowerCase()
                                                .indexOf(
                                                    value.toLocaleLowerCase()
                                                ) > -1
                                    )
                                    .forEach((address) => {
                                        filteredOptions.push({
                                            Id: address,
                                            Title: address,
                                        });
                                    });
                            }

                            resolve(filteredOptions);
                        });
                    },
                    key: 'To',
                    setValue: this.defaultTo,
                    selectThreshold: 0.3,
                    isPeoplePicker: false,
                    removable: true,
                    isCreateMode: true,
                    customClass: 'mail-to-field',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        custom: `
                            if (data["ToContacts"] && data["ToContacts"].results && data["ToContacts"].results.length) {
                                valid = true;
                            } else {
                                valid = input && input.results && input.results.length;
                            }
                        `,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.CONTACTS'
                    ),
                    type: 'sharePointAutocomplete',
                    multi: true,
                    allowAddOnEnter: false,
                    filteredOptionsFunction: (value) => {
                        return new Promise<any>((resolve) => {
                            const filteredOptions = [];
                            if (!value) {
                                this.clientContacts.forEach((contact) => {
                                    filteredOptions.push({
                                        Id: contact.Email,
                                        Title: `${contact.FirstName} ${contact.Title} (${contact.Email})`,
                                    });
                                });

                                resolve(filteredOptions);
                                return;
                            }

                            this.clientContacts.forEach((contact) => {
                                const contactTitle = `${contact.FirstName} ${contact.Title} (${contact.Email})`;
                                if (
                                    contactTitle
                                        .toLowerCase()
                                        .includes(value.toLowerCase())
                                ) {
                                    filteredOptions.push({
                                        Id: contact.Email,
                                        Title: contactTitle,
                                    });
                                }
                            });

                            resolve(filteredOptions);
                        });
                    },
                    key: 'ToContacts',
                    selectThreshold: 0.3,
                    isPeoplePicker: false,
                    removable: true,
                    customClass: 'mail-contact-field',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.CC'
                    ),
                    type: 'sharePointAutocomplete',
                    multi: true,
                    allowAddOnEnter: true,
                    filteredOptionsFunction: (value) => {
                        return new Promise<any>((resolve) => {
                            const filteredOptions = [];

                            if (value != null && value != '') {
                                this.addresses
                                    .filter(
                                        (x) =>
                                            x
                                                .toLocaleLowerCase()
                                                .indexOf(
                                                    value.toLocaleLowerCase()
                                                ) > -1
                                    )
                                    .forEach((address) => {
                                        filteredOptions.push({
                                            Id: address,
                                            Title: address,
                                        });
                                    });
                            }

                            resolve(filteredOptions);
                        });
                    },
                    key: 'CC',
                    selectThreshold: 0.3,
                    isPeoplePicker: false,
                    removable: true,
                    isCreateMode: this.isCreateMode,
                    customClass: 'mail-cc-field',
                },
                // {
                //     label: this._translateService.instant(
                //         'DETAIL_EMAIL.COLUMNS.TEXT'
                //     ),
                //     isUploadEnabled: false,
                //     showWordCount: false,
                //     showCharCount: false,
                //     tableView: true,
                //     alwaysEnabled: false,
                //     type: 'textarea',
                //     input: true,
                //     key: 'Body',
                //     inputFormat: 'plain',
                //     rows: 8,
                //     clearOnHide: false,
                // },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.TEXT'
                    ),
                    tag: 'div',
                    attrs: [
                        {
                            attr: 'id',
                            value: 'editorContainer',
                        },
                    ],
                    content: '',
                    refreshOnChange: false,
                    key: 'bodyEditorAnswer',
                    type: 'htmlelement',
                    input: false,
                    tableView: false,
                    customClass: 'bodyEditorAnswer',
                    hidden: !this.isReplyForwardMode(),
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.TEXT'
                    ),
                    tag: 'div',
                    attrs: [
                        {
                            attr: 'id',
                            value: 'editorContainer',
                        },
                    ],
                    content: '',
                    refreshOnChange: false,
                    key: 'bodyEditor',
                    type: 'htmlelement',
                    input: false,
                    tableView: false,
                    customClass: 'bodyEditor',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.SIGNATUREID'
                    ),
                    type: 'sharePointAutocomplete',
                    key: 'SignatureId',
                    list: 'Signaturen',
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(0)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    //isCreateMode: this.isCreateMode,
                    onSelected: (control, data) => {
                        let fdata = this.formioComponent.formio._data;
                        fdata.SignatureText = this.replaceSigValues(data.Text);
                        this.refreshForm.emit({
                            submission: {
                                data: fdata,
                            },
                        });
                    },
                    onRemoved: () => {
                        let fdata = this.formioComponent.formio._data;
                        fdata.SignatureText = '';
                        this.refreshForm.emit({
                            submission: {
                                data: fdata,
                            },
                        });
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_EMAIL.COLUMNS.SIGNATURE'
                    ),
                    isUploadEnabled: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textarea',
                    input: true,
                    key: 'SignatureText',
                    inputFormat: 'plain',
                    rows: 8,
                    clearOnHide: false,
                },
                // {
                //     label: this._translateService.instant(
                //         'DETAIL_EMAIL.COLUMNS.ATTACHMENTS'
                //     ),
                //     type: 'contentCustom',
                //     component: ListAttachmentComponent,
                //     key: '',
                // },
                {
                    label: this._translateService.instant('DETAIL_EMAIL.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };

        if (this.isReplyForwardMode()) {
            const submitButtonComponentIndex =
                this.formioConfiguration.components.findIndex(
                    (component) => component.key === 'submit'
                );
            const editorComponentIndex =
                this.formioConfiguration.components.findIndex(
                    (component) => component.key === 'bodyEditor'
                );
            const element =
                this.formioConfiguration.components[editorComponentIndex];
            this.formioConfiguration.components.splice(editorComponentIndex, 1);
            this.formioConfiguration.components.splice(
                submitButtonComponentIndex,
                0,
                element
            );
        }
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                var x = this.documentGrid.itemSelected;
                this.fileInput.nativeElement.click();
                break;
        }
    }

    replaceSigValues(signatureText) {
        let pattern = /{{([a-zA-Z]+)}}/g;

        let tmpArr = signatureText.match(pattern);

        if (tmpArr) {
            tmpArr.forEach((match) => {
                let field = match.replace('{{', '').replace('}}', '');
                let value;

                if (field == 'businessPhones') {
                    if (this.currentUser[field] && this.currentUser[field][0]) {
                        value = this.currentUser['businessPhones'][0];
                    }
                } else {
                    value = this.currentUser[field];
                }

                if (value) {
                    signatureText = signatureText.replace(match, value);
                } else {
                    signatureText = signatureText.replace(match, '');
                }
            });
        }
        return signatureText;
    }

    fileChange(e) {
        let data;

        if (!this.isRequest) {
            data = {
                AuftragId: this.parentId,
            };
        } else {
            data = {
                AntragId: this.parentId,
            };
        }

        this._documentService.fileChangeEvent(
            e,
            this.documentGrid,
            this.tableConfig.list,
            data,
            !this.isReplyForwardMode()
        );
    }

    private async loadCustomerContacts() {
        const projectClientId =
            this.data.project.KundeId || this.data.project.AuftraggeberId || 0;
        const taskClientId = this.data.taskAuftraggeberId || 0;
        let taskClients = [];
        let tasksClientsQueryFilter = `${taskClientId}`;

        if (!this.isRequest) {
            const tasks = await this._sharepointService.getListItems({
                title: 'Auftragsaufgaben',
                folderName: `${this.data.project.Id}`,
                camlQuery: new CamlBuilder()
                    .Where()
                    .LookupField('Auftraggeber')
                    .Id()
                    .IsNotNull()
                    .ToString(),
                isDocumentLibrary: false,
            });

            taskClients = tasks.map((task) => task.AuftraggeberId);
            taskClients = [...new Set(taskClients)];
            tasksClientsQueryFilter =
                taskClients.join(' or Kunde/Id eq ') || '0';
        }

        this._sharepointService.web.lists
            .getByTitle('Kontakte')
            .items.select(
                '*',
                'Kunde/Id',
                'Kunde/Title',
                'Kunde/Title2',
                'Kunde/Title3'
            )
            .filter(
                `Kunde/Id eq ${projectClientId} or Kunde/Id eq ${tasksClientsQueryFilter}`
            )
            .expand('Kunde')
            .orderBy('Title')()
            .then((contacts) => {
                this.clientContacts = contacts;
                this.clientContacts.forEach((contact) => {
                    const kundeTitle1 = contact.Kunde.Title ?? '';
                    const kundeTitle2 = contact.Kunde.Title2 ?? '';
                    const kundeTitle3 = contact.Kunde.Title3 ?? '';
                    const kundeTitles =
                        kundeTitle1 || kundeTitle2 || kundeTitle3
                            ? ` (${kundeTitle1} ${kundeTitle2} ${kundeTitle3})`
                            : '';
                    contact.Title = `${contact.Title}${kundeTitles}`;
                });
            });
    }

    private initDocumentTableConfig(): void {
        this.tableConfig = {
            list: this.isRequest ? 'Antragsdokumente' : 'Auftragsdokumente',
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: false,
            addCheckboxColumn: true,
            camlQuery: this.camlQuery,
            folderName: this.parentId + '',
            rootFolderName: this.rootFolderName,
            useMaterialDesign: true,
            pageSize: 10,
            toolbar: [
                {
                    title: this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NEW'
                    ),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            afterRefreshTable: () => {
                // Update sidebar
                if (this.isRequest) {
                    this.data.isOrder
                        ? this._orderService.getSideBarConfig(this.parentId)
                        : this._requestService.getSideBarConfig(this.parentId);
                } else {
                    this._applicationService.getSideBarConfig(this.parentId);
                }
            },
        };

        if (this.taskId) {
            this.tableConfig = {
                ...this.tableConfig,
                list: 'Aufgabendokumente',
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .OrderByDesc('Modified')
                    .ToString(),
                folderName: this.parentId + '/' + this.taskId,
                rootFolderName: this._translateService.instant(
                    'DETAIL_TASK.DOCS.TITLE'
                ),
            };
        }
    }

    public openRenameDocumentDialog(
        document: SPDocument,
        mode: ChangeDocumentStatus
    ): void {
        this._documentsService
            .openRenameDocumentDialog(this.id, document, mode)
            .subscribe(() => {
                this.documentGrid.refresh();
            });
    }

    private isReplyForwardMode(): boolean {
        return this.data.forwardTo || this.data.replyAll || this.isReply;
    }
}
