import { ApplicationService } from 'src/app/main/services/application.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1106Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList: IList =
                this._sharePointService.web.lists.getByTitle('Aufträge');
            const propertyList =
                this._sharePointService.web.lists.getByTitle('Properties');
            const teamsList: IList =
                await this._sharePointService.web.lists.getByTitle('Teams');
            const locationsList =
                await this._sharePointService.web.lists.getByTitle('Standorte');
            let invoiceList: IList;

            const promises = [
                // Add List Rechnungen
                await this._updateService.addList('Rechnungen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Dokumentenvorlagen
                await new Promise<void>((resolve) => {
                    invoiceList =
                        this._sharePointService.web.lists.getByTitle(
                            'Rechnungen'
                        );
                    resolve();
                }),
                // Add Field Forderung to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Forderung',
                    FieldType.Currency
                ),
                // Add Field Gezahlt to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Gezahlt',
                    FieldType.Currency
                ),
                // Add Field Offen to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Offen',
                    FieldType.Currency
                ),
                // Add Field Jahr to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Jahr',
                    FieldType.Number
                ),
                // Add Field Rechnungsnummer to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Rechnungsnummer',
                    FieldType.Text
                ),
                // Add Field Projekt to List Rechnungen
                await this._updateService.addLookupField(
                    invoiceList,
                    projectList,
                    'Projekt'
                ),
                // Add Field Monat to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Monat',
                    FieldType.Number
                ),
                // Add LookUp-Field Standort to List Rechnungen
                await this._updateService.addLookupField(
                    invoiceList,
                    locationsList,
                    'Standort',
                    false,
                    'Title'
                ),
                // Add LookUp-Field Team to List Rechnungen
                await this._updateService.addLookupField(
                    invoiceList,
                    teamsList,
                    'Team',
                    false,
                    'Title'
                ),
                // Add Field Von to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Von',
                    FieldType.Date
                ),
                // Add Field Bis to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Bis',
                    FieldType.Date
                ),
                // Add Field Frist to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Frist',
                    FieldType.Date
                ),
                // Add Field Abrechnungstyp to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'Abrechnungstyp',
                    FieldType.Text
                ),
                // Add Field AR_Number to List Rechnungen
                await this._updateService.addField(
                    invoiceList,
                    'AR_Number',
                    FieldType.Number
                ),

                // Add Item to List Properties
                await propertyList.items.add({
                    Title: 'Aktuelle Rechnungsnummer',
                    Value: '00001',
                }),

                // Add Field currentAR_Number to List Projekte
                await this._updateService.addField(
                    projectList,
                    'currentAR_Number',
                    FieldType.Number
                ),

                // Add folders for existing orders in List Mailbox-Anträge
                await this._applicationService.addFoldersForExistingProjects(
                    'Rechnungen',
                    false
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
