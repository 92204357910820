export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Tasks',
        KANBAN: 'Kanban',
        NOTES: 'Notes',
        NEW: 'Add new involved',
        PROJECTNUMBER: 'Projectnumber',
        COLUMNS: {
            NAME: 'Names',
            KIND: 'Kind',
            INTERN: 'Intern',
            EXTERN: 'Extern',
            PARTICIPATION: 'Participation',
            INPERCENT: '(in %)',
            START: 'Start',
            END: 'End',
            USER: 'User (AD)',
            PROJECT: 'Project',
            SAVE: 'Save',
        },
    },
};
