export const locale = {
    lang: 'en',
    data: {
        DETAIL_CONTACT: {
            EDIT: 'Edit contact',
            NEW: 'New contact',
            NOTIFICATIONS: {
                SAVING: 'Saving contact...',
                WAIT: 'Please wait.',
                SUCCESS: 'Contact saved successfully',
                ERROR: 'Error while saving: ',
            },
            FORM: {
                SALUTATION: 'Salutation',
                TITLE: 'Name',
                FIRSTNAME: 'Surname',
                EMAIL: 'E-Mail',
                JOBTITLE: 'Jobtitle',
                WORKPHONE: 'Phone (Work)',
                HOMEPHONE: 'Phone (Private)',
                CELLPHONE: 'Phone (Mobile)',
                WORKFAX: 'Fax',
                WORKADRESS: 'Adress',
                STREET: 'Street/Number',
                ZIP: 'ZIP',
                CITY: 'City',
                WEBPAGE: 'Homepage',
                CUSTOMER: 'Company',
                SAVE: 'Save',
            },
        },
    },
};
