import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { from } from 'rxjs';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { ApplicationService } from '../../services/application.service';
import { IWeb } from '@pnp/sp/presets/all';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
    selector: 'app-detail-document',
    templateUrl: './detail-document.component.html',
    styleUrls: ['./detail-document.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailDocumentComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    @Input() auftragsId: number;
    @Input() id: number;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public sharePointItem;
    public projectItem;
    public formioConfiguration;
    public defaultPermissionUser = [];
    public hasDefaultPermission = true;
    public isCreateMode = true;

    private searchGroups: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailDocumentComponent>,
        private sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService,
        private documentService: DocumentsAssignmentService,
        private _applicationService: ApplicationService,
        private _updateService: UpdateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        // Add currentuser to permissions
        this.defaultPermissionUser.push(
            this.sharepointService.currentUser.FullName +
                this._translateService.instant('CURRENTUSER')
        );

        if (this.data.documentId) {
            const promises = [];

            this.id = this.data.documentId;
            this.isCreateMode = false;

            promises.push(
                new Promise<void>((resolve, reject) => {
                    // Dokument holen
                    this.sharepointService
                        .getItemById({
                            id: this.id,
                            listTitle: 'Auftragsdokumente',
                        })
                        .then((document) => {
                            this.sharePointItem = document;

                            // Check Default Perms
                            if (
                                this.sharePointItem.Standardberechtigung != null
                            ) {
                                this.hasDefaultPermission =
                                    this.sharePointItem.Standardberechtigung;
                            }

                            // Projekt holen
                            if (this.data.auftragsId) {
                                this.auftragsId = this.data.auftragsId;

                                this.sharepointService
                                    .getItemById({
                                        id: this.auftragsId,
                                        listTitle: 'Aufträge',
                                    })
                                    .then((project) => {
                                        this.projectItem = project;

                                        if (this.projectItem.AuftragsleiterId) {
                                            // Add Auftragsleiter to permissions
                                            this.getSiteUserByID(
                                                +this.projectItem
                                                    .AuftragsleiterId,
                                                this.sharepointService.web
                                            )
                                                .then((user) => {
                                                    this.defaultPermissionUser.push(
                                                        user.Title +
                                                            this._translateService.instant(
                                                                'MANAGER'
                                                            )
                                                    );

                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        } else {
                                            this.initForm();
                                        }
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            } else {
                                this.initForm();
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._updateService
                        .getPropertyValue('Usergruppen')
                        .then((value) => {
                            this.searchGroups = value;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            Promise.all(promises)
                .then(() => {
                    this.initForm();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    defaultChange(event) {
        this.hasDefaultPermission = event.checked;
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this.loadingService.show(
            this._translateService.instant('NOTIFICATIONS.SAVINGITEM'),
            this._translateService.instant('NOTIFICATIONS.WAIT')
        );

        // Check default perms
        if (!this.hasDefaultPermission) {
            delete e.data.submit;

            this._applicationService.cleanDataForSaving(
                e.data,
                this.formioConfiguration.components
            );

            this._applicationService.callBeforeSaveFunctions(
                this.formioConfiguration,
                e.data
            );

            if (this.sharePointItem) {
                // Set value for default perms
                e.data.Standardberechtigung = this.hasDefaultPermission;

                return from(
                    this.sharepointService
                        .updateItem({
                            id: this.id,
                            listTitle: 'Auftragsdokumente',
                            newFiledValues: e.data,
                        })
                        .then(
                            () => {
                                this.sharepointService
                                    .getItemById({
                                        listTitle: 'Auftragsdokumente',
                                        id: this.id,
                                    })
                                    .then(
                                        (document) => {
                                            this.sharePointItem = document;

                                            this.documentService
                                                .updateDocumentPermissions(
                                                    this.sharePointItem,
                                                    this.projectItem
                                                )
                                                .then(
                                                    () => {
                                                        // Success
                                                        this.loadingService.hide();

                                                        this.snackBarService.add(
                                                            this._translateService.instant(
                                                                'NOTIFICATIONS.SUCCESS'
                                                            ),
                                                            '',
                                                            {
                                                                duration: 4000,
                                                                panelClass:
                                                                    'success-dialog',
                                                            }
                                                        );

                                                        this.close('success');
                                                    },
                                                    (error) => {
                                                        this.loadingService.hide();
                                                        console.error(error);

                                                        this.snackBarService.add(
                                                            this._translateService.instant(
                                                                'NOTIFICATIONS.ERROR'
                                                            ) +
                                                                error.statusText,
                                                            '',
                                                            {
                                                                duration: 4000,
                                                                panelClass:
                                                                    'error-dialog',
                                                            }
                                                        );

                                                        this.close('success');
                                                    }
                                                );
                                        },
                                        (error) => {
                                            this.loadingService.hide();
                                            console.error(error);

                                            this.snackBarService.add(
                                                this._translateService.instant(
                                                    'NOTIFICATIONS.ERROR'
                                                ) + error.statusText,
                                                '',
                                                {
                                                    duration: 4000,
                                                    panelClass: 'error-dialog',
                                                }
                                            );

                                            this.close('success');
                                        }
                                    );
                            },
                            (error) => {
                                this.loadingService.hide();
                                console.log(error);

                                this.snackBarService.add(
                                    this._translateService.instant(
                                        'NOTIFICATIONS.ERROR'
                                    ) + error.statusText,
                                    '',
                                    {
                                        duration: 4000,
                                        panelClass: 'error-dialog',
                                    }
                                );

                                this.close('success');
                            }
                        )
                );
            }
        } else {
            return from(
                this.sharepointService.web.lists
                    .getByTitle('Auftragsdokumente')
                    .items.getById(this.sharePointItem.Id)
                    .resetRoleInheritance()
                    .then(
                        () => {
                            // Update field Standardberechtigung in item
                            this.sharepointService
                                .updateItem({
                                    id: this.id,
                                    listTitle: 'Auftragsdokumente',
                                    newFiledValues: {
                                        Standardberechtigung:
                                            this.hasDefaultPermission,
                                    },
                                })
                                .then((document) => {
                                    this.sharePointItem = document;

                                    // Success
                                    this.loadingService.hide();

                                    this.snackBarService.add(
                                        this._translateService.instant(
                                            'NOTIFICATIONS.SUCCESS'
                                        ),
                                        '',
                                        {
                                            duration: 4000,
                                            panelClass: 'success-dialog',
                                        }
                                    );

                                    this.close('success');
                                });
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'NOTIFICATIONS.ERROR'
                                ) + error.statusText,
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'error-dialog',
                                }
                            );

                            this.close('success');
                        }
                    )
            );
        }
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    onChange(ev) {
        if (ev.changed) {
            // if (ev.changed.component.key === 'DueDate')
        }
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant(
                        'COLUMNS.EDITPERMISSIONS'
                    ),
                    type: 'sharePointAutocomplete',
                    multi: true,
                    key: 'BearbeitungsberechtigungId',
                    selectThreshold: 0.3,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld!',
                        required: true,
                    },
                    isPeoplePicker: true,
                    // useGraph: !window['useApp'],
                    removable: true,
                    searchGroups: this.searchGroups,
                    isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant('COLUMNS.PROJECT'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AuftragId',
                    selectThreshold: 0.3,
                    list: 'Aufträge',
                    isPeoplePicker: false,
                    removable: true,
                    disabled: true,
                    isCreateMode: this.isCreateMode,
                },

                {
                    label: this._translateService.instant('SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }

    public getSiteUserByID(
        id: number,
        web: IWeb
    ): Promise<{ Id: number; Title: string }> {
        const spWeb = web;

        return spWeb.siteUsers
            .getById(id)
            .select('Id', 'Title')<{ Id: number; Title: string }>();
    }
}
