<h1 matDialogTitle>Import Mails</h1>
<div mat-dialog-content class="mat-dialog-body">
    <div class="toolbar">
        <div class="loading">
            Finished {{uploadedAttachments}} of {{totalAttachments}} attachments
            <span *ngIf="uploadedAttachments !== totalAttachments || (uploadStatus.done + uploadStatus.failed) !== uploadStatus.total">
                (<mat-spinner diameter="20"></mat-spinner>)
            </span>
        </div>
        <div class="loading">Finished {{uploadStatus.done + uploadStatus.failed}} of {{uploadStatus.total}} mails</div>
        <div class="stats">
            <div class="success">Success: {{uploadStatus.done}}</div>
            <div class="error">Error: {{uploadStatus.failed}}</div>
        </div>
    </div>
    <app-sharepoint-table #mailsTable
                          *ngIf="tableConfig"
                          [configuration]="tableConfig"
                          (onTooolbarButtonClick)="toolbarButtonHandler($event)"
    >
        <ng-template customCell for="Status" let-dataItem="dataItem">
            <ng-container *ngIf="mailStatus[dataItem.ID];else loading">
                <div class="status-column"
                    [class.error]="mailStatus[dataItem.ID] === EmailImportsStatus.UNEXPECTED || mailStatus[dataItem.ID] === EmailImportsStatus.DUPLICATE"
                >
                    {{ ('ASSIGN_EMAIL.IMPORT_MAILS_DIALOG.' + mailStatus[dataItem.ID]) | translate }}
                </div>
            </ng-container>
            <ng-template #loading>
                <mat-spinner diameter="20"></mat-spinner>
            </ng-template>
        </ng-template>
    </app-sharepoint-table>
</div>
<div mat-dialog-actions class="mat-dialog-actions mt-8">
    <button mat-raised-button
            class="newButton"
            color="accent"
            [disabled]="!isFinished"
            (click)="closeDialog()"
    >
        {{ "ASSIGN_EMAIL.IMPORT_MAILS_DIALOG.OK" | translate }}
    </button>
</div>
