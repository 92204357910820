import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import {
    ColumnTyp,
    CommandType,
    FilterTypes,
    FormatTypes,
    SharePointService,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import { ActivatedRoute } from '@angular/router';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { BillingsService } from '../../services/billings.service';

@Component({
    selector: 'app-invoices-assignment',
    templateUrl: './invoices-assignment.component.html',
    styleUrls: ['./invoices-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class InvoicesAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;
    public formioConfiguration;

    public tableConfig: TableConfiguration;
    public subTableConfig: TableConfiguration;
    public columns: TableColumn[];
    public subTableColumns: TableColumn[];

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _billingsService: BillingsService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('TASKS');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;
            this._billingsService
                .getBillingsAttachmentsByProject(this.id)
                .subscribe();

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this.sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        this.initGrid();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {}

    get camlQueryInvoices() {
        if (this.id) {
            // Return all invoices from this project
            return new CamlBuilder()
                .Where()
                .LookupField('Projekt')
                .Id()
                .EqualTo(this.id)
                .ToString();
        } else {
            // Return no data
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .ToString();
        }
    }

    get camlQueryMyWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryCriticalWorkPackages() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .OrderBy('Title')
            .ToString();
    }

    get camlQueryTasks() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        return (
            '<Where><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            '</Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    get camlQueryMyTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let currentUserExpression = CamlBuilder.Expression()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this.sharePointService.currentUser.Id)
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            currentUserExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    get camlQueryCriticalTasks(): string {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let markExpression = CamlBuilder.Expression()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            markExpression +
            '</And></Where><OrderBy><FieldRef Name="Modified"/></OrderBy>'
        );
    }

    initGrid() {
        if (!this.sharePointItem) {
            return;
        }

        this.subTableColumns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TASK'),
                hidden: false,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Status',
                title: this._translateService.instant('COLUMNS.STATUS'),
                hidden: false,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.subTableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.subTableColumns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: false,
            folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('BUTTONS.NEWTASK'),
                    command: 'AddTask',
                    color: 'accent',
                },
                {
                    title: this._translateService.instant('BUTTONS.MYTASK'),
                    command: CommandType.changeQuery,
                    query: this.camlQueryMyTasks,
                    color: 'basic',
                },
                {
                    title: this._translateService.instant(
                        'BUTTONS.CRITICALTASK'
                    ),
                    command: CommandType.changeQuery,
                    query: this.camlQueryCriticalTasks,
                    color: 'basic',
                },
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryTasks,
            parentItemLookupName: 'Arbeitspaket',
        };

        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('COLUMNS.TITLE'),
                hidden: false,
            },
            {
                internalName: 'Rechnungsnummer',
                title: this._translateService.instant('COLUMNS.BILL_NUMBER'),
                hidden: false,
            },
            {
                internalName: 'Rechnungsdatum',
                title: this._translateService.instant('COLUMNS.DATE'),
                hidden: false,
                type: ColumnTyp.Date,
                filter: FilterTypes.date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'Von',
                title: this._translateService.instant('COLUMNS.VON'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'Bis',
                title: this._translateService.instant('COLUMNS.BIS'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'Forderung',
                title: this._translateService.instant('COLUMNS.NETTO'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'ForderungBrutto',
                title: this._translateService.instant('COLUMNS.BRUTTO'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'PDFAttachments',
                title: '',
                hidden: false,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: 'Rechnungen',
            columns: this.columns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            folderName: this.id + '',
            useMaterialDesign: true,
            groups: [
                {
                    field: 'id',
                    aggregates: [
                        {
                            field: 'Forderung',
                            aggregate: 'sum',
                            postText: '€',
                            format: '#,##0.00',
                        },
                    ],
                },
            ],
            toolbar: [
                {
                    title: this._translateService.instant('BUTTONS.NEW'),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            isDocumentLibrary: false,
            camlQuery: this.camlQueryInvoices,
        };
    }
}
