<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">{{ "REASSIGN_EMAIL.TITLE" | translate }}</span>

    <!-- Close Diaolog -->
    <button mat-icon-button (click)="this.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>

  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" (change)="onChange($event)" [service]="this"
    [renderOptions]="formio_renderOptions"
    [form]="formioConfiguration">
  </formio>

  <app-sharepoint-table #documentGrid *ngIf="tableConfig" [configuration]="tableConfig"
    (onTooolbarButtonClick)="onTooolbarButtonClick($event)">
  </app-sharepoint-table>

</div>
