import { RequestService } from '../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1129Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const propertyList: IList =
                this._sharePointService.web.lists.getByTitle('Teams');

            // Add Field Teamleiter to List Teams
            await this._updateService.addUserField(propertyList, 'Teamleiter');

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
