import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableInvolvedComponent } from './table-involved.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { DayPilotModule } from 'daypilot-pro-angular';
import { DetailInvolvedComponent } from '../detail-involved/detail-involved.component';
import { DetailInvolvedModule } from '../detail-involved/detail-involved.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    declarations: [TableInvolvedComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        TableTaskModule,
        DayPilotModule,
        DetailInvolvedModule,
    ],
    exports: [TableInvolvedComponent]
})
export class TableInvolvedModule {}
