import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImportEmailsService } from 'src/app/main/services/import-emails.service';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableConfiguration
} from 'sp-office365-framework';
import { LoadingService } from 'src/app/main/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { SnackbarService } from 'src/app/main/services/snackbar.service';
import CamlBuilder from 'camljs';
import { EmailImportsStatus } from 'src/app/main/mail/enum';
import { MailService } from 'src/app/main/services/mail.service';
import moment from 'moment-timezone';
import { concatAll, takeUntil, tap } from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';

@Component({
    selector: 'app-import-email-dialog',
    templateUrl: './import-email-dialog.component.html',
    styleUrls: ['./import-email-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImportEmailDialogComponent implements OnInit, OnDestroy {
    public tableConfig: TableConfiguration;
    public uploadStatus = {
        done: 0,
        failed: 0,
        total: 0
    };
    public isFinished: boolean;
    public mailStatus = {};
    public EmailImportsStatus = EmailImportsStatus;
    public sharePointItems = [];
    public totalAttachments = 0;
    public uploadedAttachments = 0;
    private projektEmails: any[] = [];
    private destroy$ = new Subject();
    private uploadAttachmentsQueue$ = new Subject();
    @ViewChild('mailsTable', { static: false }) mailsTable: SharePointTableComponent;


    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private _importEmailsService: ImportEmailsService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _translateService: TranslateService,
        private _snackBarService: SnackbarService,
        private _applicationService: ApplicationService,
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<ImportEmailDialogComponent>
    ) {
        this.initTableConfig();
    }

    async ngOnInit(): Promise<void> {
        this.initUploadAttachmentsQueue();
        this.uploadStatus.total = this.dialogData.selectedMails.length;
        this.tableConfig.data = this.dialogData.selectedMails;
        const { isRequest, parentId, mailboxListTitle, taskId } = this.dialogData;
        const promises = [];
        const host = this._sharepointService.sphostUrl.split('/sites')[0];
        this.projektEmails = await this._sharepointService
            .getListItems({
                title: mailboxListTitle,
                recursiveAll: false,
                folderName: parentId + '',
                camlQuery: new CamlBuilder()
                    .Where()
                    .LookupField(mailboxListTitle === 'Mailbox' ? 'Projekt' : 'Antrag')
                    .Id()
                    .EqualTo(parentId)
                    .ToString(),
                isDocumentLibrary: false
            });

        // The logic is copied from assign-email as is, without refactoring.
        this.dialogData.selectedMails.forEach((element) => {
            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    let data: any = {
                        ReadyToSend: false,
                        Subject: element.Subject,
                        From: element.From,
                        To: element.To,
                        CC: element.CC,
                        Body: element.Body,
                        SentDateTime: element.SentDateTime.toISOString(),
                        MessageId: element.MessageId
                    };

                    if (taskId) {
                        data.ArbeitspaketId = taskId;
                    }

                    if (!isRequest) {
                        data.Title = this.dialogData.isInComingEmail ? 'project' : 'out';
                        data.ProjektId = parentId
                            ? parentId
                            : '';
                    } else {
                        data.Title = this.dialogData.isInComingEmail ? 'request' : 'out';
                        data.AntragId = parentId
                            ? parentId
                            : '';
                    }


                    if (this.checkForDuplicateEmail(element)) {
                        this.mailStatus[element.ID] = EmailImportsStatus.DUPLICATE;
                        element.Status = this._translateService.instant(`ASSIGN_EMAIL.IMPORT_MAILS_DIALOG.${EmailImportsStatus.DUPLICATE}`);
                        this.uploadStatus.failed = this.uploadStatus.failed + 1;
                        return Promise.resolve();
                    }

                    this._sharepointService
                        .addItem({
                            listTitle: mailboxListTitle,
                            data,
                            folder: parentId + '',
                        })
                        .then(async (elementId) => {
                            let promisesA = [];
                            let attachment_urls = '';
                            let body = data.Body;

                            for (let index = 0; index < element.Attachments.length; index++) {
                                const attachment = element.Attachments[index];

                                promisesA.push(
                                    await new Promise<void>(
                                        (resolve, reject) => {
                                            this.base64ToBlob(
                                                attachment.contentBytes,
                                                attachment.contentType
                                            )
                                                .then(
                                                    async (blob) => {
                                                        if (attachment.isInline) {
                                                            attachment.name = `inline_${index}_${attachment.name}`;
                                                        }

                                                        let sourceFile: File;
                                                        const documentListTitle = data.ProjektId ? 'Auftragsdokumente' : 'Antragsdokumente';
                                                        this.totalAttachments++;
                                                        let targetFolderPath =
                                                            `${this._sharepointService.computeServerRelativeUrl(this._sharepointService.sphostUrl)}/${documentListTitle}/${(data.ProjektId || data.AntragId)}/MailAttachments`;

                                                        // this._sharepointService.addFolderByPath({
                                                        //     listTitle: documentListTitle,
                                                        //     rootFolder: (data.ProjektId || data.AntragId),
                                                        //     folderName: 'MailAttachments',
                                                        //     isDocumentLibrary: true,
                                                        // })
                                                        this._applicationService.ensureFolder(targetFolderPath, documentListTitle, (data.ProjektId || data.AntragId), 'MailAttachments', true)
                                                            .then(
                                                            () => {
                                                                sourceFile = new File([blob], attachment.name);

                                                                // Add documents uploading into a queue
                                                                this.addAttachmentIntoQueue(
                                                                    new Observable(observer => {
                                                                        console.log(`%c>> Start uploading '${sourceFile.name}' document. (${new Date().toISOString()})`, 'color: red; font-size: 12px');
                                                                        this._sharepointService.uploadDocumentMSAL(
                                                                            targetFolderPath,
                                                                            sourceFile
                                                                        )
                                                                            .then(async result => {
                                                                                const file = await this._sharepointService.web.getFileByServerRelativePath(result.data.ServerRelativeUrl).getItem('Id');
                                                                                await this._sharepointService
                                                                                    .updateItem({
                                                                                        listTitle: documentListTitle,
                                                                                        id: file['Id'],
                                                                                        newFiledValues: {
                                                                                            AuftragId: data.ProjektId ?? undefined,
                                                                                            AntragId: data.AntragId ?? undefined,
                                                                                        },
                                                                                    });
                                                                            })
                                                                            .finally(() => {
                                                                                observer.next(sourceFile.name);
                                                                                observer.complete();
                                                                            });
                                                                    })
                                                                );
                                                            })
                                                            .catch(
                                                                (error) => {
                                                                    reject(error);
                                                                }
                                                            );

                                                        await this._sharepointService
                                                            .addItemAttachment(
                                                                {
                                                                    listTitle: mailboxListTitle,
                                                                    itemId: elementId,
                                                                    attachmentMetadata:
                                                                        {
                                                                            filename: attachment.name,
                                                                            content: blob,
                                                                        },
                                                                }
                                                            )
                                                            .then(
                                                                () => {
                                                                    const attachmentPath = '/sites/' +
                                                                        this._sharepointService.sphostUrl.split('/sites/')[1] +
                                                                        '/Lists/' +
                                                                        mailboxListTitle
                                                                            .split('ä')
                                                                            .join('')
                                                                            .split('-')
                                                                            .join('') +
                                                                        '/Attachments/' + elementId + '/' + attachment.name;
                                                                    const attachmentUrl = host + attachmentPath;

                                                                    if (attachment.isInline) {
                                                                        const imageId = `cid:${attachment.contentId}`;
                                                                        body = body.replace(imageId, attachmentUrl);
                                                                    }
                                                                    // Add Url to Attachment_Urls
                                                                    attachment_urls += `${attachmentPath};`;
                                                                    resolve();
                                                                }
                                                            )
                                                            .catch(
                                                                (error) => {
                                                                    reject(error);
                                                                }
                                                            );
                                                    }
                                                )
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        }
                                    )
                                );
                            }

                            Promise.all(promisesA)
                                .then(() => {
                                    // Update Mailitem
                                    this._sharepointService
                                        .updateItem({
                                            listTitle:
                                            mailboxListTitle,
                                            id: elementId,
                                            newFiledValues: {
                                                Body: body,
                                                Attachment_Urls:
                                                attachment_urls,
                                            },
                                        })
                                        .then(() => {
                                            this.uploadStatus.done = this.uploadStatus.done + 1;
                                            this.mailStatus[element.ID] = EmailImportsStatus.IMPORTED;
                                            element.Status = this._translateService.instant(`ASSIGN_EMAIL.IMPORT_MAILS_DIALOG.${EmailImportsStatus.IMPORTED}`);
                                            // Get Mailitem
                                            this._sharepointService
                                                .getItemById({
                                                    listTitle: mailboxListTitle,
                                                    id: elementId,
                                                })
                                                .then(
                                                    (emailItem) => {
                                                        this.sharePointItems.push(
                                                            emailItem
                                                        );
                                                        resolve();
                                                    }
                                                )
                                                .catch((error) => {
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                                .catch((error) => {
                                    this._loadingService.hide();
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                    .catch(() => {
                        this.uploadStatus.failed = this.uploadStatus.failed + 1;
                        this.mailStatus[element.ID] = EmailImportsStatus.UNEXPECTED;
                        element.Status = this._translateService.instant(`ASSIGN_EMAIL.IMPORT_MAILS_DIALOG.${EmailImportsStatus.UNEXPECTED}`);
                    })
            );

        });

        this.isFinished = true;
        this.cdr.markForCheck();
        this._loadingService.hide();
    }

    base64ToBlob(inputstring, type): Promise<Blob> {
        return new Promise<any>((resolve, reject) => {
            var url = 'data:' + type + ';base64,' + inputstring;
            fetch(url)
                .then(async (res) => {
                    var blob = await res.blob();
                    resolve(blob);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public toolbarButtonHandler(event): void {
        if (event.command === 'group') {
            this.tableConfig.groups = [{ field: 'Status' }];
        } else {
            this.tableConfig.groups = [];
        }

        this.mailsTable.refresh();
    }

    public closeDialog(): void {
        this.sharePointItems
            .forEach(item => {
                item.SentDateTime = moment(item.SentDateTime)
                    .tz('Europe/Berlin')
                    .format('YYYY-MM-DDTHH:mm:ss');
            });
        this.dialogRef.close(this.sharePointItems);
    }

    private initTableConfig(): void {
        this.tableConfig = {
            list: '',
            columns: [
                {
                    internalName: 'ID',
                    title: '',
                    hidden: true
                },
                {
                    internalName: 'Subject',
                    title: this._translateService.instant('ASSIGN_EMAIL.COLUMNS.SUBJECT'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'From',
                    title: this._translateService.instant('ASSIGN_EMAIL.COLUMNS.FROM'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'To',
                    title: this._translateService.instant('ASSIGN_EMAIL.COLUMNS.TO'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Status',
                    title: this._translateService.instant('ASSIGN_EMAIL.COLUMNS.STATUS'),
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '270px'
                },
                {
                    internalName: 'SentDateTime',
                    title: this._translateService.instant(
                        'ASSIGN_EMAIL.COLUMNS.SENTDATETIME'
                    ),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMddHH,
                    width: '270px'
                },
            ],
            showEditButton: false,
            showDeleteButton: false,
            useMaterialDesign: true,
            toolbar: [
                {
                    title: 'Group',
                    command: 'group',
                },
                {
                    title: 'Ungroup',
                    command: 'Ungroup',
                }
            ],

            isDocumentLibrary: false,
            data: []
        };
    }

    private checkForDuplicateEmail(element): boolean {
        return MailService.checkForDuplicateEmail(this.projektEmails, element);
    }

    private initUploadAttachmentsQueue(): void {
        this.uploadAttachmentsQueue$
            .pipe(
                takeUntil(this.destroy$),
                tap(() => console.log(`%c>> Add new document into the upload queue (${new Date().toISOString()})`, 'color: blue; font-size: 12px')),
                concatAll()
            )
            .subscribe((fileName) => {
                    this.uploadedAttachments++;
                    console.log(`%c>> Finish document uploading '${fileName}', queue is released (${new Date().toISOString()})`, 'color: GREEN; font-size: 12px');
                },
                () => {
                    this.uploadedAttachments++;
                    console.error('Error during uploading of an attachment');
                }
            );
    }

    private addAttachmentIntoQueue(promise: Observable<string>): void {
        this.uploadAttachmentsQueue$.next(promise);
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }

}
