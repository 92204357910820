import { DetailGroupComponent } from './../detail-group/detail-group.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    ListViewConfig,
    SnackbarService,
    TableConfig,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    TableColumn,
    ColumnTyp,
    SharePointService,
} from 'sp-office365-framework';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';

@Component({
    selector: 'app-listview-group',
    templateUrl: './listview-group.component.html',
    styleUrls: ['./listview-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ListviewGroupComponent implements OnInit {
    @ViewChild('groupGrid', { static: false }) groupGrid: ListViewComponent;

    public config: ListViewConfig;
    public viewConfigs: TableConfig[];
    public columns: TableColumn[];

    private groups: any[];

    constructor(
        public dialog: MatDialog,
        public _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public _translateService: TranslateService,
        private _sharePointService: SharePointService,
        private _snackbarService: SnackbarService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    async ngOnInit(): Promise<void> {
        this.groups = await this._sharePointService.web.siteGroups();

        // Set ID for Listview
        this.groups.forEach((element) => {
            element.ID = element.Id;
        });

        this.initConfig();
    }

    openDialog(item?): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {};

        if (item) {
            dialogConfig.data.groupItem = item;
        }

        const dialogRef = this.dialog.open(DetailGroupComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            if (result.GroupItem && result.NewGroup) {
                // Add new group to data
                this.groups.push(result.GroupItem);
                // Sort by Title
                this.groups.sort((a, b) =>
                    a.Title.toUpperCase() < b.Title.toUpperCase()
                        ? -1
                        : a.Title.toUpperCase() > b.Title.toUpperCase()
                        ? 1
                        : 0
                );
            } else if (result.GroupItem) {
                // Update values
                this.groups[
                    this.groups.findIndex(
                        (item) => item.Id === result.GroupItem.Id
                    )
                ] = result.GroupItem;
            }

            // Refresh table
            self.groupGrid._listViewService.onListViewDataChanged.next(
                self.groupGrid._listViewService.listView
            );
        });
    }

    initConfig() {
        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('LV_GROUP.COLUMNS.TITLE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Description',
                title: this._translateService.instant(
                    'LV_GROUP.COLUMNS.DESCRIPTION'
                ),
                hidden: false,
                type: ColumnTyp.MultiText,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.viewConfigs = [
            {
                title: this._translateService.instant('LV_GROUP.ACTIVE'),
                config: {
                    list: '',
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: this.groups,
                },
            },
        ];

        this.config = {
            title: this._translateService.instant('LV_GROUP.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_GROUP.SEARCH_LABEL'
            ),
            icon: 'assignment_turned_in',
            add_icon: 'add',
            openEditInDialog: true,
            onRowClick: (item) => {
                this.openDialog(item);
            },
            onAddClick: () => {
                this.openDialog();
            },
            onDeleteClick: (items) => {
                // Delete group
                items.forEach((group) => {
                    this._sharePointService.web.siteGroups.removeById(
                        group.dataItem.Id
                    );

                    this.groups.splice(
                        this.groups.findIndex(
                            (item) => item.Id === group.dataItem.Id
                        ),
                        1
                    );
                });

                // Snackbar bestätigung
                this._snackbarService.add(
                    this._translateService.instant(
                        'LV_GROUP.NOTIFICATIONS.DELETED'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                // Refresh table
                this.groupGrid._listViewService.onListViewDataChanged.next(
                    this.groupGrid._listViewService.listView
                );
            },
            tableConfigs: this.viewConfigs,
        };
    }
}
