export class EmployeeanalysisUtils {
    static generateExcel2ndPage(grid, usersExcelWorkbook, usersExcelFlatWorkbook): void {
        usersExcelWorkbook.sheets[0].name = 'Export NW';
        usersExcelFlatWorkbook.sheets[0].name = 'Export NW';
        usersExcelWorkbook.sheets = usersExcelWorkbook.sheets.concat(usersExcelFlatWorkbook.sheets);
        const data: Record<string, { totalHours: number, startIndex: number, endIndex: number; plannedTimeInHours?: number; diff?: number; indexDiff?: number; done?: boolean }> = {};

        grid.forEach((item, index) => {
            const token = `${item.employeeTitle}_${item.year}_${item.month}`;

            if (!data[token]) {
                data[token] = {
                    totalHours: 0,
                    startIndex: index,
                    endIndex: index
                };
            }

            if (data[token].startIndex !== index) {
                data[token].endIndex = index;
            }

            data[token].totalHours = data[token].totalHours + item.totalTimeInHoursNumber;
            data[token].plannedTimeInHours = item.plannedTimeInHoursNumber;
        });

        Object.keys(data).forEach(key => {
            data[key].diff = data[key].totalHours - data[key].plannedTimeInHours;
            data[key].indexDiff = data[key].endIndex - data[key].startIndex;
            data[key].indexDiff = !data[key].indexDiff ? 1 : (data[key].indexDiff + 1);
        });

        usersExcelFlatWorkbook.sheets[0].columns.push({ width: 100 });
        usersExcelFlatWorkbook.sheets[0].columns.push({ width: 70 });
        usersExcelFlatWorkbook.sheets[0].columns.push({ width: 120 });
        // Add new cells(calculated) into the row
        usersExcelFlatWorkbook.sheets[0].rows[0].cells.push({ value: 'gebuchte Stunden je Monat' });
        usersExcelFlatWorkbook.sheets[0].rows[0].cells.push({ value: 'Sollstunden je Monat' });
        usersExcelFlatWorkbook.sheets[0].rows[0].cells.push({ value: 'Über-/Minusstunden je Monat' });

        usersExcelFlatWorkbook.sheets[0].rows.forEach((row, rowIndex) => {
            if (row.type === 'header') {
                row.height = 70;
                row.cells.forEach(cell => {
                    cell.color = '#ffffff';
                    cell.background = '#7a7a7a';
                    cell.wrap = true;
                    cell.borderBottom = { size: 1 };
                    cell.borderLeft = { size: 1 };
                    cell.borderRight = { size: 1 };
                    cell.borderTop = { size: 1 };
                });
            }

            if (row.type === 'data') {
                const token = `${row.cells[0].value}_${row.cells[2].value}_${row.cells[1].value}`;
                const group = data[token];

                // Add union cells for multiple rows only once
                if (!group.done) {
                    row.cells.push({
                        rowSpan: group.indexDiff,
                        value: group.totalHours,
                        textAlign: 'center',
                        verticalAlign: 'center'
                    });

                    row.cells.push({
                        rowSpan: group.indexDiff,
                        value: group.plannedTimeInHours,
                        textAlign: 'center',
                        verticalAlign: 'center'
                    });

                    row.cells.push({
                        rowSpan: group.indexDiff,
                        value: group.diff,
                        textAlign: 'center',
                        verticalAlign: 'center',
                        background: group.diff > 0 ? '#c6efce' : '#ffc7ce',
                        color: group.diff > 0 ? '#008300' : '#ff0000'
                    });
                }

                row.cells.forEach(cell => {
                    cell.borderBottom = { size: 1 };
                    cell.borderLeft = { size: 1 };
                    cell.borderRight = { size: 1 };
                    cell.borderTop = { size: 1 };
                });

                group.done = true;
            }
        });
    }
}
