import { LoadingService } from 'src/app/main/services/loading.service';
import { MoveElementComponent } from './../../task/move-element/move-element.component';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import {
    TableConfiguration,
    TableColumn,
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { ActivatedRoute } from '@angular/router';
import { DocumentsAssignmentService } from './documents-assignment.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../services/application.service';
import { DetailDocumentComponent } from '../../document/detail-document/detail-document.component';
import { DetailTaskComponent } from '../../task/detail-task/detail-task.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SPDocument } from 'src/app/main/shared/models';
import { DocumentsService } from 'src/app/main/services/documents.service';
import { ChangeDocumentStatus } from '../../document/change-document-name/enum';

@Component({
    selector: 'app-documents-assignment',
    templateUrl: './documents-assignment.component.html',
    styleUrls: ['./documents-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DocumentsAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public tableConfig: TableConfiguration;
    public tableConfigTaskDocs: TableConfiguration;
    public header;
    public taskDocumentTabHeader;
    public uploadMultipleDocuments = true;
    public ChangeDocumentStatus = ChangeDocumentStatus;
    private projectItem;
    private rootFolderName = 'Dokumente';
    private locationFileserver;
    private projectfolderName;

    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;

    @ViewChild('taskGrid', { static: false })
    taskGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    private columns: TableColumn[];
    private columnsTaskDocs: TableColumn[];

    get camlQueryAll() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryProjectDocs() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .EqualTo(this.id)
            .Or()
            .NumberField('FSObjType')
            .EqualTo(1)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryTaskDocs() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .EqualTo(this.id)
            .And()
            .NumberField('FSObjType')
            .EqualTo(0)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private _sharePointService: SharePointService,
        public documentService: DocumentsAssignmentService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _snackBarService: SnackbarService,
        private _documentsService: DocumentsService,
        public dialog: MatDialog,
        private _loadingService: LoadingService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.taskDocumentTabHeader = this._translateService.instant(
            'DOCUMENTASSIGNMENT.TABS.TASKS'
        );
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                // Get number of task-documents
                this.getNewTabHeader();

                this._sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        const promises = [];

                        this.projectItem = assignment;
                        this.itemTitle = this.projectItem.Title;

                        if (
                            this.projectItem.Auftragsstart &&
                            this.projectItem.Auftragsstart != ''
                        ) {
                            this.projectfolderName =
                                new Date(
                                    this.projectItem.Auftragsstart
                                ).getFullYear() +
                                '\\' +
                                this.projectItem.Projektnummer;
                        }

                        if (this.projectItem.StandortId) {
                            promises.push(
                                new Promise((resolve, reject) => {
                                    this._sharePointService
                                        .getItemById({
                                            listTitle: 'Standorte',
                                            id: this.projectItem.StandortId,
                                        })
                                        .then((location) => {
                                            if (
                                                location &&
                                                location.Fileserver
                                            ) {
                                                this.locationFileserver =
                                                    location.Fileserver;
                                            }

                                            resolve(null);
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                })
                            );
                        }

                        if (this.projectItem.Projektnummer) {
                            this.rootFolderName =
                                this.projectItem.Projektnummer;
                            this.itemTitle =
                                this.projectItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'DOCUMENTASSIGNMENT.PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.projectItem.Projektnummer +
                                ')';
                        }

                        Promise.all(promises)
                            .then(() => {
                                this.initGrid();
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    getNewTabHeader(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._applicationService
                .getNumberOfItemsWithoutFolders(
                    'Aufgabendokumente',
                    this.id + '',
                    new CamlBuilder()
                        .Where()
                        .LookupField('Auftrag')
                        .Id()
                        .EqualTo(this.id)
                        .And()
                        .NumberField('FSObjType')
                        .EqualTo(0)
                        .And()
                        .LookupField('Aufgabe')
                        .Id()
                        .IsNotNull()
                        .OrderBy('Modified')
                        .ToString()
                )
                .then((length) => {
                    this.taskDocumentTabHeader =
                        this._translateService.instant(
                            'DOCUMENTASSIGNMENT.TABS.TASKS'
                        ) +
                        ' (' +
                        length +
                        ')';
                    resolve();
                })
                .catch((error) => {
                    console.error(error);
                    reject();
                });
        });
    }

    onNavChange() {}

    onTabChange(e) {
        //
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'moveElement':
                this.moveElement(e);
                break;
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
            case 'AddFolder':
                this.documentService.addNewFolder(
                    this.documentGrid,
                    this.tableConfig.list
                );
                break;
            case 'Fileserver':
                if (!this.locationFileserver) {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'DOCUMENTASSIGNMENT.NOTIFICATIONS.MISSINGPATH'
                        ),
                        '',
                        {
                            duration: 10000,
                            panelClass: 'error-dialog',
                        }
                    );
                } else if (!this.projectfolderName) {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'DOCUMENTASSIGNMENT.NOTIFICATIONS.MISSINGSTART'
                        ),
                        '',
                        {
                            duration: 10000,
                            panelClass: 'error-dialog',
                        }
                    );
                } else {
                    this._loadingService.show(
                        this._translateService.instant(
                            'DOCUMENTASSIGNMENT.NOTIFICATIONS.CONNECTING'
                        ),
                        this._translateService.instant(
                            'DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'
                        )
                    );

                    if (
                        this.locationFileserver.substr(
                            this.locationFileserver.length - 1
                        ) != '/' &&
                        this.locationFileserver.substr(
                            this.locationFileserver.length - 1
                        ) != '\\'
                    ) {
                        this.locationFileserver += '\\';
                    }

                    this._sharePointService
                        .getListItems({
                            title: 'Netzlaufwerkunterordner',
                            isDocumentLibrary: false,
                            camlQuery: this.camlQueryAll,
                        })
                        .then((x: any[]) => {
                            let subfolders = '';
                            x.forEach((element, index) => {
                                subfolders += '&' + element.Title;
                            });

                            this._loadingService.hide();

                            window.open(
                                'mopa:' +
                                    this.locationFileserver +
                                    this.projectfolderName +
                                    '?create' +
                                    subfolders,
                                '_blank'
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            this._loadingService.hide();
                        });
                }

                break;
            case 'Connect':
                window.open(
                    'https://mopa-netzlaufwerk.azurewebsites.net/install/publish.htm',
                    '_blank'
                );
                break;
        }
    }

    moveElement(e) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            projectId: this.id,
            taskId: null,
            itemId: e.dataItem.Id,
            fileRef: e.dataItem.FieldValuesAsText.FileRef,
            objectType: e.dataItem.FieldValuesAsText.FSObjType,
        };

        const dialogRef = this.dialog.open(MoveElementComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            this.documentGrid.refresh();
            //Get new tabHeader
            this.getNewTabHeader()
                .then(() => {
                    this.taskGrid.refresh();
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    public openRenameDocumentDialog(
        document: SPDocument,
        mode: ChangeDocumentStatus
    ): void {
        this._documentsService
            .openRenameDocumentDialog(this.id, document, mode)
            .subscribe(() => {
                this.documentGrid.refresh();
                this.taskGrid.refresh();
            });
    }

    openDialog(item): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.projectItem.Id,
            documentId: item.Id,
        };

        const dialogRef = this.dialog.open(
            DetailDocumentComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            this.documentGrid.refresh();
        });
    }

    openTaskDialog(item) {
        this._sharePointService
            .getItemById({ listTitle: 'Auftragsaufgaben', id: item.AufgabeId })
            .then(
                (task) => {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.width = window.innerWidth * 0.75 + 'px';
                    dialogConfig.hasBackdrop = true;
                    dialogConfig.disableClose = true;

                    dialogConfig.data = {
                        auftragsId: task.AuftragId,
                        isTask: task.Aufgabenart == 'Aufgabe',
                        taskId: task.Id,
                    };

                    const dialogRef = this.dialog.open(
                        DetailTaskComponent,
                        dialogConfig
                    );

                    dialogRef.afterClosed().subscribe((result) => {
                        this.documentGrid.refresh();
                        //Get new tabHeader after moving a file from
                        // "Projekte/Dokumente/Aufgabendokumente"
                        this.getNewTabHeader()
                            .then(() => {
                                this.taskGrid.refresh();
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    initGrid() {
        if (!this.projectItem) {
            return;
        }

        this.columns = [
            {
                internalName: 'Permission',
                title: '',
                hidden: false,
                type: ColumnTyp.Text,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.FILESIZE'
                ),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            {
                internalName: 'CopyColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'RenameColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'FileSystemObjectType',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Standardberechtigung',
                title: '',
                hidden: true,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        let toolbar = [
            {
                title: this._translateService.instant('DOCUMENTASSIGNMENT.NEW'),
                command: 'Add',
                color: 'accent',
            },
            {
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.NEWFOLDER'
                ),
                command: 'AddFolder',
                color: 'accent',
            },
            {
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.FILESERVER'
                ),
                command: 'Fileserver',
                color: 'accent',
            },
            {
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.CONNECT'
                ),
                command: 'Connect',
                color: 'accent',
            },
        ];

        this.tableConfig = {
            list: 'Auftragsdokumente',
            pageSize: 20,
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this.camlQueryProjectDocs,
            folderName: this.id + '',
            rootFolderName: this.rootFolderName,
            useMaterialDesign: true,
            afterDeleteItem: () => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESS_DELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );
            },
            toolbar,
            afterRefreshTable: () => {
                // Update sidebar
                this._applicationService.getSideBarConfig(this.id);
            },
        };

        this.columnsTaskDocs = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'Aufgabe',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.TASK'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.FILESIZE'
                ),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'DOCUMENTASSIGNMENT.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            {
                internalName: 'CopyColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'RenameColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfigTaskDocs = {
            list: 'Aufgabendokumente',
            columns: this.columnsTaskDocs,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: true,
            camlQuery: this.camlQueryTaskDocs,
            folderName: this.id + '',
            rootFolderName: this._translateService.instant(
                'DOCUMENTASSIGNMENT.TASKDOCS'
            ),
            useMaterialDesign: true,
        };
    }

    public async renameFolder() {
        // const listFolders = await this._sharePointService.web.lists.getByTitle("Auftragsdokumente").rootFolder.folders();
        //
        // listFolders.for
        // console.warn(listFolders);
        // const folder = this._sharePointService.web.getFolderByServerRelativePath("/sites/DevAlexey/MoPa-Development/Dev1/Auftragsdokumente/_1");
        // const item = await folder.getItem('FileLeafRef');
        // console.warn(item['FileLeafRef'])
        // // const result = await item.update({ FileLeafRef: `${item['FileLeafRef']}` });
        // const result = await item.update({ FileLeafRef: `1` });
    }
}
