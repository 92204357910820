import CamlBuilder from 'camljs';
import { OrderService } from './../services/order.service';
import { AddFolderOrdReqComponent } from './add-folder-ord-req/add-folder-ord-req.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SharePointService } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { ApplicationService } from '../services/application.service';
import { LoadingService } from '../services/loading.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Injectable({
    providedIn: 'root',
})
export class OrdReqDocsService {
    public acceptedFileExtensions =
        '.xlsx, .xls, .csv, .docx, .doc, .pptx, .ppt, .pdf, .zip';

    constructor(
        public dialog: MatDialog,
        public _loadingService: LoadingService,
        private _sharePointService: SharePointService,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _snackBarService: SnackbarService,
        private _orderService: OrderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    public loadItemFromFile(fileServerRelativeUrl: string): Promise<any> {
        return this._applicationService.loadItemFromFile(fileServerRelativeUrl);
    }

    public fileChangeEvent(
        fileInput: any,
        documentGrid: any,
        listName: string,
        newFiledValues?: any
    ): void {
        let uploadText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.UPLOAD'
        );
        let successText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESS'
        );
        let errorText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.ERROR'
        );

        // Check if Multi
        if (fileInput.target.files.length > 1) {
            uploadText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.UPLOADMULTI'
            );

            successText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESSMULTI'
            );

            errorText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.ERRORMULTI'
            );
        }

        this._loadingService.show(
            uploadText,
            this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'
            )
        );

        const promises = [];

        for (const file of fileInput.target.files) {
            if (file) {
                let folderpath = '';

                if (documentGrid.getCurrentFolderPath()) {
                    folderpath = documentGrid.getCurrentFolderPath() + '';
                }

                promises.push(
                    this.uploadDocumentsAndSetValues(
                        file,
                        listName,
                        folderpath,
                        newFiledValues
                    )
                );
            }
        }

        Promise.all(promises)
            .then(() => {
                documentGrid.refresh();
                this._loadingService.hide();

                this._snackBarService.add(successText, '', {
                    duration: 4000,
                    panelClass: 'success-dialog',
                });
            })
            .catch((error) => {
                this._loadingService.hide();

                this._snackBarService.add(errorText + error.statusText, '', {
                    duration: 4000,
                    panelClass: 'error-dialog',
                });
            });

        fileInput.target.value = '';
    }

    private changeFileEnding({ name }): string {
        return (
            name.slice(0, name.lastIndexOf('.')) +
            name.substring(name.lastIndexOf('.')).toLocaleLowerCase()
        );
    }

    public uploadDocumentsAndSetValues(
        file: File,
        listName: string,
        folderName: string,
        newFiledValues?: any
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const newName: string = this.changeFileEnding(file);

            this._sharePointService.web.lists
                .getByTitle(listName)
                .select('Title', 'RootFolder/ServerRelativeUrl')
                .expand('RootFolder')()
                .then((response) => {
                    const promises = [];

                    if (!window['useApp']) {
                        // MSAL
                        promises.push(
                            this._sharePointService.uploadDocumentMSAL(
                                response.RootFolder.ServerRelativeUrl +
                                    '/' +
                                    folderName,
                                file,
                                null,
                                newName
                            )
                        );
                    } else {
                        // APP
                        promises.push(
                            this._sharePointService.uploadDocument(
                                file,
                                folderName,
                                listName,
                                newName
                            )
                        );
                    }

                    Promise.all(promises)
                        .then((results: any) => {
                            this._applicationService
                                .loadItemFromFile(
                                    results[0].data.ServerRelativeUrl
                                )
                                .then((data) => {
                                    if (newFiledValues) {
                                        // Update Item
                                        this._sharePointService
                                            .updateItem({
                                                listTitle: listName,
                                                id: data.item.Id,
                                                newFiledValues,
                                            })
                                            .then(() => {
                                                // Success
                                                resolve();
                                            })
                                            .catch((error) => {
                                                reject(error);
                                                console.error(
                                                    'Error in updateItem function'
                                                );
                                            });
                                    } else {
                                        // Success
                                        resolve();
                                    }
                                })
                                .catch((error) => {
                                    console.error(
                                        'Error in loadFilesFromFolder function'
                                    );
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            console.error('Error in uploadDocument function');
                            reject(error);
                        });
                })
                .catch((error) => {
                    console.error('Error in get rootfolder function');
                    reject(error);
                });
        });
    }

    async checkMainFolder(orderId: number): Promise<any> {
        try {
            const docsEntry = await this._sharePointService.getListItems({
                title: 'Antragsdokumente',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('FileLeafRef')
                    .EqualTo(orderId.toString())
                    .ToString(),
            });

            if (!docsEntry.length) {
                //Item does not exist --> create folder for this
                await this._orderService.createDependantFoldersForOrder(
                    orderId
                );
            }

            //Item exists --> no further action needed
            return;
        } catch (error) {
            throw new Error(error);
        }
    }

    addNewFolder(grid: any, listName: string, id: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.15 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        const dialogRef = this.dialog.open(
            AddFolderOrdReqComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.checkMainFolder(id);

                this._loadingService.show(
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.ADDINGFOLDER'
                    ),
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'
                    )
                );
                // Add folder to List
                this._sharePointService.web.folders
                    .addUsingPath(
                        listName +
                            '/' +
                            grid.getCurrentFolderPath() +
                            '/' +
                            result
                    )
                    .then((newFolder) => {
                        // success
                        grid.refresh();
                        this._loadingService.hide();
                        this._snackBarService.add(
                            this._translateService.instant(
                                'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESSFOLDER'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        this._loadingService.hide();
                    });
            }
        });
    }
}
