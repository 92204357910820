import { IList } from '@pnp/sp/presets/all';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1131Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const mailboxList: IList =
                this._sharePointService.web.lists.getByTitle('Mailbox');
            const mailboxRequestsList: IList =
                this._sharePointService.web.lists.getByTitle('Mailbox-Anträge');
            await this._updateService.updateFieldProperty(
                mailboxList,
                'To',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );
            await this._updateService.updateFieldProperty(
                mailboxList,
                'CC',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );
            await this._updateService.updateFieldProperty(
                mailboxList,
                'BCC',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );

            await this._updateService.updateFieldProperty(
                mailboxRequestsList,
                'To',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );
            await this._updateService.updateFieldProperty(
                mailboxRequestsList,
                'CC',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );
            await this._updateService.updateFieldProperty(
                mailboxRequestsList,
                'BCC',
                { FieldTypeKind: 3 },
                'SP.FieldMultiLineText'
            );
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
