import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ListViewConfig,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
    SharePointService,
    ColumnTyp,
    FormatTypes,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TaskService } from '../../services/task.service';
import { DetailTaskComponent } from '../../task/detail-task/detail-task.component';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-listview-currentuser',
    templateUrl: './listview-currentuser.component.html',
    styleUrls: ['./listview-currentuser.component.scss'],
})
export class ListviewCurrentuserComponent {
    public config: ListViewConfig;
    public subTableConfig: TableConfiguration;
    public subTableColumns: TableColumn[];

    @ViewChild('listView', { static: false }) listView: ListViewComponent;

    get myAssignmentsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .UserField('Auftragsleiter')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('PPL')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Ansprechpartner')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Antragssteller')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Sachbearbeiter')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .OrderBy('Title')
            .ToString();
    }

    get myAssignmentsSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                ]
            )
            .And()
            .UserField('Auftragsleiter')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('PPL')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Ansprechpartner')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Antragssteller')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .Or()
            .UserField('Sachbearbeiter')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .OrderBy('Title')
            .ToString();
    }

    get myactiveWPCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .ToString();
    }

    get myactiveWPSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .ToString();
    }

    get allTasksFromWPCamlQuery() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let projectRequiredExpression = CamlBuilder.Expression()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            projectRequiredExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            '</Where>' +
            '<OrderBy><FieldRef Name="Modified" Ascending="False" /></OrderBy>'
        );
    }

    get myactiveTaksCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .ToString();
    }

    get myactiveTaksSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .UserField('AssignedTo')
            .Id()
            .EqualTo(this._sharePointService.currentUser.Id)
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .ToString();
    }

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private router: Router,
        private _sharePointService: SharePointService,
        private _taskService: TaskService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.initConfig();
    }

    openDialog(item, isTask): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: item.AuftragId,
            isTask,
            taskId: item.Id,
            arbeitsPaketId: item.ArbeitspaketId,
        };

        const dialogRef = this.dialog.open(DetailTaskComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            // Refresh table
            self.listView._listViewService.onListViewDataChanged.next(
                self.listView._listViewService.listView
            );

            if (isTask) {
                // Refresh subtables
                self.listView.listComponent.sharePointTableComponent.refreshSubtables();
            }
        });
    }

    initConfig() {
        const hasRootPermissions = this._sharePointService.currentUser.IsBusinessOwner ||
            this._sharePointService.currentUser.IsAdministrator;
        this.subTableColumns = [
            {
                internalName: 'Title',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.DUEDATE'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.ASSIGNED'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Prozessschritt',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.PROCESSSTEP'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftrag',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.PROJECT'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant(
                    'LV_CURRENTUSER.COLUMNS_TASK.WORKPACKAGE'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.subTableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.subTableColumns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            // folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: this.allTasksFromWPCamlQuery,
            onEditButtonClick: (item) => {
                this.openDialog(item, true);
            },
            afterDeleteItem: (item) => {
                this._taskService.afterDeleteItem(item);
            },
            parentItemLookupName: 'Arbeitspaket',
        };

        this.config = {
            title: this._translateService.instant('LV_CURRENTUSER.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_CURRENTUSER.SEARCH_LABEL'
            ),
            icon: 'person',
            add_icon: 'add',
            openEditInDialog: false,
            onRowClick: (item) => {
                if (item.FileRef.indexOf('Lists/Auftragsaufgaben') > -1) {
                    this.openDialog(item, false);
                } else {
                    // Aufträge
                    this.router.navigate(
                        ['projects/detail/basedata/' + item.ID],
                        {
                            queryParamsHandling: 'merge',
                        }
                    );
                }
            },
            tableConfigs: [
                {
                    title: this._translateService.instant(
                        'LV_CURRENTUSER.MYPROJECTS'
                    ),
                    config: {
                        list: 'Aufträge',
                        groups: [],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_PROJECT.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        useMaterialDesign: true,
                        camlQuery: this.myAssignmentsCamlQuery,
                    },
                    camlQueryForSearch: this.myAssignmentsSearchCamlQuery,
                },
                {
                    title: this._translateService.instant(
                        'LV_CURRENTUSER.MYWPS'
                    ),
                    config: {
                        list: 'Auftragsaufgaben',
                        groups: [{ field: 'Auftrag' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.TITLE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'DueDate',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.DUEDATE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftrag',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.PROJECT'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Prozessschritt',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.PROCESSSTEP'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Arbeitspaket',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.WORKPACKAGE'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: false,
                                type: ColumnTyp.Lookup
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title2',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: true,
                                type: ColumnTyp.Lookup
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title3',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: true,
                                type: ColumnTyp.Lookup
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        recursiveAll: true,
                        useMaterialDesign: true,
                        camlQuery: this.myactiveWPCamlQuery,
                        configurationSubtable: this.subTableConfig,
                    },
                    camlQueryForSearch: this.myactiveWPSearchCamlQuery,
                    afterDeleteItem: (item) => {
                        this._taskService.afterDeleteItem(item);
                    },
                },
                {
                    title: this._translateService.instant(
                        'LV_CURRENTUSER.MYTASKS'
                    ),
                    config: {
                        list: 'Auftragsaufgaben',
                        groups: [{ field: 'Auftrag' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.TITLE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'DueDate',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.DUEDATE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftrag',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.PROJECT'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Prozessschritt',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.PROCESSSTEP'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Arbeitspaket',
                                title: this._translateService.instant(
                                    'LV_CURRENTUSER.COLUMNS_TASK.WORKPACKAGE'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: false,
                                type: ColumnTyp.Lookup
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title2',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: true,
                                type: ColumnTyp.Lookup
                            },
                            {
                                internalName: 'Auftraggeber_x003a_Title3',
                                title: this._translateService.instant('LV_CURRENTUSER.COLUMNS_TASK.ANSPRECHPARTNER'),
                                hidden: true,
                                type: ColumnTyp.Lookup
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        recursiveAll: true,
                        useMaterialDesign: true,
                        camlQuery: this.myactiveTaksCamlQuery,
                    },
                    camlQueryForSearch: this.myactiveTaksSearchCamlQuery,
                    afterDeleteItem: (item) => {
                        this._taskService.afterDeleteItem(item);
                    },
                },
            ],
        };
    }
}
