export const locale = {
    lang: 'en',
    data: {
        ADD_USER: {
            ADD: 'Add user',
            NOTIFICATIONS: {
                SAVING: 'Adding user...',
                WAIT: 'Please wait.',
                SUCCESS: 'Aser added successfully',
                ERROR: 'Error while adding: ',
            },
            FORM: {
                USER: 'User',
                SAVE: 'Add',
            },
        },
    },
};
