export class DateUtil {
    static getBusinessHoursWithHolidays(year: number, month: number, hoursPerDay: number, holidays: any[]): number {
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);
        const currentDate = new Date(startDate.getTime());

        let count = 0;
        let hours = 0;

        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                const holiday = holidays?.find(_holiday =>
                    _holiday.Date.getFullYear() === currentDate.getFullYear() &&
                    _holiday.Date.getMonth() === currentDate.getMonth() &&
                    _holiday.Date.getDate() === currentDate.getDate()
                );

                if (holiday) {
                    hours = hours + hoursPerDay - (hoursPerDay * holiday.Faktor);
                } else {
                    count++;
                    hours = hours + hoursPerDay;
                }
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return hours;
    }

    static getBusinessHours(year: number, month: number, hoursPerDay: number): number {
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);
        const currentDate = new Date(startDate.getTime());

        let count = 0;
        let hours = 0;

        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                count++;
                hours = hours + hoursPerDay;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return hours;
    }

    static getKeyDateToken(date: Date): string {
        return `${date.getFullYear()}_${date.getMonth()}`;
    }

    static getConvertToUTCDate(date: Date): Date {
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                0
            )
        );
    }
}
