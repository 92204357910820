import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1123Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const customersList: IList =
                await this._sharePointService.web.lists.getByTitle('Firmen');

            const promises = [
                this._updateService.addField(
                    customersList,
                    'Kunden_Niederlassung',
                    FieldType.Text
                ),
            ];

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((err) => console.error(err));
        });
        return promise;
    }
}
