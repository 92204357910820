import CamlBuilder from 'camljs';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1122Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const timesList: IList =
                await this._sharePointService.web.lists.getByTitle('Zeiten');

            const promises = [
                this._updateService.addField(
                    timesList,
                    'Projektnummer',
                    FieldType.Text
                ),
            ];

            Promise.all(promises)
                .then(() => {
                    this._sharePointService
                        .getListItems({
                            title: 'Zeiten',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .ToString(),
                            recursiveAll: true,
                        })
                        .then(async (times) => {
                            for (let { ProjektId, Id } of times) {
                                if (ProjektId) {
                                    await this._sharePointService
                                        .getItemById({
                                            listTitle: 'Aufträge',
                                            id: ProjektId,
                                        })
                                        .then(async (item) => {
                                            await this._sharePointService
                                                .updateItem({
                                                    listTitle: 'Zeiten',
                                                    id: Id,
                                                    newFiledValues: {
                                                        Projektnummer:
                                                            item.Projektnummer,
                                                    },
                                                })
                                                .catch((err) => {
                                                    console.error(err);
                                                });
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                        });
                                }
                            }
                            resolve();
                        });
                })
                .catch((error) => console.error(error));
        });
        return promise;
    }
}
