import { Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import { ApplicationService } from '../../services/application.service';
import { fuseAnimations, FuseTranslationLoaderService, } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { DetailNotificationComponent } from '../../notification/detail-notification/detail-notification.component';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { WindowRef } from '../../shared/WindowRef';
import { from } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { MailService } from '../../services/mail.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import '@pnp/graph/users';
import CamlBuilder from 'camljs';
import { FormioComponent } from '@formio/angular';
import { ImportEmailDialogComponent } from 'src/app/main/mail/assign-mail/import-email-dialog/import-email-dialog.component';
import { EmailFolder } from 'src/app/main/shared/models';

@Component({
    selector: 'app-assign-mail',
    templateUrl: './assign-mail.component.html',
    styleUrls: ['./assign-mail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AssignMailComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }

    public id;
    public sharePointItems = [];
    public formioConfiguration;
    public isCreateMode = true;
    public parentId;
    public addresses = [];
    public mailFoldersSelectOptions: EmailFolder[] = [];
    public mailFoldersSelectOptionsAll: EmailFolder[] = [];
    public mailFoldersSelectOptionsRoot: EmailFolder[] = [];
    public currentPath = {
        path: '',
        folders: [],
    };
    public currentFolder;
    public _keyStr =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    public tableConfig: TableConfiguration;
    public isRequest: boolean;
    public duplicateEmails: Record<string, boolean> = {};

    private columns: TableColumn[];
    private mailboxListTitle: string;

    get camlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('documentGrid', { static: false }) documentGrid: SharePointTableComponent;
    @ViewChild('formioComponent', { static: false }) formioComponent: FormioComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailNotificationComponent>,
        public _documentService: DocumentsAssignmentService,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _mailService: MailService,
        private winRef: WindowRef,
        private dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.winRef.nativeWindow.dateFields = [];
        this.winRef.nativeWindow.userFields = [];
    }

    ngOnInit() {
        const promises = [];

        if (this.data.auftragsId) {
            this.parentId = this.data.auftragsId;
            this.mailboxListTitle = 'Mailbox';
            this.isRequest = false;
        } else {
            this.parentId = this.data.requestId;
            this.mailboxListTitle = 'Mailbox-Anträge';
            this.isRequest = true;
        }

        // Load O365 adresses in background
        this._sharepointService.graph.me.people
            .top(1000)
            .select('scoredEmailAddresses')()
            .then((people) => {
                this.addresses = people
                    .map((x) => x.scoredEmailAddresses)
                    .map((y) => y[0].address);
            })
            .catch((error) => {
                console.error(error);
            });

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                this._mailService
                    .getMailfoldersFromUser()
                    .then((rfolders) => {
                        this.mailFoldersSelectOptions = [];

                        rfolders.forEach((folder) => {
                            this.mailFoldersSelectOptionsAll.push({
                                Id: folder.id,
                                Title: folder.displayName,
                                parentFolderId: folder.parentFolderId,
                                childFolderCount: folder.childFolderCount,
                                loadChildren:
                                    folder.childFolderCount > 0 ? true : false,
                            });
                            this.mailFoldersSelectOptionsRoot.push({
                                Id: folder.id,
                                Title: folder.displayName,
                                parentFolderId: folder.parentFolderId,
                                childFolderCount: folder.childFolderCount,
                                loadChildren:
                                    folder.childFolderCount > 0 ? true : false,
                            });
                            /*folder.childFolders.forEach(e => {
                            this.mailFoldersSelectOptionsAll.push({
                                Id: e.id,
                                Title: e.displayName,
                                parentFolderId: e.parentFolderId,
                                childFolderCount: e.childFolderCount,
                                loadChildren: true,
                            });
                        });*/
                        });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('ASSIGN_EMAIL.NOTIFICATIONS.SAVING'),
            this._translateService.instant('ASSIGN_EMAIL.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;
        delete e.data.Folder;

        if (!this.isRequest) {
            e.data.Title = 'project';
            if (this.parentId) {
                e.data.ProjektId = this.parentId;
            }
        } else {
            e.data.Title = 'request';
            if (this.parentId) {
                e.data.AntragId = this.parentId;
            }
        }

        e.data.ReadyToSend = false;

        return from(
            this._applicationService
                .callBeforeSaveFunctions(this.formioConfiguration, e.data)
                .then(() => {
                    const promises = [];

                    this.documentGrid.itemSelected.forEach((element) => {
                        let data: any = {
                            Title: e.data.Title,
                            ReadyToSend: e.data.ReadyToSend,
                            Subject: element.dataItem.Subject,
                            From: element.dataItem.From,
                            To: element.dataItem.To,
                            CC: element.dataItem.CC,
                            Body: element.dataItem.Body,
                        };

                        if (!this.isRequest) {
                            data.ProjektId = e.data.ProjektId;
                        } else {
                            data.AntragId = e.data.AntragId;
                        }

                        promises.push(
                            new Promise<void>((resolve, reject) => {
                                this._sharepointService
                                    .addItem({
                                        listTitle: this.mailboxListTitle,
                                        data,
                                        folder: this.parentId + '',
                                    })
                                    .then((elementId) => {
                                        // Get Mailitem

                                        let promisesA = [];

                                        element.dataItem.Attachments.forEach(
                                            (attachment) => {
                                                promisesA.push(
                                                    new Promise<void>(
                                                        (resolve, reject) => {
                                                            this._sharepointService
                                                                .addItemAttachment(
                                                                    {
                                                                        listTitle:
                                                                            this
                                                                                .mailboxListTitle,
                                                                        itemId: elementId,
                                                                        attachmentMetadata:
                                                                            {
                                                                                filename:
                                                                                    attachment.name,
                                                                                content:
                                                                                    attachment.contentBytes,
                                                                            },
                                                                    }
                                                                )
                                                                .then(() => {
                                                                    resolve();
                                                                })
                                                                .catch(
                                                                    (error) => {
                                                                        reject(
                                                                            error
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                    )
                                                );
                                            }
                                        );

                                        Promise.all(promisesA)
                                            .then(() => {
                                                this._sharepointService
                                                    .getItemById({
                                                        listTitle:
                                                            this
                                                                .mailboxListTitle,
                                                        id: elementId,
                                                    })
                                                    .then((emailItem) => {
                                                        this.sharePointItems.push(
                                                            emailItem
                                                        );
                                                        resolve();
                                                    })
                                                    .catch((error) => {
                                                        reject(error);
                                                    });
                                            })
                                            .catch((error) => {
                                                this._loadingService.hide();
                                                console.error(error);
                                            });
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                        );
                    });

                    Promise.all(promises)
                        .then(() => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'ASSIGN_EMAIL.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close({
                                state: 'success',
                                elements: this.sharePointItems,
                            });
                        })
                        .catch((error) => {
                            this._loadingService.hide();
                            console.error(error);
                        });
                })
                .catch((error) => {
                    this._loadingService.hide();
                    console.error(error);
                })
        );
    }

    async onChange(ev) {
        if (!ev.changed) {
            if (ev.folder) {
                let folderId = ev.Id;
                let folderTitle = ev.Title;
                if (this.currentPath.path != '/') {
                    this.currentPath.path += '/';
                }
                this.currentPath.path += ev.Title;
                const promises = [];

                this._loadingService.show(
                    this._translateService.instant(
                        'ASSIGN_EMAIL.NOTIFICATIONS.LOADING'
                    ),
                    this._translateService.instant(
                        'ASSIGN_EMAIL.NOTIFICATIONS.WAIT'
                    )
                );

                promises.push(
                    new Promise<void>(async (resolve, reject) => {
                        this._mailService
                            .getMailsFromMailFolderById(folderId, 100)
                            .then(async (mails) => {
                                this.tableConfig.data = [];
                                let i = 0;
                                mails.forEach((element) => {
                                    i++;
                                    let toStr = '';
                                    let ccStr = '';
                                    element.toRecipients.forEach(
                                        (to_element) => {
                                            if (toStr != '') {
                                                toStr += '; ';
                                            }
                                            toStr +=
                                                to_element.emailAddress.address;
                                        }
                                    );
                                    element.ccRecipients.forEach(
                                        (cc_element) => {
                                            if (ccStr != '') {
                                                ccStr += '; ';
                                            }
                                            ccStr +=
                                                cc_element.emailAddress.address;
                                        }
                                    );
                                    let fromStr =
                                        element.from.emailAddress.address;

                                    this.tableConfig.data.push({
                                        ID: i,
                                        Subject: element.subject,
                                        To: toStr,
                                        CC: ccStr,
                                        From: fromStr,
                                        Body: element.body.content,
                                        Attachments: element.attachments,
                                        SentDateTime: new Date(element.sentDateTime),
                                        MessageId: element.id
                                    });
                                });
                                this.duplicateEmails = await this._mailService.findDuplicates(this.mailboxListTitle, this.parentId, this.tableConfig.data);
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );

                let data = {
                    ArbeitspaketId: folderTitle,
                    path: this.currentPath.path,
                    submit: false,
                };

                Promise.all(promises)
                    .then(() => {
                        this._loadingService.hide();
                        this.documentGrid.refresh();

                        this.refreshForm.emit({
                            submission: {
                                data,
                            },
                        });
                    })
                    .catch((error) => {
                        this._loadingService.hide();
                        console.log(error);
                    });
            } else if (ev.dirUp) {
                let data = {
                    ArbeitspaketId: null,
                    path: '/',
                };
                if (this.currentPath.folders.length > 1) {
                    this.currentPath.folders.pop();
                    this.currentFolder =
                        this.currentPath.folders[
                            this.currentPath.folders.length - 1
                        ];
                    let tmp = this.currentPath.path.split('/');
                    this.currentPath.path = '';
                    for (let i = 1; i < tmp.length - 1; i++) {
                        this.currentPath.path += '/' + tmp[i];
                    }
                    data.ArbeitspaketId = this.currentFolder.Title;
                    data.path = this.currentPath.path;
                } else {
                    this.currentFolder = null;
                    this.currentPath.folders = [];
                    this.currentPath.path = '/';
                    data.ArbeitspaketId = this.currentPath.path;
                    data.path = this.currentPath.path;
                }
                this.refreshForm.emit({
                    submission: {
                        data,
                    },
                });
            }
        }
    }

    assignMails() {
        if (!this.documentGrid.itemSelected.length) {
            return;
        }

        this.dialog.open(
            ImportEmailDialogComponent,
            {
                data: {
                    selectedMails: this.documentGrid.itemSelected.map(item => item.dataItem),
                    parentId: this.parentId,
                    mailboxListTitle: this.mailboxListTitle,
                    isRequest: this.isRequest,
                    isInComingEmail: this.data.isInComingEmail,
                    taskId: this.data.taskId
                },
                height: '95vh',
                width: '90%',
                disableClose: true
            }
        )
            .afterClosed()
            .subscribe(mails => {
                this.close({ state: 'success', elements: mails });
            });
    }

    refreshMails() {
        //CurrentFolder
        return new Promise<void>(async (resolve, reject) => {
            if (this.currentFolder) {
                this._loadingService.show(
                    this._translateService.instant(
                        'ASSIGN_EMAIL.NOTIFICATIONS.LOADING'
                    ),
                    this._translateService.instant(
                        'ASSIGN_EMAIL.NOTIFICATIONS.WAIT'
                    )
                );
                this._mailService
                    .getMailsFromMailFolderById(this.currentFolder.Id, 100)
                    .then(
                        (mails) => {
                            this.tableConfig.data = [];
                            let i = 0;
                            mails.forEach((element) => {
                                i++;
                                let toStr = '';
                                element.toRecipients.forEach((to_element) => {
                                    if (toStr != '') {
                                        toStr += '; ';
                                    }
                                    toStr += to_element.emailAddress.address;
                                });
                                let fromStr = element.from.emailAddress.address;

                                this.tableConfig.data.push({
                                    ID: i,
                                    Subject: element.subject,
                                    To: toStr,
                                    From: fromStr,
                                    Body: element.body.content,
                                    SentDateTime: new Date(element.sentDateTime)
                                });
                            });
                            this._loadingService.hide();
                            this.documentGrid.refresh();
                            resolve();
                        },
                        (error) => {
                            this._loadingService.hide();
                            reject(error);
                        }
                    );
            } else {
                resolve();
            }
        });
    }

    dirup() {
        let data = {
            ArbeitspaketId: null,
            path: '/',
        };
        if (this.currentPath.folders.length > 1) {
            this.currentPath.folders.pop();
            this.currentFolder =
                this.currentPath.folders[this.currentPath.folders.length - 1];
            let tmp = this.currentPath.path.split('/');
            this.currentPath.path = '';
            for (let i = 1; i < tmp.length - 1; i++) {
                this.currentPath.path += '/' + tmp[i];
            }
            data.ArbeitspaketId = this.currentFolder.Title;
            data.path = this.currentPath.path;
        } else {
            this.currentFolder = null;
            this.currentPath.folders = [];
            this.currentPath.path = '/';
            data.ArbeitspaketId = this.currentPath.path;
            data.path = this.currentPath.path;
        }
        this.refreshForm.emit({
            submission: {
                data,
            },
        });
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.columns = [
            {
                internalName: 'Subject',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.SUBJECT'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'From',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.FROM'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'To',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.TO'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'ALREADYCREATED',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.ALREADYCREATED'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                width: '270px'
            },
            {
                internalName: 'Body',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.TEXT'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'SentDateTime',
                title: this._translateService.instant(
                    'ASSIGN_EMAIL.COLUMNS.SENTDATETIME'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
                width: '270px'
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfig = {
            list: this.mailboxListTitle,
            columns: this.columns,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: true,
            useMaterialDesign: true,
            checkboxColumnShowSelectAll: true,
            data: [],
            afterRefreshTable: () => {
                // Update sidebar
                this._applicationService.getSideBarConfig(this.parentId);
            },
        };

        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant('ASSIGN_EMAIL.PATH'),
                    type: 'textfield',
                    key: 'path',
                    inputFormat: 'plain',
                    disabled: true,
                    defaultValue: '/',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'ASSIGN_EMAIL.FOLDER'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'ArbeitspaketId',
                                    isPeoplePicker: false,
                                    removable: true,
                                    filteredOptionsFunction: (value) => {
                                        return this.getFolderSelectValues(
                                            value
                                        );
                                    },
                                    onSelected: (control, data) => {
                                        this.currentFolder = data;
                                        this.currentPath.folders.push(
                                            this.currentFolder
                                        );

                                        data.folder = true;
                                        this.onChange(data);
                                    },
                                    onRemoved: (control, data) => {
                                        this.currentFolder = null;
                                        this.currentPath.folders = [];
                                        this.currentPath.path = '/';

                                        let fdata = {
                                            path: this.currentPath.path,
                                        };
                                        this.refreshForm.emit({
                                            submission: {
                                                fdata,
                                            },
                                        });
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                        {
                            components: [],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: false,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns1',
                },
                {
                    label: this._translateService.instant('ASSIGN_EMAIL.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                    hidden: true,
                },
            ],
        };
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
        }
    }

    getFolderSelectValues(value): Promise<EmailFolder[]> {
        return new Promise<EmailFolder[]>(async (resolve, reject) => {
            /*if (value) {
                let result = [];
                this.mailFoldersSelectOptions.forEach((element) => {
                    //let title = element.Title + '';
                    //title.toLowerCase()
                    if (element.Title.toLowerCase().indexOf(value.toLowerCase()) != -1) {
                        result.push(element);
                    }
                });
                resolve(result);
            } else {
                resolve(this.mailFoldersSelectOptions);
            }*/

            if (this.currentFolder) {
                if (this.currentFolder.loadChildren) {
                    this._applicationService
                        .getChildMailfoldersById(this.currentFolder.Id)
                        .then((rfolders: any[]) => {
                            //this._mailService.getchildMailfolderById(this.currentFolder.Id).then((rfolders) => {
                            this.mailFoldersSelectOptions = [];

                            rfolders.forEach((folder) => {
                                this.mailFoldersSelectOptionsAll.push({
                                    Id: folder.id,
                                    Title: folder.displayName,
                                    parentFolderId: folder.parentFolderId,
                                    childFolderCount: folder.childFolderCount,
                                    loadChildren:
                                        folder.childFolderCount > 0
                                            ? true
                                            : false,
                                });
                                this.currentFolder.loadChildren = false;

                                let result = [];
                                this.mailFoldersSelectOptionsAll.forEach(
                                    (element) => {
                                        if (
                                            element.parentFolderId ==
                                            this.currentFolder.Id
                                        ) {
                                            result.push(element);
                                        }
                                    }
                                );

                                resolve(this.filterEmailFolders(result, value));
                            });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    let result = [];
                    this.mailFoldersSelectOptionsAll.forEach((element) => {
                        if (element.parentFolderId == this.currentFolder.Id) {
                            result.push(element);
                        }
                    });

                    resolve(this.filterEmailFolders(result, value));
                }
            } else {
                resolve(this.filterEmailFolders(this.mailFoldersSelectOptionsRoot, value));
            }
        });
    }

    private filterEmailFolders(folders: EmailFolder[], filter: string): EmailFolder[] {
        if (!filter) {
            return folders;
        }

        return folders.filter(folder => folder.Title.toLowerCase().includes(filter.toLowerCase()));
    }
}
