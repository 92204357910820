import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1137Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Add List Länder
            await this._updateService.addList('Länder', '', 100, {
                EnableVersioning: true,
            });
            const landerList =
                this._sharePointService.web.lists.getByTitle('Länder');
            const customerList =
                this._sharePointService.web.lists.getByTitle('Firmen');
            await this._sharePointService.addItem({
                listTitle: 'Länder',
                folder: '',
                data: { Title: 'Deutschland' },
            });
            await this._updateService.addField(
                customerList,
                'Email',
                FieldType.Text
            );
            await this._updateService.addLookupField(
                customerList,
                landerList,
                'Land'
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
