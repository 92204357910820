import { OrdReqDocsService } from './ord-req-docs.service';
import { RequestService } from './../services/request.service';
import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { OrderService } from '../services/order.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { SPDocument } from 'src/app/main/shared/models';
import { DocumentsService } from 'src/app/main/services/documents.service';
import { ChangeDocumentStatus } from '../document/change-document-name/enum';
import { DocTemplatesService } from '../services/doc-templates.service';

@Component({
    selector: 'app-ord-req-docs',
    templateUrl: './ord-req-docs.component.html',
    styleUrls: ['./ord-req-docs.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class OrdReqDocsComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public tableConfig: TableConfiguration;
    public tableConfigTaskDocs: TableConfiguration;
    public header;
    public taskDocumentTabHeader;
    public uploadMultipleDocuments = true;
    public isOrder: boolean;
    public tabTitle: string = 'DOCUMENTASSIGNMENT.TABS.REQUEST';
    public breadcrumbTitle: string = 'ORDERREQUESTDOCS.REQUESTS';
    public ChangeDocumentStatus = ChangeDocumentStatus;

    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    private columns: TableColumn[];
    private item;
    private rootFolderName = 'Dokumente';

    get camlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('AntragId')
            .EqualTo(this.id)
            .Or()
            .NumberField('FSObjType')
            .EqualTo(1)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        private route: ActivatedRoute,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _documentsService: DocumentsService,
        public orderService: OrderService,
        public requestService: RequestService,
        public ordReqService: OrdReqDocsService,
        private _docTemplateService: DocTemplatesService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.taskDocumentTabHeader = this._translateService.instant(
            'DOCUMENTASSIGNMENT.TABS.TASKS'
        );
    }

    get docsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('AntragId')
            .EqualTo(this.id)
            .ToString();
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            this.orderService.getSideBarConfig(this.id);

            if (this.id != null) {
                this._sharePointService
                    .getItemById({ listTitle: 'Anträge', id: this.id })
                    .then((order) => {
                        const promises = [];

                        this.item = order;
                        this.isOrder = order.Ist_Auftrag;

                        if (this.isOrder) {
                            this.tabTitle = 'DOCUMENTASSIGNMENT.TABS.ORDER';
                            this.breadcrumbTitle = 'ORDERREQUESTDOCS.ORDERS';
                        }
                        this.itemTitle = this.item.Title;

                        Promise.all(promises)
                            .then(() => {
                                this.initGrid();
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onTabChange(e) {
        //
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.ordReqService
                    .checkMainFolder(this.id)
                    .then(() => {
                        this.fileInput.nativeElement.click();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            case 'AddFolder':
                this.ordReqService.addNewFolder(
                    this.documentGrid,
                    this.tableConfig.list,
                    this.id
                );
                break;
            case 'CreateDocument':
                let itemOptions: {
                    itemId: number;
                    listName: string;
                    lookupFieldName?: string;
                }[] = [{ itemId: this.id, listName: 'Anträge' }];

                if (this.item) {
                    let lookUpFieldsAnfrage = [
                        {
                            internalName: 'AuftraggeberId',
                            listName: 'Firmen',
                        },
                        {
                            internalName: 'ProjektsteuererId',
                            listName: 'Firmen',
                        },
                        {
                            internalName: 'KostenstelleId',
                            listName: 'Kostenstellen',
                        },
                        {
                            internalName: 'TeamId',
                            listName: 'Teams',
                        },
                        {
                            internalName: 'FachbereichId',
                            listName: 'Fachbereiche',
                        },
                        {
                            internalName: 'StandortId',
                            listName: 'Standorte',
                        },
                    ];

                    // LookupItem mitgeben wenn befüllt
                    lookUpFieldsAnfrage.forEach((element) => {
                        if (this.item[element.internalName]) {
                            itemOptions.push({
                                itemId: this.item[element.internalName],
                                listName: element.listName,
                                lookupFieldName: element.internalName,
                            });
                        }
                    });
                }

                this._docTemplateService.openDocTemplateDialog(
                    itemOptions,
                    {
                        docListName: 'Antragsdokumente',
                        docLFolderName: this.id + '',
                    },
                    { AntragId: this.id },
                    this.documentGrid,
                    'Anfrage'
                );
                break;
        }
    }

    initGrid() {
        if (!this.item) {
            return;
        }

        this.columns = [
            {
                internalName: 'FileRef',
                title: '',
                hidden: false,
                type: ColumnTyp.DocIcon,
                width: '80',
            },
            {
                internalName: 'FileRef',
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.OpenFileForEditInClient,
            },
            {
                internalName: 'File_x0020_Size',
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.COLUMNS.FILESIZE'
                ),
                hidden: false,
                type: ColumnTyp.FileSize,
            },
            {
                internalName: 'Author',
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.COLUMNS.AUTHOR'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Modified',
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.COLUMNS.MODIFIED'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMddHH,
            },
            {
                internalName: 'Standardberechtigung',
                title: '',
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'CopyColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            {
                internalName: 'RenameColumn',
                title: '',
                hidden: false,
                width: '120',
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        let toolbar = [
            {
                title: this._translateService.instant('ORDERREQUESTDOCS.NEW'),
                command: 'Add',
                color: 'accent',
            },
            {
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.NEWFOLDER'
                ),
                command: 'AddFolder',
                color: 'accent',
            },
            {
                title: this._translateService.instant(
                    'ORDERREQUESTDOCS.CREATE_DOCUMENT'
                ),
                command: 'CreateDocument',
                color: 'accent',
            },
        ];

        this.tableConfig = {
            list: 'Antragsdokumente',
            columns: this.columns,
            isDocumentLibrary: true,
            showEditButton: false,
            showDeleteButton: true,
            recursiveAll: false,
            camlQuery: this.camlQuery,
            folderName: this.id + '',
            rootFolderName: this.rootFolderName,
            useMaterialDesign: true,
            toolbar,
            afterRefreshTable: () => {
                if (this.isOrder) {
                    this.orderService.getSideBarConfig(this.id);
                } else {
                    this.requestService.getSideBarConfig(this.id);
                }
            },
        };
    }

    public openRenameDocumentDialog(
        document: SPDocument,
        mode: ChangeDocumentStatus
    ): void {
        this._documentsService
            .openRenameDocumentDialog(this.id, document, mode)
            .subscribe(() => {
                this.documentGrid.refresh();
            });
    }
}
