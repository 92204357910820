import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import {
    SharePointTableComponent,
    SharePointService,
} from 'sp-office365-framework';
import {
    fuseAnimations,
    SnackbarService,
    FuseTranslationLoaderService,
    FrameworkService,
} from 'sp-office365-layout';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-detail-group',
    templateUrl: './detail-group.component.html',
    styleUrls: ['./detail-group.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailGroupComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public id;
    public groupItem;
    public formioConfiguration;
    public isCreateMode = true;
    public firstTimeLoad = true;
    public addHint: string;
    public viewMembersHint: string;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    @ViewChild('userGrid', { static: false })
    userGrid: SharePointTableComponent;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailGroupComponent>,
        private _sharepointService: SharePointService,
        private _frameworkService: FrameworkService,
        private _loadingService: LoadingService,
        private _snackbarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data.groupItem) {
            this.isCreateMode = false;
            this.groupItem = this.data.groupItem;
            this.id = this.data.groupItem.Id;

            this.addHint = this._translateService.instant(
                'DETAIL_GROUP.NOTIFICATIONS.ADDHINTTRUE'
            );

            if (!this.groupItem.AllowMembersEditMembership) {
                this.addHint = this._translateService.instant(
                    'DETAIL_GROUP.NOTIFICATIONS.ADDHINTFALSE'
                );
            }

            if (this.groupItem.OnlyAllowMembersViewMembership) {
                this.viewMembersHint = this._translateService.instant(
                    'DETAIL_GROUP.NOTIFICATIONS.VIEWMEMBERSHINTTRUE'
                );
            }

            this.initForm();
        } else {
            this.initForm();
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._frameworkService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.groupItem) {
            dataForm = this._frameworkService.leftMerge(
                emptyObj,
                this.groupItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._frameworkService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('DETAIL_GROUP.NOTIFICATIONS.SAVING'),
            this._translateService.instant('DETAIL_GROUP.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._frameworkService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._frameworkService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (e.data.Description == null) {
            e.data.Description = '';
        }

        return from(
            new Promise<void>((resolve, reject) => {
                const promises = [];
                let newGroup = false;

                if (!this.groupItem) {
                    newGroup = true;

                    promises.push(
                        this._sharepointService.web.siteGroups.add(e.data)
                    );
                } else {
                    promises.push(
                        this._sharepointService.web.siteGroups
                            .getById(this.groupItem.Id)
                            .update(e.data)
                    );
                }

                Promise.all(promises)
                    .then((result) => {
                        this._loadingService.hide();

                        if (result.length > 0) {
                            if (!this.groupItem) {
                                // Set groupItem
                                this.groupItem = result[0].data;
                            } else {
                                // Update groupItem (title/description)
                                this.groupItem.Title = e.data.Title;
                                this.groupItem.Description = e.data.Description;
                            }
                        }

                        this._snackbarService.add(
                            this._translateService.instant(
                                'DETAIL_GROUP.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close({
                            Success: true,
                            GroupItem: this.groupItem,
                            NewGroup: newGroup,
                        });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
            }
            // else if (ev.changed.component.key === 'Art') {
            // }
        }
    }

    onTabChange(e) {
        if (this.userGrid) {
            this.userGrid.refresh();
        }
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_GROUP.FORM.TITLE'
                    ),
                    type: 'textfield',
                    key: 'Title',
                    inputFormat: 'plain',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_GROUP.FORM.DESCRIPTION'
                    ),
                    type: 'textarea',
                    input: true,
                    key: 'Description',
                    inputFormat: 'plain',
                    rows: 8,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_GROUP.FORM.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
