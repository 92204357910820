import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailTasktemplateComponent } from './detail-tasktemplate.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [DetailTasktemplateComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        TableTaskModule,
        DragDropModule,
    ],
    exports: [DetailTasktemplateComponent],
})
export class DetailTasktemplateModule {}
