import { SharePointService, UpdateService } from 'sp-office365-framework';
import { FieldUserSelectionMode, IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1007Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const assignmentList: IList = this.sharePointService.web.lists.getByTitle(
            'Aufträge'
        );

        const promise = new Promise<void>(async (resolve, reject) => {
            const promises = [
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Budget (in Tagen)',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addNumber('Budget (in Tagen)')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Budget (in Stunden)',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addNumber('Budget (in Stunden)')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // await this._updateService.runFunctionIfFieldNotExists(
                //     assignmentList,
                //     'Kennzeichen',
                //     () => {
                //         const promise = new Promise<void>((resolve, reject) => {
                //             assignmentList.fields
                //                 .addChoice('Kennzeichen', [
                //                     'Kritisch',
                //                     'Unkritisch',
                //                 ])
                //                 .then(
                //                     () => resolve(),
                //                     (error) => {
                //                         reject(error);
                //                     }
                //                 );
                //         });
                //         return promise;
                //     }
                // ),
                // await this._updateService.runFunctionIfFieldNotExists(
                //     assignmentList,
                //     'PPL',
                //     () => {
                //         const promise = new Promise<void>((resolve, reject) => {
                //             assignmentList.fields
                //                 .addUser(
                //                     'PPL',
                //                     FieldUserSelectionMode.PeopleOnly
                //                 )
                //                 .then(
                //                     () => resolve(),
                //                     (error) => {
                //                         reject(error);
                //                     }
                //                 );
                //         });
                //         return promise;
                //     }
                // ),
                // await this._updateService.runFunctionIfFieldNotExists(
                //     assignmentList,
                //     'Ansprechpartner',
                //     () => {
                //         const promise = new Promise<void>((resolve, reject) => {
                //             assignmentList.fields
                //                 .addUser(
                //                     'Ansprechpartner',
                //                     FieldUserSelectionMode.PeopleOnly
                //                 )
                //                 .then(
                //                     () => resolve(),
                //                     (error) => {
                //                         reject(error);
                //                     }
                //                 );
                //         });
                //         return promise;
                //     }
                // ),
                // await this._updateService.runFunctionIfFieldNotExists(
                //     assignmentList,
                //     'Antragssteller',
                //     () => {
                //         const promise = new Promise<void>((resolve, reject) => {
                //             assignmentList.fields
                //                 .addUser(
                //                     'Antragssteller',
                //                     FieldUserSelectionMode.PeopleOnly
                //                 )
                //                 .then(
                //                     () => resolve(),
                //                     (error) => {
                //                         reject(error);
                //                     }
                //                 );
                //         });
                //         return promise;
                //     }
                // ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });

        return promise;
    }
}
