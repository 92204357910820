export const locale = {
    lang: 'en',
    data: {
        DETAIL_GROUP: {
            EDIT: 'Edit group',
            NEW: 'New group',
            FORM: {
                TITLE: 'Group',
                DESCRIPTION: 'Description',
                SAVE: 'Save',
            },
            TABS: {
                BASE: 'Info',
                USER: 'User',
            },
            NOTIFICATIONS: {
                SAVING: 'Saving group...',
                WAIT: 'Please wait.',
                SUCCESS: 'Group saved successfully',
                ERROR: 'Error while saving: ',
                ADDHINTTRUE: 'Note: Only members of this group can add users.',
                ADDHINTFALSE: 'Note: Only owners of this group can add users.',
                VIEWMEMBERSHINTTRUE:
                    'Note: Only members of this group can see the members.',
            },
        },
    },
};
