import { RequestService } from './../../services/request.service';
import { Injectable } from '@angular/core';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { FrameworkService } from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { TaskService } from '../../services/task.service';
import { UpdateToVersion1007Component } from './updates/updatetoversion1007.component';
import { UpdateToVersion1008Component } from './updates/updatetoversion1008.component';
import { UpdateToVersion1009Component } from './updates/updatetoversion1009.component';
import { UpdateToVersion1010Component } from './updates/updatetoversion1010.component';
import { UpdateToVersion1011Component } from './updates/updatetoversion1011.component';
import { UpdateToVersion1012Component } from './updates/updatetoversion1012.component';
import { UpdateToVersion1027Component } from './updates/updatetoversion1027.component';
import { UpdateToVersion1030Component } from './updates/updatetoversion1030.component';
import { UpdateToVersion1040Component } from './updates/updatetoversion1040.component';
import { UpdateToVersion1041Component } from './updates/updatetoversion1041.component';
import { UpdateToVersion1042Component } from './updates/updatetoversion1042.component';
import { UpdateToVersion1054Component } from './updates/updatetoversion1054.component';
import { UpdateToVersion1055Component } from './updates/updatetoversion1055.component';
import { UpdateToVersion1006Component } from './updates/updatetoversion1006.component';
import { UpdateToVersion1057Component } from './updates/updatetoversion1057.component';
import { UpdateToVersion1056Component } from './updates/updatetoversion1056.component';
import { UpdateToVersion1060Component } from './updates/updatetoversion1060.component';
import { UpdateToVersion1061Component } from './updates/updatetoversion1061.component';
import { UpdateToVersion1062Component } from './updates/updatetoversion1062.component';
import { UpdateToVersion1063Component } from './updates/updatetoversion1063.component';
import { UpdateToVersion1064Component } from './updates/updatetoversion1064.component';
import { UpdateToVersion1065Component } from './updates/updatetoversion1065.component';
import { UpdateToVersion1066Component } from './updates/updatetoversion1066.component';
import { UpdateToVersion1067Component } from './updates/updatetoversion1067.component';
import { UpdateToVersion1068Component } from './updates/updatetoversion1068.component';
import { UpdateToVersion1069Component } from './updates/updatetoversion1069.component';
import { UpdateToVersion1071Component } from './updates/updatetoversion1071.component';
import { UpdateToVersion1072Component } from './updates/updatetoversion1072.component';
import { UpdateToVersion1073Component } from './updates/updatetoversion1073.component';
import { UpdateToVersion1074Component } from './updates/updatetoversion1074.component';
import { UpdateToVersion1075Component } from './updates/updatetoversion1075.component';
import { UpdateToVersion1101Component } from './updates/updatetoversion1101.component';
import { UpdateToVersion1103Component } from './updates/updatetoversion1103.component';
import { UpdateToVersion1104Component } from './updates/updatetoversion1104.component';
import { UpdateToVersion1105Component } from './updates/updatetoversion1105.component';
import { UpdateToVersion1106Component } from './updates/updatetoversion1106.component';
import { UpdateToVersion1107Component } from './updates/updatetoversion1107.component';
import { UpdateToVersion1110Component } from './updates/updatetoversion1110.component';
import { UpdateToVersion1108Component } from './updates/updatetoversion1108.component';
import { UpdateToVersion1111Component } from './updates/updatetoversion1111.component';
import { UpdateToVersion1112Component } from './updates/updatetoversion1112.component';
import { UpdateToVersion1113Component } from './updates/updatetoversion1113.component';
import { UpdateToVersion1114Component } from './updates/updatetoversion1114.component';
import { UpdateToVersion1115Component } from './updates/updatetoversion1115.component';
import { UpdateToVersion1116Component } from './updates/updatetoversion1116.component';
import { UpdateToVersion1117Component } from './updates/updatetoversion1117.component';
import { UpdateToVersion1118Component } from './updates/updatetoversion1118.component';
import { UpdateToVersion1119Component } from './updates/updatetoversion1119.component';
import { UpdateToVersion1120Component } from './updates/updatetoversion1120.component';
import { UpdateToVersion1121Component } from './updates/updatetoversion1121.component';
import { UpdateToVersion1122Component } from './updates/updatetoversion1122.component';
import { UpdateToVersion1123Component } from './updates/updatetoversion1123.component';
import { UpdateToVersion1124Component } from './updates/updatetoversion1124.component';
import { UpdateToVersion1125Component } from './updates/updatetoversion1125.component';
import { UpdateToVersion1126Component } from './updates/updatetoversion1126.component';
import { UpdateToVersion1127Component } from './updates/updatetoversion1127.component';
import { UpdateToVersion1128Component } from './updates/updatetoversion1128.component';
import { UpdateToVersion1129Component } from './updates/updatetoversion1129.component';
import { UpdateToVersion1130Component } from './updates/updatetoversion1130.component';
import { UpdateToVersion1131Component } from './updates/updatetoversion1131.component';
import { Updatetoversion1132Component } from './updates/updatetoversion1132.component';
import { UpdateToVersion1133Component } from './updates/updatetoversion1133.component';
import { UpdateToVersion1134Component } from './updates/updatetoversion1134.component';
import { UpdateToVersion1135Component } from './updates/updatetoversion1135.component';
import { UpdateToVersion1137Component } from './updates/updatetoversion1137.component';
import { UpdateToVersion1138Component } from './updates/updatetoversion1138.component';
import { UpdateToVersion1139Component } from './updates/updatetoversion1139.component';
import { UpdateToVersion1140Component } from './updates/updatetoversion1140.component';
import { UpdateToVersion1141Component } from './updates/updatetoversion1141.component';
import { UpdateToVersion1142Component } from './updates/updatetoversion1142.component';
import { UpdateToVersion1143Component } from './updates/updatetoversion1143.component';
import { UpdateToVersion1144Component } from './updates/updatetoversion1144.component';
import { UpdateToVersion1145Component } from './updates/updatetoversion1145.component';
import { UpdateToVersion1146Component } from './updates/updatetoversion1146.component';
import { UpdateToVersion1147Component } from './updates/updatetoversion1147.component';
import { UpdateToVersion1148Component } from './updates/updatetoversion1148.component';
import { UpdateToVersion1149Component } from './updates/updatetoversion1149.component';
import { UpdateToVersion1150Component } from './updates/updatetoversion1150.component';
import { UpdateToVersion1151Component } from './updates/updatetoversion1151.component';
import { UpdateToVersion1152Component } from './updates/updatetoversion1152.component';
import { UpdateToVersion1153Component } from './updates/updatetoversion1153.component';
import { UpdateToVersion1154Component } from './updates/updatetoversion1154.component';

@Injectable({
    providedIn: 'root',
})
export class UpdateManagerService {
    public latestVersion = 1154;

    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService,
        private _taskService: TaskService,
        private _requestService: RequestService,
        private _frameworkService: FrameworkService
    ) {}

    updateToVersion(version: number) {
        const promises = [];
        let updated = true;

        const promise = new Promise<void>(async (resolve, reject) => {
            switch (version) {
                case 1006:
                    promises.push(
                        await new UpdateToVersion1006Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1007:
                    promises.push(
                        await new UpdateToVersion1007Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1008:
                    promises.push(
                        await new UpdateToVersion1008Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1009:
                    promises.push(
                        await new UpdateToVersion1009Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1010:
                    promises.push(
                        await new UpdateToVersion1010Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1011:
                    promises.push(
                        await new UpdateToVersion1011Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1012:
                    promises.push(
                        await new UpdateToVersion1012Component(
                            this._updateService
                        ).run()
                    );
                    break;
                case 1027:
                    promises.push(
                        await new UpdateToVersion1027Component(
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1030:
                    promises.push(
                        await new UpdateToVersion1030Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1040:
                    promises.push(
                        await new UpdateToVersion1040Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1041:
                    promises.push(
                        await new UpdateToVersion1041Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1042:
                    promises.push(
                        await new UpdateToVersion1042Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1054:
                    promises.push(
                        await new UpdateToVersion1054Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1055:
                    promises.push(
                        await new UpdateToVersion1055Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1056:
                    promises.push(
                        await new UpdateToVersion1056Component(
                            this._sharePointService,
                            this._updateService,
                            this._applicationService
                        ).run()
                    );
                    break;
                case 1057:
                    promises.push(
                        await new UpdateToVersion1057Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1060:
                    promises.push(
                        await new UpdateToVersion1060Component(
                            this._sharePointService,
                            this._updateService,
                            this._applicationService,
                            this._taskService
                        ).run()
                    );
                    break;
                case 1061:
                    promises.push(
                        await new UpdateToVersion1061Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1062:
                    promises.push(
                        await new UpdateToVersion1062Component(
                            this._sharePointService,
                            this._updateService,
                            this._applicationService
                        ).run()
                    );
                    break;
                case 1063:
                    promises.push(
                        await new UpdateToVersion1063Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1064:
                    promises.push(
                        await new UpdateToVersion1064Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1065:
                    promises.push(
                        await new UpdateToVersion1065Component(
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1066:
                    promises.push(
                        await new UpdateToVersion1066Component(
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1067:
                    promises.push(
                        await new UpdateToVersion1067Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1068:
                    promises.push(
                        await new UpdateToVersion1068Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1069:
                    promises.push(
                        await new UpdateToVersion1069Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1071:
                    promises.push(
                        await new UpdateToVersion1071Component(
                            this._sharePointService,
                            this._updateService,
                            this._applicationService
                        ).run()
                    );
                    break;
                case 1072:
                    promises.push(
                        await new UpdateToVersion1072Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1073:
                    promises.push(
                        await new UpdateToVersion1073Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1074:
                    promises.push(
                        await new UpdateToVersion1074Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1075:
                    promises.push(
                        await new UpdateToVersion1075Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1101:
                    promises.push(
                        await new UpdateToVersion1101Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1103:
                    promises.push(
                        await new UpdateToVersion1103Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1104:
                    promises.push(
                        await new UpdateToVersion1104Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1105:
                    promises.push(
                        await new UpdateToVersion1105Component(
                            this._updateService,
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1106:
                    promises.push(
                        await new UpdateToVersion1106Component(
                            this._sharePointService,
                            this._updateService,
                            this._applicationService
                        ).run()
                    );
                    break;
                case 1107:
                    promises.push(
                        await new UpdateToVersion1107Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1108:
                    promises.push(
                        await new UpdateToVersion1108Component(
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1110:
                    promises.push(
                        await new UpdateToVersion1110Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1111:
                    promises.push(
                        await new UpdateToVersion1111Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1112:
                    promises.push(
                        await new UpdateToVersion1112Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1113:
                    promises.push(
                        await new UpdateToVersion1113Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1114:
                    promises.push(
                        await new UpdateToVersion1114Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1115:
                    promises.push(
                        await new UpdateToVersion1115Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1116:
                    promises.push(
                        await new UpdateToVersion1116Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1117:
                    promises.push(
                        await new UpdateToVersion1117Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1118:
                    promises.push(
                        await new UpdateToVersion1118Component(
                            this._updateService,
                            this._sharePointService
                        ).run()
                    );
                    break;
                case 1119:
                    promises.push(
                        await new UpdateToVersion1119Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1120:
                    promises.push(
                        await new UpdateToVersion1120Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1121:
                    promises.push(
                        await new UpdateToVersion1121Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1122:
                    promises.push(
                        await new UpdateToVersion1122Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1123:
                    promises.push(
                        await new UpdateToVersion1123Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1124:
                    promises.push(
                        await new UpdateToVersion1124Component(
                            this._sharePointService,
                            this._updateService,
                            this._requestService
                        ).run()
                    );
                    break;
                case 1125:
                    promises.push(
                        await new UpdateToVersion1125Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1126:
                    promises.push(
                        await new UpdateToVersion1126Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1127:
                    promises.push(
                        await new UpdateToVersion1127Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1128:
                    promises.push(
                        await new UpdateToVersion1128Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1129:
                    promises.push(
                        await new UpdateToVersion1129Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1130:
                    promises.push(
                        await new UpdateToVersion1130Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1131:
                    promises.push(
                        await new UpdateToVersion1131Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1132:
                    promises.push(
                        await new Updatetoversion1132Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1133:
                    promises.push(
                        await new UpdateToVersion1133Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1134:
                    promises.push(
                        await new UpdateToVersion1134Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1135:
                    promises.push(
                        await new UpdateToVersion1135Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1137:
                    promises.push(
                        await new UpdateToVersion1137Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1138:
                    promises.push(
                        await new UpdateToVersion1138Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1139:
                    promises.push(
                        await new UpdateToVersion1139Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1140:
                    promises.push(
                        await new UpdateToVersion1140Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1141:
                    promises.push(
                        await new UpdateToVersion1141Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1142:
                    promises.push(
                        await new UpdateToVersion1142Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1143:
                    promises.push(
                        await new UpdateToVersion1143Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1144:
                    promises.push(
                        await new UpdateToVersion1144Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1145:
                    promises.push(
                        await new UpdateToVersion1145Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1146:
                    promises.push(
                        await new UpdateToVersion1146Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1147:
                    promises.push(
                        await new UpdateToVersion1147Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1148:
                    promises.push(
                        await new UpdateToVersion1148Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1149:
                    promises.push(
                        await new UpdateToVersion1149Component(
                            this._sharePointService,
                            this._updateService,
                            this._frameworkService
                        ).run()
                    );
                    break;
                case 1150:
                    promises.push(
                        await new UpdateToVersion1150Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1151:
                    promises.push(
                        await new UpdateToVersion1151Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1152:
                    promises.push(
                        await new UpdateToVersion1152Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1153:
                    promises.push(
                        await new UpdateToVersion1153Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                case 1154:
                    promises.push(
                        await new UpdateToVersion1154Component(
                            this._sharePointService,
                            this._updateService
                        ).run()
                    );
                    break;
                default:
                    if (version < this.latestVersion) {
                        promises.push(await this.updateToVersion(version + 1));
                        updated = false;
                    } else {
                        updated = true;
                    }
                    break;
            }

            if (updated) {
                promises.push(
                    await this._updateService.updateComplete(version)
                );
            }

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
        return promise;
    }
}
