import { SnackbarService } from './../../services/snackbar.service';
import { LoadingService } from './../../services/loading.service';
import { NewTimeComponent } from './../../time/new-time/new-time.component';
import { TimeService } from './../../services/time.service';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ColumnTyp,
    SharePointService,
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { Timelevel } from '../../models/timelevel.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../administration/configuration/confirm-dialog/confirm-dialog.component';
import CamlBuilder from 'camljs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { HolidaysService } from 'src/app/main/services/holidays.service';
import { DateUtil } from 'src/app/main/shared/utils';
import { TeamsService } from 'src/app/main/services/teams.service';
import { toFixed } from 'src/app/main/shared/shared.util';
import { LISTVIEW_TIME_MONTH_FILTER } from '../constants/listview-time.constant';
import { CurrentUserService } from '../../services/current-user.service';
import '@pnp/sp/batching';

@Component({
    selector: 'app-listview-time',
    templateUrl: './listview-time.component.html',
    styleUrls: ['./listview-time.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ListviewTimeComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de'
    };

    public isManager = false;
    public isTeamManagerForTeams: any[] = [];
    public timeConfig;
    public editTask;
    private destroy$ = new Subject();
    private deleteMode: boolean;
    public monthAllData = [];
    public monthConfig: TableConfiguration;
    public formioFilterConfiguration;
    public filterChangeEmitter = new EventEmitter<{
        year?: string;
        month?: number;
        employee?: string;
        teammanager?: number;
    }>();
    public weekends: boolean[] = [];
    private isAdminPage: boolean;

    private employeeHours: Record<string, number> = {};
    private totalHours = {};
    private teamsWithManagers: {
        TeamleiterId?: number; // Teamleiter Id
        FieldValuesAsText?: {
            Teamleiter?: string; // Teamliter Title
        };
        Title: string; // Team Title
        Id: number; // Team Id
    }[] = [];
    private employeePerLocation: Record<number, number> = {};
    @ViewChild('timeGrid', { static: false })
    timeGrid: SharePointTableComponent;
    @ViewChild('newTimeComponent', { static: false })
    newTimeComponent: NewTimeComponent;
    @ViewChild('monthGrid', { static: false })
    public monthGrid: SharePointTableComponent;

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        public timeService: TimeService,
        public dialog: MatDialog,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _holidaysService: HolidaysService,
        private _teamsService: TeamsService,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        // Reset timeservice
        this.timeService.reset();
    }

    ngOnDestroy(): void {
        this.timeService.reset();
        this.destroy$.next(undefined);
    }

    async ngOnInit() {
        this.timeService.reinit
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.timeService.restore());

        this.timeService.setMaximumUserHoursPerDayLoading(true);
        this.isAdminPage = this.route.snapshot.data.adminHours;
        this.timeService
            .getUserDailyHours()
            .subscribe((hours) => (this.employeeHours = hours));

        // Check if currentUser is Manager
        if (
            (this._sharepointService.currentUser.IsBusinessOwner ||
                this._currentUserService.isTeamProjectLeiter()) &&
            this.isAdminPage
        ) {
            this.isManager = true;
        }

        // Check if currentUser is Manager for teams
        this.isTeamManagerForTeams = await this._sharepointService.getListItems(
            {
                title: 'Teams',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .UserField('Teamleiter')
                    .EqualToCurrentUser()
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString(),
                recursiveAll: true,
            }
        );

        // reset timeservice
        this.timeService.reset();

        // Set timelevel
        this.timeService.level = Timelevel.Application;

        await this._holidaysService.loadHolidays();
        this.timeService.calculateUserDailyHoursLimit();
        // load teammanagers
        await this.loadTeamManagers();

        // Init timetable
        this.timeService.initTimeTable(false, '');

        this.timeService.tableConfig.customButtons = [
            {
                name: 'Löschen',
                icon: 'delete',
            },
        ];

        if (this.isManager) {
            await this.updateManagerGridData();
        } else {
            this.timeConfig = this.timeService.tableConfig;
            await this.setupGridTab();
        }
    }

    private async updateManagerGridData(): Promise<void> {
        this.timeConfig = this.timeService.tableConfig;
        const interval = setInterval(() => {
            if (this.timeGrid) {
                this.timeGrid.loading = true;
            }
        }, 1000);
        await this.timeService.loadTimeTableData(false, '');
        clearInterval(interval);
        if (this.timeGrid) {
            this.timeGrid.loading = false;
            this.timeGrid?.refresh();
        }
        this.monthGrid?.refresh();
        await this.setupGridTab();
    }

    private async setupGridTab() {
        // const hasRootPermissions = (this.isManager || this.isTeamManagerForTeams.length) && this.isAdminPage;

        let timeItems = [];

        // get time data
        // if (hasRootPermissions) {
        //     timeItems =  await  this.timeService.getAllTimes(null);
        // } else {
        timeItems = await this._sharepointService.getListItems({
            title: 'Zeiten',
            isDocumentLibrary: false,
            camlQuery: this.timeService.camlQueryCurrentUser,
            recursiveAll: true,
        });
        // }

        // we may need to filter elements
        if (
            !this.isManager &&
            this.isTeamManagerForTeams.length &&
            this.isAdminPage
        ) {
            timeItems = timeItems.filter((timeItem) => {
                return (
                    timeItem.AuthorId ===
                        this._sharepointService.currentUser.Id ||
                    !!this.isTeamManagerForTeams.find(
                        (itmft) => itmft.Id === timeItem._Team?.Id
                    )
                );
            });
        }

        // load related projects in order to obtain related teamids
        let uniqueProjektIds: number[] = [
            ...new Set(timeItems.map((timeItem) => timeItem.ProjektId)),
        ] as number[];
        uniqueProjektIds = uniqueProjektIds.filter((uniqueId) => uniqueId);
        const relatedProjektItems = await this._sharepointService.getListItems({
            title: 'Aufträge',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .In(uniqueProjektIds)
                .ToString(),
            recursiveAll: true,
        });
        const projectTeamMap = new Map<number, number>();
        relatedProjektItems
            .filter((element) => element.TeamId)
            .forEach((element) => {
                projectTeamMap.set(element.Id, element.TeamId);
            });
        this.totalHours = {};
        this.monthAllData = [];
        // process total work time by employee per year and month and day
        for (const [index, timeItem] of timeItems.entries()) {
            const dataIndex = this.monthAllData.findIndex(
                (x) =>
                    x.employeeId === timeItem.AuthorId &&
                    x.year === new Date(timeItem.Datum).getFullYear() &&
                    x.month === new Date(timeItem.Datum).getMonth() &&
                    x.projectId === timeItem.ProjektId
            );
            const hours = timeItem.Minuten / 60;
            if (dataIndex != -1) {
                const dayKey = 'day' + new Date(timeItem.Datum).getDate();
                const dayHours = this.monthAllData[dataIndex][dayKey];
                if (dayHours) {
                    this.monthAllData[dataIndex][dayKey] =
                        Number(dayHours) + hours;
                } else {
                    this.monthAllData[dataIndex][dayKey] = hours;
                }
            } else {
                const entry = {
                    projectId: timeItem.ProjektId,
                    projectTitle: timeItem.FieldValuesAsText.Projekt,
                    employeeId: timeItem.AuthorId,
                    employeeTitle: timeItem.FieldValuesAsText.Author,
                    year: new Date(timeItem.Datum).getFullYear(),
                    month: new Date(timeItem.Datum).getMonth(),
                    teamId: projectTeamMap.get(timeItem.ProjektId),
                };
                const dayKey = 'day' + new Date(timeItem.Datum).getDate();
                entry[dayKey] = hours;
                this.monthAllData.push(entry);
            }

            const key = `${timeItem.AuthorId}_${new Date(
                timeItem.Datum
            ).getMonth()}_${new Date(timeItem.Datum).getFullYear()}`;
            if (!this.totalHours[key]) {
                this.totalHours[key] = 0;
            }
            this.totalHours[key] = Number(this.totalHours[key]) + hours;
        }

        await this.initHolidaysPerUser();
        this.initUserTotalHours();

        // create all columns for the current month
        const today = new Date();
        const daysInCurrentMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
        ).getDate();
        const monthColumns = [];
        for (let i = 1; i <= daysInCurrentMonth; i++) {
            monthColumns.push({
                internalName: 'day' + i.toString(),
                title: i.toString(),
                hidden: false,
                type: ColumnTyp.Text,
                width: '45px',
            });
        }

        // get entries of today
        const processedTimeData = this.monthAllData.filter((entry) => {
            Object.keys(entry).forEach((key) => {
                if (key.startsWith('day')) {
                    if (!entry.total) {
                        entry.total = 0;
                    }

                    entry.total = toFixed(entry.total + Number(entry[key]), 2);
                }
            });

            return (
                entry.year === today.getFullYear() &&
                entry.month === today.getMonth()
            );
        });

        // add employee column
        monthColumns.push({
            internalName: 'employeeTitle',
            title: 'Mitarbeiter',
            hidden: true,
            type: ColumnTyp.Text,
        });

        // add employee column with hours
        monthColumns.push({
            internalName: 'employeeTitleWithHours',
            title: 'Mitarbeiter',
            hidden: true,
            type: ColumnTyp.Text,
        });

        // add project column
        monthColumns.unshift({
            internalName: 'projectTitle',
            title: 'Projekt',
            hidden: false,
            type: ColumnTyp.Text,
            width: '200px',
        });

        monthColumns.unshift({
            internalName: 'total',
            title: 'Σ',
            hidden: false,
            type: ColumnTyp.Text,
            width: '80px',
        });

        const tableAggregates = Array(31)
            .fill(1)
            .map((value, index) => ({
                field: `day${index + 1}`,
                aggregate: 'sum',
            }));
        // setup config
        this.monthConfig = {
            list: '-',
            groups: [
                {
                    field: 'employeeTitleWithHours',
                    aggregates: tableAggregates,
                },
            ],
            columns: monthColumns,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            recursiveAll: true,
            data: processedTimeData,
            useMaterialDesign: true,
        };

        // setup filter config
        this.formioFilterConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.YEAR'
                                    ),
                                    type: 'textfield',
                                    key: 'year',
                                    hidden: false,
                                    defaultValue: new Date().getFullYear(),
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.MONTH'
                                    ),
                                    type: 'select',
                                    key: 'months',
                                    defaultValue: new Date().getMonth() + 1,
                                    data: {
                                        values: LISTVIEW_TIME_MONTH_FILTER.map(
                                            (item) => ({
                                                ...item,
                                                label: this._translateService.instant(
                                                    item.label
                                                ),
                                            })
                                        ),
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        // {
                        //     components: [
                        //         {
                        //             label: this._translateService.instant(
                        //                 'FILTER.EMPLOYEE'
                        //             ),
                        //             type: 'textfield',
                        //             key: 'employee',
                        //             hidden: (!this.isTeamManagerForTeams.length && !this.isManager) || !this.isAdminPage,
                        //         },
                        //     ],
                        //     type: 'column',
                        //     key: 'column2',
                        //     label: 'Column',
                        // },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.TEAMMANAGER'
                                    ),
                                    type: 'select',
                                    key: 'teammanager',
                                    data: {
                                        values: this.teamsWithManagers.map(
                                            (x) => {
                                                return {
                                                    label: `${x.FieldValuesAsText.Teamleiter} - ${x.Title}`,
                                                    value: x.Id,
                                                };
                                            }
                                        ),
                                    },
                                    hidden:
                                        (!this.isTeamManagerForTeams.length &&
                                            !this.isManager) ||
                                        !this.isAdminPage,
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
            ],
        };

        this.subscribeFilterChange();
    }

    onNavChange() {}

    onTooolbarButtonClick(e) {
        this.deleteMode = true;
        this.openDialog(e.dataItem);
    }

    openDialog(item, rowClick?: boolean): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            id: item.Id,
            item: item,
            editMode: true,
            disableProject: true,
        };

        if (!this.deleteMode && rowClick) {
            this.timeService.preserve();
            const dialogRef = this.dialog.open(NewTimeComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(async (result) => {
                this.timeService.restore();
                this.newTimeComponent.setValues(); // TODO: ERROR TypeError: Cannot read properties of undefined (reading 'setValues')
                if (!result && !this.timeService.timeUpdated) {
                    return;
                }
                await this.updateManagerGridData();
                // Refresh table
                this.timeGrid.refresh();
                this.timeService.timeUpdated = false;
            });
        } else if (this.deleteMode && !rowClick) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    confirmMessage: this._translateService.instant(
                        'LV_TIME.NOTIFICATIONS.PERMISSION'
                    ),
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.deleteMode = false;
                if (result) {
                    this.timeService
                        .deleteTimeItem(item)
                        .then(() => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'LV_TIME.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                            this.timeGrid.refresh();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            });
        }
    }

    public onFormioFilterChange(ev) {
        console.warn('onFormioChange', ev);

        if (!ev.data) return;

        this.filterChangeEmitter.emit({
            year: ev.data.year,
            month: ev.data.months,
            employee: ev.data.employee,
            teammanager: ev.data.teammanager,
        });
    }

    private subscribeFilterChange() {
        this.filterChangeEmitter
            .asObservable()
            .pipe(
                debounceTime(0),
                switchMap((value) => {
                    // if (value.length < 3) {
                    //   return of();
                    // }

                    return of(value);
                })
            )
            .subscribe(
                (data) => {
                    const processedTimeData = this.monthAllData.filter(
                        (mad) =>
                            mad.year === Number.parseInt(data.year) &&
                            mad.month === data.month - 1 &&
                            (data.employee
                                ? mad.employeeTitle
                                      .toLowerCase()
                                      .includes(data.employee.toLowerCase())
                                : true) &&
                            (data.teammanager && data.teammanager !== -1
                                ? mad.teamId === data.teammanager
                                : true)
                    );

                    const monthColumns = [];
                    const daysInCurrentMonth = new Date(
                        Number.parseInt(data.year),
                        data.month,
                        0
                    ).getDate();
                    for (let i = 0; i < daysInCurrentMonth; i++) {
                        const date = new Date(
                            Number.parseInt(data.year),
                            data.month - 1,
                            i + 1
                        );
                        this.weekends[i] =
                            date.getDay() === 6 || date.getDay() === 0;
                        monthColumns.push({
                            internalName: `day${i + 1}`,
                            title: `${i + 1}`,
                            hidden: false,
                            type: ColumnTyp.Text,
                            width: '45px',
                        });
                    }

                    const activeColumns = this.monthGrid.activeColumns.filter(
                        (column) => !column.internalName.startsWith('day')
                    );
                    activeColumns.splice(2, 0, ...monthColumns);
                    this.monthGrid.updateActiveColumns(activeColumns);
                    this.monthConfig.data = processedTimeData;
                    this.monthGrid.refresh();
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    private async loadTeamManagers() {
        const qryManager = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .UserField('Teamleiter')
            .Id()
            .IsNotNull()
            .ToString();

        const qryTeammanager = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .UserField('Teamleiter')
            .Id()
            .IsNotNull()
            .And()
            .UserField('Teamleiter')
            .EqualToCurrentUser()
            .ToString();

        // load all team managers
        let teamItems: any[] = await this._sharepointService.getListItems({
            title: 'Teams',
            isDocumentLibrary: false,
            recursiveAll: true,
            camlQuery: this.isManager ? qryManager : qryTeammanager,
        });

        this.teamsWithManagers = teamItems.map((x) => {
            return {
                TeamleiterId: x.TeamleiterId,
                FieldValuesAsText: {
                    Teamleiter: x.FieldValuesAsText?.Teamleiter,
                },
                Title: x.Title,
                Id: x.Id,
            };
        });
        this.teamsWithManagers.unshift({
            TeamleiterId: -1,
            FieldValuesAsText: {
                Teamleiter: 'Alle Teamleiter',
            },
            Title: 'Alle Teams',
            Id: -1,
        });
    }

    private initUserTotalHours(): void {
        this.monthAllData.forEach((item) => {
            const holidays =
                this._holidaysService.holidaysPerLocation[
                    this.employeePerLocation[item.employeeId]
                ];
            const hours = DateUtil.getBusinessHoursWithHolidays(
                Number.parseInt(item.year, 10),
                item.month,
                this.employeeHours[item.employeeId] ?? 8,
                holidays
            );

            item.holidays = [];
            holidays?.forEach((holiday) => {
                if (
                    holiday.Date.getFullYear() === item.year &&
                    holiday.Date.getMonth() === item.month
                ) {
                    item.holidays[holiday.Date.getDate()] = 1;
                }
            });

            const actualTime = toFixed(
                this.totalHours[
                    `${item.employeeId}_${item.month}_${item.year}`
                ],
                2
            ).toLocaleString();
            const targetTime = toFixed(hours, 2).toLocaleString();
            item.employeeTitleWithHours = `${item.employeeTitle} ${actualTime} / ${targetTime}`;
        });
    }

    private async initHolidaysPerUser(): Promise<void> {
        const userIds = [
            ...new Set(this.monthAllData.map((item) => item.employeeId)),
        ];
        const usersGroups = userIds.map((userId) =>
            this._sharepointService.web.siteUsers.getById(userId).groups()
        );
        const userGroups = await Promise.all(usersGroups);
        const teams = await this._teamsService.loadAllTeams();
        userGroups.forEach((_userGroups, index) => {
            const groupIds: number[] = _userGroups.map((group) => group.Id);
            const team = teams.find(
                (team) =>
                    groupIds.includes(team.GruppeId) &&
                    team.StandortId &&
                    team.AvailableForCalculation
            );
            this.employeePerLocation[userIds[index]] = team?.StandortId;
        });
    }

    public async generateHours() {
        const date = new Date(2022, 10, 1);
        date.setHours(12, 0, 0, 0);
        const data = [];
        for (let i = 0; i < 2; i++) {
            data.push({
                Title: 'Zeit',
                Datum: date,
                AufgabeId: 6,
                Minuten: 60,
                ProjektId: 2,
                Kosten: 5,
                Projektnummer: 'D1-00002',
                Beschreibung: '',
            });
        }

        const [batchedWeb, execute] = this._sharepointService.web.batched();
        const res = [];
        const list = batchedWeb.lists.getByTitle('Zeiten');

        data.forEach((item) => {
            list.items.add(item).then((response) => res.push(response));
        });

        await execute();
    }
}
