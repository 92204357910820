import { NgModule } from '@angular/core';
import { InvoicesAssignmentComponent } from './invoices-assignment.component';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
//import { TableTaskModule } from '../../task/table-task/table-task.module'; //TODO
import { KanbanModule } from '../../kanban/kanban.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TableInvoiceModule } from '../../invoice/table-invoice/table-invoice.module';
import { OverviewBudgetModule } from '../../budget/overview-budget/overview-budget.module';

const routes = [
    {
        path: 'projects/detail/invoices',
        component: InvoicesAssignmentComponent,
    },
    {
        path: 'projects/detail/invoices/:id',
        component: InvoicesAssignmentComponent,
    },
];

@NgModule({
    declarations: [InvoicesAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        MatCardModule,
        AngularFrameworkModule,
        TableInvoiceModule,
        KanbanModule,
        OverviewBudgetModule,
    ],
    exports: [InvoicesAssignmentComponent],
})
export class InvoicesAssignmentModule {}
