export const locale = {
    lang: 'de',
    data: {
        LV_CURRENTUSER: {
            TITLE: 'Mein Bereich',
            SEARCH_LABEL: 'Suchen',
            MYPROJECTS: 'Projekte',
            MYTASKS: 'Aufgaben',
            MYWPS: 'Arbeitspakete',
            COLUMNS_PROJECT: {
                NAME: 'Projekt',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                STATUS: 'Status',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                START: 'Start',
                END: 'Fertigstellung',
                BASE: 'Stammdaten',
                DESCRIPTION: 'Beschreibung',
                CATEGORY: 'Kategorie',
                BUDGET: 'Budget',
                INEURO: '(in Euro)',
                INHOURS: '(in Stunden)',
                INDAYS: '(in Tagen)',
                USER: 'Rollen',
                MANAGEMENT: 'Leitung',
                PPL: 'PPL',
                CONTACTPERSON: 'Ansprechpartner/Fachbereich',
                APPLICANT: 'Antragssteller',
                PROJECTNUMBER: 'Projektnummer',
                OW_NUMBER: 'OW-Nummer',
            },
            COLUMNS_TASK: {
                TITLE: 'Aufgabe',
                DUEDATE: 'Fälligkeitsdatum',
                STATUS: 'Status',
                TASK: 'Aufgabe',
                ASSIGNED: 'Zugewiesen an',
                PROJECT: 'Projekt',
                DESCRIPTION: 'Beschreibung',
                KIND: 'Aufgabenart',
                WORKPACKAGE: 'Arbeitspaket',
                START: 'Start',
                PRIORITY: 'Priorität',
                HIGH: '(1) Hoch',
                NORMAL: '(2) Normal',
                LOW: '(3) Niedrig',
                NOTSTARTED: 'Nicht begonnen',
                INPROCESSING: 'In Bearbeitung',
                COMPLETED: 'Abgeschlossen',
                RESET: 'Zurückgestellt',
                WAITING: 'Wartet auf jemand anderen',
                TYPE: 'Aufgabentyp',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                PROCESSSTEP: 'Prozessschritt',
                ANSPRECHPARTNER: 'Ansprechpartner'
            },
            NOTIFICATIONS: {
                LOADING: 'Daten werden geladen...',
                WAIT: 'Bitte warten.',
            },
            SAVE: 'Speichern',
        },
    },
};
