import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import CamlBuilder from 'camljs';

export class UpdateToVersion1138Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Add List Aufträge-Budgets
            await this._updateService.addList('Aufträge-Budgets', '', 100, {
                EnableVersioning: true,
            });
            // Add List Aufträge-Budgets
            await this._updateService.addList(
                'Aufträge-Budgets-Temp',
                '',
                100,
                {
                    EnableVersioning: true,
                }
            );
            const assignmentBudgets =
                this._sharePointService.web.lists.getByTitle(
                    'Aufträge-Budgets'
                );
            const assignmentBudgetsTemp =
                this._sharePointService.web.lists.getByTitle(
                    'Aufträge-Budgets-Temp'
                );
            const assignmentList =
                this._sharePointService.web.lists.getByTitle('Aufträge');

            await this._updateService.addField(
                assignmentBudgets,
                'Antragssumme',
                FieldType.Currency
            );
            await this._updateService.addField(
                assignmentBudgets,
                'Nebenkosten',
                FieldType.Number
            );
            await this._updateService.addField(
                assignmentBudgets,
                'Fremdleistung',
                FieldType.Currency
            );
            await this._updateService.addLookupField(
                assignmentBudgets,
                assignmentList,
                'Aufträge'
            );

            await this._updateService.addField(
                assignmentBudgetsTemp,
                'Antragssumme',
                FieldType.Currency
            );
            await this._updateService.addField(
                assignmentBudgetsTemp,
                'Nebenkosten',
                FieldType.Number
            );
            await this._updateService.addField(
                assignmentBudgetsTemp,
                'Fremdleistung',
                FieldType.Currency
            );
            await this._updateService.addLookupField(
                assignmentBudgetsTemp,
                assignmentList,
                'Aufträge'
            );

            const assignments = await this._sharePointService.getListItems({
                title: 'Aufträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            });

            for (let i = 0; i < assignments.length; i++) {
                await this._sharePointService.addFolder({
                    listTitle: 'Aufträge-Budgets',
                    folderName: assignments[i].Id + '',
                    isDocumentLibrary: false,
                });
                await this._sharePointService.addItem({
                    data: {
                        Antragssumme: assignments[i].Antragssumme ?? 0,
                        Nebenkosten: assignments[i].Nebenkosten ?? 0,
                        Fremdleistung: assignments[i].Fremdleistung ?? 0,
                        Auftr_x00e4_geId: assignments[i].Id,
                    },
                    folder: `${assignments[i].Id}`,
                    listTitle: 'Aufträge-Budgets',
                });

                if (i % 50 === 0) {
                    await this.delay();
                }
            }

            // const batch = this._sharePointService.web.createBatch();
            // const batchItems = assignmentBudgetsTemp.items.inBatch(batch);
            // const itemsToAdd = assignments.map((assignment) => ({
            //     Antragssumme: assignment.Antragssumme,
            //     Nebenkosten: assignment.Nebenkosten,
            //     Fremdleistung: assignment.Fremdleistung,
            //     Auftr_x00e4_geId: assignment.Id,
            // }));
            // itemsToAdd.forEach((item) => batchItems.add(item));
            // await batch.execute();

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }

    private delay(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => resolve(null), 500);
        });
    }
}
