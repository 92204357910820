import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1074Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            const fachbereicheList = this._sharePointService.web.lists.getByTitle(
                'Fachbereiche'
            );
            const companyList = this._sharePointService.web.lists.getByTitle(
                'Firmen'
            );
            const teamList = this._sharePointService.web.lists.getByTitle(
                'Teams'
            );

            let orderList: IList;
            let kostenstelleList: IList;
            let locationList: IList;

            const promises = [
                // Add List Anträge
                await this._updateService.addList('Anträge', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Anträge
                await new Promise<void>((resolve) => {
                    orderList = this._sharePointService.web.lists.getByTitle(
                        'Anträge'
                    );
                    resolve();
                }),
                // Add List Kostenstellen
                await this._updateService.addList('Kostenstellen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Kostenstellen
                await new Promise<void>((resolve) => {
                    kostenstelleList = this._sharePointService.web.lists.getByTitle(
                        'Kostenstellen'
                    );
                    resolve();
                }),
                // Add List Standorte
                await this._updateService.addList('Standorte', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Standorte
                await new Promise<void>((resolve) => {
                    locationList = this._sharePointService.web.lists.getByTitle(
                        'Standorte'
                    );
                    resolve();
                }),
                // Add Field Antrag to List Aufträge
                await this._updateService.addLookupField(
                    projectList,
                    orderList,
                    'Antrag'
                ),
                // Add Field Antragsdatum to List Anträge (Pflicht)
                await this._updateService.addField(
                    orderList,
                    'Antragsdatum',
                    FieldType.Date
                ),
                // Add Field Auftraggeber to List Anträge (Pflicht)
                await this._updateService.addLookupField(
                    orderList,
                    companyList,
                    'Auftraggeber'
                ),
                // Add Field Projektsteuerer to List Anträge
                await this._updateService.addLookupField(
                    orderList,
                    companyList,
                    'Projektsteuerer'
                ),
                // Add Field Kostenstelle to List Anträge (Pflicht)
                await this._updateService.addLookupField(
                    orderList,
                    kostenstelleList,
                    'Kostenstelle'
                ),
                // Add Field Team to List Anträge (Pflicht)
                await this._updateService.addLookupField(
                    orderList,
                    teamList,
                    'Team'
                ),
                // Add Field Fachbereich to List Anträge (Pflicht)
                await this._updateService.addLookupField(
                    orderList,
                    fachbereicheList,
                    'Fachbereich'
                ),
                // Add Field Standort to List Anträge
                await this._updateService.addLookupField(
                    orderList,
                    locationList,
                    'Standort'
                ),
                // Add Field Akkreditiert to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Akkreditiert',
                    FieldType.Boolean
                ),
                // Add Field Projektleiter to List Anträge (Pflicht)
                await this._updateService.addUserField(
                    orderList,
                    'Projektleiter'
                ),
                // Add Field Sachbearbeiter to List Anträge
                await this._updateService.addUserField(
                    orderList,
                    'Sachbearbeiter',
                    true
                ),
                // Add Field Antragssumme to List Anträge (Pflicht)
                await this._updateService.addField(
                    orderList,
                    'Antragssumme',
                    FieldType.Currency
                ),
                // Add Field Nebenkosten to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Nebenkosten',
                    FieldType.Number
                ),
                // Add Field Fremdleistung to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Fremdleistung',
                    FieldType.Currency
                ),
                // Add Field Kopie to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Kopie',
                    FieldType.Boolean
                ),
                // Add Field Auftragsbestaetigung to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Auftragsbestaetigung',
                    FieldType.Boolean
                ),
                // Add Field Ordner to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Ordner',
                    FieldType.Boolean
                ),
                // Add Field Weihnachtskarte to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Weihnachtskarte',
                    FieldType.Boolean
                ),
                // Add Field Uebergabe to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Uebergabe',
                    FieldType.Date
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
