import { SharePointService, UpdateService, } from 'sp-office365-framework';
import CamlBuilder from 'camljs';

export class UpdateToVersion1151Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {
    }

    async run() {
        try {
            const assignments = await this._sharePointService.getListItems({
                title: 'Aufträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .LookupField('Auftragskategorie')
                    .Id()
                    .IsNull()
                    .ToString()
            });

            const auftragskategorien = await this._sharePointService.getListItems({
                title: 'Auftragskategorien',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .TextField('Title')
                    .EqualTo('Projekt')
                    .ToString()
            });

            if (!auftragskategorien.length) {
                throw new Error('There is no "Projekt" Auftragskategorie');
                return;
            }

            for (let i = 0; i < assignments.length; i++) {
                await this._sharePointService.updateItem({
                    listTitle: 'Aufträge',
                    id: assignments[i].Id,
                    newFiledValues: {
                        AuftragskategorieId: auftragskategorien[0].Id
                    }
                });
            }

            return;
        } catch (error) {
            throw new Error(error);
        }
    }
}
