import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SharePointService, SharePointTableComponent, TableConfiguration, } from 'sp-office365-framework';
import { GlobalTask } from '../../models/globaltask.model';
import { DetailInvoiceComponent } from '../detail-invoice/detail-invoice.component';
import { TableTasksetComponent } from '../../taskset/table-taskset/table-taskset.component';
import { TaskService } from '../../services/task.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BillingsService } from '../../services/billings.service';
import { openFileInNewTab } from '../../shared/shared.util';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BillsAttachmentsPopupComponent } from './bills-attachments-popup/bills-attachments-popup.component';

@Component({
    selector: 'app-table-invoice',
    templateUrl: './table-invoice.component.html',
    styleUrls: ['./table-invoice.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TableInvoiceComponent implements OnInit {
    @ViewChild('taskGrid', { static: false })
    taskGrid: SharePointTableComponent;

    _auftragsId: number;
    @Input() set auftragsId(aId: number) {
        this._auftragsId = aId;
    }

    _isTask: boolean;
    @Input() set isTask(isTask: boolean) {
        this._isTask = isTask;
    }

    _configuration: TableConfiguration;
    @Input() set configuration(config: TableConfiguration) {
        this._configuration = config;
    }

    _taskInitData: GlobalTask;
    @Input() set taskInitData(initData: GlobalTask) {
        this._taskInitData = initData;
    }

    _filter: string;
    @Input() set filter(filter: string) {
        this._filter = filter;
    }

    _paketId: number;
    @Input() set paketId(pId: number) {
        this._paketId = pId;
    }

    public id: number;
    public overlayRef: OverlayRef;

    constructor(
        public dialog: MatDialog,
        public tasksService: TaskService,
        public billingsService: BillingsService,
        private _sharePointService: SharePointService,
        private sanitizer: DomSanitizer,
        private overlay: Overlay
    ) {}

    ngOnInit() {}

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this._isTask = false;
                this.openDialog();
                break;
            case 'AddTask':
                this._isTask = true;
                if (e.latestParentItem) {
                    this._paketId = e.latestParentItem.Id;
                }
                this.openDialog();
                break;
            case 'Edit':
                this.id = e.dataItem ? e.dataItem.Id : null;
                this._paketId = e.dataItem.ArbeitspaketId;

                if (this._paketId) {
                    this._isTask = true;
                } else {
                    this._isTask = false;
                }

                this.openDialog();
                break;
            case 'TaskSets':
                this.openTaskSetDialog();
                break;
            case 'Recycle':
                this._paketId = e.dataItem.ArbeitspaketId;

                if (this._paketId) {
                    this._isTask = true;
                } else {
                    this._isTask = false;
                }

                // Fake SPItem for methode
                const currentItem = {
                    Id: e.dataItem.Id,
                    Kennzeichen: 'Unkritisch',
                };

                // Parentitem
                let parentItemID = this._auftragsId;

                if (this._isTask) {
                    parentItemID = this._paketId;
                }

                this.tasksService
                    .updateStateOfParentItem(
                        this._isTask,
                        parentItemID,
                        currentItem
                    )
                    .then(() => {
                        // success
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                break;
        }
    }

    openDialog(): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            isTask: this._isTask,
            taskId: this.id,
            _taskInitData: this._taskInitData,
            _filter: this._filter,
            _paketId: this._paketId,
        };

        const dialogRef = this.dialog.open(
            DetailInvoiceComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            this.id = null;

            this.taskGrid.refresh();

            if (this._isTask) {
                this.taskGrid.refreshSubtables();
            }
        });
    }

    openTaskSetDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this._auftragsId,
            _paketId: this._paketId,
        };

        const dialogRef = this.dialog.open(TableTasksetComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.id = null;

            this.taskGrid.refresh();

            if (this._isTask) {
                this.taskGrid.refreshSubtables();
            }
        });
    }

    public downloadAttachments(projectId: number, anchor): void {
        const attachments = this.billingsService.attachments[projectId];

        if (!attachments.length) {
            return;
        }

        attachments.forEach(attachment => {
            attachment.FullUrl = `${this._sharePointService.sphostUrl.split('/sites/')[0]}/${attachment.ServerRelativeUrl}`;
        });

        // If only 1 attachment - open in new tab
        if (attachments.length === 1) {
           return openFileInNewTab(this._sharePointService.sphostUrl, attachments[0].ServerRelativeUrl);
        }

        // Show popup with multiple pdf
        let positionStrategy;
            positionStrategy = this.overlay.position()
                .flexibleConnectedTo(anchor)
                .withPositions([{
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                } as ConnectedPosition])
                .withDefaultOffsetX(-200)
                .withPush(false);

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            positionStrategy: positionStrategy,
            backdropClass: 'bills-attachments-overlay'
        });

        const componentPortal = new ComponentPortal<BillsAttachmentsPopupComponent>(BillsAttachmentsPopupComponent);
        const componentRef = this.overlayRef.attach(componentPortal);
        componentRef.instance.attachments = attachments;
        this.overlayRef.backdropClick().subscribe(() => this.hideBillPDFAttachmentsPopup());
    }

    public hideBillPDFAttachmentsPopup(): void {
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }
}
