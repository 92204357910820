import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TableTaskModule } from 'src/app/main/task/table-task/table-task.module';
import { FormioModule } from '@formio/angular';
import { TableTasktemplateModule } from 'src/app/main/tasktemplate/table-tasktemplate/table-tasktemplate.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DetailsWorkpackageSetComponent } from 'src/app/main/work-package-template/components/details-workpackage-template/details-workpackage-set.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [DetailsWorkpackageSetComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        TableTaskModule,
        FormioModule,
        TableTasktemplateModule,
        DragDropModule,
    ],
    exports: [DetailsWorkpackageSetComponent]
})
export class DetailsWorkpackageSetModule {
}
