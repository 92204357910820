<div id='configuration' class="page-layout carded fullwidth tabbed">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header accent p-6" fxLayout="row" fxLayoutAlign="start center">

      <!-- APP TITLE -->
      <div fxLayout="column" fxLayoutAlign="center start">

        <div class="logo" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon mr-4" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">settings
          </mat-icon>
          <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
            {{ "CONFIGURATION.TITLE" | translate }}
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
          <span class="secondary-text">{{ "CONFIGURATION.ADMIN" | translate }}</span>
          <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
          <span class="secondary-text">{{ "CONFIGURATION.TITLE" | translate }}</span>
        </div>

      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

          <div class="search-wrapper mt-4 mt-sm-0">

            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input [formControl]="searchInput" placeholder="Search for a contact">
            </div>

          </div>

        </div> -->
      <!-- / SEARCH -->

      <app-selected-bar-custom class="accent-600" *ngIf="hasSelectedItems" [@slideInTop] [table]="this.currentTable"
        [listName]="this.currentConfig.list">
      </app-selected-bar-custom>

    </div>
    <!-- / CONTENT HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">

      <!-- CONTENT -->
      <div class="content">

        <mat-tab-group *ngIf="this.config" dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

          <mat-tab *ngFor="let tab of this.config.tabs; let i = index" [label]="tab.label">
            <div class="p-6">

              <div *ngIf="tab.tableConfig.list === 'Feiertage'" class="import-ics-section mb-8">
                <button mat-raised-button color="accent" (click)="openLocationDialog()" class="updateButton">
                  {{ "CONFIGURATION.HOLIDAYS.IMPORT_ICS" | translate }}
                </button>
                <input #inputFile style="display: none" type="file" (change)="importICS($event, i)" />
              </div>

              <app-sharepoint-table #tabsTables
                                    [class.selectable]="tab.tableConfig.columns[0]?.internalName === 'Auswahl'"
                                    [configuration]="tab.tableConfig"
                                    (cellClick)="editItem($event, i)"
              >

                <ng-template customCell for="Auswahl" let-dataItem="dataItem">
                  <mat-checkbox #checkBoxes class="ml-2" (change)="checkBoxChange($event, dataItem)"></mat-checkbox>
                </ng-template>

                <ng-template customCell for="AvailableForCalculation" let-dataItem="dataItem">
                  {{dataItem.AvailableForCalculation ? 'Ja' : 'Nein'}}
                </ng-template>

              </app-sharepoint-table>

              <app-sptable-edit #tabsEdits [spListTitle]="tab.tableConfig.list"
                [formioConfiguration]="tab.tableConfig.formConfig" (onClose)="onClose(i)">
              </app-sptable-edit>

              <button mat-fab class="addButton" (click)="addItem(i)"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </mat-tab>

        </mat-tab-group>

      </div>
      <!-- / CONTENT -->

    </div>
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>
