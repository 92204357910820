import { LoadingService } from './../../services/loading.service';
import { MoveDocumentService } from './../../services/move-document.service';
import { SnackbarService } from './../../services/snackbar.service';
import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { TableColumn, TableConfiguration } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailTaskComponent } from '../detail-task/detail-task.component';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { TaskService } from '../../services/task.service';
import { FormioComponent } from '@formio/angular';

@Component({
    selector: 'app-move-element',
    templateUrl: './move-element.component.html',
    styleUrls: ['./move-element.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MoveElementComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public showReply = false;
    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    public sharePointItem;
    public formioConfiguration;
    public tableConfig: TableConfiguration;
    public tableConfigTasks: TableConfiguration;
    public columns: TableColumn[];
    public documentTabHeader;
    public firstTimeLoad = true;
    private taskId: number;
    private id: number;
    private projectId: number;
    private currentProjectId: number;
    private fileRef;

    constructor(
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        public _documentService: DocumentsAssignmentService,
        public _taskService: TaskService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailTaskComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _applicationService: ApplicationService,
        private _snackbarService: SnackbarService,
        private _moveDocService: MoveDocumentService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.documentTabHeader = this._translateService.instant(
            'MOVE_ELEMENT.TABS.DOCS'
        );
    }

    ngOnInit() {
        this.id = this.data.itemId;
        this.projectId = this.data.projectId;
        this.taskId = this.data.taskId;
        this.fileRef = this.data.fileRef;
        this.currentProjectId = this.projectId;
        this.sharePointItem = {
            id: this.id,
            AuftragId: this.projectId,
            fileRef: this.fileRef,
            AufgabeId: this.taskId,
            objectType: this.data.objectType,
        };
        this.initForm();
    }

    get taskCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .EqualTo(this.currentProjectId)
            .OrderBy('Title')
            .ToString();
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = this._applicationService.leftMerge(
            emptyObj,
            this.sharePointItem || {}
        );

        dataForm.vonProjektId = this.projectId;
        dataForm.AuftragId = this.projectId;
        dataForm.vonAufgabeId = this.taskId;
        dataForm.AufgabeId = this.taskId;

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        let saveCase: saveCase = { task: null };
        delete e.data.submit;

        //No Saving if no changes were made
        if (
            e.data.vonAufgabeId == e.data.AufgabeId &&
            e.data.vonProjektId == e.data.AuftragId
        ) {
            return from(
                new Promise<void>((resolve) => {
                    this._snackbarService.add(
                        this._translateService.instant(
                            'MOVE_ELEMENT.NOTIFICATION.NOCHANGE_ERROR'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );
                    resolve();
                })
            );
        }

        if (e.data.AufgabeId && !e.data.vonAufgabeId) {
            saveCase.task = 'added';
        } else if (!e.data.AufgabeId && e.data.vonAufgabeId) {
            saveCase.task = 'removed';
        } else if (!e.data.AufgabeId && !e.data.vonAufgabeId) {
            saveCase.task = 'noChangesMade';
        } else {
            saveCase.task = 'changed';
        }

        return from(
            new Promise<void>((resolve, reject) => {
                this._loadingService.show(
                    this._translateService.instant(
                        'MOVE_ELEMENT.NOTIFICATION.MOVE'
                    ),
                    this._translateService.instant(
                        'MOVE_ELEMENT.NOTIFICATION.WAIT'
                    )
                );
                this._moveDocService
                    .checkMoveDestination(e.data, this.sharePointItem, saveCase)
                    .then(() => {
                        resolve();
                        this._loadingService.hide();
                        this._snackBarService.add(
                            this._translateService.instant(
                                'MOVE_ELEMENT.NOTIFICATION.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close('success');
                    })
                    .catch((err) => {
                        console.error(err);
                        reject();
                    });
            })
        );
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'MOVE_ELEMENT.PROJECT.FROM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    list: 'Aufträge',
                                    key: 'vonProjektId',
                                    disabled: true,
                                },
                                {
                                    label: this._translateService.instant(
                                        'MOVE_ELEMENT.WORKPACKAGE.FROM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'vonAufgabeId',
                                    list: 'Auftragsaufgaben',
                                    disabled: true,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            disabled: true,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'MOVE_ELEMENT.PROJECT.TO'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    list: 'Aufträge',
                                    key: 'AuftragId',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .NumberField('ID')
                                        .NotEqualTo(-1)
                                        .OrderBy('Title')
                                        .ToString(),
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    disabeld: true,
                                    onSelected: (control, data) => {
                                        const dataForm =
                                            this.formioComponent.formio._data;

                                        if (data.Id != this.currentProjectId) {
                                            dataForm['AufgabeId'] = null;
                                        }
                                        this.currentProjectId = data.Id;

                                        // Enable task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio
                                                .components,
                                            'AufgabeId',
                                            'disabled',
                                            false
                                        );

                                        // Update searchQueryIfIsEmpty for task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio
                                                .components,
                                            'AufgabeId',
                                            'searchQueryIfIsEmpty',
                                            this.taskCamlQuery
                                        );

                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm,
                                            },
                                        });
                                    },
                                    onRemoved: (control, item) => {
                                        const dataForm =
                                            this.formioComponent.formio._data;

                                        dataForm['AufgabeId'] = null;

                                        // Disable task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio
                                                .components,
                                            'AufgabeId',
                                            'disabled',
                                            true
                                        );

                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm,
                                            },
                                        });
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'MOVE_ELEMENT.WORKPACKAGE.TO'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AufgabeId',
                                    list: 'Auftragsaufgaben',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .LookupField('Auftrag')
                                        .Id()
                                        .EqualTo(this.projectId)
                                        .OrderBy('Title')
                                        .ToString(),
                                    removable: true,
                                    disabeld: false,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant('MOVE_ELEMENT.SAVE'),
                    size: 'small',
                    type: 'button',
                    input: true,
                    defaultValue: false,
                    showValidations: false,
                    action: 'submit',
                },
            ],
        };
    }
}
