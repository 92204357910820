import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    FuseFooterService,
    NavigationService,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { UpdateManagerService } from './updateManager.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { CurrentUserService } from '../../services/current-user.service';
import { adminNavigation, adminNavigationWithTimes, navigation, navigationWithTimes } from 'src/app/navigation/navigation';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class UpdateComponent implements OnInit {
    public updateAvailable = false;
    public userIsSiteAdmin = true;

    public currentVersion;
    public latestVersion;
    public latest = true;

    constructor(
        private _updateService: UpdateService,
        private updateManagerService: UpdateManagerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private fuseFooterService: FuseFooterService,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService,
        private _sharePointService: SharePointService,
        private _currentUserService: CurrentUserService,
        private _navigationService: NavigationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.latestVersion = this.updateManagerService.latestVersion;
    }

    ngOnInit() {
        this._updateService
            .getCurrentVersion()
            .then((version) => {
                if (!version) {
                    version = '1000';
                }
                this.currentVersion = +version;
                this.latest = this.currentVersion == this.latestVersion;
            })
            .catch((error) => {
                this.currentVersion = 1000;
                this.latest = this.currentVersion == this.latestVersion;
            });
    }

    update(all?, isLoading?) {
        if (!isLoading) {
            this.loadingService.show(
                this._translateService.instant('RUNNING'),
                this._translateService.instant('DURATION')
            );
        }

        this.updateManagerService
            .updateToVersion(+this.currentVersion + 1)
            .then(() => {
                this._updateService
                    .getCurrentVersion()
                    .then((version) => {
                        this.currentVersion = +version;
                        this.latest = this.currentVersion == this.latestVersion;

                        if (this.latest || !all) {
                            // Navigation anpassen
                            if (this.latest) {
                                // Check if times enabled
                                this._updateService
                                    .getPropertyValue('Zeiterfassung')
                                    .then((value) => {
                                        if (value == 'Ja') {
                                            // Set the navigation as our current navigation
                                            if (
                                                this._sharePointService.currentUser.IsBusinessOwner ||
                                                this._currentUserService.isTeamProjectLeiter()
                                            ) {
                                                // Get adminNavigationWithTimes
                                                const _adminNavigationWithTimes = adminNavigationWithTimes;

                                                // Update Version in adminNavigationWithTimes
                                                _adminNavigationWithTimes[0].children.find(
                                                    (x) => x.id == 'admin'
                                                ).badge.title =
                                                    'V ' +
                                                    this.currentVersion
                                                        .toString()
                                                        .split('')
                                                        .join('.');
                                                        _adminNavigationWithTimes[0].children.find(
                                                    (x) => x.id == 'admin'
                                                ).badge.translate =
                                                    'V ' +
                                                    this.currentVersion
                                                        .toString()
                                                        .split('')
                                                        .join('.');

                                                this._navigationService.set(_adminNavigationWithTimes);
                                            } else {
                                                this._navigationService.set(navigationWithTimes);
                                            }
                                        } else {
                                            // Set the navigation as our current navigation
                                            if (
                                                this._sharePointService.currentUser.IsBusinessOwner ||
                                                this._currentUserService.isTeamProjectLeiter()
                                            ) {
                                                // Get adminNavigation
                                                const _adminNavigation = adminNavigation;

                                                // Update Version in adminNavigation
                                                _adminNavigation[0].children.find(
                                                    (x) => x.id == 'admin'
                                                ).badge.title =
                                                    'V ' +
                                                    this.currentVersion
                                                        .toString()
                                                        .split('')
                                                        .join('.');
                                                        _adminNavigation[0].children.find(
                                                    (x) => x.id == 'admin'
                                                ).badge.translate =
                                                    'V ' +
                                                    this.currentVersion
                                                        .toString()
                                                        .split('')
                                                        .join('.');

                                                this._navigationService.set(_adminNavigation);
                                                // this._fuseNavigationService.setCurrentNavigation(
                                                //     'admin'
                                                // );
                                            } else {
                                                // this._fuseNavigationService.setCurrentNavigation(
                                                //     'main'
                                                // );
                                                this._navigationService.set(navigation);
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        // Set the navigation as our current navigation
                                        if (
                                            this._sharePointService.currentUser.IsBusinessOwner ||
                                            this._currentUserService.isTeamProjectLeiter()
                                        ) {
                                            this._navigationService.set(adminNavigation);
                                            // this._fuseNavigationService.setCurrentNavigation(
                                            //     'admin'
                                            // );
                                        } else {
                                            this._navigationService.set(navigation);
                                            // this._fuseNavigationService.setCurrentNavigation(
                                            //     'main'
                                            // );
                                        }
                                    });
                            }

                            // Footer anpassen
                            this.fuseFooterService.unregister('main');
                            this.fuseFooterService.register('main', [
                                {
                                    Text: 'Version: ' + version,
                                    ShowDot: false,
                                },
                            ]);
                            this.fuseFooterService.setCurrentFooter('main');

                            // Hide loading
                            this.loadingService.hide();

                            // Success
                            this.snackBarService.add(
                                this._translateService.instant('UPDATESUCCESS'),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );
                        } else {
                            // Run next Update
                            this.update(all, true);
                        }
                    })
                    .catch((error) => {
                        this.loadingService.hide();

                        this.snackBarService.add(
                            this._translateService.instant('CONSOLE'),
                            this._translateService.instant('ERROR'),
                            {
                                duration: 4000,
                                panelClass: 'error-dialog',
                            }
                        );

                        console.error(error);
                    });
            })
            .catch((error) => {
                this.loadingService.hide();

                this.snackBarService.add(
                    this._translateService.instant('CONSOLE'),
                    this._translateService.instant('ERROR'),
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );

                console.error(error);
            });
    }
}
