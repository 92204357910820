import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1055Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const notificationList: IList = this.sharePointService.web.lists.getByTitle(
                'Notifications'
            );

            const promises = [
                // Add Field Subject to List Notifications
                await this._updateService.runFunctionIfFieldNotExists(
                    notificationList,
                    'Subject',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            notificationList.fields.addText('Subject').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
