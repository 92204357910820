import { AddUserModule } from './../add-user/add-user.module';
import { AddUserComponent } from './../add-user/add-user.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableUserComponent } from './table-user.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { AngularFrameworkModule } from 'sp-office365-framework';

@NgModule({
    declarations: [TableUserComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        AddUserModule,
    ],
    exports: [TableUserComponent]
})
export class TableUserModule {}
