export const locale = {
    lang: 'de',
    data: {
        LV_CUSTOMER: {
            TITLE: 'Kunden',
            SEARCH_LABEL: 'Kunden suchen',
            ALLCUSTOMERS: 'Alle',
            COLUMNS: {
                TITLE1: 'Kunden 1',
                TITLE2: 'Kunden 2',
                TITLE3: 'Kunden 3',
                LOCATIONS: 'Niederlassung',
                STREET: 'Straße/Hausnummer',
                ZIP: 'PLZ',
                CITY: 'Ort',
                SAVE: 'Speichern',
                EMAIL: 'E-Mail',
                LAND: "Land"
            },
            NOTIFICATIONS: {
                LOADING: 'Daten werden geladen...',
                WAIT: 'Bitte warten.',
            },
            SAVE: 'Speichern',
        },
    },
};
