export const locale = {
    lang: 'de',
    data: {
        DETAIL_EMAIL: {
            ADDATTACHMENT: 'Dokumente hochladen',
            NOTIFICATIONS: {
                SAVING: 'E-Mail wird gesendet...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'E-Mail erfolgreich gesendet.',
                ERROR: 'Fehler beim senden: ',
                DOUBLEATTACHMENT:
                    'Es ist bereits eine Datei mit folgendem Namen angehängt: ',
            },
            COLUMNS: {
                TITLE: 'Titel',
                SUBJECT: 'Betreff',
                TEXT: 'Nachricht',
                FROM: 'Absender',
                TO: 'An',
                CC: 'CC',
                ATTACHMENTS: 'Anhänge auswählen',
                SIGNATUREID: 'Signatur auswählen',
                SIGNATURE: 'Signatur',
                CONTACTS: 'Kontakte'
            },
            FORM: {
                CURRENTUSER: 'currentuser',
                PROJECTFROM: 'Projekt Mail',
            },
            SAVE: 'Senden',
            NEW: 'Neue E-Mail',
            EDIT: 'E-Mail bearbeiten',
            REPLY: 'Antworten',
        },
    },
};
