import { MatButtonModule } from '@angular/material/button';
import { NewTimeModule } from './../../time/new-time/new-time.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { DetailTaskComponent } from './detail-task.component';
import { FormioModule } from '@formio/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogTasksComponent } from './confirm-dialog-tasks/confirm-dialog-tasks.component';
import { NewTimeComponent } from '../../time/new-time/new-time.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TableTaskModule } from '../table-task/table-task.module';
import { LayoutModule } from 'sp-office365-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableBoxModule } from 'src/app/main/shared/components/resizable-box/resizable-box.module';
import { FilterMailModule } from '../../mail/filter-mail/filter-mail.module';
import { DocTemplatesComponent } from '../../doc-templates/doc-templates.component';
import { DocTemplatesModule } from '../../doc-templates/doc-templates.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [DetailTaskComponent, ConfirmDialogTasksComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        LayoutModule,
        MatToolbarModule,
        NewTimeModule,
        TableTaskModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        DragDropModule,
        ResizableBoxModule,
        FilterMailModule,
        DocTemplatesModule,
        MatDialogModule
    ],
    exports: [DetailTaskComponent]
})
export class DetailTaskModule {}
