import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DetailControllingComponent } from './detail-controlling.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule, MultiSelectModule, ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [DetailControllingComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FuseSharedModule,
        AngularFrameworkModule,
        MatSnackBarModule,
        MatTabsModule,
        MatButtonModule,
        FormioModule,
        MatToolbarModule,
        FormioModule,
        LayoutModule,
        DragDropModule,
        GridModule,
        DateInputsModule,
        MatIconModule,
        DropDownListModule,
        MultiSelectModule,
        ComboBoxModule,
        DropDownsModule,
        InputsModule
    ],
    exports: [DetailControllingComponent]
})
export class DetailControllingModule {}
