import { RequestService } from '../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1126Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const costRoleList: IList =
                this._sharePointService.web.lists.getByTitle('Kostenrollen');

            // Add Field Abbreviation to List Kostenrollen
            await this._updateService.addField(
                costRoleList,
                'Abbreviation',
                FieldType.Text
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
