<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "DETAIL_EMAIL.EDIT" | translate }}</span>
    <span *ngIf="!this.id && !this.isReply" class="title dialog-title">{{ "DETAIL_EMAIL.NEW" | translate }}</span>
    <span *ngIf="!this.id && this.isReply" class="title dialog-title">{{ "DETAIL_EMAIL.REPLY" | translate }}</span>

    <!-- Add Attachment -->
    <button *ngIf="!data.forwardTo" mat-raised-button class="newButton" color="warn" (click)="this.addAttachment()">
      <mat-icon>attach_file</mat-icon>
      {{ "DETAIL_EMAIL.ADDATTACHMENT" | translate }}
    </button>

    <!-- Close Diaolog -->
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>

  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>
  <input type="file" style="display: none;" [multiple]="true" [accept]="this._documentService.acceptedFileExtensions"
    (change)="this.fileChange($event);" #fileInput />
  <!--
  <input type="file" style="display: none;" [multiple]="true" [accept]="this._documentService.acceptedFileExtensions"
    (change)="this.fileChangeEvent($event)" #fileInput />
-->
  <formio #formioComponent
          class="formio-material"
          [refresh]="refreshForm"
          [service]="this"
          [renderOptions]="formio_renderOptions"
          [form]="formioConfiguration"
          (ready)="formioReady($event)"
          (change)="onChange($event)">
  </formio>

  <p>{{ "DETAIL_EMAIL.COLUMNS.ATTACHMENTS" | translate }}</p>

  <app-sharepoint-table *ngIf="tableConfig"
                        #documentGrid
                        class="mail-details-documents"
                        [configuration]="tableConfig"
                        (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
  >
    <ng-template
        customCell
        for="CopyColumn"
        let-dataItem="dataItem"
    >
      <button
          *ngIf="!dataItem.FileSystemObjectType"
          mat-icon-button
          (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.copy)"
          matTooltip="Copy file"
      >
        <mat-icon>file_copy</mat-icon>
      </button>
    </ng-template>
    <ng-template
        customCell
        for="RenameColumn"
        let-dataItem="dataItem"
    >
      <button
          *ngIf="!dataItem.FileSystemObjectType"
          mat-icon-button
          (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.rename)"
          matTooltip="Rename file"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </ng-template>
  </app-sharepoint-table>
</div>

<div *ngIf="this.data.email" #forwardMessage style="width: 100%;display: none; flex-direction: column; border-top: 1px solid black;">
  <div style="border-top: 1px solid black;">
    <hr>
    --------------------------------------------------
    <div><b>From:</b> {{this.data.email.from.email}}</div>
    <div><b>Sent:</b> {{this.data.email.time}}</div>
    <div><b>To:</b>
      {{this.data.email.to[0].email}}
    </div>
    <div><b>Subject:</b> {{this.data.email.subject}}</div>
  </div>
</div>

<div *ngIf="this.data.email" #replyMessage style="width: 100%;display: none; flex-direction: column; border-top: 1px solid black;">
  <div style="border-top: 1px solid black;">
    <hr>
    ------------------------Antworten--------------------------
  </div>
</div>
