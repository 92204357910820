import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1135Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Get List Firmen
            const list = this._sharePointService.web.lists.getByTitle('Firmen');
            await this._updateService.addField(list, 'Title2', FieldType.Text);
            await this._updateService.addField(list, 'Title3', FieldType.Text);

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
