export const locale = {
    lang: 'en',
    data: {
        DETAIL_TASKSET: {
            BASE: 'Basedata',
            TASKSET: 'Taskset',
            TASKTEMPLATES: 'Tasktemplates',
            SEARCH_LABEL: 'Search for a taskset',
            TITLE: 'Tasksets',
            NEW: 'New taskset',
            EDIT: 'Edit taskset',
            NEWTASKTEMPLATE: 'New tasktemplate',
            COLUMNS: {
                TITLE: 'Name',
            },
            COLUMNSTASKTEMPLATE: {
                TITLE: 'Name',
                DESCRIPTION: 'Description',
                OFFSETSTART: 'Addition to the startdate',
                INDAYS: '(in days)',
                OFFSETEND: 'Addition to the duedate',
                PRIORITY: 'Priority',
                HIGH: '(1) High',
                NORMAL: '(2) Normal',
                LOW: '(3) Low',
                PROCESSSTEP: 'Processstep',
                TYPE: 'Tasktype',
                SCHEDULEDTIME: 'Scheduled time',
                INHOURS: '(in hours)',
                OFFSETSTARTTYPE: 'Type of addition to the startdate',
                OFFSETENDTYPE: 'Type of addition to the duedate',
                TODAY: 'Since today',
                START: 'Since start of workpackage',
                END: 'From end of workpackage',
                STARTASSIGNMENT: 'Since start of project',
                ENDASSIGNMENT: 'From end of project',
            },
            NOTIFICATIONS: {
                SAVING: 'Saving taskset...',
                WAIT: 'Please wait.',
                SUCCESS: 'Taskset saved successfully',
                ERROR: 'Error while saving: ',
            },
            SAVE: 'Save',
        },
    },
};
