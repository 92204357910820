export const locale = {
    lang: 'de',
    data: {
        LV_GROUP: {
            TITLE: 'Gruppen',
            SEARCH_LABEL: 'Nach einer Gruppe suchen',
            ALL: 'Alle',
            COLUMNS: {
                TITLE: 'Gruppe',
                DESCRIPTION: 'Beschreibung',
            },
            NEW: 'Neue Gruppe',
            EDIT: 'Gruppe bearbeiten',
            SAVE: 'Speichern',
            NOTIFICATIONS: {
                DELETED: 'Gruppe wurde gelöscht!',
                HINT: 'Hinweis: Nur Besitzer einer Gruppe können diese löschen.',
            },
        },
    },
};
