export function excelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

export function getCustomerTitle(item: { Title: string, Title2: string, Title3: string, Kunden_Niederlassung: string, Strasse: string, WorkZip: string; WorkCity: string }): string {
    const title2 = item.Title2 ? ` ${item.Title2}` : '';
    const title3 = item.Title3 ? ` ${item.Title3}` : '';
    const street = item.Strasse ? `${item.Strasse}, ` : '';
    const plz = item.WorkZip ? `${item.WorkZip}, ` : '';
    const city = item.WorkCity ? `${item.WorkCity}, ` : '';
    const branch = item.Kunden_Niederlassung ? `${item.Kunden_Niederlassung}` : '';
    let title = `${item.Title}${title2}${title3}`;

    if (street || plz || city || branch) {
        return `${title} (${street}${plz}${city}${branch})`;
    }

    return title;
}

export function toFixed(value: number, fractionDigits: number): number {
    value = Number(value);
    return value % 1 ? Number(value.toFixed(fractionDigits)) : value;
}

export function splitArrayIntoChunks<T>(array: T[], chunkSize = 500): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i = i + chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunks.push(chunk);
  }

  return chunks;
}

export function openFileInNewTab(sphostUrl: string, serverRelativeUrl: string): void {
    window.open(`${sphostUrl.split('/sites/')[0]}/${serverRelativeUrl}`, '_blank');
}
