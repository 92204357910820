import { BudgetService } from './../../services/budget.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ApplicationService } from '../../services/application.service';
import { SharePointService } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { TimeService } from 'src/app/main/services/time.service';
import { BillingsService } from '../../services/billings.service';
import { forkJoin } from 'rxjs';
import { OverviewBudgetChartEnum } from './overview-budget-chart.enum';

@Component({
    selector: 'app-overview-budget',
    templateUrl: './overview-budget.component.html',
    styleUrls: ['./overview-budget.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class OverviewBudgetComponent implements OnInit {
    public sharePointItem;
    public OverviewBudgetChartEnum = OverviewBudgetChartEnum;
    @Input() showOnlyBillsChart: boolean;

    constructor(
        public _applicationService: ApplicationService,
        public _budgetService: BudgetService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _sharepointService: SharePointService,
        private _timeService: TimeService,
        private _billingsService: BillingsService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        const promises = [];

        // Reset values
        this._budgetService.currentCosts = 0;
        this._budgetService.currentMinutes = 0;

        if (this._applicationService.currentProjectId > -1) {
            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._sharepointService
                        .getListItems({
                            title: 'Zeiten',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .NotEqualTo(-1)
                                .And()
                                .NumberField('FSObjType')
                                .EqualTo(0)
                                .OrderByDesc('Datum')
                                .ToString(),
                            folderName:
                                this._applicationService.currentProjectId + '',
                        })
                        .then((times) => {
                            times.forEach((time) => {
                                this._budgetService.currentCosts += time.Kosten;
                                this._budgetService.currentMinutes +=
                                    time.Minuten;
                            });

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._sharepointService
                        .getItemById({
                            listTitle: 'Aufträge',
                            id: this._applicationService.currentProjectId,
                        })
                        .then((item) => {
                            this.sharePointItem = item;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(this._billingsService.getBillingsAttachmentsByProject(this._applicationService.currentProjectId));
        }

        forkJoin(promises)
            .subscribe(
                () => this._budgetService.updateBudget(this.sharePointItem),
                error => console.error(error)
            );
    }
}
