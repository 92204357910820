import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignMailComponent } from './assign-mail.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { TableTaskModule } from '../../task/table-task/table-task.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImportEmailDialogComponent } from './import-email-dialog/import-email-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [AssignMailComponent, ImportEmailDialogComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        TableTaskModule,
        DragDropModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [AssignMailComponent]
})
export class AssignMailModule {}
