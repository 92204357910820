export const locale = {
    lang: 'en',
    data: {
        PROJECTEVALUATION: {
            EVALUATION: 'Projektliste',
            FILTER: {
                LOCATION: "Location",
                UNTIL_YEAR: "Year",
                YEAR: "Year",
                TEAM: "Team",
                MONTH: {
                    UNTIL: "Month",
                    NO: 'Nein',
                    1: "January",
                    2: "February",
                    3: "March",
                    4: "April",
                    5: "May",
                    6: "June",
                    7: "July",
                    8: "August",
                    9: "September",
                    10: "October",
                    11: "November",
                    12: "December",
                },
                QUARTER: {
                    TITLE: 'Quarter',
                    1: 'Quarter 1',
                    2: 'Quarter 2',
                    3: 'Quarter 3',
                    4: 'Quarter 4'
                },
                ERRORMESSAGE: {
                    YEARS:
                        'The value for "from (Year)" has to be less than or equal to the value of "until (Year)"!',
                    FOURDIGITS: "The values for the years must have 4 digits!",
                    NOTVALID: "The specified period is not valid!",
                },
                HIDECLOSEDPORJECTS: "Abgeschlossene Projekte ausblenden",
                HIDE999PROJECTS: "999-Projekte ausblenden"
            },

            HEADER: {
                LOADINGCOLUMNS: 'Please, wait. Kostenrolle columns are still loading. Otherwise they will not be visible in the report'
            },
            COLUMNS: {
                TITLE: 'Title',
                PROJECTSUM: 'Projektsumme ',
                BUDGET: 'Interne Auftragssumme',
                BUDGET_STUD: 'Interne Auftragssumme (in Stunden)',
                BUDGET_TAG: 'Interne Auftragssumme (in Tagen)',
                TOTLALSUM: 'Auftragssumme',
                TOTALBUDGET: 'Gesamt Budget',
                START: 'Start',
                END: 'Ende',
                STATUS: 'Status',
                CUSTOMER: 'Auftraggeber / Ansprechpartner',
                ADDITIONAL_PER: 'zzgl. Nebenkosten (%)',
                ADDITIONAL_EURO: 'zzgl. Nebenkosten (Euro)',
                EXTERNAL: 'Davon Fremdleistung (Euro)',
                TEAM: 'Team ',
                OW_NUMBER: 'OW-Nummer',
                PROJECTNUMBER: 'Projektnummer',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                EXPECTEDPROFITMARGIN: "erwartete Gewinnmarge (in %)",
                WORKLIST: 'Arbeitsvorrat',
                GEWINN_EURO: 'Gewinn (in €)',
                GEWINN_PERCENT: 'Gewinn (in %)'
            }
        }
    },
};
