import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { from } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormioComponent } from '@formio/angular';
import { DataResult, State, process } from '@progress/kendo-data-query';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CellClickEvent, CellCloseEvent, GridComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-detail-controlling',
    templateUrl: './detail-controlling.component.html',
    styleUrls: ['./detail-controlling.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DetailControllingComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public refreshForm: EventEmitter<any> = new EventEmitter();
    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;

    
    @ViewChild('kendoGridComponent', { static: false })
    kendoGridComponent: GridComponent;
    private gridData: {
        'team': {
            'id': number;
            'title': string;
        };
        'targetValue'?: number,
        'sollZahlenItemId'?: number
    }[] = [];
    private sollZahlenListItems: any[] = [];
    public tableConfig: any;
    public state: State = {};
    public gridView: DataResult;

    public id: number;
    public itemTitle = '';
    public sharePointItem;
    public formioConfiguration;
    public triggerChange = false;
    public firstTimeLoad = true;
    public author;
    public createDate;

    public disableMark = false;
    public isCreateMode = true;
    public header;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DetailControllingComponent>,
        public _applicationService: ApplicationService,
        private _sharepointService: SharePointService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        public formBuilder: UntypedFormBuilder
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    async ngOnInit() {
        if (this.data.id) {
            this.id = this.data.id;
        }

        if (this.id) {
            // edit mode
            this.isCreateMode = false;

            this._sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'SollZahlen',
                })
                .then((setPoints) => {
                    this.sharePointItem = setPoints;
                    this.initGrid();
                })
                .catch((error) => console.error(error));
        } else {
            // create mode
            this.initGrid();

            const currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let currentMonth = this._applicationService.convertMonthValue(currentDate.getMonth() + 1) as string;
            this.sollZahlenListItems = await this.loadSollZahlenEntries(currentYear, currentMonth);
            this.initTable();
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
            dataForm.Monat = this._applicationService.convertMonthValue(
                this.sharePointItem.Monat
            );
        } else {
            dataForm = emptyObj;
            const currentDate = new Date();
            dataForm.Jahr = currentDate.getFullYear();
            let month: number | string = currentDate.getMonth() + 1;
            month = this._applicationService.convertMonthValue(month);

            dataForm.Monat = month;
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: { data: dataForm },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('NOTIFICATIONS.SAVING'),
            this._translateService.instant('NOTIFICATIONS.WAIT')
        );
        e.data.Monat = this._applicationService.convertMonthValue(e.data.Monat);

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        this._sharepointService
            .getListItems({
                title: 'Teams',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .EqualTo(e.data.TeamId)
                    .OrderBy('Title')
                    .ToString(),
            }).then(async (result) => {
                try {
                    e.data.Title = result[0].Title + '-' + (e.data.Jahr * 100 + e.data.Monat).toString();

                    if (this.sharePointItem) {
                        await this._sharepointService
                            .updateItem({
                                id: this.sharePointItem.Id,
                                listTitle: 'SollZahlen',
                                newFiledValues: e.data,
                            });

                        const item = await this._sharepointService
                            .getItemById({
                                listTitle: 'SollZahlen',
                                id: this.id,
                            });

                        this.sharePointItem = item;
                        this._loadingService.hide();
                        this._snackBarService.add(
                            this._translateService.instant(
                                'SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close({
                            Success: true,
                            ItemId: this.id,
                        });
                    } else {
                        // Create Mode
                        await this.saveCreation();
                    }
                } catch (e) {
                    this._loadingService.hide();
                    console.error(e);
                }
            });
    }

    public async saveCreation() {
        try {
            await new Promise(resolve => setTimeout(resolve, 500)); // wait for 500 ms because we may need to wait for last cellCloseHandler

            const itemIds = [];
            for (const [index, item] of (this.kendoGridComponent.data as any).data.entries()) {
                if (!Number.isInteger(Number.parseInt(item.targetValue)))
                    continue;
                    
                if (item.sollZahlenItemId) {
                    // update
    
                    await this._sharepointService
                        .updateItem({
                            id: item.sollZahlenItemId,
                            listTitle: 'SollZahlen',
                            newFiledValues: {
                                'Soll_Wert': Number.parseInt(item.targetValue)
                            }
                        });

                    itemIds.push(item.sollZahlenItemId);
                } else {
                    // create
                    const newItemId = await this._sharepointService
                        .addItem({
                            listTitle: 'SollZahlen',
                            data: {
                                'Jahr': this.formioComponent.formio._data.Jahr,
                                'Monat': this._applicationService.convertMonthValue(this.formioComponent.formio._data.Monat),
                                'Soll_Wert': Number.parseInt(item.targetValue),
                                'TeamId': item.teamId,
                                'Title': item.teamTitle + '-' + (this.formioComponent.formio._data.Jahr * 100 + (this._applicationService.convertMonthValue(this.formioComponent.formio._data.Monat) as number)).toString()
                            },
                            folder: '',
                        });
    
                    itemIds.push(newItemId);
                }
    
                // const item = await this._sharepointService
                //     .getItemById({
                //         listTitle: 'SollZahlen',
                //         id: +newItemId,
                //     });
            }

            // Success
            this._loadingService.hide();
            this._snackBarService.add(
                this._translateService.instant(
                    'SUCCESS'
                ),
                '',
                {
                    duration: 4000,
                    panelClass:
                        'success-dialog',
                }
            );
            this.close({
                Success: true,
                ItemId: null,
                ItemIds: itemIds
            });
        } catch (e) {
            this._loadingService.hide();
            console.error(e);
            throw e;
        }
    }

    initGrid() {
        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant('FORM.TEAM'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'TeamId',
                    selectThreshold: 0.3,
                    list: 'Teams',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    isCreateMode: this.isCreateMode,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: !this.isCreateMode,
                    },
                    hidden: this.isCreateMode
                },
                {
                    label: this._translateService.instant('FORM.MONTH'),
                    hideLabel: false,
                    placeholder: '',
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'select',
                    input: true,
                    key: 'Monat',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'MONTH.JANUARY'
                                ),
                                value: 'Januar',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.FEBRUARY'
                                ),
                                value: 'Februar',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.MARCH'
                                ),
                                value: 'März',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.APRIL'
                                ),
                                value: 'April',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.MAY'
                                ),
                                value: 'Mai',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.JUNE'
                                ),
                                value: 'Juni',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.JULY'
                                ),
                                value: 'Juli',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.AUGUST'
                                ),
                                value: 'August',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.SEPTEMBER'
                                ),
                                value: 'September',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.OCTOBER'
                                ),
                                value: 'Oktober',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.NOVEMBER'
                                ),
                                value: 'November',
                            },
                            {
                                label: this._translateService.instant(
                                    'MONTH.DECEMBER'
                                ),
                                value: 'Dezember',
                            },
                        ],
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    lazyLoad: false,
                },
                {
                    label: this._translateService.instant('FORM.YEAR'),
                    type: 'number',
                    key: 'Jahr',
                    inputFormat: 'plain',
                    validateOn: 'blur',
                    validate: {
                        customMessage:
                            '{{field}} ist ein Pflichtfeld mit vierstelligem Wert',
                        required: true,
                        min: 1000,
                        max: 9999,
                    },
                },
                {
                    label: this._translateService.instant('FORM.VALUE'),
                    type: 'number',
                    key: 'Soll_Wert',
                    inputFormat: 'plain',
                    validateOn: 'blur',
                    hidden: this.isCreateMode
                },
                {
                    label: this._translateService.instant('SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                    hidden: this.isCreateMode
                },
            ],
        };
    }

    private async initTable() {
        this.tableConfig = {
            'columns': [
                { internalName: 'teamId', title: 'Team Id', hidden: true, editable: false },
                { internalName: 'teamTitle', title: 'Team', hidden: false, editable: false },
                { internalName: 'targetValue', title: 'Soll-Wert', hidden: false, editable: true }
            ]
        };

        const teamsListItems: any[] = await this._sharepointService.getListItems({
            title: 'Teams',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString()
        });

        this.gridData = [];
        teamsListItems.forEach(elem => {
            this.gridData.push({
                team: {
                    id: elem.Id,
                    title: elem.Title
                },
                targetValue: this.sollZahlenListItems.find(x => x.TeamId === elem.Id)?.Soll_Wert,
                sollZahlenItemId: this.sollZahlenListItems.find(x => x.TeamId === elem.Id)?.Id
            });
        });

        const data = this.gridData.map(x => {
            return {
                'teamId': x.team.id,
                'teamTitle': x.team.title,
                'targetValue': x.targetValue,
                'sollZahlenItemId': x.sollZahlenItemId
            }
        })


        this.gridView = process(data, this.state);
    }

    public cellClickHandler(args: CellClickEvent) {
        const { sender, rowIndex, columnIndex, dataItem, isEdited } = args;

        if (!isEdited && columnIndex == 1) {
            const formGroup = this.createFormGroup(dataItem);
            sender.editCell(rowIndex, columnIndex, formGroup);
        }
    }

    public cellCloseHandler(args: CellCloseEvent) {
        const { formGroup, dataItem, column } = args;

        if (!formGroup.valid) {
            // prevent closing the edited cell if there are invalid values.
            args.preventDefault();
        } else if (formGroup.dirty) {
            // formGroup.value.keyabc
            Object.assign(dataItem, formGroup.value);
        }
    }
    
    public cellSaveHandler(grid: GridComponent): void {
        grid.closeCell();
    }

    public createFormGroup(dataItem: any): UntypedFormGroup {
        return this.formBuilder.group({
            'teamId': [dataItem.teamId],
            'targetValue': [dataItem.targetValue],
        });
    }

    async onChange(ev) {
        if (ev.changed) {

            // Get formvalues
            const data = JSON.parse(JSON.stringify(ev.data));

            if (this.isCreateMode) {
                if (
                    ev.changed.component.key === 'Monat' ||
                    ev.changed.component.key === 'Jahr'
                ) {
                    const year: number = ev.data.Jahr;
                    const month: string = ev.data.Monat;
                    this.sollZahlenListItems = await this.loadSollZahlenEntries(year, month);

                    this.gridData.forEach(elem => {
                        elem.targetValue = this.sollZahlenListItems.find(x => x.TeamId === elem.team.id)?.Soll_Wert;
                        elem.sollZahlenItemId = this.sollZahlenListItems.find(x => x.TeamId === elem.team.id)?.Id;
                    });
                    const data = this.gridData.map(x => {
                        return {
                            'teamId': x.team.id,
                            'teamTitle': x.team.title,
                            'targetValue': x.targetValue,
                            'sollZahlenItemId': x.sollZahlenItemId
                        }
                    });
                    this.gridView = process(data, this.state);
                }
            }
        }
    }

    async loadSollZahlenEntries(year: number, month: string): Promise<any[]> {
        const monthNumeric: number = this._applicationService.convertMonthValue(month) as number;
        const spSollZahlenListItems = await this._sharepointService
            .getListItems({
                title: 'SollZahlen',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .And()
                    .NumberField('Jahr')
                    .EqualTo(year)
                    .And()
                    .NumberField('Monat')
                    .EqualTo(monthNumeric)
                    .OrderBy('Title')
                    .ToString(),
            });
        console.error('spSollZahlenListItems', spSollZahlenListItems);
        return spSollZahlenListItems;
    }
}
