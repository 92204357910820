export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Tasks',
        KANBAN: 'Kanban',
        PROJECTNUMBER: 'Projectnumber',
        NOTES: 'Notes',
        TABS: {
            WORKPACKAGES: 'Workpackages',
            TASKS: 'Tasks',
        },
        BUTTONS: {
            NEW: 'Add new workpackage',
            NEWTASK: 'Add new task',
            MY: 'My workpackages',
            MYTASK: 'My tasks',
            CRITICAL: 'Critical workpackages',
            CRITICALTASK: 'Critical tasks',
            CLEARFILTER: 'Clear filter',
        },
        COLUMNS: {
            TITLE: 'Workpackage',
            DUEDATE: 'Duedate',
            STATUS: 'Status',
            TASK: 'Task',
            ASSIGNED: 'Assigned to',
            ANSPRECHPARTNER: 'Ansprechpartner'
        },
    },
};
