import { NgModule } from '@angular/core';
import { KanbanAssignmentComponent } from './kanban-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { KanbanModule } from '../../kanban/kanban.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

const routes = [
    {
        path: 'projects/detail/kanban/:id',
        component: KanbanAssignmentComponent,
    },
];

@NgModule({
    declarations: [KanbanAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        FuseSidebarModule,
        MatCardModule,
        KanbanModule,
    ],
    exports: [KanbanAssignmentComponent],
})
export class KanbanAssignmentModule {}
