export const locale = {
    lang: 'de',
    data: {
        TABLE_TASKSET: {
            TITLE: 'Aufgabensets',
            NEWTASKSET: 'Aufgaben erstellen',
            SAVE: 'Speichern',
            COLUMNS: {
                TITLE: 'Bezeichnung',
            },
            NOTIFICATIONS: {
                CREATE: 'Aufgaben werden erstellt...',
                WAIT: 'Bitte warten.',
            },
        },
    },
};
