import { NgModule } from '@angular/core';
import { FilterBillsComponent } from './filter-bills.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';

@NgModule({
    declarations: [FilterBillsComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
    ],
    exports: [FilterBillsComponent],
})
export class FilterBillsModule {}
