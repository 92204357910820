export const locale = {
    lang: 'de',
    data: {
        DETAIL_TASK: {
            TITLE: 'Aufgaben',
            SEARCH_LABEL: 'Nach einer Aufgabe suchen',
            ALL: 'Alle Aufgaben',
            MY: 'Meine Aufgaben',
            CRITICAL: 'Kritische Aufgaben',
            OVERDUEDATE: 'Überfällige Aufgaben',
            FILTER_EMAILS: 'Mails filtern',
            DIALOG: {
                PERMISSION: 'Wollen Sie dieses Element wirklich löschen?',
                SUCCESS:
                    'Der ausgewählte Zeiteintrag wurde erfolgreich gelöscht',
                ERROR: 'Beim Löschen ist ein Fehler aufgtetreten!',
            },
            COLUMNS: {
                TITLE: 'Titel',
                DUEDATE: 'Fälligkeitsdatum',
                STATUS: 'Status',
                TASK: 'Aufgabe',
                ASSIGNED: 'Zugewiesen an',
                PROJECT: 'Projekt',
                DESCRIPTION: 'Beschreibung',
                KIND: 'Aufgabenart',
                WORKPACKAGE: 'Arbeitspaket',
                START: 'Start',
                PRIORITY: 'Priorität',
                HIGH: '(1) Hoch',
                NORMAL: '(2) Normal',
                LOW: '(3) Niedrig',
                NOTSTARTED: 'Nicht begonnen',
                INPROCESSING: 'In Bearbeitung',
                COMPLETED: 'Abgeschlossen',
                RESET: 'Zurückgestellt',
                WAITING: 'Wartet auf jemand anderen',
                TYPE: 'Aufgabentyp',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                PROCESSSTEP: 'Prozessschritt',
                ANSPRECHPARTNER: 'Ansprechpartner',
            },
            TABS: {
                BASE: 'Stammdaten',
                TASKS: 'Aufgaben',
                DOCS: 'Dokumente',
                TIMES: 'Zeiten',
                EMAILS: {
                    TITLE: 'E-Mails',
                    IN: 'Empfangen',
                    OUT: 'Gesendet',
                    SEND: 'Neue E-Mail',
                    FILTER_EMAILS: 'Mails filtern',
                    RE: 'Antworten',
                    REALL: 'Alle antworten',
                    FORWARD: 'Weiterleiten',
                    INDEX: 'E-Mails zuordnen',
                    ASSIGN: 'E-Mails einlesen',
                },
            },
            NEWTASK: 'Neue Aufgabe',
            EDITTASK: 'Aufgabe bearbeiten',
            NEW: 'Neues Arbeitspaket',
            EDIT: 'Arbeitspaket bearbeiten',
            SAVE: 'Speichern',
            TASKSETS: 'Aufgabensets',
            GIVEUSERPERMISSIONTOPROJECT:
                // tslint:disable-next-line: max-line-length
                'Der ausgewählte User besitzt keine Berechtigungen auf das aktuelle Projekt. Sollen diesem Bearbeitungsberechtigungen gegeben werden?',
            NOTIFICATIONS: {
                SAVINGITEM: 'Aufgabe/Arbeitspaket wird gespeichert...',
                SAVING: 'Berechtigungen werden gesetzt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Berechtigungen erfolgreich gespeichert.',
                SUCCESSITEM: 'Aufgabe/Arbeitspaket erfolgreich gespeichert.',
                ERROR: 'Fehler beim setzen der Berechtigungen: ',
                DUEDATEERROR:
                    'Das Fälligkeitsdatum darf das Enddatum des Projekts nicht überschreiten.',
                DUEDATEERRORTASK:
                    'Das Fälligkeitsdatum darf das Enddatum des Arbeitspakets nicht überschreiten.',
                STARTDATEERROR:
                    'Das Startdatum darf nicht vor dem Startdatum des Projekts liegen.',
                STARTDATEERRORTASK:
                    'Das Startdatum darf nicht vor dem Startdatum des Arbeitspakets liegen.',
                STARTDUEDATEERROR:
                    'Das Startdatum darf das Fälligkeitsdatum nicht überschreiten.',
                DUEDATEHINT: 'Nicht später als: ',
                STARTDATEHINT: 'Nicht früher als: ',
                SUCCESS_DELETE: 'Datei/Ordner wurde erfolgreich gelöscht.',
            },
            DOCS: {
                TITLE: 'Aufgabendokumente',
                NEW: 'Dokument hochladen',
                MOVE: 'Element verschieben',
                NEWFOLDER: 'Neuer Ordner',
                CREATE_DOCUMENT: 'Aus Vorlage erstellen',
                COLUMNS: {
                    TITLE: 'Titel',
                    FILESIZE: 'Dateigröße',
                    AUTHOR: 'Ersteller',
                    MODIFIED: 'Geändert',
                },
            },
        },
    },
};
