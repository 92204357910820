export const locale = {
    lang: 'de',
    data: {
        TITLE: 'Aufgabenvorlagen',
        COLUMNS: {
            TITLE: 'Name',
            DESCRIPTION: 'Beschreibung',
            OFFSETSTART: 'Addition zum Anfangsdatum',
            INDAYS: '(in Tagen)',
            OFFSETEND: 'Addition zum Fälligkeitsdatum',
            PRIORITY: 'Priorität',
            HIGH: '(1) Hoch',
            NORMAL: '(2) Normal',
            LOW: '(3) Niedrig',
            PROCESSSTEP: 'Prozessschritt',
            TYPE: 'Aufgabentyp',
            SCHEDULEDTIME: 'Geschätzte Arbeitszeit',
            INHOURS: '(in Tagen)',
            OFFSETSTARTTYPE: 'Typ der Addition zum Anfangsdatum',
            OFFSETENDTYPE: 'Typ der Addition zum Fälligkeitsdatum',
            TODAY: 'Ab Heute',
            START: 'Ab Beginn Arbeitspaket',
            END: 'Ab Ende Arbeitspaket',
            STARTASSIGNMENT: 'Ab Beginn Projekt',
            ENDASSIGNMENT: 'Ab Ende Projekt',
        },
        NEW: 'Neue Aufgabenvorlage',
        EDIT: 'Aufgabenvorlage bearbeiten',
        SAVE: 'Speichern',
        NOTIFICATIONS: {
            SAVING: 'Aufgabenvorlage wird gespeichert...',
            WAIT: 'Bitte warten.',
            SUCCESS: 'Aufgabenvorlage erfolgreich gespeichert.',
            ERROR: 'Fehler beim speichern: ',
        },
        TABS: {
            BASE: 'Stammdaten',
            DOCS: 'Dokumente',
        },
        DOCS: {
            TITLE: 'Aufgabendokumente',
            NEW: 'Dokument hochladen',
            NEWFOLDER: 'Neuer Ordner',
            COLUMNS: {
                TITLE: 'Titel',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
            },
        },
    },
};
