import {
    Component,
    OnInit,
    ViewEncapsulation,
    EventEmitter,
    Input,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import {
    TableConfiguration,
    SharePointService,
    ColumnTyp,
    FormatTypes,
    TableColumn,
    SharePointTableComponent,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { DetailTasksetComponent } from '../../taskset/detail-taskset/detail-taskset.component';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ApplicationService } from '../../services/application.service';
import { from } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DocumentsAssignmentService } from '../../assignment/documents-assignment/documents-assignment.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-detail-category',
    templateUrl: './detail-category.component.html',
    styleUrls: ['./detail-category.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailCategoryComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public refreshForm: EventEmitter<any> = new EventEmitter();

    @Input() id: number;

    @ViewChild('documentGrid', { static: false })
    documentGrid: SharePointTableComponent;
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    public tableConfigDocs: TableConfiguration;
    private columns: TableColumn[];
    public formioConfiguration;
    public sharePointItem;

    private toolbarArray = [];

    get camlQueryTasksTemplates() {
        return new CamlBuilder()
            .Where()
            .LookupField('Kategorie')
            .Id()
            .EqualTo(this.id)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<DetailTasksetComponent>,
        public _documentService: DocumentsAssignmentService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _applicationService: ApplicationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.toolbarArray = [
            {
                title: this._translateService.instant(
                    'DETAIL_CATEGORY.NEWDOCUMENT'
                ),
                command: 'Add',
                color: 'accent',
            },
        ];
    }

    ngOnInit() {
        if (this.data) {
            this.id = this.data.categoryId;
        }

        if (this.id) {
            this._sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Auftragskategorien',
                })
                .then((category) => {
                    this.sharePointItem = category;

                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_CATEGORY.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('DETAIL_CATEGORY.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            console.log(e.data);
            return from(
                this._sharepointService
                    .updateItem({
                        id: this.sharePointItem.Id,
                        listTitle: 'Auftragskategorien',
                        newFiledValues: e.data,
                    })
                    .then(
                        () => {
                            this._sharepointService
                                .getItemById({
                                    listTitle: 'Auftragskategorien',
                                    id: this.id,
                                })
                                .then(
                                    () => {
                                        this._loadingService.hide();

                                        this._snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_CATEGORY.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close('success');
                                    },
                                    (error) => {
                                        this._loadingService.hide();
                                        console.log(error);
                                    }
                                );
                        },
                        (error) => {
                            this._loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        } else {
            console.log(e.data);
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Auftragskategorien',
                        data: e.data,
                        folder: '',
                    })
                    .then(
                        () => {
                            this._loadingService.hide();

                            this._snackBarService.add(
                                this._translateService.instant(
                                    'DETAIL_CATEGORY.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this._loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        }
    }

    close(message?): void {
        this.matDialogRef.close(message);
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises).then(
            () =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                }),
            (error) => {
                console.error(error);
            }
        );
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.fileInput.nativeElement.click();
                break;
        }
    }

    onChange(ev) {}

    onTabChange(ec) {}

    initForm() {
        if (this.id) {
            this.columns = [
                {
                    internalName: 'FileRef',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.DocIcon,
                    width: '80',
                },
                {
                    internalName: 'FileRef',
                    title: this._translateService.instant(
                        'DETAIL_CATEGORY.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.OpenFileForEditInClient,
                },
                {
                    internalName: 'File_x0020_Size',
                    title: this._translateService.instant(
                        'DETAIL_CATEGORY.COLUMNS.FILESIZE'
                    ),
                    hidden: false,
                    type: ColumnTyp.FileSize,
                },
                {
                    internalName: 'Author',
                    title: this._translateService.instant(
                        'DETAIL_CATEGORY.COLUMNS.AUTHOR'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'Modified',
                    title: this._translateService.instant(
                        'DETAIL_CATEGORY.COLUMNS.MODIFIED'
                    ),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMddHH,
                },
                {
                    internalName: 'Standardberechtigung',
                    title: '',
                    hidden: true,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ];

            this.tableConfigDocs = {
                list: 'Kategoriedokumente',
                columns: this.columns,
                isDocumentLibrary: true,
                showEditButton: !this.data.readOnly,
                showDeleteButton: !this.data.readOnly,
                addCheckboxColumn: false,
                recursiveAll: true,
                camlQuery: this.camlQueryTasksTemplates,
                useMaterialDesign: true,
                toolbar: this.toolbarArray,
            };
        }

        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_CATEGORY.COLUMNS.TITLE'
                    ),
                    hideLabel: false,
                    disabled: this.data.readOnly,
                    placeholder: '',
                    allowMultipleMasks: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    defaultValue: '',
                    validate: {
                        required: true,
                        customMessage: '',
                        json: '',
                    },
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CATEGORY.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
