import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';
import { tap } from 'rxjs/operators';
import { AttachmentFile, Bill } from '../shared/models';

@Injectable({
    providedIn: 'root',
})
export class BillingsService {
    public attachments: Record<number, AttachmentFile[]> = {};
    public totalBillsSum = 0;

    constructor(private _sharePointService: SharePointService) {}

    public getBillingsAttachmentsByProject(
        projectId: number
    ): Observable<Bill[]> {
        return from(
            this._sharePointService.web.lists
                .getByTitle('Rechnungen')
                .items // .filter(`FileDirRef eq '${this._sharePointService.computeServerRelativeUrl(this._sharePointService.sphostUrl)}/Lists/Rechnungen/${projectId}'`)
                .filter(`Projekt/ID eq ${projectId}`)
                .select(
                    'Title',
                    'Id',
                    'FileDirRef',
                    'Forderung',
                    'AttachmentFiles',
                    'Projekt/ID'
                )
                .expand('AttachmentFiles', 'Projekt')()
        ).pipe(
            tap((bills: Bill[]) => {
                this.totalBillsSum = 0;
                bills.forEach((bill) => {
                    this.attachments[bill.Id] = bill.AttachmentFiles;
                    this.totalBillsSum = this.totalBillsSum + bill.Forderung;
                });
            })
        );
    }
}
