import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import { splitArrayIntoChunks } from '../../../shared/shared.util';

export class UpdateToVersion1153Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const antrageList =
                await this._sharePointService.web.lists.getByTitle('Anträge');
            const tasksList =
                await this._sharePointService.web.lists.getByTitle(
                    'Auftragsaufgaben'
                );
            await this._updateService.addField(
                antrageList,
                'Old_Id',
                FieldType.Number
            );
            await this._updateService.addField(
                tasksList,
                'Old_Id',
                FieldType.Number
            );
            await this.copyIdToOldId('Anträge');
            await this.copyIdToOldId('Auftragsaufgaben');

            return;
        } catch (error) {
            throw new Error(error);
        }
    }

    private async copyIdToOldId(listTitle: string): Promise<void> {
        // const list = await this._sharePointService.web.lists.getByTitle(listTitle);
        // let items = await list.items.top(5000).getAll();
        // items = items.filter(assignment => !assignment.FileSystemObjectType);
        // console.warn(items);
        // const chunks = splitArrayIntoChunks(items, 1000);
        // for (let i = 0; i < chunks.length; i++) {
        //     let batch = this._sharePointService.web.createBatch();
        //     let batchItems = list.items.inBatch(batch);
        //     let chunkItems = chunks[i];
        //     for (let j = 0; j < chunkItems.length; j++) {
        //         let assignment = chunkItems[j];
        //         batchItems.getById(assignment.Id).inBatch(batch).update({ Old_Id: assignment.ID });
        //     }
        //     await batch.execute();
        // }
    }
}
