export const PROJECT_EVALUATION_FILTER_MONTHS: { label: string, value: number }[] = [
    {
        label: ' ',
        value: -1,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.1',
        value: 1,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.2',
        value: 2,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.3',
        value: 3,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.4',
        value: 4,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.5',
        value: 5,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.6',
        value: 6,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.7',
        value: 7,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.8',
        value: 8,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.9',
        value: 9,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.10',
        value: 10,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.11',
        value: 11,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.MONTH.12',
        value: 12,
    },
];

export const PROJECT_EVALUATION_FILTER_QUARTERS: { label: string, value: number }[] = [
    {
        label: ' ',
        value: -1,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.QUARTER.1',
        value: 1,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.QUARTER.2',
        value: 2,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.QUARTER.3',
        value: 3,
    },
    {
        label: 'PROJECTEVALUATION.FILTER.QUARTER.4',
        value: 4,
    }
];

export const QUARTER_DATES_RANGE: Record<string, { startMonth: number, endMonth: number }> = {
    1: {
        startMonth: 0,
        endMonth: 2
    },
    2: {
        startMonth: 3,
        endMonth: 5
    },
    3: {
        startMonth: 6,
        endMonth: 8
    },
    4: {
        startMonth: 9,
        endMonth: 11
    }
};

export const PROJECT_EVALUATION_HEADER_FORMAT = {
    color: '#ffffff',
    bold: true,
    textAlign: 'center',
    verticalAlign: 'center',
    background: '#64b144',
    borderLeft: { size: 1, color: '#ffffff' },
    borderRight: { size: 1, color: '#ffffff' },
    borderTop: { size: 1, color: '#ffffff' },
    borderBottom: { size: 1, color: '#ffffff' },
    wrap: true
};

export const PROJECT_EVALUATION_SUB_HEADER_FORMAT = {
    ...PROJECT_EVALUATION_HEADER_FORMAT,
    background: '#31869b',
};

export const MAP_PROJECT_STATUS = {
    'Auftrag angelegt': 'PROJECTEVALUATION.COLUMNS.CREATED',
    'Auftrag in Arbeit': 'PROJECTEVALUATION.COLUMNS.PROGRESS',
    'Auftrag gestoppt': 'PROJECTEVALUATION.COLUMNS.STOPPED',
    'Auftrag abgeschlossen': 'PROJECTEVALUATION.COLUMNS.COMPLETED',
};

export const PROJECT_EVALUATION_DEFAULT_BUDGET = {
    Antragssumme: 0,
    Nebenkosten: 0,
    Fremdleistung: 0,
    NebenkostenEuro: 0,
    Auftragsbudget: 0
};
export const PROJECT_EVALUATION_DEFAULT_BILL = { Internal: 0, External: 0, Total: 0 };
