<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id && this.isTask" class="title dialog-title">{{
      'DETAIL_TASK.EDITTASK' | translate
      }}</span>
    <span *ngIf="!this.id && this.isTask" class="title dialog-title">{{
      'DETAIL_TASK.NEWTASK' | translate
      }}</span>
    <span *ngIf="this.id && !this.isTask" class="title dialog-title">{{
      'DETAIL_TASK.EDIT' | translate
      }}</span>
    <span *ngIf="!this.id && !this.isTask" class="title dialog-title">{{
      'DETAIL_TASK.NEW' | translate
      }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id="content">
  <mat-tab-group #rootTab
                 dynamicHeight="true"
                 [class.mails-mode]="mailsSectionSelected"
                 (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab class="tab" label="{{ 'DETAIL_TASK.TABS.BASE' | translate }}">
      <formio *ngIf="rootTab.selectedIndex === 0" class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
        [renderOptions]="formio_renderOptions"
        (change)="onChange($event)">
      </formio>
    </mat-tab>

    <mat-tab class="tab" label="{{ 'DETAIL_TASK.TABS.TASKS' | translate }}" *ngIf="!this.isTask && this.id">
      <app-table-task #taskTable  [configuration]="tableConfigTasks" [paketId]="this.id" [auftragsId]="auftragsId"
        [isTask]="true">
      </app-table-task>
    </mat-tab>

    <mat-tab *ngIf="this.id" class="tab" label="{{ this.documentTabHeader }}">
      <input #fileInput
             type="file"
             style="display: none"
             [multiple]="uploadMultipleDocuments"
             [accept]="_documentService.acceptedFileExtensions"
             (change)="onSelectNewDocumentFile($event)" />

      <app-sharepoint-table *ngIf="tableConfig"
                            #documentGrid
                            class="documents-table"
                            [configuration]="tableConfig"
                            (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
      >
        <ng-template
            customCell
            for="CopyColumn"
            let-dataItem="dataItem"
        >
          <button
              *ngIf="!dataItem.FileSystemObjectType"
              mat-icon-button
              (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.copy)"
              matTooltip="Copy file"
          >
            <mat-icon>file_copy</mat-icon>
          </button>
        </ng-template>
        <ng-template
            customCell
            for="RenameColumn"
            let-dataItem="dataItem"
        >
          <button
              *ngIf="!dataItem.FileSystemObjectType"
              mat-icon-button
              (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.rename)"
              matTooltip="Rename file"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </ng-template>
        <ng-template customCell for="FileSystemObjectType" let-dataItem="dataItem">
          <button *ngIf="dataItem.FileSystemObjectType == 0" mat-icon-button
            (click)="moveElement({ dataItem: dataItem })" matTooltip="Bearbeiten">
            <mat-icon>compare_arrows</mat-icon>
          </button>
        </ng-template>
      </app-sharepoint-table>
    </mat-tab>

    <mat-tab *ngIf="this.id && this.timesEnabled" class="tab" label="{{ 'DETAIL_TASK.TABS.TIMES' | translate }}">
      <app-new-time #newTimeComponent *ngIf="this.assignmentItem && this.sharePointItem && (rootTab.selectedIndex === 3 && !isTask || rootTab.selectedIndex === 2 && isTask)"
        [projectItem]="this.assignmentItem" [taskItem]="this.sharePointItem">
      </app-new-time>

      <app-sharepoint-table #timeGrid class="times-table" *ngIf="this.timeConfig" [configuration]="this.timeConfig"
        (onTooolbarButtonClick)="onTooolbarButtonClick($event)" (cellClick)="openDialog($event, true)">
        <ng-template customCell for="Minuten" let-dataItem="dataItem">
          {{ (dataItem.Minuten / 60).toFixed(2) }}
        </ng-template>
      </app-sharepoint-table>
    </mat-tab>

    <mat-tab *ngIf="this.id" class="tab" label="{{ 'DETAIL_TASK.TABS.EMAILS.TITLE' | translate }}">
      <mat-tab-group #matTabGroup dynamicHeight="true" class="mails-tab" (selectedTabChange)="onTabChangeMail($event)">
        <mat-tab class="tab" label="{{ 'DETAIL_TASK.TABS.EMAILS.IN' | translate }}">
          <app-filter-mail (filter)="filterEmail($event)"></app-filter-mail>
          <mat-card appearance="outlined" class="mat-card">
            <app-resizable-box>
              <mail-list #mailIn *ngIf="this.mailConfigIn" [keyBoardNavigation]="true" [config]="this.mailConfigIn" leftSide>
              </mail-list>

              <mail-details *ngIf="this.mailConfigIn" [showDetails]="true" [trustEmail]="true" rightSide></mail-details>
            </app-resizable-box>

          </mat-card>
        </mat-tab>

        <mat-tab class="tab" label="{{ 'DETAIL_TASK.TABS.EMAILS.OUT' | translate }}">
          <app-filter-mail (filter)="filterEmail($event)"></app-filter-mail>
          <mat-card appearance="outlined" class="mat-card">
            <app-resizable-box>
              <mail-list #mailOut *ngIf="this.mailConfigOut" [keyBoardNavigation]="true" [config]="this.mailConfigOut" leftSide>
              </mail-list>

              <mail-details *ngIf="this.mailConfigOut" [showDetails]="true" [trustEmail]="true" rightSide></mail-details>
            </app-resizable-box>
          </mat-card>
        </mat-tab>
      </mat-tab-group>

      <button mat-raised-button class="newButton" color="accent" (click)="send()">
        <mat-icon>mail</mat-icon>
        {{ 'DETAIL_TASK.TABS.EMAILS.SEND' | translate }}
      </button>

      <button mat-raised-button class="newButton" color="accent" (click)="assign()">
        <mat-icon>mail</mat-icon>
        {{ "DETAIL_TASK.TABS.EMAILS.ASSIGN" | translate }}
      </button>

      <button mat-raised-button *ngIf="mailIn?.currentMail || mailOut?.currentMail" class="newButton" color="accent" (click)="mailRE(true)">
        <mat-icon>mail</mat-icon>
        {{ "DETAIL_TASK.TABS.EMAILS.REALL" | translate }}
      </button>

      <button mat-raised-button *ngIf="mailIn?.currentMail || mailOut?.currentMail" class="newButton" color="accent"
        (click)="mailRE()">
        <mat-icon>mail</mat-icon>
        {{ 'DETAIL_TASK.TABS.EMAILS.RE' | translate }}
      </button>

      <button mat-raised-button *ngIf="mailIn?.currentMail || mailOut?.currentMail" class="newButton" color="accent" (click)="forwardTo()">
        <mat-icon>mail</mat-icon>
        {{ "DETAIL_TASK.TABS.EMAILS.FORWARD" | translate }}
      </button>

      <button mat-raised-button class="newButton" color="accent" (click)="index()">
        <mat-icon>mail</mat-icon>
        {{ 'DETAIL_TASK.TABS.EMAILS.INDEX' | translate }}
      </button>
    </mat-tab>
  </mat-tab-group>
</div>
