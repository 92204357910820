export class ClientUtil {
    static getClientSummary(client: { Title: string, Title2: string, Title3: string, Strasse: string, WorkZip: string, WorkCity: string }): string {
        const { Title, Title2, Title3, Strasse, WorkCity, WorkZip } = client;
        const data = [
            Title,
            Title2,
            Title3,
            Strasse,
            `${WorkZip} ${WorkCity}`,
        ].join('\n');

        let firmenbriefkopf = data.replace(/null/g, '').split('\n').filter(value => {
            if (value) {
                return !!(value?.trim());
            }

            return false;
        }).map(value => value.trim());

        const totalLines = 7;
        const amountOfEmptyLines = (totalLines - firmenbriefkopf.length);

        for (let i = 0; i < amountOfEmptyLines; i++) {
            firmenbriefkopf.push(' ');
        }

        return firmenbriefkopf.join('\n');
    }
}
