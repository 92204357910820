import { IList } from '@pnp/sp/lists';
import { FrameworkService } from 'sp-office365-layout';
import { FieldType, SharePointService, UpdateService } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { Bill } from '../../../shared/models';

export class UpdateToVersion1145Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _frameworkService: FrameworkService
    ) {
    }

    async run() {
        try {
            const billsList: IList = await this._sharePointService.web.lists.getByTitle('Rechnungen');
            // Add Field Rechnungsdatum to List Rechnungen
            await this._updateService.runFunctionIfFieldNotExists(
                billsList,
                'Rechnungsdatum',
                async () => await this._updateService.addField(billsList, 'Rechnungsdatum', FieldType.Date)
            );

            const billItems: Bill[] = await this._sharePointService.getListItems({
                title: 'Rechnungen',
                isDocumentLibrary: false,
                recursiveAll: true,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString()
            });

            for (let i = 0; i < billItems.length; i++) {
                if (billItems[i].Jahr && billItems[i].Monat) {
                    const rechnungsdatum = new Date(billItems[i].Jahr, billItems[i].Monat - 1, 1, 10);
                    await this._sharePointService.updateItem({
                        listTitle: 'Rechnungen',
                        id: billItems[i].Id,
                        newFiledValues: {
                            Rechnungsdatum: rechnungsdatum
                        }
                    });

                    if (i % 50 === 0) {
                        await this.delay();
                    }
                }
            }
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }

    private delay(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => resolve(null), 500);
        });
    }
}
