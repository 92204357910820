import { MailsOrderModule } from './main/order/mails-order/mails-order.module';
import { MailsRequestModule } from './main/request/mails-request/mails-request.module';
import { DetailProcessstepsModule } from './main/processsteps/detail-processsteps/detail-processsteps.module';
import { MoveElementModule } from './main/task/move-element/move-element.module';
import { OrdReqDocsModule } from './main/ord-req-docs/ord-req-docs.module';
import { ListviewRequestsModule } from './main/request/listview-requests/listview-requests.module';
import { DetailRequestModule } from './main/request/detail-request/detail-request.module';
import { FremdkostenModule } from './main/fremdkosten/fremdkosten.module';
import { EvaluationChartModule } from './main/evaluation/evaluation-chart/evaluation-chart.module';
import { ListviewEvaluationModule } from './main/evaluation/listview-evaluation/listview-evaluation.module';
import { FilterEvaluationModule } from './main/evaluation/filter-evaluation/filter-evaluation.module';
import { DetailPositionModule } from './main/position/detail-position/detail-position.module';
import { TableControllingModule } from './main/controlling/table-controlling/table-controlling.module';
import { FilterBillsModule } from './main/bills/filter-bills/filter-bills.module';
import { ListviewBillsModule } from './main/bills/listview-bills/listview-bills.module';
import { ListviewControllingModule } from './main/controlling/listview-controlling/listview-controlling.module';
import { DetailControllingModule } from './main/controlling/detail-controlling/detail-controlling.module';
import { ListviewOrderModule } from './main/order/listview-order/listview-order.module';
import { DetailOrderModule } from './main/order/detail-order/detail-order.module';
import { TableUserModule } from './main/user/table-user/table-user.module';
import { DetailGroupModule } from './main/group/detail-group/detail-group.module';
import { TimelineAssignmentModule } from './main/assignment/timeline-assignment/timeline-assignment.module';
import { ListviewWorkpackageModule } from './main/task/listview-workpackage/listview-workpackage.module';
import { FilterTimeModule } from './main/time/filter-time/filter-time.module';
import { ListviewReportModule } from './main/report/listview-report/listview-report.module';
import { DurationTimeModule } from './main/time/duration-time/duration-time.module';
import { NewTimeModule } from './main/time/new-time/new-time.module';
import { TimesAssignmentModule } from './main/assignment/times-assignment/times-assignment.module';
import { DetailCategoryModule } from './main/category/detail-category/detail-category.module';
import { DetailCustomerModule } from './main/customer/detail-customer/detail-customer.module';
import { DetailContactModule } from './main/contact/detail-contact/detail-contact.module';
import {
    NgModule,
    LOCALE_ID,
    DEFAULT_CURRENCY_CODE,
    APP_INITIALIZER,
    Injector,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
    AngularFrameworkModule,
    AuthTypes,
    Environment,
    init_sp_url_settings,
    SharePointService,
} from 'sp-office365-framework';

import { AppComponent } from './app.component';
import {
    FuseModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseProgressBarModule,
    LayoutModule,
    FuseConfigModule,
    AppConfig,
    registerFormioCustomComponents,
} from 'sp-office365-layout';

import { InstallationModule } from './installation/installation.module';
import { ListviewAssignmentModule } from './main/assignment/listview-assignment/listview-assignment.module';
import { ConfigurationModule } from './main/administration/configuration/configuration.module';
import { UpdateModule } from './main/administration/update/update.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { ListviewTaskModule } from './main/task/listview-task/listview-task.module';
import { HighlightModule } from './main/highlight/highlight.module';
import { EditAssignmentModule } from './main/assignment/edit-assignment/edit-assignment.module';
import { DocumentsAssignmentModule } from './main/assignment/documents-assignment/documents-assignment.module';
import { InvolvedAssignmentModule } from './main/assignment/involved-assignment/involved-assignment.module';
import { TasksAssignmentModule } from './main/assignment/tasks-assignment/tasks-assignment.module';
import { TableTaskModule } from './main/task/table-task/table-task.module';
import { DetailTaskModule } from './main/task/detail-task/detail-task.module';
import { KanbanModule } from './main/kanban/kanban.module';
import { KanbanAssignmentModule } from './main/assignment/kanban-assignment/kanban-assignment.module';
import { ChartModule } from './main/chart/chart.module';
import de from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/de/all';
import { registerLocaleData } from '@angular/common';
import { IntlModule } from '@progress/kendo-angular-intl';
import { TableTasksetModule } from './main/taskset/table-taskset/table-taskset.module';
import { TableTasktemplateModule } from './main/tasktemplate/table-tasktemplate/table-tasktemplate.module';
import { DetailTasksetModule } from './main/taskset/detail-taskset/detail-taskset.module';
import { SchedulerModule } from './main/scheduler/scheduler.module';
import { NotesAssignmentModule } from './main/assignment/notes-assignment/notes-assignment.module';
import { DetailDocumentModule } from './main/document/detail-document/detail-document.module';
import { ListviewCurrentuserModule } from './main/currentuser/listview-currentuser/listview-currentuser.module';
import { DetailNotificationModule } from './main/notification/detail-notification/detail-notification.module';
import { WindowRef } from './main/shared/WindowRef';
import { CustomMessageService } from './main/shared/message.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { MailsAssignmentModule } from './main/assignment/mails-assignment/mails-assignment.module';
import { DetailMailModule } from './main/mail/detail-mail/detail-mail.module';
import { CommentsAssignmentModule } from './main/assignment/comments-assignment/comments-assignment.module';
import { ListviewCustomerModule } from './main/customer/listview-customer/listview-customer.module';
import { ListviewTimeModule } from './main/time/listview-time/listview-time.module';
import { QuillModule } from 'ngx-quill';
import { OverviewBudgetModule } from './main/budget/overview-budget/overview-budget.module';
import { InvoicesAssignmentModule } from './main/assignment/invoices-assignment/invoices-assignment.module';
import { TableInvoiceModule } from './main/invoice/table-invoice/table-invoice.module';
import { DetailInvoiceModule } from './main/invoice/detail-invoice/detail-invoice.module';
import { ListviewInvoiceModule } from './main/invoice/listview-invoice/listview-invoice.module';
import { TablePositionModule } from './main/position/table-position/table-position.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssignMailModule } from './main/mail/assign-mail/assign-mail.module';
import { ListviewGroupModule } from './main/group/listview-group/listview-group.module';
import { AddUserModule } from './main/user/add-user/add-user.module';
import { IndexMailModule } from './main/mail/index-mail/index-mail.module';
import { ReAssignMailModule } from './main/mail/reassign-mail/reassign-mail.module';
import { CostRolesAssignmentModule } from './main/assignment/costRoles-assignment/costRoles-assignment.module';
import { CostRolesOrderModule } from './main/order/costRoles-order/costRoles-order.module';
import { ListviewEmployeeanalysisModule } from './main/employeeanalysis/listview-employeeanalysis/listview-employeeanalysis.module';
import { PartiallyFinishedServicesModule } from 'src/app/main/controlling/partially-finished-services/partially-finished-services.module';
import { ProjectEvaluationModule } from './main/project-evaluation/project-evaluation.module';
import { DocTemplatesModule } from './main/doc-templates/doc-templates.module';
import { CurrentUserService } from './main/services/current-user.service';
import { DocumenttemplateModule } from './main/documenttemplate/documenttemplate.module';
import { HeaderComponent } from './main/shared/components/header/header.component';

declare const window: Window;

registerLocaleData(de);

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'projects',
    },
];

class EnvironmentImpl implements Environment {
    production = false;
    serverUrl = window.location.href;
    authType = !window['useApp'] ? AuthTypes.MSAL : AuthTypes.SHA;
    spHostUrl = window['spHostUrl'] ? window['spHostUrl'] : null;
    msalConfig = {
        graphPermissions: ['People.Read', 'Mail.Send', 'Mail.Read'],
        tenantIdForAuth: window['tenantId']
            ? window['tenantId']
            : 'c5f61af3-7c37-455b-a530-4b7110bb534a',
        // clientIdForAuth: '36c4b230-4ce9-4f49-9300-c4a525bcc5ea', // Sharepoint-Template Azure App ClientId
    };
    manualAppInitializer: true;
}
export const env = new EnvironmentImpl();
env.manualAppInitializer = true;

export const appConfig: AppConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: 'theme-mopa',
    title: '',
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'white', //'fuse-navy-50',
            secondaryBackground: 'white', //fuse-navy-50',
            folded: false,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-2',
            customLogo: true,
        },
        toolbar: {
            customBackgroundColor: false,
            background: 'fuse-white-500',
            hidden: false,
            position: 'below-static',
            shortcuts: {
                hidden: true,
            },
            search: {
                hidden: true,
            },
            language: {
                hidden: true,
            },
            quickPanel: {
                hidden: true,
            },
        },
        footer: {
            customBackgroundColor: false,
            background: 'fuse-navy-900',
            hidden: true,
            position: 'below-fixed',
        },
        sidepanel: {
            hidden: true,
            position: 'right',
        },
    },

    layoutV2: 'classic',
    scheme: 'light',
    screens: {
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px',
    },
    theme: 'theme-mopa',
    themes: [
        {
            id: 'theme-default',
            name: 'Default',
        },
        {
            id: 'theme-brand',
            name: 'Brand',
        },
        {
            id: 'theme-teal',
            name: 'Teal',
        },
        {
            id: 'theme-rose',
            name: 'Rose',
        },
        {
            id: 'theme-purple',
            name: 'Purple',
        },
        {
            id: 'theme-amber',
            name: 'Amber',
        },
    ],
};

@NgModule({
    declarations: [AppComponent, HeaderComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        IntlModule,

        AngularFrameworkModule,

        // Quill editor
        QuillModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(appConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        InstallationModule,
        LayoutModule,
        ListviewAssignmentModule,
        ConfigurationModule,
        UpdateModule,
        DashboardModule,
        ListviewTaskModule,
        ListviewWorkpackageModule,
        HighlightModule,
        EditAssignmentModule,
        DocumentsAssignmentModule,
        InvolvedAssignmentModule,
        TasksAssignmentModule,
        TableTaskModule,
        DetailTaskModule,
        KanbanModule,
        KanbanAssignmentModule,
        ChartModule,
        TableTasksetModule,
        TableTasktemplateModule,
        DetailTasksetModule,
        SchedulerModule,
        NotesAssignmentModule,
        DetailDocumentModule,
        ListviewCurrentuserModule,
        DetailNotificationModule,
        MailsAssignmentModule,
        DetailMailModule,
        AssignMailModule,
        IndexMailModule,
        ReAssignMailModule,
        CommentsAssignmentModule,
        DetailContactModule,
        DetailCustomerModule,
        ListviewCustomerModule,
        DetailCategoryModule,
        TimesAssignmentModule,
        NewTimeModule,
        DurationTimeModule,
        ListviewTimeModule,
        OverviewBudgetModule,
        ListviewReportModule,
        FilterTimeModule,
        DetailOrderModule,
        ListviewOrderModule,
        DetailControllingModule,
        ListviewControllingModule,
        ListviewBillsModule,
        FilterBillsModule,
        ListviewEvaluationModule,
        ListviewEmployeeanalysisModule,
        FilterEvaluationModule,
        InvoicesAssignmentModule,
        CostRolesAssignmentModule,
        CostRolesOrderModule,
        TableInvoiceModule,
        DetailInvoiceModule,
        ListviewInvoiceModule,
        TablePositionModule,
        DetailPositionModule,
        TableControllingModule,
        EvaluationChartModule,
        TimelineAssignmentModule,
        ListviewGroupModule,
        DetailGroupModule,
        TableUserModule,
        AddUserModule,
        FremdkostenModule,
        DetailRequestModule,
        ListviewRequestsModule,
        OrdReqDocsModule,
        MoveElementModule,
        DetailProcessstepsModule,
        MailsRequestModule,
        MailsOrderModule,
        PartiallyFinishedServicesModule,
        ProjectEvaluationModule,
        DocTemplatesModule,
        DocumenttemplateModule,
        FuseConfigModule.forRoot(appConfig),
        LayoutModule,
    ],
    providers: [
        { provide: Environment, useValue: env },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        { provide: MessageService, useClass: CustomMessageService },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [SharePointService, CurrentUserService],
            multi: true,
        },
        WindowRef,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        registerFormioCustomComponents(injector);
    }
}

export function appInitializerFactory(
    sharePointService: SharePointService,
    currentUserService: CurrentUserService
) {
    return () => {
        init_sp_url_settings(sharePointService);
        return sharePointService.initApp().then(() => {
            return currentUserService.initCurrentUserRoles();
        });
    };
}
