import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    ListViewConfig,
    TableConfig,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { ColumnTyp, FormatTypes, TableColumn } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import moment from 'moment';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailTaskComponent } from '../detail-task/detail-task.component';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { TaskService } from '../../services/task.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-listview-task',
    templateUrl: './listview-task.component.html',
    styleUrls: ['./listview-task.component.scss'],
    animations: fuseAnimations,
})
export class ListviewTaskComponent implements AfterViewInit, OnDestroy {
    @ViewChild('taskGrid', { static: false }) taskGrid: ListViewComponent;

    public config: ListViewConfig;
    public viewConfigs: TableConfig[];
    public columns: TableColumn[];
    public queries = {};
    public searchQuery = '';
    public currentConfig: { index: number, default: string, search: () => string, defaultFilter?: (item) => boolean, searchFilter?: (item) => boolean } ;
    private destroy$ = new Subject();

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _taskService: TaskService,
        private _cdr: ChangeDetectorRef
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initConfig();

        this.queries = {
            [this._translateService.instant('LV_TASK.ACTIVE')]: {
                index: 0,
                default: this.activeTaskCamlQuery,
                search: () => this.getActiveTaskSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Auftrag && !!item.Arbeitspaket && item.Aufgabenart === 'Aufgabe' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt',
                searchFilter: (item) => !!item.AuftragId && !!item.Auftrag && !!item.Arbeitspaket && item.Aufgabenart === 'Aufgabe' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            },
            [this._translateService.instant('LV_TASK.OVERDUEDATE')]: {
                index: 1,
                default: this.activeOverDueTaskCamlQuery,
                search: () => this.getActiveOverDueTaskSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Auftrag && !!item.Arbeitspaket && item.Aufgabenart === 'Aufgabe' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && moment(item.DueDate).isSameOrBefore(new Date()),
                searchFilter: (item) => !!item.AuftragId && !!item.Auftrag && !!item.Arbeitspaket && item.Aufgabenart === 'Aufgabe' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && moment(item.DueDate).isSameOrBefore(new Date()) && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            },
            [this._translateService.instant('LV_TASK.ALL')]: {
                index: 2,
                default: this.allWPCamlQuery,
                search: () => this.getAllWPSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Arbeitspaket && !!item.Auftrag && item.Aufgabenart === 'Aufgabe',
                searchFilter: (item) => !!item.AuftragId && !!item.Arbeitspaket && !!item.Auftrag && item.Aufgabenart === 'Aufgabe' && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            }
        }
    }

    ngAfterViewInit(): void {
        this.taskGrid._listViewService.onSearchTextChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe(async searchText => {
                this.searchQuery = searchText;
                const query: string = searchText ? this.currentConfig.search() : this.currentConfig.default;
                const filter = searchText ? this.currentConfig.searchFilter : this.currentConfig.defaultFilter;
                await this.loadTasks(this.currentConfig.index, query, filter);
            });

        this.taskGrid?._listViewService?.onConfigChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe(async data => {
                this.searchQuery = '';
                const config = this.queries[data.title];
                this.currentConfig = config;
                await this.loadTasks(config.index, config.default, config.defaultFilter);
            })
    }

    get activeTaskCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .ToString();
    }

    private getActiveTaskSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    get activeOverDueTaskCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .ToString();
    }

    private getActiveOverDueTaskSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    get allWPCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();
    }

    private getAllWPSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    openDialog(item, isTask): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: item.AuftragId,
            isTask,
            taskId: item.Id,
            arbeitsPaketId: item.ArbeitspaketId,
        };

        const dialogRef = this.dialog.open(DetailTaskComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            // Refresh table
            self.taskGrid._listViewService.onListViewDataChanged.next(
                self.taskGrid._listViewService.listView
            );
            const query: string = this.searchQuery ? this.currentConfig.search() : this.currentConfig.default;
            const filter = this.searchQuery ? this.currentConfig.searchFilter : this.currentConfig.defaultFilter;
            this.loadTasks(this.currentConfig.index, query, filter);

            if (isTask) {
                // Refresh subtables
                self.taskGrid.listComponent.sharePointTableComponent.refreshSubtables();
            }
        });
    }

    initConfig() {
        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('LV_TASK.COLUMNS.TITLE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant(
                    'LV_TASK.COLUMNS.DUEDATE'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant(
                    'LV_TASK.COLUMNS.ASSIGNED'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Prozessschritt',
                title: this._translateService.instant(
                    'LV_TASK.COLUMNS.PROCESSSTEP'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftrag',
                title: this._translateService.instant(
                    'LV_TASK.COLUMNS.PROJECT'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant(
                    'LV_TASK.COLUMNS.WORKPACKAGE'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('LV_TASK.COLUMNS.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('LV_TASK.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('LV_TASK.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.viewConfigs = [
            {
                title: this._translateService.instant('LV_TASK.ACTIVE'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Arbeitspaket' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: []
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
            {
                title: this._translateService.instant('LV_TASK.OVERDUEDATE'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Arbeitspaket' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: []
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
            {
                title: this._translateService.instant('LV_TASK.ALL'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Arbeitspaket' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: []
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
        ];

        this.config = {
            title: this._translateService.instant('LV_TASK.TITLE'),
            searchLabel: this._translateService.instant('LV_TASK.SEARCH_LABEL'),
            icon: 'assignment_turned_in',
            add_icon: 'add',
            openEditInDialog: true,
            onRowClick: (item) => {
                this.openDialog(item, true);
            },
            tableConfigs: this.viewConfigs,
        };
    }

    async loadTasks(configIndex: number, query: string, filter: (item) => boolean) {
        if (this.taskGrid) {
            this.taskGrid.listComponent.sharePointTableComponent.loading = true;
            this._cdr.detectChanges();
        }

        // const tasks = await this._taskService.loadTasksByQuery(query);
        const tasks = await this._taskService.loadAllTasks(filter);
        this.viewConfigs[configIndex].config.data = tasks;
        this.taskGrid.listComponent.sharePointTableComponent.refresh();

        return tasks;
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }
}
