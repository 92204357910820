export const locale = {
    lang: 'de',
    data: {
        PROJECTS: 'Projekte',
        BASE: 'Stammdaten',
        DOCS: 'Dokumente',
        INVOLVED: 'Beteiligte',
        TASKS: 'Rechnungen',
        INVOICES: 'Rechnungen',
        KANBAN: 'Kanban',
        PROJECTNUMBER: 'Projektnummer',
        NOTES: 'Notizen',
        TABS: {
            WORKPACKAGES: 'Arbeitspakete',
            TASKS: 'Aufgaben',
        },
        BUTTONS: {
            NEW: 'Neue Rechnung anlegen',
            NEWTASK: 'Neue Aufgabe anlegen',
            MY: 'Meine Arbeitspakete',
            MYTASK: 'Meine Aufgaben',
            CRITICAL: 'Kritische Arbeitspakete',
            CRITICALTASK: 'Kritische Aufgaben',
            SAVE: 'Speichern',
        },
        COLUMNS: {
            TITLE: 'Kostenrolle',
            COSTROLE: 'Kostenrolle',
            COSTSPERHOUR: 'Stundensatz',
            INEURO: '(in Euro)',
            ABBREVIATION: 'Abkürzung',
            ORDER: 'Auftrag',
        },
    },
};
