export const locale = {
    lang: 'de',
    data: {
        DURATION_TIME: {
            HOURS: 'Stunden',
            MINUTES: 'Minuten',
            HOURSERROR: 'Nur Zeiten zwischen 0 und 23',
            MINUTESERROR: 'Nur Zeiten im 5 Minuten-Takt und kleiner 60',
        },
    },
};
