import { Injectable } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import { LoadingService } from '../../services/loading.service';
import { DialogService, SnackbarService } from 'sp-office365-layout';
import CamlBuilder from 'camljs';
import { splitArrayIntoChunks } from '../../shared/shared.util';

@Injectable({
    providedIn: 'root',
})
export class NewTenantMigrationService {
    private projectFolders = [
        {
            title: 'Auftragsdokumente',
            isDocumentLibrary: true,
        },
        {
            title: 'Aufgabendokumente',
            isDocumentLibrary: true,
        },
        {
            title: 'Zeiten',
            isDocumentLibrary: false,
        },
        {
            title: 'Aufträge-Budgets',
            isDocumentLibrary: false,
        },
        {
            title: 'Auftragsaufgaben',
            isDocumentLibrary: false,
        },
        {
            title: 'Mailbox',
            isDocumentLibrary: false,
        },
        {
            title: 'Projektkommentare',
            isDocumentLibrary: false,
        },
        {
            title: 'Rechnungen',
            isDocumentLibrary: false,
        },
        {
            title: 'Auftragsbeteiligte',
            isDocumentLibrary: false,
        },
    ];

    private prefixedFolders = [
        {
            title: 'Auftragsdokumente',
            isDocumentLibrary: true,
        },
        {
            title: 'Antragsdokumente',
            isDocumentLibrary: true,
        },
        {
            title: 'Aufgabendokumente',
            isDocumentLibrary: true,
        },
        {
            title: 'Zeiten',
            isDocumentLibrary: false,
        },
        {
            title: 'Aufträge-Budgets',
            isDocumentLibrary: false,
        },
        {
            title: 'Auftragsaufgaben',
            isDocumentLibrary: false,
        },
        {
            title: 'Mailbox',
            isDocumentLibrary: false,
        },
        {
            title: 'Mailbox-Anträge',
            isDocumentLibrary: false,
        },
        {
            title: 'Projektkommentare',
            isDocumentLibrary: false,
        },
        {
            title: 'Rechnungen',
            isDocumentLibrary: false,
        },
        {
            title: 'Auftragsbeteiligte',
            isDocumentLibrary: false,
        },
    ];
    private chunkSize = 300;

    constructor(
        private _sharePointService: SharePointService,
        private loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _dialogService: DialogService
    ) {}

    public async addPrefixToAllProjectDocumentLibraries(): Promise<void> {
        for (let i = 0; i < this.prefixedFolders.length; i++) {
            console.warn(
                `%c${this.prefixedFolders[i].title} - ${i + 1}/${
                    this.prefixedFolders.length
                }`,
                'color:green;font-size:30px;'
            );
            await this.addDocumentLibraryFolderPrefix(
                this.prefixedFolders[i].title,
                this.prefixedFolders[i].isDocumentLibrary
            );
        }
    }

    public async removePrefixToAllProjectDocumentLibraries(): Promise<void> {
        for (let i = 0; i < this.prefixedFolders.length; i++) {
            await this.removeDocumentLibraryFolderPrefix(
                this.prefixedFolders[i].title,
                this.prefixedFolders[i].isDocumentLibrary
            );
        }
    }

    public async fixAllProjectDocumentLibraries(): Promise<void> {
        for (let i = 0; i < this.projectFolders.length; i++) {
            console.warn(
                `%c${this.prefixedFolders[i].title} - ${i + 1}/${
                    this.prefixedFolders.length
                }`,
                'color:green;font-size:30px;'
            );
            await this.fixDocumentLibraryFolderName(
                this.projectFolders[i].title,
                this.projectFolders[i].isDocumentLibrary
            );
        }
    }

    // SP LIST
    public async addListItemPrefix(listName: string): Promise<void> {
        try {
            // this.showLoader(`Updating ${listName} folders`);
            // const list = await this._sharePointService.web.lists.getByTitle(listName);
            // let items = await list.items.top(5000).getAll();
            // items = items.filter(assignment => !!assignment.FileSystemObjectType);
            // const chunks = splitArrayIntoChunks(items, this.chunkSize);

            // for (let i = 0; i < chunks.length; i++) {
            //     let batch = this._sharePointService.web.createBatch();
            //     let batchItems = list.items.inBatch(batch);
            //     let chunkItems = chunks[i];

            //     for (let j = 0; j < chunkItems.length; j++) {
            //         let assignment = chunkItems[j];

            //         if (!this.isNumeric(assignment.Title) || this.isNumeric(assignment.Title)[0] === '_') {
            //             continue;
            //         }

            //         batchItems.getById(assignment.Id).inBatch(batch).update({ Title: `_${assignment.Title}` });
            //     }

            //     await batch.execute();
            // }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    // SP LIST
    public async removeListItemPrefix(listName: string): Promise<void> {
        try {
            this.showLoader(`Updating ${listName} folders`);
            const list = await this._sharePointService.web.lists.getByTitle(
                listName
            );
            let items = await list.items.top(5000).getAll();
            items = items.filter(
                (assignment) => !!assignment.FileSystemObjectType
            );
            const chunks = splitArrayIntoChunks(items, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let batchItems = list.items.inBatch(batch);
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     const folderName = chunkItems[j].Title.replace(/_/g, '');
                //     if (!this.isNumeric(folderName)) {
                //         continue;
                //     }
                //     batchItems.getById(chunkItems[j].Id).inBatch(batch).update({ Title: folderName });
                // }
                // await batch.execute();
            }

            // const listFolderItems = await this._sharePointService.getListItems({
            //     title: listName,
            //     isDocumentLibrary: false,
            //     camlQuery: new CamlBuilder()
            //         .Where()
            //         .NumberField('FSObjType')
            //         .EqualTo(1)
            //         .ToString()
            // });
            //
            //
            // for (let i = 0; i < listFolderItems.length; i++) {
            //     const folderName = listFolderItems[i].Title.replace(/_/g, '');
            //
            //     if (!this.isNumeric(folderName)) {
            //         continue;
            //     }
            //
            //     await this._sharePointService.updateItem({
            //         listTitle: listName,
            //         id: listFolderItems[i].Id,
            //         newFiledValues: {
            //             Title: folderName,
            //         }
            //     });
            // }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    // DOCUMENT LIBRARY
    public async addDocumentLibraryFolderPrefix(
        listName: string,
        isDocumentLibrary?: boolean
    ): Promise<void> {
        const listFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.folders();
        const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

        for (let i = 0; i < chunks.length; i++) {
            // let batch = this._sharePointService.web.createBatch();
            // let chunkItems = chunks[i];
            // for (let j = 0; j < chunkItems.length; j++) {
            //     let item = chunkItems[j];
            //     if (!this.isNumeric(item.Name) || this.isNumeric(item.Name)[0] === '_') {
            //         continue;
            //     }
            //     const folder = this._sharePointService.web.getFolderByServerRelativePath(item.ServerRelativeUrl);
            //     const folderItem = await folder.getItem('FileLeafRef');
            //     folderItem.inBatch(batch).update({ FileLeafRef: `_${item.Name}` });
            // }
            // await batch.execute();
        }

        if (!isDocumentLibrary) {
            await this.addListItemPrefix(listName);
        }

        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    // DOCUMENT LIBRARY
    public async removeDocumentLibraryFolderPrefix(
        listName: string,
        isDocumentLibrary?: boolean
    ): Promise<void> {
        // const result = await this._dialogService.waitForConfirm('Are you sure?', 'Step back', 'OK', 'Cancel');
        //
        // if (!result) {
        //     return;
        // }

        const listFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.folders();
        const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

        for (let i = 0; i < chunks.length; i++) {
            // let batch = this._sharePointService.web.createBatch();
            // let chunkItems = chunks[i];
            // for (let j = 0; j < chunkItems.length; j++) {
            //     let item = chunkItems[j];
            //     const folderName = item.Name.replace(/_/g, '');
            //     if (!this.isNumeric(folderName)) {
            //         continue;
            //     }
            //     const folder = this._sharePointService.web.getFolderByServerRelativePath(item.ServerRelativeUrl);
            //     const folderItem = await folder.getItem('FileLeafRef');
            //     folderItem.inBatch(batch).update({ FileLeafRef: folderName });
            // }
            // await batch.execute();
        }

        if (!isDocumentLibrary) {
            await this.removeListItemPrefix(listName);
        }
        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    // DOCUMENT LIBRARY
    public async fixDocumentLibraryFolderName(
        listName: string,
        isDocumentLibrary?: boolean
    ): Promise<void> {
        try {
            const newAssignmentNumberIdMapping = {};
            const assignments = await this._sharePointService.getListItems({
                title: 'Aufträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            });

            assignments.forEach((assignment) => {
                if (!assignment.Projektnummer) {
                    return;
                }

                newAssignmentNumberIdMapping[assignment.Projektnummer] =
                    assignment.Id;
            });

            this.showLoader(`Updating ${listName} folders`);
            const listFolders = await this._sharePointService.web.lists
                .getByTitle(listName)
                .rootFolder.folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     const folderName = item.Name.replace(/_/g, '');
                //     const newFolderName = newAssignmentNumberIdMapping[OLD_TENANT_ASSIGNMENTS_MAPPING[folderName]];
                //     if (!folderName || !newFolderName || !this.isNumeric(newFolderName) || item.Name[0] !== '_') {
                //         continue;
                //     }
                //     console.warn(item.Name, newFolderName);
                //     const folder = this._sharePointService.web.getFolderByServerRelativePath(item.ServerRelativeUrl);
                //     const folderItem = await folder.getItem('FileLeafRef');
                //     folderItem.inBatch(batch).update({ FileLeafRef: `${newFolderName}` });
                // }
                // await batch.execute();
            }

            if (!isDocumentLibrary) {
                await this.fixListFolderName(listName);
            }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    // SP LIST
    public async fixListFolderName(listName: string): Promise<void> {
        try {
            const newAssignmentNumberIdMapping = {};
            const assignments = await this._sharePointService.getListItems({
                title: 'Aufträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            });

            assignments.forEach((assignment) => {
                if (!assignment.Projektnummer) {
                    return;
                }

                newAssignmentNumberIdMapping[assignment.Projektnummer] =
                    assignment.Id;
            });

            const list = await this._sharePointService.web.lists.getByTitle(
                listName
            );
            let items = await list.items.top(5000).getAll();
            items = items.filter(
                (assignment) => !!assignment.FileSystemObjectType
            );
            const chunks = splitArrayIntoChunks(items, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let batchItems = list.items.inBatch(batch);
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     const folderName = item.Title.replace(/_/g, '');
                //     const newFolderName = newAssignmentNumberIdMapping[OLD_TENANT_ASSIGNMENTS_MAPPING[folderName]];
                //     if (!folderName || !newFolderName || !this.isNumeric(newFolderName) || item.Title[0] !== '_') {
                //         continue;
                //     }
                //     console.warn(item.Title, newFolderName);
                //     batchItems.getById(item.Id).inBatch(batch).update({ Title: `${newFolderName}` });
                // }
                // await batch.execute();
            }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    //////////////////////////////////////////////////////////////////////////////
    // ORDERS/REQUESTS

    // DOCUMENT LIBRARY
    public async fixAntrageDocumentLibraryFolderName(
        listName: string,
        isDocumentLibrary?: boolean
    ): Promise<void> {
        try {
            const antrageIdMapping = {};
            const antrageItems = await this._sharePointService.getListItems({
                title: 'Anträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            });

            antrageItems.forEach((antrage) => {
                if (!antrage.Old_Id) {
                    return;
                }

                antrageIdMapping[antrage.Old_Id] = antrage.Id;
            });

            console.warn(antrageIdMapping);

            this.showLoader(`Updating ${listName} folders`);
            const listFolders = await this._sharePointService.web.lists
                .getByTitle(listName)
                .rootFolder.folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     const folderName = item.Name.replace(/_/g, '');
                //     const newFolderName = antrageIdMapping[folderName];
                //     if (
                //         !folderName ||
                //         !newFolderName ||
                //         !this.isNumeric(newFolderName) ||
                //         item.Name[0] !== '_'
                //     ) {
                //         continue;
                //     }
                //     console.warn(item.Name, newFolderName);
                //     const folder =
                //         this._sharePointService.web.getFolderByServerRelativePath(
                //             item.ServerRelativeUrl
                //         );
                //     const folderItem = await folder.getItem('FileLeafRef');
                //     folderItem
                //         .inBatch(batch)
                //         .update({ FileLeafRef: `${newFolderName}` });
                // }
                // await batch.execute();
            }

            if (!isDocumentLibrary) {
                await this.fixAntrageListFolderName(listName);
            }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    // SP LIST
    public async fixAntrageListFolderName(listName: string): Promise<void> {
        try {
            const antrageIdMapping = {};
            const antrageItems = await this._sharePointService.getListItems({
                title: 'Anträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            });

            antrageItems.forEach((antrage) => {
                if (!antrage.Old_Id) {
                    return;
                }

                antrageIdMapping[antrage.Old_Id] = antrage.Id;
            });

            const list = await this._sharePointService.web.lists.getByTitle(
                listName
            );
            let items = await list.items.top(5000).getAll();
            items = items.filter(
                (assignment) => !!assignment.FileSystemObjectType
            );
            const chunks = splitArrayIntoChunks(items, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let batchItems = list.items.inBatch(batch);
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     const folderName = item.Title.replace(/_/g, '');
                //     const newFolderName = antrageIdMapping[folderName];
                //     if (
                //         !folderName ||
                //         !newFolderName ||
                //         !this.isNumeric(newFolderName) ||
                //         item.Title[0] !== '_'
                //     ) {
                //         continue;
                //     }
                //     console.warn(item.Title, newFolderName);
                //     batchItems
                //         .getById(item.Id)
                //         .inBatch(batch)
                //         .update({ Title: `${newFolderName}` });
                // }
                // await batch.execute();
            }

            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    ////////////////////////////////////////////

    public async addPrefixToTaskFolder(listName: string): Promise<void> {
        const itemFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.select()
            .folders();

        for (let a = 0; a < itemFolders.length; a++) {
            console.warn(
                `%c${itemFolders[a].Name}`,
                'color:green;font-size:30px;'
            );
            const listFolders = await this._sharePointService.web
                .getFolderByServerRelativePath(itemFolders[a].ServerRelativeUrl)
                .folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     if (
                //         !this.isNumeric(item.Name) ||
                //         this.isNumeric(item.Name)[0] === '_'
                //     ) {
                //         continue;
                //     }
                //     const folder =
                //         this._sharePointService.web.getFolderByServerRelativePath(
                //             item.ServerRelativeUrl
                //         );
                //     const folderItem = await folder.getItem('FileLeafRef');
                //     folderItem
                //         .inBatch(batch)
                //         .update({ FileLeafRef: `_${item.Name}` });
                // }
                // await batch.execute();
            }
        }

        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    public async removePrefixTaskFolder(listName: string): Promise<void> {
        const itemFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.select()
            .folders();

        for (let a = 0; a < itemFolders.length; a++) {
            console.warn(
                `%c${itemFolders[a].Name}`,
                'color:green;font-size:30px;'
            );
            const listFolders = await this._sharePointService.web
                .getFolderByServerRelativePath(itemFolders[a].ServerRelativeUrl)
                .folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

            for (let i = 0; i < chunks.length; i++) {
                // let batch = this._sharePointService.web.createBatch();
                // let chunkItems = chunks[i];
                // for (let j = 0; j < chunkItems.length; j++) {
                //     let item = chunkItems[j];
                //     const folderName = item.Name.replace(/_/g, '');
                //     if (!this.isNumeric(folderName)) {
                //         continue;
                //     }
                //     const folder =
                //         this._sharePointService.web.getFolderByServerRelativePath(
                //             item.ServerRelativeUrl
                //         );
                //     const folderItem = await folder.getItem('FileLeafRef');
                //     folderItem
                //         .inBatch(batch)
                //         .update({ FileLeafRef: folderName });
                // }
                // await batch.execute();
            }
        }

        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    // DOCUMENT LIBRARY
    public async fixTaskDocumentLibraryFolderName(
        listName: string
    ): Promise<void> {
        try {
            const antrageIdMapping = {};
            const list = await this._sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );
            let taskItems = await list.items.top(5000).getAll();

            taskItems.forEach((antrage) => {
                if (!antrage.Old_Id) {
                    return;
                }

                antrageIdMapping[antrage.Old_Id] = antrage.Id;
            });

            const itemFolders = await this._sharePointService.web.lists
                .getByTitle(listName)
                .rootFolder.select()
                .folders();

            for (let a = 0; a < itemFolders.length; a++) {
                console.warn(
                    `%c${itemFolders[a].Name}`,
                    'color:green;font-size:30px;'
                );
                const listFolders = await this._sharePointService.web
                    .getFolderByServerRelativePath(
                        itemFolders[a].ServerRelativeUrl
                    )
                    .folders();
                const chunks = splitArrayIntoChunks(
                    listFolders,
                    this.chunkSize
                );

                for (let i = 0; i < chunks.length; i++) {
                    // let batch = this._sharePointService.web.createBatch();
                    // let chunkItems = chunks[i];
                    // for (let j = 0; j < chunkItems.length; j++) {
                    //     let item = chunkItems[j];
                    //     const folderName = item.Name.replace(/_/g, '');
                    //     const newFolderName = antrageIdMapping[folderName];
                    //     if (
                    //         !folderName ||
                    //         !newFolderName ||
                    //         !this.isNumeric(newFolderName) ||
                    //         item.Name[0] !== '_'
                    //     ) {
                    //         continue;
                    //     }
                    //     console.warn(item.Name, newFolderName);
                    //     const folder =
                    //         this._sharePointService.web.getFolderByServerRelativePath(
                    //             item.ServerRelativeUrl
                    //         );
                    //     const folderItem = await folder.getItem('FileLeafRef');
                    //     folderItem
                    //         .inBatch(batch)
                    //         .update({ FileLeafRef: `${newFolderName}` });
                    // }
                    // await batch.execute();
                }
            }
            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
        } catch (e) {
            this.showErrorSnackBar();
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // ASSIGN LOOKUP FIELD

    public async assignProjectLookupIdDocumentLibrary(
        listName: string,
        fieldName
    ): Promise<any> {
        const listFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.select()
            .folders();
        const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

        for (let i = 0; i < chunks.length; i++) {
            let chunkItems = chunks[i];

            for (let j = 0; j < chunkItems.length; j++) {
                let item = chunkItems[j];

                if (!this.isNumeric(item.Name) || item.Name[0] === '_') {
                    continue;
                }

                const files = await this.getAllFiles(item.ServerRelativeUrl);
                const fileChunks = splitArrayIntoChunks(files, this.chunkSize);

                for (let c = 0; c < fileChunks.length; c++) {
                    // let batch = this._sharePointService.web.createBatch();
                    // const _fileChunk = fileChunks[c];
                    // for (let f = 0; f < _fileChunk.length; f++) {
                    //     if (_fileChunk[f][fieldName]) {
                    //         continue;
                    //     }
                    //     try {
                    //         const file = this._sharePointService.web
                    //             .getFileByServerRelativeUrl(
                    //                 (_fileChunk[f] as any).ServerRelativeUrl
                    //             )
                    //             .expand('ListItemAllFields');
                    //         const fileItem = await file.getItem();
                    //         fileItem
                    //             .inBatch(batch)
                    //             .update({ [fieldName]: Number(item.Name) });
                    //     } catch (e) {
                    //         console.warn(e);
                    //         console.warn(_fileChunk[f]);
                    //     }
                    // }
                    // try {
                    //     await batch.execute();
                    // } catch (e) {
                    //     console.warn(
                    //         'BATCH_FAILED',
                    //         _fileChunk.map((item) => item['ServerRelativeUrl'])
                    //     );
                    // }
                }
            }
        }
    }

    public async assignProjectLookupIdDocumentLibrary1(
        listName: string,
        fieldName
    ): Promise<any> {
        this.showLoader(`Updating ${listName} folders`);
        const list = await this._sharePointService.web.lists.getByTitle(
            listName
        );
        // const entityTypeFullName = await list.getListItemEntityTypeFullName();
        let listItems = await list.items.top(5000).getAll();
        const folders = listItems.filter(
            (assignment) => !!assignment.FileSystemObjectType
        );

        for (let i = 0; i < folders.length; i++) {
            if (
                !folders[i].Title ||
                !this.isNumeric(folders[i].Title) ||
                folders[i].Title[0] === '_'
            ) {
                continue;
            }

            let folderItems = await this._sharePointService.getListItems({
                title: listName,
                isDocumentLibrary: false,
                recursiveAll: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
                folderName: folders[i].Title,
            });
            folderItems = folderItems.filter(
                (item) => !item.FileSystemObjectType
            );

            const chunks = splitArrayIntoChunks(folderItems, this.chunkSize);

            for (let j = 0; j < chunks.length; j++) {
                // let batch = this._sharePointService.web.createBatch();
                // let batchItems = list.items.inBatch(batch);
                // let chunkItems = chunks[j];
                // for (let c = 0; c < chunkItems.length; c++) {
                //     batchItems
                //         .getById((chunkItems[c] as any).Id)
                //         .inBatch(batch)
                //         .update(
                //             { [fieldName]: Number(folders[i].Title) },
                //             '*',
                //             entityTypeFullName
                //         );
                // }
                // await batch.execute();
            }
        }
        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    public async assignProjectLookupIdAufgabeDocumentLibrary(
        listName: string
    ): Promise<any> {
        const itemFolders = await this._sharePointService.web.lists
            .getByTitle(listName)
            .rootFolder.select()
            .folders();

        for (let a = 0; a < itemFolders.length; a++) {
            console.warn(
                `%c${itemFolders[a].Name}`,
                'color:green;font-size:30px;'
            );
            const listFolders = await this._sharePointService.web
                .getFolderByServerRelativePath(itemFolders[a].ServerRelativeUrl)
                .folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);

            if (
                !this.isNumeric(itemFolders[a].Name) ||
                itemFolders[a].Name[0] === '_'
            ) {
                continue;
            }

            for (let i = 0; i < chunks.length; i++) {
                let chunkItems = chunks[i];

                for (let j = 0; j < chunkItems.length; j++) {
                    let item = chunkItems[j];

                    if (!this.isNumeric(item.Name) || item.Name[0] === '_') {
                        continue;
                    }

                    try {
                        const files = await this.getAllFiles(
                            item.ServerRelativeUrl
                        );
                        const fileChunks = splitArrayIntoChunks(
                            files,
                            this.chunkSize
                        );

                        for (let c = 0; c < fileChunks.length; c++) {
                            // let batch =
                            //     this._sharePointService.web.createBatch();
                            // const _fileChunk = fileChunks[c];
                            // for (let f = 0; f < _fileChunk.length; f++) {
                            //     try {
                            //         if (
                            //             _fileChunk[f]['ListItemAllFields'] &&
                            //             _fileChunk[f]['ListItemAllFields'][
                            //                 'AuftragId'
                            //             ] &&
                            //             _fileChunk[f]['ListItemAllFields'][
                            //                 'AufgabeId'
                            //             ]
                            //         ) {
                            //             continue;
                            //         }
                            //         const file = this._sharePointService.web
                            //             .getFileByServerRelativeUrl(
                            //                 (_fileChunk[f] as any)
                            //                     .ServerRelativeUrl
                            //             )
                            //             .expand('ListItemAllFields');
                            //         const fileItem = await file.getItem();
                            //         fileItem.inBatch(batch).update({
                            //             AuftragId: Number(itemFolders[a].Name),
                            //             AufgabeId: Number(item.Name),
                            //         });
                            //     } catch (e) {
                            //         console.warn(e);
                            //         console.warn(_fileChunk[f]);
                            //     }
                            // }
                            // try {
                            //     await batch.execute();
                            // } catch (e) {
                            //     console.warn(
                            //         'BATCH_FAILED',
                            //         _fileChunk.map(
                            //             (item) => item['ServerRelativeUrl']
                            //         )
                            //     );
                            // }
                        }
                    } catch (e) {
                        console.warn(e);
                        console.warn('FOlder failed', item);
                    }

                    // const folder = this._sharePointService.web.getFolderByServerRelativePath(item.ServerRelativeUrl);
                    // const folderItem = await folder.getItem('FileLeafRef');
                    // folderItem.inBatch(batch).update({ FileLeafRef: `_${item.Name}` });
                }
            }
        }

        console.warn(`%c${listName} - FINISHED`, 'color:red;font-size:30px;');
    }

    public getAllFiles(folder: string, files: string[] = []): Promise<any> {
        return this._sharePointService.web
            .getFolderByServerRelativePath(folder.replace("'", ''))
            .expand(
                'ListItemAllFields, Folders, Files, Files/ListItemAllFields'
            )
            .select(
                'Folders/ServerRelativeUrl,Files/*, Files/ListItemAllFields'
            )()
            .then((folder: any) => {
                return Promise.all(
                    folder.Files.map((file) => files.push(file))
                ).then(() => {
                    return Promise.all(
                        folder.Folders.map((folder) =>
                            this.getAllFiles(folder.ServerRelativeUrl, files)
                        )
                    );
                });
            })
            .then(() => files);
    }

    /////////////////////////////////////
    // FIX AUFTRAGE AND AUGABE FOR ZEITEN AND MAILBOX

    public async fixAuftrageAufgabeZeitenMailbox(
        listName: string,
        fieldName: string
    ): Promise<void> {
        try {
            const aufgabeIdMapping = {};
            const augabenList =
                await this._sharePointService.web.lists.getByTitle(
                    'Auftragsaufgaben'
                );
            let taskItems = await augabenList.items.top(5000).getAll();

            taskItems.forEach((antrage) => {
                if (!antrage.Old_Id) {
                    return;
                }

                aufgabeIdMapping[antrage.Old_Id] = antrage.Id;
            });

            const list = await this._sharePointService.web.lists.getByTitle(
                listName
            );
            // const entityTypeFullName =
            //     await list.getListItemEntityTypeFullName();
            let listItems = await list.items.top(5000).getAll();

            // listItems.forEach(antrage => {
            //     if (!antrage.Old_TaskId) {
            //         return;
            //     }
            //
            //     aufgabeTaskIdMapping[antrage.Old_TaskId] = antrage.Id;
            // });

            const folders = listItems.filter(
                (assignment) => !!assignment.FileSystemObjectType
            );

            for (let i = 0; i < folders.length; i++) {
                if (
                    !folders[i].Title ||
                    !this.isNumeric(folders[i].Title) ||
                    folders[i].Title[0] === '_'
                ) {
                    continue;
                }

                let folderItems = await this._sharePointService.getListItems({
                    title: listName,
                    isDocumentLibrary: false,
                    recursiveAll: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .ToString(),
                    folderName: folders[i].Title,
                });
                folderItems = folderItems.filter(
                    (item) => !item.FileSystemObjectType
                );

                const chunks = splitArrayIntoChunks(
                    folderItems,
                    this.chunkSize
                );

                for (let j = 0; j < chunks.length; j++) {
                    // let batch = this._sharePointService.web.createBatch();
                    // let batchItems = list.items.inBatch(batch);
                    // let chunkItems = chunks[j];
                    // for (let c = 0; c < chunkItems.length; c++) {
                    //     const fieldId =
                    //         aufgabeIdMapping[(chunkItems[c] as any).Old_TaskId];
                    //     batchItems
                    //         .getById((chunkItems[c] as any).Id)
                    //         .inBatch(batch)
                    //         .update(
                    //             {
                    //                 ProjektId: Number(folders[i].Title),
                    //                 [fieldName]: Number(fieldId) || null,
                    //             },
                    //             '*',
                    //             entityTypeFullName
                    //         );
                    //     // batchItems.getById((chunkItems[c] as any).Id).inBatch(batch).update({ ProjektId: null, [fieldName]: null }, '*', entityTypeFullName);
                    // }
                    // await batch.execute();
                }
            }
            console.warn(
                `%c${listName} - FINISHED`,
                'color:red;font-size:30px;'
            );
            this.showSuccessSnackBar();
            this.hideLoader();
        } catch (e) {
            this.showErrorSnackBar();
            this.hideLoader();
        }
    }

    public async getDocumentsWithoutLookups(applyChanges: boolean): Promise<void> {
        const itemFolders = await this._sharePointService.web.lists
            .getByTitle('Aufgabendokumente')
            .rootFolder.select()
            .folders();
        let brokenFiles = [];

        // PROJECT FOLDERS
        for (let a = 0; a < itemFolders.length; a++) {

            if (
                !this.isNumeric(itemFolders[a].Name) ||
                itemFolders[a].Name[0] === '_'
            ) {
                console.warn(
                    `%cSkip Project: ${itemFolders[a].Name}`,
                    'color:orange;font-size:24px;'
                );
                continue;
            }

            console.warn(
                `%cProject: ${itemFolders[a].Name}`,
                'color:green;font-size:24px;'
            );
            const listFolders = await this._sharePointService.web
                .getFolderByServerRelativePath(itemFolders[a].ServerRelativeUrl)
                .folders();
            const chunks = splitArrayIntoChunks(listFolders, this.chunkSize);
            // console.log('Task folders chunks: ', chunks);

            // TASK FOLDERS CHUNK
            for (let i = 0; i < chunks.length; i++) {
                let chunkItems = chunks[i];

                // TASK FOLDERS
                for (let j = 0; j < chunkItems.length; j++) {
                    let item = chunkItems[j];

                    if (!this.isNumeric(item.Name) || item.Name[0] === '_') {
                        console.warn(
                            `%cSkip Task: ${item.Name}`,
                            'color:orange;font-size:24px;'
                        );
                        continue;
                    }

                    try {
                        const files = await this.getAllFiles(
                            item.ServerRelativeUrl
                        );

                        // if (files.length) {
                        //     console.log(`Files | ${item.ServerRelativeUrl}: `, files);
                        // }

                        const fileChunks = splitArrayIntoChunks(
                            files,
                            this.chunkSize
                        );

                        // FILES CHUNKS
                        for (let c = 0; c < fileChunks.length; c++) {
                            const _fileChunk = fileChunks[c];

                            // FILE CHUNK. Loop over chunk files
                            for (let f = 0; f < _fileChunk.length; f++) {
                                try {
                                    if (
                                        _fileChunk[f]['ListItemAllFields'] &&
                                        (_fileChunk[f]['ListItemAllFields']['AuftragId'] && _fileChunk[f]['ListItemAllFields']['AufgabeId']) &&
                                        (_fileChunk[f]['ListItemAllFields']['AuftragId'] == itemFolders[a].Name && _fileChunk[f]['ListItemAllFields']['AufgabeId'] == item.Name)
                                    ) {
                                        continue;
                                    }

                                    brokenFiles.push(_fileChunk[f]['ServerRelativeUrl']);
                                    console.warn(
                                        `%cBroken file:`,
                                        'color:red;font-size:18px;'
                                    );
                                    console.log(_fileChunk[f]['ServerRelativeUrl']);


                                    if (applyChanges) {
                                        this._sharePointService
                                            .updateItem({
                                                listTitle: 'Aufgabendokumente',
                                                id: _fileChunk[f]['ListItemAllFields'].ID,
                                                newFiledValues: {
                                                    // AufgabeId: newItem.AufgabeId,
                                                    // AuftragId: newItem.AuftragId,
                                                    AuftragId: Number(itemFolders[a].Name),
                                                    AufgabeId: Number(item.Name),
                                                },
                                            });
                                        console.warn(
                                            `%cFIXED`,
                                            'color:green;font-size:18px;'
                                        );
                                    }
                                    // const file = this._sharePointService.web
                                    //     .getFileByServerRelativeUrl(
                                    //         (_fileChunk[f] as any)
                                    //             .ServerRelativeUrl
                                    //     )
                                    //     .expand('ListItemAllFields');
                                    // const fileItem = await file.getItem();
                                    // fileItem.inBatch(batch).update({
                                    //     AuftragId: Number(itemFolders[a].Name),
                                    //     AufgabeId: Number(item.Name),
                                    // });
                                } catch (e) {
                                    console.error(_fileChunk[f]['ServerRelativeUrl']);
                                    console.error(e);
                                }
                            }
                        }
                    } catch (e) {
                        console.warn(e);
                        console.warn('FOlder failed', item);
                    }
                }
            }
        }

        console.warn(
            '%cDone',
            'color:green;font-size:18px;'
        );

        console.warn(
            `%cTotal broken files: ${brokenFiles.length}`,
            'color:red;font-size:18px;'
        );
        console.warn(brokenFiles);
        console.warn(JSON.stringify(brokenFiles));
    }

    private showLoader(title = 'Updating'): void {
        // this.loadingService.show(title, 'Please, wait');
    }

    private hideLoader(): void {
        this.loadingService.hide();
    }

    private isNumeric(value: string): boolean {
        return /^-?\d+$/.test(value);
    }

    private showSuccessSnackBar(): void {
        this._snackBarService.add('Successfully update', '', {
            duration: 4000,
            panelClass: 'success-dialog',
        });
    }

    private showErrorSnackBar(): void {
        this._snackBarService.add('Error', '', {
            duration: 4000,
            panelClass: 'error-dialog',
        });
    }
}
