export const locale = {
    lang: 'en',
    data: {
        NAV: {
            HEADER: '',
            START: {
                TITLE: 'Start',
                BADGE: '',
            },
            SCHEDULER: {
                TITLE: 'Scheduler',
                BADGE: '',
            },
            NEW: {
                TITLE: 'New Project',
                BADGE: '',
            },
            NEWORDER: {
                TITLE: 'New Order',
                BADGE: '',
            },
            NEWREQUEST: {
                TITLE: 'New Request',
                BADGE: '',
            },
            CURRENTUSER: {
                TITLE: 'My views',
                BADGE: '',
            },
            ASSIGNMENTS: {
                TITLE: 'Projects',
                BADGE: '',
            },
            ORDERS: {
                TITLE: 'Orders',
                BADGE: '',
            },
            REQUEST: {
                TITLE: 'Requests',
                BADGE: '',
            },
            WORKPACKAGES: {
                TITLE: 'Workpackages',
                BADGE: '',
            },
            TASKS: {
                TITLE: 'Tasks',
                BADGE: '',
            },
            TIMES: {
                TITLE: 'Times',
                BADGE: '',
            },
            MYTIMES: {
                TITLE: 'My times',
                BADGE: '',
            },
            REPORTS: {
                TITLE: 'Reports',
                BADGE: '',
            },
            CUSTOMERS: {
                TITLE: 'Customers',
                BADGE: '',
            },
            BILLS: {
                TITLE: 'Bills',
                BADGE: '',
            },
            TARGET: {
                TITLE: 'Target Figures',
                BADGE: '',
            },
            CONTROLLING: {
                TITLE: 'Controlling',
                BADGE: '',
            },
            EVALUATION: {
                TITLE: 'Evaluation',
                BADGE: '',
            },
            EMPLOYEEANALYSIS: {
                TITLE: 'Personenauswertung',
                BADGE: '',
            },
            PARTIALLY_FINISHED_SERVICES: {
                TITLE: 'Teilfertigeleistungen',
                BADGE: '',
            },
            ADMIN: {
                TITLE: 'Administration',
                BADGE: '',
            },
            UPDATE: {
                TITLE: 'Update',
                BADGE: '1',
            },
            CONFIGURATION: {
                TITLE: 'Configuration',
                BADGE: '',
            },
            TEMPLATES: {
                TITLE: 'Templates',
                BADGE: '',
            },
            GROUPS: {
                TITLE: 'Groups',
                BADGE: '',
            },
            SITECONTENT: {
                TITLE: 'Sitecontent',
                BADGE: '',
            },
        },
    },
};
