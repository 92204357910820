import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NotesAssignmentComponent } from './notes-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { QuillModule } from 'ngx-quill';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
    {
        path: 'projects/detail/notes',
        component: NotesAssignmentComponent,
    },
    {
        path: 'projects/detail/notes/:id',
        component: NotesAssignmentComponent,
    },
];

@NgModule({
    declarations: [NotesAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatSnackBarModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatFormFieldModule,
        QuillModule,
    ],
    exports: [NotesAssignmentComponent],
    bootstrap: [NotesAssignmentComponent],
})
export class NotesAssignmentModule {}
