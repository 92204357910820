import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DetailTasksetComponent } from 'src/app/main/taskset/detail-taskset/detail-taskset.component';
import { DocumentsAssignmentService } from 'src/app/main/assignment/documents-assignment/documents-assignment.service';
import { FuseTranslationLoaderService, SnackbarService } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { SharePointService } from 'sp-office365-framework';
import { LoadingService } from 'src/app/main/services/loading.service';
import { ApplicationService } from 'src/app/main/services/application.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import * as CamlBuilder from 'camljs';
import { from, Observable } from 'rxjs';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-holiday-details',
    templateUrl: './holiday-details.component.html',
    styleUrls: ['./holiday-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HolidayDetailsComponent implements OnInit {
    public id: number;
    public formioConfiguration;
    public formio_renderOptions = {
        // language: 'de'
    };
    public sharePointItem;
    public refreshForm: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public matDialogRef: MatDialogRef<DetailTasksetComponent>,
        public _documentService: DocumentsAssignmentService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _applicationService: ApplicationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        if (this.data) {
            this.id = this.data.Id;
        }

        if (this.id) {
            this._sharepointService
                .getItemById({ id: this.id, listTitle: 'Feiertage' })
                .then((processstep) => {
                    this.sharePointItem = processstep;
                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    private initForm(): void {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.TITLE'),
                    type: 'textfield',
                    key: 'Title',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.DATE'),
                    type: 'datetime',
                    key: 'Date',
                    format: 'dd.MM.yyyy',
                    enableTime: false,
                    validate: {
                        customMessage:
                            '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    minDate: new Date('01.01.1901'),
                    customOptions: {
                        locale: German
                    },
                },
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.LOCATION'),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'StandortId',
                    list: 'Standorte',
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    removable: true,
                    validate: {
                        customMessage:
                            '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.BUNDESLAND'),
                    type: 'textfield',
                    key: 'Bundesland',
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.FAKTOR'),
                    type: 'number',
                    key: 'Faktor',
                    inputFormat: 'plain',
                    validate: {
                        customMessage:
                            '{{field}} ist ein Pflichtfeld (sollte zwischen 0,5 und 1 liegen)',
                        required: true,
                        min: 0.5,
                        max: 1,
                    },
                },
                {
                    label: this._translateService.instant('HOLIDAY_DETAILS.FORM.SAVE'),
                    theme: 'accent',
                    disableOnInvalid: false,
                    type: 'button',
                    key: 'submit',
                    showValidations: true,
                },
            ],
        };
    }

    public formioReady(): void {
        let dataForm;
        const emptyObj = { Date: new Date() };

        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || { Date: new Date() }
            );
        } else {
            dataForm = emptyObj;
        }

        this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm)
            .then(
                () => this.refreshForm.emit({ submission: { data: dataForm } }),
                (error) => console.error(error)
            );
    }

    public saveSubmission(event): Observable<void> {
        let data = { ...event.data };
        this._loadingService.show(
            this._translateService.instant('HOLIDAY_DETAILS.NOTIFICATIONS.SAVING'),
            this._translateService.instant('HOLIDAY_DETAILS.NOTIFICATIONS.WAIT')
        );

        delete data.submit;
        const date = new Date(data.Date);
        date.setHours(5, 0, 0);
        data.Date = date;
        data.Month = date.getMonth() + 1;
        data.Year = date.getFullYear();

        this._applicationService.cleanDataForSaving(data, this.formioConfiguration.components);
        this._applicationService.callBeforeSaveFunctions(this.formioConfiguration, data);

        if (this.sharePointItem) {
            return from(
                this._sharepointService
                    .updateItem({
                        listTitle: 'Feiertage',
                        id: this.id,
                        newFiledValues: data,
                    })
                    .then(() => {
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('HOLIDAY_DETAILS.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            );
        } else {
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Feiertage',
                        data: data,
                        folder: '',
                    })
                    .then(() => {
                        this.matDialogRef.close();
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('HOLIDAY_DETAILS.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            );
        }
    }

}
