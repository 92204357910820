import { DetailDocumentComponent } from './../document/detail-document/detail-document.component';
import { NgModule } from '@angular/core';
import { OrdReqDocsComponent } from './ord-req-docs.component';
import { AddFolderOrdReqComponent } from './add-folder-ord-req/add-folder-ord-req.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { DetailDocumentModule } from '../document/detail-document/detail-document.module';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
    {
        path: 'orders/detail/documents/:id',
        component: OrdReqDocsComponent,
    },
    {
        path: 'requests/detail/documents/:id',
        component: OrdReqDocsComponent,
    },
];

@NgModule({
    declarations: [OrdReqDocsComponent, AddFolderOrdReqComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        MatDialogModule,
        MatSnackBarModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        DetailDocumentModule,
    ],
    exports: [OrdReqDocsComponent]
})
export class OrdReqDocsModule {}
