import { Injectable } from '@angular/core';
import { forkJoin, from, Observable } from 'rxjs';
import { Assignment, Bill } from '../../shared/models';
import { SharePointService } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { map, switchMap } from 'rxjs/operators';
import { TimeService } from '../../services/time.service';
import { AssignmentBudgetService } from '../../services/assignment-budget.service';
import { splitArrayIntoChunks } from '../../shared/shared.util';
import { PROJECT_EVALUATION_DEFAULT_BILL } from '../../project-evaluation/project-evaluation.constant';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class PartiallyFinishedServicesService {
    private bills: Record<number, any> = {};

    constructor(
        private _sharePointService: SharePointService,
        private _timeService: TimeService,
        private _assignmentBudgetService: AssignmentBudgetService
    ) {}

    public loadAllTimesByProjects(projects: Assignment[]): Observable<any[]> {
        const projectIds = projects.map((project) => project.Id);
        const projectIdsChunks = splitArrayIntoChunks(projectIds);
        const chunks$: Observable<any>[] = [];

        projectIdsChunks.forEach((projectIdsChunk) => {
            chunks$.push(
                this._timeService.getAllTimeByProjects(projectIdsChunk)
            );
        });

        return forkJoin(chunks$).pipe(
            map((times) => {
                const _times = times.flat();
                const costHours = {};
                const projectHours = {};

                _times.forEach((time) => {
                    if (!costHours[time.ProjektId]) {
                        costHours[time.ProjektId] = {};
                        projectHours[time.ProjektId] = 0;
                    }

                    projectHours[time.ProjektId] =
                        projectHours[time.ProjektId] + time.Kosten;
                });

                for (let i = 0; i < projects.length; i++) {
                    const gewinnmarge = projects[i].Gewinnmarge ?? 0;
                    projects[i]._TotalHours = projectHours[projects[i].Id] ?? 0;
                    projects[i]._WorkInProgressCost =
                        projects[i].Antragssumme +
                        projects[i].NebenkostenEuro -
                        projects[i].Fremdleistung -
                        (gewinnmarge / 100) *
                            (projects[i].Antragssumme +
                                projects[i].NebenkostenEuro) -
                        projects[i]._TotalHours;
                }

                return _times;
            })
        );
    }

    public getOpenExternalProjects(): Observable<any[]> {
        return from(
            this._sharePointService.getListItems({
                title: 'Aufträge',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .LookupField('Auftragskategorie')
                    .ValueAsText()
                    .EqualTo('Projekt')
                    .And()
                    .ChoiceField('Auftragsstatus')
                    .NotEqualTo('Auftrag abgeschlossen')
                    .ToString(),
            })
        ).pipe(
            map((assignments) => {
                assignments.forEach((assignment) => {
                    if (assignment.Auftragsstart) {
                        assignment.Auftragsstart = new Date(
                            assignment.Auftragsstart
                        );
                    }

                    if (assignment.Auftragsende) {
                        assignment.Auftragsende = new Date(
                            assignment.Auftragsende
                        );
                    }

                    assignment.NebenkostenEuro =
                        assignment.Antragssumme *
                        ((assignment.Nebenkosten ?? 0) / 100);
                    assignment.Auftragsbudget =
                        assignment.Antragssumme -
                        (assignment.Fremdleistung || 0) +
                        assignment.NebenkostenEuro;
                });
                return assignments;
            })
        );
    }

    public exportPartiallyFinishedServices(
        sliderValue: number
    ): Observable<any[]> {
        return new Observable((observer) => {
            this.getOpenExternalProjects()
                .pipe(
                    switchMap((projects: Assignment[]) => {
                        const projectsIds = projects.map(
                            (project) => project.Id
                        );
                        return forkJoin([
                            this.loadBills(projectsIds),
                            this.loadAllTimesByProjects(projects),
                        ]).pipe(
                            map(([bills, times]) => [projects, bills, times])
                        );
                    })
                )
                .subscribe(([projects, _bills, _times]) => {
                    const data = [];
                    projects.forEach((project) => {
                        const projectBills = _bills.filter(
                            (bill) => bill.ProjektId === project.Id
                        );
                        const projectTimes = _times.filter(
                            (time) => time.ProjektId === project.Id
                        );

                        // const project = {} as any;
                        project.title = project.Title;
                        project.billCosts =
                            projectBills?.reduce(
                                (acc, bill) => bill.Forderung + acc,
                                0
                            ) ?? 0;
                        project.timeCosts =
                            projectTimes?.reduce(
                                (acc, time) => time.Kosten + acc,
                                0
                            ) ?? 0;
                        project.calculated =
                            (project.timeCosts - project.billCosts) *
                            sliderValue;
                        project._Bills =
                            this.bills[project.Id] ??
                            PROJECT_EVALUATION_DEFAULT_BILL;
                        project._RemainingFee =
                            project.Antragssumme +
                            project.NebenkostenEuro -
                            project._Bills.Total;
                        project._InvoiceWithFactor = 0;
                        project._ServicesTotalPrice = 0;

                        if (project.Auftragsstart && project.Auftragsende) {
                            project.StartEndDateDifference = moment(
                                project.Auftragsende
                            ).diff(moment(project.Auftragsstart), 'month');
                        }

                        if (project._TotalHours > project._Bills.Internal) {
                            project._InvoiceWithFactor =
                                (project._TotalHours - project._Bills.Total) *
                                sliderValue;
                        }

                        if (!project._Bills.External) {
                            if (project.StartEndDateDifference > 0) {
                                project._ServicesTotalPrice =
                                    project._InvoiceWithFactor +
                                    project.Fremdleistung /
                                        project.StartEndDateDifference;
                            }
                        } else if (
                            project._Bills.External === project.Fremdleistung
                        ) {
                            project._ServicesTotalPrice =
                                project._InvoiceWithFactor;
                        } else if (project._Bills.External > 0) {
                            if (project.StartEndDateDifference > 0) {
                                project._ServicesTotalPrice =
                                    project._InvoiceWithFactor +
                                    (project.Fremdleistung /
                                        project.StartEndDateDifference -
                                        project._Bills.External);
                            }
                        }

                        if (project.StartEndDateDifference > 0) {
                            const gewinnmarge = project.Gewinnmarge ?? 0;
                            if (
                                project._TotalHours >= project._Bills.Internal
                            ) {
                                project._WIP =
                                    project._TotalHours *
                                        (1 + gewinnmarge / 100) -
                                    project._Bills.Internal +
                                    (project.Fremdleistung -
                                        project._Bills.External) /
                                        project.StartEndDateDifference;
                            } else {
                                project._WIP =
                                    (project.Fremdleistung -
                                        project._Bills.External) /
                                    project.StartEndDateDifference;
                            }
                        } else {
                            project._WIP = 0;
                        }

                        data.push(project);
                    });
                    data.sort((project1, project2) =>
                        project1.title.localeCompare(project2.title)
                    );
                    observer.next(data);
                    observer.complete();
                });
        });
    }

    public exportFeeNotChargedProjects(): Observable<any[]> {
        return new Observable((observer) => {
            this.getOpenExternalProjects()
                .pipe(
                    switchMap((projects: Assignment[]) => {
                        const projectIds = projects.map(
                            (project) => project.Id
                        );
                        return this.loadAllTimesByProjects(projects).pipe(
                            map((times) => {
                                return [projects, times];
                            })
                        );
                    })
                )
                .subscribe(([projects, _times]) => {
                    // const requests$ = [];
                    const data = [];
                    projects.forEach((project) => {
                        const _project = {} as any;
                        const projectTimes = _times.filter(
                            (time) => time.ProjektId === project.Id
                        );
                        _project.title = project.Title;
                        _project.budget = project.Antragssumme;
                        _project.timeCosts =
                            projectTimes?.reduce(
                                (acc, time) => time.Kosten + acc,
                                0
                            ) ?? 0;
                        _project.calculated =
                            project.Antragssumme - _project.timeCosts;
                        data.push(_project);
                    });
                    data.sort((project1, project2) =>
                        project1.title.localeCompare(project2.title)
                    );

                    observer.next(data);
                    observer.complete();
                });
        });
    }

    private loadBills(projectIds: number[]): Observable<any> {
        const projectIdsChunks = splitArrayIntoChunks(projectIds);
        const chunks$: Observable<Bill>[] = [];

        projectIdsChunks.forEach((projectIdsChunk) => {
            chunks$.push(
                from(
                    this._sharePointService.getListItems({
                        title: 'Rechnungen',
                        isDocumentLibrary: false,
                        camlQuery: new CamlBuilder()
                            .Where()
                            .LookupField('Projekt')
                            .Id()
                            .In(projectIdsChunk)
                            .ToString(),
                        recursiveAll: true,
                    })
                )
            );
        });

        return forkJoin(chunks$).pipe(
            map((billsFlatten: Bill[]) => {
                const bills = billsFlatten.flat();
                this.bills = {};
                bills.forEach((bill) => {
                    let billRecord = this.bills[bill.ProjektId];

                    if (!billRecord) {
                        this.bills[bill.ProjektId] = {
                            Internal: 0,
                            External: 0,
                            Total: 0,
                        };
                        billRecord = this.bills[bill.ProjektId];
                    }

                    billRecord.Internal = billRecord.Internal + bill.Forderung;
                    billRecord.Total = billRecord.Total + bill.Forderung + 0;
                });

                return billsFlatten;
            })
        );
    }
}
