export const locale = {
    lang: 'en',
    data: {
        LV_TASK: {
            TITLE: 'TASKS',
            SEARCH_LABEL: 'Search for a task',
            ALL: 'All tasks',
            MY: 'My tasks',
            OVERDUEDATE: 'Overdue tasks',
            COLUMNS: {
                TITLE: 'Task',
                DUEDATE: 'Duedate',
                STATUS: 'Status',
                TASK: 'Task',
                ASSIGNED: 'Assigned to',
                PROJECT: 'Project',
                DESCRIPTION: 'Description',
                KIND: 'Taskkind',
                WORKPACKAGE: 'Workpackage',
                START: 'Start',
                PRIORITY: 'Priority',
                HIGH: '(1) High',
                NORMAL: '(2) Normal',
                LOW: '(3) Low',
                NOTSTARTED: 'Not started',
                INPROCESSING: 'In processing',
                COMPLETED: 'Completed',
                RESET: 'Reset',
                WAITING: 'Waiting',
                TYPE: 'Tasktype',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                PROCESSSTEP: 'Processstep',
            },
            NEW: 'New task',
            EDIT: 'Edit task',
            SAVE: 'Save',
        },
    },
};
