<div class="update-step">
    <!--    <div style="display: none">-->
    <!--        <h2>Step 1</h2>-->
    <!--        <div class="controls">-->
    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.addPrefixToAllProjectDocumentLibraries()" class="updateButton">-->
    <!--                Add prefix (ALL document libraries)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="newTenantMigrationService.removePrefixToAllProjectDocumentLibraries()"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (ALL document libraries)-->
    <!--            </button>-->

    <!--            <hr />-->

    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Auftragsdokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Auftragsdokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Aufgabendokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Aufgabendokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Zeiten', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Zeiten list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Aufträge-Budgets', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Aufträge-Budgets list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Auftragsaufgaben', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Auftragsaufgaben list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Mailbox', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Mailbox list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Projektkommentare', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Projektkommentare list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Rechnungen', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Rechnungen list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.addDocumentLibraryFolderPrefix('Auftragsbeteiligte', false)"-->
    <!--                    class="updateButton">-->
    <!--                Add prefix (Auftragsbeteiligte list)-->
    <!--            </button>-->

    <!--            <hr />-->

    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="newTenantMigrationService.removeDocumentLibraryFolderPrefix('Auftragsdokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (Auftragsdokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Aufgabendokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (Aufgabendokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Zeiten', false)"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (Zeiten list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Aufträge-Budgets', false)"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (Aufträge-Budgets list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="warn"-->
    <!--                    (click)="this.newTenantMigrationService.removeDocumentLibraryFolderPrefix('Auftragsaufgaben', false)"-->
    <!--                    class="updateButton">-->
    <!--                Remove prefix (Auftragsaufgaben list)-->
    <!--            </button>-->

    <!--            &lt;!&ndash;        <hr />&ndash;&gt;-->
    <!--            &lt;!&ndash;        <button mat-raised-button color="warn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                (click)="newTenantMigrationService.removeDocumentLibraryFolderSpecialChars('Auftragsdokumente')"&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="updateButton">&ndash;&gt;-->
    <!--            &lt;!&ndash;            Remove all special chars (Auftragsdokumente document library)&ndash;&gt;-->
    <!--            &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--            &lt;!&ndash;        <button mat-raised-button color="warn"&ndash;&gt;-->
    <!--            &lt;!&ndash;                (click)="newTenantMigrationService.removeDocumentLibraryFolderSpecialChars('Aufgabendokumente')"&ndash;&gt;-->
    <!--            &lt;!&ndash;                class="updateButton">&ndash;&gt;-->
    <!--            &lt;!&ndash;            Remove all special chars (Aufgabendokumente document library)&ndash;&gt;-->
    <!--            &lt;!&ndash;        </button>&ndash;&gt;-->
    <!--        </div>-->

    <!--        <h2>Step 2</h2>-->
    <!--        <div class="controls">-->
    <!--            <button mat-raised-button color="accent"-->
    <!--                    class="updateButton"-->
    <!--                    (click)="newTenantMigrationService.fixAllProjectDocumentLibraries()"-->
    <!--            >-->
    <!--                Fix ALL <strong>document libraries</strong>-->
    <!--            </button>-->

    <!--            <hr />-->

    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Auftragsdokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Auftragsdokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Aufgabendokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Aufgabendokumente document library)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Zeiten', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Zeiten list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Aufträge-Budgets', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Aufträge-Budgets list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Auftragsaufgaben', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Auftragsaufgaben list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Mailbox', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Mailbox list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Projektkommentare', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Projektkommentare list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Rechnungen', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Rechnungen list)-->
    <!--            </button>-->
    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixDocumentLibraryFolderName('Auftragsbeteiligte', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Auftragsbeteiligte list)-->
    <!--            </button>-->

    <!--        </div>-->

    <!--        <h2>Step 3</h2>-->
    <!--        <div class="controls">-->
    <!--            <button mat-raised-button color="accent"-->
    <!--                    (click)="newTenantMigrationService.fixAntrageDocumentLibraryFolderName('Antragsdokumente', true)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Antragsdokumente document library)-->
    <!--            </button>-->

    <!--            <button mat-raised-button color="primary"-->
    <!--                    (click)="newTenantMigrationService.fixAntrageDocumentLibraryFolderName('Mailbox-Anträge', false)"-->
    <!--                    class="updateButton">-->
    <!--                Fix folder name (Mailbox-Anträge list)-->
    <!--            </button>-->
    <!--            <h2>Step 4</h2>-->
    <!--            <div class="controls">-->
    <!--                <button mat-raised-button color="accent"-->
    <!--                        (click)="newTenantMigrationService.addPrefixToTaskFolder('Aufgabendokumente')"-->
    <!--                        class="updateButton">-->
    <!--                    Add prefix folder name (Aufgabendokumente document library)-->
    <!--                </button>-->

    <!--                <button mat-raised-button color="warn"-->
    <!--                        (click)="newTenantMigrationService.removePrefixTaskFolder('Aufgabendokumente')"-->
    <!--                        class="updateButton">-->
    <!--                    Remove prefix folder name (Aufgabendokumente document library)-->
    <!--                </button>-->
    <!--            </div>-->

    <!--            <h2>Step 5</h2>-->
    <!--            <div class="controls">-->
    <!--                <button mat-raised-button color="accent"-->
    <!--                        (click)="newTenantMigrationService.fixTaskDocumentLibraryFolderName('Aufgabendokumente')"-->
    <!--                        class="updateButton">-->
    <!--                    Fix folder name (Aufgabendokumente document library)-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--    <h2>Step 6</h2>-->
    <!--    <div class="controls">-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary('Auftragsdokumente', 'AuftragId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Auftragsdokumente document library)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Zeiten', 'ProjektId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Zeiten list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Aufträge-Budgets', 'Auftr_x00e4_geId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Aufträge-Budgets list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Auftragsaufgaben', 'AuftragId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Auftragsaufgaben list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Mailbox', 'ProjektId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Mailbox list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Projektkommentare', 'ProjektId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Projektkommentare list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Rechnungen', 'ProjektId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Rechnungen list)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Auftragsbeteiligte', 'AuftragId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Auftragsbeteiligte list)-->
    <!--        </button>-->
    <!--    </div>-->

    <!--    <h2>Step 7</h2>-->
    <!--    <div class="controls">-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary('Antragsdokumente', 'AntragId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Antragsdokumente document library)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdDocumentLibrary1('Mailbox-Anträge', 'AntragId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Mailbox-Anträge list)-->
    <!--        </button>-->
    <!--    </div>-->

    <!--    <h2>Step 8</h2>-->
    <!--    <div class="controls">-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.assignProjectLookupIdAufgabeDocumentLibrary('Aufgabendokumente')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Aufgabendokumente document library)-->
    <!--        </button>-->
    <!--    </div>-->

    <!--    <h2>Step 9</h2>-->
    <!--    <div class="controls">-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.fixAuftrageAufgabeZeitenMailbox('Zeiten', 'AufgabeId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Zeiten document library)-->
    <!--        </button>-->
    <!--        <button mat-raised-button color="accent"-->
    <!--                (click)="newTenantMigrationService.fixAuftrageAufgabeZeitenMailbox('Mailbox', 'ArbeitspaketId')"-->
    <!--                class="updateButton">-->
    <!--            Fix LOOKUP field (Mailbox document library)-->
    <!--        </button>-->
    <!--    </div>-->

    <div class="controls">
        <button mat-raised-button
                color="accent"
                (click)="newTenantMigrationService.getDocumentsWithoutLookups(false)"
                class="updateButton"
        >
            Show documents without lookups
        </button>
        <button mat-raised-button
                color="accent"
                (click)="newTenantMigrationService.getDocumentsWithoutLookups(true)"
                class="updateButton"
        >
            Fix documents without lookups
        </button>
    </div>
</div>
