export const locale = {
    lang: 'de',
    data: {
        ADMIN: 'Administration',
        UPDATE: 'Update',
        AVAILABLE: 'Ein Update ist vorhanden.',
        RUN: 'Update einzeln durchführen',
        RUNALL: 'Alle Updates durchführen',
        INSTALL: 'Anwendung installieren',
        NOADMIN: 'Sie sind kein Sitecollectionadministrator.',
        UPTODATE1: 'Die Anwendung ist auf dem aktuellen Stand.',
        UPTODATE2: 'Kein Update verfügbar.',
        CURRENT: 'Aktuelle Version:',
        NOCURRENT: 'Aktuelle Version: Nicht installiert',
        LATEST: 'Neuste Version:',
        RUNNING: 'Update wird durchgeführt.',
        DURATION: 'Dieser Vorgang kann mehrere Minuten dauern.',
        CONSOLE: 'Siehe Konsole für Details.',
        ERROR: 'Fehler',
        UPDATESUCCESS: 'Update erfolgreich!',
    },
};
