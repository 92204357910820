export const locale = {
    lang: 'en',
    data: {
        TIMEASSIGNMENT: {
            PROJECTNUMBER: 'Projectnumber',
            PROJECTS: 'Projects',
            TIMES: 'Times',
            NEW: 'Add new time',
            NOTIFICATIONS: {
                PERMISSION: 'DO you really want to delete the selected element?',
                SUCCESS: 'The entry was deleted successfully.',
            },
            COLUMNS: {
                DATE: 'Date',
                MINUTES: 'Duration (in minutes)',
                TASK: 'Task',
                AUTHOR: 'Employee',
            },
            FILTER: {
                YEAR: "Jahr",
                MONTH: "Monat",
                MONTHS: {
                    ALL: " ",
                    1: "Januar",
                    2: "Februar",
                    3: "März",
                    4: "April",
                    5: "Mai",
                    6: "Juni",
                    7: "Juli",
                    8: "August",
                    9: "September",
                    10: "Oktober",
                    11: "November",
                    12: "Dezember",
                },
                EMPLOYEE: "Mitarbeiter",
            },
        },
    },
};
