import { LookupService } from './lookup.service';
import { Injectable } from '@angular/core';
import CamlBuilder from 'camljs';
import { SharePointTableComponent } from 'sp-office365-framework';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';

@Injectable({
  providedIn: 'root'
})



export class SharepointEntrysService {

  //Variables for the chart in "Controlling/Evaluation/ProjectEvaluation"
  public yearChart = {
    from: this.getCurrentYear(),
    until: this.getCurrentYear(),
  };
  public monthChart = {
    from: this.getCurrentMonth(),
    until: this.getCurrentMonth(),
  };

  //Variables for the table in "Controlling/Bills"
  public yearTable: number;
  public monthTable: number;
  public quarter: number;
  public currentListView: ListViewComponent;
  public currentTable: SharePointTableComponent;



  //***  Both Caml Querys are used to filter entrys from the lists "Rechnungen" and "SollZahlen" ***

  constructor(private lookupService: LookupService) { }

  get camlQueryAll() {
    return new CamlBuilder()
      .Where()
      .NumberField("ID")
      .NotEqualTo(-1)
      .OrderBy("Jahr")
      .ThenBy("Monat")
      .ToString();
  }

  //filters entry for the chart in the navigation "Controlling/Evaluation"
  get filteredEntrysForChart() {
    let camlbuilder;
    let teamsIds: number[] = [];

    if (this.yearChart.from < this.yearChart.until) {
      let firstYearExpression;
      let yearsInBetweenExpression;
      let lastYearExpression;

      // All Entrys for the first year of the specified period (Teams and Location not considered)
      firstYearExpression = CamlBuilder.Expression()
        .NumberField("Monat")
        .GreaterThanOrEqualTo(this.monthChart.from)
        .And()
        .NumberField("Jahr")
        .EqualTo(this.yearChart.from);

      // All Entrys for the last year of the specified period (Teams and Location not considered)
      lastYearExpression = CamlBuilder.Expression()
        .NumberField("Monat")
        .LessThanOrEqualTo(this.monthChart.until)
        .And()
        .NumberField("Jahr")
        .EqualTo(this.yearChart.until);

      //All Entrys Betwenn the last and the first year of the specified value
      yearsInBetweenExpression = CamlBuilder.Expression()
        .NumberField("Jahr")
        .GreaterThan(this.yearChart.from)
        .And()
        .NumberField("Jahr")
        .LessThan(this.yearChart.until);

      let allExpressions = [
        firstYearExpression,
        yearsInBetweenExpression,
        lastYearExpression,
      ];

      //Change all Expresion via forEach-Loop if a location or a team is set
      if (this.lookupService.teams.length > 0 || this.lookupService.location) {
        if (this.lookupService.teams.length > 0) {
          teamsIds = [...this.lookupService.teams];
        } else {
          teamsIds = this.lookupService.teamsFromLocation.length > 0 ? [...this.lookupService.teamsFromLocation] : [0];
        }

        allExpressions.forEach((expression) => {
          expression = expression.And().LookupField("Team").Id().In(teamsIds);
        });
      }

      camlbuilder = new CamlBuilder()
        .Where()
        .Any(allExpressions[0], allExpressions[1], allExpressions[2]);
    } else {
      //If "year from" is equal to "year until"
      camlbuilder = new CamlBuilder()
        .Where()
        .NumberField("Jahr")
        .EqualTo(this.yearChart.from)
        .And()
        .NumberField("Monat")
        .GreaterThanOrEqualTo(this.monthChart.from)
        .And()
        .NumberField("Monat")
        .LessThanOrEqualTo(this.monthChart.until);

      //If a team or location is set the camlquery will be changed
      if (this.lookupService.teams.length > 0) {
        teamsIds = [...this.lookupService.teams];
        camlbuilder = camlbuilder.And().LookupField("Team").Id().In(teamsIds)
      }
      else if (this.lookupService.teamsFromLocation.length > 0 || this.lookupService.location) {

        teamsIds = this.lookupService.teamsFromLocation.length > 0 ? [...this.lookupService.teamsFromLocation] : [0]

        camlbuilder = camlbuilder.And().LookupField("Team").Id().In(teamsIds)
      }
    }

    return camlbuilder
      .And()
      .NumberField("FSObjType")
      .EqualTo(0)
      .OrderBy("Jahr")
      .ThenBy("Monat")
      .ToString();
  }


  //Camlyquery to filter entrys for the table in "Controlling/Bills"
  get filteredEntrysForTable() {
    let camlbuilder;
    let yearExpression;
    let monthExpression;
    let quarterExpression;
    let teamExpression;

    if (this.yearTable) {
      yearExpression = CamlBuilder.Expression()
        .NumberField("Jahr")
        .EqualTo(this.yearTable)
        .And()
        .NumberField("FSObjType")
        .EqualTo(0);

      if (!camlbuilder) {
        camlbuilder = new CamlBuilder().Where().Any(yearExpression);
      } else {
        camlbuilder = camlbuilder.And().Any(yearExpression);
      }
    }

    if (this.monthTable && this.monthTable != 0 && this.quarter == undefined) {
      monthExpression = CamlBuilder.Expression()
        .NumberField("Monat")
        .EqualTo(this.monthTable)
        .And()
        .NumberField("FSObjType")
        .EqualTo(0);

      if (!camlbuilder) {
        camlbuilder = new CamlBuilder().Where().Any(monthExpression);
      } else {
        camlbuilder = camlbuilder.And().Any(monthExpression);
      }
    }

    if (this.quarter) {
      quarterExpression = CamlBuilder.Expression()
        .NumberField("Monat")
        .GreaterThanOrEqualTo(this.quarterToMonth().start)
        .And()
        .NumberField("Monat")
        .LessThanOrEqualTo(this.quarterToMonth().end)
        .And()
        .NumberField("FSObjType")
        .EqualTo(0);

      if (!camlbuilder) {
        camlbuilder = new CamlBuilder().Where().Any(quarterExpression);
      } else {
        camlbuilder = camlbuilder.And().Any(quarterExpression);
      }
    }
    //Kein Team gesetzt, dafür aber ein Standort
    if (this.lookupService.location && this.lookupService.teams.length == 0) {
      //If teamIds ist equal to [0] --> The choosen location has no Team --> No entry will be shown on the table
      let teamsIds : number[] =  this.lookupService.teamsFromLocation.length > 0 ? [...this.lookupService.teamsFromLocation] : [0]

      teamExpression = CamlBuilder.Expression()
        .LookupField("Team")
        .Id()
        .In(teamsIds)
        .And()
        .NumberField("FSObjType")
        .EqualTo(0);

      if (!camlbuilder) {
        camlbuilder = new CamlBuilder().Where().Any(teamExpression);
      } else {
        camlbuilder = camlbuilder.And().Any(teamExpression);
      }
    }

    //Team gesetzt (Möglicherweise auch ein Standort)
    if (this.lookupService.teams.length > 0) {
      teamExpression = CamlBuilder.Expression()
        .LookupField("Team")
        .Id()
        .In(this.lookupService.teams)
        .And()
        .NumberField("FSObjType")
        .EqualTo(0);

      if (!camlbuilder) {
        camlbuilder = new CamlBuilder().Where().Any(teamExpression);
      } else {
        camlbuilder = camlbuilder.And().Any(teamExpression);
      }
    }

    return camlbuilder ? camlbuilder.ToString() : this.camlQueryAll;
  }

  getCurrentQuarter() {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;

    return Math.ceil(month / 3);
  }

  getCurrentMonth() {
    const currentDate = new Date();

    return currentDate.getMonth() + 1;
  }

  getCurrentYear() {
    const currentDate = new Date();

    return currentDate.getFullYear();
  }

  quarterToMonth(): { start: number; end: number } {
    switch (this.quarter) {
      case 1:
        return { start: 1, end: 3 };

      case 2:
        return { start: 4, end: 6 };

      case 3:
        return { start: 7, end: 9 };

      case 4:
        return { start: 10, end: 12 };
    }
  }
}
