import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CamlBuilder from 'camljs';
import { SharePointTableComponent, TableConfiguration, ColumnTyp, FormatTypes } from 'sp-office365-framework';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { ApplicationService } from '../services/application.service';
import { locale as english } from "./i18n/en";
import { locale as german } from "./i18n/de";

@Component({
  selector: 'app-fremdkosten',
  templateUrl: './fremdkosten.component.html',
  styleUrls: ['./fremdkosten.component.scss']
})
export class FremdkostenComponent implements OnInit {
  @ViewChild("externalCostGrid", { static: false })
  externalCostGrid: SharePointTableComponent;

  public tableConfig: TableConfiguration;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _applicationService: ApplicationService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit() {

    this.tableConfig = {
      list: "Fremdkosten",
      columns: [
        {
          internalName: "Kosten",
          title: this._translateService.instant("COSTS"),
          hidden: false,
          type: ColumnTyp.Text,
        },
        {
          internalName: "Rechnungsdatum",
          title: this._translateService.instant("BILLDATE"),
          hidden: false,
          type: ColumnTyp.Date,
          format: FormatTypes.yyyyMMdd
        },
        { internalName: "ID", title: "ID", hidden: true },
      ],
      isDocumentLibrary: false,
      showEditButton: false,
      showDeleteButton: true,
      recursiveAll: true,
      camlQuery: new CamlBuilder()
        .Where()
        .LookupField("Projekt")
        .Id()
        .EqualTo(this._applicationService.currentProjectId)
        .OrderByDesc("Modified")
        .ToString(),
      useMaterialDesign: true,
      afterRefreshTable: (data, table) => {},
    };
  }
}
