import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { MailService } from 'sp-office365-layout';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-resizable-box',
    templateUrl: './resizable-box.component.html',
    styleUrls: ['./resizable-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResizableBoxComponent implements OnInit, OnDestroy {
    public touched: boolean;
    private destroy$ = new Subject();
    @ViewChild('leftSideContentWrapper', { static: true }) leftSideContentWrapper: ElementRef;
    @ViewChild('rightSideContentWrapper', { static: true }) rightSideContentWrapper: ElementRef;

    constructor(private mailService: MailService) {
    }

    ngOnInit(): void {
        this.mailService.onCurrentMailChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const mailDetailsElement = this.rightSideContentWrapper.nativeElement.querySelector('mail-details');
                if (mailDetailsElement) {
                    mailDetailsElement.scrollTop = 0;
                }
            });
    }

    public onResizeEnd(event: ResizeEvent): void {
        this.touched = true;
        this.leftSideContentWrapper.nativeElement['style'].width = event.rectangle.width + 'px';
    }

    public onResizeStart(): void {
        const [el1, el2] = Array.from(document.querySelectorAll('mail-list')) as HTMLElement[];
        el2.scrollTop = el1.scrollTop;
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }

}
