<div class="page-layout simple left-sidebar inner-sidebar inner-scroll">
    <!-- HEADER -->
    <div
        class="header accent p-16 p-sm-24"
        fxLayout="column"
        fxLayoutAlign="start"
        fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center"
    >
        <!-- APP TITLE -->

        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon
                    class="logo-icon mr-4"
                    [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
                >my_library_books
                </mat-icon>
                <span
                    class="logo-text h1"
                    [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }"
                >
          {{ "PROJECTEVALUATION.EVALUATION" | translate }}
        </span>
            </div>
        </div>
        <!-- / APP TITLE -->

    </div>
    <!-- / HEADER -->

    <div id="content">
        <div class="content">
            <div class="p-6">
                <app-project-evaluation-filter></app-project-evaluation-filter>
                <app-project-evaluation-table></app-project-evaluation-table>
            </div>
        </div>
    </div>
</div>
