export const locale = {
  lang: "de",
  data: {
    EMPLOYEEANALYSIS: {
      EMPLOYEEANALYSIS: "Personenauswertung",
    },
    EMPLOYEEANALYSIS_FILTER: {
      YEAR: "Jahr",
      MONTH: "Monat",
      MONTHS: {
        ALL: " ",
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      EMPLOYEE: "Mitarbeiter",
      TEAMMANAGER: "Teamleiter",
    },
  },
};
