import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1072Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );

            const mailboxList = this._sharePointService.web.lists.getByTitle(
                'Mailbox'
            );

            const promises = [
                // Remove Field Notizen from List Aufträge
                await projectList.fields.getByTitle('Notizen').delete(),
                // Add Field Notizen to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Notizen',
                    FieldType.MultilineText
                ),
                // // Remove Field Body from List Mailbox
                // await projectList.fields.getByTitle('Body').delete(),
                // // Add Field Body to List Mailbox
                // await this._updateService.addField(
                //     mailboxList,
                //     'Body',
                //     FieldType.MultilineText
                // ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
