import { NewTimeModule } from './../../time/new-time/new-time.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { DetailInvoiceComponent } from './detail-invoice.component';
import { FormioModule } from '@formio/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogInvoicesComponent } from './confirm-dialog-invoices/confirm-dialog-invoices.component';
import { NewTimeComponent } from '../../time/new-time/new-time.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TableInvoiceModule } from '../table-invoice/table-invoice.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [DetailInvoiceComponent, ConfirmDialogInvoicesComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        NewTimeModule,
        TableInvoiceModule,
        MatSlideToggleModule,
        MatIconModule,
        DragDropModule,
        MatDialogModule
    ],
    exports: [DetailInvoiceComponent]
})
export class DetailInvoiceModule {}
