<!-- Header -->
<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">

    <span *ngIf="this.id" class="title dialog-title">
      {{ "EDIT" | translate }}
    </span>

    <span *ngIf="!this.id" class="title dialog-title">
      {{ "NEW" | translate }}
    </span>

    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>

  </mat-toolbar-row>
</mat-toolbar>
<!-- End Header -->


<!-- Content -->
<div id='content'>

  <formio class="formio-material" #formioComponent [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this"
    [renderOptions]="formio_renderOptions" (change)="onChange($event)"
    [form]="formioConfiguration">
  </formio>

  <div *ngIf="this.isCreateMode">
    <kendo-grid
      #kendoGridComponent
      [data]="this.gridView"
      *ngIf="this.tableConfig"
      [selectable]="{ enabled: true, checkboxOnly: true }"
      (cellClick)="cellClickHandler($event)"
      (cellClose)="cellCloseHandler($event)"
      (save)="cellSaveHandler($event)"
    >
      <kendo-grid-column
        *ngFor="let column of this.tableConfig.columns"
        editor="text"
        [field]="column.internalName"
        [hidden]="column.hidden"
        [title]="column.title"
      >

      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem[column.internalName] ? dataItem[column.internalName] : 'keine Eingabe' }}</span>
      </ng-template>
      <ng-template *ngIf="column.editable"
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup">
          <input type="text" [formControl]="formGroup.get(column.internalName)" [name]="column.internalName" />
      </ng-template>

      </kendo-grid-column>
    </kendo-grid>

    <button
      mat-raised-button
      color="primary"
      style="background-color: #64b144; margin: 24px;"
      (click)="saveCreation()">
        {{"SAVE" | translate}}
    </button>
  </div>

</div>
<!-- End Content -->
