import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1075Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList: IList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            const orderList: IList = this._sharePointService.web.lists.getByTitle(
                'Anträge'
            );
            const propertyList: IList = this._sharePointService.web.lists.getByTitle(
                'Properties'
            );
            const companyList: IList = this._sharePointService.web.lists.getByTitle(
                'Firmen'
            );
            const kostenstelleList: IList = this._sharePointService.web.lists.getByTitle(
                'Kostenstellen'
            );
            const locationList: IList = this._sharePointService.web.lists.getByTitle(
                'Standorte'
            );
            let docTemplateList: IList;

            const promises = [
                // Add List Dokumentenvorlagen
                await this._updateService.addList(
                    'Dokumentenvorlagen',
                    '',
                    101,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Dokumentenvorlagen
                await new Promise<void>((resolve) => {
                    docTemplateList = this._sharePointService.web.lists.getByTitle(
                        'Dokumentenvorlagen'
                    );
                    resolve();
                }),
                // Add Field Uebergabe to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Uebergabe',
                    FieldType.Date
                ),
                // Add Field Projektsteuerer to List Aufträge
                await this._updateService.addLookupField(
                    projectList,
                    companyList,
                    'Projektsteuerer'
                ),
                // Add Field Kostenstelle to List Aufträge (Pflicht)
                await this._updateService.addLookupField(
                    projectList,
                    kostenstelleList,
                    'Kostenstelle'
                ),
                // Add Field Standort to List Aufträge
                await this._updateService.addLookupField(
                    projectList,
                    locationList,
                    'Standort'
                ),
                // Add Field Akkreditiert to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Akkreditiert',
                    FieldType.Boolean
                ),
                // Add Field Sachbearbeiter to List Aufträge
                await this._updateService.addUserField(
                    projectList,
                    'Sachbearbeiter',
                    true
                ),
                // Add Field Antragssumme to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Antragssumme',
                    FieldType.Currency
                ),
                // Add Field Nebenkosten to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Nebenkosten',
                    FieldType.Number
                ),
                // Add Field Fremdleistung to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'Fremdleistung',
                    FieldType.Currency
                ),
                // Add Field Genehmigt to List Anträge
                await this._updateService.addField(
                    orderList,
                    'Genehmigt',
                    FieldType.Boolean
                ),
                // Add Items to List Properties
                await propertyList.items.add({
                    Title: 'Standard Gruppen für Genehmigungen',
                    Value: 'Genehmiger',
                }),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
