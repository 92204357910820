import { TranslateService } from '@ngx-translate/core';
import { InjectHeaders } from '@pnp/queryable';
import { SharePointService } from 'sp-office365-framework';
import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class MoveDocumentService {
    constructor(
        private _sharePointService: SharePointService,
        private _translateService: TranslateService,
        private _snackBarService: SnackbarService
    ) {}

    showErrorMessage(): void {
        this._snackBarService.add(
            this._translateService.instant('MOVE_SERVICE.ERROR'),
            '',
            {
                duration: 4000,
                panelClass: 'error-dialog',
            }
        );
    }

    moveFile(oldURL: string, destinationURL: string) {
        return new Promise<void>(async (resolve, reject) => {
            this._sharePointService.web
                .using(
                    InjectHeaders({
                        Accept: 'application/json;odata=fullmetadata',
                        'Content-Type': 'application/json;charset=utf-8',
                    })
                )
                .getFileByServerRelativePath(oldURL)
                .moveByPath(destinationURL, false, true)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }

    checkMoveDestination(newItem, oldItem, saveCase: saveCase): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let promise: Promise<void>;

            switch (saveCase.task) {
                case 'removed':
                    promise = this.moveAfterTaskRemoved(newItem, oldItem);
                    break;

                case 'changed':
                    promise = this.moveAfterTaskChanged(newItem, oldItem);
                    break;

                case 'added':
                    promise = this.moveAfterTaskAdded(newItem, oldItem);
                    break;

                // Only possible when no task was set before and ist still not setted
                case 'noChangesMade':
                    promise = this.moveWithinProject(newItem, oldItem);
                    break;
            }

            promise
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }

    getDestinationURL(
        oldItem,
        newItem,
        currentList: string,
        destinationList: string
    ): string {
        const splitSlash = oldItem.fileRef.split('/');

        //Get Name of the file or folder
        const elementName = splitSlash[splitSlash.length - 1];

        let urlArray: string[];
        if (oldItem.AufgabeId) {
            urlArray = oldItem.fileRef.split(
                currentList + '/' + oldItem.AuftragId + '/' + oldItem.AufgabeId
            );
        } else {
            urlArray = oldItem.fileRef.split(
                currentList + '/' + oldItem.AuftragId
            );
        }

        let destinationURL: string =
            urlArray[0] + destinationList + '/' + newItem.AuftragId + '/';

        if (destinationList == 'Aufgabendokumente') {
            destinationURL = destinationURL + newItem.AufgabeId + '/';
        }

        destinationURL = destinationURL + elementName;

        return destinationURL;
    }

    // But task has  changed! --> Project might have changed. But it works for both cases!
    // This method also works if only the task changed but not the project!
    moveAfterTaskChanged(newItem, oldItem): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let promise: Promise<void>;

            const destinationURL = this.getDestinationURL(
                oldItem,
                newItem,
                'Aufgabendokumente',
                'Aufgabendokumente'
            );

            //element is a file
            promise = this.moveFile(oldItem.fileRef, destinationURL);
            promise
                .then(() => {
                    this._sharePointService
                        .updateItem({
                            listTitle: 'Aufgabendokumente',
                            id: oldItem.id,
                            newFiledValues: {
                                AufgabeId: newItem.AufgabeId,
                                AuftragId: newItem.AuftragId,
                            },
                        })
                        .then((result) => {
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.showErrorMessage();
                        });
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }

    // A task which was set before is now removed
    // --> Move file/folder from "Aufgabendokumente" to "Auftragsdokumente"
    moveAfterTaskRemoved(newItem, oldItem): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const destinationURL = this.getDestinationURL(
                oldItem,
                newItem,
                'Aufgabendokumente',
                'Auftragsdokumente'
            );

            //First updateItem before moving the element, because the element gets a new ID after beeing moved to another location (and another list!).
            // --> No need to make a get-request to get the new ID
            this._sharePointService
                .updateItem({
                    listTitle: 'Aufgabendokumente',
                    id: oldItem.id,
                    newFiledValues: {
                        AuftragId: newItem.AuftragId,
                        AufgabeId: null,
                    },
                })
                .then(() => {
                    this.moveFile(oldItem.fileRef, destinationURL)
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.showErrorMessage();
                        });
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }

    //No task was set before but is now selected --> Move item from "Auftragsdokumente" to "Aufgabendokumente"
    moveAfterTaskAdded(newItem, oldItem): Promise<void> {
        return new Promise(async (resolve, reject): Promise<void> => {
            const destinationURL = this.getDestinationURL(
                oldItem,
                newItem,
                'Auftragsdokumente',
                'Aufgabendokumente'
            );

            this.moveFile(oldItem.fileRef, destinationURL)
                .then(async () => {
                    try {
                        const file = await this._sharePointService.web.getFileByServerRelativePath(destinationURL).getItem('Id');
                        await this._sharePointService
                            .updateItem({
                                listTitle: 'Aufgabendokumente',
                                id: file['Id'],
                                newFiledValues: {
                                    AuftragId: newItem.AuftragId,
                                    AufgabeId: newItem.AufgabeId,
                                },
                            });
                        resolve();
                    } catch(error) {
                        console.error(error);
                        this.showErrorMessage();
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }

    //No task was set before and still is not set, only the Project changed
    // --> Movement within the list "Austragsdokumente"
    moveWithinProject(newItem, oldItem) {
        return new Promise<void>((resolve) => {
            const destinationURL = this.getDestinationURL(
                oldItem,
                newItem,
                'Auftragsdokumente',
                'Auftragsdokumente'
            );

            this.moveFile(oldItem.fileRef, destinationURL)
                .then(() => {
                    this._sharePointService
                        .updateItem({
                            listTitle: 'Auftragsdokumente',
                            id: oldItem.id,
                            newFiledValues: {
                                AuftragId: newItem.AuftragId,
                            },
                        })
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.showErrorMessage();
                        });
                })
                .catch((err) => {
                    console.error(err);
                    this.showErrorMessage();
                });
        });
    }
}
