<div id="maintenance" fxLayout="column">
  <div id="maintenance-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="maintenance-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img id="customLogo" width="128" src="" />
      </div>

      <div class="title">{{ "TITLE" | translate }}</div>

      <div class="subtitle">
        <span [innerHTML]="'SUBTITLE' | translate"></span>
      </div>
    </div>
  </div>
</div>
