import {
    Component,
    Inject,
    OnInit,
    Optional,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DayPilotSchedulerComponent, DayPilot } from 'daypilot-pro-angular';
import { SharePointService } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import PerfectScrollbar from 'perfect-scrollbar';
import * as CamlBuilder from 'camljs';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DetailTaskComponent } from '../task/detail-task/detail-task.component';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class SchedulerComponent implements OnInit {
    @ViewChild('scheduler', { static: false })
    scheduler: DayPilotSchedulerComponent;

    public schedulerReady = false;
    public config: any;
    public currentUser: any;
    public isLoading: boolean;

    private events = [];
    private resources = [];
    private currentItems = [];
    private startDate: Date;
    private endDate: Date;
    private timerangeDays = Math.round(window.innerWidth / 58.5);
    private startField: string;
    private endField: string;
    private currentUserId: number = this.sharePointService.currentUser.Id;

    get activeAssignmentsCamlQuery() {
        return new CamlBuilder()
            .Where()
            .UserField('Auftragsleiter')
            .Id()
            .EqualTo(this.currentUserId)
            .And()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag angelegt')
            .Or()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag in Arbeit')
            .ToString();
    }

    get camlQueryTasks() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .OrderBy('Modified')
            .ToString();
    }

    constructor(
        public dialog: MatDialog,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private router: Router,
        @Optional() public dialogRef: MatDialogRef<any>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        this.currentUser = this.sharePointService.currentUser;
        this.isLoading = true;

        if (this.data && this.data.auftragsId) {
            this.startField = 'StartDate';
            this.endField = 'DueDate';

            // Get all tasks from wp
            this.sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    camlQuery: this.camlQueryTasks,
                    isDocumentLibrary: false,
                    folderName: this.data.auftragsId + '',
                    recursiveAll: false,
                })
                .then((tasks) => {
                    this.currentItems = tasks;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.startField = 'Auftragsstart';
            this.endField = 'Auftragsende';

            // Get all active projects
            this.sharePointService
                .getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: this.activeAssignmentsCamlQuery,
                })
                .then((projects) => {
                    this.currentItems = projects;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    openDialog(clickedElementId: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.data.auftragsId,
            isTask: true,
            taskId: clickedElementId,
            arbeitsPaketId: this.data.taskId,
        };

        const dialogRefTask = this.dialog.open(
            DetailTaskComponent,
            dialogConfig
        );

        this.initAfterClose(dialogRefTask);
    }

    initAfterClose(dialogRef) {
        dialogRef.afterClosed().subscribe((result) => {
            // Get all tasks from wp
            this.sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    camlQuery: this.camlQueryTasks,
                    isDocumentLibrary: false,
                    folderName: this.data.auftragsId + '',
                    recursiveAll: false,
                })
                .then((tasks) => {
                    this.currentItems = tasks;
                    this.initScheduler();
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    filter(filter) {
        switch (filter) {
            case 1:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 365,
                    timeHeaders: [{ groupBy: 'Month' }, { groupBy: 'Week' }],
                    scale: 'Week',
                });
                break;
            case 2:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 730,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 3:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1095,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 4:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1460,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 5:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1825,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 6:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        2
                    ).toISOString(),
                    // Get number of days in current month
                    days: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                    ).getDate(),
                    timeHeaders: [
                        { groupBy: 'Month' },
                        { groupBy: 'Day', format: 'dd.' },
                    ],
                    scale: 'Day',
                });
                break;
            default:
                this.initScheduler();
                break;
        }
    }

    initScheduler() {
        const self = this;

        this.startDate = new Date();
        this.endDate = new Date();

        this.resources = [];
        this.events = [];

        this.currentItems.forEach((item) => {
            if (new Date(item[this.startField]) < this.startDate) {
                this.startDate = new Date(item[this.startField]);
            }

            if (new Date(item[this.endField]) > this.endDate) {
                this.endDate = new Date(item[this.endField]);
            }

            this.resources.push({
                name: item.Title,
                id: item.Id,
                expanded: false,
                // children: [{ name: 'Production Line A.2', id: 'R2' }]
            });

            this.events.push({
                id: item.Id,
                start: this.addHours(
                    new Date(item[this.startField]),
                    2
                ).toISOString(),
                end: this.addHours(
                    new Date(item[this.endField]),
                    3
                ).toISOString(),
                resource: item.Id,
                text: item.Title,
                bubbleHtml:
                    '<b>' +
                    item.Title +
                    '</b></br>' +
                    this._translateService.instant('START') +
                    ': ' +
                    new Date(item[this.startField]).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('END') +
                    ': ' +
                    new Date(item[this.endField]).toLocaleDateString(),
            });
        });

        if (
            this.timerangeDays <
            Math.ceil((+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24))
        ) {
            this.timerangeDays = Math.ceil(
                (+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24)
            );
        }

        this.config = {
            timeHeaders: [
                { groupBy: 'Month' },
                { groupBy: 'Day', format: 'dd.' },
            ],
            scale: 'Day',
            days: this.timerangeDays,
            startDate: new Date(
                this.addHours(this.addDays(this.startDate, -1), 2)
            ).toISOString(),
            eventHeight: 50,
            cellWidth: 50,
            durationBarVisible: false,
            eventStackingLineHeight: 75,
            crosshairType: 'Header',
            separators: [
                {
                    color: 'red',
                    location: new DayPilot.Date(),
                    width: 5,
                },
            ],
            locale: navigator.language,
            rowHeaderWidthAutoFit: false,
            rowHeaderWidth: 120,
            rowHeaderHideIconEnabled: true,
            rowMinHeight: 50,
            events: this.events,
            resources: this.resources,
            onAfterRender(args) {
                self.scheduler.control.scrollTo(
                    self.addDays(new Date(), -7).toISOString()
                );

                const container: any = document.querySelector(
                    'daypilot-scheduler div .scheduler_default_scrollable'
                );
                const ps = new PerfectScrollbar(container);
            },
            eventHoverHandling: 'Bubble',
            eventClickHandling: 'Enabled',
            onEventClicked(args) {
                if (self.data && self.data.auftragsId) {
                    self.openDialog(args.e.id());
                } else {
                    self.router.navigate(
                        ['projects/detail/timeline/' + args.e.id()],
                        {
                            queryParamsHandling: 'merge',
                        }
                    );
                }
            },
            eventResizeHandling: 'Disabled',
            onEventResized: (args) => {
                this.scheduler.control.message(
                    this._translateService.instant('RESIZED')
                );
            },
            eventMoveHandling: 'Disabled',
            onEventMoved: (args) => {
                this.scheduler.control.message(
                    this._translateService.instant('MOVED')
                );
            },
        };

        this.schedulerReady = true;
    }

    addHours(date: Date, h: number): Date {
        const result = new Date(date);
        result.setTime(date.getTime() + h * 60 * 60 * 1000);

        return result;
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}
