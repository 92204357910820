<listview
    #listView
    *ngIf="config"
    [config]="config"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
    <ng-template customCell for="StandortID" let-dataItem="dataItem">
        {{ showNames(dataItem) }}
    </ng-template>
</listview>
