import { SharepointEntrysService } from './../../services/sharepoint-entrys.service';
import { LookupService } from './../../services/lookup.service';
import {
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    FrameworkService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { TableControllingComponent } from '../../controlling/table-controlling/table-controlling.component';
import CamlBuilder from 'camljs';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-filter-bills',
    templateUrl: './filter-bills.component.html',
    styleUrls: ['./filter-bills.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FilterBillsComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de'
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();
    public formioConfiguration;
    public firstTimeLoad = true;
    public sharePointItem;
    public showNewTimeForm = true;
    private periodValues = ['months', 'quartal'];

    public isManager = false;

    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;

    constructor(
        private _applicationService: ApplicationService,
        private _lookupService: LookupService,
        private _frameworkService: FrameworkService,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointEntrysService: SharepointEntrysService,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        const promises = [];

        // Check if currentUser is Manager
        if (this._sharePointService.currentUser.IsBusinessOwner || this._currentUserService.isTeamProjectLeiter()) {
            this.isManager = true;
        }
        if (this._sharepointEntrysService.currentListView) {
            this._sharepointEntrysService.currentListView.changeQuery(
                this._sharepointEntrysService.filteredEntrysForTable
            );
        }

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    ngOnDestroy() {
        this._lookupService.reset();
    }

    updateCamlQuery(key: 'TeamId' | 'StandortId'): void {
        let camlQuery;
        let searchCaml;

        camlQuery =
            key == 'StandortId'
                ? this._lookupService.locationCAMLQuery
                : this._lookupService.teamCAMLQuery;
        searchCaml =
            key == 'StandortId'
                ? this._lookupService.locationSearchCAMLQuery
                : this._lookupService.teamSearchCAMLQuery;

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQueryIfIsEmpty',
            camlQuery
        );

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQuery',
            searchCaml
        );
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;

        // Set default values
        dataForm.Hotkeys = 'Monat';
        dataForm.months = this._sharepointEntrysService.getCurrentMonth();
        dataForm.year = this._sharepointEntrysService.getCurrentYear();

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    onChange(ev) {
        if (ev.add) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = ev.Id;
                new Promise<void>((resolve, reject) => {
                    this._sharePointService
                        .getListItems({
                            title: 'Teams',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .LookupField('Standort')
                                .Id()
                                .EqualTo(ev.Id)
                                .ToString(),
                        })
                        .then((results) => {
                            const teams = results;
                            let teamId: number[] = [];
                            teams.forEach((team) => {
                                teamId.push(team.Id);
                            });
                            this._lookupService.teamsFromLocation = [...teamId];
                            this._sharepointEntrysService.currentListView.changeQuery(
                                this._sharepointEntrysService
                                    .filteredEntrysForTable
                            );
                            this._sharepointEntrysService.currentTable.changeQuery(
                                this._sharepointEntrysService
                                    .filteredEntrysForTable
                            );
                            resolve();
                        })
                        .catch((err) => {
                            console.error(err);
                            reject();
                        });
                });
            } else {
                this._lookupService.teams.push(ev.Id);
                this._lookupService.locationLookUpId = ev.StandortId;
                this._sharepointEntrysService.currentListView.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );
                this._sharepointEntrysService.currentTable.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );
            }
        } else if (ev.remove) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
                this._sharepointEntrysService.currentListView.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );
                this._sharepointEntrysService.currentTable.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );
            } else {
                let leftTeamsInArray = this._lookupService.teams.filter(
                    (value) => {
                        return value != ev.Id;
                    }
                );
                this._lookupService.teams = leftTeamsInArray;

                this._lookupService.locationLookUpId = null;

                this._sharepointEntrysService.currentListView.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );

                this._sharepointEntrysService.currentTable.changeQuery(
                    this._sharepointEntrysService.filteredEntrysForTable
                );
            }
        }

        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
            } else if (ev.changed.component.key == 'Hotkeys') {
                const dataForm = this.formioComponent.formio._data;
                switch (ev.changed.value) {
                    case 'Monat':
                        this.periodValues.forEach((value) => {
                            this._frameworkService.updateFormioComponentProperty(
                                this.formioComponent,
                                value,
                                'hidden',
                                value != 'months' ? true : false
                            );
                        });
                        dataForm.months =
                            this._sharepointEntrysService.getCurrentMonth();
                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;

                    case 'Quartal':
                        this.periodValues.forEach((value) => {
                            this._frameworkService.updateFormioComponentProperty(
                                this.formioComponent,
                                value,
                                'hidden',
                                value != 'quartal' ? true : false
                            );
                        });
                        dataForm.quartal =
                            this._sharepointEntrysService.getCurrentQuarter();
                        this.refreshForm.emit({
                            submission: {
                                data: dataForm,
                            },
                        });
                        break;
                }

                if (this._sharepointEntrysService.currentListView) {
                    this._sharepointEntrysService.monthTable = ev.data.months;
                    this._sharepointEntrysService.yearTable = ev.data.year;
                    this._sharepointEntrysService.currentListView.changeQuery(
                        this._sharepointEntrysService.filteredEntrysForTable
                    );
                    this._sharepointEntrysService.currentTable.changeQuery(
                        this._sharepointEntrysService.filteredEntrysForTable
                    );
                }
            } else {
                if (this._sharepointEntrysService.currentListView) {
                    this._sharepointEntrysService.monthTable = ev.data.months;
                    this._sharepointEntrysService.yearTable = ev.data.year;
                    this._sharepointEntrysService.quarter = ev.data.quartal;
                    this._sharepointEntrysService.currentListView.changeQuery(
                        this._sharepointEntrysService.filteredEntrysForTable
                    );
                    this._sharepointEntrysService.currentTable.changeQuery(
                        this._sharepointEntrysService.filteredEntrysForTable
                    );
                }
            }
        }
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.YEAR'
                                    ),
                                    type: 'textfield',
                                    key: 'year',
                                    hidden: false,
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.TIME'
                                    ),
                                    type: 'select',
                                    key: 'Hotkeys',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH_'
                                                ),
                                                value: 'Monat',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.QUARTER_'
                                                ),
                                                value: 'Quartal',
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.QUARTER_'
                                    ),
                                    type: 'select',
                                    key: 'quartal',
                                    hidden: true,
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.QUARTER.1'
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.QUARTER.2'
                                                ),
                                                value: 2,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.QUARTER.3'
                                                ),
                                                value: 3,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.QUARTER.4'
                                                ),
                                                value: 4,
                                            },
                                        ],
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'FILTER.MONTH_'
                                    ),
                                    type: 'select',
                                    key: 'months',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.ALL'
                                                ),
                                                value: 0,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.1'
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.2'
                                                ),
                                                value: 2,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.3'
                                                ),
                                                value: 3,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.4'
                                                ),
                                                value: 4,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.5'
                                                ),
                                                value: 5,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.6'
                                                ),
                                                value: 6,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.7'
                                                ),
                                                value: 7,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.8'
                                                ),
                                                value: 8,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.9'
                                                ),
                                                value: 9,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.10'
                                                ),
                                                value: 10,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.11'
                                                ),
                                                value: 11,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.12'
                                                ),
                                                value: 12,
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'LV_BILLS.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.locationCAMLQuery,
                                    searchQuery:
                                        this._lookupService
                                            .locationSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('TeamId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('TeamId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.TEAM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'TeamId',
                                    list: 'Teams',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.teamCAMLQuery,
                                    searchQuery:
                                        this._lookupService.teamSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    multi: true,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('StandortId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('StandortId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                {
                    label: '',
                    type: 'contentCustom',
                    component: TableControllingComponent,
                    key: '',
                },
            ],
        };
    }
}
