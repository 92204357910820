import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import {
    TableConfiguration,
    SharePointService,
    ColumnTyp,
} from 'sp-office365-framework';

import * as CamlBuilder from 'camljs';

import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ApplicationService } from '../../services/application.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-detail-taskset',
    templateUrl: './detail-taskset.component.html',
    styleUrls: ['./detail-taskset.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailTasksetComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de',
    };

    public refreshForm: EventEmitter<any> = new EventEmitter();

    @Input() id: number;

    public tableConfigTasktemplates: TableConfiguration;
    public formioConfiguration;
    public sharePointItem;

    private toolbarArray = [];

    get camlQueryTasksTemplates() {
        return new CamlBuilder()
            .Where()
            .LookupField('Aufgabenset')
            .Id()
            .EqualTo(this.id)
            .OrderBy('Modified')
            .ToString();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<DetailTasksetComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private sharepointService: SharePointService,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService,
        private _applicationService: ApplicationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.toolbarArray = [
            {
                title: this._translateService.instant(
                    'DETAIL_TASKSET.NEWTASKTEMPLATE'
                ),
                command: 'Add',
                color: 'accent',
            },
        ];
    }

    ngOnInit() {
        if (this.data) {
            this.id = this.data.tasksetId;

            if (this.data.readOnly) {
                this.toolbarArray = [];
            }
        }

        if (this.id != null) {
            this.sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Aufgabensets',
                })
                .then((taskset) => {
                    this.sharePointItem = taskset;

                    this.initForm();
                });
        } else {
            this.initForm();
        }
    }

    saveSubmission(e) {
        this.loadingService.show(
            this._translateService.instant(
                'DETAIL_TASKSET.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('DETAIL_TASKSET.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.sharePointItem) {
            console.log(e.data);
            return from(
                this.sharepointService
                    .updateItem({
                        id: this.sharePointItem.Id,
                        listTitle: 'Aufgabensets',
                        newFiledValues: e.data,
                    })
                    .then(
                        () => {
                            this.sharepointService
                                .getItemById({
                                    listTitle: 'Aufgabensets',
                                    id: this.id,
                                })
                                .then(
                                    () => {
                                        this.loadingService.hide();

                                        this.snackBarService.add(
                                            this._translateService.instant(
                                                'DETAIL_TASKSET.NOTIFICATIONS.SUCCESS'
                                            ),
                                            '',
                                            {
                                                duration: 4000,
                                                panelClass: 'success-dialog',
                                            }
                                        );

                                        this.close('success');
                                    },
                                    (error) => {
                                        this.loadingService.hide();
                                        console.log(error);
                                    }
                                );
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        } else {
            console.log(e.data);
            return from(
                this.sharepointService
                    .addItem({
                        listTitle: 'Aufgabensets',
                        data: e.data,
                        folder: '',
                    })
                    .then(
                        () => {
                            this.loadingService.hide();

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'DETAIL_TASKSET.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        }
    }

    close(message?): void {
        this.matDialogRef.close(message);
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    onChange(ev) {}

    onTabChange(ec) {}

    initForm() {
        if (this.id) {
            this.tableConfigTasktemplates = {
                list: 'Aufgabenvorlagen',
                columns: [
                    {
                        internalName: 'Title',
                        title: this._translateService.instant(
                            'DETAIL_TASKSET.COLUMNS.TITLE'
                        ),
                        hidden: false,
                        type: ColumnTyp.Text,
                    },
                    { internalName: 'ID', title: 'ID', hidden: true },
                ],
                isDocumentLibrary: false,
                showEditButton: !this.data.readOnly,
                showDeleteButton: !this.data.readOnly,
                addCheckboxColumn: false,
                recursiveAll: true,
                camlQuery: this.camlQueryTasksTemplates,
                useMaterialDesign: true,
                toolbar: this.toolbarArray,
            };
        }

        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_TASKSET.COLUMNS.TITLE'
                    ),
                    hideLabel: false,
                    disabled: this.data.readOnly,
                    placeholder: '',
                    allowMultipleMasks: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    defaultValue: '',
                    validate: {
                        required: true,
                        customMessage: '',
                        json: '',
                    },
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_TASKSET.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                    hidden: this.data.readOnly,
                },
            ],
        };
    }
}
