export const locale = {
    lang: 'de',
    data: {
        LV_TIME: {
            TITLE: 'Meine Zeiten',
            PROJECTS: 'Projekte',
            TIMES: 'Zeiten',
            NEW: 'Neue Zeit erfassen',
            NOTIFICATIONS: {
                PERMISSION: 'Wollen Sie das gewählte Element wirklich löschen?',
                SUCCESS: 'Der Zeiteintrag wurde erfolgreich gelöscht.',
            },
            COLUMNS: {
                DATE: 'Datum',
                MINUTES: 'Dauer (in Minuten)',
                TASK: 'Aufgabe',
                AUTHOR: 'Mitarbeiter',
            },
        },
        FILTER: {
            YEAR: "Jahr",
            MONTH: "Monat",
            MONTHS: {
                ALL: " ",
                JANUARY: "Januar",
                FEBRUARY: "Februar",
                MARCH: "März",
                APRIL: "April",
                MAY: "Mai",
                JUNE: "Juni",
                JULY: "Juli",
                AUGUST: "August",
                SEPTEMBER: "September",
                OCTOBER: "Oktober",
                NOVEMBER: "November",
                DECEMBER: "Dezember",
            },
            EMPLOYEE: "Mitarbeiter",
            TEAMMANAGER: "Teamleiter",
        },
    },
};
