import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as CamlBuilder from 'camljs';
import { ColumnTyp, FormatTypes, SharePointService } from 'sp-office365-framework';
import {
    ListViewConfig,
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-listview-order',
    templateUrl: './listview-order.component.html',
    styleUrls: ['./listview-order.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ListviewOrderComponent {
    public config: ListViewConfig;

    get allOrdersCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .OrderBy('Title')
            .ToString();
    }

    get allOrdersSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    get openOrdersCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Genehmigt')
            .IsFalse()
            .Or()
            .BooleanField('Genehmigt')
            .IsNull()
            .And()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .OrderBy('Title')
            .ToString();
    }

    get openOrdersSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Genehmigt')
            .IsFalse()
            .Or()
            .BooleanField('Genehmigt')
            .IsNull()
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .And()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .OrderBy('Title')
            .ToString();
    }

    get closedOrdersCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Genehmigt')
            .IsTrue()
            .And()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .OrderBy('Title')
            .ToString();
    }

    get closedOrdersSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .BooleanField('Genehmigt')
            .IsTrue()
            .And()
            .BooleanField('Ist_Auftrag')
            .IsTrue()
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _currentUserService: CurrentUserService,
        private router: Router
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initConfig();
    }

    initConfig() {
        const hasRootPermissions = this._sharepointService.currentUser.IsBusinessOwner ||
            this._sharepointService.currentUser.IsAdministrator ||
            this._currentUserService.isTeamProjectLeiter();
        this.config = {
            title: this._translateService.instant('LV_ORDER.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_ORDER.SEARCH_LABEL'
            ),
            icon: 'call_missed_outgoing',
            add_icon: 'add',
            searchDebounceTime: 1000,
            openEditInDialog: false,
            // Delete group}
            onRowClick: (item) => {
                // console.log(item);
                this.router.navigate(['orders/detail/basedata/' + item.ID], {
                    queryParamsHandling: 'merge',
                });
            },
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_ORDER.OPEN'),
                    config: {
                        list: 'Anträge',
                        // groups: [{ field: 'Auftragsstatus' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Team',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.TEAM'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Standort',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.LOCATION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.openOrdersCamlQuery,
                    },
                    camlQueryForSearch: this.openOrdersSearchCamlQuery,
                },
                {
                    title: this._translateService.instant('LV_ORDER.CLOSED'),
                    config: {
                        list: 'Anträge',
                        // groups: [{ field: 'Auftragsstatus' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Uebergabe',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.HANDINGOVER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Team',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.TEAM'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Standort',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.LOCATION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.closedOrdersCamlQuery,
                    },
                    camlQueryForSearch: this.closedOrdersSearchCamlQuery,
                },
                {
                    title: this._translateService.instant('LV_ORDER.ALL'),
                    config: {
                        list: 'Anträge',
                        // groups: [{ field: 'Auftragsstatus' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.DESCRIPTION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftraggeber',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.CLIENT'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Team',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.TEAM'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Standort',
                                title: this._translateService.instant(
                                    'LV_ORDER.COLUMNS.LOCATION'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.allOrdersCamlQuery,
                    },
                    camlQueryForSearch: this.allOrdersSearchCamlQuery,
                },
            ],
        };
    }
}
