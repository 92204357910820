<form [formGroup]="form">
    <mat-form-field>
        <input matInput
               formControlName="commonQuery"
               type="text"
               autocomplete="off"
               [placeholder]="'MAILFILTER.FILTER_EMAILS' | translate"
        >
        <button *ngIf="form.value.commonQuery" matSuffix mat-icon-button aria-label="Clear" (click)="cleanUpField('commonQuery')">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             formControlName="fromUser"
             type="text"
             autocomplete="off"
             [placeholder]="'MAILFILTER.FROM' | translate"
      >
      <button *ngIf="form.value.fromUser" matSuffix mat-icon-button aria-label="Clear" (click)="cleanUpField('fromUser')">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             formControlName="toUser"
             type="text"
             autocomplete="off"
             [placeholder]="'MAILFILTER.TO' | translate"
      >
      <button *ngIf="form.value.toUser" matSuffix mat-icon-button aria-label="Clear" (click)="cleanUpField('toUser')">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
</form>
