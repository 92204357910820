import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumenttemplateComponent } from '../documenttemplate/documenttemplate.component';
import {
    SharePointService,
    SharePointTableComponent,
} from 'sp-office365-framework';
import '@pnp/graph/users';

@Injectable({
    providedIn: 'root',
})
export class DocTemplatesService {
    constructor(
        private _applicationService: ApplicationService,
        public dialog: MatDialog,
        private _sharepointService: SharePointService
    ) {}

    async createDocument(options: {
        templateUrl: string;
        elementIds: string;
        fileName?: string;
    }) {
        try {
            if (!options.fileName) {
                options.fileName = this.getFileNameFromUrl(options.templateUrl);
            }

            let graphUser = await this._sharepointService.graph.me.select(
                'mail, displayName, streetAddress, businessPhones, mobilePhone, officeLocation, city, postalCode, mySite, faxNumber'
            )();

            let currentUser: any = {};

            if (graphUser) {
                if (graphUser.displayName) {
                    currentUser.DisplayName = graphUser.displayName;
                }
                if (graphUser.businessPhones.length > 0) {
                    currentUser.BusinessPhone = graphUser.businessPhones[0];
                }
                if (graphUser.mobilePhone) {
                    currentUser.MobilePhone = graphUser.mobilePhone;
                }
                if (graphUser.mail) {
                    currentUser.Mail = graphUser.mail;
                }
            }

            const result = await this._applicationService.callApiWithAuthNew(
                'https://mopa-server.azurewebsites.net/api/Document/ReplacePlaceHoldersInWordAsync',
                [
                    {
                        Name: 'fileServerRelativeUrl',
                        Value: options.templateUrl,
                    },
                    {
                        Name: 'elementIds',
                        Value: options.elementIds,
                    },
                    {
                        Name: 'currentUser',
                        Value: JSON.stringify(currentUser),
                    },
                ],
                null,
                'arraybuffer'
            );

            return result;
        } catch (error) {
            throw error;
        }
    }

    async downloadBloob(blob, fileName) {
        try {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();

            return true;
        } catch (error) {
            throw error;
        }
    }

    getFileNameFromUrl(url: string): string {
        const pathData = url.split('/');
        const fileName = pathData.pop().split('.');
        return fileName.join('.');
    }

    openDocTemplateDialog(
        itemOptions: {
            itemId: number;
            listName: string;
            lookupFieldName?: string;
        }[],
        docOptions: {
            docListName: string;
            docLFolderName: string;
            lookupFieldName?: string;
        },
        fieldValues: any,
        documentGrid: SharePointTableComponent,
        category: string
    ): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.7 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = 'dialog-without-padding';

        dialogConfig.data = {
            itemOptions,
            docOptions,
            fieldValues,
            category,
        };

        const dialogRef = this.dialog.open(
            DocumenttemplateComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            documentGrid.refresh();
        });
    }
}
