<div *ngIf="!showOnlyBillsChart" class='booked'>
    <mat-form-field>
        <mat-label>{{ "OV_BUDGET.BOOKEDCURRENCY" | translate }}</mat-label>
        <input matInput disabled="true" [value]="this._budgetService.currentCosts" type="number">
        <mat-hint>{{ "OV_BUDGET.INEURO" | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "OV_BUDGET.BOOKEDHOURS" | translate }}</mat-label>
        <input matInput disabled="true" [value]="(this._budgetService.currentMinutes/60).toFixed(2)" type="number">
        <mat-hint>{{ "OV_BUDGET.INHOURS" | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ "OV_BUDGET.BOOKEDDAYS" | translate }}</mat-label>
        <input matInput disabled="true" [value]="(this._budgetService.currentMinutes/60/8).toFixed(2)" type="number">
        <mat-hint>{{ "OV_BUDGET.INDAYS" | translate }}</mat-hint>
    </mat-form-field>
</div>

<div class="budgets-wrapper">
    <ng-container *ngFor="let widget of this._budgetService.widgets">
        <ng-container *ngIf="showOnlyBillsChart && widget.id === OverviewBudgetChartEnum.BILL || !showOnlyBillsChart">
            <div *ngIf="this._budgetService.ready" class="fuse-card progress-bar-budget">

                <div class="p-16">
                    <div class="h1 font-weight-300">{{ widget.label | translate }}</div>
                </div>

                <div class="chart-wrapper h-200">
                    <ngx-charts-pie-chart *fuseIfOnDom
                                          [scheme]="widget.scheme"
                                          [results]="widget.devices"
                                          [doughnut]="true"
                    >
                    </ngx-charts-pie-chart>
                </div>


                <div class="p-16" fxLayout="row" fxLayoutAlign="center center">
                    <div class="px-16"
                         *ngFor="let device of widget.devices"
                         fxLayout="column"
                         fxLayoutAlign="start center"
                    >
                        <div class="h4 secondary-text">{{ device.name }}</div>
                        <div class="h2 font-weight-300 py-8">{{ device.value | number:'1.0-2' }}%</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
