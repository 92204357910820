export const locale = {
    lang: 'de',
    data: {
        DETAIL_GROUP: {
            EDIT: 'Gruppe bearbeiten',
            NEW: 'Neue Gruppe',
            FORM: {
                TITLE: 'Gruppe',
                DESCRIPTION: 'Beschreibung',
                SAVE: 'Speichern',
            },
            TABS: {
                BASE: 'Info',
                USER: 'Mitglieder',
            },
            NOTIFICATIONS: {
                SAVING: 'Gruppe wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Gruppe erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
                ADDHINTTRUE:
                    'Hinweis: Nur Mitglieder dieser Gruppe können User hinzufügen.',
                ADDHINTFALSE:
                    'Hinweis: Nur Besitzer dieser Gruppe können User hinzufügen.',
                VIEWMEMBERSHINTTRUE:
                    'Hinweis: Nur Mitglieder dieser Gruppe können die Mitglieder sehen.',
            },
        },
    },
};
