import { NgModule } from '@angular/core';
import { ListviewCurrentuserComponent } from './listview-currentuser.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

const routes = [
    {
        path: 'myviews',
        component: ListviewCurrentuserComponent,
    },
];

@NgModule({
    declarations: [ListviewCurrentuserComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
    ],
    exports: [ListviewCurrentuserComponent],
})
export class ListviewCurrentuserModule {}
