import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-confirmdatedialog',
    templateUrl: './confirmdatedialog.component.html',
    styleUrls: ['./confirmdatedialog.component.scss'],
})
export class ConfirmdatedialogComponent {
    public confirmMessage: any;

    constructor(
        public dialogRef: MatDialogRef<ConfirmdatedialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        if (data.confirmMessage) {
            this.confirmMessage = data.confirmMessage;
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    close(): void {
        this.dialogRef.close(false);
    }

    save(): void {
        this.dialogRef.close(true);
    }
}
