export const locale = {
    lang: 'en',
    data: {
        TABLE_TASKSET: {
            TITLE: 'Tasksets',
            NEWTASKSET: 'Create tasks',
            SAVE: 'Save',
            COLUMNS: {
                TITLE: 'Title',
            },
            NOTIFICATIONS: {
                CREATE: 'Creating tasks.',
                WAIT: 'Please wait.',
            },
        },
    },
};
