export const locale = {
    lang: 'de',
    data: {
        ADD_USER: {
            ADD: 'User hinzufügen',
            NOTIFICATIONS: {
                SAVING: 'User werden hinzugefügt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'User erfolgreich hinzugefügt.',
                ERROR: 'Fehler beim hinzugefügen: ',
            },
            FORM: {
                USER: 'User',
                SAVE: 'Hinzufügen',
            },
        },
    },
};
