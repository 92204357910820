<div class="page-layout carded left-sidebar tabbed inner-scroll">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar" name="carded-left-sidebar-tabbed-2" position="left" lockedOpen="gt-md">
    <!-- SIDEBAR HEADER -->
    <div class="header p-6 accent">
      <h2 class="m-0"></h2>
    </div>
    <!-- / SIDEBAR HEADER -->

    <!-- SIDEBAR CONTENT -->
    <div class="content" fusePerfectScrollbar [@animate]="{ value: '*', params: { y: '50px' } }">
      <div *ngIf="isOrder" class="nav material2">
        <div class="nav-subheader"></div>
        <div *ngFor="let item of this.orderService.sideBarConfigOrder" class="nav-item">
          <a class="nav-link" matRipple [routerLink]="item.Link" [routerLinkActive]="['active', 'accent']"
            queryParamsHandling="merge">
            <mat-icon class="nav-link-icon">{{
              item.Icon
              }}</mat-icon>
            <span>{{ item.Text }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="!isOrder" class="nav material2">
        <div class="nav-subheader"></div>
        <div *ngFor="
                        let item of this.requestService.sideBarConfigRequest
                    " class="nav-item">
          <a class="nav-link" matRipple [routerLink]="item.Link" [routerLinkActive]="['active', 'accent']"
            queryParamsHandling="merge">
            <mat-icon class="nav-link-icon">{{
              item.Icon
              }}</mat-icon>
            <span>{{ item.Text }}</span>
          </a>
        </div>
      </div>
    </div>
    <!-- / SIDEBAR CONTENT -->
  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center">
    <!-- CONTENT HEADER -->
    <div class="header accent p-6" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center start">
        <div class="h2 mt-4">
          <mat-icon class="logo-icon mr-4" [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }">assignment
          </mat-icon>
          <span class="logo-text h1" [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }">
            {{ this.itemTitle }}
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
          <span class="secondary-text">{{
            breadcrumbTitle | translate
            }}</span>
          <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
          <span class="secondary-text">{{
            'ORDERREQUESTDOCS.DOCS' | translate
            }}</span>
        </div>
      </div>

      <!-- SEARCH -->
      <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

            <div class="search-wrapper mt-4 mt-sm-0">

              <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>search</mat-icon>
                <input [formControl]="searchInput" placeholder="Search for a contact">
              </div>

            </div>

          </div> -->
      <!-- / SEARCH -->
    </div>
    <!-- / CONTENT HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">
      <!-- CONTENT -->
      <div class="content card-content">
        <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">
          <mat-tab class="tab" label="{{ tabTitle | translate }}">
            <input type="file" style="display: none" [multiple]="uploadMultipleDocuments"
              [accept]="this.ordReqService.acceptedFileExtensions" (change)="
                                this.ordReqService.fileChangeEvent(
                                    $event,
                                    this.documentGrid,
                                    this.tableConfig.list,
                                    { AntragId: this.id }
                                )
                            " #fileInput />

            <app-sharepoint-table #documentGrid *ngIf="tableConfig" class="documents-table"
              [configuration]="tableConfig" (onTooolbarButtonClick)="onTooolbarButtonClick($event)">
              <ng-template customCell for="CopyColumn" let-dataItem="dataItem">
                <button *ngIf="!dataItem.FileSystemObjectType" mat-icon-button
                  (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.copy)" matTooltip="Copy file">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </ng-template>
              <ng-template customCell for="RenameColumn" let-dataItem="dataItem">
                <button *ngIf="!dataItem.FileSystemObjectType" mat-icon-button
                  (click)="openRenameDocumentDialog(dataItem, ChangeDocumentStatus.rename)" matTooltip="Rename file">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-template>
            </app-sharepoint-table>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- / CONTENT -->
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>