import { Injectable } from '@angular/core';
import { FuseProgressBarService } from 'sp-office365-layout';
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private loadingGifUrl = '/ui/assets/images/loading.gif';

    constructor(private _fuseProgressBarService: FuseProgressBarService) {
        if (location.href.indexOf('/ui/') == -1) {
            this.loadingGifUrl = '/assets/images/loading.gif';
        }
    }

    public show(title: string, text: string): any {
        this._fuseProgressBarService.show();

        swal.fire({
            title,
            text,
            imageUrl: this.loadingGifUrl,
            showConfirmButton: false,
            allowOutsideClick: false,
            background: 'transparent',
        });
    }

    public hide() {
        swal.close();

        this._fuseProgressBarService.hide();
    }
}
