import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import { IList, FieldUserSelectionMode } from '@pnp/sp/presets/all';

export class UpdateToVersion1006Component {
    bearbeitungsberechtigungxml =
        '<Field Type="UserMulti" DisplayName="Bearbeitungsberechtigung" List="UserInfo" Description="Erhalten Bearbeitungsberechtigung." ' +
        'Required="FALSE" EnforceUniqueValues="FALSE" ShowField="ImnName" UserSelectionMode="PeopleAndGroups" UserSelectionScope="0" Mult="TRUE" ' +
        'ortable="FALSE" StaticName="Bearbeitungsberechtigung" Name="Bearbeitungsberechtigung" ColName="int5" RowOrdinal="0" />';

    leseberechtigungxml =
        '<Field Type="UserMulti" DisplayName="Leseberechtigung" List="UserInfo" Description="Erhalten Leseberechtigung." ' +
        'Required="FALSE" EnforceUniqueValues="FALSE" ShowField="ImnName" UserSelectionMode="PeopleAndGroups" UserSelectionScope="0" Mult="TRUE" ' +
        'ortable="FALSE" StaticName="Leseberechtigung" Name="Leseberechtigung" ColName="int5" RowOrdinal="0" />';

    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        let assignmentList: IList;
        let categoryList: IList;
        let involvedList: IList;
        let docCategoryList: IList;
        let taskList: IList;
        let taskTypeList: IList;
        let docsList: IList;
        let taskDocsList: IList;

        const promise = new Promise<void>(async (resolve, reject) => {
            const promises = [
                // Add List Aufträge
                await this._updateService.addList('Aufträge', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Aufträge
                await new Promise<void>((resolve) => {
                    assignmentList =
                        this.sharePointService.web.lists.getByTitle('Aufträge');
                    resolve();
                }),
                // Add List Auftragskategorien
                await this._updateService.addList(
                    'Auftragskategorien',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Auftragskategorien
                await new Promise<void>((resolve) => {
                    categoryList =
                        this.sharePointService.web.lists.getByTitle(
                            'Auftragskategorien'
                        );
                    resolve();
                }),
                // Add List Auftragsbeteiligte
                await this._updateService.addList(
                    'Auftragsbeteiligte',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Auftragskategorien
                await new Promise<void>((resolve) => {
                    involvedList =
                        this.sharePointService.web.lists.getByTitle(
                            'Auftragsbeteiligte'
                        );
                    resolve();
                }),
                // Add List Dokumentenkategorien
                await this._updateService.addList(
                    'Dokumentenkategorien',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Dokumentenkategorien
                await new Promise<void>((resolve) => {
                    docCategoryList =
                        this.sharePointService.web.lists.getByTitle(
                            'Dokumentenkategorien'
                        );
                    resolve();
                }),
                // Add List Auftragsaufgaben
                await this._updateService.addList('Auftragsaufgaben', '', 107, {
                    EnableVersioning: true,
                }),
                // Get List Auftragsaufgaben
                await new Promise<void>((resolve) => {
                    taskList =
                        this.sharePointService.web.lists.getByTitle(
                            'Auftragsaufgaben'
                        );
                    resolve();
                }),
                // Add List Aufgabentypen
                await this._updateService.addList('Aufgabentypen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Aufgabentypen
                await new Promise<void>((resolve) => {
                    taskTypeList =
                        this.sharePointService.web.lists.getByTitle(
                            'Aufgabentypen'
                        );
                    resolve();
                }),
                // Add List Auftragsdokumente
                await this._updateService.addList(
                    'Auftragsdokumente',
                    '',
                    101,
                    { EnableVersioning: true }
                ),
                // Get List Auftragsdokumente
                await new Promise<void>((resolve) => {
                    docsList =
                        this.sharePointService.web.lists.getByTitle(
                            'Auftragsdokumente'
                        );
                    resolve();
                }),
                // Add List Aufgabendokumente
                await this._updateService.addList(
                    'Aufgabendokumente',
                    '',
                    101,
                    { EnableVersioning: true }
                ),
                // Get List Aufgabendokumente
                await new Promise<void>((resolve) => {
                    taskDocsList =
                        this.sharePointService.web.lists.getByTitle(
                            'Aufgabendokumente'
                        );
                    resolve();
                }),
                // Add Field Auftragsstatus to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Auftragsstatus',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // assignmentList.fields
                            //     .addChoice('Auftragsstatus', [
                            //         'Auftrag angelegt',
                            //         'Auftrag in Arbeit',
                            //         'Auftrag gestoppt',
                            //         'Auftrag abgeschlossen',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftragsstart to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Auftragsstart',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addDateTime('Auftragsstart')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftragsende to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Auftragsende',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addDateTime('Auftragsende')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftragsleiter to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Auftragsleiter',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // assignmentList.fields
                            //     .addUser(
                            //         'Auftragsleiter',
                            //         FieldUserSelectionMode.PeopleAndGroups
                            //     )
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftragsbudget to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Auftragsbudget',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .addCurrency('Auftragsbudget')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftragskategorie to List Aufträge
                await new Promise<void>((resolve, reject) => {
                    categoryList
                        .select('Id')()
                        .then(
                            (categoryListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        assignmentList,
                                        'Auftragskategorie',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // assignmentList.fields
                                                    //     .addLookup(
                                                    //         'Auftragskategorie',
                                                    //         categoryListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Bearbeitungsberechtigung to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Bearbeitungsberechtigung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .createFieldAsXml(
                                    this.bearbeitungsberechtigungxml
                                )
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Leseberechtigung to List Aufträge
                await this._updateService.runFunctionIfFieldNotExists(
                    assignmentList,
                    'Leseberechtigung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            assignmentList.fields
                                .createFieldAsXml(this.leseberechtigungxml)
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Art to List Auftragsbeteiligte
                await this._updateService.runFunctionIfFieldNotExists(
                    involvedList,
                    'Art',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // involvedList.fields
                            //     .addChoice('Art', ['intern', 'extern'])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Person_x0020_AD to List Auftragsbeteiligte
                await this._updateService.runFunctionIfFieldNotExists(
                    involvedList,
                    'Person_x0020_AD',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // involvedList.fields
                            //     .addUser(
                            //         'Person_x0020_AD',
                            //         FieldUserSelectionMode.PeopleAndGroups
                            //     )
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Beteiligung to List Auftragsbeteiligte
                await this._updateService.runFunctionIfFieldNotExists(
                    involvedList,
                    'Beteiligung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            involvedList.fields.addNumber('Beteiligung').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Auftrag to List Auftragsbeteiligte
                await new Promise<void>((resolve, reject) => {
                    assignmentList
                        .select('Id')()
                        .then(
                            (assignmentListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        involvedList,
                                        'Auftrag',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // involvedList.fields
                                                    //     .addLookup(
                                                    //         'Auftrag',
                                                    //         assignmentListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Start to List Auftragsbeteiligte
                await this._updateService.runFunctionIfFieldNotExists(
                    involvedList,
                    'Start',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            involvedList.fields.addDateTime('Start').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Field Ende to List Auftragsbeteiligte
                await this._updateService.runFunctionIfFieldNotExists(
                    involvedList,
                    'Ende',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            involvedList.fields.addDateTime('Ende').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Items to List Auftragskategorien
                await categoryList.items.add({
                    Title: 'Intern',
                }),
                await categoryList.items.add({
                    Title: 'Extern',
                }),
                await categoryList.items.add({
                    Title: 'Support',
                }),
                // Add Field Farbe to List Dokumentenkategorien
                await this._updateService.addField(
                    docCategoryList,
                    'Farbe',
                    FieldType.Text
                ),
                // Add Field Sortierung to List Dokumentenkategorien
                await this._updateService.runFunctionIfFieldNotExists(
                    docCategoryList,
                    'Sortierung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            docCategoryList.fields.addNumber('Sortierung').then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        });
                        return promise;
                    }
                ),
                // Add Items to List Dokumentenkategorien
                await docCategoryList.items.add({
                    Title: 'Konzepte',
                    Farbe: '#ff9999',
                    Sortierung: 1,
                }),
                await docCategoryList.items.add({
                    Title: 'Protokolle',
                    Farbe: '#99ccff',
                    Sortierung: 2,
                }),
                await docCategoryList.items.add({
                    Title: 'E-Mails',
                    Farbe: '#99cc99',
                    Sortierung: 3,
                }),
                // Add Field Farbe to List Aufgabentypen
                await this._updateService.addField(
                    taskTypeList,
                    'Farbe',
                    FieldType.Text
                ),
                // Add Items to List Aufgabentypen
                await taskTypeList.items.add({
                    Title: 'Anforderung',
                    Farbe: '#99ccff',
                }),
                await taskTypeList.items.add({
                    Title: 'Feature',
                    Farbe: '#ccffcc',
                }),
                await taskTypeList.items.add({
                    Title: 'Bug',
                    Farbe: '#ffcccc',
                }),
                await taskTypeList.items.add({
                    Title: 'Verbesserung',
                    Farbe: '#ccffcc',
                }),
                // Add Field Auftrag to List Auftragsaufgaben
                await new Promise<void>((resolve, reject) => {
                    assignmentList
                        .select('Id')()
                        .then(
                            (assignmentListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskList,
                                        'Auftrag',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskList.fields
                                                    //     .addLookup(
                                                    //         'Auftrag',
                                                    //         assignmentListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Arbeitspaket to List Auftragsaufgaben
                await new Promise<void>((resolve, reject) => {
                    taskList
                        .select('Id')()
                        .then(
                            (taskListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskList,
                                        'Arbeitspaket',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskList.fields
                                                    //     .addLookup(
                                                    //         'Arbeitspaket',
                                                    //         taskListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Aufgabenart to List Auftragsaufgaben
                await this._updateService.runFunctionIfFieldNotExists(
                    taskList,
                    'Aufgabenart',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // taskList.fields
                            //     .addChoice('Aufgabenart', [
                            //         'Arbeitspaket',
                            //         'Aufgabe',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Aufgabentyp to List Auftragsaufgaben
                await new Promise<void>((resolve, reject) => {
                    taskTypeList
                        .select('Id')()
                        .then(
                            (taskTypeListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskList,
                                        'Aufgabentyp',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskList.fields
                                                    //     .addLookup(
                                                    //         'Aufgabentyp',
                                                    //         taskTypeListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Kategorie to List Auftragsdokumente
                await new Promise<void>((resolve, reject) => {
                    docCategoryList
                        .select('Id')()
                        .then(
                            (docCategoryListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        docsList,
                                        'Kategorie',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // docsList.fields
                                                    //     .addLookup(
                                                    //         'Kategorie',
                                                    //         docCategoryListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Auftrag to List Auftragsdokumente
                await new Promise<void>((resolve, reject) => {
                    assignmentList
                        .select('Id')()
                        .then(
                            (assignmentListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        docsList,
                                        'Auftrag',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // docsList.fields
                                                    //     .addLookup(
                                                    //         'Auftrag',
                                                    //         assignmentListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Kategorie to List Aufgabendokumente
                await new Promise<void>((resolve, reject) => {
                    docCategoryList
                        .select('Id')()
                        .then(
                            (docCategoryListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskDocsList,
                                        'Kategorie',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskDocsList.fields
                                                    //     .addLookup(
                                                    //         'Kategorie',
                                                    //         docCategoryListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Aufgabe to List Aufgabendokumente
                await new Promise<void>((resolve, reject) => {
                    taskList
                        .select('Id')()
                        .then(
                            (taskListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        taskDocsList,
                                        'Aufgabe',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // taskDocsList.fields
                                                    //     .addLookup(
                                                    //         'Aufgabe',
                                                    //         taskListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });

        return promise;
    }
}
