import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    public confirmMessage: any;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        if (data.confirmMessage) {
            this.confirmMessage = data.confirmMessage;
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    close(): void {
        this.dialogRef.close(false);
    }

    save(): void {
        this.dialogRef.close(true);
    }
}
