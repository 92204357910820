import {
    Component,
    OnInit,
    EventEmitter,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { from } from 'rxjs';
import { WindowRef } from '../../shared/WindowRef';
import { ApplicationService } from '../../services/application.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-detail-notification',
    templateUrl: './detail-notification.component.html',
    styleUrls: ['./detail-notification.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailNotificationComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public id;
    public sharePointItem;
    public formioConfiguration;
    public isCreateMode = true;
    private lists = [];
    private firstTimeLoad = true;

    public refreshForm: EventEmitter<any> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<DetailNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService,
        private winRef: WindowRef
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.winRef.nativeWindow.dateFields = [];
        this.winRef.nativeWindow.userFields = [];
    }

    ngOnInit() {
        const promises = [];

        this.id = this.data.notificationId;

        if (this.id != null) {
            this.isCreateMode = false;

            promises.push(
                new Promise<void>(async (resolve, reject) => {
                    this.sharepointService
                        .getItemById({
                            id: this.id,
                            listTitle: 'Notifications',
                        })
                        .then(
                            (notification) => {
                                this.sharePointItem = notification;

                                if (this.sharePointItem.List) {
                                    this.loadFieldsFromList(
                                        this.sharePointItem.List
                                    ).then(
                                        () => {
                                            this.refreshForm.emit({
                                                form: this.formioConfiguration,
                                            });

                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                                } else {
                                    resolve();
                                }
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                })
            );
        }

        promises.push(
            new Promise<void>(async (resolve, reject) => {
                this.sharepointService.web.lists().then(
                    (lists) => {
                        lists.forEach((list) => {
                            this.lists.push({
                                label: list.Title,
                                value: list.Id,
                            });
                        });
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
            })
        );

        Promise.all(promises).then(
            () => {
                this.initForm();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;

            // Set default values
            dataForm.Title = 'Benachrichtigung';
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this.loadingService.show(
            this._translateService.instant(
                'DETAIL_NOTIFICATION.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant(
                'DETAIL_NOTIFICATION.NOTIFICATIONS.WAIT'
            )
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        if (this.id != null) {
            return from(
                this.sharepointService
                    .updateItem({
                        id: this.id,
                        listTitle: 'Notifications',
                        newFiledValues: e.data,
                    })
                    .then(
                        () => {
                            this.loadingService.hide();

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'DETAIL_NOTIFICATION.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        } else {
            return from(
                this.sharepointService
                    .addItem({
                        listTitle: 'Notifications',
                        data: e.data,
                        folder: '',
                    })
                    .then(
                        () => {
                            this.loadingService.hide();

                            this.snackBarService.add(
                                this._translateService.instant(
                                    'DETAIL_NOTIFICATION.NOTIFICATIONS.SUCCESS'
                                ),
                                '',
                                {
                                    duration: 4000,
                                    panelClass: 'success-dialog',
                                }
                            );

                            this.close('success');
                        },
                        (error) => {
                            this.loadingService.hide();
                            console.log(error);
                        }
                    )
            );
        }
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
                return;
            }

            // Update values after List selected
            if (ev.changed.component.key === 'List') {
                this.loadFieldsFromList(ev.changed.value).then(
                    () => {
                        const promises = [];

                        if (ev.changed.value) {
                            // Show fields
                            this.formioConfiguration.components.find(
                                (x) => x.key == 'Datefield'
                            ).hidden = false;
                            this.formioConfiguration.components.find(
                                (x) => x.key == 'Receiverfields'
                            ).hidden = false;

                            // Update Title
                            promises.push(
                                new Promise<void>(async (resolve) => {
                                    this.sharepointService.web.lists
                                        .getById(ev.changed.value)()
                                        .then(
                                            (list) => {
                                                ev.data.Title =
                                                    'Benachrichtigung für Liste: ' +
                                                    list.Title;
                                                resolve();
                                            },
                                            (error) => {
                                                ev.data.Title =
                                                    'Benachrichtigung';
                                                resolve();
                                            }
                                        );
                                })
                            );
                        } else {
                            // Hide fields
                            this.formioConfiguration.components.find(
                                (x) => x.key == 'Datefield'
                            ).hidden = true;
                            this.formioConfiguration.components.find(
                                (x) => x.key == 'Receiverfields'
                            ).hidden = true;

                            // Update Title
                            ev.data.Title = 'Benachrichtigung';
                        }

                        Promise.all(promises).then(
                            () => {
                                // Update fields and values in form
                                this.refreshForm.emit({
                                    form: this.formioConfiguration,
                                    submission: {
                                        data: ev.data,
                                    },
                                });
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        }
    }

    loadFieldsFromList(listId): Promise<any> {
        return new Promise<void>(async (resolve, reject) => {
            this.winRef.nativeWindow.dateFields = [];
            this.winRef.nativeWindow.userFields = [];

            this.sharepointService.web.lists
                .getById(listId)
                .fields()
                .then(
                    (fields) => {
                        fields.forEach((field) => {
                            if (
                                field.TypeAsString.toLowerCase().indexOf(
                                    'date'
                                ) > -1
                            ) {
                                this.winRef.nativeWindow.dateFields.push({
                                    label: field.Title,
                                    value: field.StaticName,
                                });
                            } else if (
                                field.TypeAsString.toLowerCase().indexOf(
                                    'user'
                                ) > -1
                            ) {
                                this.winRef.nativeWindow.userFields.push({
                                    label: field.Title,
                                    value: field.StaticName,
                                });
                            }
                        });

                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.TITLE'
                    ),
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    inputFormat: 'plain',
                    defaultValue: 'Benachrichtigung',
                    disabled: true,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.SUBJECT'
                    ),
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    type: 'textfield',
                    input: true,
                    key: 'Subject',
                    inputFormat: 'plain',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.TEXT'
                    ),
                    isUploadEnabled: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textarea',
                    input: true,
                    key: 'Text',
                    inputFormat: 'plain',
                    rows: 8,
                    clearOnHide: false,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.INTERVALL'
                    ),
                    description: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.INTERVALLDESCRIPTION'
                    ),
                    type: 'number',
                    input: true,
                    key: 'Intervall',
                    delimiter: false,
                    requireDecimal: false,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.LIST'
                    ),
                    type: 'select',
                    input: true,
                    key: 'List',
                    data: {
                        values: this.lists,
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.DATEFIELD'
                    ),
                    description: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.FIELDSDESCRIPTION'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Datefield',
                    dataSrc: 'custom',
                    data: {
                        custom: 'values = window["dateFields"];',
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    hidden:
                        this.winRef.nativeWindow.dateFields == null ||
                        this.winRef.nativeWindow.dateFields.length == 0,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.RECEIVERFIELDS'
                    ),
                    description: this._translateService.instant(
                        'DETAIL_NOTIFICATION.COLUMNS.FIELDSDESCRIPTION'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Receiverfields',
                    dataSrc: 'custom',
                    data: {
                        custom: 'values = window["userFields"];',
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    hidden:
                        this.winRef.nativeWindow.userFields == null ||
                        this.winRef.nativeWindow.userFields.length == 0,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_NOTIFICATION.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
