import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumenttemplateComponent } from './documenttemplate.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormioModule } from '@formio/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [DocumenttemplateComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [DocumenttemplateComponent],
})
export class DocumenttemplateModule {}
