import { IList } from '@pnp/sp/presets/all';
import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';

export class UpdateToVersion1114Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const locationList: IList =
                this._sharePointService.web.lists.getByTitle('Standorte');

            const promises = [
                // Add Field Fileserver to List Standorte
                await this._updateService.addField(
                    locationList,
                    'Fileserver',
                    FieldType.Text
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
