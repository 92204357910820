import { RequestService } from '../../../services/request.service';
import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1127Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const projectList: IList =
                this._sharePointService.web.lists.getByTitle('Aufträge');
            const orderList: IList =
                this._sharePointService.web.lists.getByTitle('Anträge');
            const costRoleList: IList =
                this._sharePointService.web.lists.getByTitle('Kostenrollen');

            let pCostRoleList: IList;

            // Add List Projekt_Kostenrollen
            await this._updateService.addList('Projekt_Kostenrollen', '', 100, {
                EnableVersioning: true,
            });
            // Get List Projekt_Kostenrollen
            await new Promise<void>((resolve) => {
                pCostRoleList = this._sharePointService.web.lists.getByTitle(
                    'Projekt_Kostenrollen'
                );
                resolve();
            });

            pCostRoleList = this._sharePointService.web.lists.getByTitle(
                'Projekt_Kostenrollen'
            );

            // Add Field Stundensatz to List Projekt_Kostenrollen
            await this._updateService.addField(
                pCostRoleList,
                'Stundensatz',
                FieldType.Currency
            );

            // Add Field Abbreviation to List Projekt_Kostenrollen
            await this._updateService.addField(
                pCostRoleList,
                'Abbreviation',
                FieldType.Text
            );

            // Add Field Projekt to List Projekt_Kostenrollen
            await this._updateService.addLookupField(
                pCostRoleList,
                projectList,
                'Projekt'
            );

            // Add Field Auftrag to List Projekt_Kostenrollen
            await this._updateService.addLookupField(
                pCostRoleList,
                orderList,
                'Auftrag'
            );

            // Add Field Kostenrolle to List Projekt_Kostenrollen
            await this._updateService.addLookupField(
                pCostRoleList,
                costRoleList,
                'Kostenrolle'
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
