import { Injectable } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import { ApplicationService } from '../../services/application.service';
import { AddFolderDialogComponent } from './add-folder-dialog/add-folder-dialog.component';
import { LoadingService } from '../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { SnackbarService } from '../../services/snackbar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

declare var SP: any;

@Injectable({
    providedIn: 'root',
})
export class DocumentsAssignmentService {
    public acceptedFileExtensions =
        '.xlsx, .xls, .csv, .docx, .doc, .pptx, .ppt, .pdf, .zip';

    constructor(
        public dialog: MatDialog,
        public _loadingService: LoadingService,
        private _sharePointService: SharePointService,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _snackBarService: SnackbarService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    public loadItemFromFile(fileServerRelativeUrl: string): Promise<any> {
        return this._applicationService.loadItemFromFile(fileServerRelativeUrl);
    }

    public fileChangeEvent(
        fileInput: Event,
        documentGrid: any,
        listName: string,
        newFiledValues?: any,
        selectAddedDocuments?: boolean
    ): void {
        let uploadText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.UPLOAD'
        );
        let successText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESS'
        );
        let errorText = this._translateService.instant(
            'DOCUMENTASSIGNMENT.NOTIFICATIONS.ERROR'
        );

        const eventTarget = fileInput.target as HTMLInputElement;
        const files: FileList = eventTarget.files;

        // Check if Multi
        if (files.length > 1) {
            uploadText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.UPLOADMULTI'
            );

            successText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESSMULTI'
            );

            errorText = this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.ERRORMULTI'
            );
        }

        this._loadingService.show(
            uploadText,
            this._translateService.instant(
                'DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'
            )
        );

        const promises = [];

        for (const file of files) {
            if (file) {
                let folderpath = '';

                if (documentGrid.getCurrentFolderPath()) {
                    folderpath = documentGrid.getCurrentFolderPath() + '';
                }

                promises.push(
                    this.uploadDocumentsAndSetValues(
                        file,
                        listName,
                        folderpath,
                        newFiledValues
                    )
                );
            }
        }

        Promise.all(promises)
            .then((results) => {
                if (selectAddedDocuments) {
                    documentGrid.preselection = [
                        ...documentGrid._preselection,
                        ...results,
                    ];
                }

                documentGrid.refresh();
                this._loadingService.hide();

                this._snackBarService.add(successText, '', {
                    duration: 4000,
                    panelClass: 'success-dialog',
                });
            })
            .catch((error) => {
                this._loadingService.hide();

                this._snackBarService.add(errorText + error.statusText, '', {
                    duration: 4000,
                    panelClass: 'error-dialog',
                });
            });

        eventTarget.value = '';
    }

    private changeFileEnding({ name }): string {
        return (
            name.slice(0, name.lastIndexOf('.')) +
            name.substring(name.lastIndexOf('.')).toLocaleLowerCase()
        );
    }

    public uploadDocumentsAndSetValues(
        file: File,
        listName: string,
        folderName: string,
        newFiledValues?: any
    ): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const newName = this.changeFileEnding(file);

            this._sharePointService.web.lists
                .getByTitle(listName)
                .select('Title', 'RootFolder/ServerRelativeUrl')
                .expand('RootFolder')()
                .then((response) => {
                    const promises = [];
                    let folderServerRelativeUrl =
                        response.RootFolder.ServerRelativeUrl;

                    if (folderName) {
                        folderServerRelativeUrl += '/' + folderName;
                    }

                    if (!window['useApp']) {
                        // MSAL
                        promises.push(
                            this._sharePointService.uploadDocumentMSAL(
                                folderServerRelativeUrl,
                                file,
                                null,
                                newName
                            )
                        );
                    } else {
                        // APP
                        promises.push(
                            this._sharePointService.uploadDocument(
                                file,
                                folderName,
                                listName,
                                newName
                            )
                        );
                    }

                    Promise.all(promises)
                        .then((results: any) => {
                            this._applicationService
                                .loadItemFromFile(
                                    results[0].data.ServerRelativeUrl
                                )
                                .then((data) => {
                                    if (newFiledValues) {
                                        // Update Item
                                        this._sharePointService
                                            .updateItem({
                                                listTitle: listName,
                                                id: data.item.Id,
                                                newFiledValues,
                                            })
                                            .then(() => {
                                                // Success
                                                resolve(data.item.Id);
                                            })
                                            .catch((error) => {
                                                reject(error);
                                                console.error(
                                                    'Error in updateItem function'
                                                );
                                            });
                                    } else {
                                        // Success
                                        resolve(data.item.Id);
                                    }
                                })
                                .catch((error) => {
                                    console.error(
                                        'Error in loadFilesFromFolder function'
                                    );
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            console.error('Error in uploadDocument function');
                            reject(error);
                        });
                })
                .catch((error) => {
                    console.error('Error in get rootfolder function');
                    reject(error);
                });
        });
    }

    addNewFolder(grid: any, listName: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.15 + 'px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        const dialogRef = this.dialog.open(
            AddFolderDialogComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._loadingService.show(
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.ADDINGFOLDER'
                    ),
                    this._translateService.instant(
                        'DOCUMENTASSIGNMENT.NOTIFICATIONS.WAIT'
                    )
                );

                // Add folder to List
                this._sharePointService.web.folders
                    .addUsingPath(
                        listName +
                            '/' +
                            grid.getCurrentFolderPath() +
                            '/' +
                            result
                    )
                    .then((newFolder) => {
                        // success
                        grid.refresh();
                        this._loadingService.hide();
                        this._snackBarService.add(
                            this._translateService.instant(
                                'DOCUMENTASSIGNMENT.NOTIFICATIONS.SUCCESSFOLDER'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        this._loadingService.hide();
                    });
            }
        });
    }

    public updateDocumentPermissions(documentItem, projectItem): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let editorIds = [];

            // Get SPItem
            const documentSPItem = this._sharePointService.web.lists
                .getByTitle('Auftragsdokumente')
                .items.getById(documentItem.Id);

            if (documentItem.BearbeitungsberechtigungId) {
                if (documentItem.BearbeitungsberechtigungId.results) {
                    editorIds = documentItem.BearbeitungsberechtigungId.results;
                } else {
                    editorIds = documentItem.BearbeitungsberechtigungId;
                }
            }

            // Add Auftragsleiter to permissions
            if (
                projectItem.AuftragsleiterId &&
                editorIds.indexOf(projectItem.AuftragsleiterId) == -1
            ) {
                editorIds.push(projectItem.AuftragsleiterId);
            }

            // Add currentuser to permissions
            if (
                this._sharePointService.currentUser.Id &&
                editorIds.indexOf(this._sharePointService.currentUser.Id) == -1
            ) {
                editorIds.push(this._sharePointService.currentUser.Id);
            }

            documentSPItem
                .breakRoleInheritance()
                .then(() => {
                    this._applicationService
                        .removePermissionsFromItem(documentSPItem)
                        .then(() => {
                            this._applicationService
                                .setPermissionsForItem(
                                    documentSPItem,
                                    editorIds,
                                    5
                                )
                                .then(() => {
                                    // Success
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public checkIfDocumentExists(path: string): Promise<boolean> {
        return this._sharePointService.web
            .getFileByServerRelativePath(path)
            .exists();
    }
}
