<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">{{ "WORKPACKAGESETS.TITLE" | translate }}</span>
        <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div id='content'>
    <mat-tab-group dynamicHeight="true">
        <mat-tab [label]='"WORKPACKAGESETS.FORM.TABS.BASE" | translate'>
            <formio #firstTabFormio
                    class="formio-material"
                    [refresh]="refreshForm"
                    [service]="this"
                    [form]="formioConfiguration"
                    [renderOptions]="formio_renderOptions"
                    (ready)="formioReady()">
            </formio>
        </mat-tab>

        <mat-tab *ngIf="id" [label]="'WORKPACKAGESETS.FORM.TABS.WORKPACKAGESETS' | translate">
            <app-sharepoint-table #aufgabensetsGrid
                                  *ngIf="tableAufgabensetsConfig"
                                  [preselection]="sharePointItem.AufgabensetsId"
                                  [configuration]="tableAufgabensetsConfig"
            >
            </app-sharepoint-table>
        </mat-tab>
    </mat-tab-group>
    <button mat-raised-button
            class="mat-accent m-16"
            color="accent"
            (click)="save()"
    >{{ "WORKPACKAGESETS.FORM.SAVE" | translate }}
    </button>
</div>
