export const locale = {
    lang: 'de',
    data: {
        LV_REQUEST: {
            TITLE: 'Anfragen',
            OPEN: 'Offene Anfragen',
            CANCELED: 'Abgesagte Anfragen',
            ALL: 'Alle Anfragen',
            TEAMS: 'Offen (Teams)',
            COLUMNS: {
                DESCRIPTION: 'Beschreibung',
                CLIENT: 'Auftraggeber',
                TEAM: 'Team',
            },
            SEARCH_LABEL: 'Anfragen durchsuchen',
        },
    },
};
