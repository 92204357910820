export const locale = {
    lang: 'de',
    data: {
        TABLE_USER: {
            COLUMNS: {
                TITLE: 'User',
                LOGINNAME: 'Loginname',
            },
            NEW: 'User hinzufügen',
            NOTIFICATIONS: {
                REMOVED: 'User wurde aus der Gruppe entfernt!',
            },
        },
    },
};
