export const locale = {
    lang: 'en',
    data: {
        LV_ORDER: {
            TITLE: 'Orders',
            SEARCH_LABEL: 'Search for a order',
            ALL: 'All orders',
            OPEN: 'Open offers',
            CLOSED: 'Closed offers',
            COLUMNS: {
                DESCRIPTION: 'Description',
                CLIENT: 'Client',
                HANDINGOVER: 'Handing over date',
                TEAM: 'Team',
                LOCATION: 'Niederlassung',
            },
        },
    },
};
