import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizableBoxComponent } from './resizable-box.component';
import { ResizableModule } from 'angular-resizable-element';

@NgModule({
  declarations: [ResizableBoxComponent],
  imports: [
    CommonModule,
    ResizableModule
  ],
  exports: [ResizableBoxComponent]
})
export class ResizableBoxModule { }
