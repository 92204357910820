import { FilterEvaluationComponent } from './filter-evaluation.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    FuseSharedModule,
    FuseSidebarModule,
    LayoutModule,
} from 'sp-office365-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FormioModule } from '@formio/angular';

@NgModule({
    declarations: [FilterEvaluationComponent],
    imports: [
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
    ],
    exports: [FilterEvaluationComponent],
})
export class FilterEvaluationModule {}
