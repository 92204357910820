import { Component, EventEmitter, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations, FuseTranslationLoaderService } from 'sp-office365-layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService } from 'src/app/main/services/application.service';
import { ColumnTyp, SharePointService, SharePointTableComponent, TableConfiguration } from 'sp-office365-framework';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { LoadingService } from 'src/app/main/services/loading.service';
import CamlBuilder from 'camljs';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';

@Component({
    selector: 'app-details-workpackage-set',
    templateUrl: './details-workpackage-set.component.html',
    styleUrls: ['./details-workpackage-set.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DetailsWorkpackageSetComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    };

    public refreshForm: EventEmitter<any> = new EventEmitter();
    public tableAufgabensetsConfig: TableConfiguration;
    public subTableConfig: TableConfiguration;
    public formioConfiguration;
    public sharePointItem;
    public aufgabensets: any[];
    public id: number;
    @ViewChild('aufgabensetsGrid', { static: false }) aufgabensetsGrid: SharePointTableComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<DetailsWorkpackageSetComponent>,
        private _applicationService: ApplicationService,
        private _translateService: TranslateService,
        private _loadingService: LoadingService,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.id = this.data.id;

        if (this.id) {
            this._sharepointService
                .getItemById({ id: this.id, listTitle: 'Arbeitspaketesets' })
                .then((processstep) => {
                    this.sharePointItem = processstep;
                    this.sharePointItem.AufgabensetsId = this.sharePointItem.AufgabensetsId ?? [];
                    console.warn(this.sharePointItem);
                    this.initForm();
                });
        } else {
            setTimeout(() => this.initForm());
        }
    }

    public async formioReady() {
        let dataForm;
        const emptyObj = {};
        this._applicationService.getEmptyObject(this.formioConfiguration.components, emptyObj);

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(emptyObj, this.sharePointItem || {});
        } else {
            dataForm = emptyObj;
        }

        await this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm);

        this.refreshForm.emit({
            submission: {
                data: dataForm,
            },
        });
    }

    public initForm(): void {
        if (this.id) {
            this.initAufgabensetsTableConfig();
        }

        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant('WORKPACKAGE.FORM.TITLE'),
                    hideLabel: false,
                    disabled: this.data.readOnly,
                    placeholder: '',
                    allowMultipleMasks: false,
                    showWordCount: false,
                    showCharCount: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'textfield',
                    input: true,
                    key: 'Title',
                    defaultValue: '',
                    validate: {
                        required: true,
                        customMessage: '',
                        json: '',
                    },
                    inputFormat: 'plain',
                },
                {
                    label: this._translateService.instant(
                        'WORKPACKAGE.FORM.COLUMNS.DESCRIPTION'
                    ),
                    type: 'textarea',
                    input: true,
                    key: 'Description',
                    inputFormat: 'plain',
                    rows: 8,
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'WORKPACKAGE.FORM.OFFSETSTART'
                                    ),
                                    hideLabel: false,
                                    description: this._translateService.instant(
                                        'WORKPACKAGE.FORM.INDAYS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'OffsetAnfangsdatum',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    delimiter: false,
                                    requireDecimal: false,
                                    reorder: false,
                                },
                                {
                                    label: this._translateService.instant(
                                        'WORKPACKAGE.FORM.OFFSETEND'
                                    ),
                                    hideLabel: false,
                                    description: this._translateService.instant(
                                        'WORKPACKAGE.FORM.INDAYS'
                                    ),
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'textfield',
                                    input: true,
                                    key: 'OffsetF_x00e4_lligkeitsdatum',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    delimiter: false,
                                    requireDecimal: false,
                                    reorder: false,
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'WORKPACKAGE.FORM.OFFSETSTARTTYPE'
                                    ),
                                    hideLabel: false,
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'OffsetStartType',
                                    defaultValue: 'Ab Heute',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.TODAY'
                                                ),
                                                value: 'Ab Heute',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.STARTASSIGNMENT'
                                                ),
                                                value: 'Ab Beginn Auftrag',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.ENDASSIGNMENT'
                                                ),
                                                value: 'Ab Ende Auftrag',
                                            },
                                        ],
                                    },
                                },
                                {
                                    label: this._translateService.instant(
                                        'WORKPACKAGE.FORM.OFFSETENDTYPE'
                                    ),
                                    hideLabel: false,
                                    mask: false,
                                    tableView: true,
                                    alwaysEnabled: false,
                                    type: 'select',
                                    input: true,
                                    key: 'OffsetEndeType',
                                    defaultValue: 'Ab Heute',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.TODAY'
                                                ),
                                                value: 'Ab Heute',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.STARTASSIGNMENT'
                                                ),
                                                value: 'Ab Beginn Auftrag',
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'WORKPACKAGE.FORM.ENDASSIGNMENT'
                                                ),
                                                value: 'Ab Ende Auftrag',
                                            },
                                        ],
                                    },
                                },
                            ],
                            width: 6,
                            offset: 0,
                            push: 0,
                            pull: 0,
                            type: 'column',
                            input: false,
                            hideOnChildrenHidden: false,
                            key: 'column',
                            tableView: true,
                            label: 'Column',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'WORKPACKAGE.FORM.COLUMNS.PRIORITY'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Priority',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'WORKPACKAGE.FORM.COLUMNS.HIGH'
                                ),
                                value: '(1) Hoch',
                            },
                            {
                                label: this._translateService.instant(
                                    'WORKPACKAGE.FORM.COLUMNS.NORMAL'
                                ),
                                value: '(2) Normal',
                            },
                            {
                                label: this._translateService.instant(
                                    'WORKPACKAGE.FORM.COLUMNS.LOW'
                                ),
                                value: '(3) Niedrig',
                            },
                        ],
                    },
                },
                {
                    label: this._translateService.instant(
                        'WORKPACKAGE.FORM.COLUMNS.TYPE'
                    ),
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'AufgabentypId',
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    list: 'Aufgabentypen',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    // isCreateMode: this.isCreateMode,
                },
                {
                    label: this._translateService.instant(
                        'WORKPACKAGE.FORM.COLUMNS.PROCESSSTEP'
                    ),
                    hideLabel: false,
                    placeholder: '',
                    mask: false,
                    tableView: true,
                    alwaysEnabled: false,
                    type: 'sharePointAutocomplete',
                    input: true,
                    key: 'ProzessschrittId',
                    list: 'Prozessschritte',
                    searchQuery: new CamlBuilder()
                        .Where()
                        .TextField('Title')
                        .Contains('<%searchText%>')
                        .OrderBy('Title')
                        .ToString(),
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true,
                    validate: {
                        customMessage:
                            '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                },
                {
                    label: this._translateService.instant(
                        'WORKPACKAGE.FORM.COLUMNS.MARK'
                    ),
                    type: 'select',
                    input: true,
                    key: 'Kennzeichen',
                    data: {
                        values: [
                            {
                                label: this._translateService.instant(
                                    'WORKPACKAGE.FORM.COLUMNS.CRITICAL'
                                ),
                                value: 'Kritisch',
                            },
                            {
                                label: this._translateService.instant(
                                    'WORKPACKAGE.FORM.COLUMNS.UNCRITICALLY'
                                ),
                                value: 'Unkritisch',
                            },
                        ],
                    },
                    valueProperty: 'value',
                    selectThreshold: 0.3,
                    lazyLoad: false,
                },
                {
                    label: this._translateService.instant('WORKPACKAGE.FORM.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }

    public saveSubmission(event): Observable<void> {
        const { data } = event;
        this._loadingService.show(
            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.SAVING'),
            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.WAIT')
        );

        delete data.submit;
        delete data.isTask;

        this._applicationService.cleanDataForSaving(data, this.formioConfiguration.components);
        this._applicationService.callBeforeSaveFunctions(this.formioConfiguration, data);

        if (this.sharePointItem) {
            return from(
                this._sharepointService
                    .updateItem({
                        listTitle: 'Arbeitspaketesets',
                        id: this.id,
                        newFiledValues: {
                            ...data,
                            AufgabensetsId: { results: this.aufgabensetsGrid.allSelectedItemsIDs }
                        },
                    })
                    .then(() => {
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            ) as Observable<void>;
        } else {
            return from(
                this._sharepointService
                    .addItem({
                        listTitle: 'Arbeitspaketesets',
                        data: data,
                        folder: '',
                    })
                    .then(() => {
                        this.matDialogRef.close();
                        this._loadingService.hide();
                    })
                    .catch(() => {
                        this._loadingService.hide();
                        this._loadingService.show(
                            this._translateService.instant('WORKPACKAGE.FORM.NOTIFICATIONS.ERROR'),
                            ''
                        );
                    })
            ) as Observable<void>;
        }
    }

    private initAufgabensetsTableConfig(): void {
        this.subTableConfig = {
            list: 'Aufgabenvorlagen',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('WORKPACKAGE.FORM.COLUMNS.TITLE'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            useMaterialDesign: true,
            isDocumentLibrary: false,
            camlQuery: this.camlQueryTasks,
            parentItemLookupName: 'Aufgabensets',
        };

        this.tableAufgabensetsConfig = {
            list: 'Aufgabensets',
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant('WORKPACKAGE.FORM.COLUMNS.TITLE'),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: true,
            checkboxColumnShowSelectAll: false,
            recursiveAll: true,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString(),
            useMaterialDesign: true,
            configurationSubtable: this.subTableConfig
        };
    }

    get camlQueryTasks() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Aufgabenset" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        return (
            '<Where><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            '</Where><OrderBy><FieldRef Name="Modified" Ascending="FALSE" /></OrderBy>'
        );
    }

}
