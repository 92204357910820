<formio
  #formioComponent
  class="formio-material"
  [refresh]="refreshForm"
  (ready)="formioReady()"
  [service]="this"
  [form]="formioConfiguration"
  (change)="onChange($event)"
  [renderOptions]="formio_renderOptions">
</formio>
