import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SharePointTableComponent, TableConfiguration, } from 'sp-office365-framework';
import { DetailTasktemplateComponent } from '../detail-tasktemplate/detail-tasktemplate.component';

@Component({
    selector: 'app-table-tasktemplate',
    templateUrl: './table-tasktemplate.component.html',
    styleUrls: ['./table-tasktemplate.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TableTasktemplateComponent implements OnInit {
    @ViewChild('taskGrid', { static: false })
    taskGrid: SharePointTableComponent;

    _tasksetId: number;
    @Input() set tasksetId(tsId: number) {
        this._tasksetId = tsId;
    }

    _configuration: TableConfiguration;
    @Input() set configuration(config: TableConfiguration) {
        this._configuration = config;
    }

    _filter: string;
    @Input() set filter(filter: string) {
        this._filter = filter;
    }

    constructor(public dialog: MatDialog) {}

    ngOnInit() {}

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.openDialog();
                break;
            case 'Edit':
                this.openDialog(e.dataItem.Id);
                break;
        }
    }

    openDialog(id?): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            taskTemplateID: id,
            taskSetId: this._tasksetId,
        };

        const dialogRef = this.dialog.open(
            DetailTasktemplateComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.taskGrid.refresh();
            }
            this.taskGrid.refresh();
        });
    }

    refresh() {
        this.taskGrid.refresh();
    }
}
