import { SchedulerComponent } from './../../scheduler/scheduler.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { DayPilot, DayPilotSchedulerComponent } from 'daypilot-pro-angular';
import PerfectScrollbar from 'perfect-scrollbar';
import * as CamlBuilder from 'camljs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-timeline-assignment',
    templateUrl: './timeline-assignment.component.html',
    styleUrls: ['./timeline-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TimelineAssignmentComponent implements OnInit {
    @ViewChild('scheduler', { static: false })
    scheduler: DayPilotSchedulerComponent;

    public id: number;
    public itemTitle = '';
    public header;
    public sharePointItem;

    public schedulerReady = false;
    public config: any;

    private events = [];
    private resources = [];
    private startDate: Date;
    private endDate: Date;
    private timerangeDays = Math.round(window.innerWidth / 58.5);
    private workPackages = [];

    get camlQueryWorkPackage() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .OrderBy('Modified')
            .ToString();
    }

    constructor(
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.header = this._translateService.instant('TIMELINE.HEADER');
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            if (this.id != null) {
                this.sharePointService
                    .getItemById({ listTitle: 'Aufträge', id: this.id })
                    .then((assignment) => {
                        this.sharePointItem = assignment;
                        this.itemTitle = this.sharePointItem.Title;

                        if (this.sharePointItem.Projektnummer) {
                            this.itemTitle =
                                this.sharePointItem.Title +
                                ' (' +
                                this._translateService.instant(
                                    'TIMELINE.PROJECTNUMBER'
                                ) +
                                ': ' +
                                this.sharePointItem.Projektnummer +
                                ')';
                        }

                        // Get all workpackages from project
                        this.sharePointService
                            .getListItems({
                                title: 'Auftragsaufgaben',
                                camlQuery: this.camlQueryWorkPackage,
                                isDocumentLibrary: false,
                                folderName: this.id + '',
                            })
                            .then((elements) => {
                                this.workPackages = elements;

                                this.initScheduler();
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    }

    onNavChange() {
        //
    }

    filter(filter) {
        switch (filter) {
            case 1:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 365,
                    timeHeaders: [{ groupBy: 'Month' }, { groupBy: 'Week' }],
                    scale: 'Week',
                });
                break;
            case 2:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 730,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 3:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1095,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 4:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1460,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 5:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        0,
                        1,
                        1
                    ).toISOString(),
                    days: 1825,
                    timeHeaders: [
                        { groupBy: 'Year' },
                        { groupBy: 'Month', format: 'MM' },
                    ],
                    scale: 'Month',
                });
                break;
            case 6:
                this.scheduler.control.update({
                    startDate: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        2
                    ).toISOString(),
                    // Get number of days in current month
                    days: new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                    ).getDate(),
                    timeHeaders: [
                        { groupBy: 'Month' },
                        { groupBy: 'Day', format: 'dd.' },
                    ],
                    scale: 'Day',
                });
                break;
            default:
                this.initScheduler();
                break;
        }
    }

    openDialog(wpId: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.id,
            isTask: false,
            taskId: wpId,
        };

        this.dialog.open(SchedulerComponent, dialogConfig);
    }

    initScheduler() {
        const self = this;

        this.startDate = new Date();
        this.endDate = new Date();

        this.resources = [];
        this.events = [];

        this.workPackages.forEach((workpackage) => {
            if (new Date(workpackage.StartDate) < this.startDate) {
                this.startDate = new Date(workpackage.StartDate);
            }

            if (new Date(workpackage.DueDate) > this.endDate) {
                this.endDate = new Date(workpackage.DueDate);
            }

            this.resources.push({
                name: workpackage.Title,
                id: workpackage.Id,
                expanded: false,
                // children: [{ name: 'Production Line A.2', id: 'R2' }]
            });

            this.events.push({
                id: workpackage.Id,
                start: this.addHours(
                    new Date(workpackage.StartDate),
                    2
                ).toISOString(),
                end: this.addHours(
                    new Date(workpackage.DueDate),
                    3
                ).toISOString(),
                resource: workpackage.Id,
                text: workpackage.Title,
                bubbleHtml:
                    '<b>' +
                    workpackage.Title +
                    '</b></br>' +
                    this._translateService.instant('START') +
                    ': ' +
                    new Date(workpackage.StartDate).toLocaleDateString() +
                    '</br>' +
                    this._translateService.instant('END') +
                    ': ' +
                    new Date(workpackage.DueDate).toLocaleDateString(),
            });
        });

        if (
            this.timerangeDays <
            Math.ceil((+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24))
        ) {
            this.timerangeDays = Math.ceil(
                (+this.endDate - +this.startDate) / (1000 * 60 * 60 * 24)
            );
        }

        this.config = {
            timeHeaders: [
                { groupBy: 'Month' },
                { groupBy: 'Day', format: 'dd.' },
            ],
            scale: 'Day',
            days: this.timerangeDays,
            startDate: new Date(
                this.addHours(this.addDays(this.startDate, -1), 2)
            ).toISOString(),
            eventHeight: 50,
            cellWidth: 50,
            durationBarVisible: false,
            eventStackingLineHeight: 75,
            crosshairType: 'Header',
            separators: [
                {
                    color: 'red',
                    location: new DayPilot.Date(),
                    width: 5,
                },
            ],
            locale: navigator.language,
            rowHeaderWidthAutoFit: false,
            rowHeaderWidth: 120,
            rowHeaderHideIconEnabled: true,
            rowMinHeight: 50,
            events: this.events,
            resources: this.resources,
            onAfterRender(args) {
                self.scheduler.control.scrollTo(
                    self.addDays(new Date(), -7).toISOString()
                );

                const container: any = document.querySelector(
                    'daypilot-scheduler div .scheduler_default_scrollable'
                );
                const ps = new PerfectScrollbar(container);
            },
            eventHoverHandling: 'Bubble',
            eventClickHandling: 'Enabled',
            onEventClicked(args) {
                self.openDialog(args.e.id());
            },
            eventResizeHandling: 'Disabled',
            onEventResized: (args) => {
                this.scheduler.control.message(
                    this._translateService.instant('RESIZED')
                );
            },
            eventMoveHandling: 'Disabled',
            onEventMoved: (args) => {
                this.scheduler.control.message(
                    this._translateService.instant('MOVED')
                );
            },
        };

        this.schedulerReady = true;
    }

    addHours(date: Date, h: number): Date {
        const result = new Date(date);
        result.setTime(date.getTime() + h * 60 * 60 * 1000);

        return result;
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}
