import { NgModule } from '@angular/core';
import { DetailRequestComponent } from './detail-request.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FormioModule } from '@formio/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { OverviewBudgetModule } from '../../budget/overview-budget/overview-budget.module';
import { DetailCustomerModule } from '../../customer/detail-customer/detail-customer.module';

const routes = [
    {
        path: 'new-request',
        component: DetailRequestComponent,
    },
    {
        path: 'requests/detail/basedata/:id',
        component: DetailRequestComponent,
    },
];

@NgModule({
    declarations: [DetailRequestComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        FormioModule,
        DetailCustomerModule,
        OverviewBudgetModule,
    ],
    exports: [DetailRequestComponent],
})
export class DetailRequestModule {}
