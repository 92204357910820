export const locale = {
    lang: 'de',
    data: {
        CONFIRM_DIALOG: {
            CONFIRM: 'Bestätigung',
            OK: 'OK',
            CANCEL: 'Abbrechen',
        },
    },
};
