import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1111Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const mailboxList: IList = this._sharePointService.web.lists.getByTitle(
                'Mailbox'
            );
            const workpackagesList: IList = this._sharePointService.web.lists.getByTitle(
                'Auftragsaufgaben'
            );
            const projectList: IList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );

            const promises = [
                
                // Add Field Arbeitspaket to List Mailbox
                await this._updateService.addLookupField(
                    mailboxList,
                    workpackagesList,
                    'Arbeitspaket'
                ),

                // Add Field ProjectMail to List Aufträge
                await this._updateService.addField(
                    projectList,
                    'ProjectMail',
                    FieldType.Text
                ),


            ];


            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
