export const locale = {
    lang: 'en',
    data: {
        TITLE: 'Documents',
        SEARCH_LABEL: 'Search for a document',
        COLUMNS: {
            EDITPERMISSIONS: 'Edit permissions',
            PROJECT: 'Project',
        },
        NEW: 'New document',
        EDIT: 'Edit document',
        EDITPERMISSIONS: 'Set permissions',
        SAVE: 'Save',
        CURRENTUSER: '(Currentuser)',
        MANAGER: '(Projectmanager)',
        DEFAULTPERMISSIONS: 'Defaultpermissions',
        NOTIFICATIONS: {
            SAVINGITEM: 'Saving document...',
            SAVING: 'Saving permissions...',
            WAIT: 'Please wait.',
            SUCCESS: 'Permissions set successfully',
            SUCCESSITEM: 'Document saved successfully',
            ERROR: 'Error while setting permissions: ',
            DUEDATEERROR:
                'The due date cannot exceed the end date of the project.',
            PERMISSIONS:
                'Hint! The following useres get automatically permissions: ',
        },
    },
};
