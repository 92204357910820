export const locale = {
    lang: 'de',
    data: {
        ASSIGN_EMAIL: {
            ADDATTACHMENT: 'Datei anhängen',
            NOTIFICATIONS: {
                LOADING: 'E-Mails werden geladen...',
                SAVING: 'E-Mails werden eingelesen...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'E-Mails erfolgreich eingelesen.',
                ERROR: 'Fehler beim einlesen: ',
            },
            COLUMNS: {
                TITLE: 'Titel',
                SUBJECT: 'Betreff',
                TEXT: 'Nachricht',
                TO: 'An',
                FROM: 'Von',
                ATTACHMENTS: 'Anhänge',
                SENTDATETIME: 'Gesendet am',
                STATUS: 'Status',
                ALREADYCREATED: 'Bereits eingelesen'
            },
            IMPORT_MAILS_DIALOG: {
                OK: 'OK',
                DUPLICATE: 'Duplikat',
                UNEXPECTED: 'Unerwarteter Fehler',
                IMPORTED: 'Fertig',
            },
            SAVE: 'E-Mails einlesen',
            FOLDER: 'Ordner auswählen',
            ASSIGN: 'E-Mails einlesen',
            NEW: 'E-Mails einlesen',
            PATH: 'aktueller Pfad',
            DIRUP: 'Verzeichnis hoch',
            REFRESH: 'Aktualisieren'
        },
    },
};
