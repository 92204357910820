import { ColumnTyp, SharePointService } from 'sp-office365-framework';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService, ListViewConfig, } from 'sp-office365-layout';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import * as CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailCustomerComponent } from '../detail-customer/detail-customer.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-listview-customer',
    templateUrl: './listview-customer.component.html',
    styleUrls: ['./listview-customer.component.scss'],
})
export class ListviewCustomerComponent implements OnInit {
    public config: ListViewConfig;
    private allLocations;

    @ViewChild('listView', { static: false }) listView: ListViewComponent;

    get allCustomersCamlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Title')
            .ToString();
    }

    get allCustomersSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .Any([
                CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('Title2').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('Title3').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('WorkZip').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('WorkCity').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('Strasse').Contains('<%searchText%>'),
                CamlBuilder.Expression().TextField('Kunden_Niederlassung').Contains('<%searchText%>'),
            ])

            .OrderByDesc('Title')
            .ToString();
    }

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        let allIds = [];

        this._sharepointService
            .getListItems({
                title: 'Firmen',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    .NotEqualTo(-1)
                    .ToString(),
            })
            .then((items) => {
                let noLocationExists: boolean = true;
                for (let item of items) {
                    if (item.StandortID) {
                        //At least one Entrys location is set
                        noLocationExists = false;
                        if (!allIds.includes(item.StandortID))
                            allIds.push(item.StandortID);
                    }
                }
                if (noLocationExists) {
                    this.initConfig();
                } else {
                    this._sharepointService
                        .getListItems({
                            title: 'Standorte',
                            isDocumentLibrary: false,
                            camlQuery: new CamlBuilder()
                                .Where()
                                .NumberField('ID')
                                .In(allIds)
                                .ToString(),
                        })
                        .then((locations) => {
                            this.allLocations = locations;

                            this.initConfig();
                        })
                        .catch((err) => {
                            console.error(err);
                            this.initConfig();
                        });
                }
            })
            .catch((err) => {
                console.error(err);
                this.initConfig();
            });
    }

    showNames(dataItem) {
        if (dataItem.StandortID && this.allLocations) {
            const locationItem: any = this.allLocations.filter(
                (element) => dataItem.StandortID == element.Id
            )[0];

            return locationItem ? locationItem.Title : '';
        }
        return;
    }

    openDialog(itemId?): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            id: itemId,
        };

        const dialogRef = this.dialog.open(
            DetailCustomerComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.ngOnInit();
            // Refresh table
            self.listView._listViewService.onListViewDataChanged.next(
                self.listView._listViewService.listView
            );
        });
    }

    initConfig() {
        this.config = {
            title: this._translateService.instant('LV_CUSTOMER.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_CUSTOMER.SEARCH_LABEL'
            ),
            icon: 'contacts',
            add_icon: 'add',
            openEditInDialog: false,
            onRowClick: (item) => this.openDialog(item.Id),
            onAddClick: () => this.openDialog(),
            tableConfigs: [
                {
                    title: this._translateService.instant(
                        'LV_CUSTOMER.ALLCUSTOMERS'
                    ),
                    config: {
                        list: 'Firmen',
                        pageSize: 10,
                        excelExportFileName: 'Kunden',
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.TITLE1'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Title2',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.TITLE2'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Title3',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.TITLE3'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Strasse',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.STREET'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'WorkZip',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.ZIP'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'WorkCity',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.CITY'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Kunden_Niederlassung',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.LOCATIONS'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Email',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.EMAIL'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Land',
                                title: this._translateService.instant(
                                    'LV_CUSTOMER.COLUMNS.LAND'
                                ),
                                hidden: false,
                                type: ColumnTyp.Lookup,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: true,
                        camlQuery: this.allCustomersCamlQuery,
                        useMaterialDesign: true
                    },
                    camlQueryForSearch: this.allCustomersSearchCamlQuery,
                },
            ],
        };
    }
}
