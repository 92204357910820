import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-folder-dialog',
    templateUrl: './add-folder-dialog.component.html',
    styleUrls: ['./add-folder-dialog.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AddFolderDialogComponent {
    public confirmMessage: any;
    public value: string;
    public placeHolder;

    constructor(
        public dialogRef: MatDialogRef<AddFolderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.placeHolder = this._translateService.instant(
            'ADDFOLDERDIALOG.NAME'
        );

        this.confirmMessage = this._translateService.instant(
            'ADDFOLDERDIALOG.MESSAGE'
        );
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    close(): void {
        this.dialogRef.close(false);
    }

    save(): void {
        if (!this.value) {
            this.dialogRef.close(false);
        } else {
            this.dialogRef.close(this.value);
        }
    }
}
