import { LookupService } from './../../services/lookup.service';
import { SharepointEntrysService } from './../../services/sharepoint-entrys.service';
import { ChartService } from './../../services/chart.service';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import CamlBuilder from 'camljs';
import { SharePointService } from 'sp-office365-framework';
import {
    FrameworkService,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-filter-evaluation',
    templateUrl: './filter-evaluation.component.html',
    styleUrls: ['./filter-evaluation.component.scss'],
})
export class FilterEvaluationComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de'
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();
    public formioConfiguration;

    public isManager = false;

    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;

    constructor(
        private _applicationService: ApplicationService,
        private _lookupService: LookupService,
        private _sharepointEntrysService: SharepointEntrysService,
        private _frameworkService: FrameworkService,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _chartService: ChartService,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        const promises = [];

        // Check if currentUser is Manager
        if (this._sharePointService.currentUser.IsBusinessOwner || this._currentUserService.isTeamProjectLeiter()) {
            this.isManager = true;
        }

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    ngOnDestroy() {
        this._lookupService.reset();
        this._chartService.showChart = true;
        this._sharepointEntrysService.yearChart = {
            from: this._sharepointEntrysService.getCurrentYear(),
            until: this._sharepointEntrysService.getCurrentYear(),
        };
        this._sharepointEntrysService.monthChart = {
            from: this._sharepointEntrysService.getCurrentMonth(),
            until: this._sharepointEntrysService.getCurrentMonth(),
        };
    }

    updateCamlQuery(key: 'TeamId' | 'StandortId'): void {
        let camlQuery;
        let searchCaml;

        camlQuery =
            key == 'StandortId'
                ? this._lookupService.locationCAMLQuery
                : this._lookupService.teamCAMLQuery;
        searchCaml =
            key == 'StandortId'
                ? this._lookupService.locationSearchCAMLQuery
                : this._lookupService.teamSearchCAMLQuery;

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQueryIfIsEmpty',
            camlQuery
        );

        this._frameworkService.updateFormioComponentProperty(
            this.formioComponent,
            key,
            'searchQuery',
            searchCaml
        );
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;
        dataForm.Hotkeys = 'Monat';
        dataForm.monthFrom = this._sharepointEntrysService.getCurrentMonth();
        dataForm.monthUntil = this._sharepointEntrysService.getCurrentMonth();
        dataForm.yearFrom = this._sharepointEntrysService.getCurrentYear();
        dataForm.yearUntil = this._sharepointEntrysService.getCurrentYear();

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    onChange(ev) {
        //If ev.add/ev.remove == true --> location or team has been added or removed
        const promises = [];
        if (ev.add) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = ev.Id;
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharePointService
                            .getListItems({
                                title: 'Teams',
                                isDocumentLibrary: false,
                                camlQuery: new CamlBuilder()
                                    .Where()
                                    .LookupField('Standort')
                                    .Id()
                                    .EqualTo(ev.Id)
                                    .ToString(),
                            })
                            .then((results) => {
                                const teams = results;
                                let teamId: number[] = [];
                                teams.forEach((team) => {
                                    teamId.push(team.Id);
                                });
                                this._lookupService.teamsFromLocation = [
                                    ...teamId,
                                ];
                                resolve();
                            })
                            .catch((err) => {
                                console.log(err);
                                reject();
                            });
                    })
                );
            } else {
                this._lookupService.teams.push(ev.Id);
                this._lookupService.locationLookUpId = ev.StandortId;
            }
        } else if (ev.remove) {
            if (ev['odata.type'] == 'SP.Data.StandorteListItem') {
                this._lookupService.location = null;
                this._lookupService.teamsFromLocation = [];
            } else {
                let leftTeamsInArray = this._lookupService.teams.filter(
                    (value) => {
                        return value != ev.Id;
                    }
                );
                this._lookupService.teams = leftTeamsInArray;

                this._lookupService.locationLookUpId = null;
            }
        }

        if (ev.changed) {
            switch (ev.changed.component.key) {
                case 'monthUntil':
                    this._sharepointEntrysService.monthChart.until =
                        ev.changed.value;
                    break;

                case 'monthFrom':
                    this._sharepointEntrysService.monthChart.from =
                        ev.changed.value;
                    break;

                case 'yearFrom':
                    this._sharepointEntrysService.yearChart.from =
                        ev.changed.value || 0;
                    break;

                case 'yearUntil':
                    this._sharepointEntrysService.yearChart.until =
                        ev.changed.value || 0;
                    break;
            }
        }

        Promise.all(promises)
            .then(() => {
                if (
                    this._sharepointEntrysService.yearChart.from < 1000 ||
                    this._sharepointEntrysService.yearChart.from > 9999 ||
                    this._sharepointEntrysService.yearChart.until < 1000 ||
                    this._sharepointEntrysService.yearChart.until > 9999
                ) {
                    this._chartService.errorMessage =
                        this._translateService.instant(
                            'FILTER.ERRORMESSAGE.FOURDIGITS'
                        );
                    this._chartService.showChart = false;
                } else if (
                    this._sharepointEntrysService.yearChart.from >
                    this._sharepointEntrysService.yearChart.until
                ) {
                    this._chartService.errorMessage =
                        this._translateService.instant(
                            'FILTER.ERRORMESSAGE.YEARS'
                        );
                    this._chartService.showChart = false;
                } else if (
                    this._sharepointEntrysService.yearChart.from >=
                        this._sharepointEntrysService.yearChart.until &&
                    this._sharepointEntrysService.monthChart.until <
                        this._sharepointEntrysService.monthChart.from
                ) {
                    this._chartService.errorMessage =
                        this._translateService.instant(
                            'FILTER.ERRORMESSAGE.NOTVALID'
                        );
                    this._chartService.showChart = false;
                } else {
                    this._chartService.showChart = true;
                    this._chartService.updateValues();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    initForm() {
        this.formioConfiguration = {
            components: [
                //ANCHOR 1.Jahr Monat von - Jahr von
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.MONTH.FROM'
                                    ),
                                    type: 'select',
                                    key: 'monthFrom',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.1'
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.2'
                                                ),
                                                value: 2,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.3'
                                                ),
                                                value: 3,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.4'
                                                ),
                                                value: 4,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.5'
                                                ),
                                                value: 5,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.6'
                                                ),
                                                value: 6,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.7'
                                                ),
                                                value: 7,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.8'
                                                ),
                                                value: 8,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.9'
                                                ),
                                                value: 9,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.10'
                                                ),
                                                value: 10,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.11'
                                                ),
                                                value: 11,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.12'
                                                ),
                                                value: 12,
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.FROM_YEAR'
                                    ),
                                    type: 'number',
                                    validate: {
                                        min: 1000,
                                        max: 9999,
                                    },
                                    key: 'yearFrom',
                                    hidden: false,
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                //ANCHOR 2.Reihe Monat bis und Jahr bis
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.MONTH.UNTIL'
                                    ),
                                    type: 'select',
                                    key: 'monthUntil',
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.1'
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.2'
                                                ),
                                                value: 2,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.3'
                                                ),
                                                value: 3,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.4'
                                                ),
                                                value: 4,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.5'
                                                ),
                                                value: 5,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.6'
                                                ),
                                                value: 6,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.7'
                                                ),
                                                value: 7,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.8'
                                                ),
                                                value: 8,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.9'
                                                ),
                                                value: 9,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.10'
                                                ),
                                                value: 10,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.11'
                                                ),
                                                value: 11,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'FILTER.MONTH.12'
                                                ),
                                                value: 12,
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.UNTIL_YEAR'
                                    ),
                                    type: 'number',
                                    key: 'yearUntil',
                                    validate: {
                                        min: 1000,
                                        max: 9999,
                                    },
                                    hidden: false,
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                //ANCHOR 3.Zeile Standort und Teams
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.LOCATION'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'StandortId',
                                    list: 'Standorte',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.locationCAMLQuery,
                                    searchQuery:
                                        this._lookupService
                                            .locationSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('TeamId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('TeamId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'FILTER.TEAM'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    key: 'TeamId',
                                    list: 'Teams',
                                    searchQueryIfIsEmpty:
                                        this._lookupService.teamCAMLQuery,
                                    searchQuery:
                                        this._lookupService.teamSearchCAMLQuery,
                                    isPeoplePicker: false,
                                    multi: true,
                                    removable: true,
                                    onSelected: (control, data) => {
                                        data.add = true;
                                        data.remove = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('StandortId');
                                    },
                                    onRemoved: (control, data) => {
                                        data.remove = true;
                                        data.add = false;

                                        this.onChange(data);

                                        this.updateCamlQuery('StandortId');
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
            ],
        };
    }
}
