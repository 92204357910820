<listview #listView
          *ngIf="this.config"
          [config]="this.config"
          (onTooolbarButtonClick)="toolbarButtonClick($event)"
          class="page-layout times-table simple left-sidebar inner-sidebar inner-scroll"
>
    <ng-template customHeader>
        <div class="listview-header">
            <app-filter-time></app-filter-time>
        </div>
    </ng-template>

    <ng-template customGroupHeader for="OriginalDate" let-group="group">
      <div *ngIf="group.value">
        {{'LV_REPORT.COLUMNS.DATE' | translate}}:
        {{group.value | date}}
      </div>
    </ng-template>

    <ng-template customCell for="Minuten" let-dataItem="dataItem">
        {{ dataItem.Minuten | number : '1.0-2' }}
    </ng-template>
    <ng-template customCell for="Kosten" let-dataItem="dataItem">
        {{ dataItem.Kosten | number : '1.0-2' }}
    </ng-template>
</listview>
<kendo-excelexport #kendoExcelExport fileName="Report.xlsx">
    <ng-container *ngFor="let column of tableColumns">
        <kendo-excelexport-column *ngIf="!column.hidden"
                                  [field]="column.internalName"
                                  [title]="column.title"
        >
        </kendo-excelexport-column>
    </ng-container>
</kendo-excelexport>
