export const locale = {
    lang: 'de',
    data: {
        DETAIL_CONTACT: {
            EDIT: 'Kontakt bearbeiten',
            NEW: 'Neuer Kontakt',
            NOTIFICATIONS: {
                SAVING: 'Kontakt wird gespeichert...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Kontakt erfolgreich gespeichert.',
                ERROR: 'Fehler beim speichern: ',
            },
            FORM: {
                SALUTATION: 'Anrede',
                TITLE: 'Nachname',
                FIRSTNAME: 'Vorname',
                EMAIL: 'E-Mail',
                JOBTITLE: 'Position',
                WORKPHONE: 'Telefon (Geschäftlich)',
                HOMEPHONE: 'Telefon (Privat)',
                CELLPHONE: 'Mobil',
                WORKFAX: 'Fax',
                WORKADRESS: 'Adresse',
                STREET: 'Straße/Hausnummer',
                ZIP: 'PLZ',
                CITY: 'Ort',
                WEBPAGE: 'Homepage',
                CUSTOMER: 'Firma',
                SAVE: 'Speichern',
            },
        },
    },
};
