export const locale = {
    lang: 'en',
    data: {
        LV_REPORT: {
            TITLE: 'Reports',
            SEARCH_LABEL: 'Search for a time',
            ALLTIMES: 'All',
            COLUMNS: {
                DATE: 'Date',
                PROJECTNUMBER: 'Projectnumber',
                MINUTES: 'Duration (in hours)',
                MINUTESEXCEL: 'Duration (in minutes)',
                HOURS: 'Duration (in hours)',
                COSTS: 'Costs (in €)',
                PROJECT: 'Project',
                TASK: 'Task',
                AUTHOR: 'Employee',
                DESCRIPTION: 'Description',
                SUM: 'Sum: ',
            },
        },
    },
};
