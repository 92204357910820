<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id && !this.data.readOnly" class="title dialog-title">{{ "DETAIL_TASKSET.EDIT" | translate
      }}</span>
    <span *ngIf="!this.id && !this.data.readOnly" class="title dialog-title">{{ "DETAIL_TASKSET.NEW" | translate
      }}</span>
    <span *ngIf="this.data.readOnly" class="title dialog-title">{{ "DETAIL_TASKSET.TASKSET" | translate }}</span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

    <mat-tab label='{{ "DETAIL_TASKSET.BASE" | translate }}'>

      <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
        [renderOptions]="formio_renderOptions" (change)="onChange($event)">
      </formio>

    </mat-tab>

    <mat-tab label='{{ "DETAIL_TASKSET.TASKTEMPLATES" | translate }}' *ngIf="this.id">

      <app-table-tasktemplate [configuration]="tableConfigTasktemplates" [tasksetId]="id"></app-table-tasktemplate>

    </mat-tab>

  </mat-tab-group>

</div>
