import { NgModule } from '@angular/core';
import { InvolvedAssignmentComponent } from './involved-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TableInvolvedModule } from '../../involved/table-involved/table-involved.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
    {
        path: 'projects/detail/involved',
        component: InvolvedAssignmentComponent,
    },
    {
        path: 'projects/detail/involved/:id',
        component: InvolvedAssignmentComponent,
    },
];

@NgModule({
    declarations: [InvolvedAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        AngularFrameworkModule,
        MatCardModule,
        TableInvolvedModule,
    ],
    exports: [InvolvedAssignmentComponent],
})
export class InvolvedAssignmentModule {}
