export const locale = {
  lang: 'de',
  data: {
    NEW:"Neuer Eintrag",
    CONTROLLING:"Controlling",
    MONTH_:"Monat",
    YEAR:"Jahr",
    EDIT:"Eintrag bearbeiten",
    SUCCESS:"Erfolgreich gespeichert",
    SEARCH: "Eintrag suchen",
    TARGETFIGURES:"Soll-Zahlen",
    TABS:{
        NUMBERS: "Soll-Zahlen"
    },
    FORM:{
        TEAM :"Team",
        MONTH: "Monat",
        YEAR: "Jahr",
        VALUE: "Soll-Wert"
    },
    TABLE:{
        AMOUNT:"Soll-Betrag",
        TEAM:"Team"
    },
    MONTH:{
        JANUARY: "Januar",
        FEBRUARY: "Februar",
        MARCH: "März",
        APRIL: "April",
        MAY: "Mai",
        JUNE: "Juni",
        JULY: "Juli",
        AUGUST: "August",
        SEPTEMBER: "September",
        OCTOBER: "Oktober",
        NOVEMBER: "November",
        DECEMBER: "Dezember"
    },
    NOTIFICATIONS:{
        SAVING:"Speichern",
        WAIT:"Bitte warten Sie..."
    },
    SAVE:"Speichern"
  }
};
