import { IList } from '@pnp/sp/presets/all';
import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1116Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            await this._updateService.addList('Berechtigungsgruppen');
            const roleList: IList =
                await this._sharePointService.web.lists.getByTitle(
                    'Berechtigungsgruppen'
                );
            await this._updateService.addUserField(
                roleList,
                'Berechtigungsgruppe',
                false
            );

            const promises = [
                this._sharePointService.addItem({
                    listTitle: 'Berechtigungsgruppen',
                    folder: '',
                    data: {
                        Title: 'Standard Gruppe für Genehmigungen',
                    },
                }),

                this._sharePointService.addItem({
                    listTitle: 'Berechtigungsgruppen',
                    folder: '',
                    data: {
                        Title: 'Standard Gruppe für Bearbeitungsberechtigungen',
                    },
                }),

                this._sharePointService.addItem({
                    listTitle: 'Berechtigungsgruppen',
                    folder: '',
                    data: {
                        Title: 'Projektanleger',
                    },
                }),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
