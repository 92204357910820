export const locale = {
    lang: 'de',
    data: {
        CHART_SERVICE: {
            ERROR: "Für die angegebenen Filterkriterien fehlt mindestens ein Eintrag in der Liste 'Sollzahlen'!",
        },
        TASK_SERVICE: {
            NOTASKSETSELECTED: 'Kein Aufgabenset ausgewählt.',
            NOTASKS: 'Aufgabenset enthält keine Aufgaben.',
            ERROR: 'Fehler: ',
            DELETETASK: {
                MESSAGE:
                    'Sollen alle möglicherweise vorhandenen Aufgaben des gelöschten Arbeitpakets ebenfalls gelöscht werden?',
                SUCCESS:
                    'Alle Aufgaben des gelöschten Arbeitspakets wurden ebenfalls gelöscht',
                NOTASKS: 'Das gelöschte Arbeitspaket enthielt keine Aufgaben!',
            },
            SUCCESS: 'Aufgabe erfolgreich angelegt!',
            WORKPACKAGESTATUS: 'Arbeitspaket auf folgenden Status gesetzt: ',
            PROJECTSTATUS: 'Projekt in folgenden Status gesetzt: ',
            SYNCTOWP:
                'Sollen folgende Werte auf die Daten der zugehörigen Arbeitspakete und Aufgaben addiert werden? ',
            SYNCTOTASK:
                'Sollen folgende Werte auf die Daten der zugehörigen Aufgaben addiert werden? ',
            START: 'Start: ',
            END: 'Ende: ',
            DAYS: 'Tage',
            SUCCESSSYNC:
                'Dauer für zugehörige Arbeitspakete/Aufgaben übernommen!',
            NOTIFICATIONS: {
                SAVINGPROJECT: 'Projekt wird gespeichert...',
                SAVINGWP: 'Arbeitspaket wird gespeichert...',
                WAIT: 'Bitte warten.',
            },
        },
        REQUESTS_SERVICE: {
            BASE: 'Stammdaten',
            DOCS: 'Dokumente',
            MAILS: 'E-Mails',
        },
        MOVE_SERVICE: {
            ERROR: 'Beim Verschieben trat ein Fehler auf!',
        },
        APPLICATION_SERVICE: {
            PROJECTS: 'Projekte',
            BASE: 'Stammdaten',
            COSTROLES: 'Kostenrollen',
            DOCS: 'Dokumente',
            INVOICES: 'Rechnungen',
            INVOLVED: 'Beteiligte',
            TASKS: 'Arbeitspakete',
            KANBAN: 'Kanban',
            NOTES: 'Notizen',
            COMMENTS: 'Kommentare',
            MAILS: 'E-Mails',
            TIMES: 'Zeiten',
            TIMELINE: 'Zeitplan',
            NOTIFICATIONS: {
                COPYCATEGORYDOCS:
                    'Der ausgewählten Kategorie sind Dokumente zugewiesen. Sollen diese in das Projekt kopiert werden?',
            },
        },
        ORDER_SERVICE: {
            BASE: 'Stammdaten',
            DOCS: 'Dokumente',
            MAILS: 'E-Mails',
            COSTROLES: 'Kostenrollen',
        },
        TIME_SERVICE: {
            NOTIFICATIONS: {
                DELETING: 'Der ausgewählte Zeiteintrag wird gelöscht',
                SAVING: 'Zeit wird gespeichert...',
                WAIT: 'Bitte warten.',
                UPDATE: 'Die Änderungen wurden übernommen.',
                SUCCESS: 'Zeit erfolgreich gespeichert.',
                ERRORTASK: 'Bitte wählen Sie eine Aufgabe aus!',
                ERRORDURATION: 'Die Dauer muss größer 0 sein!',
                ERRORDATE: 'Bitte wählen Sie ein Datum aus!',
                HOURSERROR: 'Stunden: Nur Zahlen zwischen 0 und 23',
                MINUTESERROR:
                    'Minuten: Nur Zahlen der 5er Reihe und kleiner 60',
                ZEROCOSTPERHOUR:
                    'Die Kosten pro Stunde betragen Null. Bist du dir sicher, dass du weitermachen willst?',
                THROTTLED_ERROR:
                    'Die Abfrage liefert zu viele Ergebnisse. Bitte verfeinern Sie die Filterkriterien.',
            },
            COLUMNS: {
                DATE: 'Datum',
                MINUTES: 'Dauer (in Stunden)',
                COSTS: 'Kosten (in €)',
                PROJECT: 'Projekt',
                TASK: 'Aufgabe',
                AUTHOR: 'Mitarbeiter',
                DESCRIPTION: 'Beschreibung',
            },
        },
        BUDGET_SERVICE: {
            BOOKED: 'Verbraucht',
            OPEN: 'Verfügbar',
            TOTALSUM_BILL: 'Verbraucht',
            TOTALSUMLEFT_BILL: 'Verfügbar',
        },
    },
};
