export const locale = {
    lang: 'en',
    data: {
        DETAIL_ORDER: {
            ORDERS: 'Orders',
            BASE: 'Basedata',
            NEW: 'New order',
            FORM: {
                CREATED1: 'Created by ',
                CREATED2: ' on ',
                CREATED3: ' at ',
                DATE: 'Orderdate',
                DESCRIPTION: 'Description',
                CLIENT: 'Client',
                TAXER: 'Taxer',
                TAXERDESCRIPTION: 'only for auditing',
                COSTCENTRE: 'Cost centre',
                TEAM: 'Team',
                AREA: 'Subject area',
                LOCATION: 'Location',
                ACCREDITED: 'Accredited',
                MANAGER: 'Projectmanager',
                CLERK: 'Clerk',
                SUM: 'Sum',
                INEURO: '(in Euro)',
                ADDITIONAL: 'Additional costs',
                INPERCENT: '(in %)',
                EXTERNAL: 'External service',
                COPY: 'Copy order',
                CONFIRMATION: 'Send confirmation',
                FOLDER: 'Add folder',
                XMAS: 'X-Mas card',
                HANDINGOVER: 'Handing over date',
                SAVE: 'Save',
                ACCEPT: 'Accept',
                DELETE: 'Delete',
                POSITION:{
                    HEADER:"Position",
                    TITLE: "Title",
                    WORK:"Work",
                    NETTO:"Netto Amount [€]"
                }
            },
            NOTIFICATIONS: {
                SAVING: 'Saving order...',
                DELETING: 'Deleting order...',
                CREATINGPROJECT: 'Creating project...',
                WAIT: 'Please wait.',
                SUCCESS: 'Order saved successfully',
                SUCCESSDELETE: 'Order deleted successfully',
                SUCCESSPROJECT: 'Project created successfully',
                ERROR: 'Error while saving: ',
            },
        },
    },
};
