import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SharePointService } from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
} from 'sp-office365-layout';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import CamlBuilder from 'camljs';
import {
    groupBy,
    GroupDescriptor,
    GroupResult,
    State,
} from '@progress/kendo-data-query';
import {
    DataStateChangeEvent,
    ExcelExportEvent,
    GridComponent,
} from '@progress/kendo-angular-grid';
import { debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FormioComponent } from '@formio/angular';
import { TimeService } from 'src/app/main/services/time.service';
import { DateUtil } from 'src/app/main/shared/utils';
import { toFixed } from 'src/app/main/shared/shared.util';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { EmployeeanalysisUtils } from './employeeanalysis.utils';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-listview-employeeanalysis',
    templateUrl: './listview-employeeanalysis.component.html',
    styleUrls: ['./listview-employeeanalysis.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ListviewEmployeeanalysisComponent implements OnInit {
    // filter vars
    public formio_renderOptions = {
        // language: 'de'
    };
    public formioConfiguration;
    public refreshFormio: EventEmitter<any> = new EventEmitter();
    public filterChangeEmitter = new EventEmitter<{
        year?: string;
        month?: number;
        employee?: string;
        teammanager?: number;
    }>();
    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;
    @ViewChild('usersExcelTable', { static: false })
    usersExcelTable: GridComponent;
    @ViewChild('usersFlatExcelTable', { static: false })
    usersFlatExcelTable: ExcelExportComponent;

    // grid vars
    private gridData: {
        employee: {
            id: number;
            title: string;
        };
        team: {
            id: number;
            title: string;
        };
        totalTimeInHours: number;
        plannedTimeInHours?: number;
        percentTime: number;
        month: string;
        userGroup?: string;
        year: number;
        yearGroup?: string;
        fullDate: Date;
    }[] = [];
    public filteredGridData = [];
    public tableConfig: any = null;
    public state: State = {};
    public gridView:
        | GroupResult[]
        | {
              employeeId: number;
              employeeTitle: string;
              teamId: number;
              teamTitle: string;
              percentTime: string;
              totalTimeInHours: string;
              totalTimeInHoursNumber: number;
              plannedTimeInHoursNumber: number;
              year: number;
              month: string;
          }[];
    public groups: GroupDescriptor[] = [{ field: 'employeeTitle' }];

    // other vars
    private teamsWithManagers: {
        TeamleiterId?: number; // Teamleiter Id
        FieldValuesAsText?: {
            Teamleiter?: string; // Teamliter Title
        };
        Title: string; // Team Title
        Id: number; // Team Id
    }[] = [];

    public isManager = false;
    public isTeamManagerForTeams: any[] = [];

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        private _timeService: TimeService,
        private sanitized: DomSanitizer,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    async ngOnInit() {
        // Check if currentUser is Manager
        if (
            this._sharepointService.currentUser.IsBusinessOwner ||
            this._currentUserService.isTeamProjectLeiter()
        ) {
            this.isManager = true;
        }
        console.error('isManager', this.isManager);

        // Check if currentUser is Manager for teams
        this.isTeamManagerForTeams = await this._sharepointService.getListItems(
            {
                title: 'Teams',
                isDocumentLibrary: false,
                camlQuery: new CamlBuilder()
                    .Where()
                    .UserField('Teamleiter')
                    .EqualToCurrentUser()
                    .And()
                    .NumberField('FSObjType')
                    .EqualTo(0)
                    .OrderByDesc('Datum')
                    .ToString(),
                recursiveAll: true,
            }
        );

        this._timeService.getUserDailyHours().subscribe(async () => {
            await this._timeService.initHolidaysPerUser();
            await this.calcGridData();
            await this.loadTeamManagers();
            this.initFormio();
            this.initTable();
            this.subscribeFilterChange();
        });
    }

    private async calcGridData() {
        const qryAll = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        const qryUser = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .UserField('Author')
            .EqualToCurrentUser()
            .And()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        // load all times

        const hasRootPermissions =
            this.isManager || this.isTeamManagerForTeams.length;

        let timeItems = [];

        // get time data
        if (hasRootPermissions) {
            timeItems = await this._timeService.getAllTimes(null);
        } else {
            timeItems = await this._sharepointService.getListItems({
                title: 'Zeiten',
                isDocumentLibrary: false,
                recursiveAll: true,
                camlQuery: qryUser,
            });
        }

        // get all unique assignment ids from times
        const assignmentIds: number[] = timeItems.map((x) => x.ProjektId);
        const uniqueAssignmentIds: number[] = [...new Set(assignmentIds)];

        // load all neccessary assignments
        const assignmentItems: any[] = [];
        const chunkSize = 495;
        for (let i = 0; i < uniqueAssignmentIds.length; i += chunkSize) {
            const chunk = uniqueAssignmentIds
                .slice(i, i + chunkSize)
                .filter((elem) => elem);

            const assignmentItems_part =
                await this._sharepointService.getListItems({
                    title: 'Aufträge',
                    isDocumentLibrary: false,
                    camlQuery: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .In(chunk)
                        .ToString(),
                });

            assignmentItems.push(...assignmentItems_part);
        }

        // map the teams to the times
        for (const [index, timeItem] of timeItems.entries()) {
            const correspondingAssignmentItem = this.singleOrDefault<any>(
                assignmentItems.filter((x) => x.Id === timeItem.ProjektId)
            );

            timeItems[index]._Team = {
                Id: correspondingAssignmentItem?.TeamId
                    ? correspondingAssignmentItem.TeamId
                    : -1,
                Title: correspondingAssignmentItem?.FieldValuesAsText?.Team
                    ? correspondingAssignmentItem.FieldValuesAsText.Team
                    : '-- Team nicht vorhanden --',
            };
        }

        // we may need to filter elements
        // if (!this.isManager && this.isTeamManagerForTeams.length) {
        //   timeItems = timeItems.filter((timeItem) => {
        //     return timeItem.AuthorId === this._sharepointService.currentUser.Id || !!this.isTeamManagerForTeams.find(itmft => itmft.Id === timeItem._Team.Id)
        //   });
        // }

        // calculate the absolute time per team and year and month for each user
        this.gridData = [];
        for (const [index, timeItem] of timeItems.entries()) {
            const gridDataIndex = this.gridData.findIndex(
                (x) =>
                    x.employee.id === timeItem.AuthorId &&
                    x.team.id === timeItem._Team.Id &&
                    x.year === new Date(timeItem.Datum).getFullYear() &&
                    x.month === Months[new Date(timeItem.Datum).getMonth()]
            );
            if (gridDataIndex != -1) {
                this.gridData[gridDataIndex].totalTimeInHours +=
                    timeItem.Minuten / 60;
            } else {
                this.gridData.push({
                    employee: {
                        id: timeItem.AuthorId,
                        title: timeItem.FieldValuesAsText.Author,
                    },
                    team: {
                        id: timeItem._Team.Id,
                        title: timeItem._Team.Title,
                    },
                    totalTimeInHours: timeItem.Minuten / 60,
                    percentTime: -1,
                    year: new Date(timeItem.Datum).getFullYear(),
                    month: Months[new Date(timeItem.Datum).getMonth()],
                    fullDate: timeItem.Datum,
                });
            }
        }

        // create a object based on griddata grouped by year, month and employee
        const totalTimePerTeamMerged: any = this.gridData.reduce(
            (r, { year, month, employee, ...rest }) => {
                const key = `${year}-${month}-${employee.id}`;
                r[key] = r[key] || {
                    year,
                    month,
                    employee,
                    totalTimePerTeam: [],
                };
                r[key]['totalTimePerTeam'].push(rest);
                return r;
            },
            {}
        );
        const totalTimePerTeamTable: {
            year: number;
            month: string;
            employee: {
                id: number;
                title: string;
            };
            totalTimePerTeam: {
                team: {
                    id: number;
                    title: string;
                };
                totalTimeInHours: number;
            }[];
        }[] = Object.values(totalTimePerTeamMerged);

        this.gridData.sort(function (a, b) {
            return (
                a.employee.title.localeCompare(b.employee.title) ||
                b.year - a.year ||
                a.fullDate.getMonth() - b.fullDate.getMonth()
            );
        });

        // calculate the percentage of time per team and year and month for each employee
        totalTimePerTeamTable.forEach((x) => {
            // sum all totalTimeInHours
            const sumTotalTimesInMinutes = x.totalTimePerTeam.reduce(
                (accumulator, object) => {
                    return accumulator + object.totalTimeInHours;
                },
                0
            );

            // iterate over all totalTimePerTeam items
            x.totalTimePerTeam.forEach((y) => {
                // get the according gridData item
                const gridDataIndex = this.gridData.findIndex(
                    (z) =>
                        z.employee.id === x.employee.id &&
                        z.team.id === y.team.id &&
                        z.year === x.year &&
                        z.month === x.month
                );
                const gridItem = this.gridData[gridDataIndex];
                gridItem.plannedTimeInHours = DateUtil.getBusinessHours(
                    gridItem.fullDate.getFullYear(),
                    gridItem.fullDate.getMonth(),
                    this._timeService.dailyMitarbeiterHours[
                        gridItem.employee.id
                    ] ?? 8
                );

                const holidaysHours =
                    this._timeService.monthlyMitarbeiterHolidayHours[
                        gridItem.employee.id
                    ] &&
                    this._timeService.monthlyMitarbeiterHolidayHours[
                        gridItem.employee.id
                    ][DateUtil.getKeyDateToken(gridItem.fullDate)];

                if (holidaysHours) {
                    gridItem.plannedTimeInHours =
                        gridItem.plannedTimeInHours - holidaysHours;
                }

                // calculate the percentage
                let percent =
                    (gridItem.totalTimeInHours * 100) /
                    gridItem.plannedTimeInHours;

                if (!gridItem.plannedTimeInHours) {
                    percent = 0;
                }

                // patch the percentage
                gridItem.percentTime = Number.parseFloat(percent.toFixed(2));
                gridItem.plannedTimeInHours = toFixed(
                    gridItem.plannedTimeInHours,
                    2
                );
                gridItem.totalTimeInHours = toFixed(
                    gridItem.totalTimeInHours,
                    2
                );
            });
        });
    }

    private async loadTeamManagers() {
        const qryManager = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .UserField('Teamleiter')
            .Id()
            .IsNotNull()
            .ToString();

        const qryTeammanager = new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .UserField('Teamleiter')
            .Id()
            .IsNotNull()
            .And()
            .UserField('Teamleiter')
            .EqualToCurrentUser()
            .ToString();

        // load all team managers
        const teamItems: any[] = await this._sharepointService.getListItems({
            title: 'Teams',
            isDocumentLibrary: false,
            recursiveAll: true,
            camlQuery: this.isManager ? qryManager : qryTeammanager,
        });

        this.teamsWithManagers = teamItems.map((x) => {
            return {
                TeamleiterId: x.TeamleiterId,
                FieldValuesAsText: {
                    Teamleiter: x.FieldValuesAsText?.Teamleiter,
                },
                Title: x.Title,
                Id: x.Id,
            };
        });
        this.teamsWithManagers.unshift({
            TeamleiterId: -1,
            FieldValuesAsText: {
                Teamleiter: 'Alle Teamleiter',
            },
            Title: 'Alle Teams',
            Id: -1,
        });
    }

    private initTable() {
        const tableData = this.gridData.map((x) => {
            return {
                employeeId: x.employee.id,
                employeeTitle: x.employee.title,
                teamId: x.team.id,
                teamTitle: x.team.title,
                percentTime: x.percentTime.toLocaleString(),
                totalTimeInHours: x.totalTimeInHours.toLocaleString(),
                totalTimeInHoursNumber: x.totalTimeInHours,
                plannedTimeInHours: x.plannedTimeInHours.toLocaleString(),
                plannedTimeInHoursNumber: x.plannedTimeInHours,
                year: x.year,
                month: x.month,
                fullDate: x.fullDate,
            };
        });

        this.tableConfig = {
            columns: [
                {
                    internalName: 'employeeId',
                    title: 'Mitarbeiter Id',
                    hidden: true,
                },
                {
                    internalName: 'employeeTitle',
                    title: 'Mitarbeiter',
                    hidden: true,
                },
                { internalName: 'teamId', title: 'Team Id', hidden: true },
                { internalName: 'teamTitle', title: 'Team', hidden: false },
                {
                    internalName: 'percentTime',
                    title: '%-Aufteilung',
                    hidden: false,
                },
                {
                    internalName: 'totalTimeInHours',
                    title: 'Stunden',
                    hidden: false,
                },
                // { internalName: 'plannedTimeInHours', title: 'Target(Stunden)', hidden: false },
                { internalName: 'year', title: 'Jahr', hidden: false },
                { internalName: 'month', title: 'Monat', hidden: false },
                {
                    internalName: 'userGroup',
                    title: 'Mitarbeiter',
                    hidden: true,
                },
                { internalName: 'yearGroup', title: 'Monat', hidden: true },
            ],
        };

        this.state.take = 10;
        this.state.skip = 0; // Starts at page 1
        this.gridView = groupBy(tableData, this.groups);
    }

    public dataStateChange(state?: DataStateChangeEvent): void {
        const tableData = this.gridData.map((x) => {
            return {
                employeeId: x.employee.id,
                employeeTitle: x.employee.title,
                teamId: x.team.id,
                teamTitle: x.team.title,
                percentTime: x.percentTime.toLocaleString(),
                totalTimeInHours: x.totalTimeInHours.toLocaleString(),
                totalTimeInHoursNumber: x.totalTimeInHours,
                plannedTimeInHours: x.plannedTimeInHours.toLocaleString(),
                plannedTimeInHoursNumber: x.plannedTimeInHours,
                year: x.year,
                month: x.month,
                fullDate: x.fullDate,
            };
        });
        this.state = state;

        this.gridView = groupBy(tableData, this.groups);
    }

    public selectionChange(e) {}

    private getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    private singleOrDefault<Type>(array: Array<Type>): Type {
        return array.reduce(function (acc, cur, idx, src): any {
            if (src.length > 1) {
                throw 'More than one found';
            }
            return src[0];
        }, null);
    }

    public onFormioReady(ev) {}

    public onFormioChange(ev) {
        if (!ev.data) return;

        this.filterChangeEmitter.emit({
            year: ev.data.year,
            month: ev.data.months,
            employee: ev.data.employee,
            teammanager: ev.data.teammanager,
        });
    }

    public initFormio() {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EMPLOYEEANALYSIS_FILTER.YEAR'
                                    ),
                                    type: 'textfield',
                                    key: 'year',
                                    hidden: false,
                                    defaultValue: new Date().getFullYear(),
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EMPLOYEEANALYSIS_FILTER.MONTH'
                                    ),
                                    type: 'select',
                                    key: 'months',
                                    defaultValue: new Date().getMonth() + 1,
                                    data: {
                                        values: [
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.ALL'
                                                ),
                                                value: 0,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.1'
                                                ),
                                                value: 1,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.2'
                                                ),
                                                value: 2,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.3'
                                                ),
                                                value: 3,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.4'
                                                ),
                                                value: 4,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.5'
                                                ),
                                                value: 5,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.6'
                                                ),
                                                value: 6,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.7'
                                                ),
                                                value: 7,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.8'
                                                ),
                                                value: 8,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.9'
                                                ),
                                                value: 9,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.10'
                                                ),
                                                value: 10,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.11'
                                                ),
                                                value: 11,
                                            },
                                            {
                                                label: this._translateService.instant(
                                                    'EMPLOYEEANALYSIS_FILTER.MONTHS.12'
                                                ),
                                                value: 12,
                                            },
                                        ],
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EMPLOYEEANALYSIS_FILTER.EMPLOYEE'
                                    ),
                                    type: 'textfield',
                                    key: 'employee',
                                    hidden:
                                        !this.isTeamManagerForTeams.length &&
                                        !this.isManager,
                                },
                            ],
                            type: 'column',
                            key: 'column3',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'EMPLOYEEANALYSIS_FILTER.TEAMMANAGER'
                                    ),
                                    type: 'select',
                                    key: 'teammanager',
                                    data: {
                                        values: this.teamsWithManagers.map(
                                            (x) => {
                                                return {
                                                    label: `${x.FieldValuesAsText.Teamleiter} - ${x.Title}`,
                                                    value: x.Id,
                                                };
                                            }
                                        ),
                                    },
                                    hidden:
                                        !this.isTeamManagerForTeams.length &&
                                        !this.isManager,
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
            ],
        };
    }

    private subscribeFilterChange() {
        this.filterChangeEmitter
            .asObservable()
            .pipe(
                debounceTime(0),
                switchMap((value) => {
                    // if (value.length < 3) {
                    //   return of();
                    // }

                    return of(value);
                })
            )
            .subscribe(
                (data) => {
                    this.changeGroups(data.month, data.year);

                    const tableData = this.gridData
                        .map((x) => {
                            return {
                                employeeId: x.employee.id,
                                employeeTitle: x.employee.title,
                                teamId: x.team.id,
                                teamTitle: x.team.title,
                                percentTime: x.percentTime.toLocaleString(),
                                percentTimeNumber: x.percentTime,
                                totalTimeInHours:
                                    x.totalTimeInHours.toLocaleString(),
                                totalTimeInHoursNumber: x.totalTimeInHours,
                                plannedTimeInHours:
                                    x.plannedTimeInHours.toLocaleString(),
                                plannedTimeInHoursNumber: x.plannedTimeInHours,
                                year: x.year,
                                month: x.month,
                                fullDate: x.fullDate,
                            };
                        })
                        .filter((x) => {
                            return (
                                (data.year
                                    ? x.year === Number.parseInt(data.year)
                                    : true) &&
                                (data.month
                                    ? x.month === Months[data.month - 1]
                                    : true) &&
                                (data.employee
                                    ? x.employeeTitle
                                          .toLowerCase()
                                          .includes(data.employee.toLowerCase())
                                    : true) &&
                                ((data.teammanager && data.teammanager !== -1
                                    ? x.teamId === data.teammanager
                                    : true) ||
                                    (data.teammanager &&
                                        this._timeService.teamUsers[
                                            data.teammanager
                                        ] &&
                                        this._timeService.teamUsers[
                                            data.teammanager
                                        ][x.employeeId]) ||
                                    (data.teammanager &&
                                        this._timeService.teamsRecord[
                                            data.teammanager
                                        ]?.TeamleiterId === x.employeeId))
                            );
                        });
                    this.filteredGridData = tableData;
                    this.state = this.state;
                    this.gridView = groupBy(tableData, this.groups);

                    if (!data.year) {
                        this.gridView.forEach((group) => {
                            group.items.sort((a, b) => {
                                if (!b.items[0]?.year && !a.items[0]?.year) {
                                    return (
                                        b.items[0]?.items[0]?.year -
                                        a.items[0]?.items[0]?.year
                                    );
                                }

                                return b.items[0]?.year - a.items[0]?.year;
                            });
                        });
                    }

                    this.calculateGroupHeader(this.gridView);
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    public calculateGroupHeader(groups): void {
        groups.forEach((group) => {
            if (group.items[0].field === 'month') {
                group.items.sort(
                    (item1, item2) =>
                        item1.items[0]?.fullDate?.getMonth() -
                        item2.items[0]?.fullDate?.getMonth()
                );
            }

            if (group.field === this.groups[this.groups.length - 1].field) {
                let totalHours = 0;
                group.items.forEach(
                    (item) =>
                        (totalHours = totalHours + item.totalTimeInHoursNumber)
                );
                group.value = this.calculateGroupTitle(
                    toFixed(totalHours, 2),
                    group.items[0]
                );
            } else {
                this.calculateGroupHeader(group.items);
            }
        });
    }

    private calculateGroupTitle(totalTimeInHours, gridItem) {
        const hoursDifference =
            totalTimeInHours - gridItem.plannedTimeInHoursNumber;
        const isNegativeDifference = hoursDifference < 0;
        const className = isNegativeDifference ? 'red' : '#00bf00';
        const hoursDifferenceHTML = `<span style="color:${className};">${hoursDifference.toLocaleString()}</span>`;
        let title = gridItem.employeeTitle;
        const currentGroup = this.groups[this.groups.length - 1].field;

        if (currentGroup === 'month') {
            title = `${gridItem.month}`;
        } else if (currentGroup === 'year') {
            title = `${gridItem.year}`;
        }

        return this.sanitized.bypassSecurityTrustHtml(
            `${title} ${totalTimeInHours.toLocaleString()}/${
                gridItem.plannedTimeInHours
            }/${hoursDifferenceHTML}`
        );
    }

    public changeGroups(month, year): void {
        if (!month) {
            this.groups = [
                ...this.groups.filter((group) => group.field !== 'month'),
                { field: 'month' },
            ];
        } else {
            this.groups = this.groups.filter(
                (group) => group.field !== 'month'
            );
        }

        if (!year) {
            this.groups = this.groups.filter((group) => group.field !== 'year');
            this.groups.splice(1, 0, { field: 'year', dir: 'asc' });
        } else {
            this.groups = this.groups.filter((group) => group.field !== 'year');
        }
    }

    public onExcelExport(event: ExcelExportEvent): void {
        EmployeeanalysisUtils.generateExcel2ndPage(
            this.filteredGridData,
            event.workbook,
            this.usersFlatExcelTable.workbookOptions()
        );
        event.workbook.sheets = [event.workbook.sheets[1]];
    }
}

enum Months {
    Januar,
    Februar,
    März,
    April,
    Mai,
    Juni,
    Juli,
    August,
    September,
    Oktober,
    November,
    Dezember,
}
