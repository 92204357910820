import { IList } from '@pnp/sp/lists';
import {
    UpdateService,
    SharePointService,
    FieldType,
} from 'sp-office365-framework';

export class UpdateToVersion1118Component {
    constructor(
        private _updateService: UpdateService,
        private _sharePointService: SharePointService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            try {
                await this._updateService.addList('Antragsdokumente', '', 101);

                const documentsList: IList =
                    await this._sharePointService.web.lists.getByTitle(
                        'Antragsdokumente'
                    );

                await this._updateService.addField(
                    documentsList,
                    'AntragId',
                    FieldType.Number
                );
                resolve();
            } catch (err) {
                console.error(err);
                reject();
            }
        });
        return promise;
    }
}
