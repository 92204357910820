export const locale = {
    lang: 'en',
    data: {
        DETAIL_CUSTOMER: {
            EDIT: 'Edit customer',
            NEW: 'New customer',
            NOTIFICATIONS: {
                SAVING: 'Saving customer...',
                WAIT: 'Please wait.',
                SUCCESS: 'Customer saved successfully',
                ERROR: 'Error while saving: ',
            },
            FORM: {
                TITLE1: 'Companyname 1',
                TITLE2: 'Companyname 2',
                TITLE3: 'Companyname 3',
                STREET: 'Street/Number',
                ZIP: 'ZIP',
                CITY: 'City',
                LOCATION: 'Niederlassung',
                SAVE: 'Save',
                EMAIL: 'E-Mail',
                LAND: 'Land'
            },
            TABS: {
                BASE: 'Basedata',
                CONTACTS: 'Contacts',
            },
            CONTACTS: {
                NEW: 'New Contact',
                COLUMNS: {
                    TITLE: 'Name',
                    FIRSTNAME: 'Surname',
                    EMAIL: 'E-Mail',
                    JOBTITLE: 'Jobtitle',
                    WORKPHONE: 'Phone (Work)',
                    HOMEPHONE: 'Phone (Private)',
                    CELLPHONE: 'Phone (Mobile)',
                    WORKFAX: 'Fax',
                    WORKADRESS: 'Adress',
                    WEBPAGE: 'Homepage',
                    CUSTOMER: 'Company',
                    SAVE: 'Save',
                },
            },
        },
    },
};
