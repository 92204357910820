<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{'HOLIDAY_DETAILS.EDIT' | translate}}</span>
    <span *ngIf="!id" class="title dialog-title">{{'HOLIDAY_DETAILS.NEW' | translate}}</span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id="content">
  <formio class="formio-material pb-0"
              [refresh]="refreshForm"
              (ready)="formioReady()"
              [service]="this"
              [form]="formioConfiguration"
              [renderOptions]="formio_renderOptions">
  </formio>
</div>
