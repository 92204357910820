import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1104Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {

        let setPointsList : IList
        const teamsList = this._sharePointService.web.lists.getByTitle('Teams');

        const promise = new Promise<void>(async (resolve, reject) => {

            const promises = [

                await this._updateService.addList(
                    'SollZahlen',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),

                await new Promise<void>((resolve) => {
                    setPointsList = this._sharePointService.web.lists.getByTitle(
                        'SollZahlen'
                    );
                    resolve();
                }),

                await this._updateService.addField(
                    setPointsList,
                    'Monat',
                    FieldType.Number
                ),

                await this._updateService.addField(
                    setPointsList,
                    'Jahr',
                    FieldType.Number
                ),

                await this._updateService.addField(
                    setPointsList,
                    'Soll_Wert',
                    FieldType.Number
                ),

                await this._updateService.addLookupField(
                    setPointsList,
                    teamsList,
                    "Team",
                    false,
                    "Title"
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
