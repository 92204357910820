export const locale = {
    lang: 'en',
    data: {
        ADDFOLDERDIALOG: {
            ADDFOLDER: 'Add folder',
            OK: 'OK',
            CANCEL: 'Cancel',
            NAME: 'Name',
            MESSAGE: 'Please enter the name of teh new folder:',
        },
    },
};
