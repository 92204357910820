import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Inject,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import * as CamlBuilder from 'camljs';
import { DayPilotKanbanComponent } from 'daypilot-pro-angular';
import { SharePointService } from 'sp-office365-framework';
import { DetailTaskComponent } from '../task/detail-task/detail-task.component';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
} from 'sp-office365-layout';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogConfig,
} from '@angular/material/dialog';

@Component({
    selector: 'app-kanban',
    templateUrl: './kanban.component.html',
    styleUrls: ['./kanban.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class KanbanComponent implements OnInit {
    @ViewChild('kanbanBoard', { static: false })
    kanbanBoard: DayPilotKanbanComponent;

    @Input() auftragsId: number;
    @Input() workpackageId: number;

    public config: any;
    public isTask = true;
    public showBoard = false;
    public isPopUp = false;

    private elements = [];
    private processSteps = [];
    private taskTypes = [];
    private query: string;

    get camlQueryTasksInWorkpackage() {
        return new CamlBuilder()
            .Where()
            .LookupField('Arbeitspaket')
            .Id()
            .EqualTo(this.workpackageId)
            .OrderBy('Modified')
            .ToString();
    }

    get camlQueryWorkPackage() {
        return new CamlBuilder()
            .Where()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .OrderBy('Modified')
            .ToString();
    }

    get camlQueryProcessSteps() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Schritt')
            .ToString();
    }

    get camlQueryTaskTypes() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .ToString();
    }

    constructor(
        private sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<any>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data) {
            this.auftragsId = this.data.auftragsId;
            this.workpackageId = this.data.taskId;
            this.isPopUp = true;
        }

        if (this.auftragsId) {
            this.query = this.camlQueryWorkPackage;

            if (this.workpackageId) {
                this.query = this.camlQueryTasksInWorkpackage;
            }

            const promises = [];

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Auftragsaufgaben',
                            camlQuery: this.query,
                            isDocumentLibrary: false,
                            folderName: this.auftragsId + '',
                        })
                        .then((elements) => {
                            this.elements = elements;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Prozessschritte',
                            camlQuery: this.camlQueryProcessSteps,
                            isDocumentLibrary: false,
                        })
                        .then((processSteps) => {
                            this.processSteps = processSteps;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this.sharePointService
                        .getListItems({
                            title: 'Aufgabentypen',
                            camlQuery: this.camlQueryTaskTypes,
                            isDocumentLibrary: false,
                        })
                        .then((taskTypes) => {
                            this.taskTypes = taskTypes;

                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
            );

            Promise.all(promises)
                .then(() => this.initBoard())
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    openDialog(clickedElementId: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: this.auftragsId,
            isTask: this.isTask,
            taskId: clickedElementId,
            arbeitsPaketId: this.workpackageId,
        };

        if (!this.workpackageId) {
            this.dialogRef = this.dialog.open(KanbanComponent, dialogConfig);
            this.initAfterClose(this.dialogRef);
        } else {
            const dialogRefTask = this.dialog.open(
                DetailTaskComponent,
                dialogConfig
            );
            this.initAfterClose(dialogRefTask);
        }
    }

    initAfterClose(dialogRef) {
        dialogRef.afterClosed().subscribe((result) => {
            this.sharePointService
                .getListItems({
                    title: 'Auftragsaufgaben',
                    camlQuery: this.query,
                    isDocumentLibrary: false,
                    folderName: this.auftragsId + '',
                })
                .then((elements) => {
                    this.elements = elements;
                    this.initBoard();
                });
        });
    }

    initBoard() {
        const self = this;
        const taskList =
            this.sharePointService.web.lists.getByTitle('Auftragsaufgaben');

        // Colums
        const columns = [];

        this.processSteps.forEach((element) => {
            let taskStatus = 'Nicht begonnen';

            if (element.Aufgabenstatus != null) {
                taskStatus = element.Aufgabenstatus;
            }

            columns.push({
                name: element.Title,
                id: element.ID,
                taskStatus,
            });
        });

        // Cards
        const cards = [];

        this.elements.forEach((element) => {
            const body =
                '</br><p>' +
                this._translateService.instant('ASSIGNED') +
                ': ' +
                element.FieldValuesAsText.AssignedTo +
                '</p>' +
                '<p>' +
                this._translateService.instant('DUEDATE') +
                ': ' +
                element.FieldValuesAsText.DueDate +
                '</p>';

            const tasktype = self.taskTypes.filter(
                (x) => x.ID == element.AufgabentypId
            );

            let color = '#999';

            if (tasktype && tasktype.length > 0 && tasktype[0].Farbe) {
                color = tasktype[0].Farbe;
            }

            cards.push({
                name: element.Title,
                id: element.ID,
                text: body,
                barColor: color,
                column: element.ProzessschrittId,
            });
        });

        this.config = {
            columnWidthSpec: 'Auto',
            swimlaneMoveHandling: 'Disabled',
            cardDeleteHandling: 'Disabled',
            cardMoveHandling: 'Update',
            onCardMove(args) {
                taskList.items
                    .getById(args.card.data.id)
                    .update({
                        ProzessschrittId: args.column.data.id,
                        Status: args.column.data.taskStatus,
                    })
                    .then(() => {
                        this.message(
                            self._translateService.instant('TASKMOVED')
                        );
                    })
                    .catch((error) => {
                        this.message(
                            self._translateService.instant('ERRORONMOVINGTASK')
                        );
                    });
            },
            onCardClick(args) {
                self.openDialog(args.card.data.id);
            },
            columns,
            cards,
        };

        if (self.kanbanBoard && self.kanbanBoard.control) {
            self.kanbanBoard.control.update(this.config);
        }

        self.showBoard = true;
    }
}
