import { DetailProcessstepsComponent } from './../../processsteps/detail-processsteps/detail-processsteps.component';
import { DetailCategoryComponent } from './../../category/detail-category/detail-category.component';
import {
    AfterViewChecked,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import {
    ColumnTyp,
    FormatTypes,
    SharePointService,
    TableConfiguration,
    UpdateService,
} from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { ConfigurationService } from './configuration.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { DetailTasksetComponent } from '../../taskset/detail-taskset/detail-taskset.component';
import { DetailNotificationComponent } from '../../notification/detail-notification/detail-notification.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HolidayDetailsComponent } from 'src/app/main/holidays/holiday-details/holiday-details.component';
import { SelectLocationDialogComponent } from 'src/app/main/administration/configuration/select-location-dialog/select-location-dialog.component';
import * as ical from 'ical.js';
import { LoadingService } from 'src/app/main/services/loading.service';
import { DetailsWorkpackageSetComponent } from 'src/app/main/work-package-template/components/details-workpackage-template/details-workpackage-set.component';
import { DetailsWorkpackageSetTemplateComponent } from '../../work-package-template/components/details-workpackage-set-template/details-workpackage-set-template.component';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationComponent implements OnInit, AfterViewChecked {
    public configCategories: TableConfiguration;
    public configSalutations: TableConfiguration;
    public configProcesssteps: TableConfiguration;
    public configTasktypes: TableConfiguration;
    public configTaskSets: TableConfiguration;
    public configNotifications: TableConfiguration;
    public configProperties: TableConfiguration;
    public configTeams: TableConfiguration;
    public configFachbereiche: TableConfiguration;
    public configLocations: TableConfiguration;
    public configLands: TableConfiguration;
    public configCostCentres: TableConfiguration;
    public configCostRoles: TableConfiguration;
    public configEmployees: TableConfiguration;
    public configSignatures: TableConfiguration;
    public configPermissions: TableConfiguration;
    public configHolidays: TableConfiguration;
    public workPackages: TableConfiguration;
    public workPackageTemplates: TableConfiguration;
    public netWorkDrives: TableConfiguration;

    public hasSelectedItems;
    public config: any;
    public currentTable: any;
    public currentConfig: TableConfiguration;
    private _unsubscribeAll: Subject<any>;
    private searchGroups: string;
    private checkBoxClick = false;
    private firstTableLoaded = false;
    private selectedLocationId: number;

    @ViewChildren('checkBoxes') checkBoxes;
    @ViewChildren('tabsTables') tabsTables;
    @ViewChildren('tabsEdits') tabsEdits;
    @ViewChild('inputFile', { static: false }) inputFile: ElementRef;

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _configurationService: ConfigurationService,
        private _updateService: UpdateService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _snackBarService: SnackbarService,
        private _ngZone: NgZone
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        const promises = [];

        promises.push(
            new Promise<void>((resolve, reject) => {
                this._updateService
                    .getPropertyValue('Usergruppen')
                    .then((value) => {
                        this.searchGroups = value;

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );

        this._configurationService.onSelectedItemsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((selectedItems) => {
                this.hasSelectedItems = selectedItems.length > 0;
            });

        this._configurationService.onItemsDeleted
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((deletedItems) => {
                this.hasSelectedItems = false;

                if (this.checkBoxes) {
                    this.checkBoxes.forEach((checkBox) => {
                        if (checkBox._checked) {
                            checkBox._checked = false;
                        }
                    });
                }
            });

        Promise.all(promises)
            .then(() => {
                this.initGrids();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    ngAfterViewChecked(): void {
        if (
            this.tabsTables._results[0] &&
            this.config.tabs[0] &&
            !this.firstTableLoaded
        ) {
            this.currentTable = this.tabsTables._results[0];
            this.currentConfig = this.config.tabs[0].tableConfig;
            this.firstTableLoaded = true;
        }
    }

    get camlPermissionQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Title')
            .ToString();
    }

    get camlQuery() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderByDesc('Modified')
            .ToString();
    }

    get camlQueryProcessSteps() {
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Schritt')
            .ToString();
    }

    onTabChange(e) {
        this.currentTable = this.tabsTables._results[e.index];
        this.currentConfig = this.config.tabs[e.index].tableConfig;

        this._configurationService.deselectItems();

        this.checkBoxes.forEach((checkBox) => {
            if (checkBox._checked) {
                checkBox._checked = false;
            }
        });
    }

    checkBoxChange(e, item) {
        this.checkBoxClick = true;
        this._configurationService.toggleSelectedItem(item.ID);
    }

    addItem(tab) {
        switch (this.tabsTables._results[tab]._configuration.list) {
            case 'Aufgabensets':
                this.openDialog(tab, DetailTasksetComponent, {});
                return;
            case 'ArbeitspaketesetsTemplate':
                this.openDialog(tab, DetailsWorkpackageSetTemplateComponent, {});
                return;
            case 'Arbeitspaketesets':
                this.openDialog(tab, DetailsWorkpackageSetComponent, {});
                return;
            case 'Notifications':
                this.openDialog(tab, DetailNotificationComponent, {
                    notificationId: tab,
                });
                return;
            case 'Auftragskategorien':
                this.openDialog(tab, DetailCategoryComponent, {
                    categoryId: tab,
                });
                return;
            case 'Prozessschritte':
                this.openDialog(tab, DetailProcessstepsComponent, {
                    Id: null,
                });
                return;
            case 'Feiertage':
                this.openDialog(tab, HolidayDetailsComponent, {
                    Id: null,
                });
                return;
        }

        this.tabsEdits._results[tab].show();
    }

    editItem(e, tab) {
        if (this.checkBoxClick) {
            this.checkBoxClick = false;
        } else {
            switch (this.tabsTables._results[tab]._configuration.list) {
                case 'Aufgabensets':
                    this.openDialog(tab, DetailTasksetComponent, {
                        tasksetId: e.Id,
                    });
                    return;
                case 'ArbeitspaketesetsTemplate':
                    this._ngZone.runOutsideAngular(() => {
                        this.openDialog(tab, DetailsWorkpackageSetTemplateComponent, {
                            id: e.Id,
                        });
                    });
                    return;
                case 'Arbeitspaketesets':
                    this._ngZone.runOutsideAngular(() => {
                        this.openDialog(tab, DetailsWorkpackageSetComponent, {
                            id: e.Id,
                        });
                    });
                    return;
                case 'Notifications':
                    this.openDialog(tab, DetailNotificationComponent, {
                        notificationId: e.Id,
                    });
                    return;
                case 'Auftragskategorien':
                    this.openDialog(tab, DetailCategoryComponent, {
                        categoryId: e.Id,
                    });
                    return;
                case 'Prozessschritte':
                    this.openDialog(tab, DetailProcessstepsComponent, {
                        Id: e.Id,
                    });
                    return;
                case 'Feiertage':
                    this.openDialog(tab, HolidayDetailsComponent, {
                        Id: e.Id,
                    });
                    return;
            }

            this.tabsEdits._results[tab].show(e.Id);
        }
    }

    openDialog(tab, detailComponent, data): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = data;

        const dialogRef = this.dialog.open(detailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.tabsTables._results[tab].refresh();
            }
            this.tabsTables._results[tab].refresh();
        });
    }

    onClose(tab) {
        this.tabsTables._results[tab].refresh();
    }

    initGrids() {
        this.configCategories = {
            list: 'Auftragskategorien',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.CATEGORIES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
        };

        this.configSignatures = {
            list: 'Signaturen',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.SIGNATURES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SIGNATURES.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SIGNATURES.COLUMNS.TEXT'
                        ),
                        type: 'textarea',
                        key: 'Text',
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configSalutations = {
            list: 'Anreden',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.SALUTATIONS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SALUTATIONS.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configCostRoles = {
            list: 'Kostenrollen',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.COSTROLES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Abbreviation',
                    title: this._translateService.instant(
                        'CONFIGURATION.COSTROLES.COLUMNS.ABBREVIATION'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.COSTROLES.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.COSTROLES.COLUMNS.ABBREVIATION'
                        ),
                        type: 'textfield',
                        key: 'Abbreviation',
                        /*validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },*/
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.COSTROLES.COLUMNS.COSTSPERHOUR'
                        ),
                        description: this._translateService.instant(
                            'CONFIGURATION.COSTROLES.COLUMNS.INEURO'
                        ),
                        type: 'number',
                        key: 'Stundensatz',
                        decimalLimit: 2,
                        requireDecimal: true,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configEmployees = {
            list: 'Mitarbeiter',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'User',
                    title: this._translateService.instant(
                        'CONFIGURATION.EMPLOYEES.COLUMNS.USER'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'Kostenrolle',
                    title: this._translateService.instant(
                        'CONFIGURATION.EMPLOYEES.COLUMNS.COSTROLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.EMPLOYEES.COLUMNS.USER'
                        ),
                        type: 'sharePointAutocomplete',
                        key: 'UserId',
                        selectThreshold: 0.3,
                        isPeoplePicker: true,
                        removable: true,
                        searchGroups: this.searchGroups,
                        // isCreateMode: this.isCreateMode,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.EMPLOYEES.COLUMNS.HOURSPERDAY'
                        ),
                        description: this._translateService.instant(
                            'CONFIGURATION.EMPLOYEES.COLUMNS.INHOURS'
                        ),
                        type: 'number',
                        key: 'Tagessoll',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.EMPLOYEES.COLUMNS.COSTROLE'
                        ),
                        type: 'sharePointAutocomplete',
                        key: 'KostenrolleId',
                        selectThreshold: 0.3,
                        list: 'Kostenrollen',
                        searchQueryIfIsEmpty: new CamlBuilder()
                            .Where()
                            .NumberField('ID')
                            .NotEqualTo(-1)
                            .OrderBy('Title')
                            .ToString(),
                        isPeoplePicker: false,
                        removable: true,
                        // isCreateMode: this.isCreateMode,
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configProcesssteps = {
            list: 'Prozessschritte',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Schritt',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.STEP'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Aufgabenstatus',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.STATUS'
                    ),
                    hidden: false,
                },
                {
                    internalName: 'Standardwert',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.DEFAULTVALUE.TITLE'
                    ),
                    type: ColumnTyp.Choice,
                    hidden: false,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQueryProcessSteps,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROCESSSTEPS.COLUMNS.TITLE'
                        ),
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROCESSSTEPS.COLUMNS.STEP'
                        ),
                        mask: false,
                        tableView: true,
                        alwaysEnabled: false,
                        type: 'textfield',
                        input: true,
                        key: 'Schritt',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        delimiter: false,
                        requireDecimal: false,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROCESSSTEPS.COLUMNS.DEFAULTVALUE.TITLE'
                        ),
                        type: 'select',
                        input: true,
                        key: 'Standardwert',
                        defaultValue: 'Nein',
                        data: {
                            values: [
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.DEFAULTVALUE.YES'
                                    ),
                                    value: 'Ja',
                                },
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.DEFAULTVALUE.NO'
                                    ),
                                    value: 'Nein',
                                },
                            ],
                        },
                        valueProperty: 'value',
                        selectThreshold: 0.3,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROCESSSTEPS.COLUMNS.STATUS'
                        ),
                        type: 'select',
                        input: true,
                        key: 'Aufgabenstatus',
                        defaultValue: 'Nicht begonnen',
                        data: {
                            values: [
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.NOTSTARTED'
                                    ),
                                    value: 'Nicht begonnen',
                                },
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.INPROCESSING'
                                    ),
                                    value: 'In Bearbeitung',
                                },
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.COMPLETED'
                                    ),
                                    value: 'Abgeschlossen',
                                },
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.RESET'
                                    ),
                                    value: 'Zurückgestellt',
                                },
                                {
                                    label: this._translateService.instant(
                                        'CONFIGURATION.PROCESSSTEPS.COLUMNS.WAITING'
                                    ),
                                    value: 'Wartet auf jemand anderen',
                                },
                            ],
                        },
                        valueProperty: 'value',
                        selectThreshold: 0.3,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configTasktypes = {
            list: 'Aufgabentypen',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.TASKTYPES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                },
                {
                    internalName: 'Farbe',
                    title: this._translateService.instant(
                        'CONFIGURATION.TASKTYPES.COLUMNS.COLOR'
                    ),
                    hidden: false,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            isDocumentLibrary: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TASKTYPES.COLUMNS.TITLE'
                        ),
                        hideLabel: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TASKTYPES.COLUMNS.COLOR'
                        ),
                        allowMultipleMasks: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Farbe',
                        defaultValue: '',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configTaskSets = {
            list: 'Aufgabensets',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.TASKSET.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
        };

        this.configNotifications = {
            list: 'Notifications',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.NOTIFICATIONS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Intervall',
                    title: this._translateService.instant(
                        'CONFIGURATION.NOTIFICATIONS.COLUMNS.INTERVALL'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
        };

        this.configTeams = {
            list: 'Teams',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.TEAMS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Gruppe',
                    title: this._translateService.instant(
                        'CONFIGURATION.TEAMS.COLUMNS.GROUP'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'Standort',
                    title: this._translateService.instant(
                        'CONFIGURATION.TEAMS.COLUMNS.LOCATION'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'Teamleiter',
                    title: this._translateService.instant(
                        'CONFIGURATION.TEAMS.COLUMNS.TEAMMANAGER'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'AvailableForCalculation',
                    title: this._translateService.instant(
                        'CONFIGURATION.TEAMS.COLUMNS.AVAILABLEFORCALCULATION'
                    ),
                    hidden: false,
                    type: ColumnTyp.Boolean,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TEAMS.COLUMNS.TITLE'
                        ),
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TEAMS.COLUMNS.GROUP'
                        ),
                        type: 'sharePointAutocomplete',
                        input: true,
                        key: 'GruppeId',
                        selectThreshold: 0.3,
                        isPeoplePicker: true,
                        removable: true,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TEAMS.COLUMNS.LOCATION'
                        ),
                        type: 'sharePointAutocomplete',
                        input: true,
                        key: 'StandortId',
                        list: 'Standorte',
                        searchQueryIfIsEmpty: new CamlBuilder()
                            .Where()
                            .NumberField('ID')
                            .NotEqualTo(-1)
                            .OrderBy('Title')
                            .ToString(),
                        removable: true,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TEAMS.COLUMNS.TEAMMANAGER'
                        ),
                        type: 'sharePointAutocomplete',
                        input: true,
                        key: 'TeamleiterId',
                        selectThreshold: 0.3,
                        isPeoplePicker: true,
                        removable: true,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.TEAMS.COLUMNS.AVAILABLEFORCALCULATION'
                        ),
                        type: 'checkbox',
                        key: 'AvailableForCalculation',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        mask: false,
                        tableView: true,
                        alwaysEnabled: false,
                        type: 'button',
                        key: 'submit',
                        input: true,
                        defaultValue: false,
                        showValidations: true,
                    },
                ],
            },
        };

        this.configFachbereiche = {
            list: 'Fachbereiche',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.FACHBEREICHE.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Bereichsnummer',
                    title: this._translateService.instant(
                        'CONFIGURATION.FACHBEREICHE.COLUMNS.NUMBER'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.FACHBEREICHE.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.FACHBEREICHE.COLUMNS.NUMBER'
                        ),
                        type: 'textfield',
                        key: 'Bereichsnummer',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        type: 'button',
                        key: 'submit',
                        showValidations: true,
                    },
                ],
            },
        };

        this.configLocations = {
            list: 'Standorte',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.LOCATIONS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Fileserver',
                    title: this._translateService.instant(
                        'CONFIGURATION.LOCATIONS.COLUMNS.FILESERVER'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.LOCATIONS.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.LOCATIONS.COLUMNS.FILESERVER'
                        ),
                        type: 'textfield',
                        key: 'Fileserver',
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        type: 'button',
                        key: 'submit',
                        showValidations: true,
                    },
                ],
            },
        };

        this.configLands = {
            list: 'Länder',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.LANDS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.LANDS.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        type: 'button',
                        key: 'submit',
                        showValidations: true,
                    },
                ],
            },
        };

        this.configCostCentres = {
            list: 'Kostenstellen',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.COSTCENTRES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.COSTCENTRES.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        type: 'button',
                        key: 'submit',
                        showValidations: true,
                    },
                ],
            },
        };

        this.configPermissions = {
            list: 'Berechtigungsgruppen',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.PERMISSIONS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Berechtigungsgruppe',
                    title: this._translateService.instant(
                        'CONFIGURATION.PERMISSIONS.COLUMNS.VALUE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlPermissionQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PERMISSIONS.COLUMNS.TITLE'
                        ),
                        hideLabel: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        inputFormat: 'plain',
                        disabled: true,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PERMISSIONS.COLUMNS.VALUE'
                        ),
                        multi: true,
                        hideLabel: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'sharePointAutocomplete',
                        isPeoplePicker: true,
                        removable: true,
                        input: true,
                        key: 'BerechtigungsgruppeId',
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.netWorkDrives = {
            list: 'Netzlaufwerkunterordner',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.NETWORKDRIVES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.NETWORKDRIVES.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        theme: 'accent',
                        disableOnInvalid: false,
                        type: 'button',
                        key: 'submit',
                        showValidations: true,
                    },
                ],
            },
        };

        this.configProperties = {
            list: 'Properties',
            pageSize: 10,
            columns: [
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROPERTIES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Value',
                    title: this._translateService.instant(
                        'CONFIGURATION.PROPERTIES.COLUMNS.VALUE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROPERTIES.COLUMNS.TITLE'
                        ),
                        hideLabel: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                        disabled: true,
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.PROPERTIES.COLUMNS.VALUE'
                        ),
                        hideLabel: false,
                        showWordCount: false,
                        showCharCount: false,
                        tableView: true,
                        type: 'textfield',
                        input: true,
                        key: 'Value',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    },
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SAVE'
                        ),
                        size: 'small',
                        type: 'button',
                        key: 'submit',
                        action: 'submit',
                    },
                ],
            },
        };

        this.configHolidays = {
            list: 'Feiertage',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Date',
                    title: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.COLUMNS.DATE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Date,
                    format: FormatTypes.yyyyMMdd,
                },
                {
                    internalName: 'Standort',
                    title: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.COLUMNS.LOCATION'
                    ),
                    hidden: false,
                    type: ColumnTyp.Lookup,
                },
                {
                    internalName: 'Bundesland',
                    title: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.COLUMNS.BUNDESLAND'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Faktor',
                    title: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.COLUMNS.FAKTOR'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                {
                    internalName: 'Year',
                    title: 'Year',
                    hidden: true,
                },
                {
                    internalName: 'Month',
                    title: 'Month',
                    hidden: true,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
        };

        this.workPackages = {
            list: 'Arbeitspaketesets',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.WORK_PACKAGE_TEMPLATES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
        };

        this.workPackageTemplates = {
            list: 'ArbeitspaketesetsTemplate',
            columns: [
                {
                    internalName: 'Auswahl',
                    title: '',
                    hidden: false,
                    type: ColumnTyp.Text,
                    width: '80',
                },
                {
                    internalName: 'Title',
                    title: this._translateService.instant(
                        'CONFIGURATION.WORK_PACKAGE_TEMPLATES.COLUMNS.TITLE'
                    ),
                    hidden: false,
                    type: ColumnTyp.Text,
                },
                { internalName: 'ID', title: 'ID', hidden: true },
            ],
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            addCheckboxColumn: false,
            recursiveAll: true,
            camlQuery: this.camlQuery,
            formConfig: {
                useMaterialDesign: true,
                components: [
                    {
                        label: this._translateService.instant(
                            'CONFIGURATION.SIGNATURES.COLUMNS.TITLE'
                        ),
                        type: 'textfield',
                        key: 'Title',
                        validate: {
                            customMessage: '{{field}} ist ein Pflichtfeld',
                            required: true,
                        },
                        inputFormat: 'plain',
                    }
                ],
            },
        }

        this.config = {
            tabs: [
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.CATEGORIES.TITLE'
                    ),
                    tableConfig: this.configCategories,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.SIGNATURES.TITLE'
                    ),
                    tableConfig: this.configSignatures,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.SALUTATIONS.TITLE'
                    ),
                    tableConfig: this.configSalutations,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.COSTROLES.TITLE'
                    ),
                    tableConfig: this.configCostRoles,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.EMPLOYEES.TITLE'
                    ),
                    tableConfig: this.configEmployees,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.PROCESSSTEPS.TITLE'
                    ),
                    tableConfig: this.configProcesssteps,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.TASKTYPES.TITLE'
                    ),
                    tableConfig: this.configTasktypes,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.WORK_PACKAGE_TEMPLATES.TITLE'
                    ),
                    tableConfig: this.workPackageTemplates,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.WORK_PACKAGES.TITLE'
                    ),
                    tableConfig: this.workPackages,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.TASKSET.TITLE'
                    ),
                    tableConfig: this.configTaskSets,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.TEAMS.TITLE'
                    ),
                    tableConfig: this.configTeams,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.FACHBEREICHE.TITLE'
                    ),
                    tableConfig: this.configFachbereiche,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.LOCATIONS.TITLE'
                    ),
                    tableConfig: this.configLocations,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.LANDS.TITLE'
                    ),
                    tableConfig: this.configLands,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.HOLIDAYS.TITLE'
                    ),
                    tableConfig: this.configHolidays,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.COSTCENTRES.TITLE'
                    ),
                    tableConfig: this.configCostCentres,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.NOTIFICATIONS.TITLE'
                    ),
                    tableConfig: this.configNotifications,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.PERMISSIONS.TITLE'
                    ),
                    tableConfig: this.configPermissions,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.NETWORKDRIVES.TITLE'
                    ),
                    tableConfig: this.netWorkDrives,
                },
                {
                    label: this._translateService.instant(
                        'CONFIGURATION.PROPERTIES.TITLE'
                    ),
                    tableConfig: this.configProperties,
                },
            ],
        };
    }

    public openLocationDialog(): void {
        this.dialog
            .open(SelectLocationDialogComponent, { minWidth: 500 })
            .afterClosed()
            .subscribe((locationId) => {
                if (!locationId) {
                    return;
                }

                this.selectedLocationId = locationId;
                this.inputFile.nativeElement.click();
            });
    }

    public importICS(event, tabIndex: number): void {
        const htmlFileInput = event.target as HTMLInputElement;
        const file = htmlFileInput.files[0];

        const fileReader = new FileReader();
        fileReader.onload = async (event) => {
            const data = ical.parse((event.currentTarget as any).result)[2];
            const items = data
                .filter((item) => item[0] === 'vevent')
                .map((item) => item[1]);

            const events = [];
            items.forEach((item) => {
                const icalData = item;
                let title: string;
                let description: string;
                let startDate: Date;
                let endDate: Date;
                let location: string;

                icalData.forEach((item) => {
                    if (item[0] === 'summary') {
                        title = item[3];
                    }

                    if (item[0] === 'description') {
                        description = item[3];
                    }

                    if (item[0] === 'dtstart') {
                        startDate = new Date(item[3]);
                    }

                    if (item[0] === 'dtend') {
                        endDate = item[3];
                    }

                    if (item[0] === 'location') {
                        location = item[3];
                    }
                });

                events.push({
                    Title: title,
                    Date: startDate,
                    Month: startDate.getMonth() + 1,
                    Year: startDate.getFullYear(),
                    Bundesland: location,
                    StandortId: this.selectedLocationId,
                    Faktor: 1,
                });
            });

            const [batchedWeb, execute] = this._sharepointService.web.batched();
            const res = [];
            const list = batchedWeb.lists.getByTitle('Feiertage');

            events.forEach(item => {
                list.items.add(item).then(response => res.push(response));
            });

            // const list =
            //     this._sharepointService.web.lists.getByTitle('Feiertage');
            // const batch = this._sharepointService.web.createBatch();
            // const batchItems = list.items.inBatch(batch);
            // events.forEach((item) => {
            //     batchItems.add(item);
            // });

            this._loadingService.show(
                this._translateService.instant(
                    'CONFIGURATION.NOTIFICATIONS.WAIT'
                ),
                ''
            );

            execute()
                .then(() => {
                    htmlFileInput.value = '';
                    this._loadingService.hide();
                    this._snackBarService.add(
                        this._translateService.instant(
                            'CONFIGURATION.HOLIDAYS.SUCCESS'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );
                    this.tabsTables._results[tabIndex].refresh();
                })
                .catch((err) => {
                    console.error(err);
                    this._snackBarService.add(
                        this._translateService.instant(
                            'CONFIGURATION.NOTIFICATIONS.ERROR'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );
                });
        };
        fileReader.readAsText(file);
    }
}
