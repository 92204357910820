export const locale = {
    lang: 'en',
    data: {
        DELETEDIALOG: {
            CONFIRM: 'Confirm',
            OK: 'OK',
            CANCEL: 'Cancel',
        },
    },
};
