<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "DETAIL_CATEGORY.EDIT" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "DETAIL_CATEGORY.NEW" | translate }}</span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">

    <mat-tab label='{{ "DETAIL_CATEGORY.BASE" | translate }}'>

      <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
        [renderOptions]="formio_renderOptions"
        (change)="onChange($event)">
      </formio>

    </mat-tab>

    <mat-tab label='{{ "DETAIL_CATEGORY.DOCUMENTS" | translate }}' *ngIf="this.id">

      <input type="file" style="display: none;" [multiple]="true"
        [accept]="this._documentService.acceptedFileExtensions"
        (change)="this._documentService.fileChangeEvent($event, this.documentGrid, this.tableConfigDocs.list, { KategorieId: this.id })"
        #fileInput />

      <app-sharepoint-table #documentGrid *ngIf="tableConfigDocs" [configuration]="tableConfigDocs"
        (onTooolbarButtonClick)="onTooolbarButtonClick($event)">
      </app-sharepoint-table>

    </mat-tab>

  </mat-tab-group>

</div>
