import { DetailGroupModule } from './../detail-group/detail-group.module';
import { DetailGroupComponent } from './../detail-group/detail-group.component';
import { NgModule } from '@angular/core';
import { ListviewGroupComponent } from './listview-group.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';

const routes = [
    {
        path: 'administration/groups',
        component: ListviewGroupComponent,
    },
];

@NgModule({
    declarations: [ListviewGroupComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        DetailGroupModule,
    ],
    exports: [ListviewGroupComponent]
})
export class ListviewGroupModule {}
