export const locale = {
    lang: 'de',
    data: {
        FILTER_TIME: {
            COLUMNS: {
                LOCATION: 'Standort',
                TEAM: 'Team',
                HOTBUTTONS: 'Zeitrahmen wählen',
                THISYEAR: 'Dieses Jahr',
                THISQAURTAL: 'Dieses Quartal',
                LAST30: 'Letzte 30 Tage',
                THISMONTH: 'Diesen Monat',
                LASTMONTH: 'Letzter Monat',
                THISWEEK: 'Diese Woche',
                TODAY: 'Heute',
                START: 'Von',
                END: 'Bis',
                PROJECT: 'Projekt',
                USER: 'Person',
            },
        },
    },
};
