import {
    SharePointService,
    UpdateService,
    FieldType,
} from 'sp-office365-framework';
import { ApplicationService } from 'src/app/main/services/application.service';
import { IList } from '@pnp/sp/presets/all';
import * as CamlBuilder from 'camljs';

export class UpdateToVersion1062Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService,
        private _applicationService: ApplicationService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList = this._sharePointService.web.lists.getByTitle(
                'Aufträge'
            );
            let commentList: IList;

            const promises = [
                // Add List Projektkommentare
                await this._updateService.addList(
                    'Projektkommentare',
                    '',
                    100,
                    {
                        EnableVersioning: true,
                    }
                ),
                // Get List Projektkommentare
                await new Promise<void>((resolve) => {
                    commentList = this._sharePointService.web.lists.getByTitle(
                        'Projektkommentare'
                    );
                    resolve();
                }),
                // Add Field Projekt to List Projektkommentare
                await this._updateService.addLookupField(
                    commentList,
                    projectList,
                    'Projekt'
                ),
                // Add Field Body to List Kommentare
                await this._updateService.addField(
                    commentList,
                    'Body',
                    FieldType.MultilineRichText
                ),
                // Add folders for existing projects in List Projektkommentare
                // Set permissions to new folders in List Projektkommentare
                await this._applicationService.addFoldersForExistingProjects(
                    'Projektkommentare',
                    false
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
