import { DetailContactModule } from './../../contact/detail-contact/detail-contact.module';
import { DetailContactComponent } from './../../contact/detail-contact/detail-contact.component';
import { NgModule } from '@angular/core';
import { DetailCustomerComponent } from './detail-customer.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [DetailCustomerComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        MatIconModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
        DetailContactModule,
        DragDropModule,
    ],
    exports: [DetailCustomerComponent]
})
export class DetailCustomerModule {}
