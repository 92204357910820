<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0" *ngIf="id">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">{{
      'NEW_TIME.UPDATE' | translate
      }}</span>

    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="booked" *ngIf="!sharePointItem">
  {{ 'NEW_TIME.BOOKEDTIMESFORSELECTEDDATE1' | translate }}

  <div class="time">
    {{ (this.timeService.sumOfMinutesForSelectedDate / 60).toFixed(2) }}
  </div>

  <ng-container *ngIf="this.timeService.currentUserProfile">
    {{ 'NEW_TIME.BOOKEDTIMESFORSELECTEDDATE3' | translate }}

    <div class="time">
      <span *ngIf="!(timeService.maximumUserHoursPerDayLoading$ | async);else maximumUserHoursPerDayLoader">
        {{ timeService.maximumUserHoursPerDay$ | async }}
      </span>
      <ng-template #maximumUserHoursPerDayLoader>
        <mat-spinner diameter="20"></mat-spinner>
      </ng-template>
    </div>
  </ng-container>

  {{ 'NEW_TIME.BOOKEDTIMESFORSELECTEDDATE2' | translate }}
</div>

<ng-container *ngIf="this.showNewTimeForm">
  <div [ngClass]="{ noHeader: sharePointItem }">
    <formio #formioComponent class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this"
      [renderOptions]="formio_renderOptions"
      [form]="formioConfiguration" (change)="onChange($event)">
    </formio>
  </div>
</ng-container>

<ng-container *ngIf="!this.showNewTimeForm">
  <div class="info">
    {{ 'NEW_TIME.PROJECTSTATE' | translate }}
  </div>
</ng-container>
