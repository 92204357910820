export const locale = {
    lang: 'en',
    data: {
        DETAIL_GROUP: {
            TITLE: 'Document templates',
            NEW: 'Upload template',
            NOTIFICATIONS: {
                SAVING: 'Creating template...',
                WAIT: 'Please wait.',
                SUCCESS: 'Template saved successfully',
                SUCCESS_DOC: 'Document created successfully.',
                ERROR: 'Error while saving: ',
                NO_TEMPLATE_SELECTED: 'No template selected.',
                NO_ELEMENT_ID: 'No element ID specified.',
                COPY_SUCCESS: 'Content has been copied to the clipboard.',
                SUCCESS_DELETE: 'Template deleted successfully.',
                CHANGE_CATEGORY: 'Category is being changed.',
                SUCCESS_CHANGE_CATEGORY: 'Category changed successfully.',
            },
            COLUMNS: {
                TITLE: 'Name',
                FILESIZE: 'File size',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
                CATEGORY: 'Category',
            },
        },
    },
};
