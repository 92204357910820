export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        INVOLVEMENT: 'Involvement',
        TASKS: 'Tasks',
        KANBAN: 'Kanban',
        NEW: 'Add new involved',
        EDIT: 'Eidt involved',
        COLUMNS: {
            NAME: 'Names',
            KIND: 'Kind',
            INTERN: 'Intern',
            EXTERN: 'Extern',
            PARTICIPATION: 'Participation',
            INPERCENT: '(in %)',
            START: 'Start',
            END: 'End',
            USER: 'User (AD)',
            CONTACT: 'Contact',
            PROJECT: 'Project',
            SAVE: 'Save',
        },
        TABS: {
            SCHEDULER: 'Scheduler for this involved in all projects',
            BASE: 'Basedata',
        },
        NOTIFICATIONS: {
            SAVING: 'Saving involved...',
            WAIT: 'Please wait.',
            SUCCESS: 'Involved saved successfully',
            ERROR: 'Error while saving: ',
        },
    },
};
