import {
    Component,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
} from '@angular/core';
import * as CamlBuilder from 'camljs';
import { ActivatedRoute } from '@angular/router';
import { SharePointService, UpdateService } from 'sp-office365-framework';
import { ApplicationService } from '../../services/application.service';
import {
    FuseTranslationLoaderService,
    fuseAnimations,
    CommentService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { SnackbarService } from '../../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-comments-assignment',
    templateUrl: './comments-assignment.component.html',
    styleUrls: ['./comments-assignment.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CommentsAssignmentComponent implements OnInit {
    public id: number;
    public itemTitle = '';
    public header;
    public selectedComment: any;
    public selectedCommentId: number;
    public config: any;
    public enableEdit = false;
    public enableDelete = false;

    @ViewChild('messageBody', { static: false })
    messageBody: ElementRef;

    private items = [];
    private sharePointItem;

    get camlQuery() {
        if (this.id) {
            // Return all comments from this project
            return new CamlBuilder()
                .Where()
                .LookupField('Projekt')
                .Id()
                .EqualTo(this.id)
                .OrderByDesc('Created')
                .ToString();
        } else {
            // Return no data
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .ToString();
        }
    }

    constructor(
        public dialog: MatDialog,
        public _applicationService: ApplicationService,
        private route: ActivatedRoute,
        private _sharepointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _commentService: CommentService,
        private _snackBarService: SnackbarService,
        private _updateService: UpdateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        // Eventlistener
        this._commentService.sideBarOpenedChange.subscribe((result) => {
            // On SideBar closed
            if (!result) {
                this.selectedComment = null;
                this.selectedCommentId = -1;
            }
        });

        this._commentService.onCommentSelected.subscribe((commentId) => {
            // On comment selected
            if (commentId) {
                this.selectedCommentId = commentId;
                this.loadSelectedComment();
            }
        });

        this.header = this._translateService.instant(
            'COMMENTS_ASSIGNMENT.COMMENTS'
        );
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.id = +params.id;

            // Get Sidebarconfig if projectId Changed
            if (this.id != this._applicationService.currentProjectId) {
                this._applicationService.getSideBarConfig(this.id);
            }

            const promises = [];

            if (this.id) {
                promises.push(
                    new Promise<void>((resolve, reject) => {
                        this._sharepointService
                            .getItemById({ listTitle: 'Aufträge', id: this.id })
                            .then((assignment) => {
                                this.sharePointItem = assignment;
                                this.itemTitle = this.sharePointItem.Title;

                                if (this.sharePointItem.Projektnummer) {
                                    this.itemTitle =
                                        this.sharePointItem.Title +
                                        ' (' +
                                        this._translateService.instant(
                                            'PROJECTNUMBER'
                                        ) +
                                        ': ' +
                                        this.sharePointItem.Projektnummer +
                                        ')';
                                }
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        // Get Comments
                        this._sharepointService
                            .getListItems({
                                title: 'Projektkommentare',
                                isDocumentLibrary: false,
                                folderName: this.id + '',
                                camlQuery: this.camlQuery,
                            })
                            .then((comments) => {
                                comments.forEach((comment) => {
                                    this.items.push({
                                        who: {
                                            Id: comment.EditorId,
                                            FullName:
                                                comment.FieldValuesAsText
                                                    .Author,
                                        },
                                        message: comment.Body,
                                        time: comment.Created,
                                        id: comment.Id,
                                    });
                                });

                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                );
            }

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._updateService
                        .getPropertyValue('Kommentare löschen')
                        .then((value) => {
                            if (value == 'Ja') {
                                this.enableDelete = true;
                            }

                            resolve();
                        })
                        .catch((error) => reject(error));
                })
            );

            promises.push(
                new Promise<void>((resolve, reject) => {
                    this._updateService
                        .getPropertyValue('Kommentare bearbeiten')
                        .then((value) => {
                            if (value == 'Ja') {
                                this.enableEdit = true;
                            }

                            resolve();
                        })
                        .catch((error) => reject(error));
                })
            );

            Promise.all(promises)
                .then(() => this.initComments())
                .catch((error) => console.error(error));
        });
    }

    onNavChange() {
        //
    }

    loadSelectedComment() {
        this._sharepointService
            .getItemById({
                listTitle: 'Projektkommentare',
                id: this.selectedCommentId,
            })
            .then((comment) => {
                if (
                    comment &&
                    comment.AuthorId == this._sharepointService.currentUser.Id
                ) {
                    this.selectedComment = comment;
                } else {
                    this.selectedComment = null;
                    this.selectedCommentId = -1;
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    addComment(message: any) {
        this._sharepointService
            .addItem({
                listTitle: 'Projektkommentare',
                folder: this.id + '',
                data: {
                    Body: message.message,
                    Title: 'Kommentar',
                },
            })
            .then((commentId) => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'COMMENTS_ASSIGNMENT.NOTIFICATIONS.SUCCESSADD'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                message.id = commentId;

                // Add message to array begin
                this.config.items.unshift(message);
            })
            .catch((error) => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'COMMENTS_ASSIGNMENT.NOTIFICATIONS.ERRORADD'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );

                console.error(error);
            });
    }

    deleteComment() {
        if (this.selectedCommentId) {
            this._sharepointService
                .recycleItem({
                    listTitle: 'Projektkommentare',
                    id: this.selectedCommentId,
                })
                .then(() => {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'COMMENTS_ASSIGNMENT.NOTIFICATIONS.SUCCESSDELETE'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );

                    // Update item array
                    this.config.items = this.config.items.filter(
                        (x) => x.id !== this.selectedCommentId
                    );

                    this._commentService.rightSidenav.close();
                })
                .catch((error) => {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'COMMENTS_ASSIGNMENT.NOTIFICATIONS.ERRORDELETE'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'error-dialog',
                        }
                    );

                    console.error(error);
                });
        }
    }

    saveComment() {
        this._sharepointService
            .updateItem({
                listTitle: 'Projektkommentare',
                id: this.selectedCommentId,
                newFiledValues: { Body: this.messageBody.nativeElement.value },
            })
            .then(() => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'COMMENTS_ASSIGNMENT.NOTIFICATIONS.SUCCESSUPDATE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'success-dialog',
                    }
                );

                const item = this.config.items.find(
                    (x) => x.id == this.selectedCommentId
                );

                // Update item array
                if (item) {
                    item.message = this.messageBody.nativeElement.value;
                }

                this._commentService.rightSidenav.close();
            })
            .catch((error) => {
                this._snackBarService.add(
                    this._translateService.instant(
                        'COMMENTS_ASSIGNMENT.NOTIFICATIONS.ERRORUPDATE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );

                console.error(error);
            });
    }

    initComments() {
        this.config = {
            header: '',
            headerIcon: '',
            autoFocus: false,
            items: this.items,
            placeholder: this._translateService.instant(
                'COMMENTS_ASSIGNMENT.ADDCOMMENT'
            ),
            enableClickOnComment: this.enableDelete || this.enableEdit,
            afterReplyFunction: (message) => {
                this.addComment(message);
            },
        };
    }
}
