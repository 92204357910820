export const locale = {
    lang: 'en',
    data: {
        PROJECTS: 'Projects',
        BASE: 'Basedata',
        DOCS: 'Documents',
        INVOLVED: 'Involved',
        TASKS: 'Invoices',
        INVOICES: 'Invoices',
        KANBAN: 'Kanban',
        PROJECTNUMBER: 'Projectnumber',
        NOTES: 'Notes',
        TABS: {
            WORKPACKAGES: 'Workpackages',
            TASKS: 'Tasks',
        },
        BUTTONS: {
            NEW: 'Add new invoice',
            NEWTASK: 'Add new task',
            MY: 'My workpackages',
            MYTASK: 'My tasks',
            CRITICAL: 'Critical workpackages',
            CRITICALTASK: 'Critical tasks',
        },
        COLUMNS: {
            TITLE: 'Rechnungstitel',
            DATE: 'Datum',
            DUEDATE: 'Fälligkeitsdatum',
            STATUS: 'Status',
            TASK: 'Aufgabe',
            ASSIGNED: 'Zugewiesen an',
            BILL_NUMBER: 'Rechnungsnummer',
            NETTO: 'Nettobetrag',
            BRUTTO: 'Bruttobetrag',
            VON: 'Von',
            BIS: 'Bis'
        },
    },
};
