import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    ColumnTyp,
    FormatTypes,
    SharePointTableComponent,
    TableConfiguration,
} from 'sp-office365-framework';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectEvaluationService } from '../project-evaluation.service';
import { locale as english } from '../i18n/en';
import { locale as german } from '../i18n/de';
import { FuseTranslationLoaderService } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import {
    PROJECT_EVALUATION_HEADER_FORMAT,
    PROJECT_EVALUATION_SUB_HEADER_FORMAT,
} from '../project-evaluation.constant';

@Component({
    selector: 'app-project-evaluation-table',
    templateUrl: './project-evaluation-table.component.html',
    styleUrls: ['./project-evaluation-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectEvaluationTableComponent implements OnInit, AfterViewInit {
    public tableConfig: TableConfiguration;
    public excelColumns = [];
    public currencyFormat = '_(€ #,##0.00_);_(€ -#,##0.00;_(@_)';
    public PROJECT_EVALUATION_HEADER_FORMAT = PROJECT_EVALUATION_HEADER_FORMAT;
    public PROJECT_EVALUATION_SUB_HEADER_FORMAT =
        PROJECT_EVALUATION_SUB_HEADER_FORMAT;
    private destroy$ = new Subject();
    @ViewChild('grid') grid: SharePointTableComponent;
    @ViewChild('export', { static: false }) export: ExcelExportComponent;

    constructor(
        public projectEvaluationService: ProjectEvaluationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translationService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        this.initExcelColumns();
        this.initTableConfiguration();
        this.projectEvaluationService.projects$
            .pipe(takeUntil(this.destroy$))
            .subscribe((projects) => {
                this.tableConfig.data = projects;
                this.grid?.refresh();
            });
        this.projectEvaluationService.triggerFilter$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.grid) {
                    this.grid.loading = true;
                }
            });
    }

    ngAfterViewInit(): void {
        this.grid.loading = true;
    }

    private initTableConfiguration(): void {
        this.tableConfig = {
            list: '',
            columns: this.getTableColumns(),
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            checkboxColumnShowSelectAll: false,
            data: [],
            useMaterialDesign: true,
        };
    }

    private getTableColumns() {
        return [
            {
                internalName: 'Title',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Projektnummer',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.PROJECTNUMBER'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'OW_Nummer',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.OW_NUMMER'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: '_Budgets.Antragssumme',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.TOTLALSUM'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Budgets.Auftragsbudget',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.BUDGET'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Bills.Internal',
                title: 'Rechnungen',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: 'Budget_x0020__x0028_in_x0020_Stu',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.BUDGET_STUD'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Budget_x0020__x0028_in_x0020_Tag',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.BUDGET_TAG'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: '_Budgets.Nebenkosten',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.ADDITIONAL_PER'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: '_Budgets.NebenkostenEuro',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.ADDITIONAL_EURO'
                ),
                hidden: true,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Budgets.Fremdleistung',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.EXTERNAL'
                ),
                hidden: true,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: 'Auftragsstatus',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.STATUS'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Auftragsstart',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.START'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
                width: '170px',
            },
            {
                internalName: 'Auftragsende',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.END'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
                width: '170px',
            },
            {
                internalName: '_Kunde',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.CUSTOMER'
                ),
                hidden: true,
                type: ColumnTyp.Text,
            },
            {
                internalName: '_Team',
                title: this._translationService.instant('Team'),
                hidden: true,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];
    }

    private initExcelColumns() {
        this.excelColumns = [
            {
                internalName: 'Projektnummer',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.PROJECTNUMBER'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                width: '150px',
            },
            {
                internalName: 'Title',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'OW_Nummer',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.OW_NUMMER'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Auftragsstart',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.START'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
                width: '100px',
            },
            {
                internalName: 'Auftragsende',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.END'
                ),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
                width: '100px',
            },
            {
                internalName: '_Budgets.Antragssumme',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.TOTLALSUM'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: 'Gewinnmarge',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.EXPECTEDPROFITMARGIN'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: '_Budgets.NebenkostenEuro',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.ADDITIONAL_EURO'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Budgets.Auftragsbudget',
                title: 'interne Auftragsumme',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Budgets.Fremdleistung',
                title: 'Gesamte Fremdleistungen',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Bills.Internal',
                title: 'Rechnung intern',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Bills.External',
                title: 'Rechnung extern',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Bills.Total',
                title: 'Rechnungen (intern/extern)',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_RemainingFee',
                title: 'Resthonorar (intern/extern)',
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_TotalHours',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.TOTAL_HOURS'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_Arbeitsvorrat',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.WORKLIST'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_GewinnEuro',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.GEWINN_EURO'
                ),
                hidden: false,
                type: ColumnTyp.Text,
                currency: true,
            },
            {
                internalName: '_GewinnPercent',
                title: this._translationService.instant(
                    'PROJECTEVALUATION.COLUMNS.GEWINN_PERCENT'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Auftragsstatus',
                title: 'Status',
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'FieldValuesAsText.Kostenstelle',
                title: 'Kostenstelle',
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'FieldValuesAsText.Kunde',
                title: 'Auftraggeber',
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'FieldValuesAsText.Team',
                title: 'Team   ',
                hidden: false,
                type: ColumnTyp.Text,
                width: '200px',
            },
            {
                internalName: 'FieldValuesAsText.Auftragsleiter',
                title: 'PL',
                hidden: false,
                type: ColumnTyp.Text,
            },
        ];
    }

    public onExcelExport(component: ExcelExportComponent): void {
        const options = component.workbookOptions();
        const rows = options.sheets[0].rows;

        rows.forEach((row) => {
            if (row.type === 'data') {
                row.cells.forEach((cell, index) => {
                    if (index === 12 || index === 13) {
                        if (typeof cell.value === 'number' && cell.value < 0) {
                            cell.color = '#ff0000';
                        } else {
                            cell.color = '#008010';
                        }
                    }
                });
            }
        });

        component.save(options);
    }

    ngOnDestroy(): void {
        this.projectEvaluationService.resetFilter();
        this.destroy$.next(undefined);
    }
}
