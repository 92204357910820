import { SharePointService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1066Component {
    constructor(private _sharePointService: SharePointService) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const propertyList: IList = this._sharePointService.web.lists.getByTitle(
                'Properties'
            );

            const promises = [
                // Add Items to List Properties
                await propertyList.items.add({
                    Title: 'Standard Gruppen für Bearbeitungsberechtigung',
                    Value: '',
                }),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
