import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';

export class UpdateToVersion1009Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            let tasksetList: IList;
            let tasktemplateList: IList;

            const processStepList: IList = this.sharePointService.web.lists.getByTitle(
                'Prozessschritte'
            );
            const taskTypeList: IList = this.sharePointService.web.lists.getByTitle(
                'Aufgabentypen'
            );

            const promises = [
                // Add List Aufgabensets
                await this._updateService.addList('Aufgabensets', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Aufgabensets
                await new Promise<void>((resolve) => {
                    tasksetList = this.sharePointService.web.lists.getByTitle(
                        'Aufgabensets'
                    );
                    resolve();
                }),
                // Add List Aufgabenvorlagen
                await this._updateService.addList('Aufgabenvorlagen', '', 100, {
                    EnableVersioning: true,
                }),
                // Get List Aufgabenvorlagen
                await new Promise<void>((resolve) => {
                    tasktemplateList = this.sharePointService.web.lists.getByTitle(
                        'Aufgabenvorlagen'
                    );
                    resolve();
                }),
                // Add Field Beschreibung to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'Beschreibung',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            tasktemplateList.fields
                                .addMultilineText('Beschreibung')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field OffsetAnfangsdatum to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'OffsetAnfangsdatum',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            tasktemplateList.fields
                                .addNumber('OffsetAnfangsdatum')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field OffsetF_x00e4_lligkeitsdatum to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'OffsetF_x00e4_lligkeitsdatum',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            tasktemplateList.fields
                                .addNumber('OffsetF_x00e4_lligkeitsdatum')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Priorit_x00e4_t to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'Priorit_x00e4_t',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // tasktemplateList.fields
                            //     .addChoice('Priorit_x00e4_t', [
                            //         '(1) Hoch',
                            //         '(2) Mittel',
                            //         '(3) Niedrig',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field Aufgabentyp to List Aufgabenvorlagen
                await new Promise<void>((resolve, reject) => {
                    taskTypeList
                        .select('Id')()
                        .then(
                            (taskTypeListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        tasktemplateList,
                                        'Aufgabentyp',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // tasktemplateList.fields
                                                    //     .addLookup(
                                                    //         'Aufgabentyp',
                                                    //         taskTypeListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Prozessschritt to List Aufgabenvorlagen
                await new Promise<void>((resolve, reject) => {
                    processStepList
                        .select('Id')()
                        .then(
                            (processStepListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        tasktemplateList,
                                        'Prozessschritt',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // tasktemplateList.fields
                                                    //     .addLookup(
                                                    //         'Prozessschritt',
                                                    //         processStepListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => {
                                                    //             resolve2();
                                                    //         },
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field Geplante_x0020_Arbeitszeit to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'Geplante_x0020_Arbeitszeit',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            tasktemplateList.fields
                                .addNumber('Geplante_x0020_Arbeitszeit')
                                .then(
                                    () => resolve(),
                                    (error) => {
                                        reject(error);
                                    }
                                );
                        });
                        return promise;
                    }
                ),
                // Add Field Vorg_x00e4_nger to List Aufgabenvorlagen
                await new Promise<void>((resolve, reject) => {
                    tasktemplateList
                        .select('Id')()
                        .then(
                            (tasktemplateListProps) => {
                                this._updateService
                                    .runFunctionIfFieldNotExists(
                                        tasktemplateList,
                                        'Vorg_x00e4_nger',
                                        () => {
                                            const promise = new Promise<void>(
                                                (resolve2, reject2) => {
                                                    // tasktemplateList.fields
                                                    //     .addLookup(
                                                    //         'Vorg_x00e4_nger',
                                                    //         tasktemplateListProps.Id.toString(),
                                                    //         'Title'
                                                    //     )
                                                    //     .then(
                                                    //         () => resolve2(),
                                                    //         (error) => {
                                                    //             reject2(error);
                                                    //         }
                                                    //     );
                                                }
                                            );
                                            return promise;
                                        }
                                    )
                                    .then(
                                        () => {
                                            resolve();
                                        },
                                        (error) => {
                                            reject(error);
                                        }
                                    );
                            },
                            (error) => {
                                reject(error);
                            }
                        );
                }),
                // Add Field OffsetStartType to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'OffsetStartType',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // tasktemplateList.fields
                            //     .addChoice('OffsetStartType', [
                            //         'Ab Heute',
                            //         'Ab Beginn Arbeitspaket',
                            //         'Ab Ende Arbeitspaket',
                            //         'Ab Beginn Auftrag',
                            //         'Ab Ende Auftrag',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Add Field OffsetEndeType to List Aufgabenvorlagen
                await this._updateService.runFunctionIfFieldNotExists(
                    tasktemplateList,
                    'OffsetEndeType',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // tasktemplateList.fields
                            //     .addChoice('OffsetEndeType', [
                            //         'Ab Heute',
                            //         'Ab Beginn Arbeitspaket',
                            //         'Ab Ende Arbeitspaket',
                            //         'Ab Beginn Auftrag',
                            //         'Ab Ende Auftrag',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
