export const locale = {
    lang: 'en',
    data: {
        CHART_SERVICE: {
            ERROR: 'At least one data set entry is missing!',
        },
        TASK_SERVICE: {
            NOTASKSETSELECTED: 'No taskset selected.',
            NOTASKS: 'No tasks in taskset.',
            ERROR: 'Error: ',
            SUCCESS: 'Task added successfully!',
            DELETETASK: {
                MESSAGE:
                    'Do you want to delete all tasks of the recently deleted workpackage?',
                SUCCESS: 'All tasks of the workpackage are removed!',
                NOTASKS: 'The deleted workpackage had no tasks!',
            },
            WORKPACKAGESTATUS: 'Changed the workpackage status to: ',
            PROJECTSTATUS: 'Changed the project status to: ',
            SYNCTOWP:
                'Should the following values be added to the dates of the associated work packages and tasks? ',
            SYNCTOTASK:
                'Should the following values be added to the dates of the associated tasks? ',
            START: 'Start: ',
            END: 'End: ',
            DAYS: 'Days',
            SUCCESSSYNC: 'Duration assumed for associated workpackages/tasks!',
            NOTIFICATIONS: {
                SAVINGPROJECT: 'Saving project...',
                SAVINGWP: 'Saving workpackage...',
                WAIT: 'Please wait.',
            },
        },
        MOVE_SERVICE: {
            ERROR: 'An error occured!',
        },
        REQUESTS_SERVICE: {
            BASE: 'Basedata',
            DOCS: 'Documents',
            MAILS: 'E-Mails',
        },
        APPLICATION_SERVICE: {
            PROJECTS: 'Projects',
            BASE: 'Basedata',
            DOCS: 'Documents',
            COSTROLES: 'Costroles',
            INVOICES: 'Invoices',
            INVOLVED: 'Involved',
            TASKS: 'Tasks',
            KANBAN: 'Kanban',
            NOTES: 'Notes',
            COMMENTS: 'Comments',
            MAILS: 'E-Mails',
            TIMES: 'Times',
            TIMELINE: 'Timeline',
            NOTIFICATIONS: {
                COPYCATEGORYDOCS:
                    'The selected category has documents. Should these be copied into the project?',
            },
        },
        ORDER_SERVICE: {
            BASE: 'Basedata',
            DOCS: 'Documents',
            MAILS: 'E-Mails',
            COSTROLES: 'Costroles',
        },
        TIME_SERVICE: {
            NOTIFICATIONS: {
                DELETING: 'Deleting element.',
                UPDATE: 'Succesfully changed the item.',
                SAVING: 'Saving time...',
                WAIT: 'Please wait.',
                SUCCESS: 'Time saved successfully',
                ERRORTASK: 'Please select a task!',
                ERRORDURATION: 'The duration must be greater than 0!',
                ERRORDATE: 'Please select a date!',
                HOURSERROR: 'Hours: Only numbers between 0 and 23',
                MINUTESERROR: 'Minutes: Only numbers of 5-series and lower 60',
                ZEROCOSTPERHOUR:
                    'Cost per hour is zero. Are you sure you want to continue?',
                THROTTLED_ERROR:
                    'The query returns too many results. Please refine the filter criteria.',
            },
            COLUMNS: {
                DATE: 'Date',
                MINUTES: 'Duration (in hours)',
                COSTS: 'Costs (in €)',
                PROJECT: 'Project',
                TASK: 'Task',
                AUTHOR: 'Employee',
                DESCRIPTION: 'Description',
            },
        },
        BUDGET_SERVICE: {
            BOOKED: 'Booked',
            OPEN: 'Available',
        },
    },
};
