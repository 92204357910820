import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportWorkpackageComponent } from './import-workpackage.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexModule } from '@angular/flex-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

@NgModule({
    declarations: [ImportWorkpackageComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        DragDropModule,
        FlexModule,
        AngularFrameworkModule
    ],
    exports: [ImportWorkpackageComponent]
})
export class ImportWorkpackageModule {
}
