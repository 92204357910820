<div class="page-layout simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <!-- APP TITLE -->
    <div fxLayout="column" fxLayoutAlign="center start">

      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-4" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">settings
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{ "UPDATE" | translate }}
        </span>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
        <span class="secondary-text">{{ "ADMIN" | translate }}</span>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{ "UPDATE" | translate }}</span>
      </div>

    </div>
    <!-- / APP TITLE -->

    <!-- SEARCH -->
    <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

        <div class="search-wrapper mt-4 mt-sm-0">

          <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>search</mat-icon>
            <input [formControl]="searchInput" placeholder="Search for a contact">
          </div>

        </div>

      </div> -->
    <!-- / SEARCH -->

  </div>
  <!-- / HEADER -->

  <div id="content">

    <mat-card appearance="outlined" class="mat-card">

      <div id='central'>

        <!-- If Update available -->
        <ng-container *ngIf="!latest">

          <!-- Update -->
          <ng-container *ngIf="currentVersion != 1000">
            <h4 class="m-0 secondary-text">{{ "AVAILABLE" | translate }}</h4><br>

            <!-- Update one -->
            <button mat-raised-button color="accent" (click)="update()" class="updateButton"
              [disabled]="!userIsSiteAdmin">{{ "RUN" | translate }}</button>

            <!-- Update all -->
            <button mat-raised-button color="accent" (click)="update(true)" class="updateButton"
              [disabled]="!userIsSiteAdmin">{{ "RUNALL" | translate }}</button>

          </ng-container>

          <!-- Install -->
          <ng-container *ngIf="currentVersion == 1000">

            <!-- Install all -->
            <button mat-raised-button color="accent" (click)="update(true)" class="updateButton"
              [disabled]="!userIsSiteAdmin">{{ "INSTALL" | translate }}</button>

          </ng-container>


          <br /><br />

          <!-- If User is not in Group Business-Owner or is no Sitecollection Admin -->
          <h4 class="m-0 secondary-text" *ngIf="!userIsSiteAdmin">{{ "NOADMIN" | translate }}</h4>

          <!-- Show current version -->
          <h4 class="m-0 secondary-text" *ngIf="currentVersion != 1000">{{ "CURRENT" | translate }}
            {{ currentVersion }}</h4>

          <!-- Show current not installed -->
          <h4 class="m-0 secondary-text" *ngIf="currentVersion == 1000">{{ "NOCURRENT" | translate }}</h4>
          <br />

          <!-- Show latest version -->
          <h4 class="m-0 secondary-text" *ngIf="latestVersion != 0">{{ "LATEST" | translate }} {{ latestVersion }}
          </h4>
        </ng-container>

        <!-- If no Update available -->
        <ng-container *ngIf="latest">
          <h4 class="m-0 secondary-text">{{ "UPTODATE1" | translate }}</h4>
          <h4 class="mt-0 secondary-text">{{ "UPTODATE2" | translate }}</h4>
        </ng-container>



      </div>

    </mat-card>

  </div>

</div>
