export const locale = {
    lang: 'de',
    data: {
        OV_BUDGET: {
            BOOKEDCURRENCY: 'Gebuchtes Budget',
            BOOKEDHOURS: 'Gebuchtes Budget',
            BOOKEDDAYS: 'Gebuchtes Budget',
            INEURO: '(in Euro)',
            INHOURS: '(in Stunden)',
            INDAYS: '(in Tagen)',
            DIFFCURRENCY: 'Differenz',
            DIFFHOURS: 'Differenz',
            DIFFDAYS: 'Differenz',
            BOOKEDBUDGETINPERCENT: 'Budget (in Euro)',
            BOOKED: 'Verbraucht',
            OPEN: 'Verfügbar',
            BUDGETBILLWDIGETTITLE: 'Resthonorar',
        },
    },
};
