import { SchedulerModule } from './../../scheduler/scheduler.module';
import { NgModule } from '@angular/core';
import { TimelineAssignmentComponent } from './timeline-assignment.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    FuseSharedModule,
    LayoutModule,
    FuseSidebarModule,
} from 'sp-office365-layout';
import { DayPilotModule } from 'daypilot-pro-angular';
import { MatButtonModule } from '@angular/material/button';

const routes = [
    {
        path: 'projects/detail/timeline/:id',
        component: TimelineAssignmentComponent,
    },
];

@NgModule({
    declarations: [TimelineAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        FuseSidebarModule,
        MatCardModule,
        SchedulerModule,
        DayPilotModule,
        MatButtonModule,
    ],
    exports: [TimelineAssignmentComponent],
})
export class TimelineAssignmentModule {}
