import { Injectable } from '@angular/core';
import CamlBuilder from 'camljs';

@Injectable({
    providedIn: 'root',
})
export class LookupService {
    public teams: number[] = [];
    public location: number;
    public locationLookUpId: number;
    public teamsFromLocation: number[] = [];
    public teamsFirstTimeLoad: number[] = [];

    constructor() {}

    reset(): void {
        this.teams = [];
        this.teamsFromLocation = [];
        this.teamsFirstTimeLoad = [];
        this.location = null;
        this.locationLookUpId = null;
    }
    allEntrysCamlQuery = new CamlBuilder()
        .Where()
        .NumberField('ID')
        .NotEqualTo(-1)
        .OrderBy('Title')
        .ToString();

    get locationCAMLQuery() {
        if (this.teams.length == 1) {
            return (
                new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    //If Team has no Location --> show no location
                    .EqualTo(this.locationLookUpId || -1)
                    .OrderBy('Title')
                    .ToString()
            );
        }
        //If more than two Teams are choosen --> display no location!
        else if (this.teams.length > 1) {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .OrderBy('Title')
                .ToString();
        }
        //FIRST TIME LOAD in Edit Assignment if an entry has a team and a location
        else if (this.teamsFirstTimeLoad.length && this.location) {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(this.location)
                .OrderBy('Title')
                .ToString();
        }

        return this.allEntrysCamlQuery;
    }

    get locationSearchCAMLQuery() {
        if (this.teams.length == 1) {
            return (
                new CamlBuilder()
                    .Where()
                    .NumberField('ID')
                    //If Team has no Location --> show no location
                    .EqualTo(this.locationLookUpId || -1)
                    .And()
                    .TextField('Title')
                    .Contains('<%searchText%>')
                    .OrderBy('Title')
                    .ToString()
            );
        }
        //Get no Locations if more than 2 Teams are choosen
        else if (this.teams.length > 1) {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .EqualTo(-1)
                .And()
                .TextField('Title')
                .Contains('<%searchText%>')
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    get teamCAMLQuery() {
        if (this.location) {
            return new CamlBuilder()
                .Where()
                .LookupField('Standort')
                .Id()
                .EqualTo(this.location)
                .OrderBy('Title')
                .ToString();
        }
        //FIRST TIME LOAD in Edit-Assignment when an item has a team but no Location
        else if (this.teamsFirstTimeLoad.length) {
            return new CamlBuilder()
                .Where()
                .NumberField('ID')
                .In(this.teamsFirstTimeLoad)
                .OrderBy('Title')
                .ToString();
        }
        return this.allEntrysCamlQuery;
    }

    get teamSearchCAMLQuery() {
        if (this.location) {
            return new CamlBuilder()
                .Where()
                .LookupField('Standort')
                .Id()
                .EqualTo(this.location)
                .And()
                .TextField('Title')
                .Contains('<%searchText%>')
                .OrderBy('Title')
                .ToString();
        }
        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .TextField('Title')
            .Contains('<%searchText%>')
            .OrderBy('Title')
            .ToString();
    }

    get projectCamlQuery() {
        if (this.teams.length) {
            return new CamlBuilder()
                .Where()
                .LookupField('Team')
                .Id()
                .In(this.teams)
                .OrderBy('Title')
                .ToString();
        }

        if (this.location) {
            return new CamlBuilder()
                .Where()
                .LookupField('Standort')
                .Id()
                .EqualTo(this.location)
                .OrderBy('Title')
                .ToString();
        }
        return this.allEntrysCamlQuery;
    }

    get projectSearchCamlQuery() {
        if (this.teams.length) {
            return new CamlBuilder()
                .Where()
                .LookupField('Team')
                .Id()
                .In(this.teams)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>')
                    ]
                )
                .OrderBy('Title')
                .ToString();
        }

        if (this.location) {
            return new CamlBuilder()
                .Where()
                .LookupField('Standort')
                .Id()
                .EqualTo(this.location)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>')
                    ]
                )
                .OrderBy('Title')
                .ToString();
        }
        return new CamlBuilder()
            .Where()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>')
                ]
            )
            .OrderBy('Title')
            .ToString();
    }

    public getTeamleiterById(id: number): string {
        return new CamlBuilder()
            .Where()
            .UserField('Teamleiter')
            .Id()
            .EqualTo(id)
            .ToString()
    }
}
