import { SharePointService } from 'sp-office365-framework';
import { TimeService } from './../../services/time.service';
import { DetailTaskComponent } from './../../task/detail-task/detail-task.component';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as CamlBuilder from 'camljs';
import { fuseAnimations, FuseTranslationLoaderService, } from 'sp-office365-layout';
import { ApplicationService } from '../../services/application.service';
import { DurationTimeComponent } from '../duration-time/duration-time.component';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { German } from 'flatpickr/dist/l10n/de.js';

@Component({
    selector: 'app-new-time',
    templateUrl: './new-time.component.html',
    styleUrls: ['./new-time.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class NewTimeComponent implements OnInit, OnDestroy {
    public formio_renderOptions = {
        // language: 'de'
    }

    public refreshForm: EventEmitter<any> = new EventEmitter();
    public formioConfiguration;
    public firstTimeLoad = true;
    public sharePointItem;
    public showNewTimeForm = true;
    //The initial ProjectId of the Element
    public projectId: number;

    //Checks if currently a project is choosen in the form
    private projectSetted: boolean = false;
    public id: number;

    public _projectItem: any;
    @Input() set projectItem(pi: any) {
        this._projectItem = pi;
    }

    public _editTask: any;
    @Input() set editTask(pi: any) {
        this._editTask = pi;
    }

    public _taskItem: any;
    @Input() set taskItem(ti: any) {
        this._taskItem = ti;
    }

    @ViewChild('formioComponent', { static: false })
    formioComponent: FormioComponent;

    constructor(
        public timeService: TimeService,
        private _applicationService: ApplicationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharepointService: SharePointService,
        public dialogRef: MatDialogRef<NewTimeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        if (this.data.item) {
            this.timeService.editMode = true;
        }
    }

    ngOnDestroy(): void {
        this.timeService.editMode = false;
    }

    async ngOnInit() {
        const promises = [];

        if (this.data.item) {
            this.timeService.preserve();
            this.timeService.reset();
        }

        if (this._projectItem) {
            promises.push(this.timeService.getEmplyoeeProfileForCurrentUser(this._projectItem.Id));
        } else {
            promises.push(this.timeService.getEmplyoeeProfileForCurrentUser());
        }


        if (
            this._projectItem && (this._projectItem.Auftragsstatus == 'Auftrag abgeschlossen' || this._projectItem.Auftragsstatus == 'Auftrag gestoppt')
        ) {
            this.showNewTimeForm = false;
        }

        if (this.data.item) {
            this.id = this.data.item.Id;
            this.projectSetted = true;
            this.projectId = this.data.item.ProjektId;
            this.timeService.projectNumber =
                await this.timeService.getProjectNumber(this.projectId);
            this.sharePointItem = this.data.item;
            this.timeService.projectId = this.data.item.ProjektId;
            if (this.data.item.AufgabeId) {
                this.timeService.taskId = this.data.item.AufgabeId;
            }
            this.timeService.itemId = this.data.item.Id;
            this.timeService.setDate(this.sharePointItem.Datum);

            if (this.data.item.Minuten < 60) {
                this.timeService.hours = 0;
                this.timeService.minutes = this.data.item.Minuten;
            } else if (
                Math.floor(this.data.item.Minuten / 60) ==
                this.data.item / 60
            ) {
                this.timeService.hours = this.data.item / 60;
                this.timeService.minutes = 0;
            } else {
                this.timeService.hours = Math.floor(
                    this.data.item.Minuten / 60
                );
                this.timeService.minutes =
                    this.data.item.Minuten - this.timeService.hours * 60;
            }
        } else {
            promises.push(this.timeService.getSumOfTimesForDate());
            //If new-time component has been opened via detail-task
            if (this.data.auftragsId) {
                this.timeService.projectNumber =
                    await this.timeService.getProjectNumber(
                        this.data.auftragsId
                    );
            }
        }

        Promise.all(promises)
            .then(() => {
                this.initForm();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    setValues() {
        //Set values for the time service after a PopUp Window with the same Component was closed
        const { AufgabeId, ProjektId, Datum, Beschreibung } =
            this.formioComponent.formio._data;

        this.timeService.taskId = AufgabeId;
        this.timeService.projectId = ProjektId;
        this.timeService.setDate(Datum);
        this.timeService.description = Beschreibung;
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );

            if (this.data.disableProjectAndTask) {
                this._applicationService.updateFormioComponentProperty(
                    this.formioComponent.formio.components,
                    'AufgabeId',
                    'disabled',
                    true
                );
            } else {
                //Only disable tasks but not Project
                this._applicationService.updateFormioComponentProperty(
                    this.formioComponent.formio.components,
                    'AufgabeId',
                    'disabled',
                    false
                );
            }

            if (this.data.disableProject || this.data.disableProjectAndTask) {
                this._applicationService.updateFormioComponentProperty(
                    this.formioComponent.formio.components,
                    'ProjektId',
                    'disabled',
                    true
                );
            }

            if (!this._taskItem && !this.sharePointItem.AufgabeId) {
                this.timeService.taskId = -1;
            }
        } else {
            dataForm = emptyObj;

            // Set default values
            dataForm.Datum = new Date();

            if (this._taskItem != null) {
                dataForm.AufgabeId = this._taskItem.Id;
            }

            if (this._projectItem != null) {
                dataForm.ProjektId = this._projectItem.Id;
                this.timeService.projectId = this._projectItem.Id;
            }

            //If you get here from "project/times" --> Projectform is already set and disabeld.
            //But the query for the task has to be taken over by the form
            // Update searchQueryIfIsEmpty for task
            this._applicationService.updateFormioComponentProperty(
                this.formioComponent.formio.components,
                'AufgabeId',
                'searchQueryIfIsEmpty',
                this.timeService.searchQueryIfIsEmptyForTasks
            );

            // Update searchQuery for task
            this._applicationService.updateFormioComponentProperty(
                this.formioComponent.formio.components,
                'AufgabeId',
                'searchQuery',
                this.timeService.searchQueryForTasks
            );
        }

        // Set Stundensatz from costrole
        if ( this.timeService.currentUserCostRole != null && this.timeService.currentUserCostRole.Stundensatz != null ) {
            dataForm.Stundensatz = this.timeService.currentUserCostRole.Stundensatz;
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                throw error;
            });
    }

    onChange(ev) {
        if (ev.changed) {
            if (this.firstTimeLoad) {
                this.firstTimeLoad = false;
            } else {
                if (ev.changed.component.key == 'Datum') {
                    if (ev.changed.value && ev.changed.value) {
                        this.timeService.setDate(ev.changed.value);
                    } else {
                        this.timeService.setDate(ev.changed.value);
                    }

                    this.timeService.getSumOfTimesForDate();
                }
                if (ev.changed.component.key == 'Beschreibung') {
                    if (ev.changed.value) {
                        this.timeService.description = ev.changed.value;
                    }
                }
            }
        }
    }

    initForm() {
        //if (this.sharePointItem) {
        this.formioConfiguration = {
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'NEW_TIME.COLUMNS.PROJECT'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'ProjektId',
                                    selectThreshold: 0.3,
                                    list: 'Aufträge',
                                    searchQueryIfIsEmpty: new CamlBuilder()
                                        .Where()
                                        .ChoiceField('Auftragsstatus')
                                        .NotEqualTo('Auftrag gestoppt')
                                        .And()
                                        .ChoiceField('Auftragsstatus')
                                        .NotEqualTo('Auftrag abgeschlossen')
                                        .OrderBy('Title')
                                        .ToString(),
                                    searchQuery: new CamlBuilder()
                                        .Where()
                                        .ChoiceField('Auftragsstatus')
                                        .NotEqualTo('Auftrag gestoppt')
                                        .And()
                                        .ChoiceField('Auftragsstatus')
                                        .NotEqualTo('Auftrag abgeschlossen')
                                        .And()
                                        .Any([
                                            CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                                            CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                                        ])
                                        .OrderBy('Title')
                                        .ToString(),
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    formatTitle: (item) => `${item.Title} (${item.Projektnummer})`,
                                    onSelected: async (control, data) => {
                                        const dataForm =
                                            this.formioComponent.formio._data;

                                        dataForm['Beschreibung'] = null;

                                        //Check if another Project was choosen before the old one was removed
                                        if (this.projectSetted) {
                                            dataForm['AufgabeId'] = null;
                                            this.timeService.taskId = -1;
                                        }
                                        this.projectSetted = true;
                                        // Set projectId
                                        this.timeService.projectId = data.Id;
                                        this.timeService.projectNumber =
                                            await this.timeService.getProjectNumber(
                                                data.Id
                                            );
                                        if (this.timeService.editMode) {
                                            // Check if if the choosen projectId differs from the original one
                                            if (data.Id != this.projectId) {
                                                this.timeService.newProjectId =
                                                    data.Id;
                                            } else {
                                                this.timeService.newProjectId =
                                                    null;
                                            }
                                        }

                                        // Enable task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio
                                                .components,
                                            'AufgabeId',
                                            'disabled',
                                            false
                                        );

                                        // Update searchQueryIfIsEmpty for task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio.components,
                                            'AufgabeId',
                                            'searchQueryIfIsEmpty',
                                            this.timeService.searchQueryIfIsEmptyForTasks
                                        );

                                        // Update searchQuery for task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio.components,
                                            'AufgabeId',
                                            'searchQuery',
                                            this.timeService.searchQueryForTasks
                                        );

                                        await this.timeService.getEmplyoeeProfileForCurrentUser(data.Id);
                                        dataForm.Stundensatz = this.timeService.currentUserCostRole.Stundensatz;
                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm
                                            }
                                        });
                                    },
                                    onRemoved: async () => {
                                        this.projectSetted = false;
                                        this.timeService.projectId = -1;
                                        const dataForm =
                                            this.formioComponent.formio._data;

                                        dataForm['AufgabeId'] = null;
                                        dataForm['Beschreibung'] = null;
                                        this.timeService.taskId = -1;

                                        // Disable task
                                        this._applicationService.updateFormioComponentProperty(
                                            this.formioComponent.formio
                                                .components,
                                            'AufgabeId',
                                            'disabled',
                                            true
                                        );
                                        await this.timeService.getEmplyoeeProfileForCurrentUser();
                                        dataForm.Stundensatz = this.timeService.currentUserCostRole.Stundensatz;
                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm,
                                            },
                                        });
                                    },
                                    disabled: this._projectItem != null,
                                },
                            ],
                            type: 'column',
                            key: 'column1',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'NEW_TIME.COLUMNS.TASK'
                                    ),
                                    type: 'sharePointAutocomplete',
                                    input: true,
                                    key: 'AufgabeId',
                                    selectThreshold: 0.3,
                                    list: 'Auftragsaufgaben',
                                    searchQueryIfIsEmpty:
                                        this.timeService
                                            .searchQueryIfIsEmptyForTasks,
                                    addComponent: DetailTaskComponent,
                                    addComponentData: {
                                        auftragsId: this.timeService.projectId,
                                        isTask: false,
                                        createMode: true,
                                    },
                                    isPeoplePicker: false,
                                    removable: true,
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    onSelected: (control, data) => {
                                        const dataForm =  this.formioComponent.formio._data;

                                        this.timeService.taskId = data.Id;
                                        dataForm['Beschreibung'] = null;
                                        this.timeService.description = null;

                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm,
                                            },
                                        });
                                    },
                                    onRemoved: (control, item) => {
                                        const dataForm =  this.formioComponent.formio._data;

                                        this.timeService.taskId = -1;
                                        dataForm['Beschreibung'] = null;
                                        this.timeService.description = null;

                                        this.refreshForm.emit({
                                            submission: {
                                                data: dataForm,
                                            },
                                        });
                                    },
                                    disabled:
                                        this._taskItem != null ||
                                        this._projectItem == null,
                                },
                            ],
                            type: 'column',
                            key: 'column2',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'NEW_TIME.COLUMNS.DATE'
                                    ),
                                    hideLabel: false,
                                    format: 'dd.MM.yyyy',
                                    type: 'datetime',
                                    enableTime: false,
                                    key: 'Datum',
                                    suffix: true,
                                    widget: {
                                        type: 'calendar',
                                        displayInTimezone: 'viewer',
                                        submissionTimezone: 'Europe/Berlin',
                                        language: 'en',
                                        useLocaleSettings: false,
                                        allowInput: true,
                                        mode: 'single',
                                        enableTime: true,
                                        noCalendar: false,
                                        format: 'dd.MM.yyyy HH:mm',
                                        defaultDate: '',
                                        hourIncrement: 1,
                                        minuteIncrement: 1,
                                        time_24hr: true,
                                        minDate: '',
                                        maxDate: '',
                                        icons: 'fontawesome',
                                    },
                                    datePicker: {
                                        minDate: '',
                                        maxDate: '',
                                    },
                                    timePicker: {
                                        showMeridian: false,
                                    },
                                    validateOn: 'blur',
                                    onCleared: (control) => {
                                        this.timeService.date = null;
                                        this.timeService.getSumOfTimesForDate();
                                    },
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                    customOptions: {
                                        locale: German
                                    },
                                },
                            ],
                            type: 'column',
                            key: 'column3',
                            label: 'Column',
                        },
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'NEW_TIME.COLUMNS.DESCRIPTION'
                                    ),
                                    type: 'textfield',
                                    key: 'Beschreibung',
                                    inputFormat: 'plain',
                                    validateOn: 'blur',
                                    onChange: (control) => {
                                        this.timeService.description = control.data.Beschreibung;
                                    }
                                },
                            ],
                            type: 'column',
                            key: 'column4',
                            label: 'Column',
                        },
                    ],
                    type: 'columns',
                    key: 'columns1',
                },
                {
                    label: 'secondColumn',
                    columns: [
                        {
                            components: [
                                // Components
                                {
                                    label: this._translateService.instant(
                                        'NEW_TIME.COLUMNS.COSTSPERHOUR'
                                    ),
                                    type: 'number',
                                    key: 'Stundensatz',
                                    inputFormat: 'plain',
                                    validateOn: 'blur',
                                    disabled: true,
                                },
                            ],
                            type: 'column',
                            key: 'column5',
                            label: 'Column',
                        },
                        {
                            components: [
                                {
                                    label: '',
                                    type: 'contentCustom',
                                    component: DurationTimeComponent,
                                    key: '',
                                },
                            ],
                        },
                    ],
                    type: 'columns',
                    key: 'secondColumn',
                },
            ],
        };
    }
}
