import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { map } from 'rxjs/operators';
import { Land } from 'src/app/main/shared/models';

@Injectable({
    providedIn: 'root'
})
export class LandService {

    constructor(
        private _sharePointService: SharePointService
    ) {
    }

    public getDefaultLand(): Observable<Land> {
        return from(this._sharePointService.getListItems({
            title: 'Länder',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .TextField('Title')
                .EqualTo('Deutschland')
                .ToString()
        }))
            .pipe(
                map(lands => {
                    return lands[0];
                })
            );
    }
}
