import { AddUserComponent } from './../add-user/add-user.component';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    ColumnTyp,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import {
    fuseAnimations,
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-table-user',
    templateUrl: './table-user.component.html',
    styleUrls: ['./table-user.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TableUserComponent implements OnInit {
    public tableConfigUsers: TableConfiguration;
    public columns: TableColumn[];
    public user: any[];

    @ViewChild('userGrid', { static: false })
    userGrid: SharePointTableComponent;

    _groupId: number;
    @Input() set groupId(gId: number) {
        this._groupId = gId;
    }

    constructor(
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _sharePointService: SharePointService,
        private _snackbarService: SnackbarService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    async ngOnInit() {
        if (this._groupId) {
            // Get Users
            this.user = await this._sharePointService.web.siteGroups
                .getById(this._groupId)
                .users();

            this.initGrid();
        }
    }

    async onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.openDialog();
                break;
            case 'Remove':
                // Remove user from group
                if (e.dataItem.Id) {
                    await this._sharePointService.web.siteGroups
                        .getById(this._groupId)
                        .users.removeById(e.dataItem.Id);

                    this.user.splice(
                        this.user.findIndex(
                            (item) => item.Id === e.dataItem.Id
                        ),
                        1
                    );

                    // Snackbar bestätigung
                    this._snackbarService.add(
                        this._translateService.instant(
                            'TABLE_USER.NOTIFICATIONS.REMOVED'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );

                    // Refresh table
                    this.userGrid.refresh();
                }

                break;
        }
    }

    openDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            groupId: this._groupId,
        };

        const dialogRef = this.dialog.open(AddUserComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.AddedUsers) {
                // Add users to data
                result.AddedUsers.forEach((user) => {
                    if (!this.user.some((x) => x.LoginName == user.LoginName)) {
                        this.user.push(user);
                    }
                });

                // Refresh table
                this.userGrid.refresh();
            }
        });
    }

    initGrid() {
        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant(
                    'TABLE_USER.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'LoginName',
                title: this._translateService.instant(
                    'TABLE_USER.COLUMNS.LOGINNAME'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },

            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.tableConfigUsers = {
            list: '',
            columns: this.columns,
            pageSize: 10,
            isDocumentLibrary: false,
            showEditButton: false,
            showDeleteButton: false,
            customButtons: [{ name: 'Remove', icon: 'delete' }],
            recursiveAll: true,
            data: this.user,
            folderName: '',
            useMaterialDesign: true,
            toolbar: [
                {
                    title: this._translateService.instant('TABLE_USER.NEW'),
                    command: 'Add',
                    color: 'accent',
                },
            ],
            afterRefreshTable: () => {
                //
            },
        };
    }

    refresh() {
        this.userGrid.refresh();
    }
}
