export const locale = {
    lang: 'en',
    data: {
        DETAIL_EMAIL: {
            ADDATTACHMENT: 'Add attachment',
            NOTIFICATIONS: {
                SAVING: 'Sending mail...',
                WAIT: 'Please wait.',
                SUCCESS: 'Mail sent successfully',
                ERROR: 'Error while sending: ',
                DOUBLEATTACHMENT:
                    'There is already a file with the following name attached: ',
            },
            COLUMNS: {
                TITLE: 'Title',
                SUBJECT: 'Subject',
                TEXT: 'Message',
                TO: 'To',
                CC: 'CC',
                ATTACHMENTS: 'Select attachments',
                SIGNATUREID: 'Select signature',
                SIGNATURE: 'Signature',
                CONTACTS: 'Contacts'
            },
            SAVE: 'Send',
            NEW: 'New E-Mail',
            EDIT: 'Edit E-Mail',
            REPLY: 'Reply',
        },
    },
};
