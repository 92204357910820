export const locale = {
    lang: 'en',
    data: {
        PARTIALLY_FINISHED_SERVICES: {
            TITLE: 'Teilfertigeleistungen',
            DOWNLOAD_PARTIAL_SERVICES_BUTTON: 'Teilfertigeleistungen generieren',
            DOWNLOAD_FEE_NOT_CHARGED_PROJECTS_BUTTON: 'Nicht berechnetes Honorar',
            SLIDER_TITLE: 'Korrekturfaktor',
            NO_PROJECTS: 'There are no projects with correct Status and Kategorie',
            COLUMNS: {
                TITLE: 'Projekt',
                OWNUMBER: 'OW Nummer',
                PROJECTSUM: 'Projektsumme ',
                BUDGET: 'Interne Auftragssumme',
                BUDGET_STUD: 'Interne Auftragssumme (in Stunden)',
                BUDGET_TAG: 'Interne Auftragssumme (in Tagen)',
                TOTLALSUM: 'Auftragssumme',
                TOTALBUDGET: 'Gesamt Budget',
                START: 'Start',
                END: 'Ende',
                STATUS: 'Status',
                CUSTOMER: 'Auftraggeber / Ansprechpartner',
                ADDITIONAL_PER: 'zzgl. Nebenkosten (%)',
                ADDITIONAL_EURO: 'zzgl. Nebenkosten (Euro)',
                EXTERNAL: 'Davon Fremdleistung (Euro)',
                TEAM: 'Team ',
                OW_NUMBER: 'OW Nummer',
                PROJECTNUMBER: 'Projekt-nummer',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                TOTAL_HOURS: 'gebuchter Zeitaufwand',
                WORK_IN_PROGRESS_COST: 'Arbeitsvorrat',
                EXPECTEDPROFITMARGIN: 'erwartete Gewinnmarge (in %)',
                WORKLIST: 'Arbeitsvorrat',
                WIP: 'WIP'
            }
        }
    }
};
