import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { locale as englishCopy } from './i18n-copy/en';
import { locale as germanCopy } from './i18n-copy/de';
import { FuseTranslationLoaderService, SnackbarService } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/main/services/application.service';
import { from, Observable, of } from 'rxjs';
import { DocumentsAssignmentService } from 'src/app/main/assignment/documents-assignment/documents-assignment.service';
import { SharePointService } from 'sp-office365-framework';
import { LoadingService } from 'src/app/main/services/loading.service';
import { ChangeDocumentName } from '../models';
import { DocumentsService } from '../../../services/documents.service';
import { ChangeDocumentStatus } from '../enum';
import { InjectHeaders } from '@pnp/queryable';

@Component({
    selector: 'app-change-document-name-dialog',
    templateUrl: './change-document-name-dialog.component.html',
    styleUrls: ['./change-document-name-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeDocumentNameDialogComponent implements OnInit {
    public documentId: number;
    public formio_renderOptions = {
        // language: 'de'
    };
    public refreshForm: EventEmitter<any> = new EventEmitter();
    public formioConfiguration;
    public oldFileName: string;
    public fileExtension: string;
    public filePath: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChangeDocumentName,
        public dialogRef: MatDialogRef<ChangeDocumentNameDialogComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _cdr: ChangeDetectorRef,
        private _applicationService: ApplicationService,
        private _documentsAssignmentService: DocumentsAssignmentService,
        private _sharePointService: SharePointService,
        private _snackBarService: SnackbarService,
        private _loadingService: LoadingService
    ) {
        if (this.data.mode === ChangeDocumentStatus.rename) {
            this._fuseTranslationLoaderService.loadTranslations(english, german);
        } else {
            this._fuseTranslationLoaderService.loadTranslations(englishCopy, germanCopy);
        }
    }

    ngOnInit(): void {
        this.initFilePathData();
        this.initForm();
    }

    async formioReady() {
        let dataForm;
        const emptyObj = {};
        this._applicationService.getEmptyObject(this.formioConfiguration.components, emptyObj);
        dataForm = this._applicationService.leftMerge(emptyObj, { ...this.data, fileName: this.oldFileName } || {});
        await this._applicationService.callBeforeLoadFunctions(this.formioConfiguration, dataForm);
        this.refreshForm.emit({ submission: { data: dataForm } });
    }

    public saveSubmission(event): Observable<any> {
        const fileNameSpecialCharacters = new RegExp('[\~\#\%\&\*\{\}\\\:\<\>\?\/\+\|]');

        if (fileNameSpecialCharacters.test(event.data.fileName)) {
            this._snackBarService.add(
                this._translateService.instant('CHANGE_DOCUMENT_DIALOG.NOTIFICATIONS.INVALID_CHARACTERS'),
                '',
                { duration: 4000, panelClass: 'error-dialog', }
            );
            return of(false);
        }

        return from(
            this._documentsAssignmentService.checkIfDocumentExists(this.filePath + event.data.fileName + `.${this.fileExtension}`)
                .then(async documentExists => {

                    try {
                        if (!documentExists) {
                            const fileRelativeUrl = this.filePath + this.oldFileName + `.${this.fileExtension}`;
                            const copyTo = this.filePath + event.data.fileName + `.${this.fileExtension}`;
                            if (this.data.mode === ChangeDocumentStatus.rename) {
                                await this._sharePointService.web
                                    .using(
                                        InjectHeaders({
                                            Accept: 'application/json;odata=fullmetadata',
                                            'Content-Type': 'application/json;charset=utf-8',
                                        })
                                    )
                                    .getFileByServerRelativePath(
                                        fileRelativeUrl
                                    )
                                    .moveByPath(copyTo, true);
                            } else {
                                await this._sharePointService.web
                                    .using(
                                        InjectHeaders({
                                            Accept: 'application/json;odata=fullmetadata',
                                            'Content-Type':
                                                'application/json;charset=utf-8',
                                        })
                                    )
                                    .getFileByServerRelativePath(
                                        fileRelativeUrl
                                    )
                                    .copyByPath(copyTo, true);
                            }

                            this.oldFileName = event.data.fileName;

                            this._snackBarService.add(
                                this._translateService.instant('CHANGE_DOCUMENT_DIALOG.NOTIFICATIONS.SUCCESS'),
                                '',
                                { duration: 4000, panelClass: 'success-dialog', }
                            );
                            this.dialogRef.close();
                        } else {
                            this._snackBarService.add(
                                this._translateService.instant('CHANGE_DOCUMENT_DIALOG.NOTIFICATIONS.DUPLICATE'),
                                '',
                                { duration: 4000, panelClass: 'error-dialog', }
                            );
                        }
                        this._loadingService.hide();
                    } catch (error) {
                        this._snackBarService.add(
                            this._translateService.instant('CHANGE_DOCUMENT_DIALOG.NOTIFICATIONS.ERROR'),
                            '',
                            { duration: 4000, panelClass: 'error-dialog', }
                        );
                    }
                })
        );
    }


    private initForm(): void {
        this.formioConfiguration = {
            components: [
                {
                    label: this._translateService.instant('CHANGE_DOCUMENT_DIALOG.FORM.TITLE'),
                    type: 'textfield',
                    key: 'fileName',
                    inputFormat: 'plain',
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true
                    }
                },
                {
                    label: this._translateService.instant('CHANGE_DOCUMENT_DIALOG.FORM.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }

    private initFilePathData(): void {
        const { fileExtension, filePath, oldFileName } = DocumentsService.initFilePathData(this.data.document);
        this.fileExtension = fileExtension;
        this.oldFileName = oldFileName;
        this.filePath = filePath;
    }

}
