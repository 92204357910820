<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span *ngIf="this.id" class="title dialog-title">{{ "EDIT" | translate }}</span>
    <span *ngIf="!this.id" class="title dialog-title">{{ "NEW" | translate }}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div id='content'>

  <formio class="formio-material" [refresh]="refreshForm" (ready)="formioReady($event)" [service]="this" [form]="formioConfiguration"
    [renderOptions]="formio_renderOptions"
    (change)="onChange($event)">
  </formio>

  <mat-card appearance="outlined" *ngIf="this.showScheduler" class="mat-card scheduler">

    <h2>{{ "TABS.SCHEDULER" | translate }}</h2>

    <daypilot-scheduler #scheduler [config]="schedulerConfig"></daypilot-scheduler>

  </mat-card>

</div>
