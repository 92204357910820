import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { LayoutModule, FuseSharedModule } from "sp-office365-layout";
import { InstallComponent } from "./install/install.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";

const routes = [
  {
    path: "install",
    component: InstallComponent
  },
  {
    path: "maintenance",
    component: MaintenanceComponent
  }
];

@NgModule({
  declarations: [InstallComponent, MaintenanceComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    LayoutModule
  ],
  exports: [InstallComponent, MaintenanceComponent]
})
export class InstallationModule {}
