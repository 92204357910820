import { NgModule } from '@angular/core';
import { PartiallyFinishedServicesComponent } from 'src/app/main/controlling/partially-finished-services/partially-finished-services.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

const routes = [
    {
        path: 'partially-finished-services',
        component: PartiallyFinishedServicesComponent,
    },
];

@NgModule({
    declarations: [PartiallyFinishedServicesComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
        MatSliderModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ExcelExportModule
    ],
    exports: [PartiallyFinishedServicesComponent]
})
export class PartiallyFinishedServicesModule {
}
