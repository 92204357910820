import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import {
    FrameworkService,
    fuseAnimations,
    FuseTranslationLoaderService,
    SnackbarService,
} from 'sp-office365-layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { SharePointService } from 'sp-office365-framework';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de'
    }
    
    public groupId;
    public formioConfiguration;
    public isCreateMode = true;
    public selectedUsers = [];

    public refreshForm: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddUserComponent>,
        private _sharepointService: SharePointService,
        private _frameworkService: FrameworkService,
        private _loadingService: LoadingService,
        private _snackbarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data.groupId) {
            this.groupId = this.data.groupId;

            this.initForm();
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._frameworkService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        dataForm = emptyObj;

        // Set default values

        const promises = [];

        promises.push(
            this._frameworkService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant('ADD_USER.NOTIFICATIONS.SAVING'),
            this._translateService.instant('ADD_USER.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._frameworkService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._frameworkService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        return from(
            new Promise<void>((resolve, reject) => {
                const promises = [];
                const addedUsers = [];

                // Add users to group
                this.selectedUsers.forEach((user) => {
                    promises.push(
                        new Promise<void>((resolve, reject) => {
                            this._sharepointService.web.siteGroups
                                .getById(this.groupId)
                                .users.add(user.LoginName)
                                .then(() => {
                                    addedUsers.push(user);

                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                    );
                });

                Promise.all(promises)
                    .then(() => {
                        this._loadingService.hide();

                        this._snackbarService.add(
                            this._translateService.instant(
                                'ADD_USER.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close({ Success: true, AddedUsers: addedUsers });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: this._translateService.instant('ADD_USER.FORM.USER'),
                    type: 'sharePointAutocomplete',
                    multi: true,
                    key: 'UserId',
                    isPeoplePicker: true,
                    useGraph: !window['useApp'],
                    removable: true,
                    validate: {
                        customMessage: '{{field}} ist ein Pflichtfeld',
                        required: true,
                    },
                    isCreateMode: this.isCreateMode,
                    hidden: false,
                    onSelected: (control, item) => {
                        this.selectedUsers.push(item);
                    },
                    onRemoved: (control, item) => {
                        this.selectedUsers.splice(
                            this.selectedUsers.findIndex(
                                (x) => x.Id === item.Id
                            ),
                            1
                        );
                    },
                },
                {
                    label: this._translateService.instant('ADD_USER.FORM.SAVE'),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };
    }
}
