import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';
import { splitArrayIntoChunks } from '../../../shared/shared.util';

export class UpdateToVersion1154Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const hoursList =
                await this._sharePointService.web.lists.getByTitle('Zeiten');
            const mailboxList =
                await this._sharePointService.web.lists.getByTitle('Mailbox');
            await this._updateService.addField(
                hoursList,
                'Old_TaskId',
                FieldType.Number
            );
            await this._updateService.addField(
                mailboxList,
                'Old_TaskId',
                FieldType.Number
            );
            await this.copyIdToOldId('Zeiten', 'AufgabeId');
            await this.copyIdToOldId('Mailbox', 'ArbeitspaketId');

            return;
        } catch (error) {
            throw new Error(error);
        }
    }

    private async copyIdToOldId(
        listTitle: string,
        fieldToCopy: string
    ): Promise<void> {
        // const list = await this._sharePointService.web.lists.getByTitle(listTitle);
        // const listTypeFullName = await list.getListItemEntityTypeFullName()
        // let items = await list.items.top(5000).getAll();
        // items = items.filter(assignment => !assignment.FileSystemObjectType);
        // console.warn(items);
        // const chunks = splitArrayIntoChunks(items, 1000);
        // for (let i = 0; i < chunks.length; i++) {
        //     let batch = this._sharePointService.web.createBatch();
        //     let batchItems = list.items.inBatch(batch);
        //     let chunkItems = chunks[i];
        //     for (let j = 0; j < chunkItems.length; j++) {
        //         let assignment = chunkItems[j];
        //         batchItems.getById(assignment.Id).inBatch(batch).update({ Old_TaskId: assignment[fieldToCopy] }, '*', listTypeFullName);
        //     }
        //     await batch.execute();
        // }
    }
}
