import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { fuseAnimations } from 'sp-office365-layout';
import { HighLight } from '../models/highlight.model';
import { SharePointService } from 'sp-office365-framework';

@Component({
    selector: 'app-highlight',
    templateUrl: './highlight.component.html',
    styleUrls: ['./highlight.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class HighlightComponent implements OnInit {
    public _configuration: any;
    @Input() set configuration(config: any) {
        this._configuration = config;
    }
    public ready = false;

    constructor(private sharePointService: SharePointService) {}

    ngOnInit() {
        this.loadData();
    }

    refresh(config) {
        this.ready = false;
        this._configuration = config;
        this.loadData();
    }

    loadData() {
        if (!this._configuration) {
            return;
        }

        const promises = [];

        this._configuration.forEach((highLight: HighLight) => {
            const spQuery: any = {
                title: highLight.Query.ListTitle,
                camlQuery: highLight.Query.CamlQuery,
                isDocumentLibrary: highLight.Query.isDocumentLibrary,
                viewFields: [{ internalName: 'ID' }],
            };
            if (highLight.Query.RecursiveAll) {
                spQuery.recursiveAll = highLight.Query.RecursiveAll;
            }
            if (highLight.Query.FolderName) {
                spQuery.folderName = highLight.Query.FolderName;
            }
            promises.push(this.sharePointService.getListItems(spQuery));
        });

        Promise.all(promises).then(
            (results) => {
                let count = 0;

                this._configuration.forEach((highLight: HighLight) => {
                    highLight.Count = results[count].length;
                    count++;
                });

                this.ready = true;
            },
            (error) => {
                console.error(error);
            }
        );
    }
}
