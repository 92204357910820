<div class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- APP TITLE -->

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-4" [@animate]="{
                      value: '*',
                      params: { delay: '50ms', scale: '0.2' }
                  }">file_copy
        </mat-icon>
        <span class="logo-text h1" [@animate]="{
                      value: '*',
                      params: { delay: '100ms', x: '-25px' }
                  }">
          {{ 'DOC_TEMPLATES.TITLE' | translate }}
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->

    <!-- SEARCH -->
    <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

    <div class="search-wrapper mt-4 mt-sm-0">

      <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>search</mat-icon>
        <input [formControl]="searchInput" placeholder="Search for a contact">
      </div>

    </div>

  </div> -->
    <!-- / SEARCH -->
  </div>
  <!-- / HEADER -->

  <div id="content">
    <div class="content">
      <div class="p-6">
        <mat-card appearance="outlined" class="mat-card">

          <div>

            <input type="file" style="display: none" [multiple]="true" [accept]="this.acceptedFileExtensions" (change)="
                                this._documentService.fileChangeEvent(
                                    $event,
                                    this.templateGrid,
                                    this.tableConfig.list)
                            " #fileInput />

            <app-sharepoint-table #templateGrid *ngIf="this.tableConfig" [configuration]="this.tableConfig"
              (onTooolbarButtonClick)="onTooolbarButtonClick($event)">

              <ng-template customCell for="Kategorie" let-dataItem="dataItem">

                <mat-form-field class="full-width">
                  <!-- <mat-label>Kategorie</mat-label> -->
                  <mat-select #categorySelect (selectionChange)="this.changeDocumentCategory($event, dataItem);"
                    [(value)]="dataItem.Kategorie">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{category}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </ng-template>

            </app-sharepoint-table>

          </div>

          <div class="createDocument">

            <h4>Dokument erstellen</h4>

            <mat-form-field class="custom-width">
              <mat-label>ElementIds</mat-label>
              <input matInput placeholder="Ids der Elemente" value="Aufträge#2;Auftragsaufgaben#5;" #elementIdInput>
              <mat-hint>Bitte tragen sie alle Ids der benötigten Elemente dieser Vorlage ein (Bsp.:
                'Aufträge#2;Auftragsaufgaben#5;')</mat-hint>
            </mat-form-field>

            <button mat-raised-button class="mat-accent" [disabled]="this.createButtonDisbaled"
              (click)="this.onButtonClick();">Dokument
              generieren</button>

          </div>

          <div class="mt-16">

            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>Platzhalter Arbeitspakete</b>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [innerHTML]="textPH1"></div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>Platzhalter Aufträge/Anfragen</b>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [innerHTML]="textPH2"></div>
            </mat-expansion-panel>

          </div>

        </mat-card>
      </div>
    </div>
  </div>
</div>