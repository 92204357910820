<app-sharepoint-table #taskGrid
                      *ngIf="_configuration"
                      class="assignment-tasks-table"
                      [configuration]="_configuration"
                      (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
>
    <ng-template customCell for="Auftraggeber" let-dataItem="dataItem">
        {{ dataItem.FieldValuesAsText.Auftraggeber }} {{ dataItem.FieldValuesAsText.Auftraggeber_x005f_x003a_x005f_Title2 }} {{ dataItem.FieldValuesAsText.Auftraggeber_x005f_x003a_x005f_Title3 }}
    </ng-template>
</app-sharepoint-table>
