export const locale = {
    lang: 'de',
    data: {
        TABLE_TASK: {
            NOTIFICATIONS: {
                WAIT: 'Bitte warten.',
                MISSINGPATH: 'Kein Pfad zum Fileserver hinterlegt',
                CONNECTING: 'Verbindung wird hergestellt',
                MISSINGSTART: 'Kein Startdatum eingetragen',
            },
        },
    },
};
