import { SharePointService, UpdateService } from 'sp-office365-framework';

export class UpdateToVersion1134Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Get List Auftragsdokumente
            const list =
                this._sharePointService.web.lists.getByTitle(
                    'Auftragsdokumente'
                );
            await list.fields
                .getByInternalNameOrTitle('Auftrag')
                .update({ Indexed: true });

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
