import { Component, EventEmitter, Inject, OnInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import {
    ColumnTyp,
    SharePointService,
    SharePointTableComponent,
    TableColumn,
    TableConfiguration,
} from 'sp-office365-framework';
import { fuseAnimations, FuseTranslationLoaderService, } from 'sp-office365-layout';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from '../../services/snackbar.service';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { from } from 'rxjs';
import * as CamlBuilder from 'camljs';
import { DetailContactComponent } from '../../contact/detail-contact/detail-contact.component';
import { ApplicationService } from '../../services/application.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, } from '@angular/material/dialog';
import { LandService } from 'src/app/main/services/land.service';
import { mergeMap } from 'rxjs/operators';
import { ClientUtil } from '../../shared/utils';

@Component({
    selector: 'app-detail-customer',
    templateUrl: './detail-customer.component.html',
    styleUrls: ['./detail-customer.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DetailCustomerComponent implements OnInit {
    public formio_renderOptions = {
        // language: 'de',
    };

    public id;
    public sharePointItem;
    public formioConfiguration;
    public isCreateMode = true;
    public tableConfigContacts: TableConfiguration;
    public columns: TableColumn[];
    public refreshForm: EventEmitter<any> = new EventEmitter();

    @ViewChild('contactGrid', { static: false })
    contactGrid: SharePointTableComponent;

    get camlQueryContacts() {
        return new CamlBuilder()
            .Where()
            .LookupField('Kunde')
            .Id()
            .EqualTo(this.id)
            .OrderByDesc('Modified')
            .ToString();
    }

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DetailCustomerComponent>,
        private _sharepointService: SharePointService,
        private _applicationService: ApplicationService,
        private _loadingService: LoadingService,
        private _snackbarService: SnackbarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private landService: LandService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit() {
        if (this.data.id) {
            this.id = this.data.id;
        }

        if (this.id) {
            this.isCreateMode = false;

            this._sharepointService
                .getItemById({
                    id: this.id,
                    listTitle: 'Firmen',
                })
                .then((customer) => {
                    this.sharePointItem = customer;

                    this.initForm();
                })
                .catch((error) => console.error(error));
        } else {
            this.landService.getDefaultLand()
                .pipe(
                    mergeMap(land => {
                        let landPayload = {};

                        if (land) {
                            landPayload = { LandId: land.Id };
                        }

                        return this._sharepointService
                            .addItem({
                                listTitle: 'Firmen',
                                data: { Title: 'Neuer Kunde', ...landPayload },
                                folder: ''
                            });
                    })
                )
                .subscribe(id => {
                    this.id = id;
                    this.ngOnInit();
                });
        }
    }

    formioReady(ev) {
        let dataForm;
        const emptyObj: any = {};
        this._applicationService.getEmptyObject(
            this.formioConfiguration.components,
            emptyObj
        );

        if (this.sharePointItem) {
            dataForm = this._applicationService.leftMerge(
                emptyObj,
                this.sharePointItem || {}
            );
        } else {
            dataForm = emptyObj;
            // Set default values
        }

        const promises = [];

        promises.push(
            this._applicationService.callBeforeLoadFunctions(
                this.formioConfiguration,
                dataForm
            )
        );

        Promise.all(promises)
            .then(() =>
                this.refreshForm.emit({
                    submission: {
                        data: dataForm,
                    },
                })
            )
            .catch((error) => {
                console.log(error);
            });
    }

    saveSubmission(e) {
        this._loadingService.show(
            this._translateService.instant(
                'DETAIL_CUSTOMER.NOTIFICATIONS.SAVING'
            ),
            this._translateService.instant('DETAIL_CUSTOMER.NOTIFICATIONS.WAIT')
        );

        delete e.data.submit;

        this._applicationService.cleanDataForSaving(
            e.data,
            this.formioConfiguration.components
        );

        this._applicationService.callBeforeSaveFunctions(
            this.formioConfiguration,
            e.data
        );

        return from(
            new Promise<void>((resolve, reject) => {
                const promises = [];

                e.data.Firmenbriefkopf = ClientUtil.getClientSummary(e.data);

                if (this.id) {
                    promises.push(
                        this._sharepointService.updateItem({
                            id: this.id,
                            listTitle: 'Firmen',
                            newFiledValues: e.data,
                        })
                    );
                } else {
                    promises.push(
                        this._sharepointService.addItem({
                            listTitle: 'Firmen',
                            data: e.data,
                            folder: '',
                        })
                    );
                }

                Promise.all(promises)
                    .then((result) => {
                        this._loadingService.hide();

                        if (!this.id && result) {
                            this.id = +result;
                        }

                        this._snackbarService.add(
                            this._translateService.instant(
                                'DETAIL_CUSTOMER.NOTIFICATIONS.SUCCESS'
                            ),
                            '',
                            {
                                duration: 4000,
                                panelClass: 'success-dialog',
                            }
                        );

                        this.close({ Success: true, ItemId: this.id });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );
    }

    close(message?): void {
        this.dialogRef.close(message);
    }

    onTabChange(e) {
        this.contactGrid.refresh();
    }

    onTooolbarButtonClick(e) {
        switch (e.command) {
            case 'Add':
                this.openDialog();
                break;
            case 'Edit':
                this.openDialog(e.dataItem.Id);
                break;
        }
    }

    openDialog(contactId?: number): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            customer: this.sharePointItem,
        };

        if (contactId) {
            dialogConfig.data.contactId = contactId;
        }

        const dialogRef = this.dialog.open(
            DetailContactComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            this.contactGrid.refresh();
        });
    }

    initForm() {
        this.formioConfiguration = {
            useMaterialDesign: true,
            components: [
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CUSTOMER.FORM.TITLE1'
                                    ),
                                    type: 'textfield',
                                    key: 'Title',
                                    inputFormat: 'plain',
                                    validate: {
                                        customMessage:
                                            '{{field}} ist ein Pflichtfeld',
                                        required: true,
                                    },
                                },
                            ],
                            width: 4,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CUSTOMER.FORM.TITLE2'
                                    ),
                                    type: 'textfield',
                                    key: 'Title2',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 4,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CUSTOMER.FORM.TITLE3'
                                    ),
                                    type: 'textfield',
                                    key: 'Title3',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 4,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columnsName',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CUSTOMER.FORM.STREET'
                    ),
                    type: 'textfield',
                    key: 'Strasse',
                    inputFormat: 'plain',
                },
                {
                    label: 'Columns',
                    columns: [
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CUSTOMER.FORM.ZIP'
                                    ),
                                    type: 'textfield',
                                    key: 'WorkZip',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column1',
                        },
                        {
                            components: [
                                {
                                    label: this._translateService.instant(
                                        'DETAIL_CUSTOMER.FORM.CITY'
                                    ),
                                    type: 'textfield',
                                    key: 'WorkCity',
                                    inputFormat: 'plain',
                                },
                            ],
                            width: 6,
                            type: 'column',
                            hideOnChildrenHidden: false,
                            key: 'column2',
                        },
                    ],
                    mask: false,
                    tableView: false,
                    alwaysEnabled: false,
                    type: 'columns',
                    input: false,
                    key: 'columns2',
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CUSTOMER.FORM.LOCATION'
                    ),
                    type: 'textfield',
                    key: 'Kunden_Niederlassung',
                    selectThreshold: 0.3,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CUSTOMER.FORM.EMAIL'
                    ),
                    type: 'textfield',
                    key: 'Email',
                    selectThreshold: 0.3,
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CUSTOMER.FORM.LAND'
                    ),
                    type: 'sharePointAutocomplete',
                    key: 'LandId',
                    list: 'Länder',
                    searchQueryIfIsEmpty: new CamlBuilder()
                        .Where()
                        .NumberField('ID')
                        .NotEqualTo(-1)
                        .OrderBy('Title')
                        .ToString(),
                    isPeoplePicker: false,
                    removable: true
                },
                {
                    label: this._translateService.instant(
                        'DETAIL_CUSTOMER.FORM.SAVE'
                    ),
                    size: 'small',
                    type: 'button',
                    key: 'submit',
                    action: 'submit',
                },
            ],
        };

        this.columns = [
            {
                internalName: 'FirstName',
                title: this._translateService.instant(
                    'DETAIL_CUSTOMER.CONTACTS.COLUMNS.FIRSTNAME'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Title',
                title: this._translateService.instant(
                    'DETAIL_CUSTOMER.CONTACTS.COLUMNS.TITLE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'Email',
                title: this._translateService.instant(
                    'DETAIL_CUSTOMER.CONTACTS.COLUMNS.EMAIL'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'WorkPhone',
                title: this._translateService.instant(
                    'DETAIL_CUSTOMER.CONTACTS.COLUMNS.WORKPHONE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        if (this.id) {
            this.tableConfigContacts = {
                list: 'Kontakte',
                columns: this.columns,
                isDocumentLibrary: false,
                showEditButton: true,
                showDeleteButton: true,
                recursiveAll: true,
                camlQuery: this.camlQueryContacts,
                folderName: '',
                useMaterialDesign: true,
                toolbar: [
                    {
                        title: this._translateService.instant(
                            'DETAIL_CUSTOMER.CONTACTS.NEW'
                        ),
                        command: 'Add',
                        color: 'accent',
                    },
                ],
                afterRefreshTable: () => {
                    //
                },
            };
        }
    }
}
