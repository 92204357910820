import { ColumnTyp, FormatTypes, SharePointService, TableColumn, TableConfiguration } from 'sp-office365-framework';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as CamlBuilder from 'camljs';
import moment from 'moment';
import { fuseAnimations, FuseTranslationLoaderService, ListViewConfig, TableConfig, } from 'sp-office365-layout';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { TaskService } from '../../services/task.service';
import { DetailTaskComponent } from '../detail-task/detail-task.component';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';

@Component({
    selector: 'app-listview-workpackage',
    templateUrl: './listview-workpackage.component.html',
    styleUrls: ['./listview-workpackage.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ListviewWorkpackageComponent implements AfterViewInit, OnDestroy {
    @ViewChild('taskGrid', { static: false }) taskGrid: ListViewComponent;

    public confirmDialogRef: MatDialogRef<DeleteConfirmationComponent>;
    public refreshForm: EventEmitter<any> = new EventEmitter();
    public config: ListViewConfig;
    public packageId: number;
    public viewConfigs: TableConfig[];
    public columns: TableColumn[];
    public subTableConfig: TableConfiguration;
    public subTableColumns: TableColumn[];
    public queries = {};
    public searchQuery = '';
    public currentConfig: { index: number, default: string, search: () => string, defaultFilter?: (item) => boolean, searchFilter?: (item) => boolean } ;
    private destroy$ = new Subject();

    constructor(
        public dialog: MatDialog,
        private _sharePointService: SharePointService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _taskService: TaskService,
        private _cdr: ChangeDetectorRef
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);

        this.initConfig();

        this.queries = {
            [this._translateService.instant('LV_WP.ACTIVE')]: {
                index: 0,
                default: this.activeWPCamlQuery,
                search: () => this.getActiveWPSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt',
                searchFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            },
            [this._translateService.instant('LV_WP.OVERDUEDATE')]: {
                index: 1,
                default: this.activeOverDueWPCamlQuery,
                search: () => this.getActiveOverDueWPSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && moment(item.DueDate).isSameOrBefore(new Date()),
                searchFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket' && item.Status !== 'Abgeschlossen' && item.Status !== 'Zurückgestellt' && moment(item.DueDate).isSameOrBefore(new Date()) && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            },
            [this._translateService.instant('LV_WP.ALL')]: {
                index: 2,
                default: this.allWPCamlQuery,
                search: () => this.getAllWPSearchCamlQuery(),
                defaultFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket',
                searchFilter: (item) => !!item.AuftragId && !!item.Auftrag && item.Aufgabenart === 'Arbeitspaket' && item.Title.toLowerCase().includes(this.searchQuery.toLowerCase()),
            }
        }
    }

    ngAfterViewInit(): void {
        this.taskGrid._listViewService.onSearchTextChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe(async searchText => {
                this.searchQuery = searchText;
                const query: string = searchText ? this.currentConfig.search() : this.currentConfig.default;
                const filter = searchText ? this.currentConfig.searchFilter : this.currentConfig.defaultFilter;
                await this.loadTasks(this.currentConfig.index, query, filter);
            });

        this.taskGrid?._listViewService?.onConfigChanged
            .pipe(takeUntil(this.destroy$))
            .subscribe(async data => {
                this.searchQuery = '';
                const config = this.queries[data.title];
                this.currentConfig = config;
                await this.loadTasks(config.index, config.default, config.defaultFilter);
            });
    }

    get activeWPCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .ToString();
    }

    private getActiveWPSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    get activeOverDueWPCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .ToString();
    }

    private getActiveOverDueWPSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Abgeschlossen')
            .And()
            .ChoiceField('Status')
            .NotEqualTo('Zurückgestellt')
            .And()
            .DateTimeField('DueDate')
            .LessThanOrEqualTo(new Date())
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    get allWPCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .ToString();
    }

    private getAllWPSearchCamlQuery() {
        return new CamlBuilder()
            .Where()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .And()
            .ChoiceField('Aufgabenart')
            .EqualTo('Arbeitspaket')
            .And()
            .TextField('Title')
            .Contains(this.searchQuery)
            .ToString();
    }

    get allTasksFromWPCamlQuery() {
        let workpackageExpression =
            '<Eq><FieldRef Name="Arbeitspaket" LookupId="True" /><Value Type="Integer">{{ParentItemId}}</Value></Eq>';

        let noFolderExpression = CamlBuilder.Expression()
            .NumberField('FSObjType')
            .EqualTo(0)
            .ToString();

        let onlyTasksExpression = CamlBuilder.Expression()
            .ChoiceField('Aufgabenart')
            .EqualTo('Aufgabe')
            .ToString();

        let projectRequiredExpression = CamlBuilder.Expression()
            .LookupField('Auftrag')
            .Id()
            .IsNotNull()
            .ToString();

        return (
            '<Where><And><And><And>' +
            workpackageExpression +
            noFolderExpression +
            '</And>' +
            projectRequiredExpression +
            '</And>' +
            onlyTasksExpression +
            '</And>' +
            '</Where>' +
            '<OrderBy><FieldRef Name="Modified" Ascending="False" /></OrderBy>'
        );
    }

    openDialog(item, isTask): void {
        const self = this;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = window.innerWidth * 0.75 + 'px';
        dialogConfig.panelClass = 'dialog-without-padding';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        dialogConfig.data = {
            auftragsId: item.AuftragId,
            isTask,
            taskId: item.Id,
            arbeitsPaketId: item.ArbeitspaketId,
        };

        if (!isTask && !item.Id) {
            // Collapse subtables on adding new wp
            self.taskGrid.listComponent.sharePointTableComponent.collapseAll();
        }

        const dialogRef = this.dialog.open(DetailTaskComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            // Refresh table
            self.taskGrid._listViewService.onListViewDataChanged.next(
                self.taskGrid._listViewService.listView
            );
            const query: string = this.searchQuery ? this.currentConfig.search() : this.currentConfig.default;
            const filter = this.searchQuery ? this.currentConfig.searchFilter : this.currentConfig.defaultFilter;
            this.loadTasks(this.currentConfig.index, query, filter);
            // Refresh subtables
            self.taskGrid.listComponent.sharePointTableComponent.refreshSubtables();
        });
    }

    initConfig() {
        this.subTableColumns = [
            {
                internalName: 'Title',
                title: this._translateService.instant('LV_WP.COLUMNS.TITLE'),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('LV_WP.COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('LV_WP.COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Prozessschritt',
                title: this._translateService.instant(
                    'LV_WP.COLUMNS.PROCESSSTEP'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftrag',
                title: this._translateService.instant('LV_WP.COLUMNS.PROJECT'),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant(
                    'LV_WP.COLUMNS.WORKPACKAGE'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.subTableConfig = {
            list: 'Auftragsaufgaben',
            columns: this.subTableColumns,
            showEditButton: true,
            showDeleteButton: true,
            addCheckboxColumn: false,
            recursiveAll: true,
            // folderName: this.id + '',
            useMaterialDesign: true,
            toolbar: [],
            isDocumentLibrary: false,
            camlQuery: this.allTasksFromWPCamlQuery,
            onEditButtonClick: (item) => {
                this.openDialog(item, true);
            },
            afterDeleteItem: (item) => {
                this._taskService.afterDeleteItem(item);
            },
            parentItemLookupName: 'Arbeitspaket',
        };

        this.columns = [
            {
                internalName: 'Title',
                title: this._translateService.instant(
                    'LV_WP.COLUMNS.WORKPACKAGE'
                ),
                hidden: false,
                type: ColumnTyp.Text,
            },
            {
                internalName: 'DueDate',
                title: this._translateService.instant('LV_WP.COLUMNS.DUEDATE'),
                hidden: false,
                type: ColumnTyp.Date,
                format: FormatTypes.yyyyMMdd,
            },
            {
                internalName: 'AssignedTo',
                title: this._translateService.instant('LV_WP.COLUMNS.ASSIGNED'),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Prozessschritt',
                title: this._translateService.instant(
                    'LV_WP.COLUMNS.PROCESSSTEP'
                ),
                hidden: false,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftrag',
                title: this._translateService.instant('LV_WP.COLUMNS.PROJECT'),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Arbeitspaket',
                title: this._translateService.instant(
                    'LV_WP.COLUMNS.WORKPACKAGE'
                ),
                hidden: true,
                type: ColumnTyp.Lookup,
            },
            {
                internalName: 'Auftraggeber',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: false,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title2',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            {
                internalName: 'Auftraggeber_x003a_Title3',
                title: this._translateService.instant('LV_WP.COLUMNS.ANSPRECHPARTNER'),
                hidden: true,
                type: ColumnTyp.Lookup
            },
            { internalName: 'ID', title: 'ID', hidden: true },
        ];

        this.viewConfigs = [
            {
                title: this._translateService.instant('LV_WP.ACTIVE'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Auftrag' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: [],
                    configurationSubtable: this.subTableConfig,
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
            {
                title: this._translateService.instant('LV_WP.OVERDUEDATE'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Auftrag' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: [],
                    configurationSubtable: this.subTableConfig,
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
            {
                title: this._translateService.instant('LV_WP.ALL'),
                config: {
                    list: 'Auftragsaufgaben',
                    groups: [{ field: 'Auftrag' }],
                    pageSize: 10,
                    columns: this.columns,
                    isDocumentLibrary: false,
                    showEditButton: false,
                    showDeleteButton: false,
                    addCheckboxColumn: true,
                    recursiveAll: true,
                    useMaterialDesign: true,
                    data: [],
                    configurationSubtable: this.subTableConfig,
                },
                camlQueryForSearch: 'query',
                afterDeleteItem: (item) => {
                    this._taskService.afterDeleteItem(item);
                },
            },
        ];

        this.config = {
            title: this._translateService.instant('LV_WP.TITLE'),
            searchLabel: this._translateService.instant('LV_WP.SEARCH_LABEL'),
            icon: 'assignment_turned_in',
            add_icon: 'add',
            openEditInDialog: true,
            onRowClick: (item) => {
                this.openDialog(item, false);
            },
            tableConfigs: this.viewConfigs,
        };
    }

    async loadTasks(configIndex: number, query: string, filter: (item) => boolean) {
        if (this.taskGrid) {
            this.taskGrid.listComponent.sharePointTableComponent.loading = true;
            this._cdr.detectChanges();
        }

        // const tasks = await this._taskService.loadTasksByQuery(query);
        const tasks = await this._taskService.loadAllTasks(filter);
        this.viewConfigs[configIndex].config.data = tasks;
        this.taskGrid.listComponent.sharePointTableComponent.refresh();
        return tasks;
    }

    ngOnDestroy(): void {
        this.destroy$.next(undefined);
    }
}
