import { FilterBillsComponent } from '../../bills/filter-bills/filter-bills.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableControllingComponent } from './table-controlling.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { FormioModule } from '@formio/angular';
import { AngularFrameworkModule } from 'sp-office365-framework';

@NgModule({
    declarations: [TableControllingComponent],
    imports: [
        CommonModule,
        AngularFrameworkModule,
        TranslateModule,
        MatTabsModule,
        FormioModule,
        MatSnackBarModule,
        MatToolbarModule,
    ],
    exports: [TableControllingComponent]
})
export class TableControllingModule {}
