export const locale = {
    lang: 'de',
    data: {
        NAV: {
            HEADER: '',
            START: {
                TITLE: 'Start',
                BADGE: '',
            },
            SCHEDULER: {
                TITLE: 'Zeitplan',
                BADGE: '',
            },
            NEW: {
                TITLE: 'Neues Projekt',
                BADGE: '',
            },
            NEWORDER: {
                TITLE: 'Neuer Auftrag',
                BADGE: '',
            },
            NEWREQUEST: {
                TITLE: 'Neue Anfrage',
                BADGE: '',
            },
            CURRENTUSER: {
                TITLE: 'Mein Bereich',
                BADGE: '',
            },
            ASSIGNMENTS: {
                TITLE: 'Projekte',
                BADGE: '',
            },
            ORDERS: {
                TITLE: 'Aufträge',
                BADGE: '',
            },
            WORKPACKAGES: {
                TITLE: 'Arbeitspakete',
                BADGE: '',
            },
            TASKS: {
                TITLE: 'Aufgaben',
                BADGE: '',
            },
            TIMES: {
                TITLE: 'Zeiten',
                BADGE: '',
            },
            MYTIMES: {
                TITLE: 'Meine Zeiten',
                BADGE: '',
            },
            ALLTIMES: {
                TITLE: 'Alle Zeiten',
                BADGE: '',
            },
            REQUEST: {
                TITLE: 'Anfragen',
                BADGE: '',
            },
            REPORTS: {
                TITLE: 'Reports',
                BADGE: '',
            },
            BILLS: {
                TITLE: 'Rechnungen',
                BADGE: '',
            },
            TARGET: {
                TITLE: 'Soll Zahlen',
                BADGE: '',
            },
            CONTROLLING: {
                TITLE: 'Controlling',
                BADGE: '',
            },
            EVALUATION: {
                TITLE: 'Auswertung',
                BADGE: '',
            },
            EMPLOYEEANALYSIS: {
                TITLE: 'Personenauswertung',
                BADGE: '',
            },
            PARTIALLY_FINISHED_SERVICES: {
                TITLE: 'Teilfertigeleistungen',
                BADGE: '',
            },
            CUSTOMERS: {
                TITLE: 'Kunden',
                BADGE: '',
            },
            ADMIN: {
                TITLE: 'Administration',
                BADGE: '',
            },
            UPDATE: {
                TITLE: 'Update',
                BADGE: '1',
            },
            CONFIGURATION: {
                TITLE: 'Konfiguration',
                BADGE: '',
            },
            TEMPLATES: {
                TITLE: 'Vorlagen',
                BADGE: '',
            },
            GROUPS: {
                TITLE: 'Gruppen',
                BADGE: '',
            },
            SITECONTENT: {
                TITLE: 'Websiteinhalte',
                BADGE: '',
            },
            PROJECT_EVALUATION: {
                TITLE: 'Projektliste',
                BADGE: '',
            },
        },
    },
};
