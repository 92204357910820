import { ChartService } from './../../services/chart.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-evaluation-chart',
    templateUrl: './evaluation-chart.component.html',
    styleUrls: ['./evaluation-chart.component.scss'],
})
export class EvaluationChartComponent implements OnInit, OnDestroy {
    constructor(public chartService: ChartService) {}

    ngOnInit(): void {}

    ngOnDestroy() {
        this.chartService.chartOptions = {
            series: [
                {
                    name: '',
                    data: [],
                    type: 'line',
                    color: 'RGB(55,2,128)',
                },
                {
                    name: '',
                    data: [],
                    type: 'line',
                    color: 'RGB(252, 40, 3)',
                },
            ],
            chart: {
                height: 350,
                //type: "line",
            },
            title: {
                align: 'center',
            },
            xaxis: {
                categories: [],
            },
        };
    }
}
