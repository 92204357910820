<div class="page-layout carded left-sidebar tabbed inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- SIDEBAR -->
    <fuse-sidebar
        class="sidebar"
        name="carded-left-sidebar-tabbed-2"
        position="left"
        lockedOpen="gt-md"
    >
        <!-- SIDEBAR HEADER -->
        <div class="header p-6 accent">
            <h2 class="m-0"></h2>
        </div>
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div
            class="content"
            fusePerfectScrollbar
            [@animate]="{ value: '*', params: { y: '50px' } }"
        >
            <div class="nav material2">
                <div class="nav-subheader"></div>

                <div
                    *ngFor="let item of this._applicationService.sideBarConfig"
                    class="nav-item"
                >
                    <a
                        class="nav-link"
                        matRipple
                        [routerLink]="item.Link"
                        [routerLinkActive]="['active', 'accent']"
                        queryParamsHandling="merge"
                        (click)="onNavChange()"
                    >
                        <mat-icon class="nav-link-icon">{{
                            item.Icon
                        }}</mat-icon>
                        <span>{{ item.Text }}</span>
                    </a>
                </div>
            </div>
        </div>
        <!-- / SIDEBAR CONTENT -->
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT HEADER -->
        <div
            class="header accent p-6"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div fxLayout="column" fxLayoutAlign="center start">
                <div class="h2 mt-4">
                    <mat-icon
                        class="logo-icon mr-4"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                        >assignment
                    </mat-icon>
                    <span
                        class="logo-text h1"
                        [@animate]="{
                            value: '*',
                            params: { delay: '100ms', x: '-25px' }
                        }"
                    >
                        {{ this.itemTitle }}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="secondary-text s-18">arrow_right</mat-icon>
                    <span class="secondary-text">{{
                        'TIMEASSIGNMENT.PROJECTS' | translate
                    }}</span>
                    <mat-icon class="secondary-text s-16"
                        >chevron_right</mat-icon
                    >
                    <span class="secondary-text">{{
                        'TIMEASSIGNMENT.TIMES' | translate
                    }}</span>
                </div>
            </div>

            <!-- SEARCH -->
            <!-- <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

              <div class="search-wrapper mt-4 mt-sm-0">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <input [formControl]="searchInput" placeholder="Search for a contact">
                </div>

              </div>

            </div> -->
            <!-- / SEARCH -->
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <!-- CONTENT TOOLBAR -->
            <div class="toolbar px-24 py-8">
                <button mat-icon-button class="sidebar-toggle" fxHide.gt-md>
                    <mat-icon>menu</mat-icon>
                </button>

                <div class="header">{{ this.header }}</div>
            </div>
            <!-- / CONTENT TOOLBAR -->

            <!-- CONTENT -->
            <div class="content card-content">
                <app-new-time
                    #newTimeComponent
                    *ngIf="this.sharePointItem"
                    [projectItem]="this.sharePointItem"
                    [editTask]="editTask"
                ></app-new-time>

                <mat-tab-group>
                    <mat-tab label="Liste">
                        <ng-template matTabContent>

                            <app-sharepoint-table
                                #timeGrid
                                *ngIf="this.timeConfig"
                                class="assignment-times-table"
                                [configuration]="this.timeConfig"
                                (onTooolbarButtonClick)="onTooolbarButtonClick($event)"
                                (cellClick)="openDialog($event, true)">
                                <ng-template
                                    customCell
                                    for="Minuten"
                                    let-dataItem="dataItem">
                                    {{ (dataItem.Minuten / 60).toFixed(2).toString().replace(".",',') }}
                                </ng-template>
                            </app-sharepoint-table>

                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Tabelle">
                        <ng-template matTabContent>

                            <formio class="formio-material" [service]="this" [form]="formioFilterConfiguration"
                                [renderOptions]="formio_renderOptions"
                                (change)="onFormioFilterChange($event)">
                            </formio>

                            <div class="hideColumnMenu">
                                <app-sharepoint-table
                                    #monthGrid
                                    class="month-table"
                                    *ngIf="this.monthConfig"
                                    [configuration]="this.monthConfig"
                                >
                                    <ng-template customCell [for]="'total'" let-dataItem="dataItem">
                                        {{dataItem.total | number : '1.0-2'}}
                                    </ng-template>
                                    <ng-container *ngFor="let column of monthGrid.activeColumns;let index = index;">
                                        <ng-container *ngIf="!column.hidden">
                                            <ng-template customCell [for]="column.internalName" let-dataItem="dataItem">
                                                <div [class.day-off]="dataItem.holidays[index - 1]"
                                                     [class.weekend]="weekends[index - 1]"
                                                >
                                                    {{dataItem[column.internalName] | number : '1.0-2'}}
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </app-sharepoint-table>
                            </div>

                        </ng-template>
                    </mat-tab>
                  </mat-tab-group>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
