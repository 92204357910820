import { NgModule } from '@angular/core';
import { ListviewAssignmentComponent } from './listview-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, LayoutModule } from 'sp-office365-layout';
import { AngularFrameworkModule } from 'sp-office365-framework';

const routes = [
    {
        path: 'projects',
        component: ListviewAssignmentComponent,
    },
];

@NgModule({
    declarations: [ListviewAssignmentComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        AngularFrameworkModule,
    ],
    exports: [ListviewAssignmentComponent],
})
export class ListviewAssignmentModule {}
