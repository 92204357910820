export const locale = {
    lang: 'de',
    data: {
        DETAIL_INVOICE: {
            TITLE: 'Rechnungen',
            SEARCH_LABEL: 'Nach einer Rechnung suchen',
            ALL: 'Alle Rechnungen',
            //MY: 'Meine Rechnungen',
            //CRITICAL: 'Kritische Aufgaben',
            //OVERDUEDATE: 'Überfällige Aufgaben',
            ISNETTO: 'Netto/Brutto Eingabe',
            COLUMNS: {
                TITLE: 'Titel',
                YEAR: 'Jahr',
                MONTH: 'Monat',
                CLAIM: 'Forderung (Netto)',
                CLAIMGROSS: 'Forderung (Brutto)',
                PAID: 'Gezahlt (Netto)',
                PAIDGROSS: 'Gezahlt (Brutto)',
                OUTSTANDING: 'Offen (Netto)',
                OUTSTANDINGGROSS: 'Offen (Brutto)',
                ADDRESS: 'Anschrift',
                TEMPLATE: 'Rechnungsvorlage',
                PROJECTID: 'Projekt',
                VON: 'Von',
                BIS: 'Bis',
                FRIST: 'Frist',
                ORDERDATE: 'Auftragsdatum',
                INVOICETYPE: 'Abrechnungstyp',
                BILL_NUMBER: 'Rechnungsnummer',
                NOTIZEN: 'Notizen',
                RECHNUNGSDATUM: 'Rechnungsdatum'
                /*
                DUEDATE: 'Fälligkeitsdatum',
                STATUS: 'Status',
                TASK: 'Aufgabe',
                ASSIGNED: 'Zugewiesen an',
                PROJECT: 'Projekt',
                DESCRIPTION: 'Beschreibung',
                KIND: 'Aufgabenart',
                WORKPACKAGE: 'Arbeitspaket',
                START: 'Start',
                PRIORITY: 'Priorität',
                HIGH: '(1) Hoch',
                NORMAL: '(2) Normal',
                LOW: '(3) Niedrig',
                NOTSTARTED: 'Nicht begonnen',
                INPROCESSING: 'In Bearbeitung',
                COMPLETED: 'Abgeschlossen',
                RESET: 'Zurückgestellt',
                WAITING: 'Wartet auf jemand anderen',
                TYPE: 'Aufgabentyp',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                PROCESSSTEP: 'Prozessschritt',
                */
            },
            FORM: {
                INEURO: '(in Euro)',
                MONTH: {
                    JAN: 'Jan',
                    FEB: 'Feb',
                    MAR: 'Mar',
                    APR: 'Apr',
                    MAY: 'May',
                    JUN: 'Jun',
                    JUL: 'Jul',
                    AUG: 'Aug',
                    SEP: 'Sep',
                    OCT: 'Oct',
                    NOV: 'Nov',
                    DEC: 'Dec',
                }
            },
            TABS: {
                BASE: 'Stammdaten',
                TASKS: 'Aufgaben',
                DOCS: 'Dokumente',
                TIMES: 'Zeiten',
            },
            //NEWTASK: 'Neue Rechnung',
            //EDITTASK: 'Rechnung bearbeiten',
            NEW: 'Neue Rechnung',
            EDIT: 'Rechnung bearbeiten',
            SAVE: 'Speichern',
            //TASKSETS: 'Aufgabensets',
            GIVEUSERPERMISSIONTOPROJECT:
                // tslint:disable-next-line: max-line-length
                'Der ausgewählte User besitzt keine Berechtigungen auf das aktuelle Projekt. Sollen diesem Bearbeitungsberechtigungen gegeben werden?',
            NOTIFICATIONS: {
                SAVINGITEM: 'Rechnung wird gespeichert...',
                SAVING: 'Berechtigungen werden gesetzt...',
                WAIT: 'Bitte warten.',
                SUCCESS: 'Berechtigungen erfolgreich gespeichert.',
                SUCCESSITEM: 'Rechnung erfolgreich gespeichert.',
                ERROR: 'Fehler beim setzen der Berechtigungen: ',
                DUEDATEERROR:
                    'Das Fälligkeitsdatum darf das Enddatum des Projekts nicht überschreiten.',
            },
            DOCS: {
                TITLE: 'Aufgabendokumente',
                NEW: 'Dokument hochladen',
                NEWFOLDER: 'Neuer Ordner',
                COLUMNS: {
                    TITLE: 'Titel',
                    FILESIZE: 'Dateigröße',
                    AUTHOR: 'Ersteller',
                    MODIFIED: 'Geändert',
                },
            },
        },
    },
};
