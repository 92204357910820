<listview #groupGrid *ngIf="config" [config]="config"
  class="page-layout simple left-sidebar inner-sidebar inner-scroll">

  <ng-template customHeader>

    <div class="hint">
      {{ this._translateService.instant('LV_GROUP.NOTIFICATIONS.HINT') }}
    </div>

  </ng-template>


</listview>
