import {
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1103Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {

        const teamsList = this._sharePointService.web.lists.getByTitle('Teams');
        const locationList = this._sharePointService.web.lists.getByTitle('Standorte');

        const promise = new Promise<void>(async (resolve, reject) => {

            let promises = [
                await this._updateService.addLookupField(
                    teamsList,
                    locationList,
                    'Standort',
                    false,
                    'Title'
                ),
            ]

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
