export const ASSIGNMENT_TIME_MONTH_FILTER = [
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.ALL',
        value: 0,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.1',
        value: 1,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.2',
        value: 2,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.3',
        value: 3,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.4',
        value: 4,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.5',
        value: 5,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.6',
        value: 6,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.7',
        value: 7,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.8',
        value: 8,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.9',
        value: 9,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.10',
        value: 10,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.11',
        value: 11,
    },
    {
        label: 'TIMEASSIGNMENT.FILTER.MONTHS.12',
        value: 12,
    },
];
