export const locale = {
    lang: 'de',
    data: {
        LV_ASSIGNMENT: {
            TITLE: 'Projekte',
            SEARCH_LABEL: 'Nach einem Projekt suchen',
            ALL: 'Alle',
            CRITICAL: 'Kritisch',
            TEAMS: 'Offen (Teams)',
            CURRENT: 'Aktiv',
            CLOSED: 'Abgeschlossen',
            NOTIFICATIONS: {
                SUCCESS_DELETE:
                    'Alle ausgewählten Projekte wurden erfolgreich gelöscht.',
                ERROR_DELETE: 'Beim Löschvorgang ist ein Fehler aufgetreten!',
                DELETING: 'Ausgewählte Projekte werden gelöscht.',
                WAIT: 'Bitte Warten',
            },
            COLUMNS: {
                NAME: 'Projekt',
                MARK: 'Kennzeichen',
                CRITICAL: 'Kritisch',
                UNCRITICALLY: 'Unkritisch',
                STATUS: 'Status',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                START: 'Start',
                END: 'Fertigstellung',
                BASE: 'Stammdaten',
                OW_NUMBER: 'OW-Nummer',
                DESCRIPTION: 'Beschreibung',
                CATEGORY: 'Kategorie',
                BUDGET: 'Budget',
                INEURO: '(in Euro)',
                INHOURS: '(in Stunden)',
                INDAYS: '(in Tagen)',
                USER: 'Rollen',
                MANAGEMENT: 'Leitung',
                PROJECTNUMBER: 'Projektnummer',
                PPL: 'PPL',
                CONTACTPERSON: 'Ansprechpartner/Fachbereich',
                APPLICANT: 'Antragssteller',
                TEAM: 'Team',
            },
            NEW: 'Neues Projekt',
            EDIT: 'Projekt bearbeiten',
            SAVE: 'Speichern',
        },
    },
};
