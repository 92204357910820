export const locale = {
    lang: 'en',
    data: {
        MAILORDER: {
            PROJECTS: 'Projects',
            REQUESTS: 'Requests',
            ORDERS: 'Orders',
            EMAILS: 'E-Mails',
            SEND: 'New E-Mail',
            IN: 'Received',
            OUT: 'Sent',
            ASSIGN: 'Assign E-Mails',
            ASSIGNED_EMAILS: 'Assigned E-Mails',
            REASSIGN: 'Reassign E-Mails',
            RE: 'Antworten',
            REALL: 'Alle antworten',
            FORWARD: 'Forward',
            FILTER_EMAILS: 'Mails filtern',
            COLUMNS: {
                TITLE: 'Title',
                FILESIZE: 'Filesize',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
            },
            NOTIFICATIONS: {
                UPLOAD: 'Uploading document...',
                ADDINGFOLDER: 'Adding folder...',
                SUCCESS: 'File uploaded successfully.',
                SUCCESSFOLDER: 'Folder added successfully.',
                ERROR: 'Error while uploading: ',
                UPLOADMULTI: 'Uploading documents...',
                SUCCESSMULTI: 'Files uploaded successfully.',
                ERRORMULTI: 'Fehler beim hochladen: ',
                WAIT: 'Please wait.',
            },
        },
    },
};
