import { LoadingService } from './../../services/loading.service';
import { Component, ViewChild } from '@angular/core';
import { locale as english } from './i18n/en';
import { locale as german } from './i18n/de';
import { ColumnTyp, FormatTypes, SharePointService, ToolBarControlType, } from 'sp-office365-framework';
import * as CamlBuilder from 'camljs';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService, ListViewConfig, SnackbarService, } from 'sp-office365-layout';
import { Router } from '@angular/router';
import { ListViewComponent } from 'sp-office365-layout/lib/src/app/layout/listview/listview.component';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
    selector: 'app-listview-assignment',
    templateUrl: './listview-assignment.component.html',
    styleUrls: ['./listview-assignment.component.scss'],
})
export class ListviewAssignmentComponent {
    public config: ListViewConfig;
    public currentUserId: number;

    @ViewChild('listView', { static: false }) listView: ListViewComponent;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private router: Router,
        private _snackBarService: SnackbarService,
        private _sharepointService: SharePointService,
        private _loadingService: LoadingService,
        private _currentUserService: CurrentUserService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.initConfig();
    }

    private getAllAssignmentsCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .OrderBy('Title')
            .ToString();
    }

    private getAllAssignmentsSearchCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                    ]
                )
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .NumberField('ID')
            .NotEqualTo(-1)
            .And()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                ]
            )
            .OrderBy('Title')
            .ToString();
    }

    private getCriticalAssignmentsCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .ChoiceField('Kennzeichen')
                .EqualTo('Kritisch')
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .OrderBy('Title')
            .ToString();
    }

    private getCriticalAssignmentsSearchCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .ChoiceField('Kennzeichen')
                .EqualTo('Kritisch')
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                    ]
                )
                .OrderBy('Title')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .ChoiceField('Kennzeichen')
            .EqualTo('Kritisch')
            .And()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                ]
            )
            .OrderBy('Title')
            .ToString();
    }

    private getCurrentAssignmentsCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .ChoiceField('Auftragsstatus')
                .EqualTo('Auftrag angelegt')
                .Or()
                .ChoiceField('Auftragsstatus')
                .EqualTo('Auftrag in Arbeit')
                .OrderByDesc('Modified')
                .ToString();
        }
        return new CamlBuilder()
            .Where()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag angelegt')
            .Or()
            .ChoiceField('Auftragsstatus')
            .EqualTo('Auftrag in Arbeit')
            .OrderByDesc('Modified')
            .ToString();
    }

    private getCurrentAssignmentsSearchCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Auftragsstatus').EqualTo('Auftrag angelegt'),
                        CamlBuilder.Expression().TextField('Auftragsstatus').EqualTo('Auftrag in Arbeit'),
                    ]
                )
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                    ]
                )
                .OrderByDesc('Modified')
                .ToString();
        }

        return new CamlBuilder()
            .Where()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Auftragsstatus').EqualTo('Auftrag angelegt'),
                    CamlBuilder.Expression().TextField('Auftragsstatus').EqualTo('Auftrag in Arbeit'),
                ]
            )
            .And()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                ]
            )
            .OrderByDesc('Modified')
            .ToString();
    }

    private getClosedAssignmentsCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .ChoiceField('Auftragsstatus')
                .NotEqualTo('Auftrag angelegt')
                .And()
                .ChoiceField('Auftragsstatus')
                .NotEqualTo('Auftrag in Arbeit')
                .OrderByDesc('Modified')
                .ToString();
        }
        return new CamlBuilder()
            .Where()
            .ChoiceField('Auftragsstatus')
            .NotEqualTo('Auftrag angelegt')
            .And()
            .ChoiceField('Auftragsstatus')
            .NotEqualTo('Auftrag in Arbeit')
            .OrderByDesc('Modified')
            .ToString();
    }

    private getClosedAssignmentsSearchCamlQuery(userId?) {
        if (userId) {
            return new CamlBuilder()
                .Where()
                .UserField('Auftragsleiter')
                .Id()
                .EqualTo(userId)
                .And()
                .ChoiceField('Auftragsstatus')
                .NotEqualTo('Auftrag angelegt')
                .And()
                .ChoiceField('Auftragsstatus')
                .NotEqualTo('Auftrag in Arbeit')
                .And()
                .Any(
                    [
                        CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                        CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                    ]
                )
                .OrderByDesc('Modified')
                .ToString();
        }
        return new CamlBuilder()
            .Where()
            .ChoiceField('Auftragsstatus')
            .NotEqualTo('Auftrag angelegt')
            .And()
            .ChoiceField('Auftragsstatus')
            .NotEqualTo('Auftrag in Arbeit')
            .And()
            .Any(
                [
                    CamlBuilder.Expression().TextField('Title').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('Projektnummer').Contains('<%searchText%>'),
                    CamlBuilder.Expression().TextField('OW_Nummer').Contains('<%searchText%>')
                ]
            )
            .OrderByDesc('Modified')
            .ToString();
    }

    removeItems(items) {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this._loadingService.show(
                    this._translateService.instant(
                        'LV_ASSIGNMENT.NOTIFICATIONS.DELETING'
                    ),
                    this._translateService.instant(
                        'LV_ASSIGNMENT.NOTIFICATIONS.WAIT'
                    )
                );
                for (let item of items) {
                    const id = item.dataItem.Id;

                    await this._sharepointService.recycleItem({
                        listTitle: 'Aufträge',
                        id: id,
                    });
                }

                resolve();
            } catch (err) {
                console.error(err);
                this._snackBarService.add(
                    this._translateService.instant(
                        'LV_ASSIGNMENT.NOTIFICATIONS.ERROR_DELETE'
                    ),
                    '',
                    {
                        duration: 4000,
                        panelClass: 'error-dialog',
                    }
                );
            }
        });
    }

    initConfig() {
        const hasRootPermissions = this._sharepointService.currentUser.IsBusinessOwner ||
            this._sharepointService.currentUser.IsAdministrator ||
            this._currentUserService.isTeamProjectLeiter();
        this.config = {
            title: this._translateService.instant('LV_ASSIGNMENT.TITLE'),
            searchLabel: this._translateService.instant(
                'LV_ASSIGNMENT.SEARCH_LABEL'
            ),
            icon: 'assignment',
            add_icon: 'add',
            searchDebounceTime: 1000,
            onDeleteClick: (items) => {
                this.removeItems(items).then(() => {
                    this._snackBarService.add(
                        this._translateService.instant(
                            'LV_ASSIGNMENT.NOTIFICATIONS.SUCCESS_DELETE'
                        ),
                        '',
                        {
                            duration: 4000,
                            panelClass: 'success-dialog',
                        }
                    );

                    this._loadingService.hide();
                    const self = this;
                    // Refresh table
                    self.listView._listViewService.onListViewDataChanged.next(
                        self.listView._listViewService.listView
                    );
                });
            },
            openEditInDialog: false,
            onRowClick: (item) => {
                // console.log(item);
                this.router.navigate(['projects/detail/basedata/' + item.ID], {
                    queryParamsHandling: 'merge',
                });
            },
            tableConfigs: [
                {
                    title: this._translateService.instant('LV_ASSIGNMENT.ALL'),
                    config: {
                        list: 'Aufträge',
                        groups: [],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getAllAssignmentsCamlQuery(),
                        toolbar: [{
                            title: 'Test',
                            command: 'filterUsers',
                            type: ToolBarControlType.Dropdown,
                            dropDownItems: [
                                {label: 'label1', value: 'value1'},
                                {label: 'label2', value: 'value2'}
                            ]
                        }],
                        useMaterialDesign: true,
                    },
                    camlQueryForSearch: this.getAllAssignmentsSearchCamlQuery(),
                },
                {
                    title: this._translateService.instant(
                        'LV_ASSIGNMENT.CRITICAL'
                    ),
                    config: {
                        list: 'Aufträge',
                        groups: [],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getCriticalAssignmentsCamlQuery(),
                        useMaterialDesign: true,
                    },
                    camlQueryForSearch: this.getCriticalAssignmentsSearchCamlQuery(),
                },
                {
                    title: this._translateService.instant(
                        'LV_ASSIGNMENT.CURRENT'
                    ),
                    config: {
                        list: 'Aufträge',
                        groups: [],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getCurrentAssignmentsCamlQuery(),
                        useMaterialDesign: true,
                    },
                    camlQueryForSearch: this.getCurrentAssignmentsSearchCamlQuery(),
                },
                {
                    title: this._translateService.instant(
                        'LV_ASSIGNMENT.CLOSED'
                    ),
                    config: {
                        list: 'Aufträge',
                        groups: [],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getClosedAssignmentsCamlQuery(),
                        useMaterialDesign: true,
                    },
                    camlQueryForSearch: this.getClosedAssignmentsSearchCamlQuery(),
                },
                {
                    title: this._translateService.instant(
                        'LV_ASSIGNMENT.TEAMS'
                    ),
                    config: {
                        list: 'Aufträge',
                        groups: [{ field: 'Team' }],
                        pageSize: 10,
                        columns: [
                            {
                                internalName: 'Title',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.NAME'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Projektnummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.PROJECTNUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'OW_Nummer',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.OW_NUMBER'
                                ),
                                hidden: false,
                                type: ColumnTyp.Text,
                            },
                            {
                                internalName: 'Auftragsstatus',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.STATUS'
                                ),
                                hidden: false,
                            },
                            {
                                internalName: 'Team',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.TEAM'
                                ),
                                hidden: true,
                                type: ColumnTyp.Lookup,
                            },
                            {
                                internalName: 'Auftragsstart',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.START'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            {
                                internalName: 'Auftragsende',
                                title: this._translateService.instant(
                                    'LV_ASSIGNMENT.COLUMNS.END'
                                ),
                                hidden: false,
                                type: ColumnTyp.Date,
                                format: FormatTypes.yyyyMMdd,
                            },
                            { internalName: 'ID', title: 'ID', hidden: true },
                        ],
                        isDocumentLibrary: false,
                        showEditButton: false,
                        showDeleteButton: false,
                        addCheckboxColumn: hasRootPermissions,
                        camlQuery: this.getCurrentAssignmentsCamlQuery(),
                        useMaterialDesign: true,
                    },
                    camlQueryForSearch: this.getCurrentAssignmentsSearchCamlQuery(),
                },
            ],
        };

        this.initUserFilterData();
    }

    public toolbarButtonHandler(event): void {
        if (event.command === 'filterUsers') {
            this.currentUserId = event.e.value;

            if(this.listView.listComponent.currentTableConfig.title === this.config.tableConfigs[0].title) {
                (this.config.tableConfigs[0].config.toolbar[0] as any).value = event.e.value;
                this.config.tableConfigs[0].config.camlQuery = this.getAllAssignmentsCamlQuery(event.e.value);
                this.config.tableConfigs[0].camlQueryForSearch = this.getAllAssignmentsSearchCamlQuery(event.e.value);
            } else if(this.listView.listComponent.currentTableConfig.title === this.config.tableConfigs[1].title) {
                (this.config.tableConfigs[1].config.toolbar[0] as any).value = event.e.value;
                this.config.tableConfigs[1].config.camlQuery = this.getCriticalAssignmentsCamlQuery(event.e.value);
                this.config.tableConfigs[1].camlQueryForSearch = this.getCriticalAssignmentsSearchCamlQuery(event.e.value);
            } else if(this.listView.listComponent.currentTableConfig.title === this.config.tableConfigs[2].title) {
                (this.config.tableConfigs[2].config.toolbar[0] as any).value = event.e.value;
                this.config.tableConfigs[2].config.camlQuery = this.getCurrentAssignmentsCamlQuery(event.e.value);
                this.config.tableConfigs[2].camlQueryForSearch = this.getCurrentAssignmentsSearchCamlQuery(event.e.value);
            }  else if(this.listView.listComponent.currentTableConfig.title === this.config.tableConfigs[3].title) {
                (this.config.tableConfigs[3].config.toolbar[0] as any).value = event.e.value;
                this.config.tableConfigs[3].config.camlQuery = this.getClosedAssignmentsCamlQuery(event.e.value);
                this.config.tableConfigs[3].camlQueryForSearch = this.getClosedAssignmentsSearchCamlQuery(event.e.value);
            }  else if(this.listView.listComponent.currentTableConfig.title === this.config.tableConfigs[4].title) {
                (this.config.tableConfigs[4].config.toolbar[0] as any).value = event.e.value;
                this.config.tableConfigs[4].config.camlQuery = this.getCurrentAssignmentsCamlQuery(event.e.value);
                this.config.tableConfigs[4].camlQueryForSearch = this.getCurrentAssignmentsSearchCamlQuery(event.e.value);
            }

            this.listView.listComponent.sharePointTableComponent.updateCamlQuery();
            this.listView.listComponent.sharePointTableComponent.refresh();
        }
    }

    public initUserFilterData(): void {
        this._sharepointService.getListItems({
            title: 'Aufträge',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString()
        }).then(projects => {
            const usersRecord = {};
            projects.forEach(project => {
                if (project.AuftragsleiterId) {
                    usersRecord[project.AuftragsleiterId] = {
                        id: project.AuftragsleiterId,
                        title: project.FieldValuesAsText.Auftragsleiter
                    }
                }
            })

            const users = Object.keys(usersRecord)
                .map(key => ({ label: usersRecord[key].title, value: usersRecord[key].id }));
            users.unshift({ label: 'All', value: null });

            this.config.tableConfigs.forEach(config => {
                config.config.toolbar = [{
                    title: '',
                    command: 'filterUsers',
                    type: ToolBarControlType.Dropdown,
                    placeholder: 'Filter by user',
                    dropDownItems: users
                }];
            });
        })
    }
}
