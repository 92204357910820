import { SharePointService, UpdateService } from 'sp-office365-framework';
import { IList } from '@pnp/sp/presets/all';
import '@pnp/sp/items/get-all';

export class UpdateToVersion1042Component {
    constructor(
        private sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const processstepList: IList = this.sharePointService.web.lists.getByTitle(
                'Prozessschritte'
            );

            const promises = [
                // Add Field Aufgabenstatus to List Prozessschritte
                await this._updateService.runFunctionIfFieldNotExists(
                    processstepList,
                    'Aufgabenstatus',
                    () => {
                        const promise = new Promise<void>((resolve, reject) => {
                            // processstepList.fields
                            //     .addChoice('Aufgabenstatus', [
                            //         'Nicht begonnen',
                            //         'In Bearbeitung',
                            //         'Abgeschlossen',
                            //         'Zurückgestellt',
                            //         'Wartet auf jemand anderen',
                            //     ])
                            //     .then(
                            //         () => resolve(),
                            //         (error) => {
                            //             reject(error);
                            //         }
                            //     );
                        });
                        return promise;
                    }
                ),
                // Update Items in List Prozessschritte
                await new Promise<void>((resolve, reject) => {
                    processstepList.items.getAll().then(
                        (processSteps) => {
                            const promises = [];

                            processSteps.forEach((processStep) => {
                                let status = 'Nicht begonnen';

                                if (processStep.Title == 'Bearbeitung') {
                                    status = 'In Bearbeitung';
                                } else if (
                                    processStep.Title == 'Abgeschlossen'
                                ) {
                                    status = 'Abgeschlossen';
                                }

                                promises.push(
                                    processstepList.items
                                        .getById(processStep.Id)
                                        .update({
                                            Aufgabenstatus: status,
                                        })
                                );
                            });

                            Promise.all(promises).then(
                                () => resolve(),
                                (error) => {
                                    reject(error);
                                }
                            );
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                }),
            ];

            Promise.all(promises).then(
                () => resolve(),
                (error) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
