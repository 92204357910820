import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class Updatetoversion1132Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const list: IList =
                this._sharePointService.web.lists.getByTitle('Feiertage');
            await this._updateService.addField(
                list,
                'Faktor',
                FieldType.Number
            );
            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
