import { SharepointEntrysService } from './sharepoint-entrys.service';
import { FuseTranslationLoaderService } from "sp-office365-layout";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { SharePointService } from "sp-office365-framework";
import { locale as english } from "./i18n/en";
import { locale as german } from "./i18n/de";

@Injectable({
  providedIn: "root",
})
export class ChartService {
  public chartOptions: any;
  public showChart: boolean = true;
  public errorMessage: string;
  public sumAllBills: Map<number, number> = new Map<number, number>();
  public sumAllSollzahl: Map<number, number> = new Map<number, number>();

  constructor(
    public _sharepointEntrysService: SharepointEntrysService,
    public _sharepointService: SharePointService,
    private _translateService: TranslateService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  settingDate(date: Date, month: number, year: number, day = 1): void {
    date.setDate(day);
    date.setMonth(month - 1);
    date.setFullYear(year);
  }

  updateValues() {
    let promises = [];
    let allBills;
    let allTargetAmounts;
    let sollWerte = [];
    let istWerte = [];
    this.chartOptions = null;
    promises.push(
      new Promise<void>((resolve, reject) => {
        this._sharepointService
          .getListItems({
            title: "SollZahlen",
            isDocumentLibrary: false,
            camlQuery: this._sharepointEntrysService.filteredEntrysForChart,
          })
          .then((result) => {
            allTargetAmounts = result;
            resolve(allTargetAmounts);
          })
          .catch((err) => {
            reject(err);
          });
      })
    );

    promises.push(
      new Promise<void>((resolve, reject) => {
        this._sharepointService
          .getListItems({
            title: "Rechnungen",
            isDocumentLibrary: false,
            camlQuery: this._sharepointEntrysService.filteredEntrysForChart,
            recursiveAll: true
          })
          .then((result) => {
            allBills = result;
            resolve(allBills);
          })
          .catch((err) => {
            reject(err);
          });
      })
    );

    Promise.all(promises)
      .then((results) => {
        var currentKey: number;
        this.sumAllSollzahl.clear();
        let sumTargetAmounts = 0;
        let xValue: string[] = [];

        allTargetAmounts.forEach((targetAmount) => {
          currentKey = targetAmount.Jahr * 100 + targetAmount.Monat;
          sumTargetAmounts = sumTargetAmounts + targetAmount["Soll_Wert"];
          this.sumAllSollzahl.set(currentKey, sumTargetAmounts);
        });

        let firstDate: Date = new Date();
        let lastDate: Date = new Date();

        this.settingDate(
          firstDate,
          this._sharepointEntrysService.monthChart.from,
          this._sharepointEntrysService.yearChart.from
        );
        this.settingDate(
          lastDate,
          this._sharepointEntrysService.monthChart.until,
          this._sharepointEntrysService.yearChart.until
        );

        if (
          this.monthDiff(firstDate, lastDate) + 1 !=
          this.sumAllSollzahl.size
        ) {
          this.errorMessage = this._translateService.instant(
            "CHART_SERVICE.ERROR"
          );
          this.showChart = false;
          return;
        }

        this.sumAllBills.clear();
        let currentIndex = 0;
        let sumBills = 0;

        this.sumAllSollzahl.forEach((value, key, map) => {
          for (let index = currentIndex; index < allBills.length; index++) {
            let currentKey =
              allBills[index]["Jahr"] * 100 + allBills[index]["Monat"];
            if (currentKey != key && currentIndex != 0) {
              this.sumAllBills.set(key, sumBills);
              break;
            } else if (currentKey != key && currentIndex == 0) {
              this.sumAllBills.set(key, sumBills);
              break;
            } else {
              // Prüfen ob mehrere Werte aufeinenader addiert werden müssen!
              sumBills = sumBills + allBills[index].Forderung;
              if (
                index < allBills.length - 1 &&
                allBills[index + 1].Monat != allBills[index].Monat
              ) {
                this.sumAllBills.set(key, sumBills);
                currentIndex = index + 1;
                break;
              } else if (index == allBills.length - 1) {
                this.sumAllBills.set(key, sumBills);
              }
            }
          }

          if (allBills.length == 0) {
            this.sumAllBills.set(key, 0);
          }
        });

        this.sumAllBills.forEach((value, key) => {
          istWerte.push(value);
        });

        let currentXValue: string;
        this.sumAllSollzahl.forEach((value, key) => {
          sollWerte.push(value);
          currentXValue =
            key.toString().substring(0, 4) +
            "-" +
            key.toString().substring(4, 6);
          xValue.push(currentXValue);
        });

        this.chartOptions = {
          series: [
            {
              name: this._translateService.instant("CHART.TARGETVALUE"),
              data: sollWerte,
              type: "line",
              color: "RGB(55,2,128)",
            },
            {
              name: this._translateService.instant("CHART.ACTUALVALUE"),
              data: istWerte,
              type: "line",
              color: "RGB(252, 40, 3)",
            },
          ],
          chart: {
            height: 350,
            //type: "line",
          },
          title: {
            align: "center",
          },
          xaxis: {
            categories: xValue,
          },
        };
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
