import { ConfirmdatedialogComponent } from './confirmdatedialog/confirmdatedialog.component';
import { OverviewBudgetModule } from './../../budget/overview-budget/overview-budget.module';
import { DetailCustomerModule } from './../../customer/detail-customer/detail-customer.module';
import { NgModule } from '@angular/core';
import { EditAssignmentComponent } from './edit-assignment.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule, FuseSidebarModule } from 'sp-office365-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { FormioModule } from '@formio/angular';
import { DetailCustomerComponent } from '../../customer/detail-customer/detail-customer.component';
import { OverviewBudgetComponent } from '../../budget/overview-budget/overview-budget.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

const routes = [
    {
        path: 'new-project',
        component: EditAssignmentComponent,
    },
    {
        path: 'projects/detail/basedata/:id',
        component: EditAssignmentComponent,
    },
];

@NgModule({
    declarations: [EditAssignmentComponent, ConfirmdatedialogComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        LayoutModule,
        MatIconModule,
        MatTabsModule,
        FuseSidebarModule,
        FormioModule,
        DetailCustomerModule,
        OverviewBudgetModule,
        ExcelExportModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [EditAssignmentComponent, ConfirmdatedialogComponent]
})
export class EditAssignmentModule {}
