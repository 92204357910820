<div id="documentTemplate">
  <mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ "DOCUMENTTEMPLATE.TITLE" | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div id='content'>

    <app-sharepoint-table #templateGrid *ngIf="this.tableConfig" [configuration]="this.tableConfig">

      <ng-template customCell for="Name" let-dataItem="dataItem">

        <ng-container *ngIf="dataItem.FieldValuesAsText.FileRef">
          {{dataItem.FieldValuesAsText.FileRef.split("/").pop()}}
        </ng-container>

      </ng-template>


    </app-sharepoint-table>

    <button mat-raised-button color="accent" class="createButton" (click)="createDocuments()">{{
      "DOCUMENTTEMPLATE.CREATE"
      | translate
      }}</button>

  </div>

</div>