import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1133Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            // Add List Aufgabensets
            await this._updateService.addList('Arbeitspaketesets', '', 100, {
                EnableVersioning: true,
            });
            // Get List Aufgabensets
            const workPackageSetList =
                this._sharePointService.web.lists.getByTitle(
                    'Arbeitspaketesets'
                );
            const aufgabensetsList =
                this._sharePointService.web.lists.getByTitle('Aufgabensets');
            const aufgabentypenList =
                this._sharePointService.web.lists.getByTitle('Aufgabentypen');
            const prozessschritteList =
                this._sharePointService.web.lists.getByTitle('Prozessschritte');

            await this._updateService.addLookupField(
                workPackageSetList,
                aufgabensetsList,
                'Aufgabensets',
                true
            );
            await this._updateService.addLookupField(
                workPackageSetList,
                aufgabentypenList,
                'Aufgabentyp',
                false
            );
            await this._updateService.addLookupField(
                workPackageSetList,
                prozessschritteList,
                'Prozessschritt',
                false
            );
            await this._updateService.addField(
                workPackageSetList,
                'Description',
                FieldType.MultilineText
            );
            await this._updateService.addField(
                workPackageSetList,
                'Priority',
                FieldType.Choice,
                undefined,
                ['(1) High', '(2) Normal', '(3) Low']
            );
            await this._updateService.addField(
                workPackageSetList,
                'OffsetAnfangsdatum',
                FieldType.Number
            );
            await this._updateService.addField(
                workPackageSetList,
                'OffsetF_x00e4_lligkeitsdatum',
                FieldType.Number
            );
            await this._updateService.addField(
                workPackageSetList,
                'OffsetStartType',
                FieldType.Choice,
                undefined,
                ['Ab Heute', 'Ab Beginn Auftrag', 'Ab Ende Auftrag']
            );
            await this._updateService.addField(
                workPackageSetList,
                'OffsetEndeType',
                FieldType.Choice,
                undefined,
                ['Ab Heute', 'Ab Beginn Auftrag', 'Ab Ende Auftrag']
            );
            await this._updateService.addField(
                workPackageSetList,
                'Kennzeichen',
                FieldType.Choice,
                undefined,
                ['Kritisch', 'Unkritisch']
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
