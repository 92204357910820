export const locale = {
    lang: 'de',
    data: {
        PROJECTS: 'Projekte',
        BASE: 'Stammdaten',
        DOCS: 'Dokumente',
        INVOLVED: 'Beteiligte',
        INVOLVEMENT: 'Beteiligung',
        TASKS: 'Arbeitspakete',
        KANBAN: 'Kanban',
        NEW: 'Neuen Beteiligten hinzufügen',
        EDIT: 'Beteiligten bearbeiten',
        COLUMNS: {
            NAME: 'Name',
            KIND: 'Art',
            INTERN: 'Intern',
            EXTERN: 'Extern',
            PARTICIPATION: 'Beteiligung',
            INPERCENT: '(in %)',
            START: 'Start',
            END: 'Ende',
            USER: 'User (AD)',
            CONTACT: 'Kontakt',
            PROJECT: 'Projekt',
            SAVE: 'Speichern',
        },
        TABS: {
            SCHEDULER: 'Zeitplan des Beteiligten in allen Projekten',
            BASE: 'Stammdaten',
        },
        NOTIFICATIONS: {
            SAVING: 'Beteiligter wird gespeichert...',
            WAIT: 'Bitte warten.',
            SUCCESS: 'Beteiligter erfolgreich gespeichert.',
            ERROR: 'Fehler beim speichern: ',
        },
    },
};
