import { TableTasksetModule } from './../../taskset/table-taskset/table-taskset.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableTaskComponent } from './table-task.component';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { TableTasksetComponent } from '../../taskset/table-taskset/table-taskset.component';
import { ImportWorkpackageModule } from 'src/app/main/work-package-template/components/import-workpackage/import-workpackage.module';
import { ImportWorkpackageSetModule } from '../../work-package-template/components/import-workpackage-set/import-workpackage-set.module';

@NgModule({
    declarations: [TableTaskComponent],
    imports: [CommonModule, AngularFrameworkModule, TableTasksetModule, ImportWorkpackageModule, ImportWorkpackageSetModule],
    exports: [TableTaskComponent]
})
export class TableTaskModule {
}
