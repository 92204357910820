import { IList } from '@pnp/sp/presets/all';
import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1119Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const projectList: IList =
                await this._sharePointService.web.lists.getByTitle('Aufträge');

            const promises = [
                this._updateService.addField(
                    projectList,
                    'Notiz_geaendert_von',
                    FieldType.Text
                ),

                this._updateService.addField(
                    projectList,
                    'Notiz_geaendert_am',
                    FieldType.Text
                ),
            ];

            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
