export const locale = {
    lang: 'de',
    data: {
        TIMEASSIGNMENT: {
            PROJECTNUMBER: 'Projektnummer',
            PROJECTS: 'Projekte',
            TIMES: 'Zeiten',
            NEW: 'Neue Zeit erfassen',
            NOTIFICATIONS: {
                PERMISSION: 'Wollen Sie das gewählte Element wirklich löschen?',
                SUCCESS: 'Der Zeiteintrag wurde erfolgreich gelöscht.',
            },
            COLUMNS: {
                DATE: 'Datum',
                MINUTES: 'Dauer (in Minuten)',
                TASK: 'Aufgabe',
                AUTHOR: 'Mitarbeiter',
            },
            FILTER: {
                YEAR: "Jahr",
                MONTH: "Monat",
                MONTHS: {
                    ALL: " ",
                    1: "Januar",
                    2: "Februar",
                    3: "März",
                    4: "April",
                    5: "Mai",
                    6: "Juni",
                    7: "Juli",
                    8: "August",
                    9: "September",
                    10: "Oktober",
                    11: "November",
                    12: "Dezember",
                },
                EMPLOYEE: "Mitarbeiter",
            },
        },
    },
};
