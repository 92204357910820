import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import * as CamlBuilder from 'camljs';
import { BillsImportsStatus } from 'src/app/main/bills/enum/bills-imports-status.enum';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportEmailsService {

  constructor() { }

  public importEmails(): Observable<any> {
    return of();
  }

  public importMail(item: { date: Date, docNumber: string; projectNumber: string, matchCode: string; price: number }): Promise<any> {
    const fristDate = new Date(item.date);
    fristDate.setMonth(fristDate.getMonth() + 1);

    const payload = {
      Title: `${item.docNumber}_${item.matchCode}`,
      Von: new Date(item.date.getFullYear(), item.date.getMonth(), 1),
      Bis: new Date(item.date.getFullYear(), item.date.getMonth() + 1, 0),
      Frist: fristDate,
      Jahr: item.date.getFullYear(),
      Monat: item.date.getMonth() + 1,
      Abrechnungstyp: 'Pauschal',
      Forderung: item.price,
      Gezahlt: null,
      Offen: null,
      Rechnungsnummer: item.docNumber,
      StandortId: null,
      TeamId: null,
      ProjektId: null,
      AR_Number: null
    };

    return
  }
}
