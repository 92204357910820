export const locale = {
    lang: 'en',
    data: {
        ORDERREQUESTDOCS: {
            ORDERS: 'Orders',
            REQUESTS: 'Requests',
            NEWFOLDER: 'New Folder',
            NEW: 'Upload Documents',
            DOCS: 'Documents',
            CREATE_DOCUMENT: 'Create from Template',
            COLUMNS: {
                TITLE: 'Title',
                FILESIZE: 'Filesize',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
                TASK: 'Task',
            },
            TABS: {
                PROJECT: 'Project Documents',
                TASKS: 'Task Documents',
                ORDER: 'Order Documents',
                REQUEST: 'Request Documents',
            },
        },
    },
};
