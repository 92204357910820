import { Injectable } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import CamlBuilder from 'camljs';
import { Holiday } from 'src/app/main/shared/models';

@Injectable({
    providedIn: 'root'
})
export class HolidaysService {
    public holidaysPerLocation: Record<number, Holiday[]> = {};

    constructor(private _sharePointService: SharePointService) {
    }

    public loadHolidays(): Promise<Record<number, Holiday[]>> {
        return this._sharePointService.getListItems({
            title: 'Feiertage',
            isDocumentLibrary: false,
            camlQuery: new CamlBuilder()
                .Where()
                .NumberField('ID')
                .NotEqualTo(-1)
                .ToString()
        }).then(holidays => {
            const holidaysRecord: Record<number, Holiday[]> = {};

            holidays.forEach(holiday => {
                if (holiday.Date) {
                    holiday.Date = new Date(holiday.Date);
                }

                if (!holidaysRecord[holiday.StandortId]) {
                    holidaysRecord[holiday.StandortId] = [];
                }

                holidaysRecord[holiday.StandortId].push(holiday);
            });

            this.holidaysPerLocation = holidaysRecord;
            return holidaysRecord;
        });
    }
}
