import { IList } from '@pnp/sp/presets/all';
import {
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1112Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    run() {
        const promise = new Promise<void>(async (resolve, reject) => {
            const externalCostList: IList = await this._sharePointService.web.lists.getByTitle(
                'Fremdkosten'
            );

            const projectList: IList = await this._sharePointService.web.lists.getByTitle(
              'Aufträge'
          );

            const promises = [
                // Add LookUp-Field Projekt to List Fremdkosten
                await this._updateService.addLookupField(
                    externalCostList,
                    projectList,
                    'Projekt',
                    false,
                    "Title"
                ),
            ];


            Promise.all(promises)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
        return promise;
    }
}
