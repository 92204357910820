<div id="tabletaskset">
  <mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ "TABLE_TASKSET.TITLE" | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div id='content'>

    <app-sharepoint-table #table [configuration]="tableConfig" (cellClick)="editItem($event)">

      <ng-template customCell for="Auswahl" let-dataItem="dataItem">
        <mat-checkbox #checkBoxes (click)="$event.stopPropagation()"
          (change)="checkBoxChange($event, dataItem)"></mat-checkbox>
      </ng-template>

    </app-sharepoint-table>

    <button mat-raised-button color="accent" class="createButton" (click)="createTaskSet()">{{
      "TABLE_TASKSET.NEWTASKSET"
      | translate
      }}</button>

  </div>

</div>