export const locale = {
    lang: 'de',
    data: {
        COMMENTS_ASSIGNMENT: {
            ADDCOMMENT: 'Neuer Kommentar',
            PROJECTS: 'Projekte',
            COMMENTS: 'Kommentare',
            EDIT: 'Bearbeiten',
            SAVE: 'Speichern',
            DELETE: 'Löschen',
            NOTIFICATIONS: {
                SUCCESSDELETE: 'Kommentar erfolgreich gelöscht!',
                ERRORDELETE: 'Fehler beim Löschen des Kommentars!',
                SUCCESSUPDATE: 'Kommentar erfolgreich aktualisiert!',
                ERRORUPDATE: 'Fehler beim Aktualisieren des Kommentars!',
                SUCCESSADD: 'Kommentar erfolgreich hinzugefügt!',
                ERRORADD: 'Fehler beim Hinzufügen des Kommentars!',
            },
        },
    },
};
