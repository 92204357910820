export const locale = {
    lang: 'en',
    data: {
        TITLE: 'Scheduler',
        START: 'Start',
        END: 'End',
        RESIZED: 'Project resized',
        MOVED: 'Project moved',
        THISMONTH: 'This month',
        THISYEAR: 'This year',
        NEXTYEAR: '2 years',
        THREEYEARS: '3 years',
        FOURYEARS: '4 years',
        FIVEYEARS: '5 years',
        CURRENT: 'Current',
    },
};
