<div class="table-header pl-12 pb-12">
    <div>
        <div class="mb-4">
            <span class="total-budget-label">{{'PROJECTEVALUATION.COLUMNS.TOTLALSUM' | translate}}:</span>
            {{(projectEvaluationService.totalProjectSum$ | async) | number: '1.2-2'}}
        </div>
        <div>
            <span class="total-budget-label">{{'PROJECTEVALUATION.COLUMNS.BUDGET' | translate}}:</span>
            {{(projectEvaluationService.totalProjectBudget$ | async) | number: '1.2-2'}}
        </div>
        <div>
            <span class="total-budget-label">{{'PROJECTEVALUATION.COLUMNS.TOTALBILLS' | translate}}:</span>
            {{(projectEvaluationService.totalBills$ | async) | number: '1.2-2'}}
        </div>
    </div>

    <ng-container *ngIf="tableConfig?.data?.length">
        <button type="button" mat-raised-button (click)="onExcelExport(export)">
            <mat-icon>get_app</mat-icon>
            Excel-Export
        </button>

        <ng-container *ngIf="projectEvaluationService.loadingRoleColumn$ | async">
            <mat-spinner class="ml-8" diameter="20"></mat-spinner>
            <span>{{'PROJECTEVALUATION.HEADER.LOADINGCOLUMNS' | translate}}</span>
        </ng-container>
    </ng-container>
</div>

<app-sharepoint-table #grid
                      *ngIf="tableConfig"
                      [configuration]="tableConfig"
>
    <ng-template customCell for="_Budgets.Antragssumme" let-dataItem="dataItem">
        {{dataItem._Budgets.Antragssumme | number: '1.2-2'}}
    </ng-template>
    <ng-template customCell for="_Budgets.Auftragsbudget" let-dataItem="dataItem">
        {{dataItem._Budgets.Auftragsbudget | number: '1.2-2'}}
    </ng-template>
    <ng-template customCell for="_Budgets.NebenkostenEuro" let-dataItem="dataItem">
        {{dataItem._Budgets.NebenkostenEuro | number: '1.2-2'}}
    </ng-template>
    <ng-template customCell for="_Budgets.Fremdleistung" let-dataItem="dataItem">
        {{dataItem._Budgets.Fremdleistung | number: '1.2-2'}}
    </ng-template>
    <ng-template customCell for="_Bills.Total" let-dataItem="dataItem">
        {{dataItem._Bills.Total | number: '1.2-2'}}
    </ng-template>
    <ng-template customCell for="_Bills.Internal" let-dataItem="dataItem">
        {{dataItem._Bills.Internal | number: '1.2-2'}}
    </ng-template>
</app-sharepoint-table>

<kendo-excelexport *ngIf="tableConfig"
                   #export
                   [data]="tableConfig.data"
                   fileName="Projektliste.xlsx"
>
    <ng-container *ngFor="let column of excelColumns">
        <kendo-excelexport-column *ngIf="column.internalName !== 'ID'"
                                  [field]="column.internalName"
                                  [title]="column.title"
                                  [width]="column.width"
                                  [cellOptions]="column.currency ? {format: currencyFormat} : null"
                                  [headerCellOptions]="PROJECT_EVALUATION_HEADER_FORMAT"
        >
        </kendo-excelexport-column>
    </ng-container>
    <ng-container *ngFor="let column of this.projectEvaluationService.clerkColumns$ | async">
        <kendo-excelexport-column [field]="column.internalName"
                                  [title]="column.title"
                                  [headerCellOptions]="PROJECT_EVALUATION_HEADER_FORMAT"
        >
        </kendo-excelexport-column>
    </ng-container>
    <kendo-excelexport-column-group title="geleisteter Stundenaufwand [Std.]"
                                    [headerCellOptions]="PROJECT_EVALUATION_HEADER_FORMAT"
    >
        <ng-container *ngFor="let column of this.projectEvaluationService.roleColumns$ | async">
            <kendo-excelexport-column [field]="column.internalName"
                                      [title]="column.title"
                                      [cellOptions]="{textAlign: 'center'}"
                                      [headerCellOptions]="PROJECT_EVALUATION_SUB_HEADER_FORMAT"
            >
            </kendo-excelexport-column>
        </ng-container>
    </kendo-excelexport-column-group>
</kendo-excelexport>

