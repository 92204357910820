import {
    FieldType,
    SharePointService,
    UpdateService,
} from 'sp-office365-framework';

export class UpdateToVersion1142Component {
    constructor(
        private _sharePointService: SharePointService,
        private _updateService: UpdateService
    ) {}

    async run() {
        try {
            const teamsList =
                this._sharePointService.web.lists.getByTitle('Teams');
            this._updateService.addField(
                teamsList,
                'AvailableForCalculation',
                FieldType.Boolean
            );

            return null;
        } catch (error) {
            throw new Error(error);
        }
    }
}
