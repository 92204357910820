export const locale = {
    lang: 'en',
    data: {
        LV_REQUEST: {
            TITLE: 'Requests',
            OPEN: 'Open requests',
            CANACELED: 'Canceled requests',
            ALL: 'All requests',
            TEAMS: 'Teams',
            COLUMNS: {
                DESCRIPTION: 'Description',
                CLIENT: 'Client',
                TEAM: 'Team',
            },
            SEARCH_LABEL: 'Search for requests',
        },
    },
};
