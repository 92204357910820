<div>
    <mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ "WORKPACKAGESETS.TITLE" | translate }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div id='content'>
        <app-sharepoint-table #table [configuration]="tableWorkpackageSetConfig">
        </app-sharepoint-table>

        <button mat-raised-button
                color="accent"
                class="create-button"
                (click)="importWorkpackageSet()"
        >
            {{ "WORKPACKAGESETS.FORM.IMPORT_WORKPACKAGE_SET" | translate }}
        </button>
    </div>
</div>
