import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDocumentNameDialogComponent } from './change-document-name-dialog/change-document-name-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from 'sp-office365-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormioModule } from '@formio/angular';

@NgModule({
    declarations: [ChangeDocumentNameDialogComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        DragDropModule,
        MatSlideToggleModule,
        MatIconModule,
        TranslateModule,
        FuseSharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormioModule
    ]
})
export class ChangeDocumentNameModule {
}
