export const locale = {
    lang: 'en',
    data: {
        DOCUMENTTEMPLATE: {
            TITLE: 'Documenttemplates',
            CREATE: 'Create Documents',
            NOTIFICATIONS: {
                CREATE: 'Documents are being created...',
                WAIT: 'Please wait.',
            },
            COLUMNS: {
                TITLE: 'Name',
                FILESIZE: 'File size',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
            },
        },
    },
};
