export const locale = {
    lang: 'en',
    data: {
        LV_ASSIGNMENT: {
            TITLE: 'Projects',
            SEARCH_LABEL: 'Search for a project',
            ALL: 'All',
            CRITICAL: 'Critical',
            TEAMS: 'Teams',
            CURRENT: 'Current',
            CLOSED: 'Closed',
            NOTIFICATIONS: {
                SUCCESS_DELETE: 'Selected Projects were removed.',
                ERROR_DELETE: 'An error occured!',
                DELETING: 'Deleting selected projects',
                WAIT: 'Please wait...',
            },
            COLUMNS: {
                NAME: 'Project',
                MARK: 'Mark',
                CRITICAL: 'Critical',
                UNCRITICALLY: 'Uncritically',
                STATUS: 'Status',
                CREATED: 'Created',
                PROGRESS: 'In progress',
                STOPPED: 'Stopped',
                COMPLETED: 'Completed',
                START: 'Start',
                END: 'End',
                BASE: 'Basedata',
                DESCRIPTION: 'Description',
                OW_NUMBER: 'OW-Number',
                CATEGORY: 'Category',
                BUDGET: 'Budget',
                INEURO: '(in euro)',
                INHOURS: '(in hours)',
                PROJECTNUMBER: 'Projectnumber',
                INDAYS: '(in days)',
                USER: 'Roles',
                MANAGEMENT: 'Management',
                PPL: 'PPL',
                CONTACTPERSON: 'Contact person',
                APPLICANT: 'Applicant',
                TEAM: 'Team',
            },
            NEW: 'New project',
            EDIT: 'Edit project',
            SAVE: 'Save',
        },
    },
};
