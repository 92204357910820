export const locale = {
    lang: 'de',
    data: {
        ORDERREQUESTDOCS: {
            ORDERS: 'Aufträge',
            REQUESTS: 'Anfragen',
            NEWFOLDER: 'Neuer Ordner',
            NEW: 'Dokumente hochladen',
            CREATE_DOCUMENT: 'Aus Vorlage erstellen',
            DOCS: 'Dokumente',
            COLUMNS: {
                TITLE: 'Titel',
                FILESIZE: 'Dateigröße',
                AUTHOR: 'Ersteller',
                MODIFIED: 'Geändert',
                TASK: 'Arbeitspaket/Aufgabe',
            },
            TABS: {
                PROJECT: 'Projektdokumente',
                TASKS: 'Aufgabendokumente',
                ORDER: 'Auftragsdokumente',
                REQUEST: 'Anfragendokumente',
            },
        },
    },
};
