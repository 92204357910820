export const locale = {
    lang: 'de',
    data: {
        PROJECTEVALUATION: {
            EVALUATION: 'Projektliste',
            FILTER: {
                YEAR: 'Jahr',
                UNTIL_YEAR: 'Jahr',
                LOCATION: 'Standort',
                TEAM: 'Team',
                MONTH: {
                    UNTIL: 'Monat',
                    NO: 'Nein',
                    1: 'Januar',
                    2: 'Februar',
                    3: 'März',
                    4: 'April',
                    5: 'Mai',
                    6: 'Juni',
                    7: 'Juli',
                    8: 'August',
                    9: 'September',
                    10: 'Oktober',
                    11: 'November',
                    12: 'Dezember',
                },
                QUARTER: {
                    TITLE: 'Quartal',
                    NO: 'Nein',
                    1: 'Quartal 1',
                    2: 'Quartal 2',
                    3: 'Quartal 3',
                    4: 'Quartal 4',
                },
                ERRORMESSAGE: {
                    YEARS: 'Der Wert für "von (Jahr)" darf nicht größer als der Wert für "bis (Jahr)" sein!',
                    FOURDIGITS:
                        'Die Jahreswerte müssen jeweils im vierstelligen Bereich liegen!',
                    NOTVALID: 'Der angegebene Zeitraum ist ungültig!',
                },
                HIDECLOSEDPORJECTS: 'Abgeschlossene Projekte ausblenden',
                HIDE999PROJECTS: '999-Projekte ausblenden',
            },
            HEADER: {
                LOADINGCOLUMNS:
                    'Bitte warten. Kostenrolle Spalten werden noch geladen. Ansonsten werden sie nicht im Bericht sichtbar sein',
            },
            COLUMNS: {
                TITLE: 'Projekt',
                PROJECTSUM: 'Projektsumme ',
                BUDGET: 'Interne Auftragssumme',
                BUDGET_STUD: 'Interne Auftragssumme (in Stunden)',
                BUDGET_TAG: 'Interne Auftragssumme (in Tagen)',
                TOTLALSUM: 'Auftragssumme',
                TOTALBUDGET: 'Gesamt Budget',
                TOTALBILLS: 'Gestellte Rechungen',
                START: 'Start',
                END: 'Ende',
                STATUS: 'Status',
                CUSTOMER: 'Auftraggeber / Ansprechpartner',
                ADDITIONAL_PER: 'zzgl. Nebenkosten (%)',
                ADDITIONAL_EURO: 'zzgl. Nebenkosten',
                EXTERNAL: 'Davon Fremdleistung',
                TEAM: 'Team ',
                OW_NUMBER: 'OW-Nummer',
                PROJECTNUMBER: 'Projektnummer',
                CREATED: 'Angelegt',
                PROGRESS: 'In Arbeit',
                STOPPED: 'Gestoppt',
                COMPLETED: 'Abgeschlossen',
                OW_NUMMER: 'OW Nummer      ',
                TOTAL_HOURS: 'gebuchter Zeitaufwand',
                WORK_IN_PROGRESS_COST: 'Arbeitsvorrat',
                EXPECTEDPROFITMARGIN: 'erwartete Gewinnmarge (in %)',
                WORKLIST: 'Arbeitsvorrat',
                GEWINN_EURO: 'Gewinn (in €)',
                GEWINN_PERCENT: 'Gewinn (in %)',
            },
        },
    },
};
