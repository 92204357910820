import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SharePointService } from 'sp-office365-framework';
import { fuseAnimations } from 'sp-office365-layout';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ChartComponent implements OnInit {
    public _configuration: any;
    @Input() set configuration(config: any) {
        this._configuration = config;
    }
    public ready = false;

    constructor(private sharePointService: SharePointService) {}

    ngOnInit() {
        this.loadData();
    }

    refresh(config) {
        this.ready = false;
        this._configuration = config;
        this.loadData();
    }

    loadData() {
        if (!this._configuration) {
            return;
        }

        const promises = [];

        this._configuration.forEach((config: any) => {
            config.Chart.forEach((chart: any) => {
                const spQuery: any = {
                    title: chart.Query.ListTitle,
                    camlQuery: chart.Query.CamlQuery,
                    isDocumentLibrary: chart.Query.isDocumentLibrary,
                    viewFields: [{ internalName: 'ID' }],
                };
                if (chart.Query.RecursiveAll) {
                    spQuery.recursiveAll = chart.Query.RecursiveAll;
                }
                if (chart.Query.FolderName) {
                    spQuery.folderName = chart.Query.FolderName;
                }
                promises.push(this.sharePointService.getListItems(spQuery));
            });
        });

        Promise.all(promises).then(
            (results) => {
                let count = 0;
                this._configuration.forEach((config: any) => {
                    config.Chart.forEach((chart: any) => {
                        chart.value = results[count].length;
                        count++;
                    });
                });

                this.ready = true;
            },
            (error) => {
                console.error(error);
            }
        );
    }
}
