import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportBillsDialogComponent } from 'src/app/main/bills/import-bills-dialog/import-bills-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GridModule } from '@progress/kendo-angular-grid';
import { AngularFrameworkModule } from 'sp-office365-framework';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [ImportBillsDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule,
        MatIconModule,
        GridModule,
        AngularFrameworkModule,
        MatProgressSpinnerModule
    ],
    exports: [ImportBillsDialogComponent]
})
export class ImportBillsDialogModule {
}
