export const locale = {
    lang: 'en',
    data: {
        TITLE: 'Tasktemplates',
        COLUMNS: {
            TITLE: 'Name',
            DESCRIPTION: 'Description',
            OFFSETSTART: 'Addition to the startdate',
            INDAYS: '(in days)',
            OFFSETEND: 'Addition to the duedate',
            PRIORITY: 'Priority',
            HIGH: '(1) High',
            NORMAL: '(2) Normal',
            LOW: '(3) Low',
            PROCESSSTEP: 'Processstep',
            TYPE: 'Tasktype',
            SCHEDULEDTIME: 'Scheduled time',
            INHOURS: '(in hours)',
            OFFSETSTARTTYPE: 'Type of addition to the startdate',
            OFFSETENDTYPE: 'Type of addition to the duedate',
            TODAY: 'Since today',
            START: 'Since start of workpackage',
            END: 'From end of workpackage',
            STARTASSIGNMENT: 'Since start of project',
            ENDASSIGNMENT: 'From end of project',
        },
        NEW: 'New tasktemplate',
        EDIT: 'Edit tasktemplate',
        SAVE: 'Save',
        NOTIFICATIONS: {
            SAVING: 'Saving tasktemplate...',
            WAIT: 'Please wait.',
            SUCCESS: 'Tasktemplate saved successfully',
            ERROR: 'Error while saving: ',
        },
        TABS: {
            BASE: 'Basedata',
            DOCS: 'Documents',
        },
        DOCS: {
            TITLE: 'Taskdocuments',
            NEW: 'Upload document',
            NEWFOLDER: 'New folder',
            COLUMNS: {
                TITLE: 'Title',
                FILESIZE: 'Filesize',
                AUTHOR: 'Author',
                MODIFIED: 'Modified',
            },
        },
    },
};
